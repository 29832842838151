import PresentToAllOutlined from '@material-ui/icons/PresentToAllOutlined';
import { ArrowUpwardOutlined, NavigationOutlined, NavigationRounded } from '@material-ui/icons';
import { intersection } from 'lodash';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';
import React from 'react';
import { CowgStatusEnum } from '../../../../store/selectors/map-page-items.selectors';

export const CattleEventsColours = {
  [CowgStatusEnum.SHOCK_FAILED]: '#ff65a1',
  [CowgStatusEnum.SHOCK]: '#e80140',
  [CowgStatusEnum.IN_BOUNDARY]: '#878282',
  [CowgStatusEnum.IN_ZONE]: '#878282',
  [CowgStatusEnum.TRANSITIONING]: '#878282',
  [CowgStatusEnum.PIEZOING]: '#e3aa13',
  [CowgStatusEnum.VIBING]: '#367b02',
  [CowgStatusEnum.SOFT_SHOCK_LOCKOUT]: '#8f012b',
  [CowgStatusEnum.CONTROL_DISABLED_LOCATION]: '#66174B',
  [CowgStatusEnum.SHOVE]: '#FF6600',
  [CowgStatusEnum.HARD_SHOCK_LOCKOUT]: '#34000e',
  [CowgStatusEnum.GENTLE]: '#878282',
  [CowgStatusEnum.CONTROL_DISABLED_OTHER]: '#66174B',
  [CowgStatusEnum.CONTROL_DISABLED_NEAR_WATER_TROUGH]: '#66174B',
  [CowgStatusEnum.COLLAR_ROLLED]: '#66174B',
  [CowgStatusEnum.CONTROL_DISABLED_STATIONARY_COW]: '#66174B',
  [CowgStatusEnum.CONTROL_DISABLED_MANUAL_PAUSE_OR_DISABLED]: '#66174B',
  [CowgStatusEnum.CONTROL_DISABLED_COMMAND_PAUSED]: '#66174B',
  OTHER: '#878282',
};

export const CattleEventsIcons = {
  [CowgStatusEnum.TRANSITIONING]: ArrowUpwardOutlined,
  [CowgStatusEnum.IN_BOUNDARY]: PresentToAllOutlined,
  [CowgStatusEnum.IN_ZONE]: NavigationRounded,
  OTHER: NavigationOutlined,
};

export const getCattleEventIconType = (
  cowgStatuses: CowgStatusEnum[],
  selectedStatuses: CowgStatusEnum[] | undefined = undefined
) => {
  const statusesToGetIcon: CowgStatusEnum[] =
    selectedStatuses == null ? cowgStatuses : intersection(selectedStatuses, cowgStatuses);
  if (statusesToGetIcon.includes(CowgStatusEnum.TRANSITIONING))
    return CattleEventsIcons[CowgStatusEnum.TRANSITIONING];
  if (statusesToGetIcon.includes(CowgStatusEnum.IN_ZONE)) return CattleEventsIcons[CowgStatusEnum.IN_ZONE];
  if (statusesToGetIcon.includes(CowgStatusEnum.IN_BOUNDARY))
    return CattleEventsIcons[CowgStatusEnum.IN_BOUNDARY];
  return CattleEventsIcons.OTHER;
};

export const getCattleEventIconColor = (
  cowgStatuses: CowgStatusEnum[],
  selectedStatuses: CowgStatusEnum[] | undefined = undefined,
  eventType: number = 0
) => {
  const statusesToGetColor: CowgStatusEnum[] =
    selectedStatuses == null ? cowgStatuses : intersection(selectedStatuses, cowgStatuses);

  const matchedKey = Object.keys(CowgStatusEnum).find((key) =>
    statusesToGetColor.includes(key as CowgStatusEnum)
  ) as CowgStatusEnum | undefined;

  // Paddock activated
  if (eventType === 5) return '#429bff';
  // Paddock deactivated
  if (eventType === 6) return '#bd42ff';
  return matchedKey == null ? CattleEventsColours.OTHER : CattleEventsColours[matchedKey];
};

export const generateEventIcon = (
  cowgStatuses: CowgStatusEnum[],
  selectedCowGStatuses: CowgStatusEnum[] | undefined = undefined,
  heading: number = 0,
  eventType: number = 0
) => {
  const iconType = getCattleEventIconType(cowgStatuses, selectedCowGStatuses);
  const iconProps: SvgIconProps = {
    style: { color: 'white' },
    width: 20,
    height: 20,
    opacity: 0.8,
    x: 6,
    y: 6,
    fill: 'white',
  };
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${heading}deg)` }}
    >
      <circle
        cx="50%"
        cy="50%"
        r="16"
        fill={getCattleEventIconColor(cowgStatuses, selectedCowGStatuses, eventType)}
      />
      {React.createElement(iconType, { ...iconProps })}
    </svg>
  );
};
