import { flatten } from 'flat';
import { union, omit, sortBy } from 'lodash';
import moment from 'moment';

import { Device } from 'data/device';
import { SettingsMetadata } from 'data/settings';

import { DEFAULT_DATE_TIME_FORMAT } from 'application/modules/debug-cattle/utils/constants';

export const boolToString = (value?: boolean) => {
  if (value == null) return undefined;
  return value ? 'True' : 'False';
};

export const listToHasItemsString = (value?: string[]) => {
  if (value == null) return undefined;
  return value.length > 0 ? 'True' : 'False';
};

const flattenSettings = (settings?: object): { [key: string]: string | number } => {
  if (settings == null) return {};

  return flatten<any, { [key: string]: string | number }>(omit(settings, 'loraSettings'));
};

export const getSettingsColumns = (devices: Device[], settingsMetadata: SettingsMetadata | null) => {
  const settingsMetadataFlattened = flattenSettings(settingsMetadata ?? {});

  const flatSettingsNames = devices.map(({ settingsV2 }) =>
    Object.entries(flattenSettings(settingsV2?.settingsObject))
      .filter(([key, value]) => settingsMetadataFlattened[`${key}.default`] !== value)
      .map(([key]) => key)
  );

  const settingsColumns = union(...flatSettingsNames).map((settingName) => ({
    name: settingName.replace(/\./g, ' '),
    keyExtractor: (device: Device) => flattenSettings(device.settingsV2?.settingsObject)[settingName],
    render: (device: Device) =>
      JSON.stringify(flattenSettings(device.settingsV2?.settingsObject)[settingName]),
  }));

  return sortBy(settingsColumns, (column) => column.name);
};

export const getCheckinAge = (lastUpdate?: Date, retrievalTime?: Date) =>
  lastUpdate != null ? moment(lastUpdate).from(retrievalTime) : '';

export const prettyPrintLastLoraCheckinTime = (lastUpdate?: Date) =>
  lastUpdate != null ? moment(lastUpdate).format(DEFAULT_DATE_TIME_FORMAT) : '';
