import React, { FunctionComponent } from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  margin: 0 20px 0 0;
`;

type NumberInputProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number;
  label: string;
  id: string;
  name?: string;
};

const NumberInput: FunctionComponent<NumberInputProps> = ({ onChange, value, label, id, name }) => (
  <StyledTextField
    id={id}
    label={label}
    value={value}
    name={name}
    onChange={onChange}
    type="number"
    variant="outlined"
    InputLabelProps={{
      shrink: true,
    }}
  />
);

export default NumberInput;
