import React, { FunctionComponent, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Button,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import { getCurrentMobs } from 'store/selectors/mob.selectors';

import DateRangePicker from 'application/components/date-range-picker';

import MultiMobSelect from 'application/components/multi-mob-select';
import { isEmpty } from 'lodash';
import QueryHelpDialog from './query-help.dialog';

import FarmSelect from '../../../../components/farm-select';
import { DEFAULT_DATE_TIME_FORMAT } from '../../utils/constants';

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 1rem;
  align-items: center;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

const SEPARATOR = ',';

export type SearchPanelProps = {
  cattleEarTags: string[];
  serialNumbers: string[];
  dateRange: [Date, Date] | null;
  collarCommandIds: number[];
  firmwareVersions: string[];
  queryReturnedError: boolean;
  searchPanelIsExpanded: boolean;
  onSearchPanelExpandButtonClick: (newExpandedState: boolean) => void;
};

const SearchPanel: FunctionComponent<SearchPanelProps> = ({
  cattleEarTags: initialCattleEarTags,
  serialNumbers: initialSerialNumbers,
  collarCommandIds: initialCollarCommandIds,
  firmwareVersions: initialFirmwareVersions,
  dateRange,
  queryReturnedError,
  searchPanelIsExpanded,
  onSearchPanelExpandButtonClick,
}) => {
  const [cattleEarTags, setCattleEarTags] = useState(initialCattleEarTags);
  const [serialNumbers, setSerialNumbers] = useState(initialSerialNumbers);
  const [collarCommandIds, setCollarCommandIds] = useState(initialCollarCommandIds.map((c) => c.toString()));
  const [firmwareVersions, setFirmwareVersions] = useState(initialFirmwareVersions);
  const [fromDate, setFromDate] = useState(dateRange != null ? dateRange[0].toISOString() : '');
  const [toDate, setToDate] = useState(dateRange != null ? dateRange[1].toISOString() : '');
  const [queryHelpOpen, setQueryHelpOpen] = useState(false);

  const farmId = useSelector(getCurrentFarm);
  const mobIds = useSelector(getCurrentMobs);

  const searchPanelShortText = useMemo(() => {
    const chunks = [
      `${moment(fromDate).format(DEFAULT_DATE_TIME_FORMAT)} to ${moment(toDate).format(
        DEFAULT_DATE_TIME_FORMAT
      )}`,
    ];
    if (!isEmpty(collarCommandIds)) {
      chunks.push(`${collarCommandIds?.length} command(s)`);
    }
    if (!isEmpty(mobIds)) {
      chunks.push(`${mobIds?.length} mob(s)`);
    }
    if (!isEmpty(cattleEarTags)) {
      chunks.push(`${cattleEarTags?.length} cows(s)`);
    }
    if (!isEmpty(serialNumbers)) {
      chunks.push(`${serialNumbers?.length} collar(s)`);
    }
    if (!isEmpty(firmwareVersions)) {
      chunks.push(`${firmwareVersions?.length} firmware version(s)`);
    }
    return chunks.join(', ');
  }, [fromDate, toDate]);

  if (farmId == null) return null;

  const handleSearchEvent = () => {
    ReactGA.event({
      category: 'Debug Tool',
      action: 'Search',
    });
  };

  return (
    <HeaderWrapper>
      <QueryHelpDialog open={queryHelpOpen} handleClose={() => setQueryHelpOpen(false)} />
      <Accordion
        onChange={(e, isExpanded) => onSearchPanelExpandButtonClick(isExpanded)}
        style={{ flex: 1 }}
        expanded={searchPanelIsExpanded}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
          <div>
            <Typography>{!searchPanelIsExpanded ? searchPanelShortText : 'Search'}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <FarmSelect />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <MultiMobSelect farmId={farmId} />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <DateRangePicker
                fromDate={fromDate}
                toDate={toDate}
                setToDate={setToDate}
                setFromDate={setFromDate}
                leftMode={!isMobile}
                noMobileMode={!isMobile}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <TextField
                label="Cattle Ear Tags"
                value={cattleEarTags?.join(SEPARATOR)}
                onChange={(event) => setCattleEarTags(event.target.value.trim().split(SEPARATOR))}
                style={{ flex: 1 }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <TextField
                label="or Serial Numbers"
                value={serialNumbers?.join(SEPARATOR)}
                onChange={(event) => setSerialNumbers(event.target.value.trim().split(SEPARATOR))}
                style={{ flex: 1 }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <TextField
                label="Collar Command IDs"
                value={collarCommandIds?.join(SEPARATOR)}
                onChange={(event) =>
                  setCollarCommandIds(event.target.value.replace(/[^\d,]+/g, '').split(SEPARATOR))
                }
                style={{ flex: 1 }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <TextField
                label="Firmware Versions"
                value={firmwareVersions?.join(SEPARATOR)}
                onChange={(event) => setFirmwareVersions(event.target.value.trim().split(SEPARATOR))}
                style={{ flex: 1 }}
              />
            </Grid>
            <Grid item xs={12}>
              {queryReturnedError ? (
                <Typography style={{ color: 'red' }}>
                  Query Error: Try filter by mob, ear tag or serial number
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Row>
            <Button
              color="primary"
              component="a"
              style={{ fontSize: '12px' }}
              href={`https://farmhand.prod.halter.io/map/debug?${new URLSearchParams({
                farmId,
                ...(mobIds?.length === 1 && { mobId: mobIds[0] }),
                minDate: fromDate,
                maxDate: toDate,
              }).toString()}`}
              target="_blank"
            >
              Farmhand (New)
            </Button>
            <Button
              color="primary"
              component="a"
              style={{ fontSize: '12px' }}
              href={`/heatmap?${new URLSearchParams({
                farmIds: farmId,
                mobIds: mobIds?.join(',') ?? '',
                earTags: cattleEarTags?.join(SEPARATOR) ?? '',
                fromDate,
                toDate,
              }).toString()}`}
              target="_blank"
            >
              Heatmap (Old)
            </Button>
            <Button
              color="primary"
              component={Link}
              style={{ marginLeft: 'auto' }}
              to={{
                search: new URLSearchParams({
                  earTags: cattleEarTags?.join(SEPARATOR) ?? '',
                  serialNumbers: serialNumbers?.join(SEPARATOR) ?? '',
                  fromDate,
                  toDate,
                  farmId,
                  mobId: mobIds?.join(',') ?? '',
                  collarCommandIds: collarCommandIds?.join(SEPARATOR) ?? '',
                  firmwareVersions: firmwareVersions?.join(SEPARATOR) ?? '',
                }).toString(),
              }}
              onClick={() => handleSearchEvent()}
            >
              Start
            </Button>
          </Row>
        </AccordionActions>
      </Accordion>
    </HeaderWrapper>
  );
};

export default SearchPanel;
