import { DeviceOffFarmCheckpointEnum, IDeviceOnFarmStatesContextDTO } from '@halter-corp/fleet-service-client';
import { useSelector } from 'react-redux';
import {
  getOffFarmCheckpointsByCheckpoint,
  isOffFarmCheckpointsByCheckpointLoading,
  getDeviceOnFarmStatesByFarmId,
  isDeviceOnFarmStatesByFarmIdLoading,
  hasDeviceOnFarmStatesByFarmIdFailed,
  getDeprovisionedDeviceOnFarmStatesByFarmId,
  getDeviceOnFarmStatusCountsByFarmId,
  isDeviceOnFarmStatusCountsByFarmIdLoading,
  hasDeviceOnFarmStatusCountsByFarmIdFailed,
} from 'store/selectors/fleet.selectors';

import { DeviceOffFarmCheckpoint } from 'data/fleet-watch';
import HttpApiService from './http-api.service';

const FleetService = {
  useFetchOffFarmCheckpointsByCheckpoint: () => useSelector(getOffFarmCheckpointsByCheckpoint),
  useIsOffFarmCheckpointsByCheckpointLoading: () => useSelector(isOffFarmCheckpointsByCheckpointLoading),

  useFetchDeviceOnFarmStatesByFarmId: () => useSelector(getDeviceOnFarmStatesByFarmId),
  useIsDeviceOnFarmStatesByFarmIdLoading: () => useSelector(isDeviceOnFarmStatesByFarmIdLoading),
  useHasDeviceOnFarmStatesByFarmIdFailed: () => useSelector(hasDeviceOnFarmStatesByFarmIdFailed),
  useFetchDeprovisionedDeviceOnFarmStatesByFarmId: () =>
    useSelector(getDeprovisionedDeviceOnFarmStatesByFarmId),

  useFetchDeviceOnFarmStatusCountsByFarmId: () => useSelector(getDeviceOnFarmStatusCountsByFarmId),
  useIsDeviceOnFarmStatusCountsByFarmIdLoading: () => useSelector(isDeviceOnFarmStatusCountsByFarmIdLoading),
  useHasDeviceOnFarmStatusCountsByFarmIdFailed: () => useSelector(hasDeviceOnFarmStatusCountsByFarmIdFailed),

  setOffFarmCheckpoint: async (serialNumbers: string[], checkpoint: DeviceOffFarmCheckpointEnum) => {
    const api = await HttpApiService.getDeviceOffFarmApi();
    try {
      const { data } = await api.setDevicesOffFarmCheckpointReached({ serialNumbers, checkpoint });
      return data;
    } catch (err) {
      return undefined;
    }
  },

  async fetchOffFarmCheckpoints(
    serialNumbers?: string[],
    checkpoint?: DeviceOffFarmCheckpointEnum,
    paginationToken?: string
  ): Promise<DeviceOffFarmCheckpoint[]> {
    const api = await HttpApiService.getDeviceOffFarmApi();
    const { data } = await api.findLatestOffFarmCheckpointsForDevices({
      serialNumbers,
      checkpoint,
      paginationToken,
    });

    if (data.paginationToken != null) {
      const otherItems = await this.fetchOffFarmCheckpoints(serialNumbers, checkpoint, data.paginationToken);
      return [...data.items, ...otherItems];
    }
    return data.items;
  },

  async fetchDeviceOnFarmStates(
    serialNumbers?: string[],
    farmId?: string,
    nextSerialNumberParam?: string
  ): Promise<IDeviceOnFarmStatesContextDTO[]> {
    const api = await HttpApiService.getDeviceOnFarmApi();

    const deviceOnFarmStates: IDeviceOnFarmStatesContextDTO[] = [];
    let nextSerialNumber: string | undefined = nextSerialNumberParam;

    do {
      // eslint-disable-next-line no-await-in-loop
      const { data } = await api.findOnFarmStatesForDevices({ serialNumbers, farmId, nextSerialNumber });
      deviceOnFarmStates.push(...data.items);
      nextSerialNumber = data.nextSerialNumber;
    } while (nextSerialNumber != null);

    return deviceOnFarmStates;
  },

  async fetchDeviceOnFarmStatusCountsByFarmId(farmId: string) {
    const api = await HttpApiService.getDeviceOnFarmApi();
    const { data: deviceOnFarmStatusCounts } = await api.findDeviceOnFarmStatusCountsByFarmId(farmId);

    return deviceOnFarmStatusCounts;
  },

  setArriveAtHalterCheckpoint: async (boxIds: string[]) => {
    const api = await HttpApiService.getDeviceOffFarmApi();
    try {
      const { data } = await api.setDevicesHalterArrivalCheckpointReached({ deviceBoxIds: boxIds });
      return data;
    } catch (err) {
      return undefined;
    }
  },
};

export default FleetService;
