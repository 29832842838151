import React, { FunctionComponent, useMemo, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import { IUpdateTrainingModifierRequestDTO } from '@halter-corp/training-service-client';

import { useDispatch } from 'store';
import trainingEffects from 'store/effects/training.effects';
import TrainingService from 'services/training.service';

import Button from 'application/components/button';
import ScreenWrapper from 'application/components/screen-wrapper';

import ReactGA from 'react-ga';

import ModifierForm from '../forms/profile/modifier.form';
import DeleteModal from '../components/delete-modal';

const HeaderWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 16px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  opacity: 0.9;
  padding: 12px;
`;

const ShowModifierScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams() as { modifierId: string };
  const modifierId = useMemo(() => params.modifierId, [params]);

  useEffect(() => {
    dispatch(trainingEffects.fetchModifiers());
  }, [dispatch, modifierId]);

  const modifier = TrainingService.useSelectModifierById(modifierId);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (id: string, request: IUpdateTrainingModifierRequestDTO) => {
    setIsSubmitting(true);
    const result = await dispatch(trainingEffects.updateModifier({ modifierId: id, request }));

    setIsSubmitting(false);
    if (trainingEffects.updateModifier.fulfilled.match(result)) {
      // TODO we should just exit out of 'edit' mode instead, for now this is an indication that the request succeeded
      history.push('/training/profiles');
    }

    ReactGA.event({
      category: 'User action',
      action: 'Edit modifier button pressed',
    });
  };

  const deleteModifier = async (id: string) => {
    const result = await dispatch(trainingEffects.deleteModifier({ modifierId: id }));

    if (trainingEffects.deleteModifier.fulfilled.match(result)) {
      history.push('/training/profiles');
    }
  };

  return (
    <ScreenWrapper>
      <Grid item xs={12} sm={10} lg={8}>
        <HeaderWrapper>
          <Title>Edit modifier</Title>
          {modifier != null && (
            <Button
              text="Delete"
              style={{
                backgroundColor: '#f44336',
                color: 'white',
                marginLeft: 'auto',
                padding: '8px 24px 8px 24px',
              }}
              onClick={() => setDeleteModalOpen(true)}
            />
          )}
        </HeaderWrapper>
        {modifier != null && (
          <ModifierForm
            onSubmit={(values) => handleSubmit(modifier.id, values)}
            isSubmitting={isSubmitting}
            initialModifier={modifier}
          />
        )}
      </Grid>
      {modifier != null && (
        <DeleteModal
          open={deleteModalOpen}
          type="modifier"
          name={modifier.name}
          onConfirm={() => deleteModifier(modifier.id)}
          onDismiss={() => setDeleteModalOpen(false)}
        />
      )}
    </ScreenWrapper>
  );
};

export default ShowModifierScreen;
