import React, { FC } from 'react';
import { Typography, IconButton, Box } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styled from 'styled-components';
import { TowerWithDevicesAndNotes } from 'store/selectors/tower.selectors';

const DialogHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

type TowerNoteDrawerHeaderProps = {
  selectedTower: TowerWithDevicesAndNotes | undefined;
  onClose: () => void;
};

const TowerNoteDrawerHeader: FC<TowerNoteDrawerHeaderProps> = ({ selectedTower, onClose }) => (
  <DialogHeader>
    <Box style={{ paddingLeft: 6 }}>
      <Typography color="primary" style={{ fontSize: 14, marginBottom: -2, fontWeight: 500 }} component="p">
        Tower Notes
      </Typography>
      <Typography variant="h5" component="h2">
        {selectedTower?.id}
      </Typography>
    </Box>
    <IconButton style={{ padding: 8 }} onClick={onClose}>
      <ChevronRightIcon fontSize="large" />
    </IconButton>
  </DialogHeader>
);

export default TowerNoteDrawerHeader;
