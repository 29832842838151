import React, { createContext, FC, useContext, useState } from 'react';
import { Tower } from 'store/selectors/tower.selectors';

type ExistingTowerContextType = {
  existingTowerMap: Map<string, Tower>;
  setExistingTowerMap: React.Dispatch<React.SetStateAction<Map<string, Tower>>>;
};

const ExistingTowersContext = createContext<ExistingTowerContextType>(null!);

export const useExistingTowersContext = () => useContext(ExistingTowersContext);

export const ExistingTowersContextProvider: FC = ({ children }) => {
  const [existingTowerMap, setExistingTowerMap] = useState<Map<string, Tower>>(new Map());

  return (
    <ExistingTowersContext.Provider value={{ existingTowerMap, setExistingTowerMap }}>
      {children}
    </ExistingTowersContext.Provider>
  );
};
