import React, { FunctionComponent, useMemo } from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export enum StatusColours {
  GREEN = '#8bc34a',
  AMBER = '#ffc107',
  RED = '#b71c1c',
  GRAY = '#808080',
  BLUE = '#5db3b8',
}

type StatusIconProps = {
  color: StatusColours;
};

const StatusIcon: FunctionComponent<StatusIconProps> = ({ color }) => {
  const styles = useMemo(
    () => ({
      float: 'left' as const, // Use 'as const' to indicate the exact string value
      marginRight: '5px',
      color: `${color}`,
    }),
    [color]
  );
  return <FiberManualRecordIcon fontSize="small" style={styles} />;
};

export default StatusIcon;
