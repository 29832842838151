import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpApiService from 'services/http-api.service';
import { ISettingsMetadataDTO } from '@halter-corp/settings-service-client';

const settingsEffects = {
  fetchMetadata: createAsyncThunk('settings/fetch-metadata', async (): Promise<ISettingsMetadataDTO> => {
    const settingsApi = await HttpApiService.getSettingsApi();
    const { data: metadata } = await settingsApi.getMetadata();

    return metadata;
  }),
};

export default settingsEffects;
