import { createSlice } from '@reduxjs/toolkit';

import { reset } from 'store/actions';
import cattleAdapter from 'store/adapters/cattle.adapter';
import cattleEffects from 'store/effects/cattle.effects';
import trainingEffects from 'store/effects/training.effects';

const initialState = {
  cattle: cattleAdapter.getInitialState(),
};

const cattleSlice = createSlice({
  initialState,
  name: 'cattle',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(trainingEffects.fetchAppliedModifiers.fulfilled, (state, action) => {
        const entities = action.payload.cattleWithMobs;

        cattleAdapter.removeAll(state.cattle);
        cattleAdapter.addMany(state.cattle, entities);
      })
      .addCase(cattleEffects.fetchCattle.fulfilled, (state, action) => {
        const entities = action.payload;

        cattleAdapter.removeAll(state.cattle);
        cattleAdapter.addMany(state.cattle, entities);
      }),
});

export default cattleSlice;
