import React, { ReactElement, useState, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import copy from 'copy-to-clipboard';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Size,
  Padding,
} from '@material-ui/core';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import { isString, orderBy, partition } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';

export type Column<T> = {
  name: string | ReactElement;
  keyExtractor?: (item: T) => string | boolean | number | Date | undefined;
  render: (item: T, index: number) => ReactElement | string | number | undefined;
  toolTip?: (item: T) => string;
  sortExcludeBy?: (item: T) => boolean;
  allowCopyToClipboard?: boolean;
  noDataCondition?: (item: T) => boolean;
  headerToolTip?: string;
};

type DataTableProps<T> = {
  data: T[];
  columns: Column<T>[];
  defaultSortColumnName?: string;
  onSelectRow?: (item: T) => void;
  noDataKeys?: string[];
  size?: Size;
  padding?: Padding;
  rowColour?: string;
  headerColour?: string;
  indexColumn?: boolean;
  sortDirectionOption?: 'asc' | 'desc';
  stickyHeader?: boolean;
  tableSortDisabled?: boolean;
};

function DataTable<T>({
  data,
  columns,
  defaultSortColumnName,
  onSelectRow,
  noDataKeys = [],
  size,
  padding,
  rowColour,
  headerColour,
  indexColumn,
  sortDirectionOption,
  stickyHeader = true,
  tableSortDisabled = false,
}: DataTableProps<T>) {
  const [sortColumnName, setSortColumnName] = useState<string | undefined>(defaultSortColumnName);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(sortDirectionOption ?? 'asc');
  const header = headerColour || '#fafafa';
  const indexColumnVisible = indexColumn ?? true;

  const sortedData = useMemo(() => {
    const sortColumn = columns.find(({ name }) => sortColumnName === name);
    if (sortColumn == null) return data;

    const [sortExcludedData, sortIncludedData] = partition(
      data,
      (item) => sortColumn.sortExcludeBy?.(item) ?? false
    );

    return [
      ...orderBy(sortIncludedData, sortColumn.keyExtractor ?? sortColumn.render, sortDirection),
      ...sortExcludedData,
    ];
  }, [data, columns, sortColumnName, sortDirection]);

  return (
    <Table stickyHeader={stickyHeader} size={size}>
      <TableHead>
        <TableRow style={{ position: 'relative', backgroundColor: headerColour }}>
          {columns.map(({ name, keyExtractor, render, allowCopyToClipboard, headerToolTip }, index) => {
            let style = {};
            if (name === '') {
              style = {
                fontSize: 10,
                padding: 0,
                textAlign: 'center',
                color: 'grey',
                backgroundColor: header,
              };
            } else if (index === 0 && indexColumnVisible) {
              style = {
                position: 'sticky',
                left: 0,
                backgroundColor: header,
                zIndex: 10,
                boxShadow: '2px 0px 8px rgba(125,147,178,.25)',
              };
            } else {
              style = { backgroundColor: headerColour };
            }
            return (
              <TableCell
                key={isString(name) ? name : index}
                sortDirection={sortColumnName === name ? sortDirection : undefined}
                style={style}
                padding={padding}
              >
                <Tooltip title={headerToolTip ?? ''}>
                  <TableSortLabel
                    active={sortColumnName === name && name !== ''}
                    direction={sortColumnName === name ? sortDirection : undefined}
                    onClick={() => {
                      if (name === '' || !isString(name)) return;
                      setSortDirection(sortColumnName !== name || sortDirection === 'asc' ? 'desc' : 'asc');
                      setSortColumnName(name);
                    }}
                    disabled={tableSortDisabled}
                  >
                    {name}
                  </TableSortLabel>
                </Tooltip>
                {allowCopyToClipboard != null && (
                  <FileCopyOutlined
                    onClick={() => copy(sortedData.map<any>(keyExtractor ?? render).join(','))}
                    fontSize="small"
                    style={{ opacity: 0.8, marginBottom: -4, cursor: 'pointer' }}
                  />
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedData.map((item, rowIndex) => (
          <TableRow
            // eslint-disable-next-line react/no-array-index-key
            key={rowIndex}
            style={{ backgroundColor: rowColour }}
            hover
            onClick={() => {
              if (isMobile && onSelectRow != null) onSelectRow(item);
            }}
            onDoubleClick={() => onSelectRow?.(item)}
          >
            {columns.map((column, colIndex) => {
              let style = {};
              if (column.name === '') {
                style = {
                  fontSize: 10,
                  padding: 0,
                  textAlign: 'center',
                  color: 'grey',
                  backgroundColor: rowColour ?? '#fafafa',
                };
              } else if (colIndex === 0 && indexColumnVisible) {
                style = {
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'white',
                  height: '100%',
                  boxShadow: '2px 0px 8px rgba(125,147,178,.25)',
                };
              } else if (column.toolTip) {
                style = {
                  cursor: 'default',
                };
              }

              return (
                <Tooltip
                  title={column.toolTip ? column.toolTip(item) : ''}
                  enterDelay={500}
                  style={{ whiteSpace: 'normal' }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={colIndex}
                >
                  <TableCell
                    style={style}
                    // eslint-disable-next-line react/no-array-index-key
                    key={colIndex}
                    padding={padding}
                  >
                    {column.noDataCondition == null || !column.noDataCondition(item) ? (
                      column.render(item, rowIndex)
                    ) : (
                      <div style={{ opacity: 0.5 }}>No data</div>
                    )}
                  </TableCell>
                </Tooltip>
              );
            })}
          </TableRow>
        ))}
        {noDataKeys.map((key) => (
          <TableRow>
            {columns.map((_, colIndex) => (
              <TableCell
                padding={padding}
                style={
                  colIndex === 0
                    ? {
                        position: 'sticky',
                        left: 0,
                        backgroundColor: 'white',
                        height: '100%',
                        boxShadow: '2px 0px 8px rgba(125,147,178,.25)',
                      }
                    : {}
                }
              >
                {colIndex === 0 ? key : 'No data'}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default DataTable;
