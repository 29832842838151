import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Delete, Power, Save, SettingsInputAntenna } from '@material-ui/icons';
import Button from 'application/components/button';
import { orderBy } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Tower, selectLatLngTowers } from 'store/selectors/tower.selectors';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import DeleteConfirmationDialog from './dialogs/delete-confirmation.dialog';
import TowerForm from './tower-form';
import TowerNote from './tower-note';
import { useModifiedTowersContext } from '../contexts/modified-tower-context';
import { useExistingTowersContext } from '../contexts/existing-tower-context';
import { useNewTowersContext } from '../contexts/new-tower-context';
import { useInvalidInputContext } from '../contexts/invalid-input-context';

const drawerStyle = {
  display: 'flex',
  position: 'relative',
  marginLeft: 'auto',
  width: 'auto',
} as React.CSSProperties;

const paperStyle = {
  width: 'auto',
  minWidth: 350,
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  height: '95.5vh',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  paddingBlockEnd: '16px',
} as React.CSSProperties;

const RowWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 9999;
`;

type TowesDrawerProps = {
  moveTo: (latlng: L.LatLng, instant?: boolean) => void;
  setHover: React.Dispatch<React.SetStateAction<string>>;
  setWarningMessage: React.Dispatch<React.SetStateAction<{ open: boolean; content: string }>>;
  handleSaveTower: (tower: Tower) => Promise<any>;
  handleUpdateTower: (tower: Tower) => void;
  handleRemoveTower: (tower: Tower) => void;
  handleDeleteTower: (towerId: string) => void;
};

const TowersDrawer: FunctionComponent<TowesDrawerProps> = ({
  moveTo,
  setHover,
  setWarningMessage,
  handleSaveTower,
  handleUpdateTower,
  handleRemoveTower,
  handleDeleteTower,
}) => {
  const [open, setOpen] = useState<string | null>(null);

  const { existingTowerMap, setExistingTowerMap } = useExistingTowersContext();
  const { newTowerMap, setNewTowerMap } = useNewTowersContext();
  const { modified, setModified } = useModifiedTowersContext();
  const { invalidInput } = useInvalidInputContext();
  const [openAccordion, setOpenAccordion] = useState<Map<string, boolean>>(new Map());
  const latLngTowers = useSelector(selectLatLngTowers);

  const onClose = () => setOpen(null);

  const handleOpenAccordion = (towerId: string) => {
    setOpenAccordion((current) => {
      current.set(towerId, true);
      return new Map(current);
    });
  };

  const handleCloseAccordion = (towerId: string) => {
    setOpenAccordion((current) => {
      current.delete(towerId);
      return new Map(current);
    });
  };

  const handleClick = (tower: Tower) => {
    if (openAccordion.get(tower.id) === true) {
      handleCloseAccordion(tower.id);
    } else {
      handleOpenAccordion(tower.id);
    }
  };

  const saveAllNetworkGroupTowers = async (towers: Tower[]) => {
    let towersInGroup = towers;
    const routerTowers = towersInGroup.filter((tower) => tower.router);

    if (routerTowers.length > 1) {
      setWarningMessage({
        open: true,
        content: `More than one router tower detected in network group.`,
      });
      return null;
    }

    let currentNetworkGroupId = '';
    if (routerTowers.length === 1) {
      const result = await handleSaveTower(routerTowers[0]);
      if (result.payload == null) {
        return null;
      }
      currentNetworkGroupId = result.payload.networkGroupId;
      towersInGroup = towersInGroup.filter((tower) => tower.id !== routerTowers[0].id);
      handleRemoveTower(routerTowers[0]);
    } else {
      const towersWithRouterTowerId = towersInGroup.filter(
        (tower) => tower.routerTowerId != null && tower.routerTowerId.length > 0
      );
      if (towersWithRouterTowerId == null || towersWithRouterTowerId.length === 0) {
        setWarningMessage({
          open: true,
          content: `No external router tower id for network group without router tower`,
        });
        return null;
      }

      const { routerTowerId } = towersWithRouterTowerId[0];
      if (routerTowerId == null) {
        return null;
      }

      const externalRouterTowers = latLngTowers.filter((tower) => tower.id === routerTowerId);
      if (externalRouterTowers == null || externalRouterTowers.length === 0) {
        setWarningMessage({
          open: true,
          content: `Selected router tower for network group does not exist in range of farm`,
        });
        return null;
      }

      currentNetworkGroupId = externalRouterTowers[0].networkGroupId;
    }

    towersInGroup = towersInGroup.map((tower) => {
      const towerWithNetworkGroup = tower;
      towerWithNetworkGroup.networkGroupId = currentNetworkGroupId;
      return towerWithNetworkGroup;
    });

    return towersInGroup;
  };

  const saveAllTowers = async (allTowers: Tower[]) => {
    const groupSet = new Set<number>();
    allTowers.forEach((tower) => {
      groupSet.add(tower.groupIndex ?? 0);
    });
    const groups = Array.from(groupSet);

    const allTowersToSave: Tower[] = [];
    await Promise.all(
      groups.map(async (groupIndex) => {
        const towersInGroup = allTowers.filter((tower) => tower.groupIndex === groupIndex);

        const result = await saveAllNetworkGroupTowers(towersInGroup);
        if (result != null) {
          allTowersToSave.push(...result);
        }
      })
    );

    const saveResults = await Promise.all(allTowersToSave.map(handleSaveTower));

    const unsavedTowers = allTowersToSave.filter((_, index) => saveResults[index].payload == null);
    if (unsavedTowers.length > 0) {
      setWarningMessage({
        open: true,
        content: `Could not save tower${unsavedTowers.length !== 1 && 's'} ${unsavedTowers
          .map((tower) => `"${tower.id}"`)
          .join(
            ', '
          )}. Please check if there are existing towers of the same names and that you have set mains powered.`,
      });
    }

    allTowersToSave.forEach((tower, index) => {
      if (saveResults[index].payload != null) handleRemoveTower(tower);
    });

    setModified((current) => {
      allTowersToSave.forEach((tower, index) => {
        if (saveResults[index].payload != null) current.delete(tower.id);
      });
      return new Map(current);
    });
  };

  return (
    <Drawer
      style={drawerStyle}
      PaperProps={{ style: paperStyle }}
      variant="persistent"
      anchor="right"
      open={[...newTowerMap.values()].length > 0 || [...existingTowerMap.values()].length > 0}
    >
      <RowWrapper>
        <ListItem divider>New Towers</ListItem>
        {[...newTowerMap.values()].map((tower) => (
          <Accordion
            style={{ padding: 0, margin: 0 }}
            expanded={openAccordion.get(tower.id) === true}
            key={tower.id}
          >
            <AccordionSummary style={{ padding: 0, margin: 0 }}>
              <Box
                style={{ width: '100%', margin: 0 }}
                onClick={() => {
                  handleClick(tower);
                }}
                onMouseEnter={() => {
                  setHover(tower.id);
                }}
                onMouseLeave={() => {
                  setHover('');
                }}
              >
                <ListItem
                  button
                  style={{
                    cursor: 'pointer',
                    gap: '4px',
                    // eslint-disable-next-line no-nested-ternary
                    backgroundColor: invalidInput.get(tower.id)
                      ? '#FBB1A1'
                      : modified.get(tower.id)
                      ? 'beige'
                      : '',
                  }}
                >
                  <ListItemIcon>
                    <SettingsInputAntenna />
                    {tower.mainsPowered && <Power />}
                  </ListItemIcon>
                  <ListItemText
                    primary={tower.id}
                    secondary={modified.get(tower.id) && 'Uncommited changes'}
                  />
                  <IconButton
                    style={{ padding: 10 }}
                    disabled={modified.get(tower.id) != null}
                    onClick={async (e) => {
                      e.stopPropagation();
                      const result = await handleSaveTower(tower);
                      if (result?.payload != null) handleRemoveTower(tower);
                      else {
                        setWarningMessage({
                          open: true,
                          content: `Could not save tower "${tower.id}". Please check if there is an existing tower of the same name and that you have set mains powered.`,
                        });
                      }
                    }}
                  >
                    <Save htmlColor={modified.get(tower.id) ? 'lightgray' : 'gray'} />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveTower(tower);
                    }}
                  >
                    <Delete htmlColor="#4caf50" />
                  </IconButton>
                </ListItem>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TowerForm
                key={`new-form-${tower.id}-${tower.mainsPowered}-${tower.router}-${tower.starlink}`}
                isNew
                tower={tower}
                setTowerMap={setNewTowerMap}
                handleClose={handleCloseAccordion}
                moveTo={moveTo}
              />
            </AccordionDetails>
            <Divider />
          </Accordion>
        ))}
        <Button
          text="Save all new towers"
          disabled={[...newTowerMap.values()].length === 0}
          style={{ margin: '0px 4px 0px 4px' }}
          onClick={() => saveAllTowers([...newTowerMap.values()])}
        />
        <Box component="br" />
        <ListItem divider>Existing Towers</ListItem>

        {[...existingTowerMap.values()].map((tower) => {
          const sortedTowerNotes = orderBy(tower.notes, 'createdAt', 'desc');

          return (
            <Accordion
              style={{ padding: 0, margin: 0, boxShadow: 'none' }}
              expanded={openAccordion.get(tower.id) === true}
              key={tower.id}
            >
              <AccordionSummary style={{ padding: 0, margin: 0 }}>
                <Box
                  style={{ width: '100%', margin: 0 }}
                  key={tower.id}
                  onClick={() => {
                    handleClick(tower);
                  }}
                  onMouseEnter={() => {
                    setHover(tower.id);
                  }}
                  onMouseLeave={() => {
                    setHover('');
                  }}
                >
                  <ListItem
                    button
                    style={{
                      cursor: 'pointer',
                      gap: '4px',
                      backgroundColor: modified.get(tower.id) ? 'beige' : '',
                    }}
                  >
                    <ListItemIcon>
                      <SettingsInputAntenna />
                      {tower.mainsPowered && <Power />}
                    </ListItemIcon>
                    <ListItemText
                      primary={tower.id}
                      secondary={modified.get(tower.id) && 'Uncommited changes'}
                    />
                    <IconButton
                      disabled={modified.get(tower.id) != null}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUpdateTower(tower);
                        handleRemoveTower(tower);
                      }}
                    >
                      <Save htmlColor={modified.get(tower.id) ? 'lightgray' : 'gray'} />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(tower.id);
                      }}
                    >
                      <Delete htmlColor="#ff1744" />
                    </IconButton>
                  </ListItem>
                  <DeleteConfirmationDialog
                    open={open === tower.id}
                    onClose={onClose}
                    handleDelete={handleDeleteTower}
                    tower={tower}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: 'column' }}>
                <TowerForm
                  key={`existing-form-${tower.id}-${tower.mainsPowered}-${tower.router}-${tower.starlink}`}
                  isNew={false}
                  tower={tower}
                  setTowerMap={setExistingTowerMap}
                  handleClose={handleCloseAccordion}
                  moveTo={moveTo}
                />
                <List>
                  <ListItem style={{ marginTop: 8, paddingLeft: 3, fontSize: '16px' }} divider>
                    {pluralize('Existing note', tower.notes.length, true)}
                  </ListItem>

                  {sortedTowerNotes.map((note) => (
                    <TowerNote key={`${note.towerId}-${note.createdAt}`} tower={tower} note={note} />
                  ))}
                </List>
              </AccordionDetails>
              <Divider />
            </Accordion>
          );
        })}
      </RowWrapper>
    </Drawer>
  );
};

export default TowersDrawer;
