import React, { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Typography,
} from '@material-ui/core';

type QueryHelpDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const QueryHelpDialog: FunctionComponent<QueryHelpDialogProps> = ({ open, handleClose }) => (
  <Dialog open={open}>
    <DialogTitle>Query Language</DialogTitle>
    <DialogContent>
      <Typography>
        We use the Lucene Query Language for writing custom queries. The fixed query is generated from the
        cattle ear tags and timestamp fields, and any custom query written in the Query box gets appended with
        AND.
        <br />
        <br />
        Read the Lucene documentation&nbsp;
        <Link href="https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html">
          here
        </Link>
        .
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default QueryHelpDialog;
