import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Source, Layer, Marker } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import HeatMapService from 'services/heat-map.service';
import heatMapEffects from 'store/effects/heat-map.effects';
import { Feature, LineString } from 'geojson';
import { bbox, featureCollection, lineString } from 'turf';
import { Bounds, DataMapSearch } from '../panels/data-map.panel';

type BreakFenceLayerProps = {
  search: DataMapSearch;
  currentTime?: Date;
  recenterMap: (bounds: Bounds) => void;
  refresh: boolean;
};

const BreakFenceLayer: FunctionComponent<BreakFenceLayerProps> = ({
  search,
  currentTime,
  recenterMap,
  refresh,
}) => {
  const dispatch = useDispatch();

  const breakFences = HeatMapService.useFetchFarmBreaks();
  const exitPoints = HeatMapService.useFetchExitPoints();

  const filteredbreakFences =
    currentTime === undefined
      ? breakFences
      : breakFences.filter(
          (breakFence) =>
            breakFence.startTime.getTime() <= currentTime.getTime() &&
            breakFence.dropTime.getTime() >= currentTime.getTime()
        );

  const lineSegments = featureCollection(
    filteredbreakFences.flatMap((breakFence) => {
      const lineSegs: Feature<LineString>[] = [];

      const coordinates = breakFence.geometry.geometry.coordinates[0];
      for (let i = 0; i < coordinates.length - 1; i += 1) {
        const line = lineString([coordinates[i], coordinates[i + 1]]);
        // @ts-ignore
        const inActiveEdges: boolean = breakFence.geometry.properties.inActiveEdges.includes(i);
        line.properties = { inActiveEdges };
        lineSegs.push(line);
      }
      return lineSegs;
    })
  );

  const filteredExitPoints =
    currentTime === undefined
      ? exitPoints
      : exitPoints.filter(
          (exitPoint) =>
            exitPoint.startTime.getTime() <= currentTime.getTime() &&
            exitPoint.endTime.getTime() >= currentTime.getTime()
        );

  useEffect(() => {
    dispatch(heatMapEffects.fetchFarmBreaks(search));
    dispatch(heatMapEffects.fetchExitPoints(search));
  }, [JSON.stringify(search), refresh]);

  // Recenter Map
  useMemo(() => {
    if (breakFences.length > 0) {
      const bboxes = breakFences.map((breakFence) => bbox(breakFence.geometry));
      const bounds = bboxes.reduce(
        (acc, bb) => ({
          minLng: Math.min(acc.minLng, bb[0]),
          minLat: Math.min(acc.minLat, bb[1]),
          maxLng: Math.max(acc.maxLng, bb[2]),
          maxLat: Math.max(acc.maxLat, bb[3]),
        }),
        { minLng: Infinity, minLat: Infinity, maxLng: -Infinity, maxLat: -Infinity }
      );

      if (bounds.minLng !== Infinity) {
        recenterMap(bounds);
      }
    }
  }, [breakFences]);

  return (
    <>
      {filteredbreakFences?.map((breakFence, index) => (
        <Source type="geojson" data={breakFence.geometry} key={`breakFence${index}`}>
          <Layer type="fill" paint={{ 'fill-opacity': 0.3 }} />
        </Source>
      ))}
      <Source type="geojson" data={lineSegments}>
        <Layer
          type="line"
          paint={{
            'line-color': 'white',
            'line-width': 4,
          }}
          filter={['==', 'inActiveEdges', false]}
        />
        <Layer
          type="line"
          paint={{
            'line-color': 'white',
            'line-width': 4,
            'line-dasharray': [2, 2],
          }}
          filter={['==', 'inActiveEdges', true]}
        />
      </Source>
      {filteredExitPoints?.map((exitPoint, index) => (
        <Marker
          longitude={exitPoint.point.geometry.coordinates[0]}
          latitude={exitPoint.point.geometry.coordinates[1]}
          key={`exitPoint${index}`}
        />
      ))}
    </>
  );
};
export default BreakFenceLayer;
