import { createSelector } from '@reduxjs/toolkit';

import { IMobDTO } from '@halter-corp/cattle-service-client';
import { AllMobs } from 'data/cattle/cattle';
import { AppState } from '../store';
import { mobAdapter, allFarmsMobsAdapter } from '../adapters/mob.adapter';

export const selectMobsSlice = (state: AppState) => state.mobs.entityState;
export const selectCurrentMobsSlice = (state: AppState) => state.mobs.currentMobs;
export const allFarmsMobsSlice = (state: AppState) => state.mobs.allFarmsMobs;

export const {
  selectIds: selectMobIds,
  selectEntities: selectMobEntities,
  selectAll: selectAllMobs,
  selectById: selectMobEntityById,
} = mobAdapter.getSelectors(selectMobsSlice);

export const selectMobs = createSelector(selectMobEntities, (mobs): IMobDTO[] =>
  Object.values(mobs).reduce<IMobDTO[]>((previous, mob) => {
    if (mob == null) return previous;
    return [...previous, mob];
  }, [])
);

export const getCurrentMobs = createSelector(selectCurrentMobsSlice, (currentMobs) => currentMobs);
export const getCurrentMob = createSelector(selectCurrentMobsSlice, (currentMobs) => currentMobs?.[0]);

export const { selectIds: selectAllFarmsMobsFarmIds, selectEntities: selectAllFarmsMobsEntities } =
  allFarmsMobsAdapter.getSelectors(allFarmsMobsSlice);

export const selectAllFarmsMobs = createSelector(selectAllFarmsMobsEntities, (allMobs): AllMobs[] =>
  Object.values(allMobs).reduce<AllMobs[]>((previous, mobs) => {
    if (mobs == null) return previous;
    return [...previous, mobs];
  }, [])
);
