import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useLeafletBounds, useLeafletZoom } from 'use-leaflet';
import { LatLngBounds } from 'leaflet';
import { countBy, entries, groupBy } from 'lodash';
import { renderToStaticMarkup } from 'react-dom/server';
import { PixiOverlay } from 'packages/react-leaflet-pixi-overlay';
import { AppState, useSelector } from 'store';
import BffDebugToolService from 'services/bff-debug-tool.service';
import { selectFilteredCattleEvents } from 'store/selectors/debug-cattle.selectors';
import { CattleEvent, CowgStatusEnum } from 'store/selectors/map-page-items.selectors/cattle-event.selector';
import { generateEventIcon } from '../../../../utils/cattle-event-util';
import CattleEventPopup from './cattle-event.popup';
import { CattleEventsSummary } from '../../events-summary.panel';

export type CattleEventsProps = {
  selectedCows: string[] | null;
  scrubbedDateRange: [Date, Date] | null;
  selectedCowGStatuses: CowgStatusEnum[];
  onChangeThing: (cattleEventSummaries: CattleEventsSummary[]) => void;
};

const CattleEventsLayer2: FunctionComponent<CattleEventsProps> = ({
  selectedCows,
  scrubbedDateRange,
  selectedCowGStatuses,
  onChangeThing,
}) => {
  const zoom = useLeafletZoom();
  const bounds = useLeafletBounds();

  const showCattleEvents = useMemo(() => zoom > 20, [zoom]);
  const mapViewBounds = useMemo(() => new LatLngBounds(bounds), [bounds]);

  const cattleEvents: CattleEvent[] = useSelector((state: AppState) =>
    selectFilteredCattleEvents(state, {
      minimumDate: scrubbedDateRange?.[0] || null,
      maximumDate: scrubbedDateRange?.[1] || null,
      mapViewBounds,
      selectedCows,
      selectedCowGStatuses,
    })
  );

  const [selectedCattleEventId, setSelectedCattleEventId] = useState<string | null>(null);
  const selectedCattleEvent = useMemo(() => {
    if (selectedCattleEventId == null) return null;
    return cattleEvents.find(({ id }) => id === selectedCattleEventId) ?? null;
  }, [selectedCattleEventId]);
  const [fullCattleEvent, setFullCattleEvent] = useState<CattleEvent | null>(null);

  useEffect(() => {
    if (selectedCattleEvent == null) {
      setFullCattleEvent(null);
      return;
    }

    (async () => {
      const fullDeviceMetricsForTimeAndDevice = await BffDebugToolService.fetchDeviceMetricByTimeAndDevice(
        selectedCattleEvent.timestamp,
        selectedCattleEvent.serialNumber
      );
      const matchingDeviceMetric = fullDeviceMetricsForTimeAndDevice.find(
        (fullDeviceMetric) =>
          JSON.stringify(fullDeviceMetric.data.metric.cowgEventStream.cowgStatus) ===
            JSON.stringify(selectedCattleEvent.cowgStatuses) &&
          fullDeviceMetric.data.metric.cowgEventStream.location.lat === selectedCattleEvent.location.lat &&
          fullDeviceMetric.data.metric.cowgEventStream.location.lon === selectedCattleEvent.location.lon &&
          fullDeviceMetric.data.metric.cowgEventStream.locationMetadata.heading ===
            selectedCattleEvent.locationMetadata.heading
      );
      if (matchingDeviceMetric == null) {
        setFullCattleEvent(null);
      } else {
        const event = matchingDeviceMetric.data.metric?.cowgEventStream;
        setFullCattleEvent({
          ...event,
          cattleName: matchingDeviceMetric.data.context?.cattleName,
          serialNumber: matchingDeviceMetric.data.serialNumber,
          id: `${matchingDeviceMetric.id}`,
          timestamp: new Date(matchingDeviceMetric.timestamp).toISOString(),
          cowgStatuses: event.cowgStatus || [],
        });
      }
    })();
  }, [selectedCattleEvent]);

  useEffect(() => {
    onChangeThing(
      entries(groupBy(cattleEvents, (item) => item.cattleName)).map(([cattleName, items]) => {
        const countOfEachStatus = countBy(items.flatMap((item) => item.cowgStatuses));
        const inBounds = countOfEachStatus.IN_BOUNDARY ?? 0;
        const shockFailed = countOfEachStatus.SHOCK_FAILED ?? 0;
        const shock = countOfEachStatus.SHOCK ?? 0;
        const inZone = countOfEachStatus.IN_ZONE ?? 0;
        const piezoing = countOfEachStatus.PIEZOING ?? 0;
        const controlDisabled = countOfEachStatus.CONTROL_DISABLED ?? 0;
        const shove = countOfEachStatus.SHOVE ?? 0;
        return {
          cattleName,
          shockFailed,
          shock,
          inBounds,
          inZone,
          piezoing,
          controlDisabled,
          shove,
          items,
        } as CattleEventsSummary;
      })
    );
  }, [cattleEvents]);

  const markers = useMemo(() => {
    if (!showCattleEvents) return [];
    return cattleEvents.map((event) => ({
      id: event.id,
      iconId: `${JSON.stringify(event.cowgStatuses)}-${event.locationMetadata?.heading}`,
      customIcon: renderToStaticMarkup(
        generateEventIcon(event.cowgStatuses, selectedCowGStatuses, event.locationMetadata?.heading)
      ),
      position: [event.location.lat, event.location.lon],
      onClick: (cattleEventId: string) => setSelectedCattleEventId(cattleEventId),
    }));
  }, [cattleEvents, showCattleEvents]);

  if (!showCattleEvents) return null;

  return (
    <>
      <PixiOverlay markers={markers} />
      {selectedCattleEvent != null && (
        <CattleEventPopup
          cattleEvent={fullCattleEvent ?? selectedCattleEvent}
          onClose={() => setSelectedCattleEventId(null)}
        />
      )}
    </>
  );
};

export default CattleEventsLayer2;
