import { Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import FleetService from 'services/fleet.service';
import styled from 'styled-components';
import { DeviceOffFarmCheckpointEnum } from '@halter-corp/fleet-service-client';
import factory from '../images/factory.png';
import ship from '../images/ship.png';
import pallet from '../images/pallet.png';
import box from '../images/box.png';
import truck from '../images/truck.png';
import farm from '../images/farm.png';
import office from '../images/office.png';
import storage from '../images/storage.png';
import failedPrep from '../images/failedPrep.png';
import arrow from '../images/arrow.png';
import ft from '../images/ft.png';
import unknown from '../images/unknown.png';
import research from '../images/research.png';
import hass from '../images/hass.png';
import malrose from '../images/malrose.png';
import smallFactory from '../images/smallFactory.png';
import bin from '../images/bin.png';

type FleetTrackingDiagramProps = {
  setSelectedCheckpoint: (checkpoint: DeviceOffFarmCheckpointEnum) => void;
  counts?: Map<string, number>;
};

const Wrapper = styled.div`
  height: 580px;
  width: calc(100% - 40px);
  padding: 20px 0px;
  position: relative;
`;

// change background on hover
const TrackingStateDiv = styled.div`
  border-radius: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: #e5e5e5;
    cursor: pointer;
  }
`;

const DiagramText: FunctionComponent<{ text: string; count: string }> = ({ text, count }) => (
  <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
    <Typography style={{ textAlign: 'center', color: '#222', fontSize: '12px', width: '100%' }}>
      {text}
    </Typography>
    <Typography style={{ textAlign: 'center', color: '#444', fontSize: '18px', width: '100%' }}>
      {count}
    </Typography>
  </div>
);

const DiagramImage: FunctionComponent<{ image: string }> = ({ image }) => (
  <div style={{ display: 'flex', flex: 2, flexDirection: 'column', justifyContent: 'center' }}>
    <img src={image} style={{ height: '75px' }} alt="" />
  </div>
);

const DiagramArrow: FunctionComponent<{ top: string; left: string; angle?: number }> = ({
  top,
  left,
  angle = 0,
}) => (
  <img
    src={arrow}
    style={{ position: 'absolute', height: '40px', top, left, rotate: `${angle}deg` }}
    alt=""
  />
);

const FleetTrackingDiagram: FunctionComponent<FleetTrackingDiagramProps> = ({
  setSelectedCheckpoint,
  counts,
}) => {
  const loading = FleetService.useIsOffFarmCheckpointsByCheckpointLoading();
  const noDataMessage = loading ? 'loading...' : 'None';

  function formatCheckpoint(checkpoint: string): string {
    return checkpoint
      .toLowerCase()
      .split('_')
      .map((word, index) => {
        // don't capitalize prepositions or articles
        if (index > 0 && ['from', 'of', 'the', 'in'].includes(word)) {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  const TrackingState = ({
    checkpoint,
    text,
    top,
    left,
    image,
  }: {
    checkpoint: DeviceOffFarmCheckpointEnum;
    text?: string;
    top: string;
    left: string;
    image?: string;
  }) => (
    <TrackingStateDiv
      style={{ height: '150px', width: '125px', position: 'absolute', top, left }}
      onClick={() => setSelectedCheckpoint(checkpoint)}
    >
      <div style={{ display: 'flex', flex: 1 }} />
      {image && <DiagramImage image={image} />}
      <DiagramText
        text={text ?? formatCheckpoint(checkpoint.toString())}
        count={counts?.get(checkpoint)?.toString()?.toLocaleString() || noDataMessage}
      />
    </TrackingStateDiv>
  );

  const columnToPx = (column: number): string => `${column * 150}px`;
  const rowToPx = (row: number): string => `${row * 150}px`;

  const arrowColumnToPx = (column: number): string => `${column * 150 + 115}px`;
  const arrowRowToPx = (row: number): string => `${row * 150 + 60}px`;

  return (
    <Wrapper>
      <TrackingState
        checkpoint="SERIAL_NUMBER_GENERATED"
        image={factory}
        top={rowToPx(1)}
        left={columnToPx(0)}
      />

      <TrackingState checkpoint="HASS_TESTING" image={hass} top={rowToPx(0)} left={columnToPx(1)} />
      <TrackingState checkpoint="SHIPPED_FROM_FACTORY" image={ship} top={rowToPx(1)} left={columnToPx(1)} />
      <TrackingState
        checkpoint="NEVER_LEFT_FACTORY"
        image={smallFactory}
        top={rowToPx(2)}
        left={columnToPx(1)}
      />
      <TrackingState checkpoint="ARRIVED_AT_HALTER" image={pallet} top={rowToPx(1)} left={columnToPx(2)} />

      <TrackingState
        checkpoint="RESEARCH_AND_DEVELOPMENT"
        image={research}
        top={rowToPx(0.5)}
        left={columnToPx(3)}
      />

      <TrackingState checkpoint="STARTED_PREP" image={box} top={rowToPx(1.5)} left={columnToPx(3)} />

      <TrackingState checkpoint="FAILED_PREP" image={failedPrep} top={rowToPx(1)} left={columnToPx(4)} />
      <TrackingState checkpoint="SHIPPED_TO_FARM" image={truck} top={rowToPx(2)} left={columnToPx(4)} />

      <TrackingState checkpoint="ARRIVED_AT_FARM" image={farm} top={rowToPx(2)} left={columnToPx(5)} />

      <TrackingState checkpoint="RETURNED_TO_FT" image={ft} top={rowToPx(1)} left={columnToPx(6)} />
      <TrackingState checkpoint="RETURNED_TO_HALTER" image={office} top={rowToPx(2)} left={columnToPx(6)} />

      <TrackingState
        checkpoint="RETURNED_TO_IAC"
        text="Returned to IAC"
        image={factory}
        top={rowToPx(1)}
        left={columnToPx(7)}
      />

      <TrackingState checkpoint="SCRAPPED" image={bin} top={rowToPx(0)} left={columnToPx(6)} />

      <TrackingState checkpoint="MALROSE_MISC" image={malrose} top={rowToPx(3)} left={columnToPx(6)} />

      <TrackingState checkpoint="RETURNS_STORAGE" image={storage} top={rowToPx(2)} left={columnToPx(7)} />

      <TrackingState checkpoint="OFFICE_MISC" image={office} top={rowToPx(3)} left={columnToPx(7)} />

      <TrackingState checkpoint="UNKNOWN" image={unknown} top={rowToPx(0)} left={columnToPx(7)} />

      <DiagramArrow left={arrowColumnToPx(0)} top={arrowRowToPx(0.5)} angle={-45} />
      <DiagramArrow left={arrowColumnToPx(0)} top={arrowRowToPx(1)} angle={0} />
      <DiagramArrow left={arrowColumnToPx(0)} top={arrowRowToPx(1.5)} angle={45} />
      <DiagramArrow left={arrowColumnToPx(1)} top={arrowRowToPx(1)} />
      <DiagramArrow left={arrowColumnToPx(2)} top={arrowRowToPx(0.8)} angle={-45} />
      <DiagramArrow left={arrowColumnToPx(2)} top={arrowRowToPx(1.2)} angle={45} />
      <DiagramArrow left={arrowColumnToPx(3)} top={arrowRowToPx(1.3)} angle={-45} />
      <DiagramArrow left={arrowColumnToPx(3)} top={arrowRowToPx(1.7)} angle={45} />
      <DiagramArrow left={arrowColumnToPx(4)} top={arrowRowToPx(2)} />
      <DiagramArrow left={arrowColumnToPx(5)} top={arrowRowToPx(1.5)} angle={-45} />
      <DiagramArrow left={arrowColumnToPx(5)} top={arrowRowToPx(2)} />
      <DiagramArrow left={arrowColumnToPx(5)} top={arrowRowToPx(2.5)} angle={45} />
      <DiagramArrow left={arrowColumnToPx(6)} top={arrowRowToPx(1.5)} angle={-45} />
      <DiagramArrow left={arrowColumnToPx(6)} top={arrowRowToPx(1)} />
      <DiagramArrow left={arrowColumnToPx(6)} top={arrowRowToPx(2)} />
      <DiagramArrow left={arrowColumnToPx(6)} top={arrowRowToPx(3)} />
    </Wrapper>
  );
};
export default FleetTrackingDiagram;
