import React, { forwardRef } from 'react';
import { FormControl, InputLabel, Input as BaseInput, InputProps } from '@material-ui/core';

type InputFieldProps = InputProps & {
  label: string;
  value: string | number | boolean;
  withMargin?: boolean;
};

type Input = typeof BaseInput;

const InputField = forwardRef<Input, InputFieldProps>(({ label, withMargin = true, value, ...rest }, ref) => (
  <FormControl style={{ display: 'flex', flex: 1, margin: withMargin ? 8 : 0 }}>
    <InputLabel>{label}</InputLabel>
    <BaseInput ref={ref} value={value} {...rest} />
  </FormControl>
));

export default InputField;
