import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';
import { ICattleCowgSettingsDTO } from '@halter-corp/settings-service-client';

export const profileTypeToCowgSettingsType: Record<TrainingSettingsTypeEnum, keyof ICattleCowgSettingsDTO> = {
  [TrainingSettingsTypeEnum.HOLD_IN_ZONE]: 'holdInZone',
  [TrainingSettingsTypeEnum.ENTER_PADDOCK]: 'enterPaddock',
  [TrainingSettingsTypeEnum.EXIT_PADDOCK]: 'exitPaddock',
  [TrainingSettingsTypeEnum.EXIT_PADDOCK_DRAFTING]: 'exitPaddockDrafting',
  [TrainingSettingsTypeEnum.BREAK_TO_BREAK]: 'breakToBreak',
  [TrainingSettingsTypeEnum.RACE_NAVIGATION]: 'raceNavigation',
  [TrainingSettingsTypeEnum.HOLD_IN_ZONE_CROP]: 'holdInZoneCrop',
  [TrainingSettingsTypeEnum.BREAK_TO_BREAK_CROP]: 'breakToBreakCrop',
  [TrainingSettingsTypeEnum.EXIT_PADDOCK_CROP]: 'exitPaddockCrop',
  [TrainingSettingsTypeEnum.SHARED_BOUNDARY_TRANSITION]: 'sharedBoundaryTransition',
  [TrainingSettingsTypeEnum.ENTER_SHARED_BOUNDARY]: 'enterSharedBoundary',
};
