import React, { FunctionComponent, useMemo } from 'react';

import { CowWithAppliedModifiers } from 'data/training';

import DataTable, { Column } from 'application/components/data-table';
import TrainingService from 'services/training.service';
import { toArray, flatten } from 'lodash';
import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';
import { prettyPrintProfileType } from '../utils';

type AppliedModifiersTableProps = {
  includeUntaggedCattle?: boolean;
};

const AppliedModifiersTable: FunctionComponent<AppliedModifiersTableProps> = ({
  includeUntaggedCattle = false,
}) => {
  const cowsWithModifiers = TrainingService.useSelectAppliedModifiers();

  const data = useMemo(
    () =>
      includeUntaggedCattle ? cowsWithModifiers : cowsWithModifiers.filter((cow) => cow.serialNumber != null),
    [cowsWithModifiers, includeUntaggedCattle]
  );

  return (
    <DataTable<CowWithAppliedModifiers>
      data={data}
      defaultSortColumnName="Cow"
      columns={[
        {
          name: 'Cow',
          keyExtractor: (cowWithAppliedModifiers) =>
            Number.isNaN(Number(cowWithAppliedModifiers.cattleName)) ||
            cowWithAppliedModifiers.cattleName?.split('')[0] === '0'
              ? cowWithAppliedModifiers.cattleName
              : Number(cowWithAppliedModifiers.cattleName),
          render: (cowWithAppliedModifier) => cowWithAppliedModifier.cattleName,
          allowCopyToClipboard: true,
        },
        {
          name: 'Serial number',
          keyExtractor: (cowWithAppliedModifiers) => cowWithAppliedModifiers.serialNumber,
          render: (cowWithAppliedModifiers) => cowWithAppliedModifiers.serialNumber,
          allowCopyToClipboard: true,
        },
        {
          name: 'Mob',
          keyExtractor: (cowWithAppliedModifiers) => cowWithAppliedModifiers.mobName,
          render: (cowWithAppliedModifiers) => cowWithAppliedModifiers.mobName,
        },
        ...flatten(
          toArray(TrainingSettingsTypeEnum).map<Column<CowWithAppliedModifiers>[]>((type) => [
            {
              name: `${prettyPrintProfileType(type)} modifiers`,
              render: (cowWithAppliedModifiers) => {
                const modifiersForType = cowWithAppliedModifiers.cowAppliedModifiers.modifiers.filter(
                  (modifier) => modifier.type === type
                );
                const sortedModifiers = modifiersForType.sort((item1, item2) =>
                  item1.priority < item2.priority ? 1 : -1
                );
                return (
                  <div>
                    {sortedModifiers.map((modifier) => (
                      <div
                        style={{
                          marginBottom: '8px',
                          backgroundColor: modifier.deletedAt != null ? '#ef5350' : 'transparent',
                        }}
                      >
                        {modifier.name}
                      </div>
                    ))}
                  </div>
                );
              },
              keyExtractor: (cowWithAppliedModifiers) =>
                cowWithAppliedModifiers.cowAppliedModifiers.modifiers
                  .filter((modifier) => modifier.type === type)
                  .map((item) => item.name)[0],
            },
          ])
        ),
      ]}
    />
  );
};

export default AppliedModifiersTable;
