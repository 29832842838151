import React, { FunctionComponent, useState } from 'react';

import { Modifier } from 'data/training';
import {
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Accordion,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ModifierTitle } from './farm-profiles';

type ModifiersTableProps = {
  modifiers: Modifier[];
  onPressRow: (modifier: Modifier) => void;
  title: string;
};

const ModifiersTable: FunctionComponent<ModifiersTableProps> = ({ modifiers, onPressRow, title }) => {
  const [hoveredOverModifierId, setHoveredOverModifierId] = useState<string | undefined>(undefined);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content">
        <ModifierTitle>{`${title} modifiers`}</ModifierTitle>
      </AccordionSummary>
      <TableContainer style={{ display: 'flex', flex: 1 }}>
        <Table style={{ display: 'flex', flex: 1 }}>
          <TableBody style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            {modifiers.map((modifier) => (
              <TableRow
                key={modifier.id}
                onClick={() => onPressRow(modifier)}
                style={{
                  display: 'flex',
                  flex: 1,
                  ...(hoveredOverModifierId === modifier.id
                    ? { backgroundColor: '#e7f0f6', cursor: 'pointer' }
                    : {}),
                }}
                onMouseEnter={() => setHoveredOverModifierId(modifier.id)}
                onMouseLeave={() => setHoveredOverModifierId(undefined)}
              >
                <TableCell style={{ display: 'flex', flex: 1 }}>{modifier.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Accordion>
  );
};

export default ModifiersTable;
