import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

type ModalButtonProps = {
  onPress: () => void;
  text: string;
  className?: string;
  style: React.CSSProperties;
  disabled?: boolean;
};

const ModalButton: FunctionComponent<ModalButtonProps> = ({
  onPress,
  text,
  className,
  style,
  disabled = false,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onPress();
    e.stopPropagation();
  };

  return (
    <Wrapper>
      <Button
        className={className}
        onClick={handleClick}
        style={{ ...style, borderRadius: 8, ...(disabled ? { opacity: 0.65 } : {}) }}
        disabled={disabled}
      >
        {text}
      </Button>
    </Wrapper>
  );
};

export default ModalButton;
