import React, { FunctionComponent, useEffect, useState, useRef, useMemo } from 'react';
import { Map as LeafletMap, TileLayer, withLeaflet } from 'react-leaflet';
import styled from 'styled-components';
import moment from 'moment';
import { Paper } from '@material-ui/core';

import TopographyService from 'services/topography.service';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import MeasureControlDefault from 'react-leaflet-measure';
import { IFeatureDTO } from '@halter-corp/topography-service-client';
import { LatLngBoundsExpression } from 'leaflet';
import { getCurrentFarm } from '../../../../../store/selectors/farm-context.selectors';
import DateRangeSlider from '../../components/date-range-slider';

import CattleLocationsLayer from './layers/cattle-locations.layer';
import CattleEventsLayer from './layers/cattle-events.layer';
import MapFilterPanel from './map-filter-panel';
import {
  CowgStatusEnum,
  findCattleEvent,
  findCattleLocationPaths,
  transitionCowgStatuses,
} from '../../../../../store/selectors/map-page-items.selectors';
import { AppState } from '../../../../../store';
import DeviceCommandsLayer from './layers/device-commands.layer';
import EventsSummaryPanel from './events-summary.panel';

// @ts-ignore

const measureOptions = {
  position: 'topleft',
  primaryLengthUnit: 'meters',
  secondaryLengthUnit: 'kilometers',
  primaryAreaUnit: 'sqmeters',
  secondaryAreaUnit: 'acres',
  activeColor: '#db4a29',
  completedColor: '#9b2d14',
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const BottomMapWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  z-index: 999; // keep above the mapping layer
`;

const OtherMapWrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 4rem;
  z-index: 999; // keep above the mapping layer
  display: flex;
  flex-direction: row;
`;

export type MapPanelProps = {
  activeDateRange: [Date, Date] | null;
  searchDateRange: [Date, Date] | null;
};

const MapPanel: FunctionComponent<MapPanelProps> = ({ activeDateRange }) => {
  const [scrubbedDateRange, setScrubbedDateRange] = useState(activeDateRange);
  const MeasureControl = withLeaflet(MeasureControlDefault);

  useEffect(() => setScrubbedDateRange(activeDateRange), [activeDateRange]);

  const mapRef = useRef<LeafletMap>(null);

  const currentFarmId = useSelector(getCurrentFarm);
  const metadata = TopographyService.useFarmMapMetadata(currentFarmId);

  const [datum, setDatum] = useState<IFeatureDTO | null>(null);
  useEffect(() => {
    setDatum(null);
    (async () => {
      const retrievedDatum = await TopographyService.fetchDatum(currentFarmId);
      setDatum(retrievedDatum);
    })();
  }, [currentFarmId]);

  const allCattlePaths = useSelector((state: AppState) => findCattleLocationPaths(state));
  const allCattleEvents = useSelector((state: AppState) => findCattleEvent(state));

  const allAvailableCows = useMemo(
    () => sortBy([...new Set(allCattlePaths.map((cattlePath) => cattlePath.cattleName))]),
    [allCattlePaths]
  );
  const allAvailableCowGStatuses = useMemo(
    () =>
      sortBy([
        ...new Set([...transitionCowgStatuses, ...allCattleEvents.flatMap((event) => event.cowgStatuses)]),
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transitionCowgStatuses, allCattleEvents]
  );

  const [selectedCows, setSelectedCows] = useState<string[] | null>(null);
  const [selectedCowGStatuses, setSelectedCowGStatuses] = useState<CowgStatusEnum[] | null>(null);

  const data: any = [];
  const [cattleEventsLayerData, setCattleEventsLayerData] = useState(data);
  const [selectedCowFromLocationPath, setSelectedCowFromLocationPath] = useState<string | null>(null);

  const boundsToCenterMapAgainstSearchPanelBetter: LatLngBoundsExpression | undefined = useMemo(() => {
    const northSouthOffset = -0.001;
    const eastWestOffset = 0.003;
    return metadata?.bounds.map((c) => [c[0] + northSouthOffset, c[1] + eastWestOffset]);
  }, [metadata]);

  if (metadata == null || datum == null) return null;

  return (
    <Wrapper>
      <LeafletMap
        ref={mapRef}
        preferCanvas
        center={datum.feature.geometry.coordinates}
        bounds={boundsToCenterMapAgainstSearchPanelBetter}
        maxZoom={metadata.zoom.maxZoom}
        style={{ flex: 1 }}
      >
        <TileLayer url={metadata.tileUrl} />
        {scrubbedDateRange != null && (
          <>
            <DeviceCommandsLayer selectedCows={selectedCows} scrubbedDateRange={scrubbedDateRange} />
            <CattleLocationsLayer
              selectedCows={selectedCows}
              scrubbedDateRange={scrubbedDateRange}
              onSelectedCow={setSelectedCowFromLocationPath}
            />
            <CattleEventsLayer
              onChangeThing={setCattleEventsLayerData}
              selectedCows={selectedCows}
              scrubbedDateRange={scrubbedDateRange}
              selectedCowGStatuses={selectedCowGStatuses || allAvailableCowGStatuses}
            />
            <MeasureControl {...measureOptions} />
          </>
        )}
      </LeafletMap>
      <OtherMapWrapper>
        {allCattleEvents.length > 0 && <EventsSummaryPanel cattleEventsLayerData={cattleEventsLayerData} />}
        {selectedCowFromLocationPath != null && (
          <Paper
            style={{
              position: 'relative',
              left: '32px',
              padding: '0px 16px',
              marginTop: '4px',
              display: 'flex',
              alignItems: 'center',
              maxHeight: '32px',
            }}
          >
            Cow {selectedCowFromLocationPath}
          </Paper>
        )}
      </OtherMapWrapper>
      <BottomMapWrapper>
        {scrubbedDateRange != null && activeDateRange != null && (
          <DateRangeSlider
            dateRange={scrubbedDateRange}
            min={activeDateRange[0]}
            max={activeDateRange[1]}
            onChange={(event, value) => {
              if ((event as unknown as MouseEvent).shiftKey) {
                const startDateDifference = moment(value[0]).diff(moment(scrubbedDateRange[0]), 'seconds');
                const endDateDifference = moment(value[1]).diff(moment(scrubbedDateRange[1]), 'seconds');
                setScrubbedDateRange([
                  moment(value[0]).add(endDateDifference, 'seconds').toDate(),
                  moment(value[1]).add(startDateDifference, 'seconds').toDate(),
                ]);
              } else {
                setScrubbedDateRange(value);
              }
            }}
          />
        )}
        <MapFilterPanel
          selectedCows={selectedCows || allAvailableCows}
          selectedCowGStatuses={selectedCowGStatuses || allAvailableCowGStatuses}
          allAvailableCows={allAvailableCows}
          allAvailableCowgStatuses={allAvailableCowGStatuses}
          onSelectedCowsChange={setSelectedCows}
          onSelectedCowGStatusesChange={setSelectedCowGStatuses}
        />
      </BottomMapWrapper>
    </Wrapper>
  );
};

export default MapPanel;
