import {
  ICreateTowerFarmMappingRequestDTO,
  ICreateTowerRequestDTO,
  IInfraProductContextDTO,
  IInfraProductStatusDTO,
  ITowerDTO,
  ITowerFarmMappingDTO,
  ITowerMetadataDTO,
  IUpdateInfraProductContextRequestDTO,
  IUpdateInfraProductStatusRequestDTO,
  IUpdateTowerMetadataRequestDTO,
  IUpdateTowerRequestDTO,
} from '@halter-corp/infrastructure-service-client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import InfrastructureService from 'services/infrastructure.service';

const infrastructureEffects = {
  fetchAllInfraProductContextsByTowerIdList: createAsyncThunk(
    'infrastructure/fetchAllInfraProductContextsByTowerIdList',
    async (props: { towerIdList: string[] }): Promise<IInfraProductContextDTO[]> => {
      const { towerIdList } = props;

      const infraProductContexts: IInfraProductContextDTO[] = [];
      await Promise.all(
        towerIdList.map(async (Id) => {
          const result = await InfrastructureService.fetchInfraProductContextByTowerId(Id);
          infraProductContexts.push(...result);
        })
      );
      return infraProductContexts;
    }
  ),
  updateInfraProductContextById: createAsyncThunk(
    'infrastructure/updateInfraProductContextById',
    async (props: {
      id: string;
      updateInfraProductContextRequest: IUpdateInfraProductContextRequestDTO;
    }): Promise<IInfraProductContextDTO | null> => {
      const { id, updateInfraProductContextRequest } = props;
      return InfrastructureService.updateInfraProductContextById(id, updateInfraProductContextRequest);
    }
  ),
  updateAllInfraProductContextTowerIdByGroupId: createAsyncThunk(
    'infrastructure/updateAllInfraProductContextTowerIdByGroupId',
    async (props: { groupId: string; towerId: string }): Promise<IInfraProductContextDTO[]> => {
      const { groupId, towerId } = props;
      return InfrastructureService.updateAllInfraProductContextTowerIdByGroupId(groupId, towerId);
    }
  ),
  updateDeviceStatusById: createAsyncThunk(
    'infrastructure/updateDeviceStatusById',
    async (props: {
      id: string;
      updatedInfraProductStatusRequest: IUpdateInfraProductStatusRequestDTO;
    }): Promise<IInfraProductStatusDTO | null> => {
      const { id, updatedInfraProductStatusRequest } = props;
      return InfrastructureService.updateInfraProductStatusById(id, updatedInfraProductStatusRequest);
    }
  ),
  fetchTowers: createAsyncThunk(
    'infrastructure/fetchTowers',
    async (): Promise<ITowerDTO[]> => InfrastructureService.fetchTowers()
  ),
  fetchTowersByFarmId: createAsyncThunk(
    'infrastructure/fetchTowersByFarmId',
    async (props: { farmId: string }): Promise<ITowerDTO[]> => {
      const { farmId } = props;
      return InfrastructureService.fetchTowersByFarmId(farmId);
    }
  ),
  fetchTowersByLatLngAndRadius: createAsyncThunk(
    'infrastructure/fetchTowersByLatLngAndRadius',
    async (props: { latitude: number; longitude: number; radius: number }): Promise<ITowerDTO[]> => {
      const { latitude, longitude, radius } = props;
      return InfrastructureService.fetchTowersByLatLngAndRadius(latitude, longitude, radius);
    }
  ),
  createTower: createAsyncThunk(
    'infrastructure/createTower',
    async (props: { createTowerRequest: ICreateTowerRequestDTO }): Promise<ITowerDTO | null> => {
      const { createTowerRequest } = props;
      return InfrastructureService.createTower(createTowerRequest);
    }
  ),
  updateTowerById: createAsyncThunk(
    'infrastructure/updateTowerById',
    async (props: { id: string; updateTowerRequest: IUpdateTowerRequestDTO }): Promise<ITowerDTO | null> => {
      const { id, updateTowerRequest } = props;
      return InfrastructureService.updateTowerById(id, updateTowerRequest);
    }
  ),
  deleteTowerById: createAsyncThunk(
    'infrastructure/deleteTowerById',
    async (props: { id: string }): Promise<string | null> => {
      const { id } = props;
      await InfrastructureService.deleteTowerById(id);
      return id;
    }
  ),
  updateTowerMetadataById: createAsyncThunk(
    'infrastructure/updateTowerMetadataById',
    async (props: {
      id: string;
      updateTowerMetadataRequest: IUpdateTowerMetadataRequestDTO;
    }): Promise<ITowerMetadataDTO | null> => {
      const { id, updateTowerMetadataRequest } = props;
      return InfrastructureService.updateTowerMetadataById(id, updateTowerMetadataRequest);
    }
  ),
  createTowerFarmMapping: createAsyncThunk(
    'infrastructure/createTowerFarmMapping',
    async (props: {
      createTowerFarmMappingRequest: ICreateTowerFarmMappingRequestDTO;
    }): Promise<ITowerFarmMappingDTO | null> => {
      const { createTowerFarmMappingRequest } = props;
      return InfrastructureService.createTowerFarmMapping(createTowerFarmMappingRequest);
    }
  ),
  deleteTowerFarmMappingByFarmIdAndTowerId: createAsyncThunk(
    'infrastructure/deleteTowerFarmMappingByFarmIdAndTowerId',
    async (props: { farmId: string; towerId: string }): Promise<boolean> => {
      const { farmId, towerId } = props;
      return InfrastructureService.deleteTowerFarmMappingByFarmIdAndTowerId(farmId, towerId);
    }
  ),
};

export default infrastructureEffects;
