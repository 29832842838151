import React, { FunctionComponent } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { useDispatch } from 'store';
import farmStatusEffects from 'store/effects/farm-status.effects';

import FarmStatusDialog from './farm-status.dialog';
import { FarmStatusMode } from '../utils';
import { CaddieColours } from '../../../theme/colours';

const useStyles = makeStyles(() => ({
  dialogContent: {
    fontWeight: 'bold',
    display: 'inline',
  },
  dialogLink: {
    marginLeft: '3px',
    color: '#757575',
    textDecoration: 'underline',
    fontWeight: 'bold',
    display: 'inline',
  },
  dialogConfirmation: {
    fontWeight: 'bold',
    display: 'inline',
    textDecoration: 'underline',
  },
}));

type FarmActiveDialogProps = {
  farmStates: FarmState[];
  isDialogOpen: (isOpen: boolean) => void;
  clearSelectedFarms: () => void;
};

type FarmState = {
  farmId: string;
  farmName: string;
  status: FarmStatusMode;
};

const FarmActiveDialog: FunctionComponent<FarmActiveDialogProps> = ({
  farmStates,
  isDialogOpen,
  clearSelectedFarms,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleConfirm = async () => {
    try {
      const maintenanceModefarms = farmStates.filter((farm) => farm.status === FarmStatusMode.Maintenance);
      await Promise.all(
        maintenanceModefarms.map((farm) =>
          dispatch(farmStatusEffects.setMaintenanceOff({ farmId: farm.farmId }))
        )
      );

      const emergencyModeFarms = farmStates.filter((farm) => farm.status === FarmStatusMode.Emergency);
      await Promise.all(
        emergencyModeFarms.map((farm) => dispatch(farmStatusEffects.setEmergencyOff({ farmId: farm.farmId })))
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error when setting maintenance or emergency mode to active: ', e);
    } finally {
      isDialogOpen(false);
      clearSelectedFarms();
    }
  };

  const dialogContent = (
    <>
      Are you sure you want to turn on active mode for{' '}
      <div className={classes.dialogContent}>{farmStates.length} farms? </div>
      All app functionality will be enabled for the farmer and normal collar activity will be resumed. Any
      applied modes will be turned off.
      <Link
        className={classes.dialogLink}
        target="_blank"
        rel="noreferrer"
        href="https://www.notion.so/Local-Emergency-Release-1c65b976f4384d9ab3f53865df2beeef"
      >
        Learn more
      </Link>
    </>
  );

  const dialogConfirmation = (
    <>
      I have read and understand the <div className={classes.dialogConfirmation}>implications</div> of turning
      on Active mode.
    </>
  );

  return (
    <FarmStatusDialog
      farmStates={farmStates}
      isDialogOpen={isDialogOpen}
      dialogContent={dialogContent}
      farmStatus={FarmStatusMode.Active}
      handleConfirm={handleConfirm}
      dialogConfirmation={dialogConfirmation}
      color={CaddieColours.ActiveGreen}
      confirmationColor={CaddieColours.ActiveGreenConfirmation}
    />
  );
};

export default FarmActiveDialog;
