import { createEntityAdapter } from '@reduxjs/toolkit';
import { Cattle, WithMob } from 'data/cattle';
import {
  Profile,
  Modifier,
  FarmerTrainingGuide,
  FarmerTrainingGuideOptions,
  CowAppliedModifiers,
} from 'data/training';

const profileAdapter = createEntityAdapter<Profile>({
  selectId: (profile) => profile.id,
});

const modifierAdapter = createEntityAdapter<Modifier>({
  selectId: (modifier) => modifier.id,
});

const appliedModifiersAdapter = createEntityAdapter<CowAppliedModifiers>({
  selectId: (appliedModifiers) => appliedModifiers.cattleId,
});

const cowsToApplyToAdapter = createEntityAdapter<WithMob<Cattle>>({
  selectId: (cow) => cow.id,
});

const farmerTrainingGuideAdapter = createEntityAdapter<FarmerTrainingGuide>({
  selectId: (farmerTrainingGuide) => farmerTrainingGuide.id,
});

const farmerTrainingGuideOptionsAdapter = createEntityAdapter<FarmerTrainingGuideOptions>({
  selectId: (farmerTrainingGuideOptions) => farmerTrainingGuideOptions.farmId,
});

export {
  profileAdapter,
  modifierAdapter,
  appliedModifiersAdapter,
  cowsToApplyToAdapter,
  farmerTrainingGuideAdapter,
  farmerTrainingGuideOptionsAdapter,
};
