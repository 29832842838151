import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import ReportDetailScreen from './screens/detail.screen';
import CommandHistoryScreen from './screens/command-history-screen';

const ReportsModule: FunctionComponent = () => (
  <Switch>
    <Route exact path="/reports">
      <CommandHistoryScreen />
    </Route>
    <Route exact path="/reports/:commandId">
      <ReportDetailScreen />
    </Route>
  </Switch>
);

export default ReportsModule;
