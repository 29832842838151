import React, { FunctionComponent } from 'react';
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import InfoBox from 'application/modules/reports/components/info.box';

type MetricsCardProps = {
  title: string;
  data: { [key: string]: string | number };
};

const MetricsCard: FunctionComponent<MetricsCardProps> = ({ title, data }) => (
  <Grid item xs={12} sm={6} lg={4}>
    <Card style={{ height: '100%' }}>
      <CardHeader title={title} />
      <CardContent>
        {Object.entries(data).map(([key, value]) => (
          <InfoBox key={key} large title={key}>
            {value}
          </InfoBox>
        ))}
      </CardContent>
    </Card>
  </Grid>
);

export default MetricsCard;
