import React, { FunctionComponent, useEffect } from 'react';
import { useParseQueryParameters } from 'application/hooks/use-parameters';
import { useDispatch } from 'react-redux';
import behaviourEffects from 'store/effects/behaviour.effects';
import cattleEffects from 'store/effects/cattle.effects';
import BehaviourService from 'services/behaviour.service';
import BehaviourScreen from '../screens/behaviour.screen';

const BehaviourContainer: FunctionComponent = () => {
  const { farmId } = useParseQueryParameters();
  const dispatch = useDispatch();
  const season = BehaviourService.useSelectSeason();

  useEffect(() => {
    dispatch(behaviourEffects.fetchHeats({ farmId }));
    dispatch(behaviourEffects.fetchMetricData({ farmId }));
    dispatch(behaviourEffects.fetchMetricsSummary({ farmId }));
    dispatch(behaviourEffects.fetchSeason({ farmId }));
    dispatch(cattleEffects.fetchCattle({ farmId }));
    dispatch(behaviourEffects.fetchCurrentHealthCases({ farmId }));
    dispatch(behaviourEffects.fetchHistoricalHealthCases({ farmId }));
    dispatch(behaviourEffects.fetchIsMindaIntegrated({ farmId }));
    dispatch(behaviourEffects.fetchCalvingInfo({ farmId }));
    dispatch(behaviourEffects.fetchCalvingSummary({ farmId }));

    if (season?.id == null) return;
    dispatch(behaviourEffects.fetchInterventions({ seasonId: season.id, farmId }));
  }, [farmId, season?.id]);

  return <BehaviourScreen />;
};

export default BehaviourContainer;
