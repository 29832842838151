import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

export const useDeepEffect = (fn: Function, deps: any[]) => {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const isFirstEffect = isFirst.current;
    const isSame = isEqual(prevDeps.current, deps);

    isFirst.current = false;
    prevDeps.current = deps;

    if (isFirstEffect || !isSame) {
      return fn();
    }
  }, deps);
};
