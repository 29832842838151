import React, { FC } from 'react';
import { ExistingTowersContextProvider } from '../contexts/existing-tower-context';
import { InvalidInputContextProvider } from '../contexts/invalid-input-context';
import { ModifiedTowersContextProvider } from '../contexts/modified-tower-context';
import { NewTowersContextProvider } from '../contexts/new-tower-context';
import { ExternalTowersContextProvider } from '../contexts/external-tower-context';

const InstallPlannerContextWrapper: FC = ({ children }) => (
  <ExternalTowersContextProvider>
    <ExistingTowersContextProvider>
      <NewTowersContextProvider>
        <ModifiedTowersContextProvider>
          <InvalidInputContextProvider>{children}</InvalidInputContextProvider>
        </ModifiedTowersContextProvider>
      </NewTowersContextProvider>
    </ExistingTowersContextProvider>
  </ExternalTowersContextProvider>
);

export default InstallPlannerContextWrapper;
