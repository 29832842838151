import { createEntityAdapter } from '@reduxjs/toolkit';
import { IMobDTO } from '@halter-corp/cattle-service-client';
import { AllMobs } from 'data/cattle/cattle';

const mobAdapter = createEntityAdapter<IMobDTO>({
  selectId: (mob) => mob.id,
});

const allFarmsMobsAdapter = createEntityAdapter<AllMobs>({
  selectId: (allFarmsMobs) => allFarmsMobs.farmId,
});

export { mobAdapter, allFarmsMobsAdapter };
