import React, { FunctionComponent } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

type HeaderProps = TypographyProps & {
  text: string;
  size: 'small' | 'medium' | 'large';
};

const Header: FunctionComponent<HeaderProps> = ({ text, size, style, ...rest }) => {
  let headerStyle = {};
  if (size === 'medium') {
    headerStyle = { fontSize: '20px', fontWeight: 700 };
  } else if (size === 'large') {
    headerStyle = { fontSize: '24px', fontWeight: 700 };
  }

  return (
    <Typography style={{ ...headerStyle, ...style }} {...rest}>
      {text}
    </Typography>
  );
};

export default Header;
