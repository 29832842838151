import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import { ICreateTrainingModifierRequestDTO } from '@halter-corp/training-service-client';

import { useDispatch } from 'store';
import trainingEffects from 'store/effects/training.effects';

import ReactGA from 'react-ga';

import ScreenWrapper from 'application/components/screen-wrapper';
import ModifierForm from '../forms/profile/modifier.form';

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  opacity: 0.9;
  padding: 12px;
`;

const CreateModifierScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (request: ICreateTrainingModifierRequestDTO) => {
    setIsSubmitting(true);
    const result = await dispatch(trainingEffects.createModifier({ request }));

    setIsSubmitting(false);
    if (trainingEffects.createModifier.fulfilled.match(result)) {
      history.push('/training/profiles');
    }

    ReactGA.event({
      category: 'User action',
      action: 'Create new modifier button pressed',
    });
  };

  return (
    <ScreenWrapper>
      <Grid item xs={12} sm={10} lg={8}>
        <Title>Create new modifier</Title>
        <ModifierForm onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </Grid>
    </ScreenWrapper>
  );
};

export default CreateModifierScreen;
