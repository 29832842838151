import { ICattleDTO } from '@halter-corp/cattle-service-client';
import { ICowtrollerDeviceStateDTO } from '@halter-corp/cowtroller-service-client';
import { IDeviceDTO } from '@halter-corp/device-service-client';
import { IAlarmDTO } from '@halter-corp/collar-alarm-service-client';

import {
  CommandSyncDetails,
  MapFeaturesSyncDetails,
  SettingsDetail,
} from 'services/device-detail-page.service';

export interface Device extends Omit<IDeviceDTO, 'updatedDate' | 'createdDate'> {
  mobName?: string;
  cattle?: ICattleDTO;
  settingsV2?: SettingsDetail;
  mapFeatures?: MapFeaturesSyncDetails;
  stats?: DeviceStats;
  commands?: CommandSyncDetails;
  alarms?: IAlarmDTO[];
}

export const BuiltInTestStatusWhitelist = ['BIT_COLLAR_BACKWARD'];

export type BuiltInTestStatus = {
  isFaulty?: boolean;
  faults?: string[];
  magCalPercentage?: number;
};

export type DeviceStateReport = {
  mapFeatureHash?: number;
  settingsV2Hash?: number;
  commandSetHash?: IOptionalNumber;
  commandHash?: number;
  timestamp?: number;
  lastUpdate?: Date;
};

export type DeviceLocation = {
  hdop?: number;
  latitude?: number;
  longitude?: number;
  numSatellites?: number;
  fixAge?: number;
  lastUpdate?: Date;
  fixType?: number;
  timestamp?: Date;
  satelliteCnrDbHz?: number;
};

export type PowerGeneration = {
  batteryVoltageMv?: number;
  batterySocPercent?: number;
  uptime?: number;
  timestamp?: Date;
  lastUpdate?: Date;
};

export type PowerUsage = {
  batteryVoltageMv?: number;
  lastUpdate?: Date;
};

export type LastCommandStatus = {
  commandId?: number;
  commandType?: number;
  inZone?: boolean;
  controlDisabledReasons?: string[];
  lastUpdate?: Date;
  zoneStatusContext?: any;
};

export type WifiCheckinStatus = {
  bssid?: string;
  rssi?: number;
  batteryMv?: number;
  latitude?: number;
  longitude?: number;
  firmwareVersion?: string;
  bootLoaderVersion?: string;
  coreUptimeS?: number;
  gpsFirmwareVersion?: string;
  uptimeS?: number;
  coreBuiltInTestStatus?: number;
  lastUpdate?: Date;
  lastRetrieval: Date;
};

export type IDeviceLocationDTO = {};

export type CowgEventStreamDTO = {
  timestamp: number;
  commandId: number;
  cowgStatus: string[];
};

export type IOptionalNumber = {
  value?: number;
};

export type IDeviceStateReportDTO = {
  mapFeatureHash?: number;
  settingsV2Hash?: number;
  commandSetHash?: IOptionalNumber;
  commandHash?: number;
  timestamp?: number;
  lastUpdate?: string;
};

export type DeviceStats = {
  batteryVoltage?: number | undefined;
  batteryPercentage?: number | undefined;
  powerGeneration?: PowerGeneration;
  lastCommandStatus?: LastCommandStatus;
  loraCheckinStatus?: LoraCheckinStatus;
  builtInTestStatus?: BuiltInTestStatus;
  powerUsage?: PowerUsage;
  deviceLocation?: DeviceLocation;
  wifiCheckinStatus?: WifiCheckinStatus;
  deviceStateReport?: DeviceStateReport;
  cowgEventStream?: CowgEventStreamDTO;
};

export type LoraCheckinStatus = {
  rssi?: number;
  lastUpdate?: Date;
  lastRetrieval: Date;
};

export interface CowtrollerDeviceState extends ICowtrollerDeviceStateDTO {
  cattleId?: string;
}
