import React, { FunctionComponent } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import Button from 'application/components/button';
import { IDeviceControlMethodEnum, IUpdateDeviceSettingsRequestDTO } from '@halter-corp/tower-service-client';
import { useDispatch } from 'store';
import towerEffects from 'store/effects/tower.effects';
import styled from 'styled-components';
import TowerService from 'services/tower.service';
import { DeviceActionDialogProps } from '../DevicesActionsWrapper';

const DialogButtonWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 16px;
  padding: 8px;
`;

const ForceOffDialog: FunctionComponent<DeviceActionDialogProps> = ({ open, onClose, targets }) => {
  const dispatch = useDispatch();
  function handleRequest(target: string, value: boolean) {
    const updateSettingsRequest: IUpdateDeviceSettingsRequestDTO = {
      forceOff: value,
    };
    dispatch(
      towerEffects.updateDeviceSettingsById({ id: target, updateDeviceSettingsDto: updateSettingsRequest })
    );
    if (value === true) {
      const suspendUntil = Math.floor(Date.now() / 1000) + 1800;
      TowerService.updateSuspendUntilByGatewayId(target, suspendUntil, IDeviceControlMethodEnum.PORT_TOGGLE);
    }
    onClose();
  }

  function handleOnClick(value: boolean) {
    targets.forEach((target) => handleRequest(target, value));
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Set Gateway Force Off</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography style={{ color: '#d50000' }}>WARNING:</Typography> If this is enabled the gateway
          {targets.length > 1 ? 's' : ''} will be immediately forced off until this setting is disabled.
          Gateway{targets.length > 1 ? 's' : ''} will still turn on for shifts.
        </DialogContentText>
        <List>
          {targets.map((gatewayId) => (
            <ListItem>{gatewayId}</ListItem>
          ))}
        </List>
        <DialogButtonWrapper>
          <Button text="Disable Force Off" onClick={() => handleOnClick(false)} />
          <Button backgroundColour="#d50000" text="Enable Force Off" onClick={() => handleOnClick(true)} />
        </DialogButtonWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default ForceOffDialog;
