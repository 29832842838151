import { entries } from 'lodash';
import { flatten, unflatten } from 'flat';

import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';
import { ICattleCowgSettingsDTO } from '@halter-corp/settings-service-client';

import { Modifier, Profile } from 'data/training';
import { SettingsMetadataProps, SettingMetadata } from 'data/settings';

import { ModifierFormValues, ProfileFormValues } from './profile.form.types';
import { profileTypeToCowgSettingsType } from './constants';

const defaultProfileValues: Omit<ProfileFormValues, 'settings'> = {
  farmId: undefined,
  name: '',
  type: TrainingSettingsTypeEnum.HOLD_IN_ZONE,
};

const defaultModifierValues: Omit<ModifierFormValues, 'settings'> = {
  farmId: undefined,
  name: '',
  priority: 50,
  type: TrainingSettingsTypeEnum.HOLD_IN_ZONE,
};

export const deriveInitialProfileValues = (params: {
  profile?: Profile;
  defaults: ICattleCowgSettingsDTO;
}): ProfileFormValues => {
  const { profile, defaults } = params;

  if (profile == null) {
    return {
      ...defaultProfileValues,
      settings: (defaults.holdInZone ?? {}) as {
        [key: string]: number | boolean | object | undefined;
      },
    };
  }

  const flatDefault: Object = flatten(defaults[profileTypeToCowgSettingsType[profile.type]]);
  const flatOverridenProfile: Object = flatten(profile.settings);

  return {
    ...profile,
    settings: unflatten({ ...flatDefault, ...flatOverridenProfile }) as {
      [key: string]: number | boolean | object | undefined;
    },
    farmId: profile.farmId ?? undefined,
  };
};

export const deriveInitialModifierValues = (params: {
  modifier?: Modifier;
  defaults: ICattleCowgSettingsDTO;
}): ModifierFormValues => {
  const { modifier, defaults } = params;

  if (modifier == null) {
    return {
      ...defaultModifierValues,
      settings: (defaults.holdInZone ?? {}) as {
        [key: string]: number | boolean | object | undefined;
      },
    };
  }

  const flatDefault: Object = flatten(defaults[profileTypeToCowgSettingsType[modifier.type]]);
  const flatOverridenModifiers: Object = flatten(modifier.settings);

  return {
    ...modifier,
    settings: unflatten({ ...flatDefault, ...flatOverridenModifiers }) as {
      [key: string]: number | boolean | object | undefined;
    },
    farmId: modifier.farmId ?? undefined,
  };
};

export const getDefaults = <T extends { [K in keyof T]: T[K] }>(
  settingsMetadata: SettingsMetadataProps<T>
): Required<T> =>
  entries<SettingMetadata<T[keyof T]>>(settingsMetadata).reduce(
    (previous, [key, metadata]) => ({ ...previous, [key]: metadata.default }),
    {}
  ) as Required<T>;
