import React, { FunctionComponent, useEffect, useState } from 'react';
import { intersection } from 'lodash';
import { DeviceWithMetadata } from 'store/selectors/tower.selectors';
import { ProductTypeEnum } from '@halter-corp/discovery-service-client';
import ResetPortsDialog from './action-dialogs/ResetPortsDialog';
import ActionWrapper from './action-dialogs/ActionWrapper';
import EnableDialog from './action-dialogs/EnableDialog';
import DisableDialog from './action-dialogs/DisableDialog';
import AssignDevicesDialog from './action-dialogs/AssignDevicesDialog';
import ForceOnDialog from './action-dialogs/ForceOnDialog';
import ForceOffDialog from './action-dialogs/ForceOffDialog';

type DevicesActionsWrapperProps = {
  selectedDevices: Map<string, DeviceWithMetadata>;
  searchMode: 'farm' | 'shard';
};

export type DeviceActionDialogProps = {
  open: boolean;
  onClose: () => void;
  targets: string[];
  devices: DeviceWithMetadata[];
};

const DevicesActionsWrapper: FunctionComponent<DevicesActionsWrapperProps> = ({
  selectedDevices,
  searchMode,
}) => {
  const [devices, setDevices] = useState<DeviceWithMetadata[]>([]);

  useEffect(() => {
    setDevices([...selectedDevices.values()]);
  }, [selectedDevices]);

  const deviceActions: { [key: string]: React.FunctionComponent<DeviceActionDialogProps> } = {
    'Reset Ports': ResetPortsDialog,
    Enable: EnableDialog,
    Disable: DisableDialog,
    'Force On': ForceOnDialog,
    'Force Off': ForceOffDialog,
  };

  if (searchMode === 'farm') deviceActions.Reassign = AssignDevicesDialog;

  function getAvailableDeviceActions(towerDevice: DeviceWithMetadata): string[] {
    switch (towerDevice.type) {
      case ProductTypeEnum.GATEWAY:
        return ['Enable', 'Disable', 'Reassign', 'Force On', 'Force Off'];
      case ProductTypeEnum.SOLAR_SWITCH:
        return ['Reset Ports', 'Enable', 'Disable', 'Reassign'];
      case ProductTypeEnum.TOWER_NODE:
        return ['Enable', 'Disable'];
      case ProductTypeEnum.ROUTER:
        return ['Enable', 'Disable'];
      default:
        return [];
    }
  }

  function getAvailableActions(deviceArr: DeviceWithMetadata[]): string[] {
    const allDeviceActions = deviceArr.map((device) => getAvailableDeviceActions(device));

    return intersection(...allDeviceActions);
  }

  const availableActions = getAvailableActions(devices);

  return (
    <>
      {Object.entries(deviceActions).map(([actionName, component]) => (
        <ActionWrapper
          key={actionName}
          disabled={!availableActions.includes(actionName)}
          actionName={actionName}
          component={component}
          devices={devices}
        />
      ))}
    </>
  );
};

export default DevicesActionsWrapper;
