import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

type TowerFormSelectProps = {
  id: string;
  displayName: string;
  fieldName: string;
  handleChange: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  value: boolean | undefined;
};

const TowerFormSelect: FunctionComponent<TowerFormSelectProps> = ({
  id,
  displayName,
  fieldName,
  handleChange,
  value,
}) => (
    <FormControl>
      <InputLabel htmlFor={`${id}-${fieldName}`}>{displayName}</InputLabel>
      <Select id={`${id}-${fieldName}`} name={`${fieldName}`} onChange={handleChange} value={value}>
        <MenuItem value={true as any}>True</MenuItem>
        <MenuItem value={false as any}>False</MenuItem>
      </Select>
    </FormControl>
  );

export default TowerFormSelect;
