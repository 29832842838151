import { Dialog, DialogTitle } from '@material-ui/core';
import Button from 'application/components/button';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type DeleteConfirmationDialogProps = {
  open: boolean;
  itemName: string;
  onClose: () => void;
  onDelete: () => void;
};

const DialogContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 8px;
`;

const DeleteDialog: FunctionComponent<DeleteConfirmationDialogProps> = ({
  open,
  onClose,
  onDelete,
  itemName,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Are you sure you want to delete {itemName}?</DialogTitle>
    <DialogContent>
      <Button color="secondary" text="Delete" backgroundColour="#d50000" onClick={onDelete} />
      <Button color="primary" text="Cancel" onClick={onClose} />
    </DialogContent>
  </Dialog>
);

export default DeleteDialog;
