import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { chain, groupBy, isEmpty, keyBy } from 'lodash';
import momenttz from 'moment-timezone';
import BugReportIcon from '@material-ui/icons/BugReport';
import CodeIcon from '@material-ui/icons/Code';
import { environment } from 'env-config';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  Typography,
  LinearProgress,
  Grid,
  TablePagination,
} from '@material-ui/core';

import { IFeatureDTO } from '@halter-corp/topography-service-client';

import FarmSelect from 'application/components/farm-select';

import { useDispatch, useSelector } from 'store';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import ReportsService from 'services/reports.service';
import reportsEffects from 'store/effects/reports.effects';
import TopographyService from 'services/topography.service';
import topographyEffects from 'store/effects/topography.effects';
import MobService from 'services/mob.service';
import mobEffects from 'store/effects/mob.effects';
import { CommandTypeEnum, ICommandDTO } from '@halter-corp/cowtroller-service-client';
import InputField from 'application/modules/training/forms/components/input-field';
import moment from 'moment';
import HttpApiService from 'services/http-api.service';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import { prettyPrintOrigin, prettyPrintType } from '../utils';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 2rem;
  align-items: center;
`;

const CommandHistoryScreen: FunctionComponent = () => {
  const urlQueryString = new URLSearchParams(useLocation().search);
  const collarCommandIdFromQueryString = urlQueryString.get('collarCommandId') ?? '';

  const dispatch = useDispatch();
  const loading = ReportsService.useIsLoading();
  const commands = ReportsService.useAllReports();
  const paddocks = TopographyService.usePaddocks();
  const mobs = MobService.useFetchMobList();
  const currentFarm = useSelector(getCurrentFarm);
  const [farmId, setFarmId] = useState(currentFarm);
  const [page, setPage] = useState(0);
  const [paddockSearch, setPaddockSearch] = useState('');
  const [collarCommandIdSearch, setCollarCommandIdSearch] = useState(collarCommandIdFromQueryString);
  const [commandPayloads, setCommandPayloads] = useState<{ [collarCommandId: number]: string }>({});

  const commandsToShow = useMemo(() => {
    if (!isEmpty(collarCommandIdSearch))
      return commands.filter((command) => command.collarCommandId === parseInt(collarCommandIdSearch, 10));
    if (!isEmpty(paddockSearch)) {
      const paddocksById = keyBy(paddocks, (paddock) => paddock.id);
      return commands.filter(
        (command) => paddocksById[command.paddockId]?.feature.properties.name === paddockSearch
      );
    }
    return commands;
  }, [commands, paddocks, paddockSearch, collarCommandIdSearch]);

  const getFarmhandMapLink = (command: ICommandDTO, mapMode: string) => {
    let toDate: string = '';
    if (command.type === CommandTypeEnum.EXIT_ZONE) {
      if (moment.utc().isBefore(moment.utc(command.notCompletableUntil))) {
        toDate = new Date().toISOString();
      } else {
        toDate = moment.utc(command.notCompletableUntil).toISOString();
      }
    } else {
      const nextCommandForPaddock = chain(commands)
        .filter(
          (c) =>
            c.paddockId === command.paddockId &&
            c.collarCommandId > command.collarCommandId &&
            c.type !== CommandTypeEnum.DELETE_COMMANDS
        )
        .orderBy((c) => c.collarCommandId, 'asc')
        .value()[0];

      toDate =
        nextCommandForPaddock?.activationTime != null
          ? moment.utc(nextCommandForPaddock.activationTime).toISOString()
          : new Date().toISOString();
    }

    const searchParams = new URLSearchParams({
      farmId,
      minDate: command.createdAt as unknown as string,
      maxDate: toDate,
      searchMobIds: command.type === CommandTypeEnum.SET_ZONE ? (command as any).mobIds?.join(',') ?? '' : '',
      searchCollarCommandIds: command.collarCommandId.toString(),
      mapMode,
    });

    return `https://farmhand.${environment}.halter.io/map/debug?${searchParams.toString()}`;
  };

  const getDebugLink = (command: ICommandDTO) => getFarmhandMapLink(command, 'debug');
  const getHeatMapLink = (command: ICommandDTO) => getFarmhandMapLink(command, 'heatmap');

  const handleClickGetCommandPayload = (collarCommandId: number) => {
    (async () => {
      const api = await HttpApiService.getBffDebugToolApi();

      const esQuery =
        'eventType:"DeviceRequestResponseEvent" ' +
        'AND (payload.deviceRequestResponseEvent.payloadType:"COMMAND_REQUEST_TYPE"' +
        'OR payload.deviceRequestResponseEvent.payloadType:"COMMAND_REQUEST_SET_ZONE_V2_TYPE")' +
        ` AND payload.deviceRequestResponseEvent.responsePayload.bodyAsObject.id:"${collarCommandId}"`;
      const { data: deviceMetrics } = await api.getHistoryEvents(esQuery, undefined, 1);
      const event = deviceMetrics.items[0];
      const payload = event?.data?.payload?.deviceRequestResponseEvent?.requestPayload?.body;
      const bodyAsObject = event?.data?.payload?.deviceRequestResponseEvent?.requestPayload?.bodyAsObject;
      if (payload == null) return;
      setCommandPayloads((prev) => ({
        ...prev,
        [collarCommandId]: `${payload}\n${JSON.stringify(bodyAsObject)}`,
      }));
    })();
  };

  useEffect(() => {
    dispatch(reportsEffects.fetchAllReports({ pageSize: 200, page }));
  }, [dispatch, page, farmId]);

  useEffect(() => {
    dispatch(topographyEffects.fetchPaddocks({ farmId }));
    dispatch(mobEffects.fetch({ farmId }));
  }, [dispatch, farmId]);

  const paddocksById = groupBy(paddocks, (paddock: IFeatureDTO) => paddock.id);
  const mobsById = groupBy(mobs, (m) => m.id);

  return (
    <>
      <LinearProgress variant={loading ? 'query' : 'determinate'} value={100} />
      <Wrapper>
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Command history
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FarmSelect
              onChange={(selectedFarmId) => {
                setFarmId(selectedFarmId);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              label="Paddock search (must be exact name)"
              value={paddockSearch}
              onChange={(e) => setPaddockSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              label="Collar Command id search (must be exact name)"
              value={collarCommandIdSearch}
              onChange={(e) => setCollarCommandIdSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Paddock</TableCell>
                      <TableCell align="right">Command ID</TableCell>
                      <TableCell>Command Type</TableCell>
                      <TableCell>Command Origin</TableCell>
                      <TableCell align="right">Assigned mobs</TableCell>
                      <TableCell align="right">Activation time</TableCell>
                      <TableCell align="right">Debug</TableCell>
                      <TableCell align="right">Heatmap</TableCell>
                      <TableCell align="right">Payload</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commandsToShow.map((command) => (
                      <TableRow hover key={command.id}>
                        <TableCell align="right" title={command.paddockId}>
                          {paddocksById[command.paddockId]?.map((paddock) => paddock.feature.properties.name)}
                        </TableCell>
                        <TableCell align="right">{command.collarCommandId}</TableCell>
                        <TableCell component="th" scope="row">
                          {prettyPrintType(command.type)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {prettyPrintOrigin(command)}
                        </TableCell>
                        <TableCell align="right">
                          {command.mobIds
                            ?.map((mobId) => mobsById[mobId]?.map((m) => m.name) || mobId)
                            .join(', ')}
                        </TableCell>
                        <TableCell align="right">
                          {momenttz
                            .tz(command.activationTime, 'Pacific/Auckland')
                            .format('dddd DD/MM/YYYY HH:mm:ss')}
                        </TableCell>
                        <TableCell align="right">
                          {command.activationTime != null && command.type !== CommandTypeEnum.CLEAR_STATE && (
                            <a href={getDebugLink(command)} target="_blank" rel="noopener noreferrer">
                              <BugReportIcon style={{ color: '#009e60' }} />
                            </a>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {command.activationTime != null && command.type !== CommandTypeEnum.CLEAR_STATE && (
                            <a href={getHeatMapLink(command)} target="_blank" rel="noopener noreferrer">
                              <BlurOnIcon style={{ color: '#e35cff' }} />
                            </a>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {commandPayloads[command.collarCommandId] != null ? (
                            commandPayloads[command.collarCommandId]
                          ) : (
                            <CodeIcon
                              style={{ color: '#808080', cursor: 'pointer' }}
                              onClick={() => handleClickGetCommandPayload(command.collarCommandId)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[15]}
                        count={(page + 2) * 15}
                        onPageChange={(e, p) => {
                          setPage(p);
                        }}
                        page={page}
                        rowsPerPage={15}
                        labelDisplayedRows={() => ''}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default CommandHistoryScreen;
