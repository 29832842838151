import React, { FunctionComponent, useState } from 'react';
import { Grid, Modal, Typography, IconButton, TextField } from '@material-ui/core';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { IUpdateSeasonDTO } from '@halter-corp/cattle-breeding-service-client';
import BehaviourService from 'services/behaviour.service';
import Button from 'application/components/button';
import CattleService from 'services/cattle.service';
import { splitNumberList } from 'application/utils';
import { Info } from '@material-ui/icons';

const SEPARATOR = ',';

const ModalBody = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  margin: 16px;
`;

type ExcludeCattleFromSeasonModalProps = {
  seasonId: string;
  farmId: string;
  currentExcludedCattleIds: string[];
};

const ExcludeCattleFromSeasonModal: FunctionComponent<ExcludeCattleFromSeasonModalProps> = ({
  seasonId,
  farmId,
  currentExcludedCattleIds,
}) => {
  const [open, setOpen] = useState(false);
  const [cattleNames, setCattleNames] = useState(['']);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCattleNames(['']);
    setOpen(false);
  };

  const fetchCattleIds = async (): Promise<string[] | undefined> => {
    if (farmId == null || cattleNames == null) return undefined;
    const allCattle = await CattleService.fetchCattleByName(cattleNames, farmId);
    return allCattle.map((item) => item.id);
  };

  const updateSeason = async () => {
    setLoading(true);
    const cattleIds = await fetchCattleIds();
    if (cattleIds == null) return;
    const iUpdateSeasonDTO: IUpdateSeasonDTO = {
      excludedCattleIds: [...cattleIds, ...currentExcludedCattleIds],
    };
    await BehaviourService.updateSeason(seasonId, farmId, iUpdateSeasonDTO);
    setLoading(false);
    handleClose();
  };

  return (
    <>
      <Button
        text="Exclude cattle from season"
        colour="white"
        backgroundColour="black"
        onClick={handleClickOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          outline: 'none',
          ...{ marginLeft: '25%', marginRight: '25%' },
        }}
      >
        <ModalBody>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
                Add cattle to exclude from this seasons metrics
              </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <IconButton onClick={handleClose} style={{ color: 'black' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Info />
              <Typography>
                Cows which are pregnant or empty to ignore from the metrics.
                <b>These cows WILL have heat detection run.</b>
                If you would like to include cattle in metrics please contact behaviour support.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Cow ear tags"
                value={cattleNames?.join(SEPARATOR)}
                onChange={(event) => setCattleNames(splitNumberList(event.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                text="Update"
                colour="white"
                backgroundColour="black"
                onClick={updateSeason}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ExcludeCattleFromSeasonModal;
