import React, { FunctionComponent, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import mobSlice from 'store/slices/mob.slice';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentMobs, selectMobs } from 'store/selectors/mob.selectors';
import { IMobDTO } from '@halter-corp/cattle-service-client';
import mobEffects from 'store/effects/mob.effects';

export type MultiMobSelectProps = {
  farmId: string;
};

const MultiMobSelect: FunctionComponent<MultiMobSelectProps> = ({ farmId }) => {
  const dispatch = useDispatch();

  const currentMobs = useSelector(getCurrentMobs);
  const mobList = useSelector(selectMobs);

  useEffect(() => {
    dispatch(mobEffects.fetch({ farmId }));
  }, [dispatch, farmId]);

  const handleMobChange = (e: any) => {
    dispatch(
      mobSlice.actions.setCurrentMobs({
        currentMobs: e.target.value,
      })
    );
  };

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel>Mobs</InputLabel>
      {mobList.length > 0 && (
        <Select
          multiple
          displayEmpty 
          value={currentMobs ?? []}
          onChange={(e) => handleMobChange(e)}
        >
          {[
            ...[undefined, ...mobList].map((mob?: IMobDTO) => (
              <MenuItem style={{ height: 40 }} key={mob?.id ?? 'key'} value={mob?.id}>
                {mob?.name}
              </MenuItem>
            )),
          ]}
        </Select>
      )}
    </FormControl>
  );
};

export default MultiMobSelect;
