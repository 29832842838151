import { SearchResultTypeEnum } from '@halter-corp/bff-debug-tool-service-client';
import { Feature } from 'geojson';
import { DeviceCommandFeature2 } from 'store/effects/debug-cattle.effects';
import { CommandTypeEnum } from '@halter-corp/timeline-service-client';

import { CattleEvent, CowgEventType } from '../map-page-items.selectors';

type CattleLocation = { timestamp: number; location: { lat: number; lng: number } };

export type CattleLocationPath = {
  cattleName: string;
  locations: CattleLocation[];
};

export const mapHistoryEventToDeviceCommandFeature = (items: any[]): DeviceCommandFeature2[] =>
  items.reduce<DeviceCommandFeature2[]>((previous, item) => {
    const { command } = item.data.payload;

    if (command == null) {
      return previous;
    }

    const deviceCommandFeature: DeviceCommandFeature2 = {
      historyEventId: item.id,
      eventDate: new Date(item.timestamp).getTime(),
      boundary: 'boundaryGeometry' in command ? command.boundaryGeometry : undefined,
      exitPoint: 'exitPoint' in command ? command.exitPoint : undefined,
      exitPath: 'exitPath' in command ? command.exitPath : undefined,
      zone: 'geometry' in command ? command.geometry : undefined,
      serialNumber: item.data.payload.device.serialNumber,
      cattleName: item.data.payload.cattle?.name,
      commandId: item.data.payload.command.collarCommandId,
      commandType: item.data.payload.command.type,
      commandStatus: item.data.payload.command.status,
      referenceId: item.data.payload.command.referenceId,
      groupId: item.data.payload.command.groupId,
    };

    if (deviceCommandFeature.zone || deviceCommandFeature.exitPoint || deviceCommandFeature.boundary) {
      return [...previous, deviceCommandFeature];
    }

    return previous;
  }, []);

export const createZonesLookup = (commandFeatures: DeviceCommandFeature2[]) => {
  const lookupMap = commandFeatures.reduce<Map<string, Feature>>(
    (previous: Map<string, Feature>, commandFeature: DeviceCommandFeature2) => {
      if (commandFeature.commandType !== CommandTypeEnum.SETZONE) {
        return previous;
      }
      if (commandFeature.zone == null) {
        return previous;
      }

      previous.set(commandFeature.referenceId, commandFeature.zone);
      return previous;
    },
    new Map() // Using Map instead of object and spread operator for performance
  );

  return Object.fromEntries(lookupMap); // Convert to object so it is serializable and can be stored in redux state
};

export const mapDeviceMetricsToCattleLocations = (items: any[]): CattleLocationPath[] => {
  const cattleLocationsMap = items.reduce((previous, item) => {
    if (
      item.itemType !== SearchResultTypeEnum.DEVICEMETRIC ||
      !(item.data.metricName === 'COWG_EVENT_STREAM' || item.data.metricName === 'POSITION')
    )
      return previous;

    const cattleName = item.data.context?.cattleName;
    if (cattleName == null) {
      return previous;
    }

    const locationsForCattle = previous[cattleName]?.locations ?? [];
    const location: CattleLocation = item.data.metric?.cowgEventStream?.location || {
      lat: item.data.metric?.position?.latitude,
      lon: item.data.metric?.position?.longitude,
    };

    const newLocation = {
      timestamp: new Date(item.timestamp).getTime(),
      location,
    };

    return {
      ...previous,
      [cattleName]: { cattleName, locations: [...locationsForCattle, newLocation] },
    };
  }, {});

  return Object.values(cattleLocationsMap);
};

export const mapDeviceMetricsToCattleEvents = (items: any[]): CattleEvent[] =>
  items
    .map<CattleEvent | undefined>((item): CattleEvent | undefined => {
      if (
        item.itemType !== SearchResultTypeEnum.DEVICEMETRIC ||
        item.data.metricName !== 'COWG_EVENT_STREAM'
      ) {
        return undefined;
      }

      const event = item.data.metric?.cowgEventStream;
      const cattleName = item.data.context?.cattleName;
      const fixAge = item.data.context?.fixAge;
      const targetHeading = item.data.context?.targetHeading;
      const firmwareVersion = item.data.context?.firmwareVersion;
      const pulseAttemptCount = item.data.context?.pulseAttemptCount;
      const pulseSuccessCount = item.data.context?.pulseSuccessCount;
      const peakShockConfirmCurrentAmps = item.data.context?.peakShockConfirmCurrentAmps;

      const { serialNumber } = item.data;

      if (cattleName == null) {
        return undefined;
      }

      const cowgStatuses = event.cowgStatus || [];
      const eType = CowgEventType[event.eventType];

      const cattleEvent: CattleEvent = {
        ...event,
        cattleName,
        serialNumber,
        id: `${item.id}`,
        timestamp: new Date(item.timestamp).toISOString(),
        cowgStatuses,
        eventType: eType,
        fixAge,
        targetHeading,
        firmwareVersion,
        pulseAttemptCount,
        pulseSuccessCount,
        peakShockConfirmCurrentAmps,
      };
      return cattleEvent;
    })
    .filter((event: CattleEvent | undefined): event is CattleEvent => event != null);
