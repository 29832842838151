import { Card, CircularProgress, Grid } from '@material-ui/core';

import React, { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'store';
import styled from 'styled-components';
import BehaviourService from 'services/behaviour.service';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import CattleService from 'services/cattle.service';
import { groupBy } from 'lodash';
import { InterventionTypeEnum } from '@halter-corp/mating-service-client';
import InterventionsTable from '../components/interventions.table';

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;

export type InterventionDTO = {
  cattleName: string;
  administeredAt: Date;
  interventionType: InterventionTypeEnum;
  id: string;
};

const InterventionsPanel: FunctionComponent = () => {
  const interventions = BehaviourService.useSelectInterventions();
  const loading = BehaviourService.useIsInterventionsLoading();
  const currentFarm = useSelector(getCurrentFarm);
  const cattle = CattleService.useSelectCattle();
  const season = BehaviourService.useSelectSeason();

  const interventionDTO: InterventionDTO[] = useMemo(() => {
    const cattleById = groupBy(cattle, (cow) => cow.id);
    return interventions.flatMap((cow) => {
      const cattleDTO = cattleById[cow.cattleId];
      if (cattleDTO == null) return [];
      const cattleName = cattleDTO.length > 0 ? cattleDTO[0]?.name : '';
      return cow.interventions.map((it) => {
        const { administeredAt, interventionType, id } = it;
        return { cattleName, administeredAt, interventionType, id };
      });
    });
  }, [cattle, interventions]);

  return (
    <Grid container spacing={3} style={{ padding: '8px' }}>
      <Grid item lg={12}>
        <Card style={{ width: '100%' }}>
          {loading ? (
            <SpinnerWrapper>
              <CircularProgress size="40px" />
            </SpinnerWrapper>
          ) : (
            <InterventionsTable interventions={interventionDTO} farmId={currentFarm} seasonId={season?.id} />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default InterventionsPanel;
