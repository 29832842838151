import React, { FunctionComponent, useState } from 'react';
import {
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import JSONTree from 'react-json-tree';
import styled from 'styled-components';

import { SearchResultTypeEnum } from '@halter-corp/bff-debug-tool-service-client';

import { AppState } from 'store';
import {
  findDiscriminatedMapItemsForDateRange,
  ItemDiscriminator,
} from 'store/selectors/map-page-items.selectors/map-page-common.selector2';

import { UnfoldLess, UnfoldMore } from '@material-ui/icons';
import { getColorForItem, jsonTreeTheme } from '../utils';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TableWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid #ccc;
  display: flex;
`;

export type TablePanelProps = {
  activeDateRange: [Date, Date] | null;
  selectedDiscriminators: ItemDiscriminator[];
};

const TablePanel: FunctionComponent<TablePanelProps> = ({ activeDateRange, selectedDiscriminators }) => {
  const mapPageItems = useSelector((state: AppState) =>
    findDiscriminatedMapItemsForDateRange(state, {
      selectedDiscriminators,
      minimumDate: activeDateRange == null ? null : activeDateRange[0],
      maximumDate: activeDateRange == null ? null : activeDateRange[1],
    })
  );
  const [expandAllJsonNodes, setExpandAllJsonNodes] = useState(false);
  const shouldExpandNode = (keyPath: (string | number)[]) =>
    expandAllJsonNodes && !(keyPath.includes('geometry') || keyPath.includes('shape'));

  return (
    <Wrapper>
      <TableWrapper>
        <TableContainer style={{ position: 'absolute', top: 0, bottom: 0 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 200 }}>Timestamp</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    style={{ float: 'right' }}
                    size="small"
                    onClick={() => setExpandAllJsonNodes((v) => !v)}
                  >
                    {expandAllJsonNodes ? <UnfoldLess /> : <UnfoldMore />}
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mapPageItems.map((item) => (
                <TableRow key={item.id}>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    <p>{moment(item.timestamp).format('MMMM Do YYYY, h:mm:ss')}</p>
                    <Chip
                      label={
                        item.itemType === SearchResultTypeEnum.HISTORYEVENT
                          ? item.data.eventType
                          : item.data.metricName
                      }
                      size="small"
                      style={{
                        color: '#fff',
                        backgroundColor: getColorForItem(),
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <JSONTree
                      data={item.data}
                      theme={jsonTreeTheme}
                      shouldExpandNode={shouldExpandNode}
                      hideRoot
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
      <FooterWrapper>
        <span style={{ flex: 1, lineHeight: '32px' }}>{`${mapPageItems.length} records`}</span>
      </FooterWrapper>
    </Wrapper>
  );
};

export default TablePanel;
