import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useLeafletBounds, useLeafletZoom } from 'use-leaflet';
import { LatLngBounds } from 'leaflet';
import { countBy, entries, groupBy } from 'lodash';
import { renderToStaticMarkup } from 'react-dom/server';
import { PixiOverlay } from 'packages/react-leaflet-pixi-overlay';
import { AppState, useSelector } from 'store';
import {
  CattleEvent,
  CowgStatusEnum,
  findCattleEventsForViewBoundsAndDateRangeAndSelectedFilters,
} from '../../../../../../../store/selectors/map-page-items.selectors';
import { generateEventIcon } from '../../../../utils/cattle-event-util';
import CattleEventPopup from './cattle-event.popup';

export type CattleEventsProps = {
  selectedCows: string[] | null;
  scrubbedDateRange: [Date, Date] | null;
  selectedCowGStatuses: CowgStatusEnum[] | null;
  onChangeThing: (value: any[]) => void;
};

const CattleEventsLayer: FunctionComponent<CattleEventsProps> = ({
  selectedCows,
  scrubbedDateRange,
  selectedCowGStatuses,
  onChangeThing,
}) => {
  const zoom = useLeafletZoom();
  const bounds = useLeafletBounds();

  const showCattleEvents = useMemo(() => zoom > 20, [zoom]);
  const mapViewBounds = useMemo(() => new LatLngBounds(bounds), [bounds]);

  const cattleEvents: CattleEvent[] = useSelector((state: AppState) =>
    findCattleEventsForViewBoundsAndDateRangeAndSelectedFilters(state, {
      minimumDate: scrubbedDateRange?.[0] || null,
      maximumDate: scrubbedDateRange?.[1] || null,
      mapViewBounds,
      selectedCows,
      selectedCowGStatuses,
    })
  );

  const [selectedCattleEventData, setSelectedCattleEventData] = useState<any | null>(null);
  const selectedCattleEvent = useMemo(() => {
    if (selectedCattleEventData == null) return null;
    return (
      cattleEvents.find(
        (event) =>
          event.serialNumber === selectedCattleEventData.serialNumber &&
          event.timestamp === selectedCattleEventData.timestamp
      ) ?? null
    );
  }, [selectedCattleEventData]);

  useEffect(() => {
    onChangeThing(
      entries(groupBy(cattleEvents, (item) => item.cattleName)).map(([cattleName, items]) => {
        const countOfEachStatus = countBy(items.flatMap((item) => item.cowgStatuses));
        const inBounds = countOfEachStatus.IN_BOUNDARY ?? 0;
        const shockFailed = countOfEachStatus.SHOCK_FAILED ?? 0;
        const shock = countOfEachStatus.SHOCK ?? 0;
        const inZone = countOfEachStatus.IN_ZONE ?? 0;
        const piezoing = countOfEachStatus.PIEZOING ?? 0;
        const controlDisabled = countOfEachStatus.CONTROL_DISABLED ?? 0;
        const shove = countOfEachStatus.SHOVE ?? 0;
        const deactivationEvents = countBy(items.flatMap((item) => item.eventType))[6];

        return {
          cattleName,
          shockFailed,
          shock,
          inBounds,
          inZone,
          piezoing,
          controlDisabled,
          shove,
          deactivationEvents,
          items,
        };
      })
    );
    // eslint-disable-next-line
  }, [cattleEvents]);

  const markers = useMemo(() => {
    if (!showCattleEvents) return [];
    return cattleEvents.map((event) => ({
      id: { serialNumber: event.serialNumber, timestamp: event.timestamp },
      iconId: `${JSON.stringify(event.cowgStatuses)}-${event.locationMetadata?.heading}`,
      customIcon: renderToStaticMarkup(
        generateEventIcon(
          event.cowgStatuses,
          selectedCowGStatuses ?? undefined,
          event.locationMetadata?.heading,
          event.eventType
        )
      ),
      position: [event.location.lat, event.location.lon],
      onClick: (cattleEvent: string) => setSelectedCattleEventData(cattleEvent),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cattleEvents, showCattleEvents]);

  return (
    <>
      <PixiOverlay markers={markers} show={showCattleEvents} />
      {selectedCattleEvent != null && (
        <CattleEventPopup
          cattleEvent={selectedCattleEvent}
          onClose={() => setSelectedCattleEventData(null)}
        />
      )}
    </>
  );
};

export default CattleEventsLayer;
