import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  findDiscriminatedMapItemsForDateRange,
  ItemDiscriminator,
} from 'store/selectors/map-page-items.selectors/map-page-common.selector2';

import { getColorForEventType, getColorForMetric } from '../utils';
import DateRangeSlider from '../components/date-range-slider';
import { AppState } from '../../../../store';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
`;

const TimelineWrapper = styled.div`
  position: relative;
  height: 1.5rem;
  background-color: #fafafa;
`;

const TimelineItem = styled.div<{ eventType: string | undefined; percentage: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => `${props.percentage * 100}%`};
  background-color: ${(props) =>
    props.eventType ? getColorForEventType(props.eventType) : getColorForMetric()};
  width: 1px;
`;

export type SliderPanelProps = {
  dateRange: [Date, Date] | null;
  activeDateRange: [Date, Date] | null;
  onChangeActiveDateRange: (dateRange: [Date, Date]) => void;
  selectedDiscriminators: ItemDiscriminator[];
};

const TimelineSliderPanel: FunctionComponent<SliderPanelProps> = ({
  dateRange,
  activeDateRange,
  onChangeActiveDateRange,
  selectedDiscriminators,
}) => {
  const cattleTimelineMapItems = useSelector((state: AppState) =>
    findDiscriminatedMapItemsForDateRange(state, {
      selectedDiscriminators,
      minimumDate: dateRange == null ? null : dateRange[0],
      maximumDate: dateRange == null ? null : dateRange[1],
    })
  );

  const [scrubbedDateRange, setScrubbedDateRange] = useState(activeDateRange);
  useEffect(() => setScrubbedDateRange(dateRange), [dateRange]);
  if (dateRange == null || activeDateRange == null || scrubbedDateRange == null) return null;
  return (
    <Wrapper>
      <TimelineWrapper>
        {cattleTimelineMapItems.map((item) => {
          const timestamp = new Date(item.timestamp).getTime();

          const percentage =
            (timestamp - dateRange[0].getTime()) / (dateRange[1].getTime() - dateRange[0].getTime());
          return <TimelineItem key={item.id} eventType={item.data.eventType} percentage={percentage} />;
        })}
      </TimelineWrapper>
      <DateRangeSlider
        dateRange={scrubbedDateRange}
        min={dateRange[0]}
        max={dateRange[1]}
        onChange={(_event, value) => setScrubbedDateRange(value)}
        onChangeCommitted={(_event, value) => onChangeActiveDateRange(value)}
      />
    </Wrapper>
  );
};

export default TimelineSliderPanel;
