import React, { FunctionComponent, useRef } from 'react';
import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import styled from 'styled-components';
import TopographyService from 'services/topography.service';
import ReactDOMServer from 'react-dom/server';
import CowDot from 'application/modules/live-view/components/cow-dot';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 200px;
`;

type SingleDeviceMapViewProps = {
  serialNumber: string;
  farmId?: string;
  location: { latitude?: number; longitude?: number };
};

const SingleDeviceMapView: FunctionComponent<SingleDeviceMapViewProps> = ({
  serialNumber,
  farmId,
  location,
}) => {
  const metadata = TopographyService.useFarmMapMetadata(farmId);
  const mapRef = useRef<LeafletMap>(null);

  if (location.latitude == null || location.longitude == null) return null;

  return (
    <Wrapper>
      <LeafletMap
        ref={mapRef}
        preferCanvas
        minZoom={metadata?.zoom.minZoom ?? 16}
        maxZoom={metadata?.zoom.maxZoom ?? 23}
        center={{ lat: location.latitude, lng: location.longitude }}
        zoom={18}
        style={{ flex: 1 }}
      >
        <TileLayer url="https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}" />
        {metadata && <TileLayer url={metadata.tileUrl} />}
        <Marker
          key={serialNumber}
          icon={L.divIcon({ className: 'bla', html: ReactDOMServer.renderToString(<CowDot />) })}
          position={{ lat: location.latitude, lng: location.longitude }}
        />
      </LeafletMap>
    </Wrapper>
  );
};

export default SingleDeviceMapView;
