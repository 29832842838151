import { IFarmDTO } from '@halter-corp/business-service-client';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import BusinessService from 'services/business.service';
import farmEffects from 'store/effects/farm.effects';

export type FarmSelectProps = {
  onChange: (value: IFarmDTO[] | undefined) => void;
  disabled?: boolean;
  value?: string[];
};

const FarmSelect: FunctionComponent<FarmSelectProps> = ({ onChange, disabled, value }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(farmEffects.fetch());
  }, [dispatch]);

  const farms = BusinessService.useFetchFarmList();
  const farmStrings = farms.map((farm) => farm.name).filter((farm) => farm) as string[];

  const currentFarms = farms.filter((farm) => value?.includes(farm.id));

  return (
    <>
      {farmStrings.length > 0 && (
        <Autocomplete
          options={farms}
          renderInput={(farm) => <TextField {...farm} label="Farm" variant="outlined" size="small" />}
          getOptionLabel={(option) => option?.name || ''}
          multiple
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          disabled={disabled}
          value={currentFarms}
        />
      )}
    </>
  );
};
export default FarmSelect;
