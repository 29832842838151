import React, { ChangeEvent, FC } from 'react';
import { TableRow, TableCell, Collapse, Box, Checkbox, makeStyles } from '@material-ui/core';

import DataTable, { Column } from 'application/components/data-table';
import { DeviceWithMetadata } from 'store/selectors/tower.selectors';
import {
  batteryVoltageColumn,
  deviceNameColumn,
  enabledColumn,
  idColumn,
  indexColumn,
  statusColumn,
  lastCheckinColumn,
  typeColumn,
  vendorColumn,
  selectionColumn,
} from './common-columns';

type NestedDeviceTableProps = {
  devices: DeviceWithMetadata[];
  open: boolean;
  selectedDevices: Map<string, DeviceWithMetadata>;
  onSelectDevice: (event: ChangeEvent<HTMLInputElement>) => void;
};

const useStyles = makeStyles({
  row: {
    '&:last-child td': {
      borderBottom: 'none',
    },
  },
});

const NestedDevicetable: FC<NestedDeviceTableProps> = ({
  devices,
  open,
  selectedDevices,
  onSelectDevice,
}) => {
  const classes = useStyles();

  const checkboxColumn: Column<DeviceWithMetadata> = {
    name: <div style={{ paddingTop: 20, paddingBottom: 20 }} />,
    render: (device) => (
      <Checkbox
        color="primary"
        value={JSON.stringify(device)}
        checked={!!selectedDevices.get(device.id)}
        onChange={onSelectDevice}
      />
    ),
  };

  const deviceColumns = [
    indexColumn,
    checkboxColumn,
    idColumn,
    deviceNameColumn,
    typeColumn,
    vendorColumn,
    enabledColumn,
    statusColumn,
    selectionColumn,
    lastCheckinColumn,
    batteryVoltageColumn,
  ];

  if (devices.length === 0) return null;

  return (
    <TableRow className={classes.row}>
      <TableCell style={{ borderBottom: open ? '' : 'none', padding: 0 }} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1, padding: `0px 64px 8px 64px` }}>
            <DataTable<DeviceWithMetadata>
              size="small"
              data={devices}
              defaultSortColumnName="Name"
              columns={deviceColumns}
              padding="none"
            />
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default NestedDevicetable;
