import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import FleetTrackingContainer from './containers/fleet-tracking.container';
import FleetViewContainer from './containers/fleet-view.container';
import FarmIssuesContainer from './containers/farm-issues.container';

/**
 * @context - Temporary module to migrate from device-service APIs to fleet-service APIs
 */
const FleetWatchModule: FunctionComponent = () => (
  <Switch>
    <Route exact path="/fleet-watch">
      <FleetViewContainer />
    </Route>
    <Route exact path="/fleet-watch/tracking">
      <FleetTrackingContainer />
    </Route>
    <Route exact path="/fleet-watch/:farmId">
      <FarmIssuesContainer />
    </Route>
  </Switch>
);

export default FleetWatchModule;
