import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import { GetApplyProfilesSummaryFormValues } from './types';
import { getInitialProfilesValues } from './utils';
import ApplyProfilesFormConetent from './apply-profiles.form-content';

type ApplyProfilesFormProps = {
  onSubmit: (values: GetApplyProfilesSummaryFormValues) => void;
  onChange?: () => void;
  disableSubmit?: boolean;
};

const validationSchema = Yup.object({
  farmId: Yup.string().required('Required'),
});

const ApplyProfilesForm: FunctionComponent<ApplyProfilesFormProps> = ({
  onSubmit,
  onChange,
  disableSubmit = false,
}) => {
  const currentFarm = useSelector(getCurrentFarm);
  const initialValues = getInitialProfilesValues(currentFarm);

  return (
    <Formik<GetApplyProfilesSummaryFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => <ApplyProfilesFormConetent onChange={onChange} disableSubmit={disableSubmit} />}
    </Formik>
  );
};

export default ApplyProfilesForm;
