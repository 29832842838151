import React, { FunctionComponent, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import towerEffects from 'store/effects/tower.effects';
import { selectGatewayIds } from 'store/selectors/tower.selectors';
import { range } from 'lodash';

const MIN_RESOLUTION = 1;
const MAX_RESOLUTION = 12;

const SelectWrapper = styled.div`
  margin-top: 4px;
  background-color: #e0e0e0;
  border-radius: 4px;
`;

type HeatMapResolutionSelectProps = { visible: boolean };

const HeatMapResolutionSelect: FunctionComponent<HeatMapResolutionSelectProps> = ({ visible }) => {
  const dispatch = useDispatch();
  const [selectedResolution, setSelectedResolution] = useState(MAX_RESOLUTION);

  const gatewayIds = useSelector(selectGatewayIds);

  if (!visible) return null;

  const handleSelectResolution = (value: number) => {
    setSelectedResolution(value);
    dispatch(towerEffects.fetchAllHeatMapsByGateways({ gatewayIds, resolution: value }));
  };
  // NOTE: range is not inclusive. This is why we add +1 to max resolution here
  const resolutionOptions = range(MIN_RESOLUTION, MAX_RESOLUTION + 1);

  return (
    <SelectWrapper>
      <FormControl style={{ width: '100%' }} variant="filled">
        <InputLabel shrink>Resolution</InputLabel>
        <Select
          value={selectedResolution}
          onChange={(e) => handleSelectResolution(parseInt(e.target.value as string, 10))}
        >
          {resolutionOptions.map((resolution) => (
            <MenuItem key={resolution} value={resolution}>
              {String(resolution)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SelectWrapper>
  );
};

export default HeatMapResolutionSelect;
