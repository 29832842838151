
export enum ProductTypeEnum {
    COLLAR = 'COLLAR',
    GATEWAY = 'GATEWAY',
    ROUTER = 'ROUTER',
    TOWER_NODE = 'TOWER_NODE',
    SOLAR_SWITCH = 'SOLAR_SWITCH',
    LED_INDICATOR = 'LED_INDICATOR',
}

export enum ProductVendorEnum {
    HALTER = "HALTER",
    MULTI_TECH = "MULTI_TECH",
    RAK = "RAK",
    TEKTELIC = "TEKTELIC",
    TELTONIKA = "TELTONIKA",
    UBIQUITI = "UBIQUITI",
    VENDOR_UNSPECIFIED = "VENDOR_UNSPECIFIED",
}

export enum ProductModelEnum {
    CONDUIT_IP67_BASE_STATION = "CONDUIT_IP67_BASE_STATION",
    ER_X_SFP = "ER_X_SFP",
    EP_R8 = "EP_R8",
    EP_R6 = "EP_R6",
    ER_POE_5 = "ER_POE_5",
    HALTER_TOWER_NODE = "HALTER_TOWER_NODE",
    HSS_050_WITH_EPEVER = "HSS_050_WITH_EPEVER",
    KONA_MACRO_IOT = "KONA_MACRO_IOT",
    MODEL_UNSPECIFIED = "MODEL_UNSPECIFIED",
    RUTX12 = "RUTX12",
    SUN_MAX_SOLAR_SWITCH = "SUN_MAX_SOLAR_SWITCH",
    WISGATE_EDGE_PRO = "WISGATE_EDGE_PRO",
}

