import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICattleDTO } from '@halter-corp/cattle-service-client';

import HttpApiService from 'services/http-api.service';

import deviceEffects from './device.effects';

const cattleEffects = {
  tagOff: createAsyncThunk(
    'cattle/tagoff',
    async (props: { farmId: string; cattleId: string }, { dispatch }): Promise<void> => {
      const api = await HttpApiService.getCattleApi(props.farmId);
      await api.updateDevice(props.cattleId, undefined);

      await dispatch(deviceEffects.fetch());
    }
  ),

  togglePause: createAsyncThunk(
    'cattle/pause',
    async (props: { cattle: ICattleDTO }, { dispatch }): Promise<void> => {
      const api = await HttpApiService.getCattleApi(props.cattle.farmId);
      await api.pauseCattle(props.cattle.id, {
        paused: !props.cattle.paused,
        version: props.cattle.version,
      });

      if (props.cattle.serialNumber != null) {
        await dispatch(deviceEffects.fetchBySerialNumber({ serialNumber: props.cattle.serialNumber }));
      }
    }
  ),

  fetchCattle: createAsyncThunk('fetch/cattle', async (props: { farmId: string }): Promise<ICattleDTO[]> => {
    const cattleApi = await HttpApiService.getCattleApi(props.farmId);
    const { data: cattle } = await cattleApi.findAllCattle();
    return cattle;
  }),
};

export default cattleEffects;
