import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import FleetToolsContainer from './containers/fleet-tools.container';

const FleetToolsModule: FunctionComponent = () => (
  <Switch>
    <Route exact path="/fleet-tools">
      <FleetToolsContainer />
    </Route>
  </Switch>
);

export default FleetToolsModule;
