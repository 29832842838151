import { Card, CardContent, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';

import React, { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'store';
import styled from 'styled-components';
import { CowHeatEventSourceEnum, ISeasonDTO } from '@halter-corp/cattle-breeding-service-client';
import { dateToText } from 'application/modules/fleet-watch/utils';
import BehaviourService from 'services/behaviour.service';
import moment from 'moment';
import { groupBy, round } from 'lodash';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import { Search } from '@material-ui/icons';
import HeatTable from '../components/heat.table';
import ExcludeCattleFromSeasonModal from '../components/exclude-cattle-from-season.modal';
import BulkAddMatingsModal from '../components/bulk-add-matings.modal';
import SeasonMetricsPopup from '../components/season-metrics.pop-up';
import IncludeCattleInSeasonModal from '../components/include-cattle-in-season.modal';

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;

type HeatPanelProps = {
  season?: ISeasonDTO;
};

const HeatPanel: FunctionComponent<HeatPanelProps> = ({ season }) => {
  const heats = BehaviourService.useSelectFarmHeats();
  const loading = BehaviourService.useIsHeatsLoading();
  const seasonMetrics = BehaviourService.useSelectSeasonMetrics();
  const seasonMetricsSummary = BehaviourService.useSelectSeasonMetricsSummary();

  const currentFarm = useSelector(getCurrentFarm);

  const initialDetectionGroupings = useMemo(() => groupBy(heats, (item) => item.source), [heats]);

  const cycledCattle = useMemo(() => seasonMetrics.heatData.cycledCattleIds.length, [seasonMetrics]);
  const cyclingPercent = useMemo(
    () => season && round((cycledCattle / season.includedCattleIds.length) * 100),
    [seasonMetrics, season]
  );

  const nonReturnRate = useMemo(
    () =>
      season &&
      round(
        (seasonMetrics.abData.nonReturnedCattleIds.length / seasonMetrics.abData.eligibleInseminations) * 100
      ),
    [seasonMetrics, season]
  );

  return (
    <Grid container spacing={3} style={{ padding: '8px' }}>
      <Grid item lg={12}>
        <Card style={{ width: '100%' }}>
          <CardContent>
            <Grid container>
              {seasonMetrics && season && seasonMetricsSummary && (
                <>
                  <Grid item lg={2}>
                    <Typography variant="h6">
                      App Metrics
                      <SeasonMetricsPopup />
                    </Typography>
                    <Typography>{`${cyclingPercent || '-'}% Cycling`}</Typography>
                    <Typography>{`Recently Cycled: ${cycledCattle}`}</Typography>
                    <Typography>{`Non-cycling: ${seasonMetrics.heatData.notCycledCattleIds.length}`}</Typography>
                    <Typography>{`Submitted: ${
                      seasonMetricsSummary.submittedPercent
                        ? round(seasonMetricsSummary.submittedPercent)
                        : '-'
                    }%`}</Typography>
                    <Typography>{`Submitted count: ${seasonMetrics.abData.abCattleIds.length}`}</Typography>
                    <Typography>{`Not Submitted: ${seasonMetrics.abData.notAbCattleIds.length}`}</Typography>
                  </Grid>
                  <Grid item lg={2}>
                    <Typography variant="h6">
                      Bonus Metrics
                      <SeasonMetricsPopup />
                    </Typography>
                    <Typography>{`Included cattle: ${season.includedCattleIds.length}`}</Typography>
                    {seasonMetrics && (
                      <>
                        <Typography>{`Estimated non-return rate: ${nonReturnRate}%`}</Typography>
                        <Typography>{`Non-returned count: ${seasonMetrics.abData.nonReturnedCattleIds.length}`}</Typography>
                        <Typography>{`Eligible inseminations: ${seasonMetrics.abData.eligibleInseminations}`}</Typography>
                      </>
                    )}
                    <Typography>{`Returned count: ${seasonMetricsSummary.returnedCount}`}</Typography>
                    {seasonMetricsSummary.threeWeekSubmittedPercent && (
                      <>
                        <Typography>{`Three week submission rate: ${round(
                          seasonMetricsSummary.threeWeekSubmittedPercent
                        )}%`}</Typography>
                      </>
                    )}
                    {seasonMetricsSummary.sixWeekInCalfPercent && (
                      <>
                        <Typography>{`Estimated six week in calf rate: ${round(
                          seasonMetricsSummary.sixWeekInCalfPercent
                        )}%`}</Typography>
                      </>
                    )}
                  </Grid>
                </>
              )}
              {initialDetectionGroupings && (
                <Grid item lg={2}>
                  <Typography variant="h6">
                    Detection Source{' '}
                    <IconButton>
                      <Search style={{ color: 'black' }} />
                    </IconButton>
                  </Typography>
                  <Typography>{`Manual: ${
                    initialDetectionGroupings[CowHeatEventSourceEnum.MANUAL]?.length ?? 0
                  }`}</Typography>
                  <Typography>{`System: ${
                    initialDetectionGroupings[CowHeatEventSourceEnum.SYSTEM]?.length ?? 0
                  }`}</Typography>
                  <Typography>{`Manual then system: ${
                    initialDetectionGroupings[CowHeatEventSourceEnum.MANUAL_THEN_SYSTEM]?.length ?? 0
                  }`}</Typography>
                </Grid>
              )}
              {season && (
                <>
                  <Grid item lg={3}>
                    <Typography>
                      {`Planned Start of Calving: ${dateToText(season.plannedStartOfCalving)}`}
                    </Typography>
                    <Typography
                      color={
                        moment(season.plannedStartOfMating).isAfter(new Date()) ? 'secondary' : 'primary'
                      }
                    >{`Planned Start of Mating: ${dateToText(season.plannedStartOfMating)}`}</Typography>
                  </Grid>
                </>
              )}
              {season && (
                <Grid
                  item
                  container
                  lg={3}
                  justifyContent="space-evenly"
                  direction="column"
                  alignContent="flex-end"
                >
                  <ExcludeCattleFromSeasonModal
                    seasonId={season.id}
                    farmId={currentFarm}
                    currentExcludedCattleIds={season.excludedCattleIds}
                  />
                  <IncludeCattleInSeasonModal
                    seasonId={season.id}
                    farmId={currentFarm}
                    currentExcludedCattleIds={season.excludedCattleIds}
                  />
                  <BulkAddMatingsModal seasonId={season.id} farmId={currentFarm} />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12}>
        {loading ? (
          <SpinnerWrapper>
            <CircularProgress size="40px" />
          </SpinnerWrapper>
        ) : (
          <Card style={{ width: '100%' }}>
            <HeatTable heats={heats} farmId={currentFarm} />
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default HeatPanel;
