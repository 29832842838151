import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import ApplyModifiersFormContent from './apply-modifiers.form-content';
import { GetApplyModifiersSummaryFormValues } from './types';
import { getInitialModifiersValues } from './utils';

type ApplyModifiersFormProps = {
  onSubmit: (values: GetApplyModifiersSummaryFormValues) => void;
  onChange?: () => void;
  disableSubmit?: boolean;
};

const validationSchema = Yup.object({
  farmId: Yup.string().required('Required'),
});

const ApplyModifiersForm: FunctionComponent<ApplyModifiersFormProps> = ({
  onSubmit,
  onChange,
  disableSubmit = false,
}) => {
  const currentFarm = useSelector(getCurrentFarm);
  const initialValues = getInitialModifiersValues(currentFarm);

  return (
    <Formik<GetApplyModifiersSummaryFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => <ApplyModifiersFormContent onChange={onChange} disableSubmit={disableSubmit} />}
    </Formik>
  );
};

export default ApplyModifiersForm;
