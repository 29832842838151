import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { splitFieldValues } from 'application/utils';

import mobSlice from 'store/slices/mob.slice';

import { getCurrentFarm } from 'store/selectors/farm-context.selectors';

import debugCattleEffects from 'store/effects/debug-cattle.effects';
import { selectIsLoading, selectHasError, selectHasResults } from 'store/selectors/debug-cattle.selectors';
import debugCattleSlice from 'store/slices/debug-cattle.slice';
import DebugCattleScreen2 from './screens/debug-cattle.screen2';

const DebugCattleModule2: FunctionComponent = () => {
  const urlQueryString = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();

  const cattleEarTagsString = urlQueryString.get('earTags');
  const serialNumbersString = urlQueryString.get('serialNumbers');
  const fromDateString = urlQueryString.get('fromDate');
  const toDateString = urlQueryString.get('toDate');
  const fetchAllDeviceMetrics = urlQueryString.get('fetchAllDeviceMetrics') === 'true';
  const farmIdFromQueryString = urlQueryString.get('farmId');
  const mobIdFromQueryString = urlQueryString.get('mobId');
  const mobIds = mobIdFromQueryString && mobIdFromQueryString?.length > 0 ? mobIdFromQueryString.split(',') : null;

  if (mobIds != null) {
    dispatch(mobSlice.actions.setCurrentMobs({ currentMobs: mobIds }));
  }

  const cattleEarTags = useMemo<string[]>(() => splitFieldValues(cattleEarTagsString), [cattleEarTagsString]);
  const serialNumbers = useMemo<string[]>(() => splitFieldValues(serialNumbersString), [serialNumbersString]);

  const requestDateRange = useMemo<[Date, Date]>(
    () =>
      fromDateString == null || toDateString == null
        ? [moment().subtract(30, 'minutes').toDate(), new Date()]
        : [new Date(fromDateString), new Date(toDateString)],
    [fromDateString, toDateString]
  );

  const farmIdFromSelector = useSelector(getCurrentFarm);

  // TODO: Combine selectors from the same slice
  const isLoading = useSelector(selectIsLoading);
  const hasError = useSelector(selectHasError);
  const hasResults = useSelector(selectHasResults);

  const currentFarmId = farmIdFromQueryString || farmIdFromSelector;

  useEffect(() => {
    if ([...urlQueryString.keys()].length === 0) return;
    if (requestDateRange == null) return;

    const cattleProps = {
      serialNumbers,
      mobIds,
      timestamp: requestDateRange,
      farmId: currentFarmId,
      cattleNames: cattleEarTags || [],
      fetchAllDeviceMetrics,
    };

    dispatch(debugCattleSlice.actions.clear());
    dispatch(debugCattleEffects.fetchHistoryEvents(cattleProps));
    dispatch(debugCattleEffects.fetchPositionMetrics(cattleProps));
    dispatch(debugCattleEffects.fetchCowGEventStream(cattleProps));
  }, [cattleEarTags, serialNumbers, requestDateRange, currentFarmId, fetchAllDeviceMetrics, mobIds?.join(',')]);

  return (
    <DebugCattleScreen2
      key={urlQueryString.toString()}
      loading={isLoading}
      cattleEarTags={cattleEarTags}
      serialNumbers={serialNumbers}
      dateRange={requestDateRange}
      itemsDateRange={requestDateRange}
      query=""
      fetchAllDeviceMetrics={fetchAllDeviceMetrics}
      queryReturnedError={hasError}
      hasResults={hasResults}
    />
  );
};

export default DebugCattleModule2;
