import { Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import Button from 'application/components/button';

import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import BehaviourService from 'services/behaviour.service';
import CattleService from 'services/cattle.service';
import behaviourEffects from 'store/effects/behaviour.effects';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import deviceEffects from 'store/effects/device.effects';
import DeviceService from 'services/device.service';
import { ICattleDTO } from '@halter-corp/cattle-service-client';
import { IDeviceStatus } from '@halter-corp/device-service-client';
import { Link } from 'react-router-dom';
import HeatTable from '../components/heat.table';
import { HealthTable } from '../components/health.table';

const CowPanel: FunctionComponent = () => {
  const [cattleName, setCattleName] = useState('');
  const [cattleId, setCattleId] = useState<string | null>(null);
  const dispatch = useDispatch();
  const heats = BehaviourService.useSelectCowHeats();
  const healthCases = BehaviourService.useSelectCowHealthCases();
  const currentFarm = useSelector(getCurrentFarm);
  const device = DeviceService.useSelectDevice();

  const fetchCattleDTO = async (): Promise<ICattleDTO | undefined> => {
    if (currentFarm == null || cattleName == null) return undefined;
    const cattle = await CattleService.fetchCattleByName([cattleName], currentFarm);
    return cattle[0];
  };

  const searchCow = async () => {
    const cattleDTO = await fetchCattleDTO();
    if (cattleDTO == null) return;
    setCattleId(cattleDTO.id);
    dispatch(behaviourEffects.fetchHeatsByCattleId({ farmId: currentFarm, cattleId: cattleDTO.id }));
    dispatch(behaviourEffects.fetchHealthCasesByCattleId({ farmId: currentFarm, cattleId: cattleDTO.id }));
    if (cattleDTO.serialNumber == null) return;
    dispatch(deviceEffects.fetchBySerialNumber({ serialNumber: cattleDTO.serialNumber }));
  };

  const statusStrings = (status: IDeviceStatus): string[] => {
    const statuses = [];
    if (status.swapRequested) statuses.push('- Faulty (Manual)');
    else if (status.faulty) statuses.push('- Faulty (Auto detect)');

    if (status.outOfBattery) statuses.push('- Out of Battery');
    if (status.loraUnresponsive) statuses.push('- Lora Unresponsive');
    if (status.fallenOffCow) statuses.push('- Fallen off cow');
    if (status.backwardsOnCow) statuses.push('- Collar backwards');
    return statuses;
  };

  return (
    <Grid container style={{ padding: '8px' }} justifyContent="center" alignItems="center" spacing={3}>
      <Grid item xs={9} sm={9} lg={9} style={{ display: 'flex' }}>
        <TextField
          label="Ear Tag"
          value={cattleName}
          onChange={(event) => setCattleName(event.target.value)}
          style={{ flex: 1 }}
        />
      </Grid>
      <Grid item xs={3} sm={3} lg={3}>
        <Button text="Search Cow" onClick={searchCow} backgroundColour="black" colour="white" />
      </Grid>
      <Grid item lg={12}>
        <Card style={{ width: '100%' }}>
          <CardContent>
            <Grid container>
              {cattleId && (
                <Grid item lg={2}>
                  <Link
                    to={{
                      pathname: `https://halter.grafana.net/d/I9QlPyi4z/behaviour-support-toolbelt?orgId=1&var-farm_id=${currentFarm}&var-cattle_id=${cattleId}`,
                    }}
                    target="_blank"
                    style={{
                      color: 'black',
                      textDecoration: 'none',
                      fontSize: '24px',
                      textAlign: 'center',
                    }}
                  >
                    Grafana 🛠
                  </Link>
                </Grid>
              )}
              {device && cattleId && (
                <Grid item lg={10}>
                  <Typography variant="h6">Faults</Typography>
                  <Typography variant="overline">
                    {statusStrings(device.status).join().length > 0
                      ? statusStrings(device.status).join()
                      : 'Collar is healthy!'}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12}>
        {cattleId && heats.length > 0 && (
          <Card style={{ width: '100%' }}>
            <CardContent>
              <Typography variant="h6">Heats</Typography>
              <HeatTable heats={heats} farmId={currentFarm} />
            </CardContent>
          </Card>
        )}
      </Grid>
      <Grid item lg={12}>
        {cattleId && healthCases.length > 0 && (
          <Card style={{ width: '100%' }}>
            <CardContent>
              <HealthTable title="Health cases" healthCases={healthCases} />
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default CowPanel;
