import React, { createContext, FC, useContext, useState } from 'react';
import { Tower } from 'store/selectors/tower.selectors';

type NewTowerContextType = {
  newTowerMap: Map<string, Tower>;
  setNewTowerMap: React.Dispatch<React.SetStateAction<Map<string, Tower>>>;
};

const NewTowersContext = createContext<NewTowerContextType>(null!);

export const useNewTowersContext = () => useContext(NewTowersContext);

export const NewTowersContextProvider: FC = ({ children }) => {
  const [newTowerMap, setNewTowerMap] = useState<Map<string, Tower>>(new Map());

  return (
    <NewTowersContext.Provider value={{ newTowerMap, setNewTowerMap }}>{children}</NewTowersContext.Provider>
  );
};
