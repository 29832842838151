import React, { FunctionComponent } from 'react';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';

import GenericSwitch from 'application/components/generic-switch';
import CattleService from 'services/cattle.service';
import BusinessService from 'services/business.service';

import { Device } from 'data/device';

import DataTable from '../../../components/data-table';
import ResetButton from '../components/reset-button';

import { deviceSyncingStatusToTrafficLight } from '../components/device-status.util';

import {
  batteryVoltageColumn,
  batteryPercentageColumn,
  indexColumn,
  cowColumn,
  farmColumn,
  alarmsColumn,
  firmwareVersionColumn,
  loraColumn,
  wifiColumn,
  mobColumn,
  serialNumberColumn,
  faultsColumn,
  swapRequestColumn,
} from './common-columns';
import { boolToString, listToHasItemsString } from './utils';

type OverviewTableProps = {
  devices: Device[];
  mode: 'farm' | 'device';
  legacyFaultsColumn: boolean;
};

const OverviewTable: FunctionComponent<OverviewTableProps> = ({ devices, mode, legacyFaultsColumn }) => {
  const history = useHistory();

  const farms = BusinessService.useFetchFarmList();

  const togglePause = (device: Device) => {
    if (device.cattle == null) {
      return;
    }
    CattleService.togglePause(device.cattle);
  };

  return (
    <DataTable<Device>
      data={devices}
      defaultSortColumnName="Serial number"
      onSelectRow={(device) => history.push(`/devices/${device.serialNumber}`)}
      columns={[
        indexColumn,
        cowColumn,
        serialNumberColumn,
        ...(mode === 'device' ? [farmColumn(farms)] : []),
        mobColumn,
        batteryPercentageColumn,
        batteryVoltageColumn,
        {
          name: 'Control disabled',
          keyExtractor: (device) => device.stats?.lastCommandStatus?.controlDisabledReasons?.join(', '),
          render: (device) => listToHasItemsString(device.stats?.lastCommandStatus?.controlDisabledReasons),
          toolTip: (device) =>
            device.stats?.lastCommandStatus?.controlDisabledReasons?.join(', ') || 'Not disabled',
          noDataCondition: (device) => device.stats?.lastCommandStatus == null,
        },
        firmwareVersionColumn,
        {
          name: 'In zone',
          keyExtractor: (device) => device.stats?.lastCommandStatus?.inZone,
          render: (device) => boolToString(device.stats?.lastCommandStatus?.inZone),
          noDataCondition: (device) => device.stats?.lastCommandStatus == null,
        },
        {
          name: 'Command ID',
          keyExtractor: (device) => device.stats?.lastCommandStatus?.commandId,
          render: (device) => device.stats?.lastCommandStatus?.commandId,
          noDataCondition: (device) => device.stats?.lastCommandStatus == null,
        },
        {
          name: 'Command sync status',
          keyExtractor: ({ commands }) => commands?.status,
          render: ({ commands }) => (
            <>
              {`${commands?.status}`}
              {deviceSyncingStatusToTrafficLight(commands?.status)}
            </>
          ),
          toolTip: ({ commands }) =>
            commands != null
              ? `${commands.syncingCount} syncing, ${commands.failedToSyncCount} failed, ${commands.syncedCount} synced, (total ${commands.totalCount})`
              : 'No commands',
        },
        {
          name: 'Settings sync status',
          keyExtractor: (device) => device.settingsV2?.status,
          render: (device) => (
            <>
              {`${device.settingsV2?.status}`}
              {deviceSyncingStatusToTrafficLight(device.settingsV2?.status)}
            </>
          ),
        },
        {
          name: 'Map sync status',
          keyExtractor: ({ mapFeatures }) => mapFeatures?.syncedCount,
          render: ({ mapFeatures }) => (
            <>
              {`${mapFeatures?.status ?? 'n/a'}`}
              {deviceSyncingStatusToTrafficLight(mapFeatures?.status)}
            </>
          ),
          toolTip: ({ mapFeatures }) =>
            mapFeatures != null
              ? `${mapFeatures.syncedCount}/${mapFeatures.totalCount} synced`
              : 'No map features',
        },
        legacyFaultsColumn ? faultsColumn : alarmsColumn,
        loraColumn,
        wifiColumn,
        {
          name: 'Paused',
          keyExtractor: (device) => device.status.disabled,
          render: (device) =>
            device.cattle != null ? (
              <GenericSwitch
                value={device.cattle.paused}
                requireConfirmation
                confirmationMessage={`Are you sure you want to ${
                  device.cattle.paused ? 'unpause' : 'pause'
                } ${device.cattle.name != null ? `cow ${device.cattle.name}` : ''}, collar ${
                  device.cattle.serialNumber ?? ''
                }?`}
                onChange={() => togglePause(device)}
              />
            ) : undefined,
        },
        swapRequestColumn,
        {
          name: 'Reset',
          keyExtractor: () => undefined,
          render: (device) => <ResetButton device={device} />,
        },
        {
          name: 'View on map',
          keyExtractor: () => undefined,
          render: (device) => (
            <Link
              to={`/?farmId=${device.farmId}&serialNumbers=${device.serialNumber}&fromDate=${moment(
                device.stats?.loraCheckinStatus?.lastUpdate
              )
                .subtract(3, 'hour')
                .toISOString()}&toDate=${new Date().toISOString()}`}
            >
              <NotListedLocationIcon style={{ color: '#009e60' }} />
            </Link>
          ),
        },
      ]}
    />
  );
};

export default OverviewTable;
