import { createAsyncThunk } from '@reduxjs/toolkit';

import { DeviceOffFarmCheckpointEnum, IDeviceOnFarmStatesContextDTO } from '@halter-corp/fleet-service-client';
import FleetService from 'services/fleet.service';
import { chunk, chain } from 'lodash';
import { FarmDeviceOnFarmStatusCounts } from 'application/modules/fleet-watch/types';
import { DeviceOffFarmCheckpoint } from 'data/fleet-watch';
import { AppState } from '../store';

const fleetEffects = {
  fetchOffFarmCheckpointsByCheckpoints: createAsyncThunk(
    'fleet/fetchOffFarmCheckpointsByCheckpoints',
    async (): Promise<Record<string, DeviceOffFarmCheckpoint[]>> => {
      const deviceFffFarmCheckpoints = await Promise.all(
        Object.values(DeviceOffFarmCheckpointEnum).map(async (checkpoint) => {
          const offFarmCheckpoints = await FleetService.fetchOffFarmCheckpoints(undefined, checkpoint);
          return { checkpoint, deviceOffFarmCheckpoints: offFarmCheckpoints };
        })
      );

      return chain(deviceFffFarmCheckpoints)
        .keyBy(({ checkpoint }) => checkpoint)
        .mapValues(({ deviceOffFarmCheckpoints }) => deviceOffFarmCheckpoints)
        .value();
    }
  ),

  fetchDeviceOnFarmStates: createAsyncThunk(
    'fleet/fetchDeviceOnFarmStates',
    async (
      props: { serialNumbers?: string[]; farmId?: string; nextSerialNumber?: string } = {}
    ): Promise<IDeviceOnFarmStatesContextDTO[]> => {
      const { serialNumbers, farmId, nextSerialNumber } = props;
      return FleetService.fetchDeviceOnFarmStates(serialNumbers, farmId, nextSerialNumber);
    }
  ),

  fetchDeviceOnFarmStatusCountsByFarmId: createAsyncThunk(
    'fleet/fetchDeviceOnFarmStatusCountsByFarmId',
    async ({ farmId }: { farmId: string }): Promise<FarmDeviceOnFarmStatusCounts> => {
      const deviceOnFarmStatusCounts = await FleetService.fetchDeviceOnFarmStatusCountsByFarmId(farmId);
      return { farmId, deviceOnFarmStatusCounts };
    }
  ),

  fetchDeviceOnFarmStatusCounts: createAsyncThunk(
    'fleet/fetchDeviceOnFarmStatusCounts',
    async ({ farmIds }: { farmIds: string[] }, { getState }): Promise<FarmDeviceOnFarmStatusCounts[]> => {
      if (farmIds.length === 0) return [];
      /**
       * If we already have the deviceOnFarmStatusCounts for a farmId, we don't need to fetch it again.
       * This is to prevent too many unnecessary API calls as it will fetch the deviceOnFarmStatusCounts for all the farms
       */
      const { deviceOnFarmStatusCountsByFarmId = {} } = (getState() as AppState).fleet;
      const farmIdsToFetch = farmIds.filter((farmId) => deviceOnFarmStatusCountsByFarmId[farmId] == null);

      const farmDeviceOnFarmStatusCounts: FarmDeviceOnFarmStatusCounts[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const farmIdsChunk of chunk(farmIdsToFetch, 50)) {
        // eslint-disable-next-line no-await-in-loop
        const farmDeviceOnFarmStatusCountsChunk = await Promise.all(
          farmIdsChunk.map(async (farmId) => {
            const deviceOnFarmStatusCounts = await FleetService.fetchDeviceOnFarmStatusCountsByFarmId(farmId);
            return { farmId, deviceOnFarmStatusCounts };
          })
        );
        farmDeviceOnFarmStatusCounts.push(...farmDeviceOnFarmStatusCountsChunk);
      }

      return farmDeviceOnFarmStatusCounts;
    }
  ),
};

export default fleetEffects;
