import { Card, CardContent, CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';

import React, { FunctionComponent, useMemo, useState } from 'react';
import { useSelector } from 'store';
import styled from 'styled-components';
import BehaviourService from 'services/behaviour.service';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import { ICalvingDTO } from '@halter-corp/cattle-breeding-service-client';
import CattleService from 'services/cattle.service';
import { groupBy } from 'lodash';
import Button from 'application/components/button';
import CalvingTable from '../components/calving.table';
import SimpleSnackbar from '../components/snackbar';

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;

export interface CalvingDTO extends ICalvingDTO {
  cattleName: string;
}

const CalvingPanel: FunctionComponent = () => {
  const calvingInfo = BehaviourService.useSelectCalvingInfo();
  const loading = BehaviourService.useIsCalvingInfoLoading();
  const currentFarm = useSelector(getCurrentFarm);
  const cattle = CattleService.useSelectCattle();
  const calvingSummary = BehaviourService.useSelectCalvingSummary();

  const mindaIntegrationStatus = BehaviourService.useMindaIntegrationStatus();

  const calvingDTO = useMemo(() => {
    const cattleById = groupBy(cattle, (cow) => cow.id);
    return calvingInfo.map((calving) => {
      const cattleDTO = cattleById[calving.cattleId];
      const cattleName = cattleDTO != null && cattleDTO.length > 0 ? cattleDTO[0]?.name : '';
      return { ...calving, cattleName };
    });
  }, [cattle, calvingInfo]);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const preFetchText = 'Refresh minda data';
  const [isRefreshingMindaData, setIsRefreshingMindaData] = useState(false);
  const [refreshingMindaDataText, setRefreshingMindaDataText] = useState(preFetchText);
  const handleFetchPregnancyTestResults = async () => {
    setRefreshingMindaDataText('Refreshing...');
    setIsRefreshingMindaData(true);
    const responseMessage = await BehaviourService.triggerIngestMindaData(currentFarm);
    setSnackBarMessage(responseMessage);
    setSnackBarOpen(true);
    setIsRefreshingMindaData(false);
    setRefreshingMindaDataText(preFetchText);
  };

  return (
    <Grid container spacing={3} style={{ padding: '8px' }}>
      <Grid item lg={12}>
        <Card style={{ width: '100%' }}>
          <CardContent>
            <Grid container spacing={3} style={{ padding: '8px' }}>
              {calvingSummary && (
                <Grid item lg={10}>
                  <Typography variant="h6">Calving summary for the last 6 months</Typography>
                  <Typography>{`Calved: ${calvingSummary.calvedCount}`}</Typography>
                  <Typography>{`In-calf: ${calvingSummary.inCalfCount}`}</Typography>
                  <Typography>{`Recovering Poorly: ${calvingSummary.recoveringPoorlyCount}`}</Typography>
                </Grid>
              )}
              <Grid item lg={2}>
                {mindaIntegrationStatus ? (
                  <Button
                    text={refreshingMindaDataText}
                    colour="white"
                    backgroundColour="black"
                    onClick={handleFetchPregnancyTestResults}
                    disabled={isRefreshingMindaData}
                  />
                ) : (
                  <Tooltip title="If farm has MINDA you can ask them to integrate in app">
                    <Button text="Farm not integrated with MINDA" colour="white" backgroundColour="black" />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12}>
        {loading ? (
          <SpinnerWrapper>
            <CircularProgress size="40px" />
          </SpinnerWrapper>
        ) : (
          <Card style={{ width: '100%' }}>
            <CalvingTable calvingInfo={calvingDTO} farmId={currentFarm} />
          </Card>
        )}
      </Grid>
      <SimpleSnackbar message={snackBarMessage} open={snackBarOpen} />
    </Grid>
  );
};

export default CalvingPanel;
