import React, { FC } from 'react';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import styled from 'styled-components';
import { ITowerNoteDTO } from '@halter-corp/tower-service-client';

const NoteWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  .caution {
    color: #f4511e;
  }

  &:hover,
  &:hover .caution {
    color: #1976d2;
  }
`;

type TowerNoteProps = {
  notes: ITowerNoteDTO[];
  onClick: () => void;
};

const TowerNote: FC<TowerNoteProps> = ({ notes, onClick }) => {
  const notesCount = notes.length;
  const cautionNotesCount = notes.reduce((count, curr) => (curr.caution ? count + 1 : count), 0);

  return (
    <NoteWrapper onClick={onClick}>
      {notesCount} note{notesCount !== 1 && 's'}
      {cautionNotesCount > 0 && (
        <span className="caution">
          ({cautionNotesCount} caution{cautionNotesCount !== 1 && 's'})
        </span>
      )}
      <OpenInNewOutlinedIcon />
    </NoteWrapper>
  );
};

export default TowerNote;
