import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CommandTypeEnum, ICommandContextDTO, CommandOriginEnum } from '@halter-corp/cowtroller-service-client';
import { Paper, Tabs, Tab, Grid, LinearProgress, Typography } from '@material-ui/core';
import { useDispatch } from 'store';
import reportsEffects from 'store/effects/reports.effects';
import farmEffects from 'store/effects/farm.effects';
import topographyEffects from 'store/effects/topography.effects';
import BusinessService from 'services/business.service';
import MobService from 'services/mob.service';
import ReportsService from 'services/reports.service';
import TopographyService from 'services/topography.service';
import mobEffects from 'store/effects/mob.effects';
import ZoneSummaryPanel from 'application/modules/reports/panels/zone-summary.panel';
import TransitionMetricsPanel from 'application/modules/reports/panels/transition-metrics.panel';
import MetricsHeader from 'application/modules/reports/components/metrics.header';
import InfoBox from 'application/modules/reports/components/info.box';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 2rem;
  align-items: center;
`;

const TabPanel = styled.div`
  padding: 1rem;
`;

const getReportName = (type: CommandTypeEnum) => {
  switch (type) {
    case CommandTypeEnum.SET_ZONE:
      return 'Set Zone';
    case CommandTypeEnum.EXIT_ZONE:
      return 'Exit Paddock';
    default:
      return '';
  }
};

const getTransitionTabLabel = (type: CommandTypeEnum, context: ICommandContextDTO) => {
  let transitionType = '';
  switch (type) {
    case CommandTypeEnum.SET_ZONE:
      transitionType = 'Entry Paddock';
      if (context.origin === CommandOriginEnum.DROP_FENCE_SHIFT_TO_NEXT) {
        transitionType = 'Break to Break';
      }
      break;
    case CommandTypeEnum.EXIT_ZONE:
      transitionType = 'Exit Paddock';
      break;
    default:
      break;
  }

  return `${transitionType} Transition`;
};

const getOrigin = (type: CommandTypeEnum, origin: CommandOriginEnum | undefined): string => {
  if (type === CommandTypeEnum.SET_ZONE) {
    switch (origin) {
      case CommandOriginEnum.DROP_FENCE_SHIFT_TO_NEXT:
        return 'Break to Break';
      default:
        return 'Upcoming Break Fence';
    }
  }

  return '';
};

const ReportDetailScreen: FunctionComponent = () => {
  const dispatch = useDispatch();

  const params = useParams() as { commandId: string };
  const commandId = useMemo(() => params.commandId, [params]);
  const [currentTab, setCurrentTab] = useState('Transition');

  const farms = BusinessService.useFetchFarmList();
  const loading = ReportsService.useIsLoading();
  const report = ReportsService.useReport();
  const currentReport = ReportsService.useReportData();
  const paddocks = TopographyService.usePaddocks();
  const mobs = MobService.useFetchMobList();

  useEffect(() => {
    dispatch(reportsEffects.fetchReportDetails({ commandId }));
    dispatch(farmEffects.fetch());
  }, [dispatch, commandId]);

  useEffect(() => {
    if (report != null) {
      dispatch(topographyEffects.fetchPaddocks({ farmId: report.farmId }));
      dispatch(mobEffects.fetch({ farmId: report.farmId }));
      dispatch(reportsEffects.fetchReportData({ collarCommandId: report.collarCommandId }));
    }
  }, [dispatch, report]);

  if (report == null) {
    return null;
  }

  const farm = farms.find(({ id }) => id === report.farmId);
  const paddock = paddocks.find(({ id }) => id === report.paddockId);
  const isZoneSummaryVisible = report.type === CommandTypeEnum.SET_ZONE;

  return (
    <>
      <LinearProgress variant={loading ? 'query' : 'determinate'} value={100} />
      <Wrapper>
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">{getReportName(report.type)}</Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {getOrigin(report.type, report.context.origin)}
            </Typography>
          </Grid>

          <MetricsHeader>
            <InfoBox title="Farm">{farm?.name}</InfoBox>
            <InfoBox title="Paddock">{paddock?.feature.properties.name}</InfoBox>
            <InfoBox title="Command ID">{report.collarCommandId}</InfoBox>
          </MetricsHeader>

          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={currentTab}
                onChange={(e, tab) => {
                  setCurrentTab(tab);
                }}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab value="Transition" label={getTransitionTabLabel(report.type, report.context)} />
                {isZoneSummaryVisible && <Tab value="Zone" label="Hold In Zone" />}
              </Tabs>
            </Paper>

            {currentTab === 'Transition' && (
              <TabPanel>
                {currentReport?.transition && (
                  <TransitionMetricsPanel transition={currentReport.transition} mobs={mobs} />
                )}
              </TabPanel>
            )}
            {currentTab === 'Zone' && (
              <TabPanel>
                {currentReport?.zone && <ZoneSummaryPanel zone={currentReport.zone} mobs={mobs} />}
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default ReportDetailScreen;
