import React, { FunctionComponent } from 'react';
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

type RequestSwapProgressProps = {
  totalSwaps: number;
  swapsSent: number;
  failedSerialNumbers: string[];
};

const RequestSwapProgress: FunctionComponent<RequestSwapProgressProps> = ({ totalSwaps, swapsSent, failedSerialNumbers }) => (
    <>
      <Grid item container xs={12}>
        <Typography style={{ fontSize: 16 }}>
          Swaps sucessfully requested: {swapsSent}/{totalSwaps}
          {swapsSent === totalSwaps && (" ✅ ")}
          {failedSerialNumbers.length > 0 && (" ❌ ")}
        </Typography>
      </Grid>
      {failedSerialNumbers.length > 0 && (
        <>
          <Grid item container xs={12}>
            <Typography style={{ marginBottom: '8px' }}>Failed to request a swap:</Typography>
            <TextField
              multiline
              rows={3}
              type="string"
              value={failedSerialNumbers.join(", ")}
              variant="outlined"
              fullWidth
              focused={false}
            />
          </Grid>
        </>
      )}
    </>
  );

export default RequestSwapProgress;
