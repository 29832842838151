import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type SearchParameters = {
  farmId: string;
  shardId: string;
};

export const useParseQueryParameters = (): SearchParameters => {
  const location = useLocation();

  return useMemo(() => {
    const urlQueryString = new URLSearchParams(location.search);

    const farmIdFromQueryString = urlQueryString.get('farmId');

    const shardIdFromQueryString = urlQueryString.get('shardId');

    return {
      farmId: farmIdFromQueryString || '',
      shardId: shardIdFromQueryString || '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
};
