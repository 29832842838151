import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import HeatMapContainer from './containers/heat-map.container';


const HeatMapModule: FunctionComponent = () => (
  <Switch>
    <Route exact path="/heatMap">
      <HeatMapContainer />
    </Route>
  </Switch>
);

export default HeatMapModule;
