import { createAsyncThunk } from '@reduxjs/toolkit';

import HttpApiService from 'services/http-api.service';
import {
  ICalvingDTO,
  ICalvingSummaryDTO,
  ICowHeatEventDTO,
  ISeasonDTO,
  ISeasonMetricDataDTO,
  ISeasonMetricsSummaryDTO,
} from '@halter-corp/cattle-breeding-service-client';

import {
  ICurrentHealthCaseSummariesDTO,
  IHealthCaseDTO,
  IHistoricHealthCasesDTO,
} from '@halter-corp/cow-health-service-client';
import { ICowInterventionsDTO } from '@halter-corp/mating-service-client';

const behaviourEffects = {
  fetchHeats: createAsyncThunk(
    'behaviour/heat/fetch',
    async (props: { farmId: string }): Promise<ICowHeatEventDTO[]> => {
      const seasonApi = await HttpApiService.getSeasonApi(props.farmId);
      const heats: ICowHeatEventDTO[] = [];
      const { data: initialHeats } = await seasonApi.findHeatEvents('current', 300);
      heats.push(...initialHeats);
      while (heats.length % 300 === 0) {
        const oldestEvent = heats[heats.length - 1];
        const oldestEventFirstDetectedAt = new Date(oldestEvent.firstDetectedAt);
        // eslint-disable-next-line no-await-in-loop
        const { data: moreHeats } = await seasonApi.findHeatEvents(
          'current',
          300,
          undefined,
          oldestEventFirstDetectedAt ?? undefined
        );
        if (moreHeats.length === 0) {
          return heats;
        }
        heats.push(...moreHeats);
      }
      return heats;
    }
  ),

  fetchHeatsByCattleId: createAsyncThunk(
    'behaviour/heat/fetchByCattleId',
    async (props: { farmId: string; cattleId: string }): Promise<ICowHeatEventDTO[]> => {
      const seasonApi = await HttpApiService.getSeasonApi(props.farmId);
      const { data: heats } = await seasonApi.findHeatEvents(
        'current',
        300,
        props.cattleId,
        undefined,
        undefined,
        undefined
      );
      return heats;
    }
  ),

  fetchMetricData: createAsyncThunk(
    'behaviour/metrics/fetch',
    async (props: { farmId: string }): Promise<ISeasonMetricDataDTO> => {
      const seasonApi = await HttpApiService.getSeasonApi(props.farmId);
      const { data: seasonMetrics } = await seasonApi.findMetricData('current');
      return seasonMetrics;
    }
  ),

  fetchMetricsSummary: createAsyncThunk(
    'behaviour/metricsSummary/fetch',
    async (props: { farmId: string }): Promise<ISeasonMetricsSummaryDTO> => {
      const seasonApi = await HttpApiService.getSeasonApi(props.farmId);
      const { data: metricsSummary } = await seasonApi.findSeasonMetricsSummary('current', {});
      return metricsSummary;
    }
  ),

  fetchSeason: createAsyncThunk(
    'behaviour/season/fetch',
    async (props: { farmId: string }): Promise<ISeasonDTO> => {
      const seasonApi = await HttpApiService.getSeasonApi(props.farmId);
      const { data: season } = await seasonApi.findSeason('current');
      return season;
    }
  ),

  fetchCurrentHealthCases: createAsyncThunk(
    'behaviour/healthCases/current',
    async (props: { farmId: string }): Promise<ICurrentHealthCaseSummariesDTO> => {
      const cowHealthApi = await HttpApiService.getCowHealthApi(props.farmId);
      const { data: currentHealthCases } = await cowHealthApi.getCurrentSummaryHealthCases();
      return currentHealthCases;
    }
  ),

  fetchHistoricalHealthCases: createAsyncThunk(
    'behaviour/healthCases/historical',
    async (props: { farmId: string }): Promise<IHistoricHealthCasesDTO> => {
      const cowHealthApi = await HttpApiService.getCowHealthApi(props.farmId);
      const { data: currentHealthCases } = await cowHealthApi.getHistoricHealthCases(0, 100);
      return currentHealthCases;
    }
  ),

  fetchHealthCasesByCattleId: createAsyncThunk(
    'behaviour/healthCase/fetchByCattleId',
    async (props: { farmId: string; cattleId: string }): Promise<IHealthCaseDTO[]> => {
      const cowHealthApi = await HttpApiService.getCowHealthApi(props.farmId);
      const { data: healthCases } = await cowHealthApi.getCowHealthCases(props.cattleId);
      return healthCases;
    }
  ),

  fetchIsMindaIntegrated: createAsyncThunk(
    'behaviour/repro/fetchMindaIntegrationStatus',
    async (props: { farmId: string }): Promise<boolean> => {
      const mindaAdapterApi = await HttpApiService.getMindaAdapterApi(props.farmId);
      try {
        await mindaAdapterApi.findFarmMindaConfig(props.farmId);
        return true;
      } catch (err) {
        return false;
      }
    }
  ),

  fetchCalvingInfo: createAsyncThunk(
    'behaviour/calving/fetch',
    async (props: { farmId: string }): Promise<ICalvingDTO[]> => {
      const calvingApi = await HttpApiService.getCalvingApi(props.farmId);
      const { data: calvingInfo } = await calvingApi.findCalvings();
      return calvingInfo;
    }
  ),

  fetchCalvingSummary: createAsyncThunk(
    'behaviour/calving-summary/fetch',
    async (props: { farmId: string }): Promise<ICalvingSummaryDTO> => {
      const calvingApi = await HttpApiService.getCalvingApi(props.farmId);
      const { data: calvingSummary } = await calvingApi.getCalvingSummary();
      return calvingSummary;
    }
  ),

  fetchInterventions: createAsyncThunk(
    'behaviour/interventions/fetch',
    async (props: { seasonId: string; farmId: string }): Promise<ICowInterventionsDTO[]> => {
      const interventionApi = await HttpApiService.getInterventionApi(props.farmId);
      const { data: interventions } = await interventionApi.getInterventionsForSeason(props.seasonId);
      return interventions;
    }
  ),
};

export default behaviourEffects;
