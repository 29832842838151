import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Box,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { CattleEvent } from 'store/selectors/map-page-items.selectors';
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Column } from 'application/components/data-table';
import { CattleEventsSummary } from '../../panels/map.panel/events-summary.panel';

export type ExpandableCowgEventRowProps = {
  data: CattleEventsSummary;
  columns: Column<CattleEventsSummary>[];
};

const ExpandableCowgEventRow: FunctionComponent<ExpandableCowgEventRowProps> = ({ data, columns }) => {
  const [open, setOpen] = React.useState(false);

  const numberOfColumns = columns.length + 1; // +1 for the expand icon

  return (
    <>
      <TableRow>
        <TableCell style={{ width: 5, padding: 4 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {columns.map((column, colIndex) => (
          <Tooltip
            // eslint-disable-next-line react/no-array-index-key
            key={colIndex}
            title={column.toolTip ? column.toolTip(data) : ''}
            enterDelay={500}
            style={{ whiteSpace: 'pre' }}
          >
            <TableCell
              // eslint-disable-next-line react/no-array-index-key
              key={colIndex}
              style={{
                color: '#454545',
                paddingLeft: 3,
                paddingRight: 3,
              }}
            >
              {column.render(data, 0)}
            </TableCell>
          </Tooltip>
        ))}
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={numberOfColumns}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>Time</TableCell>
                    <TableCell>CowgStatuses</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.items?.map((detailsRow: CattleEvent) => (
                    <TableRow key={`${detailsRow.id}-${detailsRow.timestamp}`}>
                      <TableCell>{moment(detailsRow.timestamp).format('DD/MM/YYYY, h:mm:ss')}</TableCell>
                      <TableCell>{JSON.stringify(detailsRow.cowgStatuses)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ExpandableCowgEventRow;
