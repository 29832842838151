import React from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { toArray } from 'lodash';
import { IFarmDTO } from '@halter-corp/business-service-client';
import BusinessService from 'services/business.service';
import TrainingService from 'services/training.service';
import Wrapper from 'application/components/wrapper';
import Button from 'application/components/button';
import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';
import { ApplyProfileOption } from 'data/training';
import SelectField from '../components/select-field';
import { GetApplyProfilesSummaryFormValues } from './types';
import { useProfilesByType } from '../../hooks/use-profiles-by-type';
import ProfileSelect from './components/profile-select';
import { prettyPrintProfileType } from '../../utils';
import { getInitialProfilesValues } from './utils';

type ApplyProfilesFormContentProps = {
  onChange?: () => void;
  disableSubmit: boolean;
};

const ApplyProfilesFormConetent = (props: ApplyProfilesFormContentProps) => {
  const { onChange, disableSubmit } = props;

  const { setValues, setFieldValue, handleSubmit, values, errors } =
    useFormikContext<GetApplyProfilesSummaryFormValues>();
  const { farmId } = values;

  const farms = BusinessService.useFetchFarmList();

  const profiles = TrainingService.useSelectProfilesByFarmId(farmId);
  const profilesByType = useProfilesByType(profiles);

  const handleChange = () => {
    if (onChange != null) {
      onChange();
    }
  };

  const onFarmChange = (newFarmId: string) => {
    setValues({
      ...values,
      farmId: newFarmId,
      trainings: getInitialProfilesValues(newFarmId).trainings,
    });
    handleChange();
  };

  const onProfileChange = (type: TrainingSettingsTypeEnum) => (option: ApplyProfileOption) => {
    setFieldValue(`trainings[${type}]`, option);
    handleChange();
  };

  const onSubmitPress = () => {
    handleSubmit();
  };

  return (
    <Wrapper>
      <>
        <InputRow>
          <SelectField<IFarmDTO>
            label="Farm"
            options={farms}
            value={values.farmId}
            valueExtractor={(farm) => farm.id}
            displayNameExtractor={(farm) => farm.name!}
            onChange={onFarmChange}
            hasError={errors.farmId != null}
          />
        </InputRow>
        <InputRow>
          {toArray(TrainingSettingsTypeEnum).map((type) => (
            <ProfileSelect
              key={type}
              label={prettyPrintProfileType(type)}
              profiles={profilesByType[type]}
              selected={values.trainings[type]}
              onChange={onProfileChange(type)}
            />
          ))}
        </InputRow>
        <ButtonWrapper>
          <Button text="Next" onClick={onSubmitPress} disabled={disableSubmit} />
        </ButtonWrapper>
      </>
    </Wrapper>
  );
};

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
`;

const ButtonWrapper = styled(Wrapper)`
  flex-direction: row;
  justify-content: center;
`;

export default ApplyProfilesFormConetent;
