import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import GenericSwitch from 'application/components/generic-switch';

import BusinessService from 'services/business.service';
import DeviceService from 'services/device.service';

import { IEngineeringSettingsDTO } from '@halter-corp/settings-service-client';
import { Device } from 'data/device';
import { flatten } from 'flat';
import { omit } from 'lodash';

import DataTable from '../../../components/data-table';

import { deviceSyncingStatusToTrafficLight } from '../components/device-status.util';

import {
  batteryVoltageColumn,
  batteryPercentageColumn,
  indexColumn,
  cowColumn,
  farmColumn,
  firmwareVersionColumn,
  mobColumn,
  serialNumberColumn,
} from './common-columns';

type PowerTableProps = {
  devices: Device[];
  mode: 'farm' | 'device';
};

const flattenSettings = (settings?: object): { [key: string]: string | number } => {
  if (settings == null) return {};

  return flatten<any, { [key: string]: string | number }>(omit(settings, 'loraSettings'));
};

const isHibernationModeForcedOn = (device: Device) => {
  const hibernationMode = flattenSettings(device.settingsV2?.settingsObject)[
    'engineering.powerSaver.forceOn'
  ] as number;
  return hibernationMode > 0;
};

const isHibernationModeAutoOn = (device: Device) => {
  const hibernationThereshold = flattenSettings(device.settingsV2?.settingsObject)[
    'engineering.powerSaver.onThresholdBatteryVoltageMv'
  ] as number;
  const batteryVoltage = device.stats?.batteryVoltage ?? 4200; // If no battery voltage is available, assume hibernation is not on
  return batteryVoltage < hibernationThereshold;
};

const isHibernationModeOn = (device: Device) =>
  isHibernationModeForcedOn(device) || isHibernationModeAutoOn(device);

const setHibernationMode = async (device: Device) => {
  const currentSettings: IEngineeringSettingsDTO | undefined = device.settingsV2?.settingsObject;

  if (currentSettings === undefined) {
    return;
  }

  const newSettings: IEngineeringSettingsDTO = {
    ...currentSettings,
    powerSaver: { ...currentSettings.powerSaver, forceOn: !isHibernationModeForcedOn(device) },
  };
  await DeviceService.updateEngineeringSettings(device.serialNumber, newSettings, device.farmId);
  await DeviceService.updateDevice([device]);
};

const PowerTable: FunctionComponent<PowerTableProps> = ({ devices, mode }) => {
  const history = useHistory();

  const farms = BusinessService.useFetchFarmList();

  return (
    <DataTable<Device>
      data={devices}
      defaultSortColumnName="Serial number"
      onSelectRow={(device) => history.push(`/devices/${device.serialNumber}`)}
      columns={[
        indexColumn,
        cowColumn,
        serialNumberColumn,
        ...(mode === 'device' ? [farmColumn(farms)] : []),
        mobColumn,
        firmwareVersionColumn,
        batteryPercentageColumn,
        batteryVoltageColumn,
        {
          name: 'Settings sync status',
          keyExtractor: (device) => device.settingsV2?.status,
          render: (device) => (
            <>
              {`${device.settingsV2?.status}`}
              {deviceSyncingStatusToTrafficLight(device.settingsV2?.status)}
            </>
          ),
        },
        {
          name: 'Hibernation Mode',
          keyExtractor: (device: Device) => isHibernationModeOn(device),
          render: (device: Device) => (
            <GenericSwitch
              value={isHibernationModeOn(device)}
              disabled={isHibernationModeAutoOn(device)}
              requireConfirmation
              confirmationMessage={`CUSTOMERS MUST BE INFORMED OF HIBERNATING COLLARS. Are you sure you want to ${
                isHibernationModeForcedOn(device) ? 'disable' : 'enable'
              } hibernation mode for ${
                device?.cattle?.name != null ? `cow ${device.cattle.name}` : ''
              }, collar ${device?.serialNumber}?`}
              onChange={() => setHibernationMode(device)}
            />
          ),
        },
      ]}
    />
  );
};

export default PowerTable;
