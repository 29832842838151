import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';

import { ICreateTrainingProfileRequestDTO } from '@halter-corp/training-service-client';

import { useDispatch } from 'store';
import trainingEffects from 'store/effects/training.effects';

import ReactGA from 'react-ga';

import ScreenWrapper from 'application/components/screen-wrapper';
import ProfileForm from '../forms/profile/profile.form';

const CreateProfileScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handlers
  //-----------------------------------------------------

  const handleSubmit = async (request: ICreateTrainingProfileRequestDTO) => {
    setIsSubmitting(true);
    const result = await dispatch(trainingEffects.createProfile({ request }));

    setIsSubmitting(false);
    if (trainingEffects.createProfile.fulfilled.match(result)) {
      history.push('/training/profiles');
    }

    ReactGA.event({
      category: 'User action',
      action: 'Create new profile button pressed',
    });
  };

  // Render
  //-----------------------------------------------------

  return (
    <ScreenWrapper>
      <Grid item xs={12} sm={10} lg={8}>
        <Box mt={4} mb={8}>
          <Typography variant="h5">Create new profile</Typography>
          <Box mt={4}>
            <ProfileForm onSubmit={handleSubmit} isSubmitting={isSubmitting} />
          </Box>
        </Box>
      </Grid>
    </ScreenWrapper>
  );
};

export default CreateProfileScreen;
