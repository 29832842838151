import { createSlice, PayloadAction, EntityState } from '@reduxjs/toolkit';
import { IFarmDTO } from '@halter-corp/business-service-client';

import farmAdapter from 'store/adapters/farm.adapter';
import { reset } from 'store/actions';
import farmEffects from 'store/effects/farm.effects';

type FarmContextInitialState = {
  entityState: EntityState<IFarmDTO>;
  currentFarm?: string;
};

const initialState: FarmContextInitialState = {
  entityState: farmAdapter.getInitialState(),
  currentFarm: undefined,
};

const farmContextSlice = createSlice({
  initialState,
  name: 'farmContextSlice',
  reducers: {
    clear: () => initialState,

    setCurrentFarm: (state, action: PayloadAction<{ currentFarm?: string }>) => {
      state.currentFarm = action.payload.currentFarm;
      window.localStorage.setItem('currentFarm', state.currentFarm || '');
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(farmEffects.fetch.fulfilled, (state, action) => {
        const entities = action.payload;

        farmAdapter.removeAll(state.entityState);
        farmAdapter.addMany(state.entityState, entities);
      }),
});

export default farmContextSlice;
