import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useSelector as useBaseSelector,
  useDispatch as useBaseDispatch,
} from 'react-redux';

import farmContextSlice from './slices/farm-context.slice';
import mobSlice from './slices/mob.slice';
import mapPageItemsSlice from './slices/map-page-items.slice';
import debugCattleSlice from './slices/debug-cattle.slice';
import devicesOverviewSlice from './slices/devices-overview.slice';
import devicesProvisioningSlice from './slices/devices-provisioning.slice';
import deviceSlice from './slices/device.slice';
import trainingSlice from './slices/training.slice';
import cattleSlice from './slices/cattle.slice';
import settingsSlice from './slices/settings.slice';
import reportsSlice from './slices/reports.slice';
import topographySlice from './slices/topography.slice';
import farmStatusSlice from './slices/farm-status.slice';
import thingsSlice from './slices/things.slice';
import towerSlice from './slices/tower.slice';
import infrastructureSlice from './slices/infrastructure.slice';
import farmInfraSlice from './slices/farm-infra.slice';
import devicesSlice from './slices/devices.slice';
import behaviourSlice from './slices/behaviour.slice';
import heatMapSlice from './slices/heat-map.slice';
import fleetSlice from './slices/fleet.slice';
import grazingHeatmapSlice from './slices/grazing-heat-map.slice';

const reducer = combineReducers({
  farmContext: farmContextSlice.reducer,
  farmStatus: farmStatusSlice.reducer,
  mobs: mobSlice.reducer,
  mapPageItems: mapPageItemsSlice.reducer,
  debugCattle: debugCattleSlice.reducer,
  tower: towerSlice.reducer,
  heatMap: heatMapSlice.reducer,
  things: thingsSlice.reducer,
  farmInfra: farmInfraSlice.reducer,
  devicesOverview: devicesOverviewSlice.reducer,
  devicesProvisioning: devicesProvisioningSlice.reducer,
  device: deviceSlice.reducer,
  devices: devicesSlice.reducer,
  fleet: fleetSlice.reducer,
  infrastructure: infrastructureSlice.reducer,
  training: trainingSlice.reducer,
  cattle: cattleSlice.reducer,
  settings: settingsSlice.reducer,
  reports: reportsSlice.reducer,
  topography: topographySlice.reducer,
  behaviour: behaviourSlice.reducer,
  grazingHeatmap: grazingHeatmapSlice.reducer,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<AppState> = useBaseSelector;
export const useDispatch = () => useBaseDispatch<AppDispatch>();

export default store;
