import React, { FunctionComponent, useMemo, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import { IUpdateTrainingProfileRequestDTO } from '@halter-corp/training-service-client';

import { useDispatch } from 'store';
import trainingEffects from 'store/effects/training.effects';
import TrainingService from 'services/training.service';

import ScreenWrapper from 'application/components/screen-wrapper';

import ReactGA from 'react-ga';

import Button from 'application/components/button';
import ProfileForm from '../forms/profile/profile.form';

import DeleteModal from '../components/delete-modal';

const HeaderWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 16px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  opacity: 0.9;
  padding: 12px;
`;

const ShowProfileScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams() as { profileId: string };
  const profileId = useMemo(() => params.profileId, [params]);

  useEffect(() => {
    dispatch(trainingEffects.fetchProfiles());
  }, [dispatch, profileId]);

  const profile = TrainingService.useSelectProfileById(profileId);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (id: string, request: IUpdateTrainingProfileRequestDTO) => {
    setIsSubmitting(true);
    const result = await dispatch(trainingEffects.updateProfile({ profileId: id, request }));

    setIsSubmitting(false);
    if (trainingEffects.updateProfile.fulfilled.match(result)) {
      // TODO we should just exit out of 'edit' mode instead, for now this is an indication that the request succeeded
      history.push('/training/profiles');
    }

    ReactGA.event({
      category: 'User action',
      action: 'Edit profile button pressed',
    });
  };

  const deleteProfile = async (id: string) => {
    const result = await dispatch(trainingEffects.deleteProfile({ profileId: id }));

    if (trainingEffects.deleteProfile.fulfilled.match(result)) {
      history.push('/training/profiles');
    }
  };

  return (
    <ScreenWrapper>
      <Grid item xs={12} sm={10} lg={8}>
        <HeaderWrapper>
          <Title>Edit profile</Title>
          {profile != null && (
            <Button
              text="Delete"
              style={{
                backgroundColor: '#f44336',
                color: 'white',
                marginLeft: 'auto',
                padding: '8px 24px 8px 24px',
              }}
              onClick={() => setDeleteModalOpen(true)}
            />
          )}
        </HeaderWrapper>
        {profile && (
          <ProfileForm
            onSubmit={(values) => handleSubmit(profile.id, values)}
            isSubmitting={isSubmitting}
            initialProfile={profile}
          />
        )}
      </Grid>
      {profile != null && (
        <DeleteModal
          open={deleteModalOpen}
          type="profile"
          name={profile.name}
          onConfirm={() => deleteProfile(profile.id)}
          onDismiss={() => setDeleteModalOpen(false)}
        />
      )}
    </ScreenWrapper>
  );
};

export default ShowProfileScreen;
