import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Wrapper from 'application/components/wrapper';
import {
  LinearProgress,
  Box,
  Divider,
  makeStyles,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Switch,
} from '@material-ui/core';
import Header from 'application/components/header';

import farmStatusEffects from 'store/effects/farm-status.effects';
import { useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import FleetService from 'services/fleet.service';
import { Row, Column, FarmStatusMode } from '../utils';
import { CaddieColours } from '../../../theme/colours';

import FarmEmergencyDialog from '../components/farm-emergency.dialog';
import FarmStatusDropdown from '../components/farm-status.dropdown';
import FarmActiveDialog from '../components/farm-active.dialog';
import { FleetFarmState } from '../containers/fleet-view.container';
import FleetSummaryTable from '../components/fleet-summary.table';
import FarmSummaryCard from '../components/fleet-summary.card';

type FleetScreenProps = {
  favourites: string;
  farmStateData: FleetFarmState[];
};

export type FleetFarmData = {
  farmName: string;
  key: string;
  farmId: string;
  status: FarmStatusMode;
  collarCountByStatusMap: Map<DeviceOnFarmStatusEnum, number>;
};

type FarmSelectedState = {
  farmId: string;
  farmName: string;
  isPinned: boolean;
  status: FarmStatusMode;
};

const useStyles = makeStyles(() => ({
  HeaderBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const FleetViewScreen: FunctionComponent<FleetScreenProps> = ({ favourites, farmStateData }) => {
  const dispatch = useDispatch();
  const loading = FleetService.useIsDeviceOnFarmStatusCountsByFarmIdLoading();

  const classes = useStyles();
  const [filteredFarmCardContent, setFilteredFarmCardContent] = useState<FleetFarmData[]>([]);
  const [selectedFarms, setSelectedFarms] = useState<FarmSelectedState[]>([]);
  const favouritesFromBrowser: string[] = favourites ? JSON.parse(favourites) : '';
  const [pinnedFarmsList, setPinnedFarmsList] = useState<string[]>(favouritesFromBrowser);
  const [isEmergencyOpen, setIsEmergencyOpen] = useState(false);
  const [isActiveOpen, setIsActiveOpen] = useState(false);

  const [includeNotLiveFarms, setIncludeNotLiveFarms] = useState<boolean>(
    window.localStorage.getItem('includeNotLiveFarms') === 'true' ?? false
  );

  const [includeInternalFarms, setIncludeInternalFarms] = useState<boolean>(
    window.localStorage.getItem('includeInternalFarms') === 'true' ?? false
  );

  const handleActiveOpen = (isOpen: boolean) => {
    setIsActiveOpen(isOpen);
  };

  const handleEmergencyOpen = (isOpen: boolean) => {
    setIsEmergencyOpen(isOpen);
  };

  const clearSelectedFarms = () => {
    setSelectedFarms([]);
  };

  const toggleIncludeNotLiveFarms = () => {
    setIncludeNotLiveFarms(!includeNotLiveFarms);
    window.localStorage.setItem('includeNotLiveFarms', JSON.stringify(!includeNotLiveFarms));
  };

  const toggleIncludeInternalFarms = () => {
    setIncludeInternalFarms(!includeInternalFarms);
    window.localStorage.setItem('includeInternalFarms', JSON.stringify(!includeInternalFarms));
  };

  const farmCardContent: FleetFarmData[] = useMemo(
    () =>
      farmStateData.map((farmState) => {
        const { farmId, farmName, status, collarCountByStatusMap } = farmState;

        return {
          farmName,
          key: farmState.farmName || '',
          farmId,
          colour: 0,
          status,
          collarCountByStatusMap,
        };
      }),
    [farmStateData]
  );

  const toggleMaintenanceMode = (currentMaintenanceMode: boolean, farmId: string) => {
    if (currentMaintenanceMode == null) {
      return;
    }
    if (currentMaintenanceMode === true) {
      dispatch(farmStatusEffects.setMaintenanceOff({ farmId }));
      return;
    }
    dispatch(farmStatusEffects.setMaintenanceOn({ farmId }));
  };

  const updateFilteredFarmCardContent = async (search: string) => {
    setFilteredFarmCardContent(
      farmCardContent
        .filter((item) => includeNotLiveFarms || item.status !== FarmStatusMode.NotLive)
        .filter((item) => includeInternalFarms || !item.farmName.includes('Internal'))
        .filter((farm) => farm.farmName.toLowerCase().includes(search.toLowerCase()))
    );
  };

  useMemo(() => {
    updateFilteredFarmCardContent('');
  }, [farmCardContent, includeNotLiveFarms, includeInternalFarms]);

  const pinnedFarms = useMemo(
    () => filteredFarmCardContent.filter((item) => pinnedFarmsList?.includes(item.farmName)),
    [pinnedFarmsList, filteredFarmCardContent]
  );

  const unpinnedFarms = useMemo(
    () => filteredFarmCardContent.filter((item) => !pinnedFarmsList?.includes(item.farmName)),
    [pinnedFarmsList, filteredFarmCardContent]
  );

  useEffect(() => {
    localStorage.setItem('favourites', JSON.stringify(pinnedFarmsList));
  }, [pinnedFarmsList]);

  const handleSelectAllFarms = (isPinned: boolean, hasSelectedAllFarms: boolean) => {
    if (isPinned) {
      if (!hasSelectedAllFarms) {
        setSelectedFarms(selectedFarms.filter((farm) => !farm.isPinned));
        return;
      }

      const newSelectedFarms = pinnedFarms.map((farm: FleetFarmState) => {
        const { farmId, farmName, status } = farm;
        return { farmId, farmName, status, isPinned: true } as FarmSelectedState;
      });

      setSelectedFarms([...selectedFarms, ...newSelectedFarms]);
      return;
    }

    if (!hasSelectedAllFarms) {
      setSelectedFarms(selectedFarms.filter((farm) => farm.isPinned));
    } else {
      const newSelectedFarms = unpinnedFarms.map((farm: FleetFarmState) => {
        const { farmId, farmName, status } = farm;
        return { farmId, farmName, status, isPinned: false } as FarmSelectedState;
      });
      setSelectedFarms([...selectedFarms, ...newSelectedFarms]);
    }
  };

  const handleSelectedFarm = (item: FleetFarmState) => {
    let newselectedFarms = [];
    const farmStateIndex = selectedFarms.findIndex((farm) => farm.farmId === item.farmId);
    if (farmStateIndex > -1) {
      newselectedFarms = [...selectedFarms];
      newselectedFarms.splice(farmStateIndex, 1);
    } else {
      newselectedFarms = [
        ...selectedFarms,
        {
          farmId: item.farmId,
          farmName: item.farmName,
          isPinned: pinnedFarmsList?.includes(item.farmName),
          status: item.status,
        },
      ];
    }
    setSelectedFarms(newselectedFarms);
  };

  const handleSelectedFarmsFilterChange = (farmName: string) => {
    const newSelectedFarms = [...selectedFarms];
    const farm = newSelectedFarms.find((f) => f.farmName === farmName);

    if (farm) {
      farm.isPinned = !farm.isPinned;
    }
  };

  const handleFilterChange = (event: any) => {
    if (typeof event === 'string') {
      setPinnedFarmsList((searchFilter) =>
        searchFilter.includes(event)
          ? searchFilter.filter((item) => item !== event)
          : [...searchFilter, event]
      );
      handleSelectedFarmsFilterChange(event);
    } else {
      setPinnedFarmsList(event.target.value as string[]);
    }
  };

  return (
    <Wrapper style={{ backgroundColor: CaddieColours.LightGrey, minHeight: 'calc(100vh - 44px)' }}>
      <LinearProgress
        variant={loading ? 'query' : 'determinate'}
        value={100}
        style={{ position: 'sticky' }}
      />
      <Row style={{ maxWidth: '100%', overflowX: 'scroll' }}>
        <Column>
          <div style={{ marginLeft: '3vw', marginRight: '3vw' }}>
            <Box m={0}>
              <Header text="Fleet Overview" size="large" style={{ marginTop: '24px', marginLeft: '16px' }} />
              <Divider style={{ marginBottom: '16px' }} />
              <Box
                m={2}
                py={{ xs: 1, lg: 0 }}
                display="flex"
                flexDirection={{ xs: 'column', lg: 'row' }}
                gridRowGap="20px"
                className={classes.HeaderBar}
              >
                <Grid container spacing={2} alignItems="center">
                  <Box width={{ xs: '100%', lg: '350px' }}>
                    <TextField
                      fullWidth
                      style={{
                        backgroundColor: 'white',
                        height: '44px',
                        borderRadius: '8px',
                      }}
                      type="search"
                      placeholder="Search All Farms"
                      InputProps={{
                        style: { textAlign: 'center', margin: '8px' },
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => updateFilteredFarmCardContent(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                  <Grid item style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <Typography variant="subtitle2">Include Internal Farms:</Typography>
                    <Switch
                      checked={includeInternalFarms}
                      value="checked"
                      onChange={toggleIncludeInternalFarms}
                    />
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <Typography variant="subtitle2">Include Not Live Farms:</Typography>
                    <Switch
                      checked={includeNotLiveFarms}
                      value="checked"
                      onChange={toggleIncludeNotLiveFarms}
                    />
                  </Grid>
                  <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} xs={12} sm="auto">
                    <FarmStatusDropdown
                      selectedFarmsNumber={selectedFarms.length}
                      handleActiveClick={handleActiveOpen}
                      handleEmergencyClick={handleEmergencyOpen}
                    />
                  </Grid>
                </Grid>
              </Box>
              {isEmergencyOpen && (
                <FarmEmergencyDialog
                  farmStates={selectedFarms.map(({ farmId, farmName, status }) => ({
                    farmId,
                    farmName,
                    status,
                  }))}
                  isDialogOpen={handleEmergencyOpen}
                  clearSelectedFarms={clearSelectedFarms}
                />
              )}
              {isActiveOpen && (
                <FarmActiveDialog
                  farmStates={selectedFarms.map(({ farmId, farmName, status }) => ({
                    farmId,
                    farmName,
                    status,
                  }))}
                  isDialogOpen={handleActiveOpen}
                  clearSelectedFarms={clearSelectedFarms}
                />
              )}

              <Box style={{ maxWidth: '94vw', overflow: 'auto', marginBottom: '25px' }}>
                <FleetSummaryTable data={filteredFarmCardContent} />
              </Box>
              {pinnedFarms.length > 0 && (
                <Box style={{ maxWidth: '94vw', overflow: 'auto', marginBottom: '25px' }}>
                  <FarmSummaryCard
                    data={pinnedFarms}
                    handleSelectAllFarms={handleSelectAllFarms}
                    handleSelectedFarm={handleSelectedFarm}
                    handleFilterChange={handleFilterChange}
                    toggleMaintenance={toggleMaintenanceMode}
                    selectedFarms={selectedFarms.filter((farm) => farm.isPinned)}
                    isPinned
                  />
                </Box>
              )}
              <Box style={{ maxWidth: '94vw', maxHeight: '100vh', overflow: 'auto' }}>
                {unpinnedFarms.length > 0 && (
                  <FarmSummaryCard
                    data={unpinnedFarms}
                    handleSelectAllFarms={handleSelectAllFarms}
                    handleSelectedFarm={handleSelectedFarm}
                    handleFilterChange={handleFilterChange}
                    toggleMaintenance={toggleMaintenanceMode}
                    selectedFarms={selectedFarms.filter((farm) => !farm.isPinned)}
                    isPinned={false}
                  />
                )}
              </Box>
            </Box>
          </div>
        </Column>
      </Row>
    </Wrapper>
  );
};

export default FleetViewScreen;
