import React, { FunctionComponent, useState } from 'react';
import DataTable from 'application/components/data-table';
import { dateToText } from 'application/modules/fleet-watch/utils';
import { CaddieColours } from 'application/theme/colours';
import { IconButton } from '@material-ui/core';
import BehaviourService from 'services/behaviour.service';
import { Delete } from '@material-ui/icons';
import { InterventionDTO } from '../panels/interventions.panel';

type InterventionsTableProps = {
  interventions: InterventionDTO[];
  farmId: string;
  seasonId?: string;
};

const InterventionsTable: FunctionComponent<InterventionsTableProps> = ({
  interventions,
  farmId,
  seasonId,
}) => {
  const [isHandlingDelete, setIsHandlingDelete] = useState(false);

  const handleDeleteIntervention = async (interventionId: string) => {
    if (seasonId == null) return;
    setIsHandlingDelete(true);
    await BehaviourService.deleteIntervention(interventionId, farmId, seasonId);
    setIsHandlingDelete(false);
  };

  return (
    <>
      <DataTable<InterventionDTO>
        data={interventions}
        columns={[
          {
            name: '',
            render: (_, index) => Number(index + 1),
          },
          {
            name: 'Cow',
            keyExtractor: (intervention) => {
              if (
                Number.isNaN(Number(intervention.cattleName)) ||
                intervention.cattleName?.split('')[0] === '0'
              ) {
                return intervention.cattleName;
              }
              return Number(intervention.cattleName);
            },
            render: (intervention) => intervention.cattleName,
            allowCopyToClipboard: true,
          },
          {
            name: 'Date Adminstered',
            keyExtractor: (intervention) => intervention.administeredAt,
            render: (intervention) => dateToText(intervention.administeredAt),
          },
          {
            name: 'Intervention Type',
            keyExtractor: (intervention) => intervention.interventionType,
            render: (intervention) => intervention.interventionType,
          },
          {
            name: '',
            render: (intervention) => (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteIntervention(intervention.id);
                }}
                disabled={isHandlingDelete}
              >
                <Delete htmlColor="black" />
              </IconButton>
            ),
          },
        ]}
        headerColour={CaddieColours.AccentGrey}
        indexColumn={false}
        sortDirectionOption="asc"
        size="small"
      />
    </>
  );
};

export default InterventionsTable;
