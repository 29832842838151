import React, { createContext, FC, useContext, useState } from 'react';
import { Tower } from 'store/selectors/tower.selectors';

type ExternalTowerContext = {
  externalTowerMap: Map<string, Tower>;
  setExternalTowerMap: React.Dispatch<React.SetStateAction<Map<string, Tower>>>;
};

const ExternalTowersContext = createContext<ExternalTowerContext>(null!);

export const useExternalTowersContext = () => useContext(ExternalTowersContext);

export const ExternalTowersContextProvider: FC = ({ children }) => {
  const [externalTowerMap, setExternalTowerMap] = useState<Map<string, Tower>>(new Map());

  return (
    <ExternalTowersContext.Provider value={{ externalTowerMap, setExternalTowerMap }}>
      {children}
    </ExternalTowersContext.Provider>
  );
};
