import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const Container = styled.div`
  display: flex;
`;

type MetricsHeaderProps = {};

const MetricsHeader: FunctionComponent<MetricsHeaderProps> = ({ children }) => (
  <Grid item xs={12}>
    <Container>{children}</Container>
  </Grid>
);

export default MetricsHeader;
