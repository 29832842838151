import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { intersection, startCase, camelCase } from 'lodash';
import styled from 'styled-components';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
} from '@material-ui/core';

import { findAllMapItemDiscriminators, ItemDiscriminator } from 'store/selectors/map-page-items.selectors/map-page-common.selector2';

import { getColorForField } from '../utils';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0rem 1rem 0rem 1rem;
  align-items: center;
`;

type FilterItemTypesProps = {
  selectedDiscriminators: ItemDiscriminator[];
  onChangeSelectedDiscriminators: (values: ItemDiscriminator[]) => void;
};

const FilterItemTypes: FunctionComponent<FilterItemTypesProps> = ({
  selectedDiscriminators,
  onChangeSelectedDiscriminators,
}) => {
  const allDiscriminators = useSelector(findAllMapItemDiscriminators);

  const [expanded, setExpanded] = useState(false);
  const handleDiscriminatorCheckedChange = (discriminator: ItemDiscriminator, checked: boolean) => {
    const newSelectedDiscriminators = checked
      ? [...selectedDiscriminators, discriminator]
      : selectedDiscriminators.filter((d) => d.name !== discriminator.name);
    onChangeSelectedDiscriminators(newSelectedDiscriminators);
  };

  const getDiscriminatorDisplayName = (name: string) => startCase(camelCase(name));

  const selectedItemDiscriminators = intersection(
    selectedDiscriminators.map((d) => d.name),
    allDiscriminators.map((d) => d.name)
  )
    .map((name) => getDiscriminatorDisplayName(name))
    .join(', ');
  return (
    <Wrapper>
      <Accordion
        expanded={expanded}
        onChange={(e, isExpanded) => setExpanded(isExpanded)}
        style={{ flex: 1 }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
          <div>
            <Typography>
              {!expanded
                ? `Showing ${selectedItemDiscriminators || '0'} out of ${allDiscriminators.length} item types`
                : ''}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup row style={{ width: '100%' }}>
            <Grid container spacing={1}>
              {allDiscriminators.map((discriminator) => (
                <Grid key={`${discriminator.itemType}-${discriminator.name}`} item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedDiscriminators.some((d) => d.name === discriminator.name)}
                        onChange={(e, checked) => handleDiscriminatorCheckedChange(discriminator, checked)}
                        name={discriminator.name}
                        style={{ color: getColorForField(discriminator), padding: '2px' }}
                      />
                    }
                    label={getDiscriminatorDisplayName(discriminator.name)}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Wrapper>
  );
};

export default FilterItemTypes;
