import React, { FunctionComponent, useState } from 'react';
import { Switch } from '@material-ui/core';
import ConfirmationModal from 'application/components/confirmation.modal';

type GenericSwitchProps = {
  value: boolean;
  requireConfirmation: boolean;
  confirmationMessage: string;
  disabled?: boolean;
  onChange: () => void;
};

const GenericSwitch: FunctionComponent<GenericSwitchProps> = ({
  value,
  requireConfirmation,
  confirmationMessage,
  disabled,
  onChange,
}) => {
  const [confirmationPopupVisible, setConfirmationPopupVisible] = useState(false);

  const toggleValue = () => {
    setConfirmationPopupVisible(false);
    onChange();
  };

  const enableConfirmationPopup = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setConfirmationPopupVisible(true);
    e.stopPropagation();
  };

  return (
    <>
      <Switch checked={value} onClick={enableConfirmationPopup} disabled={disabled} />
      {requireConfirmation && (
        <ConfirmationModal
          confirmationMessage={confirmationMessage}
          open={confirmationPopupVisible}
          onDismiss={() => setConfirmationPopupVisible(false)}
          onConfirm={toggleValue}
        />
      )}
    </>
  );
};

export default GenericSwitch;
