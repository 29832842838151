import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import { DeviceOffFarmCheckpoint } from 'data/fleet-watch';
import Header from 'application/components/header';
import { DeviceOffFarmCheckpointEnum } from '@halter-corp/fleet-service-client';

type FleetSummaryTableProps = {
  selectedCheckpoint: DeviceOffFarmCheckpointEnum;
  checkPointData?: DeviceOffFarmCheckpoint[];
};

const FleetTrackingTable: FunctionComponent<FleetSummaryTableProps> = ({
  selectedCheckpoint,
  checkPointData,
}) => {
  const downloadCSV = (data?: DeviceOffFarmCheckpoint[]) => {
    if (!data) {
      return;
    }

    const csvData = [
      ['Serial Number', 'Check Point', 'Created Date'],
      ...data.map(({ serialNumber, checkpoint, createdDate }) => [serialNumber, checkpoint, createdDate]),
    ]
      .map((row) => row.join(','))
      .join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${selectedCheckpoint}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <Header
        text={selectedCheckpoint
          .replace(/_/g, ' ')
          .toLowerCase()
          .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())}
        size="large"
        style={{ marginBottom: '12px', marginLeft: '16px', float: 'left' }}
      />
      <Button style={{ float: 'right' }} onClick={() => downloadCSV(checkPointData)}>
        Download Data
      </Button>

      {/* Disabling the table for now as it's not really necessary and is not optimised at all */}

      {/* <DataTable<DeviceOffFarmCheckpoint>
        data={checkPointData || []}
        columns={[
          {
            name: 'Date',
            keyExtractor: (item) => item.createdDate,
            render: (item) => (
              <Typography style={{ color: 'grey' }}>{dateToText(new Date(item.createdDate))}</Typography>
            ),
          },
          {
            name: 'Serial Number',
            keyExtractor: (item) => item?.serialNumber,
            render: (item) => (
              <Typography variant="body2" style={{ marginLeft: 'auto' }}>
                {item?.serialNumber}
              </Typography>
            ),
          },
        ]}
        indexColumn={false}
        headerColour={CaddieColours.DarkGrey}
        sortDirectionOption="desc"
        size="small"
        stickyHeader={false}
      /> */}
    </div>
  );
};

export default FleetTrackingTable;
