import { createSlice } from '@reduxjs/toolkit';

import { reset } from 'store/actions';
import behaviourEffects from 'store/effects/behaviour.effects';
import {
  ICalvingDTO,
  ICalvingSummaryDTO,
  ICowHeatEventDTO,
  ISeasonDTO,
  ISeasonMetricDataDTO,
  ISeasonMetricsSummaryDTO,
} from '@halter-corp/cattle-breeding-service-client';

import {
  ICurrentHealthCaseSummariesDTO,
  IHistoricHealthCasesDTO,
  IHealthCaseDTO,
} from '@halter-corp/cow-health-service-client';

import { uniqBy } from 'lodash';
import { ICowInterventionsDTO } from '@halter-corp/mating-service-client';

type BehaviourState = {
  farmHeats: ICowHeatEventDTO[];
  cowHeats: ICowHeatEventDTO[];
  heatsLoadingStatus: 'loading' | 'error' | 'success';
  seasonMetrics: ISeasonMetricDataDTO | undefined;
  season: ISeasonDTO | undefined;
  farmCurrentHealthCases: ICurrentHealthCaseSummariesDTO;
  farmHistoricalHealthCases: IHistoricHealthCasesDTO;
  cowHealthCases: IHealthCaseDTO[];
  seasonMetricsSummary: ISeasonMetricsSummaryDTO | undefined;
  mindaIntegrationStatus: boolean | undefined;
  calvingInfo: ICalvingDTO[];
  calvingInfoLoadingStatus: 'loading' | 'error' | 'success';
  calvingSummary: ICalvingSummaryDTO | undefined;
  interventions: ICowInterventionsDTO[];
  interventionsLoadingStatus: 'loading' | 'error' | 'success';
};

const initialState: BehaviourState = {
  farmHeats: [],
  cowHeats: [],
  heatsLoadingStatus: 'loading',
  seasonMetrics: undefined,
  season: undefined,
  farmCurrentHealthCases: { diagnosed: [], checkImmediately: [], needsAttention: [] },
  farmHistoricalHealthCases: { currentPageData: [], currentOffset: 0, limit: 100, totalItems: 0 },
  cowHealthCases: [],
  seasonMetricsSummary: undefined,
  mindaIntegrationStatus: undefined,
  calvingInfo: [],
  calvingInfoLoadingStatus: 'loading',
  calvingSummary: undefined,
  interventions: [],
  interventionsLoadingStatus: 'loading',
};

const behaviourSlice = createSlice({
  initialState,
  name: 'behaviour',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(behaviourEffects.fetchHeats.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          state.farmHeats = uniqBy(action.payload, 'id');
          state.heatsLoadingStatus = 'success';
          if (state.farmHeats != null) {
            state.farmHeats = uniqBy([...state.farmHeats, ...action.payload], 'id');
            state.heatsLoadingStatus = 'success';
          }
        }
      })
      .addCase(behaviourEffects.fetchHeatsByCattleId.fulfilled, (state, action) => {
        state.cowHeats = uniqBy(action.payload, 'id');
      })
      .addCase(behaviourEffects.fetchHeats.pending, (state) => {
        state.heatsLoadingStatus = 'loading';
      })
      .addCase(behaviourEffects.fetchHeats.rejected, (state) => {
        state.heatsLoadingStatus = 'error';
      })
      .addCase(behaviourEffects.fetchMetricData.fulfilled, (state, action) => {
        state.seasonMetrics = action.payload;
      })
      .addCase(behaviourEffects.fetchMetricsSummary.fulfilled, (state, action) => {
        state.seasonMetricsSummary = action.payload;
      })
      .addCase(behaviourEffects.fetchSeason.fulfilled, (state, action) => {
        state.season = action.payload;
      })
      .addCase(behaviourEffects.fetchCurrentHealthCases.fulfilled, (state, action) => {
        state.farmCurrentHealthCases = action.payload;
      })
      .addCase(behaviourEffects.fetchHistoricalHealthCases.fulfilled, (state, action) => {
        state.farmHistoricalHealthCases = action.payload;
      })
      .addCase(behaviourEffects.fetchHealthCasesByCattleId.fulfilled, (state, action) => {
        state.cowHealthCases = action.payload;
      })
      .addCase(behaviourEffects.fetchIsMindaIntegrated.fulfilled, (state, action) => {
        state.mindaIntegrationStatus = action.payload;
      })
      .addCase(behaviourEffects.fetchCalvingInfo.fulfilled, (state, action) => {
        state.calvingInfo = action.payload;
        state.calvingInfoLoadingStatus = 'success';
      })
      .addCase(behaviourEffects.fetchCalvingInfo.pending, (state) => {
        state.calvingInfoLoadingStatus = 'loading';
      })
      .addCase(behaviourEffects.fetchCalvingInfo.rejected, (state) => {
        state.calvingInfoLoadingStatus = 'error';
      })
      .addCase(behaviourEffects.fetchCalvingSummary.fulfilled, (state, action) => {
        state.calvingSummary = action.payload;
      })
      .addCase(behaviourEffects.fetchInterventions.fulfilled, (state, action) => {
        state.interventions = action.payload;
        state.interventionsLoadingStatus = 'success';
      })
      .addCase(behaviourEffects.fetchInterventions.pending, (state) => {
        state.interventionsLoadingStatus = 'loading';
      })
      .addCase(behaviourEffects.fetchInterventions.rejected, (state) => {
        state.interventionsLoadingStatus = 'error';
      }),
});

export default behaviourSlice;
