import { Divider, LinearProgress } from '@material-ui/core';
import React, { FunctionComponent, useMemo } from 'react';
import FleetService from 'services/fleet.service';
import { DeviceOffFarmCheckpointEnum } from '@halter-corp/fleet-service-client';
import styled from 'styled-components';
import Header from 'application/components/header';
import { MapUtils } from 'application/utils/MapUtils';
import FleetTrackingTable from '../tables/fleet-tracking.table';
import FleetTrackingDiagram from '../components/fleet-tracking-diagram.panel';

const Wrapper = styled.div`
  background-color: rgb(242, 243, 245);
  min-height: calc(100vh - 45px);
`;

const WidthWrapper = styled.div`
  width: 1200px;
  margin: auto;
`;

const FleetTrackingScreen: FunctionComponent = () => {
  const offFarmCheckpointsByCheckpoint = FleetService.useFetchOffFarmCheckpointsByCheckpoint();
  const loading = FleetService.useIsOffFarmCheckpointsByCheckpointLoading();

  const [selectedCheckpoint, setSelectedCheckpoint] = React.useState<DeviceOffFarmCheckpointEnum>(
    'SERIAL_NUMBER_GENERATED' as DeviceOffFarmCheckpointEnum
  );

  const countPerCheckpoint = useMemo(() => {
    if (!offFarmCheckpointsByCheckpoint) {
      return undefined;
    }
    const offFarmCheckpointsByCheckpointMap = MapUtils.fromObject(offFarmCheckpointsByCheckpoint);

    return MapUtils.mapValues(offFarmCheckpointsByCheckpointMap, (item) => item.length);
  }, [offFarmCheckpointsByCheckpoint]);

  const selectedDeviceOffFarmCheckpoints = useMemo(() => {
    if (!offFarmCheckpointsByCheckpoint) {
      return [];
    }
    return offFarmCheckpointsByCheckpoint[selectedCheckpoint];
  }, [offFarmCheckpointsByCheckpoint, selectedCheckpoint]);

  return (
    <Wrapper>
      <LinearProgress
        variant={loading ? 'query' : 'determinate'}
        value={100}
        style={{ position: 'sticky' }}
      />
      <WidthWrapper>
        <Header text="Fleet Tracking [Beta]" size="large" style={{ marginTop: '24px', marginLeft: '16px' }} />
        <Divider style={{ marginBottom: '16px' }} />

        <FleetTrackingDiagram setSelectedCheckpoint={setSelectedCheckpoint} counts={countPerCheckpoint} />
        <FleetTrackingTable
          selectedCheckpoint={selectedCheckpoint}
          checkPointData={selectedDeviceOffFarmCheckpoints}
        />
      </WidthWrapper>
    </Wrapper>
  );
};

export default FleetTrackingScreen;
