import { createSelector } from '@reduxjs/toolkit';

import { Device } from 'data/device';

import { AppState } from '../store';
import devicesOverviewAdapter from '../adapters/devices-overview.adapter';

export const selectDevicesSlice = (state: AppState) => state.devicesOverview.entityState;
export const isLoadingSlice = (state: AppState) => state.devicesOverview.loading;
export const isProvisioningSlice = (state: AppState) => state.devicesProvisioning.loading;

export const { selectIds: selectSerialNumbers, selectEntities: selectDeviceEntities } =
  devicesOverviewAdapter.getSelectors(selectDevicesSlice);

export const listDevicesWithOverviewInfo = createSelector(selectDeviceEntities, (devices): Device[] =>
  Object.values(devices).reduce<Device[]>((previous, device) => {
    if (device == null) return previous;
    return [...previous, device];
  }, [])
);

export const isLoading = createSelector(isLoadingSlice, (state) => state);

export const isProvisioning = createSelector(isProvisioningSlice, (state) => state);

export const getSelectedDevice = createSelector(
  (state: AppState) => state.device,
  (deviceState) => deviceState.device
);