import React, { FunctionComponent, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { Column } from 'application/modules/fleet-watch/utils';
import Header from 'application/components/header';
import FleetService from 'services/fleet.service';
import OtaService from 'services/ota.service';
import QRCodeScanningScreen from './qr-code-scanning.screen';
import { Mode } from '../containers/fleet-tools.container';

type StartPrepScreenProps = {
  setMode: (mode: Mode) => void;
};

const StartPrepScreen: FunctionComponent<StartPrepScreenProps> = ({ setMode }) => {
  const [scanning, setScanning] = useState(true);
  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);

  const [collarsUpdated, setCollarsUpdated] = useState(0);
  const [failedSerialNumbers, setFailedSerialNumbers] = useState<string[]>([]);

  const handleScan = (result: string | null) => {
    if (result) {
      const pattern = /\b004-\d{4}-\d{5}\b/; // thanks chat gpt

      const serials = result
        .split(',')
        .map((x) => x.trim())
        .filter((x) => pattern.test(x));
      setSerialNumbers(serials);
    } else {
      setMode('none');
    }
    setScanning(false);
  };

  const startPrep = async () => {
    setCollarsUpdated(0);
    setFailedSerialNumbers([]);

    await Promise.all(
      serialNumbers.map(async (serialNumber) => {
        if (await OtaService.updateReleaseChannelForThing(serialNumber, 'release')) {
          setCollarsUpdated((n) => n + 1);
        } else {
          setFailedSerialNumbers((failed) => [...failed, serialNumber]);
        }
      })
    );
    FleetService.setOffFarmCheckpoint(serialNumbers, 'STARTED_PREP');
  };

  const close = () => {
    setMode('none');
    setSerialNumbers([]);
    setCollarsUpdated(0);
    setFailedSerialNumbers([]);
  };

  return (
    <>
      {scanning && <QRCodeScanningScreen handleScan={handleScan} />}
      {!scanning && (
        <Column>
          <Box m={0}>
            <Header text="Start Prep" size="large" style={{ marginTop: '24px', marginLeft: '16px' }} />
            <Divider style={{ marginBottom: '16px' }} />
            <Box m={2}>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={{ width: '100%' }}>
                  {serialNumbers.length > 0 ? (
                    <>
                      <Typography style={{ color: 'green' }}>
                        {serialNumbers.length} Serial numbers found
                      </Typography>
                      <Box
                        style={{
                          backgroundColor: '#f5f5f5',
                          padding: '10px',
                          borderRadius: '10px',
                          marginTop: '10px',
                        }}
                      >
                        <Typography style={{ color: 'green', fontSize: '12px' }}>
                          {serialNumbers.join(', ')}
                        </Typography>
                      </Box>
                      <Typography style={{ fontStyle: 'italic', marginTop: '10px', fontSize: '12px' }}>
                        Starting prep will add these collars to the release firmware channel and update their
                        state on fleet-service to STARTED_PREP
                      </Typography>
                    </>
                  ) : (
                    <Typography style={{ color: 'red' }}>Failed to read Serial numbers</Typography>
                  )}
                  {(collarsUpdated !== 0 || failedSerialNumbers.length !== 0) && (
                    <Box
                      style={{
                        backgroundColor: '#f5f5f5',
                        padding: '10px',
                        borderRadius: '10px',
                        marginTop: '20px',
                      }}
                    >
                      {collarsUpdated > 0 && (
                        <Typography style={{ color: 'green', marginTop: '10px' }}>
                          {collarsUpdated} collars updated
                        </Typography>
                      )}
                      {failedSerialNumbers.length > 0 && (
                        <Typography style={{ color: 'red', marginTop: '10px' }}>
                          {failedSerialNumbers.length} collars failed to update! (
                          {failedSerialNumbers.join(', ')})
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>
                <Grid container spacing={2} alignItems="center" direction="row" style={{ marginTop: '10px' }}>
                  {collarsUpdated === 0 ? (
                    <>
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{ minWidth: 'auto', fontSize: '12px', whiteSpace: 'nowrap' }}
                          onClick={() => setMode('none')}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      {serialNumbers.length > 0 && (
                        <Grid item>
                          <Button
                            variant="contained"
                            style={{ minWidth: 'auto', fontSize: '12px', whiteSpace: 'nowrap' }}
                            onClick={startPrep}
                          >
                            Start Prep
                          </Button>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <>
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{ minWidth: 'auto', fontSize: '12px', whiteSpace: 'nowrap' }}
                          onClick={startPrep}
                        >
                          Retry
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{ minWidth: 'auto', fontSize: '12px', whiteSpace: 'nowrap' }}
                          onClick={close}
                        >
                          Done
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Column>
      )}
    </>
  );
};
export default StartPrepScreen;
