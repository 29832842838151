import React, { FunctionComponent, useState } from 'react';
import DeviceService from 'services/device.service';
import { IEngineeringSettingsDTO } from '@halter-corp/settings-service-client';
import SerialLoadSection from '../components/serial-form';
import SettingsFormSection from '../components/settings-form';
import { DeepRequired } from '../types';

export type SettingsFormContainerProps = {
  serialNumber: string;
  data?: DeepRequired<IEngineeringSettingsDTO>;
};

const SettingsFormContainer: FunctionComponent = () => {
  const [serialNumber, setSerialNumber] = useState('');
  const [data, setData] = useState<DeepRequired<IEngineeringSettingsDTO> | undefined>(undefined);

  const findEngineeringSettings = async (serialNumberForm: string) => {
    const device = await DeviceService.getEngineeringSettings(serialNumberForm);
    setSerialNumber(serialNumberForm);
    setData(device as DeepRequired<IEngineeringSettingsDTO>);
  };

  const updateEngineeringSettings = async (
    serialNumberForm: string,
    values: DeepRequired<IEngineeringSettingsDTO>
  ) => {
    await DeviceService.updateEngineeringSettings(serialNumber, values);
    const device = await DeviceService.getEngineeringSettings(serialNumberForm);
    setSerialNumber(serialNumberForm);
    setData(device as DeepRequired<IEngineeringSettingsDTO>);
  };

  return (
    <div>
      <SerialLoadSection onClickLoad={findEngineeringSettings} />
      {data != null && (
        <SettingsFormSection
          serialNumber={serialNumber}
          data={data}
          onClickSave={updateEngineeringSettings}
        />
      )}
    </div>
  );
};

export default SettingsFormContainer;
