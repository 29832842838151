import React, { FunctionComponent, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import PlaybackControl from '../panels/playback-control.panel';
import InputPanel, { LayerSelection } from '../panels/input.panel';
import DataMap from '../panels/data-map.panel';

export type SearchParameters = {
  startTime?: Date;
  endTime?: Date;
  cattleNames?: string[];
  mobIds?: string[];
  farmIds?: string[];
};

const SEPARATOR = ',';

const HeatMapScreen: FunctionComponent = () => {
  const [currentTime, setCurrentTime] = useState<Date | undefined>(undefined);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const encodedLayers = searchParams.get('layers');
  const layers: LayerSelection | undefined = encodedLayers
    ? JSON.parse(decodeURIComponent(escape(atob(encodedLayers))))
    : undefined;

  const [refreshLayers, setRefreshLayers] = useState<LayerSelection>({
    farmLocations: false,
    farmPaddocks: false,
    farmBreaks: false,
    shockHeatmap: false,
    vibeHeatmap: false,
    piezoHeatmap: false,
    positionHeatmap: false,
    behaviourHeatmap: false,
    slotGroups: false,
  });

  const generateSearchFromQueryString = (params: URLSearchParams): SearchParameters => {
    const farmIdFromQueryString = params.get('farmIds');
    const mobIdFromQueryString = params.get('mobIds');
    const cattleEarTagsString = params.get('earTags');
    const fromDateString = params.get('fromDate');
    const toDateString = params.get('toDate');

    const search: SearchParameters = {};

    if (farmIdFromQueryString) {
      search.farmIds = farmIdFromQueryString.split(SEPARATOR);
    }
    if (mobIdFromQueryString) {
      search.mobIds = mobIdFromQueryString.split(SEPARATOR);
    }
    if (cattleEarTagsString) {
      search.cattleNames = cattleEarTagsString.split(SEPARATOR);
    }

    if (fromDateString) {
      search.startTime = new Date(fromDateString);
      if (currentTime && currentTime < search.startTime) setCurrentTime(search.startTime);
    }
    if (toDateString) {
      search.endTime = new Date(toDateString);
      if (currentTime && currentTime > search.endTime) setCurrentTime(search.endTime);
    }

    return search;
  };

  const search = useMemo(() => generateSearchFromQueryString(searchParams), [location.search]);

  return (
    <>
      <DataMap search={search} currentTime={currentTime} layers={layers} refreshLayers={refreshLayers} />
      {search.startTime && search.endTime && currentTime && (
        <PlaybackControl
          minTimestamp={search.startTime}
          maxTimestamp={search.endTime}
          currentTime={currentTime}
          setCurrentTime={setCurrentTime}
        />
      )}
      {search.startTime && search.endTime && !currentTime && (
        <Button
          variant="contained"
          style={{
            fontSize: '12px',
            whiteSpace: 'nowrap',
            position: 'absolute',
            bottom: '10px',
            right: '15px',
          }}
          onClick={() => {
            setCurrentTime(search.startTime);
          }}
        >
          Playback
        </Button>
      )}
      <InputPanel refreshLayers={refreshLayers} setRefreshLayers={setRefreshLayers} />
    </>
  );
};

export default HeatMapScreen;
