import { createAsyncThunk } from '@reduxjs/toolkit';

import { sortBy, groupBy } from 'lodash';
import HttpApiService from 'services/http-api.service';
import TopographyService from 'services/topography.service';
import { Feature, Point, Polygon } from 'geojson';
import { point } from 'turf';
import {
  BehaviourPosition,
  BreakFence,
  EventLocation,
  ExitPoint,
  SlotGroup,
} from 'store/slices/heat-map.slice';
import { DataMapSearch } from 'application/modules/heat-map/panels/data-map.panel';
import { IFeatureDTO, IFeatureGeometryPolygon } from '@halter-corp/topography-service-client';
import * as h3 from 'h3-js';

import BffDebugToolQueryService from 'services/bff-debug-tool.query-service';
import moment from 'moment';
import MessageService from 'services/message.service';

export type FarmWithDatum = {
  id: string;
  name?: string;
  datum?: Feature<Point>;
};

const fetchCowgEventLocations = async (search: DataMapSearch, event: string): Promise<EventLocation[]> => {
  if (!search.startTime) return Promise.reject(new Error('Start time is required'));
  if (!search.endTime) return Promise.reject(new Error('End time is required'));
  if (!search.farmIds) return Promise.reject(new Error('A Farm is required'));
  if (search.farmIds.length !== 1) return Promise.reject(new Error('Only one farm is supported'));

  const query = `
    SELECT latitude, longitude, utc_timestamp as timestamp
    FROM "device_cowg_events"
    WHERE ${BffDebugToolQueryService.getTimestampFilter(search.startTime, search.endTime)}
    and farm_id = '${search.farmIds[0]}'
    ${search.mobIds ? `and mob_id in ('${search.mobIds.join("','")}')` : ''}
    ${search.cattleNames ? `and cattle_name in ('${search.cattleNames.join("','")}')` : ''}
    and cowg_status = '${event}'
  `;

  const items = await BffDebugToolQueryService.query(query);

  const sortedItems = sortBy(items, ['timestamp']);

  const events = sortedItems.map((result: any) => ({
    latitude: result.latitude,
    longitude: result.longitude,
    timestamp: moment.utc(result.timestamp).toDate(),
  }));

  return events;
};

const heatMapEffects = {
  fetchPositionMetrics: createAsyncThunk(
    'heatMap/fetchPositionMetrics',
    async (search: DataMapSearch): Promise<EventLocation[]> => {
      if (!search.startTime) return Promise.reject(new Error('Start time is required'));
      if (!search.endTime) return Promise.reject(new Error('End time is required'));
      if (!search.farmIds) return Promise.reject(new Error('A Farm is required'));
      if (search.farmIds.length !== 1) return Promise.reject(new Error('Only one farm is supported'));

      const query = `
        SELECT latitude, longitude, utc_timestamp as timestamp
        FROM "device_position_metrics"
        WHERE ${BffDebugToolQueryService.getTimestampFilter(search.startTime, search.endTime)}
        and farm_id = '${search.farmIds[0]}'
        ${search.mobIds ? `and mob_id in ('${search.mobIds.join("','")}')` : ''}
        ${search.cattleNames ? `and cattle_name in ('${search.cattleNames.join("','")}')` : ''}
        and (latitude != 0 and longitude != 0)
      `;
      const items = await BffDebugToolQueryService.query(query);

      const sortedItems = sortBy(items, ['timestamp']);

      const events = sortedItems.map((result: any) => ({
        latitude: result.latitude,
        longitude: result.longitude,
        timestamp: moment.utc(result.timestamp).toDate(),
      }));

      return events;
    }
  ),
  fetchShockLocations: createAsyncThunk(
    'heatMap/fetchShockLocations',
    async (search: DataMapSearch): Promise<EventLocation[]> => fetchCowgEventLocations(search, 'SHOCK')
  ),
  fetchPiezoLocations: createAsyncThunk(
    'heatMap/fetchPiezoLocations',
    async (search: DataMapSearch): Promise<EventLocation[]> => fetchCowgEventLocations(search, 'PIEZOING')
  ),
  fetchVibeLocations: createAsyncThunk(
    'heatMap/fetchVibeLocations',
    async (search: DataMapSearch): Promise<EventLocation[]> => fetchCowgEventLocations(search, 'VIBING')
  ),

  fetchBehaviourPositions: createAsyncThunk(
    'heatMap/fetchBehaviourPositions',
    async (search: DataMapSearch): Promise<BehaviourPosition[]> => {
      if (!search.startTime) return Promise.reject(new Error('Start time is required'));
      if (!search.endTime) return Promise.reject(new Error('End time is required'));
      if (!search.farmIds) return Promise.reject(new Error('A Farm is required'));
      if (search.farmIds.length !== 1) return Promise.reject(new Error('Only one farm is supported'));

      const query = `
        WITH crossjoined AS (
          SELECT
              l.cattle_name as cattle_name,
              l.utc_timestamp as utc_timestamp,
              l.latitude as latitude,
              l.longitude as longitude,
              b.grazing_prediction as grazing_prediction,
              b.resting_prediction as resting_prediction,
              b.ruminating_prediction as ruminating_prediction,
              ROW_NUMBER() OVER (PARTITION BY l.cattle_name, l.utc_timestamp ORDER BY ABS(date_diff('minute', l.utc_timestamp, b.utc_timestamp))) as rn
          FROM "device_position_metrics" l
          CROSS JOIN "cow_behaviour_inference_events" b
          WHERE l.cattle_id = b.cattle_id
          and l.farm_id = '${search.farmIds[0]}'
          and b.farm_id = '${search.farmIds[0]}'
          ${search.mobIds ? `and l.mob_id in ('${search.mobIds.join("','")}')` : ''}
          ${search.cattleNames ? `and l.cattle_name in ('${search.cattleNames.join("','")}')` : ''}
          and cast(l.partition_utc_timestamp as varchar) between '${moment
            .utc(search.startTime)
            .format('YYYY-MM-DD')}' and '${moment.utc(search.endTime).format('YYYY-MM-DD')}z'
           and cast(l.utc_timestamp as varchar) between '${search.startTime
             .toISOString()
             .replace('T', ' ')}' and '${search.endTime.toISOString().replace('T', ' ')}'
          and cast(b.partition_utc_timestamp as varchar) between '${moment
            .utc(search.startTime)
            .format('YYYY-MM-DD')}' and '${moment.utc(search.endTime).format('YYYY-MM-DD')}z'
             and cast(b.utc_timestamp as varchar) between '${search.startTime
               .toISOString()
               .replace('T', ' ')}' and '${search.endTime.toISOString().replace('T', ' ')}'
      )
      SELECT
          cattle_name as cattleName,
          utc_timestamp as timestamp,
          latitude,
          longitude,
          grazing_prediction as grazingPrediction,
          resting_prediction as restingPrediction,
          ruminating_prediction as ruminatingPrediction
      FROM crossjoined
      WHERE rn = 1
      ORDER by utc_timestamp
      `;

      const items = await BffDebugToolQueryService.query(query);

      const behaviourPositions: BehaviourPosition[] = items.map((result: any) => ({
        latitude: Number(result.latitude),
        longitude: Number(result.longitude),
        timestamp: moment.utc(result.timestamp).toDate(),
        grazingProbability: Number(result.grazingPrediction),
        restingProbability: Number(result.restingPrediction),
        ruminatingProbability: Number(result.ruminatingPrediction),
      }));

      return behaviourPositions;
    }
  ),

  fetchFarmsWithDatums: createAsyncThunk(
    'heatMap/fetchFarmsWithDatums',
    async (farmIds?: string[]): Promise<FarmWithDatum[]> => {
      const farmApi = await HttpApiService.getFarmApi();
      const { data: allFarms } = await farmApi.findAll();

      const farmsToSearch = farmIds && farmIds.length > 0 ? farmIds : allFarms.map((farm) => farm.id);

      if (!farmsToSearch) {
        return [];
      }

      const datums = await Promise.all(farmsToSearch.map((farmId) => TopographyService.fetchDatum(farmId)));

      const farmsWithDatums = farmsToSearch.map((farmId, index) => {
        const datum = datums[index];
        const farmDto = allFarms.find((farm) => farm.id === farmId);

        if (!farmId || !datum || !farmDto) {
          return null;
        }

        const coordinates = datum?.feature.geometry.coordinates || null;

        if (!coordinates) {
          return null;
        }

        return {
          id: farmId,
          name: farmDto.name,
          datum: point([coordinates[0], coordinates[1]]),
        };
      });

      const result = farmsWithDatums.filter((farm) => farm !== null) as FarmWithDatum[];
      const sorted = sortBy(result, ['datum.geometry.coordinates[1]']).reverse();

      return sorted;
    }
  ),

  fetchFarmPaddocks: createAsyncThunk(
    'heatMap/fetchFarmPaddocks',
    async (farmIds?: string[]): Promise<IFeatureDTO[]> => {
      if (!farmIds) {
        return [];
      }

      const paddocks = await Promise.all(
        farmIds.map((farmId) => TopographyService.fetchAllPaddocksForFarm(farmId))
      );
      return paddocks.flat();
    }
  ),

  fetchFarmBreaks: createAsyncThunk(
    'heatMap/fetchFarmBreaks',
    async (search: DataMapSearch): Promise<BreakFence[]> => {
      if (!search.farmIds) return [];
      if (!search.startTime) return [];
      if (!search.endTime) return [];

      const startTime = new Date(search.startTime);
      startTime.setMinutes(startTime.getMinutes() - 30);

      const { endTime } = search;

      /* eslint-disable */
      const query = `
        SELECT command_activation_time_utc as commandActivationTimeUtc, collar_command_id, device_command_status as deviceCommandStatus, command_arguments as commandArguments,
        from_unixtime(CAST(avg(date_diff('second', timestamp '1970-01-01 00:00:00', utc_timestamp)) as bigint)) as utcTimestamp
        FROM "halter"."device_command_events" 
        WHERE ${BffDebugToolQueryService.getTimestampFilter(search.startTime, search.endTime)}
        and farm_id = '${search.farmIds[0]}'
        ${search.mobIds ? `and mob_id in ('${search.mobIds.join("','")}')` : ''}
        and command_type = 'SET_ZONE'
        and device_command_status in ('ACTIVE','COMPLETED')
        GROUP BY command_activation_time_utc, collar_command_id, device_command_status, command_arguments
      `;
      /* eslint-enable */

      const items: any = await BffDebugToolQueryService.query(query);

      const parsedItems = items.map((item: any) => ({
        ...item,
        commandArguments: JSON.parse(item.commandArguments),
      }));

      const groupedByCommand = groupBy(parsedItems, (item) => item.collar_command_id);

      const breaks: BreakFence[] = Object.values(groupedByCommand).map((group) => {
        const breakFenceStart = group.find((item) => item.deviceCommandStatus === 'ACTIVE');
        const breakFenceEnd = group.find((item) => item.deviceCommandStatus === 'COMPLETED');

        const activationTime = breakFenceStart?.commandActivationTimeUtc;
        const dropTime = breakFenceEnd?.utcTimestamp;
        const inActiveEdges =
          breakFenceStart?.commandArguments.inactiveEdges ||
          breakFenceEnd?.commandArguments.inactiveEdges ||
          [];
        const breakGeometry: Feature<Polygon> = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              breakFenceStart?.commandArguments.shape.coordinates.map((coordinate: any) => [
                coordinate.longitude,
                coordinate.latitude,
              ]) ||
                breakFenceEnd?.commandArguments.shape.coordinates.map((coordinate: any) => [
                  coordinate.longitude,
                  coordinate.latitude,
                ]),
            ],
          },
          properties: { inActiveEdges },
        };
        return {
          geometry: breakGeometry,
          startTime: activationTime ? moment.utc(activationTime).toDate() : startTime,
          dropTime: dropTime ? moment.utc(dropTime).toDate() : endTime,
        };
      });
      return breaks;
    }
  ),

  fetchExitPoints: createAsyncThunk(
    'heatMap/fetchExitPoints',
    async (search: DataMapSearch): Promise<ExitPoint[]> => {
      if (!search.farmIds) return [];
      if (!search.startTime) return [];
      if (!search.endTime) return [];

      const startTime = new Date(search.startTime);
      startTime.setMinutes(startTime.getMinutes() - 30);

      const searchEndTime = search.endTime;

      const query = `
      SELECT command_activation_time_utc as commandActivationTimeUtc, collar_command_id as collarCommandId, device_command_status as deviceCommandStatus, command_arguments as commandArguments,
      from_unixtime(CAST(avg(date_diff('second', timestamp '1970-01-01 00:00:00', utc_timestamp)) as bigint)) as utcTimestamp
      FROM "halter"."device_command_events" 
      WHERE ${BffDebugToolQueryService.getTimestampFilter(search.startTime, search.endTime)}
      and farm_id = '${search.farmIds[0]}'
      ${search.mobIds ? `and mob_id in ('${search.mobIds.join("','")}')` : ''}
      ${search.cattleNames ? `and cattle_name in ('${search.cattleNames.join("','")}')` : ''}
      and command_type = 'EXIT_ZONE'
      and device_command_status in ('ACTIVE','COMPLETED')
      GROUP BY command_activation_time_utc, collar_command_id, device_command_status, command_arguments
    `;
      const items: any = await BffDebugToolQueryService.query(query);

      const parsedItems = items.map((item: any) => ({
        ...item,
        commandArguments: JSON.parse(item.commandArguments),
      }));

      const groupedByCommand = groupBy(parsedItems, (item) => item.collarCommandId);

      const exitPoints: ExitPoint[] = Object.values(groupedByCommand).map((group) => {
        const exitStart = group.find((item) => item.deviceCommandStatus === 'ACTIVE');
        const exitCompleted = group.find((item) => item.deviceCommandStatus === 'COMPLETED');

        const activationTime = exitStart?.commandActivationTimeUtc;
        const endTime = exitCompleted?.utcTimestamp;

        const exitPoint: Feature<Point> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              exitStart?.commandArguments.exitPoint.longitude ||
                exitCompleted?.commandArguments.exitPoint.longitude,
              exitStart?.commandArguments.exitPoint.latitude ||
                exitCompleted?.commandArguments.exitPoint.latitude,
            ],
          },
          properties: {},
        };

        return {
          point: exitPoint,
          startTime: activationTime ? moment.utc(activationTime).toDate() : startTime,
          endTime: endTime ? moment.utc(endTime).toDate() : searchEndTime,
        };
      });

      return exitPoints;
    }
  ),

  fetchAllSlotGroupsByFarmIds: createAsyncThunk(
    'message/getAllSlotGroupsByFarmIds',
    async (farmIdList: string[]): Promise<SlotGroup[] | []> => {
      const slotGroupIdList: string[] = [];
      await Promise.all(
        farmIdList.map(async (farmId) => {
          const slotGroupMapping = await MessageService.getSlotGroupMappingByFarmId(farmId);
          if (slotGroupMapping) {
            slotGroupIdList.push(slotGroupMapping.slotGroupId);
          }
        })
      );
      const slotGroupList: SlotGroup[] = [];
      await Promise.all(
        slotGroupIdList.map(async (slotGroupId) => {
          const slotGroupMappingList = await MessageService.getSlotGroupMappingsBySlotGroupId(slotGroupId);
          const featureList: Feature<Polygon>[] = [];
          if (!slotGroupMappingList) {
            return;
          }
          await Promise.all(
            slotGroupMappingList.map(async (slotGroupMapping) => {
              const paddocks = await TopographyService.fetchAllPaddocksForFarm(slotGroupMapping.farmId);
              let geoIndexList: string[] = [];
              paddocks.forEach((paddock) => {
                const innerGeoIndexxList = h3.polygonToCells(paddock.feature.geometry.coordinates, 10, true);
                geoIndexList = geoIndexList.concat(innerGeoIndexxList);
              });
              const multiPolygon = h3.cellsToMultiPolygon(geoIndexList, true);
              multiPolygon.forEach((polygon) => {
                const polygonFeature: IFeatureGeometryPolygon = {
                  type: IFeatureGeometryPolygon.TypeEnum.Polygon,
                  coordinates: polygon as number[][][],
                };
                const feature: Feature<Polygon> = {
                  type: 'Feature',
                  geometry: polygonFeature,
                  properties: {
                    name: slotGroupMapping.farmId,
                  },
                };
                featureList.push(feature);
              });
            })
          );
          const slotGroup: SlotGroup = {
            id: slotGroupId,
            slotGroupMappings: slotGroupMappingList?.map((slotGroupMapping) => slotGroupMapping.farmId) ?? [],
            features: featureList,
          };
          slotGroupList.push(slotGroup);
        })
      );

      return slotGroupList;
    }
  ),
};

export default heatMapEffects;
