import React, { FunctionComponent } from 'react';
import { TableContainer } from '@material-ui/core';

import { IDeviceDTO } from '@halter-corp/device-service-client';
import {
  cowColumn,
  collarColumn,
  firmwareVersionColumn,
  debugColumn,
  updatedAtColumn,
  statusColumn,
  swapRequestColumn,
  appMessageColumn
} from './farm-issues-columns';
import { CaddieColours } from '../../../theme/colours';
import DataTable from '../../../components/data-table';

type FarmIssuesTableProps = {
  devices: IDeviceDTO[] | undefined;
};

const FarmIssuesTable: FunctionComponent<FarmIssuesTableProps> = ({ devices }) => (
  <TableContainer>
    {devices &&
      <DataTable<IDeviceDTO>
        data={devices}
        columns={[cowColumn, collarColumn, debugColumn, firmwareVersionColumn, statusColumn, swapRequestColumn, appMessageColumn, updatedAtColumn]}
        defaultSortColumnName="Cow"
        rowColour="white"
        headerColour={CaddieColours.LightGrey}
        indexColumn={false}
      />
    }
  </TableContainer>
);
export default FarmIssuesTable;
