import {
  ICreateNetworkGroupFarmMappingRequestDTO,
  IDeviceDTO,
  IFarmDTO,
  IFarmMetadataDTO,
  INetworkGroupFarmMappingDTO,
  IShardDTO,
  IUpdateDeviceRequestDTO,
} from '@halter-corp/farm-infra-service-client';
import { AxiosError } from 'axios';

import HttpApiService from './http-api.service';

const FarmInfraService = {
  fetchDevicesByFarmId: async (farmId: string): Promise<IDeviceDTO[] | []> => {
    const api = await HttpApiService.getFarmInfraDeviceApi();
    try {
      const { data } = await api.getAllDevicesByFarmID(farmId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  fetchDevicesByShardId: async (shardId: string): Promise<IDeviceDTO[] | []> => {
    const api = await HttpApiService.getFarmInfraDeviceApi();
    try {
      const { data } = await api.getAllDevicesByShardID(shardId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },

  fetchFarmMetadataById: async (id: string): Promise<IFarmMetadataDTO | null> => {
    const api = await HttpApiService.getFarmInfraFarmApi();
    try {
      if (id.trim() === '') throw new Error('Farm id cannot be null');

      const { data } = await api.getFarmMetadataByID(id);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  updateDeviceById: async (
    deviceId: string,
    farmId: string,
    updateDeviceRequestDTO: IUpdateDeviceRequestDTO
  ): Promise<IDeviceDTO | null> => {
    const api = await HttpApiService.getFarmInfraDeviceApi();
    try {
      const { data } = await api.updateDeviceByIDAndFarmID(deviceId, farmId, updateDeviceRequestDTO);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  fetchShards: async (): Promise<IShardDTO[] | []> => {
    const api = await HttpApiService.getFarmInfraShardApi();
    try {
      const { data } = await api.getAllShards();
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  fetchFarms: async (): Promise<IFarmDTO[] | []> => {
    const api = await HttpApiService.getFarmInfraFarmApi();
    try {
      const { data } = await api.getAllFarms();
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  fetchNetworkGroupMappingsByFarm: async (farmId: string): Promise<INetworkGroupFarmMappingDTO[]> => {
    const api = await HttpApiService.getFarmInfraNetworkGroupApi();
    try {
      const { data } = await api.getAllNetworkGroupFarmMappingsByFarmID(farmId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  saveNetworkGroup: async (): Promise<string | null> => {
    const api = await HttpApiService.getFarmInfraNetworkGroupApi();
    try {
      const { data } = await api.createNetworkGroup();
      return data.id;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  saveNetworkGroupFarmMapping: async (
    request: ICreateNetworkGroupFarmMappingRequestDTO
  ): Promise<INetworkGroupFarmMappingDTO | null> => {
    const api = await HttpApiService.getFarmInfraNetworkGroupApi();
    try {
      const { data } = await api.createNetworkGroupFarmMapping(request);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
};

export default FarmInfraService;
