import React, { FunctionComponent, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { Column } from 'application/modules/fleet-watch/utils';
import Header from 'application/components/header';
import FleetService from 'services/fleet.service';
import { DeviceOffFarmCheckpointEnum } from '@halter-corp/fleet-service-client';
import QRCodeScanningScreen from './qr-code-scanning.screen';
import { Mode } from '../containers/fleet-tools.container';

type ScanPalletScreenProps = {
  setMode: (mode: Mode) => void;
};

const ScanPalletScreen: FunctionComponent<ScanPalletScreenProps> = ({ setMode }) => {
  const [scanning, setScanning] = useState(true);
  const [boxIds, setBoxIds] = useState<string[]>([]);
  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);
  const [requestSuccessful, setRequestSuccessful] = useState<boolean | undefined>(undefined);

  const handleBoxIds = (result: string) => {
    const pattern = /CT03PC\d{6}/; // thanks chat gpt

    const ids = result
      .split(',')
      .map((x) => x.trim())
      .filter((x) => pattern.test(x));
    setBoxIds(ids);
  };

  const handleSerialNumbers = (result: string) => {
    const pattern = /\b004-\d{4}-\d{5}\b/;

    const serials = result
      .split(',')
      .map((x) => x.trim())
      .filter((x) => pattern.test(x));
    setSerialNumbers(serials);
  };

  const handleScan = (result: string | null) => {
    if (result) {
      handleBoxIds(result);
      handleSerialNumbers(result);
    } else {
      setMode('none');
    }
    setScanning(false);
  };

  const markAsArrvied = async () => {
    const boxResponse = await FleetService.setArriveAtHalterCheckpoint(boxIds);
    const serialResponse = await FleetService.setOffFarmCheckpoint(
      serialNumbers,
      DeviceOffFarmCheckpointEnum.ARRIVED_AT_HALTER
    );
    setRequestSuccessful(boxResponse !== undefined && serialResponse !== undefined);
  };

  const close = () => {
    setMode('none');
    setBoxIds([]);
  };

  return (
    <>
      {scanning && <QRCodeScanningScreen handleScan={handleScan} />}
      {!scanning && (
        <Column>
          <Box m={0}>
            <Header text="Arrived at Halter" size="large" style={{ marginTop: '24px', marginLeft: '16px' }} />
            <Divider style={{ marginBottom: '16px' }} />
            <Box m={2}>
              {requestSuccessful === undefined && (
                <Grid container spacing={2} alignItems="center">
                  <Grid item style={{ width: '100%' }}>
                    {boxIds.length > 0 && (
                      <>
                        <Typography style={{ color: 'green' }}>{boxIds.length} Boxes found</Typography>
                        <Box
                          style={{
                            backgroundColor: '#f5f5f5',
                            padding: '10px',
                            borderRadius: '10px',
                            marginTop: '10px',
                          }}
                        >
                          <Typography style={{ color: 'green', fontSize: '12px' }}>
                            {boxIds.join(', ')}
                          </Typography>
                        </Box>
                      </>
                    )}
                    {serialNumbers.length > 0 && (
                      <>
                        <Typography style={{ color: 'green' }}>
                          {serialNumbers.length} Collars found
                        </Typography>
                        <Box
                          style={{
                            backgroundColor: '#f5f5f5',
                            padding: '10px',
                            borderRadius: '10px',
                            marginTop: '10px',
                          }}
                        >
                          <Typography style={{ color: 'green', fontSize: '12px' }}>
                            {serialNumbers.join(', ')}
                          </Typography>
                        </Box>
                      </>
                    )}

                    {boxIds.length === 0 && serialNumbers.length === 0 && (
                      <Typography style={{ color: 'red' }}>
                        Failed to read any box IDs or serial numbers
                      </Typography>
                    )}

                    <Typography style={{ fontStyle: 'italic', marginTop: '10px', fontSize: '12px' }}>
                      Mark as arrived will update the off-farm checkpoint of the collars in these boxes to
                      ARRIVED_AT_HALTER
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    direction="row"
                    style={{ marginTop: '10px' }}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{ minWidth: 'auto', fontSize: '12px', whiteSpace: 'nowrap' }}
                        onClick={() => setMode('none')}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    {(boxIds.length > 0 || serialNumbers.length > 0) && (
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{ minWidth: 'auto', fontSize: '12px', whiteSpace: 'nowrap' }}
                          onClick={markAsArrvied}
                        >
                          Mark as Arrived
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {requestSuccessful !== undefined && (
                <Grid container spacing={2} alignItems="center" direction="row" style={{ marginTop: '10px' }}>
                  <Grid item style={{ width: '100%' }}>
                    <Typography style={{ color: requestSuccessful ? 'green' : 'red' }}>
                      {requestSuccessful ? 'Success' : 'Failed'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      style={{ minWidth: 'auto', fontSize: '12px', whiteSpace: 'nowrap' }}
                      onClick={close}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </Column>
      )}
    </>
  );
};
export default ScanPalletScreen;
