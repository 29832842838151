import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type TowerInstallTooltipProps = {};

const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 8px;
`;

const TowerInstallTooltip: FunctionComponent<TowerInstallTooltipProps> = () => (
  <Tooltip style={{ fontSize: '16px', lineHeight: '24px' }}>
    <ul>
      <li>Right click on map to create or remove a staged tower</li>
      <li>Click on any tower to edit</li>
      <li>Use drawer on right side to edit, save or delete staged towers.</li>
      <li>For more info see Tower Planning under Launch in Notion</li>
    </ul>
  </Tooltip>
);

export default TowerInstallTooltip;
