import React, { FC, useState } from 'react';
import { Box, Typography, CardActions, CardContent, Button, Tooltip } from '@material-ui/core';

import EditIcon from '@material-ui/icons/EditSharp';
import DeleteIcon from '@material-ui/icons/Delete';
import { Warning } from '@material-ui/icons';
import styled from 'styled-components';
import moment from 'moment';
import NoteFormDialog from 'application/modules/install-planner/components/dialogs/note-form.dialog';
import { TowerWithDevicesAndNotes } from 'store/selectors/tower.selectors';
import { ITowerNoteDTO, IUpdateTowerNoteRequestDTO } from '@halter-corp/tower-service-client';
import DeleteDialog from '../action-dialogs/DeleteDialog';

const NoteWrapper = styled.div<{ caution: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 2px;
  height: 100%;
  border-radius: 3.5px;
  color: #424242;
  border: 1px solid ${(props) => (props.caution ? '#ff5722' : '#e0e0e0')};
  background-color: ${(props) => (props.caution ? '#fbe9e7aa' : '#fafafa')};
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  &:hover {
    background-color: ${(props) => (props.caution ? '#fbe9e7ff' : '#f3f3f3')};
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
`;

const NoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
`;

const NoteFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-left: 12.5px;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10.5px;
  gap: 3px;
`;

const getDateFormat = (date: Date) =>
  date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });

type TowerNoteCardProps = {
  note: ITowerNoteDTO;
  onDeleteNote: (noteCreatedAt: number) => void;
  onUpdateNote: (noteCreatedAt: number, updateNoteDto: IUpdateTowerNoteRequestDTO) => void;
  tower: TowerWithDevicesAndNotes;
};

const TowerNoteCard: FC<TowerNoteCardProps> = ({ note, tower, onDeleteNote, onUpdateNote }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const createdAt = new Date(note.createdAt * 1000);
  const updatedAt = new Date(note.updatedAt * 1000);
  const createdAtFormat = getDateFormat(createdAt);
  const updatedAtFormat = getDateFormat(updatedAt);
  const updatedAtRelative = moment.utc(updatedAt.toISOString()).fromNow();

  const handleEditAction = (updateDto: IUpdateTowerNoteRequestDTO) => {
    setEditModalOpen(false);
    onUpdateNote(note.createdAt, updateDto);
  };

  const handleDeleteAction = () => {
    setDeleteModalOpen(false);
    onDeleteNote(note.createdAt);
  };

  return (
    <>
      <NoteWrapper caution={note.caution}>
        <CardContent style={{ paddingTop: '12px' }}>
          <NoteHeader>
            <Typography style={{ fontWeight: 500, fontSize: 16.5 }} variant="body2">
              {note.note}
            </Typography>

            {note.caution && (
              <Tooltip title="Caution" arrow>
                <Warning htmlColor="#ff7043" style={{ fontSize: '32px' }} />
              </Tooltip>
            )}
          </NoteHeader>
        </CardContent>

        <NoteFooter>
          <DateWrapper>
            <Box style={{ margin: 0 }} component="p">
              Created At: <time style={{ fontWeight: 500 }}>{createdAtFormat}</time>
            </Box>

            <Tooltip title={`Updated At: ${updatedAtFormat}`} arrow>
              <Box style={{ margin: 0 }} component="p">
                Last Updated: <time style={{ fontWeight: 500 }}>{updatedAtRelative}</time>
              </Box>
            </Tooltip>
          </DateWrapper>

          <CardActions style={{ marginTop: 'auto' }}>
            <Button
              onClick={() => setEditModalOpen(true)}
              color="primary"
              style={{ minWidth: '10px', padding: '5px' }}
              size="small"
            >
              <Tooltip title="Edit Note" arrow>
                <EditIcon />
              </Tooltip>
            </Button>

            <Button
              style={{ marginLeft: '3px', color: '#e91e63cc', minWidth: '10px', padding: '5px' }}
              size="small"
              onClick={() => setDeleteModalOpen(true)}
            >
              <Tooltip title="Delete Note" arrow>
                <DeleteIcon />
              </Tooltip>
            </Button>
          </CardActions>
        </NoteFooter>
      </NoteWrapper>

      <DeleteDialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleDeleteAction}
        itemName="Note"
      />

      <NoteFormDialog
        initialNote={note}
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSave={handleEditAction}
        tower={tower}
      />
    </>
  );
};

export default TowerNoteCard;
