import React, { FunctionComponent } from 'react';
import { Device } from 'data/device';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { generateBuiltInTestStatusToolTip, hasFaults } from 'store/effects/device.effects/device-stats-util';
import { alarmsToString, deviceAlarmStatus } from './alarms.util';

type DeviceAlarmsOverviewProps = {
  device: Device;
};

const IconStyle = {
  float: 'right' as const, // Use 'as const' to indicate the exact string value,
  marginLeft: '5px',
};

const TooltipDivStyle = {
  whiteSpace: 'pre-line' as const,
};

const DeviceAlarmsOverview: FunctionComponent<DeviceAlarmsOverviewProps> = ({ device }) => (
  <>
    {deviceAlarmStatus(device.alarms)}
    {alarmsToString(device.alarms)}
    {hasFaults(device.stats?.builtInTestStatus) && (
      <Tooltip
        title={
          <div style={TooltipDivStyle}>
            This tooltip contains the old faults column, which is just raw BIT errors
            <br />
            {generateBuiltInTestStatusToolTip(device.stats?.builtInTestStatus)}
          </div>
        }
      >
        <InfoIcon fontSize="small" style={IconStyle} />
      </Tooltip>
    )}
  </>
);
export default DeviceAlarmsOverview;
