import { createAsyncThunk } from '@reduxjs/toolkit';
import { IThingDTO } from '@halter-corp/thing-service-client';
import ThingsService from 'services/thing.service';

const thingsEffects = {
  fetchBySerialNumbers: createAsyncThunk(
    'things/fetchBySerialNumbers',
    async (props: { serialNumbers: string[] }): Promise<IThingDTO[] | []> => {
      const { serialNumbers } = props;
      const data = await ThingsService.fetchThingsBySerialNumbers(serialNumbers);
      return data;
    }
  ),
};

export default thingsEffects;
