import React, { FunctionComponent } from 'react';
import _groupBy from 'lodash/groupBy';
import { Grid } from '@material-ui/core';
import { IMobDTO } from '@halter-corp/cattle-service-client';
import { ZoneReportSummary } from 'data/reports';
import MetricsCard from 'application/modules/reports/components/metrics.card';
import MetricsContainer from 'application/modules/reports/components/metrics.container';
import MetricsHeader from 'application/modules/reports/components/metrics.header';
import InfoBox from 'application/modules/reports/components/info.box';
import moment from 'moment';
import momenttz from 'moment-timezone';

type ZoneSummaryProps = {
  zone: ZoneReportSummary;
  mobs: IMobDTO[];
};

const ZoneSummaryPanel: FunctionComponent<ZoneSummaryProps> = ({ zone, mobs }) => {
  // Average time out of zone (min): 67.16
  // Max time out of zone (min): 81.10
  const mobsById = _groupBy(mobs, (m) => m.id);

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12}>
        <MetricsHeader>
          <InfoBox title="Start Time">
            {momenttz.tz(moment.unix(zone.startTimeUtcS), 'Pacific/Auckland').format('DD/MM/YYYY HH:mm:ss')}
          </InfoBox>
          <InfoBox title="End Time">
            {momenttz.tz(moment.unix(zone.endTimeUtcS), 'Pacific/Auckland').format('DD/MM/YYYY HH:mm:ss')}
          </InfoBox>
          <InfoBox title="Cow Count">{zone.cowCount}</InfoBox>
          <InfoBox title="Mobs">
            {zone.mobIds.map((mobId) => mobsById[mobId]?.map((m) => m.name) || mobId).join(', ')}
          </InfoBox>
        </MetricsHeader>
      </Grid>
      <Grid item xs={12}>
        <MetricsContainer>
          <MetricsCard
            title="Zone"
            data={{
              'Average left zone count': zone.zone.averageLeftZoneCount.toFixed(2),
              'Max left zone count': zone.zone.maxLeftZoneCount,
              'Average time out of zone (min)': zone.zone.averageTimeOutOfZone.toFixed(2),
              'Max time out of zone (min)': zone.zone.maxTimeOutOfZone,
            }}
          />

          <MetricsCard
            title="Piezo"
            data={{
              'Average Activations': zone.piezo.averageActivations.toFixed(2),
              'Max Activations': zone.piezo.maxActivations,
              'Average Intensity': zone.piezo.averageIntensity.toFixed(2),
            }}
          />

          <MetricsCard
            title="Shock"
            data={{
              'Average Confirmed': zone.shock.averageConfirmed.toFixed(2),
              'Max Confirmed': zone.shock.maxConfirmed,
              'Total Confirmed': zone.shock.totalConfirmed,
              'Average Lock Out Count': zone.shock.averageLockoutCount.toFixed(2),
              'Max Lock Out Count': zone.shock.maxLockoutCount,
              'Total Lock Out Count': zone.shock.totalLockoutCount,
              'Average Failed Shocks': zone.shock.averageFailed.toFixed(2),
              'Max Failed Shocks': zone.shock.maxFailed,
              'Total Failed Shocks': zone.shock.totalFailed,
            }}
          />
        </MetricsContainer>
      </Grid>
    </Grid>
  );
};

export default ZoneSummaryPanel;
