import { createSlice } from '@reduxjs/toolkit';
import { IThingDTO } from '@halter-corp/thing-service-client';

import { reset } from 'store/actions';
import thingsEffects from 'store/effects/things.effects';

type ThingsState = {
  things: IThingDTO[];
};

const initialState: ThingsState = {
  things: [],
};

const thingsSlice = createSlice({
  initialState,
  name: 'things',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(thingsEffects.fetchBySerialNumbers.fulfilled, (state, action) => {
        state.things = action.payload;
      }),
});

export default thingsSlice;
