import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { StatusColours } from 'application/components/status-icon';
import { DeviceWithMetadata, TowerWithDevices } from 'store/selectors/tower.selectors';
import { ProductTypeEnum } from '@halter-corp/discovery-service-client';

export const getGatewayStatusColor = (gateway: DeviceWithMetadata | undefined) => {
  if (gateway == null || gateway.status?.enabled === false) return 'silver';
  if (gateway.status?.lastSeenTimestamp != null) {
    const minutesSinceCheckin =
      (Date.now() - moment.utc(gateway.status?.lastSeenTimestamp).valueOf()) / 60000;
    if (minutesSinceCheckin < 15) {
      return 'white';
    }
    if (minutesSinceCheckin < 20) {
      return StatusColours.AMBER;
    }
  }
  return StatusColours.RED;
};

export const useDebugModeDisabled = () => {
  const location = useLocation();

  const disableDebugMode: boolean = useMemo(() => {
    const urlQueryString = new URLSearchParams(location.search);
    return urlQueryString.get('disableDebugMode') === 'true';
  }, [location.search]);

  return disableDebugMode;
};

export const getTowersWithGateway = (towers: TowerWithDevices[]) =>
  towers.filter((tower) => tower.devices.find((deice) => deice.type === ProductTypeEnum.GATEWAY.toString()));
