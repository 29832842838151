import React, { FunctionComponent, CSSProperties } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
`;

type ScreenWrapperProps = {
  style?: CSSProperties;
};

const ScreenWrapper: FunctionComponent<ScreenWrapperProps> = ({ style, children }) => (
  <Wrapper style={style}>{children}</Wrapper>
);

export default ScreenWrapper;
