import { ISearchResultItemDTO } from '@halter-corp/bff-debug-tool-service-client';
import { isEmpty, chunk, flatten, sortBy, uniq, keyBy } from 'lodash';
import moment from 'moment';
import { Point } from 'leaflet';
import { HistoryDeviceCommandStatusEnum, HistoryEventTypeEnum } from '@halter-corp/timeline-service-client';
import { ICommandDTO } from '@halter-corp/cowtroller-service-client';
import HttpApiService from './http-api.service';

import LuceneQueryService, { BffDebugToolUserParameters } from './lucene-query.service';
import { startAndEndOfDates } from '../application/utils';
import BffDebugToolQueryService from './bff-debug-tool.query-service';

const positionDeviceMetricsDebugFields = [
  'metricName',
  'context.cattleName',
  'metric.position.estimatedLatitude',
  'metric.position.estimatedLongitude',
  'metric.position.latitude',
  'metric.position.longitude',
  'metric.position.fixAge',
].join(',');

const cowgEventStreamDeviceMetricsDebugFields = [
  'metricName',
  'serialNumber',
  'context',
  'metric.cowgEventStream.cowgStatus',
  'metric.cowgEventStream.locationMetadata.heading',
  'metric.cowgEventStream.locationMetadata.targetHeading',
  'metric.cowgEventStream.location.lat',
  'metric.cowgEventStream.location.lon',
  'metric.cowgEventStream.fixAge',
  'metric.cowgEventStream.eventType',
  'metric.cowgEventStream.firmwareVersion',
  'metric.cowgEventStream.pulseAttemptCount',
  'metric.cowgEventStream.pulseSuccessCount',
].join(',');

const historyEventsDebugFields = [
  'eventType',
  'payload.device.serialNumber',
  'payload.command.type',
  'payload.command.collarCommandId',
  'payload.command.status',
  'payload.command.referenceId',
  'payload.command.groupId',
  'payload.command.boundaryGeometry',
  'payload.command.exitPoint',
  'payload.command.exitPath',
  'payload.command.geometry',
].join(',');

export interface IMapPageItems {
  positionMetrics: ISearchResultItemDTO[];
  cowgEventStreamMetricsResult: ISearchResultItemDTO[];
  historyEvents: ISearchResultItemDTO[];
  outOfRangeEvents: ISearchResultItemDTO[];
}

export type LiveBreakCoordinate = {
  id: string;
  coordinates: Point[];
};

const BffDebugToolService = {
  fetchLatestLiveBreaks: async (
    farmId: string,
    minutesToLoad: number = 10
  ): Promise<LiveBreakCoordinate[]> => {
    const fetchLatestDeviceMetrics = async (fId: string, minutes: number = 10) => {
      const api = await HttpApiService.getBffDebugToolApi();
      const latestMetricsQuery = LuceneQueryService.generateLatestMetricsQuery({ farmId: fId, minutes });
      const latestMetrics = await api.getDeviceMetrics(latestMetricsQuery);

      const result = latestMetrics.data.items;
      return result;
    };

    const fetchHistoryEventsByCommandId = async (
      commandId: string,
      statuses?: HistoryDeviceCommandStatusEnum[]
    ) => {
      const api = await HttpApiService.getBffDebugToolApi();
      const eventQuerySection = LuceneQueryService.generateHistoryEventsByCommandIdQuery({
        commandId,
        statuses,
      });
      const historyEventResult = await api.getHistoryEvents(eventQuerySection);
      return historyEventResult.data.items;
    };

    try {
      const metrics = await fetchLatestDeviceMetrics(farmId, minutesToLoad);
      const uniqueCommandIds: string[] = Array.from(
        new Set<string>(metrics.map((metric) => metric.data.metric.lastCommandStatus.commandId))
      );

      // Fetch history events of the commandId that are either ACTIVE or COMPLETED
      const historyEventsArray = await Promise.all(
        uniqueCommandIds.map((commandId) =>
          fetchHistoryEventsByCommandId(commandId, [
            HistoryDeviceCommandStatusEnum.ACTIVE,
            HistoryDeviceCommandStatusEnum.COMPLETED,
          ])
        )
      );

      // History events are already sorted in descending order by timestamp
      const latestActiveHistoryEvents = historyEventsArray.map((events) => events[0]);

      const liveBreakCoordinates = latestActiveHistoryEvents.map((event) => ({
        id: event.id,
        coordinates: event.data.payload.command.geometry.geometry.coordinates,
      }));

      return liveBreakCoordinates;
    } catch (err) {
      return [];
    }
  },

  fetchMapPageData: async (
    props: BffDebugToolUserParameters
  ): Promise<{ cowg: any[]; positions: any[]; commands: ICommandDTO[] }> => {
    const {
      farmId,
      cattleNames: cattleNamesFromParam,
      serialNumbers,
      collarCommandIds,
      firmwareVersions,
      timestamp,
      mobIds,
    } = props;

    const filterQueryChunks: string[] = [`and farm_id = '${farmId}' and cattle_id is not null`];
    if (mobIds != null && !isEmpty(mobIds)) {
      filterQueryChunks.push(`and mob_id in (${mobIds.map((mobId) => `'${mobId}'`).join(',')})`);
    }
    if (cattleNamesFromParam != null && !isEmpty(cattleNamesFromParam)) {
      filterQueryChunks.push(
        `and cattle_name in (${cattleNamesFromParam.map((name) => `'${name}'`).join(',')})`
      );
    }
    if (serialNumbers != null && !isEmpty(serialNumbers)) {
      filterQueryChunks.push(
        `and serial_number in (${serialNumbers.map((serial) => `'${serial}'`).join(',')})`
      );
    }
    if (firmwareVersions != null && !isEmpty(firmwareVersions)) {
      filterQueryChunks.push(
        `and firmware_version in (${firmwareVersions.map((version) => `'${version}'`).join(',')})`
      );
    }

    const cowgEventsQuery = async (): Promise<any[]> => {
      const queryChunks = [
        `select distinct serial_number as serialNumber, cattle_id as cattleId, cattle_name as cattleName, command_id as collarCommandId, cowg_status_list as cowgStatuses,`,
        `fix_age as fixAge, target_heading_degrees as targetHeading, firmware_version as firmwareVersion, pulse_attempt_count as pulseAttemptCount, pulse_success_count as pulseSuccessCount,`,
        `case event_type when 0 then '' else cast(event_type as varchar) end as eventType, heading_degrees as heading, latitude, longitude, utc_timestamp as timestamp,`,
        `peak_shock_confirm_current_amps as peakShockConfirmCurrentAmps from "device_cowg_events" where cast(partition_utc_timestamp as varchar) between '${moment
          .utc(timestamp[0])
          .format('YYYY-MM-DD')}' and '${moment.utc(timestamp[1]).format('YYYY-MM-DD')}z'`,
        `and cast(utc_timestamp as varchar) between '${timestamp[0]
          .toISOString()
          .replace('T', ' ')}' and '${timestamp[1].toISOString().replace('T', ' ')}'`,
        ...filterQueryChunks,
        ...(collarCommandIds != null && !isEmpty(collarCommandIds)
          ? [`and command_id in (${collarCommandIds.map((c) => `'${c}'`).join(',')})`]
          : []),
        'order by utc_timestamp asc',
      ];

      const query = queryChunks.join(' ');
      const items = await BffDebugToolQueryService.query(query);

      return items.map((item: any) => ({
        ...item,
        cowgStatuses: (item.cowgStatuses as string)
          .replace('[', '')
          .replace(']', '')
          .split(', ')
          .filter((s) => s !== ''),
        eventType: parseInt(isEmpty(item.eventType) ? 0 : item.eventType, 10),
        timestamp: `${item.timestamp.replace(' ', 'T')}Z`,
      }));
    };

    const positionsQuery = async (): Promise<any[]> => {
      const queryChunks = [
        `select cattle_name as cattleName, latitude, longitude, utc_timestamp as timestamp`,
        `from "device_position_metrics"`,
        `where cast(partition_utc_timestamp as varchar) between '${moment
          .utc(timestamp[0])
          .format('YYYY-MM-DD')}' and '${moment.utc(timestamp[1]).format('YYYY-MM-DD')}z'`,
        `and cast(utc_timestamp as varchar) between '${timestamp[0]
          .toISOString()
          .replace('T', ' ')}' and '${timestamp[1].toISOString().replace('T', ' ')}'`,
        ...filterQueryChunks,
        'order by utc_timestamp asc',
      ];

      const query = queryChunks.join(' ');
      const items = await BffDebugToolQueryService.query(query);

      return items.map((item: any) => ({
        ...item,
        timestamp: `${item.timestamp.replace(' ', 'T')}Z`,
      }));
    };

    const cowgEventsQueryAndCommands = async () => {
      const cowg = await cowgEventsQuery();

      const reportedCollarCommandIds = uniq(cowg.map((c) => c.collarCommandId))
        .filter((c) => c != null && c !== '0')
        .map((c) => parseInt(c, 10));
      const cowtrollerApi = await HttpApiService.getCowtrollerCommandsApi();
      const commands = (
        await Promise.all(
          reportedCollarCommandIds.map(async (collarCommandId) => {
            const { data: commandsForCollarCommandId } = await cowtrollerApi.getCommands(
              1,
              0,
              collarCommandId
            );
            return commandsForCollarCommandId[0];
          })
        )
      ).filter((command) => command != null);

      return { cowg, commands };
    };

    // eslint-disable-next-line prefer-const, no-shadow
    let [{ cowg, commands }, positions] = await Promise.all([cowgEventsQueryAndCommands(), positionsQuery()]);

    if (!isEmpty(collarCommandIds)) {
      const cowsInCowgEvents = uniq(cowg.map((c) => c.cattleName));
      const cowsAsMap = keyBy(cowsInCowgEvents, (c) => c);
      positions = positions.filter((p) => cowsAsMap[p.cattleName] != null);
    }

    return { cowg, positions, commands };
  },

  fetchMapPageData2: async (props: BffDebugToolUserParameters): Promise<IMapPageItems> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const {
      farmId,
      cattleNames: cattleNamesFromParam,
      serialNumbers,
      customQuery,
      timestamp,
      mobIds,
      fetchAllDeviceMetrics,
    } = props;

    const cattleNames = cattleNamesFromParam ?? [];

    const findEventsOutOfDateRange = async (
      itemsFromUserQuery: ISearchResultItemDTO[]
    ): Promise<ISearchResultItemDTO[]> => {
      const commandIdsReportedWithinQueryTimeRange = new Set(
        itemsFromUserQuery
          .map((item) => item.data.metric?.cowgEventStream?.commandId)
          .filter((commandId) => commandId != null && commandId !== 0)
      );

      if (commandIdsReportedWithinQueryTimeRange.size === 0) {
        return [];
      }
      const { data: itemsOutOfDataRangeResponse } = await api.getHistoryEvents(
        LuceneQueryService.generateQueryForEventsOutOfDateRange({
          commandIds: [...commandIdsReportedWithinQueryTimeRange],
          serialNumbers,
          farmId,
          cattleNames,
          timestamp,
        }),
        undefined
      );
      return itemsOutOfDataRangeResponse.items;
    };

    const fetchDeviceMetrics = async (
      metricNames: string[],
      fieldsProjection: string,
      paginationToken?: string
    ): Promise<ISearchResultItemDTO[]> => {
      const staticQuerySection = LuceneQueryService.generateDeviceMetricsQuery({
        farmId,
        cattleNames,
        serialNumbers,
        timestamp,
        mobIds,
        fetchAllDeviceMetrics,
        metricNames,
      });
      const customQuerySection = customQuery || '';
      const { data } = await api.getDeviceMetrics(
        LuceneQueryService.combineQueries(staticQuerySection, customQuerySection),
        paginationToken,
        10000,
        fieldsProjection
      );
      if (data.paginationToken != null) {
        const otherItems = await fetchDeviceMetrics(metricNames, fieldsProjection, data.paginationToken);
        return [...data.items, ...otherItems];
      }
      return data.items;
    };

    const fetchHistoryEvents = async (paginationToken?: string): Promise<ISearchResultItemDTO[]> => {
      const eventTypes: HistoryEventTypeEnum[] = [HistoryEventTypeEnum.DeviceCommandEvent];
      const staticQuerySection = LuceneQueryService.generateHistoryEventsQuery({
        eventTypes,
        farmId,
        mobIds,
        cattleNames,
        serialNumbers,
        timestamp,
      });
      const customQuerySection = customQuery || '';
      const { data } = await api.getHistoryEvents(
        LuceneQueryService.combineQueries(staticQuerySection, customQuerySection),
        paginationToken,
        10000,
        historyEventsDebugFields
      );
      if (data.paginationToken != null) {
        const otherItems = await fetchHistoryEvents(data.paginationToken);
        return [...data.items, ...otherItems];
      }
      return data.items;
    };

    const [positionMetrics, cowgEventStreamMetricsResult, historyEvents] = await Promise.all([
      fetchDeviceMetrics(['POSITION'], positionDeviceMetricsDebugFields),
      fetchDeviceMetrics(['COWG_EVENT_STREAM'], cowgEventStreamDeviceMetricsDebugFields),
      fetchHistoryEvents(),
    ]);
    const outOfRangeEvents = await findEventsOutOfDateRange(cowgEventStreamMetricsResult);

    return {
      positionMetrics: sortBy(positionMetrics, 'timestamp'),
      cowgEventStreamMetricsResult: sortBy(cowgEventStreamMetricsResult, 'timestamp'),
      historyEvents: sortBy(historyEvents, 'timestamp'),
      outOfRangeEvents: sortBy(outOfRangeEvents, 'timestamp'),
    };
  },

  fetchDeviceMetricByTimeAndDevice: async (
    timestamp: string,
    serialNumber: string
  ): Promise<ISearchResultItemDTO[]> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `timestamp:"${timestamp}" AND serialNumber:"${serialNumber}"`;
    const { data } = await api.getDeviceMetrics(esQuery);
    return data.items;
  },

  getStatsForDevicesOverview: async (serialNumbers: string[]): Promise<ISearchResultItemDTO[]> => {
    if (isEmpty(serialNumbers)) return [];
    const api = await HttpApiService.getBffDebugToolApi();

    const serialNumberChunks = chunk(serialNumbers, 25);

    const frequentMetricsQuery =
      'metricName:("LAST_COMMAND_STATUS" OR "LORA_CHECKIN" OR "POWER_GENERATION" OR "BUILT_IN_TEST_STATUS" OR "POWER_USAGE" OR "WIFI_CHECKIN")';
    const frequentTimePeriod = `timestamp:[${moment(new Date())
      .subtract(1, 'hour')
      .toISOString()} TO ${new Date().toISOString()}]`;

    const queries = flatten(
      serialNumberChunks.map((serialNumberChunk) => {
        const serialNumbersQuery = `serialNumber:(${serialNumberChunk
          .map((serialNumber) => `"${serialNumber}"`)
          .join(' OR ')})`;

        const esQueryFrequentMetrics = [serialNumbersQuery, frequentMetricsQuery, frequentTimePeriod].join(
          ' AND '
        );
        return [esQueryFrequentMetrics];
      })
    );

    const results = await Promise.all(
      queries.map((query) => api.getDeviceMetrics(query, undefined, undefined))
    );
    return flatten(results.map(({ data }) => data.items));
  },

  getLastCommandStatusForDevice: async (
    serialNumber: string,
    baseDate: Date = new Date()
  ): Promise<ISearchResultItemDTO | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `serialNumber:"${serialNumber}" AND metricName:"LAST_COMMAND_STATUS" AND timestamp:[* TO ${baseDate.toISOString()}]`;
    const { data } = await api.getDeviceMetrics(esQuery, undefined, 1);
    return data.items[0];
  },

  getLastWifiCheckinForDevice: async (
    serialNumber: string,
    baseDate: Date = new Date()
  ): Promise<ISearchResultItemDTO | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `serialNumber:"${serialNumber}" AND metricName:"WIFI_CHECKIN" AND timestamp:[* TO ${baseDate.toISOString()}]`;
    const { data } = await api.getDeviceMetrics(esQuery, undefined, 1);
    return data.items[0];
  },

  getLastLoraCheckinForDevice: async (
    serialNumber: string,
    baseDate: Date = new Date()
  ): Promise<ISearchResultItemDTO | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `serialNumber:"${serialNumber}" AND metricName:"LORA_CHECKIN" AND timestamp:[* TO ${baseDate.toISOString()}]`;
    const { data } = await api.getDeviceMetrics(esQuery, undefined, 1);
    return data.items[0];
  },

  getLastDeviceLocationForDevice: async (
    serialNumber: string,
    baseDate: Date = new Date()
  ): Promise<ISearchResultItemDTO | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `serialNumber:"${serialNumber}" AND metricName:"POSITION" AND timestamp:[* TO ${baseDate.toISOString()}]`;
    const { data } = await api.getDeviceMetrics(esQuery, undefined, 1);
    return data.items[0];
  },

  getLastCowgEventForDevice: async (
    serialNumber: string,
    baseDate: Date = new Date()
  ): Promise<ISearchResultItemDTO | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `serialNumber:"${serialNumber}" AND metricName:"COWG_EVENT_STREAM" AND timestamp:[* TO ${baseDate.toISOString()}]`;
    const { data } = await api.getDeviceMetrics(esQuery, undefined, 1);
    return data.items[0];
  },

  getLastPowerGenerationForDevice: async (
    serialNumber: string,
    baseDate: Date = new Date()
  ): Promise<ISearchResultItemDTO | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `serialNumber:"${serialNumber}" AND metricName:"POWER_GENERATION" AND timestamp:[* TO ${baseDate.toISOString()}]`;
    const { data } = await api.getDeviceMetrics(esQuery, undefined, 1);
    return data.items[0];
  },

  getLastBuiltInTestStatusForDevice: async (
    serialNumber: string,
    baseDate: Date = new Date()
  ): Promise<ISearchResultItemDTO | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `serialNumber:"${serialNumber}" AND metricName:"BUILT_IN_TEST_STATUS" AND timestamp:[* TO ${baseDate.toISOString()}]`;
    const { data } = await api.getDeviceMetrics(esQuery, undefined, 1);
    return data.items[0];
  },

  getLastDeviceStateReportForDevice: async (
    serialNumber: string,
    baseDate: Date = new Date()
  ): Promise<ISearchResultItemDTO | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `serialNumber:"${serialNumber}" AND metricName:"DEVICE_STATE_REPORT" AND timestamp:[* TO ${baseDate.toISOString()}]`;
    const { data } = await api.getDeviceMetrics(esQuery, undefined, 1);
    return data.items[0];
  },

  getLastDeviceUpdateForDevice: async (
    serialNumber: string,
    baseDate: Date = new Date()
  ): Promise<ISearchResultItemDTO | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `eventType:"DeviceUpdateEvent"
    AND payload.device.serialNumber:"${serialNumber}"
    AND eventDatetime:[* TO ${baseDate.toISOString()}]`;
    const { data } = await api.getHistoryEvents(esQuery, undefined, 1);
    return data.items[0];
  },

  getLastSettingsV2ForDevice: async (
    serialNumber: string,
    baseDate: Date = new Date()
  ): Promise<ISearchResultItemDTO | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `eventType:"SettingsEvent"
    AND payload.settingsEvent.data.serialNumber:"${serialNumber}"
    AND eventDatetime:[* TO ${baseDate.toISOString()}]`;
    const { data } = await api.getHistoryEvents(esQuery, undefined, 1);
    return data.items[0];
  },

  getLastHeartbeatAttemptForDevice: async (
    serialNumber: string,
    baseDate: Date = new Date()
  ): Promise<ISearchResultItemDTO | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `payload.deviceRequestResponseEvent.payloadType:"HEARTBEAT_REQUEST_TYPE"
    AND payload.device.serialNumber:"${serialNumber}"
    AND eventDatetime:[* TO ${baseDate.toISOString()}]`;
    const { data } = await api.getHistoryEvents(esQuery, undefined, 1);
    return data.items[0];
  },

  getCalibrationCheck: async (
    [from, to]: [Date, Date | undefined],
    serialNumbers?: string[],
    farmId?: string
  ): Promise<ISearchResultItemDTO[]> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQueryChunks = [
      'metricName:"BUILT_IN_TEST_STATUS"',
      `timestamp:[${from.toISOString()} TO ${(to ?? new Date()).toISOString()}]`,
    ];
    if (!isEmpty(serialNumbers)) {
      esQueryChunks.push(`serialNumber:(${serialNumbers?.map((s) => `"${s}"`).join(' OR ')})`);
    } else {
      esQueryChunks.push(`context.farmId:"${farmId}"`);
    }
    const esQuery = esQueryChunks.join(' AND ');
    const { data } = await api.getDeviceMetrics(esQuery, undefined);
    return data.items;
  },

  getFarmsWithActiveDevices: async (
    baseDate: Date = new Date(),
    hours: number = 1
  ): Promise<string[] | undefined> => {
    const api = await HttpApiService.getBffDebugToolApi();
    const esQuery = `timestamp:["${new Date(
      baseDate.getTime() - hours * 60 * 60000
    ).toISOString()}" TO "${baseDate.toISOString()}"]
      AND metricName:"LORA_CHECKIN"
      AND _exists_:context.cattleId`;
    const fieldsProjection = `context.farmId`;
    const { data } = await api.getDeviceMetrics(esQuery, undefined, undefined, fieldsProjection, undefined);
    const farmIds = [
      ...new Set(data.items.flatMap((item: ISearchResultItemDTO) => item.data.context.farmId)),
    ].sort() as string[];
    return farmIds;
  },

  getFleetMetrics: async (farmId: string, day: Date): Promise<ISearchResultItemDTO[]> => {
    const [start, end] = startAndEndOfDates(day);

    const api = await HttpApiService.getBffDebugToolApi();
    const esQueryChunks = [
      'metricName:DEVICE_FAULTS',
      `metric.deviceFaults.farmId:${farmId}`,
      `timestamp:["${start.toISOString()}" TO "${end.toISOString()}"]`,
    ];

    const { data } = await api.getFleetMetrics(esQueryChunks.join(' AND '), undefined);
    return data.items;
  },
};

export default BffDebugToolService;
