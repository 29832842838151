import React, { ChangeEvent, FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import farmInfraEffects from 'store/effects/farm-infra.effects';
import { selectCurrentFarm, selectFarms } from 'store/selectors/farm-infra.selectors';
import { IFarmDTO } from '@halter-corp/farm-infra-service-client';
import farmInfraSlice from 'store/slices/farm-infra.slice';

type ShardedFarmSelectProps = {
  onChange?: (value: string) => void;
  variant?: 'standard' | 'outlined' | 'filled';
  inputLabelStyle?: any;
};

const ShardedFarmSelect: FunctionComponent<ShardedFarmSelectProps> = ({ onChange }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(farmInfraEffects.fetchFarms());
  }, [dispatch]);

  const currentFarmId = useSelector(selectCurrentFarm);
  const farms = useSelector(selectFarms);

  const handleFarmChange = (_event: ChangeEvent<{}>, newFarm: IFarmDTO | null) => {
    if (newFarm == null) {
      return;
    }

    if (onChange != null) {
      onChange(newFarm.id);
    }

    dispatch(
      farmInfraSlice.actions.setCurrentFarm({
        currentFarm: newFarm.id,
      })
    );
  };

  useEffect(() => {
    if (currentFarmId != null && onChange != null) {
      onChange(currentFarmId);
    }
  }, []);

  return (
    <FormControl style={{ width: '100%' }}>
      {farms.length > 0 && (
        <Autocomplete
          options={farms}
          renderInput={(farm) => <TextField {...farm} label="Farm" />}
          getOptionLabel={(option) => option.name || ''}
          value={farms.find(({ id }) => id === currentFarmId)}
          onChange={handleFarmChange}
        />
      )}
    </FormControl>
  );
};

export default ShardedFarmSelect;
