import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import BehaviourContainer from './containers/behaviour.container';

const BehaviourModule: FunctionComponent = () => (
  <Switch>
    <Route exact path="/behaviour">
      <BehaviourContainer />
    </Route>
  </Switch>
);

export default BehaviourModule;
