import { ProductTypeEnum } from '@halter-corp/discovery-service-client';
import StatusIcon, { StatusColours } from 'application/components/status-icon';
import { ProductVendorEnum } from 'application/utils/infrastructureUtils';
import moment from 'moment';
import React from 'react';
import { DeviceWithMetadata } from 'store/selectors/tower.selectors';

export const generateStatusIcon = (color: StatusColours) => <StatusIcon color={color} />;

export const getStatusColor = (time: string, device: DeviceWithMetadata) => {
  if (!device.status?.enabled) {
    return StatusColours.GRAY;
  }
  const { type, vendor } = device;
  const minutesSinceCheckin = (Date.now() - moment.utc(time).valueOf()) / 60000;
  if (
    (type === ProductTypeEnum.SOLAR_SWITCH &&
      vendor === ProductVendorEnum.UBIQUITI &&
      minutesSinceCheckin > 60) ||
    (type === ProductTypeEnum.SOLAR_SWITCH &&
      vendor === ProductVendorEnum.HALTER &&
      minutesSinceCheckin > 5) ||
    (type === ProductTypeEnum.GATEWAY && minutesSinceCheckin > 20)
  ) {
    return StatusColours.RED;
  }
  if (
    (type === ProductTypeEnum.SOLAR_SWITCH &&
      vendor === ProductVendorEnum.UBIQUITI &&
      minutesSinceCheckin > 30) ||
    (type === ProductTypeEnum.SOLAR_SWITCH &&
      vendor === ProductVendorEnum.HALTER &&
      minutesSinceCheckin > 3) ||
    (type === ProductTypeEnum.GATEWAY && minutesSinceCheckin > 15)
  ) {
    return StatusColours.AMBER;
  }
  return StatusColours.GREEN;
};

export const getSuspendedColor = (device: DeviceWithMetadata) => {
  if (device.status?.suspended) {
    return StatusColours.BLUE;
  }
  return StatusColours.GREEN;
};

export function deviceStatusKeyExtractor(device: DeviceWithMetadata): number {
  if (device.status?.onOutage) {
    return 0;
  }
  if (device.settings?.forceOff) {
    return 1;
  }
  if (device.settings?.forceRoundRobin) {
    return 2;
  }
  if (device.status?.suspended) {
    return 3;
  }
  return 4;
}

export function getDeviceStatusColor(device: DeviceWithMetadata): StatusColours {
  if (device.status?.enabled === false) {
    return StatusColours.GRAY;
  }
  if (device.status?.onOutage) {
    return StatusColours.RED;
  }
  if (device.settings?.forceOff) {
    return StatusColours.BLUE;
  }
  if (device.settings?.forceRoundRobin) {
    return StatusColours.BLUE;
  }
  if (device.status?.suspended) {
    return StatusColours.BLUE;
  }
  return StatusColours.GREEN;
}

export function getHighestPriorityStatus(device: DeviceWithMetadata): string {
  if (device.status?.onOutage) {
    return 'On Outage';
  }
  if (device.settings?.forceOff) {
    return 'Forced Off';
  }
  if (device.settings?.forceRoundRobin) {
    return 'Force Round Robin';
  }
  if (device.status?.suspended) {
    return 'Suspended';
  }
  if (device.status?.enabled === false) {
    return 'Disabled';
  }
  return 'Normal';
}

export function getSelectionTimeLeft(device: DeviceWithMetadata): string {
  const { loraCoverageSelection, status } = device;
  const unixNow = Math.floor(Date.now() / 1000);
  if (loraCoverageSelection == null) return 'No current selection';
  const { keepAliveUntil, suspendUntil } = loraCoverageSelection;
  if (suspendUntil != null && suspendUntil > unixNow && !status?.suspended && suspendUntil < unixNow + 300) {
    return 'round robin';
  }
  if (suspendUntil != null && suspendUntil > unixNow && !status?.suspended) {
    return `pending suspension`;
  }
  if (suspendUntil != null && suspendUntil > unixNow) {
    return `suspend expires ${moment.unix(device.loraCoverageSelection?.suspendUntil!).fromNow()}`;
  }
  if (keepAliveUntil != null && keepAliveUntil > unixNow) {
    return `force on expires ${moment.unix(device.loraCoverageSelection?.keepAliveUntil!).fromNow()}`;
  }
  return 'No current selection';
}

export function selectionKeyExtractor(device: DeviceWithMetadata): number {
  const { loraCoverageSelection } = device;
  const unixNow = Math.floor(Date.now() / 1000);
  if (loraCoverageSelection == null) return 0;
  const { keepAliveUntil, suspendUntil } = loraCoverageSelection;
  if (suspendUntil != null && suspendUntil > unixNow) {
    return suspendUntil * 10;
  }
  if (keepAliveUntil != null && keepAliveUntil > unixNow) {
    return keepAliveUntil;
  }
  return 0;
}

export function getPrettifiedDeviceType(device: DeviceWithMetadata): string {
  switch (device.type) {
    case ProductTypeEnum.GATEWAY:
      return 'Gateway';
    case ProductTypeEnum.SOLAR_SWITCH:
      return 'Solar Switch';
    case ProductTypeEnum.TOWER_NODE:
      return 'Mesh';
    case ProductTypeEnum.ROUTER:
      return 'Router';
    default:
      return device.type;
  }
}
