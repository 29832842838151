import { Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const LoginError: FunctionComponent = () => (
  <Wrapper>
    <TextWrapper>
      <Typography variant="h2" color="error">
        Whoops! Looks like something went wrong!
      </Typography>
    </TextWrapper>
  </Wrapper>
);

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  padding: 16px 32px 16px 32px;
  background-color: black;
  border-radius: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  padding: 16px 32px 16px 32px;
`;

export default LoginError;
