import React, { FunctionComponent } from 'react';
import Button from 'application/components/button';
import { MapMode } from '../types';

type MapModeButtonProps = {
  mapMode: MapMode;
  onChangeMapMode: (mode: MapMode) => void;
};

const MapModeButton: FunctionComponent<MapModeButtonProps> = ({ mapMode, onChangeMapMode }) => {
  const handleToggleMapMode = () => {
    onChangeMapMode(mapMode === MapMode.HEAT_MAP ? MapMode.KEY_HOLE_RADIO_MAP : MapMode.HEAT_MAP);
  };

  return (
    <Button
      variant="contained"
      size="small"
      text={mapMode === MapMode.HEAT_MAP ? 'View Key Hole Radio Mode' : 'View Heat Map Mode'}
      onClick={handleToggleMapMode}
    />
  );
};

export default MapModeButton;
