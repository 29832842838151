import { createAsyncThunk } from '@reduxjs/toolkit';

import HttpApiService from 'services/http-api.service';
import { Report, TransitionMetrics, ZoneSummary, ReportData } from 'data/reports';

type FetchAllReportsRequest = { pageSize: number; page: number };
type FetchReportRequest = { commandId: string };
type FetchReporDatatRequest = { collarCommandId: number };

const reportsEffects = {
  fetchAllReports: createAsyncThunk(
    'reports/fetch/all',
    async ({ pageSize, page }: FetchAllReportsRequest): Promise<Report[]> => {
      const cowtrollerCommandsApi = await HttpApiService.getCowtrollerCommandsApi();
      const { data: commands } = await cowtrollerCommandsApi.getCommands(pageSize, page * pageSize);
      return commands;
    }
  ),

  fetchReportDetails: createAsyncThunk(
    'reports/fetch/details',
    async (props: FetchReportRequest): Promise<Report> => {
      const cowtrollerCommandsApi = await HttpApiService.getCowtrollerCommandsApi();
      const { data } = await cowtrollerCommandsApi.getCommand(props.commandId);
      return data;
    }
  ),

  fetchReportData: createAsyncThunk(
    'reports/fetch/data',
    async (props: FetchReporDatatRequest): Promise<ReportData> => {
      const api = await HttpApiService.getBffDebugToolApi();

      const esQuery = `metricName:"ZONE_SUMMARY" AND metric.zoneSummary.zoneId:"${props.collarCommandId}"`;
      const { data: deviceMetrics } = await api.getDeviceMetrics(esQuery, undefined);
      const zoneSummary: ZoneSummary[] = deviceMetrics.items.map(({ data }) => data);

      const esTransitionQuery = `metricName:"TRANSITION_METRICS" AND metric.transitionMetrics.commandId:"${props.collarCommandId}"`;
      const { data: transitionData } = await api.getDeviceMetrics(esTransitionQuery, undefined);
      const transitionMetrics: TransitionMetrics[] = transitionData.items.map(({ data }) => data);

      return { zoneSummary, transitionMetrics };
    }
  ),
};

export default reportsEffects;
