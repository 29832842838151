import {
  ICreateTowerNoteRequestDTO,
  ICreateTowerRequestDTO,
  IDeviceContextDTO,
  IDeviceStatusDTO,
  IDeviceSettingsDTO,
  INetworkDeviceContextDTO,
  ILoraCoverageHeatMapCellDTO,
  ILoraCoverageSelectionDTO,
  ITowerDTO,
  ITowerNoteDTO,
  IUpdateDeviceContextRequestDTO,
  IUpdateDeviceStatusRequestDTO,
  IUpdateDeviceSettingsRequestDTO,
  IUpdateTowerNoteRequestDTO,
  IUpdateTowerRequestDTO,
  ITowerFarmMappingDTO,
  ICreateTowerFarmMappingDTO,
  IDeviceControlMethodEnum,
} from '@halter-corp/tower-service-client';

import { AxiosError } from 'axios';

import HttpApiService from './http-api.service';

const TowerService = {
  fetchAllTowers: async (): Promise<ITowerDTO[] | []> => {
    const api = await HttpApiService.getTowerTowerAPI();
    try {
      const { data } = await api.getAllTowers();
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  fetchAllTowersByRadiusAndLatLng: async (
    latitude: number,
    longitude: number,
    raduis: number
  ): Promise<ITowerDTO[] | []> => {
    const api = await HttpApiService.getTowerTowerAPI();

    try {
      const { data } = await api.getAllTowersByRadiusAndLatLng(latitude, longitude, raduis);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  saveTower: async (tower: ICreateTowerRequestDTO): Promise<ITowerDTO | null> => {
    const api = await HttpApiService.getTowerTowerAPI();
    try {
      const { data } = await api.createTower(tower);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  fetchTowersByFarmId: async (farmId: string): Promise<ITowerDTO[] | []> => {
    const api = await HttpApiService.getTowerTowerAPI();
    try {
      const { data } = await api.getAllTowersByFarmID(farmId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  fetchTowersByNetworkGroupId: async (networkGroupId: string): Promise<ITowerDTO[] | []> => {
    const api = await HttpApiService.getTowerTowerAPI();
    try {
      const { data } = await api.getAllTowersByNetworkGroupID(networkGroupId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  fetchTowerById: async (id: string): Promise<ITowerDTO | null> => {
    const api = await HttpApiService.getTowerTowerAPI();
    try {
      const { data } = await api.getTowerByID(id);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  updateTowerById: async (towerId: string, tower: IUpdateTowerRequestDTO): Promise<ITowerDTO | null> => {
    try {
      const api = await HttpApiService.getTowerTowerAPI();
      const { data } = await api.updateTowerByID(towerId, tower);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  deleteTowerById: async (towerId: string): Promise<boolean> => {
    try {
      const api = await HttpApiService.getTowerTowerAPI();
      const res = await api.deleteTowerByID(towerId);
      if (res.status === 200) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  },
  saveTowerNote: async (towerNote: ICreateTowerNoteRequestDTO): Promise<ITowerNoteDTO | null> => {
    const api = await HttpApiService.getTowerTowerAPI();
    try {
      const { data } = await api.createTowerNote(towerNote);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  fetchTowerNotesByTowerId: async (towerId: string): Promise<ITowerNoteDTO[] | []> => {
    const api = await HttpApiService.getTowerTowerAPI();
    try {
      const { data } = await api.getAllTowerNotesByTowerID(towerId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  updateTowerNoteByTowerIdAndCreatedAt: async (
    towerId: string,
    createdAt: number,
    updateNoteDto: IUpdateTowerNoteRequestDTO
  ): Promise<ITowerNoteDTO | null> => {
    const api = await HttpApiService.getTowerTowerAPI();
    try {
      const { data } = await api.updateTowerNoteByTowerIDAndCreatedAt(towerId, createdAt, updateNoteDto);
      return data;
    } catch (err) {
      return null;
    }
  },
  deleteTowerNoteByTowerIdAndCreatedAt: async (towerId: string, createdAt: number): Promise<boolean> => {
    const api = await HttpApiService.getTowerTowerAPI();
    try {
      const res = await api.deleteTowerNoteByTowerIDAndCreatedAt(towerId, createdAt);
      if (res.status === 200) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  },
  fetchAllDeviceContextByFarmId: async (farmId: string) => {
    const api = await HttpApiService.getTowerDeviceContextAPI();
    try {
      const { data } = await api.getAllDeviceContextsByFarmID(farmId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  fetchAllDeviceContextByTowerId: async (towerId: string) => {
    const api = await HttpApiService.getTowerDeviceContextAPI();
    try {
      const { data } = await api.getAllDeviceContextsByTowerID(towerId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  fetchAllDeviceContextByShardId: async (shardId: string) => {
    const api = await HttpApiService.getTowerDeviceContextAPI();
    try {
      const { data } = await api.getAllDeviceContextsByShardID(shardId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  fetchAllDeviceContextById: async (id: string): Promise<IDeviceContextDTO[] | []> => {
    const api = await HttpApiService.getTowerDeviceContextAPI();
    try {
      const { data } = await api.getAllDeviceContextsByID(id);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  updateDeviceContextByIdAndFarmId: async (
    id: string,
    farmId: string,
    updatedDeviceContextDto: IUpdateDeviceContextRequestDTO
  ): Promise<IDeviceContextDTO | null> => {
    const api = await HttpApiService.getTowerDeviceContextAPI();
    try {
      const { data } = await api.updateDeviceContextByIDAndFarmID(id, farmId, updatedDeviceContextDto);
      return data;
    } catch (err) {
      return null;
    }
  },
  fetchDeviceStatusById: async (id: string): Promise<IDeviceStatusDTO | null> => {
    const api = await HttpApiService.getTowerDeviceStatusAPI();
    try {
      const { data } = await api.getDeviceStatusByID(id);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  updateDeviceStatusById: async (
    id: string,
    updatedDeviceStatusDto: IUpdateDeviceStatusRequestDTO
  ): Promise<IDeviceStatusDTO | null> => {
    const api = await HttpApiService.getTowerDeviceStatusAPI();
    try {
      const { data } = await api.updateDeviceStatusByID(id, updatedDeviceStatusDto);
      return data;
    } catch (err) {
      return null;
    }
  },

  fetchDeviceSettingsById: async (id: string): Promise<IDeviceSettingsDTO | null> => {
    const api = await HttpApiService.getTowerDeviceSettingsAPI();
    try {
      const { data } = await api.getDeviceSettingsByID(id);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  updateDeviceSettingsById: async (
    id: string,
    updatedDeviceStatusDto: IUpdateDeviceSettingsRequestDTO
  ): Promise<IDeviceSettingsDTO | null> => {
    const api = await HttpApiService.getTowerDeviceSettingsAPI();
    try {
      const { data } = await api.updateDeviceSettingsByID(id, updatedDeviceStatusDto);
      return data;
    } catch (err) {
      return null;
    }
  },
  fetchLoraCoverageHeatMapById: async (
    id: string,
    resolution: number = 12
  ): Promise<ILoraCoverageHeatMapCellDTO[] | null> => {
    const api = await HttpApiService.getLoraCoverageAPI();
    try {
      const loraCoverageHeatMapCellList: ILoraCoverageHeatMapCellDTO[] = [];
      let cursor: number | undefined;
      do {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await api.getLoraCoverageHeatMapByGatewayID(id, resolution, cursor);
        loraCoverageHeatMapCellList.push(...data.heatMapCells);
        cursor = data.cursor;
      } while (cursor != null);
      return loraCoverageHeatMapCellList;
    } catch (err) {
      return null;
    }
  },
  updateKeepAliveUntilByGatewayId: async (
    gatewayId: string,
    keepAliveUntil: number,
    method: IDeviceControlMethodEnum
  ): Promise<ILoraCoverageSelectionDTO | null> => {
    const api = await HttpApiService.getLoraCoverageAPI();
    try {
      const { data } = await api.updateKeepAliveUntilByGatewayID(gatewayId, keepAliveUntil, method);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  updateSuspendUntilByGatewayId: async (
    gatewayId: string,
    suspendUntil: number,
    method: IDeviceControlMethodEnum
  ): Promise<ILoraCoverageSelectionDTO | null> => {
    const api = await HttpApiService.getLoraCoverageAPI();
    try {
      const { data } = await api.updateSuspendUntilByGatewayID(gatewayId, suspendUntil, method);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  fetchSelectionByGatewayId: async (gatewayId: string): Promise<ILoraCoverageSelectionDTO | null> => {
    const api = await HttpApiService.getLoraCoverageAPI();
    try {
      const { data } = await api.getLoraCoverageSelectionByGatewayID(gatewayId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  fetchAllNetworkDeviceContextsByTowerId: async (
    towerId: string
  ): Promise<INetworkDeviceContextDTO[] | []> => {
    const api = await HttpApiService.getNetworkDeviceContextApi();

    try {
      const { data } = await api.getAllNetworkDeviceContextsByTowerID(towerId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
  saveTowerFarmMapping: async (
    towerFarmMapping: ICreateTowerFarmMappingDTO
  ): Promise<ITowerFarmMappingDTO | null> => {
    const api = await HttpApiService.getTowerTowerAPI();
    try {
      const { data } = await api.createTowerFarmMapping(towerFarmMapping);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null) {
        return null;
      }
      throw err;
    }
  },
  deleteTowerFarmMappingByFarmIDAndTowerID: async (farmId: string, towerId: string): Promise<boolean> => {
    const api = await HttpApiService.getTowerTowerAPI();
    try {
      const res = await api.deleteTowerFarmMappingByFarmIDAndTowerID(farmId, towerId);
      if (res.status === 200) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  },
};

export default TowerService;
