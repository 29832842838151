import { createAsyncThunk } from '@reduxjs/toolkit';

import { FeaturePropertyTypeEnum } from '@halter-corp/topography-service-client';

import HttpApiService from 'services/http-api.service';

type FetchPaddockRequest = {
  farmId: string;
};

const topographyEffects = {
  fetchPaddocks: createAsyncThunk('topography/paddock/fetch', async (props: FetchPaddockRequest) => {
    const api = await HttpApiService.getFeatureApi(props.farmId);
    const { data } = await api.findAll(undefined, FeaturePropertyTypeEnum.Paddock);
    return data;
  }),
};

export default topographyEffects;
