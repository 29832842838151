import { createSlice } from '@reduxjs/toolkit';

import { reset } from 'store/actions';
import { IInfraProductContextDTO, ITowerDTO } from '@halter-corp/infrastructure-service-client';
import infrastructureEffects from 'store/effects/infrastructure.effects';

type InfrastructureState = {
  towers: ITowerDTO[];
  latLngTowers: ITowerDTO[];

  infraProductContexts: IInfraProductContextDTO[];
};

const initialState: InfrastructureState = {
  towers: [],
  latLngTowers: [],

  infraProductContexts: [],
};

const infrastructureSlice = createSlice({
  initialState,
  name: 'infrastructure',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(infrastructureEffects.fetchTowers.fulfilled, (state, action) => {
        state.towers = action.payload;
      })
      .addCase(infrastructureEffects.fetchTowersByLatLngAndRadius.fulfilled, (state, action) => {
        state.latLngTowers = action.payload;
      })
      .addCase(infrastructureEffects.fetchTowersByFarmId.fulfilled, (state, action) => {
        state.towers = action.payload;
      })

      .addCase(infrastructureEffects.fetchAllInfraProductContextsByTowerIdList.fulfilled, (state, action) => {
        state.infraProductContexts = action.payload;
      })
      .addCase(infrastructureEffects.updateInfraProductContextById.fulfilled, (state, action) => {
        const updatedInfraProductContext = action.payload;
        if (updatedInfraProductContext == null) return;
        const { id } = updatedInfraProductContext;
        const index = state.infraProductContexts.findIndex((infraProduct) => infraProduct.id === id);
        state.infraProductContexts[index] = updatedInfraProductContext;
      })
      .addCase(
        infrastructureEffects.updateAllInfraProductContextTowerIdByGroupId.fulfilled,
        (state, action) => {
          const updatedInfraProductContexts = action.payload;
          if (updatedInfraProductContexts.length === 0) return;
          updatedInfraProductContexts.forEach((infraProductContext) => {
            const { id } = infraProductContext;
            const index = state.infraProductContexts.findIndex((infraProduct) => infraProduct.id === id);
            state.infraProductContexts[index] = infraProductContext;
          });
        }
      ),
});

export default infrastructureSlice;
