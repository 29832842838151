import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchResultItemDTO } from '@halter-corp/bff-debug-tool-service-client';
import { ICommandDTO } from '@halter-corp/cowtroller-service-client';

type MapPageItemsState = {
  items: ISearchResultItemDTO[];
  data: { cowg: any[]; positions: any[]; commands: ICommandDTO[] };
};

const initialState: MapPageItemsState = {
  items: [],
  data: { cowg: [], positions: [], commands: [] },
};

const mapPageItemsSlice = createSlice({
  initialState,
  name: 'mapPageItems',
  reducers: {
    clear: () => initialState,

    setItemList: (state, action: PayloadAction<{ items: ISearchResultItemDTO[] }>) => {
      state.items = action.payload.items;
    },

    setData: (state, action: PayloadAction<{ cowg: any[]; positions: any[]; commands: ICommandDTO[] }>) => {
      state.data = action.payload;
    },
  },
});

export default mapPageItemsSlice;
