import { createSlice } from '@reduxjs/toolkit';

import deviceEffects from '../effects/device.effects';
import devicesOverviewAdapter from '../adapters/devices-overview.adapter';
import { reset } from '../actions';

const initialState = {
  loading: false,
  entityState: devicesOverviewAdapter.getInitialState(),
};

const devicesOverviewSlice = createSlice({
  initialState,
  name: 'devicesOverview',
  reducers: {
    clear: () => initialState,
    setLoading: (state) => ({ ...state, loading: true }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(deviceEffects.fetch.fulfilled, (state, action) => {
        const entities = action.payload;

        devicesOverviewAdapter.removeAll(state.entityState);
        devicesOverviewAdapter.addMany(state.entityState, entities);

        state.loading = false;
      })
      .addCase(deviceEffects.fetch.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deviceEffects.fetch.pending, (state) => {
        state.loading = true;
      })
      .addCase(deviceEffects.fetchBySerialNumber.fulfilled, (state, action) => {
        const entity = action.payload;

        devicesOverviewAdapter.removeOne(state.entityState, entity.serialNumber);
        devicesOverviewAdapter.addOne(state.entityState, entity);
      }),
});

export default devicesOverviewSlice;
