import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

import { AppState } from 'store';
import {
  profileAdapter,
  appliedModifiersAdapter,
  modifierAdapter,
  farmerTrainingGuideAdapter,
  farmerTrainingGuideOptionsAdapter,
} from 'store/adapters/training.adapters';
import { Profile, Modifier, CowWithAppliedModifiers } from 'data/training';
import appliedProfileAdapter from 'store/adapters/applied-profile.adapter';
import { selectCattle } from './cattle.selectors';

export const selectProfilesSlice = (state: AppState) => state.training.profiles;
export const selectModifiersSlice = (state: AppState) => state.training.modifiers;
export const selectAppliedProfilesSlice = (state: AppState) => state.training.appliedProfile;
export const selectAppliedModifiersSlice = (state: AppState) => state.training.appliedModifiers;
export const selectFarmerTrainingGuideSlice = (state: AppState) => state.training.farmerTrainingGuides;
export const selectFarmerTrainingGuideOptionsSlice = (state: AppState) =>
  state.training.farmerTrainingGuideOptions;
export const isLoadingSlice = (state: AppState) => state.training.loading;

export const { selectAll: selectAllProfiles, selectById: selectProfileEntityById } =
  profileAdapter.getSelectors(selectProfilesSlice);

export const { selectAll: selectAllModifiers, selectById: selectModifierEntityById } =
  modifierAdapter.getSelectors(selectModifiersSlice);

export const { selectAll: selectAllAppliedProfiles } =
  appliedProfileAdapter.getSelectors(selectAppliedProfilesSlice);

export const { selectAll: selectAllAppliedModifiers } =
  appliedModifiersAdapter.getSelectors(selectAppliedModifiersSlice);

export const selectProfilesByFarmId = (farmId: string) =>
  createSelector(selectAllProfiles, (profiles): Profile[] =>
    profiles.filter((profile) => profile.farmId == null || profile.farmId === farmId)
  );

export const selectModifiersByFarmId = (farmId: string) =>
  createSelector(selectAllModifiers, (modifiers): Modifier[] =>
    modifiers.filter((modifier) => modifier.farmId == null || modifier.farmId === farmId)
  );

export const { selectAll: selectAllFarmerTrainingGuides } = farmerTrainingGuideAdapter.getSelectors(
  selectFarmerTrainingGuideSlice
);

export const { selectAll: selectAllFarmerTrainingGuideOptions } =
  farmerTrainingGuideOptionsAdapter.getSelectors(selectFarmerTrainingGuideOptionsSlice);

export const selectAppliedTrainings = createSelector(
  selectCattle,
  selectAllAppliedModifiers,
  (cows, appliedModifiers): CowWithAppliedModifiers[] => {
    const appliedModifiersByCow = keyBy(appliedModifiers, ({ cattleId }) => cattleId);

    return cows.reduce<CowWithAppliedModifiers[]>((cowsWithAppliedModifiers, cow) => {
      const cowAppliedTraining = appliedModifiersByCow[cow.id];
      if (cowAppliedTraining == null) return cowsWithAppliedModifiers;

      return [
        ...cowsWithAppliedModifiers,
        {
          cattleId: cow.id,
          cattleName: cow.name,
          mobName: cow.mob?.name,
          serialNumber: cow.serialNumber,
          cowAppliedModifiers: cowAppliedTraining,
        },
      ];
    }, []);
  }
);

export const isLoading = createSelector(isLoadingSlice, (state) => state);
