import React, { FunctionComponent } from 'react';
import { Box, MenuItem, Select, Switch, TextField, Typography, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { isNumber, isBoolean, isNaN } from 'lodash';

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

type SettingInputRowProps<T extends number | boolean | string | undefined> = {
  value: T;
  onChange: (value: T) => void;
  defaultValue: T;
  options?: string[];
};

const useStyles = makeStyles({
  input: {
    textAlign: 'right',
  },
});

const SettingInput: FunctionComponent<SettingInputRowProps<number | boolean | string | undefined>> = ({
  value,
  onChange,
  defaultValue,
  options,
}) => {
  const classes = useStyles();

  // Render
  //-----------------------------------------------------

  return (
    <InputWrapper>
      {isNumber(value) && (
        <TextField
          type="number"
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value, 10))}
          style={{
            ...(value !== defaultValue && !isNaN(value)
              ? { fontWeight: 600, opacity: 0.85 }
              : { fontWeight: 300 }),
            backgroundColor: '#fff',
          }}
          onWheel={(event) => (event.target as HTMLInputElement)?.blur()}
          onBlur={() => onChange(isNaN(value) ? defaultValue : value)}
          placeholder={(defaultValue ?? {}).toString()}
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.input,
            },
          }}
        />
      )}
      {isBoolean(value) ? (
        <Box textAlign="right">
          <Switch checked={value} onClick={() => onChange(!value)} />
        </Box>
      ) : null}
      {options && (
        <Select
          displayEmpty
          value={value || ''}
          onChange={(e) => onChange(e.target.value as number)}
          variant="outlined"
        >
          {[
            options.map((option: string | number) => (
              <MenuItem style={{ height: 40 }} key={option ?? 'key'} value={option}>
                {option}
              </MenuItem>
            )),
          ]}
        </Select>
      )}
      {defaultValue ? (
        <Box mt={0.25} textAlign="right">
          <Typography color="textSecondary" variant="caption">
            Default: <strong>{defaultValue.toString()}</strong>
          </Typography>
        </Box>
      ) : null}
    </InputWrapper>
  );
};

export default SettingInput;
