import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import TowersOverviewContainer from './containers/towers-overview.container';

const TowersModule: FunctionComponent = () => (
  <Switch>
    <Route exact path="/towers">
      <TowersOverviewContainer />
    </Route>
  </Switch>
);

export default TowersModule;
