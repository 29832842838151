import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ITowerNoteDTO, IUpdateTowerNoteRequestDTO } from '@halter-corp/tower-service-client';
import moment from 'moment';
import { Box, IconButton, ListItem, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/EditSharp';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from 'application/modules/towers/components/action-dialogs/DeleteDialog';
import towerEffects from 'store/effects/tower.effects';
import { Tower } from 'store/selectors/tower.selectors';
import NoteFormDialog from './dialogs/note-form.dialog';

const useStyles = makeStyles(() => ({
  card: {
    transition: 'background-color 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: '#efefef',
    },
  },
}));

type TowerNoteProps = {
  note: ITowerNoteDTO;
  tower: Tower;
};

const TowerNote: FunctionComponent<TowerNoteProps> = ({ note, tower }) => {
  const [extended, setExtended] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [dialog, setDialog] = useState<'update' | 'delete' | null>(null);

  const handleNoteUpdate = (updateNoteDto: IUpdateTowerNoteRequestDTO) => {
    dispatch(
      towerEffects.updateNoteByTowerId({
        towerId: tower.id,
        createdAt: note.createdAt,
        updateNoteDto,
      })
    );
    setDialog(null);
  };

  const handleNoteDelete = () => {
    dispatch(towerEffects.deleteNoteByTowerId({ towerId: tower.id, createdAt: note.createdAt }));
    setDialog(null);
  };

  const handleDialogOpen = (e: React.MouseEvent, dialogType: 'update' | 'delete') => {
    e.stopPropagation();
    setDialog(dialogType);
  };

  const updatedAt = moment.utc(new Date(note.updatedAt * 1000)).fromNow();

  return (
    <>
      <ListItem
        divider
        className={classes.card}
        style={{
          cursor: 'pointer',
          padding: 4,
          paddingBottom: !extended ? 8 : 4,
          flexDirection: 'column',
          gap: 12,
        }}
        onClick={() => setExtended(!extended)}
      >
        <Box alignSelf="flex-start">
          {extended || note.note.length < 15 ? note.note : `${note.note.slice(0, 15)}...`}
          {note.caution && <Warning htmlColor="orange" style={{ position: 'absolute', right: 4 }} />}
        </Box>

        {extended && (
          <Box alignSelf="stretch" display="flex" justifyContent="space-between" alignItems="center">
            <Typography style={{ marginRight: 'auto', fontSize: 11 }}>
              Last Updated: <time style={{ fontWeight: 500 }}>{updatedAt}</time>
            </Typography>

            <IconButton
              onClick={(e) => handleDialogOpen(e, 'update')}
              color="primary"
              style={{ padding: 0 }}
              size="small"
            >
              <Tooltip title="Edit Note" arrow>
                <EditIcon />
              </Tooltip>
            </IconButton>

            <IconButton
              onClick={(e) => handleDialogOpen(e, 'delete')}
              style={{ marginLeft: '3px', color: '#e91e63', padding: 0 }}
              size="small"
            >
              <Tooltip title="Delete Note" arrow>
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </Box>
        )}
      </ListItem>

      <NoteFormDialog
        initialNote={note}
        open={dialog === 'update'}
        onClose={() => setDialog(null)}
        onSave={handleNoteUpdate}
        tower={tower}
      />

      <DeleteDialog
        open={dialog === 'delete'}
        onClose={() => setDialog(null)}
        onDelete={handleNoteDelete}
        itemName="Note"
      />
    </>
  );
};

export default TowerNote;
