import React, { FunctionComponent } from 'react';
import Button from 'application/components/button';
import { MapMode } from '../types';

type MapToggleButtonProps = {
  mapMode: MapMode;
  mapVisible: boolean;
  onChangeMapVisible: (visible: boolean) => void;
};

const MapToggleButton: FunctionComponent<MapToggleButtonProps> = ({
  mapMode,
  mapVisible,
  onChangeMapVisible,
}) => (
  <Button
    variant="contained"
    size="small"
    backgroundColour="#3f51b5"
    text={mapVisible ? `Hide ${mapMode}` : `Show ${mapMode}`}
    onClick={() => onChangeMapVisible(!mapVisible)}
  />
);

export default MapToggleButton;
