import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { CommandTypeEnum } from '@halter-corp/cowtroller-service-client';
import {
  ReportDataSummary,
  TransitionReportSummary,
  ZoneSummary,
  ZoneReportSummary,
  TransitionMetrics,
  ReportWithSummary,
} from 'data/reports';

export const isLoadingSlice = (state: AppState) => state.reports.loading;
export const allReportsSlice = (state: AppState) => state.reports.allReports;
export const reportSlice = (state: AppState) => state.reports.report;
export const reportDataSlice = (state: AppState) => state.reports.reportData;

const sum = (l: number[]) => l.reduce((a, c) => a + c, 0);
const avg = (l: number[]) => sum(l) / l.length || 0;
const max = (l: number[]) => {
  const maxValue = Math.max(...l);
  return maxValue > 0 ? maxValue : 0;
};

export const isLoading = createSelector(isLoadingSlice, (state) => state);

const getTransitionReport = (metrics: TransitionMetrics[]): TransitionReportSummary => {
  const startTimeUtcS = metrics.map(
    ({ metric: { transitionMetrics } }) => transitionMetrics?.startTimeUtcS ?? 0
  );
  const endTimeUtcS = metrics.map(({ metric: { transitionMetrics } }) => transitionMetrics?.endTimeUtcS ?? 0);

  const shockCount = metrics.map(
    ({
      metric: {
        transitionMetrics: { shock },
      },
    }) => shock.count ?? 0
  );
  const cowsShockedCount = new Set(
    metrics
      .filter(
        ({
          metric: {
            transitionMetrics: { shock },
          },
        }) => shock?.count > 0
      )
      .map(({ serialNumber }) => serialNumber)
  ).size;

  const shockLockoutCount = metrics.map(
    ({
      metric: {
        transitionMetrics: { shock },
      },
    }) => shock.lockoutCount ?? 0
  );
  const cowsShockLockoutCount = new Set(
    metrics
      .filter(
        ({
          metric: {
            transitionMetrics: { shock },
          },
        }) => shock?.lockoutCount > 0
      )
      .map(({ serialNumber }) => serialNumber)
  ).size;

  const shockFailedCount = metrics.map(
    ({
      metric: {
        transitionMetrics: { shock },
      },
    }) => shock.failedCount ?? 0
  );

  const piezoActivations = metrics.map(
    ({
      metric: {
        transitionMetrics: { piezo },
      },
    }) => piezo?.activations ?? 0
  );
  const piezoIntensityAvg = metrics.map(
    ({
      metric: {
        transitionMetrics: { piezo },
      },
    }) => piezo?.intensityAverage ?? 0
  );

  const odba = metrics.map(
    ({
      metric: {
        transitionMetrics: { odbaAverage },
      },
    }) => odbaAverage ?? 0
  );
  const odbv = metrics.map(
    ({
      metric: {
        transitionMetrics: { odbvAverage },
      },
    }) => odbvAverage ?? 0
  );

  const headingError = metrics.map(
    ({
      metric: {
        transitionMetrics: { headingErrorRms },
      },
    }) => headingErrorRms ?? 0
  );

  const uniqueSerialNumbers = new Set(metrics.map(({ serialNumber }) => serialNumber));
  const uniqueMobIds = new Array(...new Set(metrics.map(({ context: { mobId } }) => mobId)));

  return {
    shock: {
      cowsShockedCount,
      cowsShockLockoutCount,
      average: avg(shockCount),
      max: max(shockCount),
      total: sum(shockCount),
      totalLockout: sum(shockLockoutCount),
      totalFailed: sum(shockFailedCount),
    },
    piezo: {
      averageActivations: avg(piezoActivations),
      maxActivations: max(piezoActivations),
      averageIntensity: avg(piezoIntensityAvg),
    },
    odba: {
      averageOdba: avg(odba),
      maxOdba: max(odba),
      averageOdbv: avg(odbv),
    },
    heading: {
      averageHeadingError: avg(headingError),
      maxHeadingError: max(headingError),
    },
    startTimeUtcS: Math.min(...startTimeUtcS),
    endTimeUtcS: Math.max(...endTimeUtcS),
    cowCount: uniqueSerialNumbers.size,
    mobIds: uniqueMobIds,
  };
};

const getZoneReport = (zoneSummaryData: ZoneSummary[]): ZoneReportSummary => {
  const startTimeUtcS = zoneSummaryData.map(({ metric: { zoneSummary } }) => zoneSummary?.startTimeUtcS ?? 0);
  const endTimeUtcS = zoneSummaryData.map(({ metric: { zoneSummary } }) => zoneSummary?.endTimeUtcS ?? 0);

  const outOfZoneCount = zoneSummaryData.map(
    ({
      metric: {
        zoneSummary: { outOfZone },
      },
    }) => outOfZone?.count ?? 0
  );
  const outOfZoneTime = zoneSummaryData.map(
    ({
      metric: {
        zoneSummary: { outOfZone },
      },
    }) => outOfZone?.timeSpentS ?? 0
  );

  const piezoActivations = zoneSummaryData.map(
    ({
      metric: {
        zoneSummary: { piezo },
      },
    }) => piezo?.activations ?? 0
  );
  const piezoIntensityAvg = zoneSummaryData.map(
    ({
      metric: {
        zoneSummary: { piezo },
      },
    }) => piezo?.intensityAverage ?? 0
  );

  const confirmed = zoneSummaryData.map(
    ({
      metric: {
        zoneSummary: { shock },
      },
    }) => shock?.count ?? 0
  );
  const cowsShockedCount = new Set(
    zoneSummaryData
      .filter(
        ({
          metric: {
            zoneSummary: { shock },
          },
        }) => shock?.count > 0
      )
      .map(({ serialNumber }) => serialNumber)
  ).size;

  const lockout = zoneSummaryData.map(
    ({
      metric: {
        zoneSummary: { shock },
      },
    }) => shock?.lockoutCount ?? 0
  );
  const cowsShockLockoutCount = new Set(
    zoneSummaryData
      .filter(
        ({
          metric: {
            zoneSummary: { shock },
          },
        }) => shock?.lockoutCount > 0
      )
      .map(({ serialNumber }) => serialNumber)
  ).size;

  const failed = zoneSummaryData.map(
    ({
      metric: {
        zoneSummary: { shock },
      },
    }) => shock?.failedCount ?? 0
  );

  const uniqueSerialNumbers = new Set(zoneSummaryData.map(({ serialNumber }) => serialNumber));
  const uniqueMobIds = new Array(...new Set(zoneSummaryData.map(({ context: { mobId } }) => mobId)));

  return {
    zone: {
      averageLeftZoneCount: avg(outOfZoneCount),
      maxLeftZoneCount: max(outOfZoneCount),
      averageTimeOutOfZone: avg(outOfZoneTime),
      maxTimeOutOfZone: max(outOfZoneTime),
    },
    piezo: {
      averageActivations: avg(piezoActivations),
      maxActivations: max(piezoActivations),
      averageIntensity: avg(piezoIntensityAvg),
    },
    shock: {
      averageConfirmed: avg(confirmed),
      maxConfirmed: max(confirmed),
      totalConfirmed: sum(confirmed),
      cowsShockedCount,
      averageLockoutCount: avg(lockout),
      maxLockoutCount: max(lockout),
      totalLockoutCount: sum(lockout),
      cowsShockLockoutCount,
      averageFailed: avg(failed),
      maxFailed: max(failed),
      totalFailed: sum(failed),
    },
    startTimeUtcS: Math.max(...startTimeUtcS),
    endTimeUtcS: Math.max(...endTimeUtcS),
    cowCount: uniqueSerialNumbers.size,
    mobIds: uniqueMobIds,
  };
};

export const allReports = createSelector(allReportsSlice, (reports): ReportWithSummary[] =>
  reports.map((report) => {
    if (report.reportData) {
      if (report.type === CommandTypeEnum.EXIT_ZONE) {
        const {
          cowCount,
          mobIds,
          shock: { max: maxShock, cowsShockedCount, cowsShockLockoutCount },
          startTimeUtcS,
          endTimeUtcS,
        } = getTransitionReport(report.reportData.transitionMetrics);
        return {
          ...report,
          cowCount,
          mobIds,
          maxShock,
          cowsShockedCount,
          cowsShockLockoutCount,
          startTimeUtcS,
          endTimeUtcS,
        };
      }

      if (report.type === CommandTypeEnum.SET_ZONE) {
        const {
          cowCount,
          mobIds,
          shock: { maxConfirmed: maxShock, cowsShockedCount, cowsShockLockoutCount },
          startTimeUtcS,
          endTimeUtcS,
        } = getZoneReport(report.reportData.zoneSummary);
        return {
          ...report,
          cowCount,
          mobIds,
          maxShock,
          cowsShockedCount,
          cowsShockLockoutCount,
          startTimeUtcS,
          endTimeUtcS,
        };
      }
    }
    return report;
  })
);

export const report = createSelector(reportSlice, (state) => state);

export const reportData = createSelector(reportDataSlice, (reportDataSummary): ReportDataSummary | null => {
  if (reportDataSummary == null) return null;

  const { transitionMetrics, zoneSummary } = reportDataSummary;

  return {
    transition: getTransitionReport(transitionMetrics),
    zone: getZoneReport(zoneSummary),
  };
});
