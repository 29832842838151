import { AppState } from 'store/store';
import { createSelector } from '@reduxjs/toolkit';
import {
  IDeviceContextDTO,
  IDeviceSettingsDTO,
  IDeviceStatusDTO,
  ILoraCoverageHeatMapCellDTO,
  ILoraCoverageSelectionDTO,
  ITowerDTO,
  ITowerNoteDTO,
} from '@halter-corp/tower-service-client';
import { ProductTypeEnum } from 'application/utils/infrastructureUtils';

export enum DeviceStatusEnum {
  ON_OUTAGE = 'ON_OUTAGE',
  FORCE_OFF = 'FORCE_ON',
  FORCE_ROUND_ROBIN = 'FORCE_ROUND_ROBIN',
  SUSPENDED = 'SUSPENDED',
  normal = 'NORMAL',
}
export interface DeviceWithMetadata extends IDeviceContextDTO {
  status?: IDeviceStatusDTO;
  settings?: IDeviceSettingsDTO;
  loraCoverageSelection?: ILoraCoverageSelectionDTO;
}

export interface Tower extends Omit<ITowerDTO, 'mainsPowered' | 'starlink' | 'router'> {
  mainsPowered?: boolean;
  starlink?: boolean;
  router?: boolean;
  groupIndex?: number;
  routerTowerId?: string;
  notes: ITowerNoteDTO[];
}

export interface TowerWithDevices extends ITowerDTO {
  devices: DeviceWithMetadata[];
}

export interface TowerWithDevicesAndNotes extends Tower {
  devices: DeviceWithMetadata[];
}

export const selectTower = createSelector(
  (state: AppState) => state.tower,
  (tower) => tower
);

export const selectDeviceContext = createSelector(selectTower, (tower) => tower.deviceContext);

export const selectNetworkDeviceContext = createSelector(selectTower, (tower) => tower.networkDeviceContext);

export const selectDeviceStatus = createSelector(selectTower, (tower) => tower.deviceStatus);

export const selectDeviceSettings = createSelector(selectTower, (tower) => tower.deviceSettings);

export const selectLoraCoverageSelections = createSelector(
  selectTower,
  (tower) => tower.loraCoverageSelections
);

export const selectTowers = createSelector(selectTower, (tower) => tower.towers);

export const selectLatLngTowers = createSelector(selectTower, (tower) => tower.latLngTowers);

export const selectNotes = createSelector(selectTower, (tower) => tower.notes);

export const selectLoraCoverageHeatMapCells = createSelector(
  selectTower,
  (tower) => tower.loraCoverageHeatMapCells
);

export const selectExternalTowers = createSelector(
  selectTowers,
  selectLatLngTowers,
  (towers, latLngTowers): ITowerDTO[] => {
    const towerIdSet = new Set(towers.map((tower) => tower.id));
    return latLngTowers.filter((tower) => !towerIdSet.has(tower.id));
  }
);

export const selectLoraCoverageHeatMapCellsMap = createSelector(
  selectLoraCoverageHeatMapCells,
  (loraCoverageHeatMapCells): Map<string, ILoraCoverageHeatMapCellDTO[]> => {
    const loraCoverageHeatMapCellsMap = new Map<string, ILoraCoverageHeatMapCellDTO[]>();

    loraCoverageHeatMapCells.forEach((cell) => {
      const gatewayHeatMapCells = loraCoverageHeatMapCellsMap.get(cell.id) || [];
      gatewayHeatMapCells.push(cell);
      loraCoverageHeatMapCellsMap.set(cell.id, gatewayHeatMapCells);
    });

    return loraCoverageHeatMapCellsMap;
  }
);

export const selectGatewayIds = createSelector(selectDeviceContext, (devices) =>
  devices.filter((device) => device.type === ProductTypeEnum.GATEWAY.toString()).map((gateway) => gateway.id)
);

// NOTE: Filtering for the tower overviews table as we are migrating tower node device to network device, so we do not want to have them in device contexts.
export const selectFilteredDeviceContext = createSelector(selectDeviceContext, (devices) =>
  devices.filter(
    (device) => device.type === ProductTypeEnum.GATEWAY || device.type === ProductTypeEnum.SOLAR_SWITCH
  )
);

export const selectDevicesWithMetadata = createSelector(
  selectFilteredDeviceContext,
  selectNetworkDeviceContext,
  selectDeviceStatus,
  selectDeviceSettings,
  selectLoraCoverageSelections,
  (devices, networkDevices, deviceStatus, deviceSettings, loraCoverageSelections): DeviceWithMetadata[] => {
    const deviceStatusMap: Map<string, IDeviceStatusDTO> = new Map(
      deviceStatus.map((status) => [status.id, status])
    );
    const deviceSettingsMap: Map<string, IDeviceSettingsDTO> = new Map(
      deviceSettings.map((settings) => [settings.id, settings])
    );
    const loraCoverageSelectionMap: Map<string, ILoraCoverageSelectionDTO> = new Map(
      loraCoverageSelections.map((selection) => [selection.id, selection])
    );

    const totalDevices = [...devices];
    totalDevices.push(...networkDevices.map((networkDevice) => ({ ...networkDevice, farmId: '' })));

    return totalDevices.map((device) => {
      const status = deviceStatusMap.get(device.id);
      const settings = deviceSettingsMap.get(device.id);
      const loraCoverageSelection = loraCoverageSelectionMap.get(device.id);
      return { ...device, status, settings, loraCoverageSelection };
    });
  }
);

export const selectGatewaysWithStatus = createSelector(selectDevicesWithMetadata, (devicesWithMetadata) =>
  devicesWithMetadata.filter((device) => device.type === ProductTypeEnum.GATEWAY.toString())
);

export const selectShadowDevicesWithStatus = createSelector(
  selectTowers,
  selectDevicesWithMetadata,
  (towers, devices) => {
    const towerIdSet = new Set(towers.map((tower) => tower.id));
    const shadowDevices = devices.filter(
      (device) => device.towerId != null && towerIdSet.has(device.towerId) === false
    );

    return shadowDevices;
  }
);

export const selectUnassignedDevicesWithStatus = createSelector(selectDevicesWithMetadata, (devices) => {
  const unassignedDevices = devices.filter((device) => device.towerId == null);
  return unassignedDevices;
});

export const selectTowersWithDevices = createSelector(
  selectTowers,
  selectDevicesWithMetadata,
  (towers, devices) => {
    const towerIdSet = new Set(towers.map((tower) => tower.id));
    const devicesToTowerMap = new Map();
    devices.forEach((device) => {
      if (device.towerId == null) return;
      if (towerIdSet.has(device.towerId) === false) return;

      const entry = devicesToTowerMap.get(device.towerId) ?? [];
      entry.push(device);
      devicesToTowerMap.set(device.towerId, entry);
    });

    const towersWithDevices: TowerWithDevices[] = towers.map((tower) => {
      const towerDevices = devicesToTowerMap.get(tower.id) ?? [];
      return { ...tower, devices: towerDevices };
    });

    return towersWithDevices;
  }
);

export const selectTowersWithNotes = createSelector(selectTowers, selectNotes, (towers, notes) => {
  const towerIdsToNotes = new Map();
  notes.forEach((note) => {
    const { towerId } = note;
    const entry = towerIdsToNotes.get(towerId) ?? [];
    entry.push(note);
    towerIdsToNotes.set(towerId, entry);
  });

  const towersWithNotes: Tower[] = towers.map((tower) => {
    const towerNotes = towerIdsToNotes.get(tower.id) ?? [];
    return { ...tower, notes: towerNotes };
  });
  return towersWithNotes;
});

export const selectTowersWithDevicesAndNotes = createSelector(
  selectTowersWithDevices,
  selectTowersWithNotes,
  (towersWithDevices, towersWithNotes): TowerWithDevicesAndNotes[] => {
    const towerWithNotesMap = new Map<string, ITowerNoteDTO[]>();
    towersWithNotes.forEach((tower) => {
      towerWithNotesMap.set(tower.id, tower.notes);
    });

    const towerWithDevicesAndNotes: TowerWithDevicesAndNotes[] = towersWithDevices.map((tower) => ({
      ...tower,
      notes: towerWithNotesMap.get(tower.id) || [],
    }));
    return towerWithDevicesAndNotes;
  }
);
