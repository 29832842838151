import React, { FunctionComponent, useMemo } from 'react';
import { GeoJSON } from 'react-leaflet';
import JSONTree from 'react-json-tree';
import ReactDOMServer from 'react-dom/server';
import { useLeafletZoom } from 'use-leaflet';

import { HistoryEventTypeEnum } from '@halter-corp/timeline-service-client';

import { AppState, useSelector } from 'store';
import { commandsForDateRangeAndSelectedCows } from 'store/selectors/map-page-items.selectors';

import { ICommandDTO } from '@halter-corp/cowtroller-service-client';
import { omit, omitBy } from 'lodash';
import { getColorForEventType, jsonTreeTheme } from '../../../utils';
import PaddockLabelMarker from '../markers/paddock-label.marker';

export type CommandsProps = {
  selectedCows: string[] | null;
  scrubbedDateRange: [Date, Date] | null;
};

const defaultSetZoneColor = getColorForEventType(HistoryEventTypeEnum.DeviceCommandEvent);

const buildCommandEventPopupContent = (command: ICommandDTO) => {
  const element = (
    <JSONTree
      data={{
        ...omitBy(command, (_, key) => key.includes('At') || key.includes('Until')),
        arguments: omit(command.arguments, 'datum', 'boundary', 'shape'),
      }}
      theme={jsonTreeTheme}
      shouldExpandNode={() => true}
      hideRoot
    />
  );
  return ReactDOMServer.renderToStaticMarkup(element);
};

const DeviceCommandsLayer: FunctionComponent<CommandsProps> = ({ selectedCows, scrubbedDateRange }) => {
  const commands = useSelector((state: AppState) =>
    commandsForDateRangeAndSelectedCows(state, {
      minimumDate: scrubbedDateRange?.[0] || null,
      maximumDate: scrubbedDateRange?.[1] || null,
      selectedCows,
    })
  );

  const zoom = useLeafletZoom();
  const showCattleEvents = useMemo(() => zoom > 20, [zoom]);

  const toPolygon = (polygon: any): any => {
    // We store 2 versions of coordinates of the map, to allow for transition of upgrading to new collar firmware
    const coordinates = polygon.coordinatesWithIncreasedLimit ?? polygon.coordinates;
    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates.map((c: any) => [c.longitude, c.latitude])],
      },
    };
  };

  const toLine = (line: any): any => ({
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: line.coordinates.map((c: any) => [c.longitude, c.latitude]),
    },
  });

  const toPoint = (point: any): any => ({
    type: 'Feature',
    geometry: { type: 'Point', coordinates: [point.longitude, point.latitude] },
  });

  return (
    <div style={{ zIndex: 0 }}>
      {commands.map((command) => {
        const commandArguments = command.arguments as any;
        return [
          <PaddockLabelMarker key={`${command.id}-paddock-label`} paddockId={command.paddockId} />,
          commandArguments.boundary && (
            <GeoJSON
              key={`${command.id}-boundary`}
              data={toPolygon(commandArguments.boundary)}
              style={{
                color: 'white',
                fill: false,
                weight: 1,
              }}
            />
          ),
          commandArguments.shape && (
            <GeoJSON
              key={`${command.id}-zone`}
              data={toPolygon(commandArguments.shape)}
              style={{
                fillOpacity: 0,
                color: '#0ea5e9',
                weight: 2.5,
              }}
              onEachFeature={(feature, layer) => {
                if (showCattleEvents) {
                  layer.unbindPopup();
                } else {
                  layer.bindPopup(buildCommandEventPopupContent(command));
                }
              }}
            />
          ),
          commandArguments.exitPoint && (
            <GeoJSON
              key={`${command.id}-exit-point`}
              data={toPoint(commandArguments.exitPoint)}
              style={{ color: defaultSetZoneColor }}
              onEachFeature={(feature, layer) => {
                if (showCattleEvents) {
                  layer.unbindPopup();
                } else {
                  layer.bindPopup(buildCommandEventPopupContent(command));
                }
              }}
            />
          ),
          commandArguments.exitPath && (
            <GeoJSON
              key={`${command.id}-exit-path`}
              data={toLine(commandArguments.exitPath)}
              style={{ color: 'orange', dashArray: '5, 5' }}
            />
          ),
        ];
      })}
    </div>
  );
};

export default DeviceCommandsLayer;
