import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'store';
import cattleAdapter from 'store/adapters/cattle.adapter';
import { WithMob, Cattle } from 'data/cattle';

export const selectCattleSlice = (state: AppState) => state.cattle.cattle;

export const {
  selectIds: selectCattleIds,
  selectEntities: selectCattleEntities,
  selectAll: selectAllCattle,
  selectById: selectCattleEntityById,
} = cattleAdapter.getSelectors(selectCattleSlice);

export const selectCattle = createSelector(selectAllCattle, (cattle): WithMob<Cattle>[] =>
  cattle.filter((cow) => cow.serialNumber != null)
);
