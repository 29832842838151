import React, { FunctionComponent, useCallback } from 'react';
import { sortBy } from 'lodash';

import { ApplyProfileOption, Profile } from 'data/training';

import SelectField from '../../components/select-field';

type ProfileSelectProps = {
  label: string;
  profiles?: Profile[];
  selected: ApplyProfileOption;
  onChange: (option: ApplyProfileOption) => void;
};

const ProfileSelect: FunctionComponent<ProfileSelectProps> = ({
  label,
  profiles = [],
  selected,
  onChange,
}) => {
  const displayNameExtractor = useCallback((option: ApplyProfileOption) => {
    if (option.type === 'keep-current') return 'Keep current';
    if (option.type === 'remove') return 'None';
    return option.profile.name;
  }, []);

  return (
    <SelectField<ApplyProfileOption, string>
      label={label}
      options={[
        { type: 'keep-current' },
        { type: 'remove' },
        ...sortBy(profiles, ({ name }) => name).map<ApplyProfileOption>((profile) => ({
          profile,
          type: 'apply',
        })),
      ]}
      value={JSON.stringify(selected)}
      valueExtractor={(option) => JSON.stringify(option)}
      displayNameExtractor={displayNameExtractor}
      onChange={(value) => onChange(JSON.parse(value))}
      noValue={JSON.stringify({ type: 'keep-current' })}
    />
  );
};

export default ProfileSelect;
