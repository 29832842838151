import { Accordion, AccordionSummary, Typography, AccordionDetails, Box } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useState } from 'react';
import { Tower } from 'store/selectors/tower.selectors';
import { orderBy } from 'lodash';
import Button from 'application/components/button';
import pluralize from 'pluralize';
import CreateNoteDialog from './dialogs/create-note.dialog';
import TowerNote from './tower-note';

interface TowerNoteDrawerProps {
  tower: Tower;
}
const TowerNotesPopupDrawer = ({ tower }: TowerNoteDrawerProps) => {
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const sortedTowerNotes = orderBy(tower.notes, 'createdAt', 'desc');

  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(false);
  };

  return (
    <Box>
      {tower.notes.length === 0 ? (
        <Button
          text="Add Note"
          style={{ width: '100%' }}
          onClick={() => {
            setOpenNoteDialog(true);
          }}
        />
      ) : (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography style={{ fontSize: 14, marginTop: 0, marginBottom: 0, marginRight: 3 }}>
              {pluralize('Existing note', tower.notes.length, true)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="block" width="100%">
              {sortedTowerNotes.map((note) => (
                <TowerNote key={`${note.towerId}-${note.createdAt}`} tower={tower} note={note} />
              ))}
              <Button
                text="Add Note"
                style={{ width: '100%' }}
                onClick={() => {
                  setOpenNoteDialog(true);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      <CreateNoteDialog tower={tower} open={openNoteDialog} onClose={handleCloseNoteDialog} />
    </Box>
  );
};

export default TowerNotesPopupDrawer;
