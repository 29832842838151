import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Source, Layer, HeatmapLayer } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import HeatMapService from 'services/heat-map.service';
import heatMapEffects from 'store/effects/heat-map.effects';
import { featureCollection, point } from 'turf';
import { Bounds, DataMapSearch } from '../panels/data-map.panel';
import { eventLocationBounds } from '../util/utils';

const grazingHeatmapLayer: HeatmapLayer = {
  id: 'grazing-heatmap',
  type: 'heatmap',
  paint: {
    "heatmap-weight": ["get", "probability"],
    "heatmap-intensity": 3,
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0, "rgba(255, 255, 0, 0)",
      0.6, "rgba(255, 255, 0, 1)"
    ],
    'heatmap-radius': ['interpolate', ['exponential', 2], ['zoom'], 0, 1, 22, 200],
    "heatmap-opacity": 0.6
  },
};

const restingHeatmapLayer: HeatmapLayer = {
  id: 'resting-heatmap',
  type: 'heatmap',
  paint: {
    "heatmap-weight": ["get", "probability"],
    "heatmap-intensity": 3,
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0, "rgba(87, 148, 242, 0)",
      0.6, "rgba(87, 148, 242, 1)"
    ],
    'heatmap-radius': ['interpolate', ['exponential', 2], ['zoom'], 0, 1, 22, 200],
    "heatmap-opacity": 0.6
  },
};

const ruminatingHeatmapLayer: HeatmapLayer = {
  id: 'ruminating-heatmap',
  type: 'heatmap',
  paint: {
    "heatmap-weight": ["get", "probability"],
    "heatmap-intensity": 3,
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0, "rgba(128, 0, 128, 0)",
      0.6, "rgba(128, 0, 128, 1)"
    ],
    'heatmap-radius': ['interpolate', ['exponential', 2], ['zoom'], 0, 1, 22, 200],
    "heatmap-opacity": 0.6
  },
};




type BehaviourHeatmapLayerProps = {
  search: DataMapSearch;
  currentTime?: Date;
  recenterMap: (bounds: Bounds) => void;
  refresh: boolean;
};

const BehaviourHeatmapLayer: FunctionComponent<BehaviourHeatmapLayerProps> = ({
  search,
  currentTime,
  recenterMap,
  refresh,
}) => {
  const dispatch = useDispatch();
  const behaviourPositions = HeatMapService.useFetchBehaviourPositions();


  const filteredBehaviourPositions =
    currentTime === undefined
      ? behaviourPositions
      : behaviourPositions.filter(
        (position) =>
          position.timestamp.getTime() <= currentTime.getTime() &&
          position.timestamp.getTime() >= (currentTime.getTime() - 180 * 1000)
      );



  const grazingFeatureCollection =
    filteredBehaviourPositions &&
    featureCollection(filteredBehaviourPositions.map(({ latitude, longitude, grazingProbability }) => point([longitude, latitude], { probability: grazingProbability })));

  const restingFeatureCollection =
    filteredBehaviourPositions &&
    featureCollection(filteredBehaviourPositions.map(({ latitude, longitude, restingProbability }) => point([longitude, latitude], { probability: restingProbability })));

  const ruminatingFeatureCollection =
    filteredBehaviourPositions &&
    featureCollection(filteredBehaviourPositions.map(({ latitude, longitude, ruminatingProbability }) => point([longitude, latitude], { probability: ruminatingProbability })));

  useEffect(() => {
    const newStartTime = search.startTime
      ? new Date(search.startTime.getTime() - 3 * 60 * 1000)
      : search.startTime;
    dispatch(heatMapEffects.fetchBehaviourPositions({
      farmIds: search.farmIds,
      mobIds: search.mobIds,
      cattleNames: search.cattleNames,
      startTime: newStartTime,
      endTime: search.endTime,
    }))
  }, [JSON.stringify(search), refresh]);

  // Recenter Map
  useMemo(() => {
    if (filteredBehaviourPositions.length > 0) {
      const bounds = eventLocationBounds(filteredBehaviourPositions);
      recenterMap(bounds);
    }
  }, [filteredBehaviourPositions]);

  return (
    <>
      <Source type="geojson" data={grazingFeatureCollection}>
        <Layer {...grazingHeatmapLayer} minzoom={12} />
      </Source>
      <Source type="geojson" data={restingFeatureCollection}>
        <Layer {...restingHeatmapLayer} minzoom={12} />
      </Source>
      <Source type="geojson" data={ruminatingFeatureCollection}>
        <Layer {...ruminatingHeatmapLayer} minzoom={12} />
      </Source>
    </>
  );
};
export default BehaviourHeatmapLayer;
