import React, { FunctionComponent } from 'react';
import { CowHeatEventSourceEnum, ICowHeatEventDTO } from '@halter-corp/cattle-breeding-service-client';
import DataTable from 'application/components/data-table';
import { dateToText } from 'application/modules/fleet-watch/utils';
import { CaddieColours } from 'application/theme/colours';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import BehaviourService from 'services/behaviour.service';
import MatingInsightsPopup from './mating-insights.pop-up';

type HeatTableProps = {
  heats: ICowHeatEventDTO[];
  farmId: string;
};
const HeatTable: FunctionComponent<HeatTableProps> = ({ heats, farmId }) => {
  const handleDeleteManualHeat = async (seasonId: string, heatEventId: string) => {
    await BehaviourService.deleteManualHeatEvent(seasonId, farmId, heatEventId);
  };

  return (
    <DataTable<ICowHeatEventDTO>
      data={heats}
      columns={[
        {
          name: '',
          render: (_, index) => Number(index + 1),
        },
        {
          name: 'Cow',
          keyExtractor: (heat) => {
            if (Number.isNaN(Number(heat.cattleName)) || heat.cattleName?.split('')[0] === '0') {
              return heat.cattleName;
            }
            return Number(heat.cattleName);
          },
          render: (heat) => heat.cattleName,
          allowCopyToClipboard: true,
        },
        {
          name: 'Date Created',
          keyExtractor: (heat) => heat.createdDate,
          render: (heat) => dateToText(heat.createdDate),
        },
        {
          name: 'System Detection Created Date',
          keyExtractor: (heat) => heat.systemDetectionCreatedDate,
          render: (heat) => dateToText(heat.systemDetectionCreatedDate),
        },
        {
          name: 'First Detected',
          keyExtractor: (heat) => heat.firstDetectedAt,
          render: (heat) => dateToText(heat.firstDetectedAt),
        },
        {
          name: 'Expires At',
          keyExtractor: (heat) => heat.expiresAt,
          render: (heat) => dateToText(heat.expiresAt),
        },
        {
          name: 'Activity Peak',
          keyExtractor: (heat) => heat?.matingWindow?.activityPeakDate,
          render: (heat) => dateToText(heat.matingWindow?.activityPeakDate),
        },
        {
          name: 'Status',
          keyExtractor: (heat) => heat.status,
          render: (heat) => heat.status,
        },
        {
          name: 'Mated',
          keyExtractor: (heat) => heat.abEvents.length,
          render: (heat) => (
            <p style={{ color: heat.abEvents.length > 0 ? 'green' : 'orange' }}>
              {heat.abEvents.length > 0 ? `Mated ${heat.abEvents[0].source ?? ''}` : 'Not Mated'}
            </p>
          ),
        },
        {
          name: 'AB Date',
          keyExtractor: (heat) => heat.abEvents[heat.abEvents.length - 1]?.completedAt,
          render: (heat) => dateToText(heat.abEvents[heat.abEvents.length - 1]?.completedAt),
        },
        {
          name: 'Source',
          keyExtractor: (heat) => heat.source,
          render: (heat) => heat.source,
        },
        {
          name: 'Grafana',
          render: (entry) => (
            <Link
              to={{
                pathname: `https://halter.grafana.net/d/I9QlPyi4z/behaviour-support-toolbelt?orgId=1&var-farm_id=${farmId}&var-cattle_id=${entry.cattleId}`,
              }}
              target="_blank"
              style={{
                color: 'black',
                textDecoration: 'none',
                fontSize: '15px',
                textAlign: 'center',
              }}
            >
              🛠
            </Link>
          ),
        },
        {
          name: ' ',
          render: (heat) => <MatingInsightsPopup cattleId={heat.cattleId} farmId={farmId} />,
        },
        {
          name: '',
          render: (heat) => (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteManualHeat(heat.seasonId, heat.id);
              }}
              disabled={heat.source !== CowHeatEventSourceEnum.MANUAL}
            >
              <Delete htmlColor="black" />
            </IconButton>
          ),
        },
      ]}
      defaultSortColumnName="First Detected"
      headerColour={CaddieColours.AccentGrey}
      indexColumn={false}
      sortDirectionOption="desc"
      size="small"
    />
  );
};

export default HeatTable;
