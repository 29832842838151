import React, { FunctionComponent, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import pluralize from 'pluralize';
import { entries } from 'lodash';
import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';
import Wrapper from 'application/components/wrapper';
import SummaryCell from './summary-cell';
import { ApplyModifiers } from '../../forms/apply-profiles.form/types';

const Title = styled.div`
  font-size: 18px;
  font-weight: 300;
  padding: 16px;
`;

const CowsSubtitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
  padding: 16px;
`;

const Separator = styled.div`
  height: 2px;
  background-color: black;
  opacity: 0.15;
  width: 100%;
  margin: 8px 0px 16px 0px;
`;

type SummaryProps = {
  cattleCount: number;
  modifiersToApply: ApplyModifiers;
};

const Summary: FunctionComponent<SummaryProps> = ({ cattleCount, modifiersToApply }) => {
  const nonEmpty = useMemo(
    () =>
      entries(modifiersToApply)
        .filter(([, applyModifiersOption]) => applyModifiersOption.type !== 'keep-current')
        .map(([type, applyModifiersOption]) => ({
          type: type as TrainingSettingsTypeEnum,
          modifiers: applyModifiersOption.type === 'apply' ? applyModifiersOption.modifiers : [],
        })),
    [modifiersToApply]
  );

  return (
    <Wrapper>
      <Separator />
      <Title>Summary of {pluralize('modifier', nonEmpty.length)} being applied</Title>
      <Grid container spacing={1} style={{ paddingBottom: 8 }}>
        {nonEmpty.map((training) => (
          <Grid key={training.type} item sm={3}>
            <SummaryCell training={training} />
          </Grid>
        ))}
      </Grid>
      <CowsSubtitle>Will be applied to {pluralize('cow', cattleCount, true)}</CowsSubtitle>
      <Separator />
    </Wrapper>
  );
};

export default Summary;
