import { Marker } from 'react-leaflet';
import React, { FC } from 'react';
import TopographyService from 'services/topography.service';
import { centroid } from 'turf';
import L from 'leaflet';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  markerDivIcon: {
    color: 'white',
    textAlign: 'center',
  },
}));

type PaddockLabelMarkerProps = {
  paddockId: string;
};

const PaddockLabelMarker: FC<PaddockLabelMarkerProps> = ({ paddockId }) => {
  const classes = useStyles();
  const paddocks = TopographyService.usePaddocks();
  const paddock = paddocks.find((p) => p.id === paddockId);

  if (paddock == null) return null;

  const centerOfPaddock = centroid(paddock.feature);
  const [longitude, latitude] = centerOfPaddock.geometry.coordinates;

  return (
    <>
      <Marker
        position={[latitude, longitude]}
        icon={L.divIcon({
          className: classes.markerDivIcon,
          html:
            paddock.feature.properties.name ?? paddock.feature.properties.paddockName ?? 'Unnamed Paddock',
          iconSize: [100, 36],
          iconAnchor: [50, 18],
        })}
      />
    </>
  );
};

export default PaddockLabelMarker;
