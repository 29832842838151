import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import { LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import L from 'leaflet';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import { bbox, point, featureCollection, center, distance } from 'turf';
import JSONTree from 'react-json-tree';
import moment from 'moment';

import FarmSelect from 'application/components/farm-select';
import TopographyService from 'services/topography.service';
import MobService from 'services/mob.service';
import useUpdateMobs from 'application/modules/training/hooks/use-update-mobs';
import { jsonTreeTheme } from 'application/modules/debug-cattle/utils';
import { DEFAULT_MAP_CENTER } from 'application/modules/install-planner/constants';
import { IPoint } from '@halter-corp/bff-mobile-service-client';

import { DeviceWithMetadata, TowerWithDevices } from 'store/selectors/tower.selectors';
import CowDot from '../components/cow-dot';
import ActiveBreaksLayer from '../layers/active-breaks.layer';
import TowerLocationsLayer from '../layers/tower-locations.layer';
import { getTowersWithGateway } from '../util';
import LoraCoverageHeatMapsLayer from '../layers/lora-coverage-heat-maps.layer';
import TowerCoverageLayer from '../layers/tower-coverage.layer';
import { MapMode } from '../types';
import MapModeButton from '../components/map-mode-button';
import MapToggleButton from '../components/map-toggle-button';
import HeatMapResolutionSelect from '../components/heat-map-resolution-select';
import UnassignedGatewayLocationsLayer from '../layers/unassigned-gateway-locations.layer';

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 44px);
  width: 100vw;
  overflow: hidden;
  position: relative;
`;

const ContentWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 24px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-left: 64px;
  padding-right: 32px;
  z-index: 999;
`;

const AlertWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-right: auto;
`;

const FarmSelectWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 64px;
  right: 32px;
  gap: 16px;
  width: 264px;
`;

const MapActionsWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 128px;
  right: 32px;
  width: 208px;
`;

const MapActionsBox = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: #424242aa;
  border-radius: 3.5px;
  width: 208px;
`;

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

type LiveViewScreenProps = {
  farmId: string;
  towers: TowerWithDevices[];
  deviceLocations:
    | {
        serialNumber: string;
        location: IPoint;
        currentPaddockId?: string | undefined;
        updatedDate: Date;
        mobId?: string | undefined;
      }[]
    | undefined;
  gateways: DeviceWithMetadata[];
  datum: { latitude: number; longitude: number } | null;
  disableDebugMode: boolean;
};

const LiveViewScreen: FunctionComponent<LiveViewScreenProps> = ({
  farmId,
  towers,
  deviceLocations,
  datum,
  disableDebugMode,
  gateways,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [mapMode, setMapMode] = useState(MapMode.HEAT_MAP);
  const [mapVisible, setMapVisible] = useState(false);

  const [towerIdsForCoverageMap, setTowerIdsForCoverageMap] = useState<Set<string>>(new Set());
  const [gatewayIdsForHeatMap, setGatewayIdsForHeatMap] = useState<Set<string>>(new Set());

  const mobs = MobService.useFetchMobList();
  useUpdateMobs(farmId);

  const metadata = TopographyService.useFarmMapMetadata(farmId);
  const mapRef = useRef<LeafletMap>(null);

  const unassignedGateways = useMemo(() => gateways.filter((gateway) => gateway.towerId == null), [gateways]);
  const towersWithGateways = useMemo(() => getTowersWithGateway(towers), [towers]);

  useEffect(() => {
    if (deviceLocations == null || localStorage.getItem('disableAutoCenter')) return;
    const points = deviceLocations.map(({ location }) =>
      point([location.coordinates[0], location.coordinates[1]])
    );

    if (datum != null) {
      const datumPoint = point([datum.longitude, datum.latitude]);
      const centerOfCattleLocationsToDatumDistance = distance(
        center(featureCollection(points)),
        datumPoint,
        'kilometers'
      );

      if (centerOfCattleLocationsToDatumDistance > 2) {
        // eslint-disable-next-line no-unused-expressions
        mapRef.current?.leafletElement.setView({ lat: datum.latitude, lng: datum.longitude }, 16);
        return;
      }
    }

    const bounds = bbox(featureCollection(points));

    const leafletBounds = [
      [bounds[1], bounds[0]],
      [bounds[3], bounds[2]],
    ];

    if (deviceLocations.length !== 0) {
      // eslint-disable-next-line no-unused-expressions
      mapRef.current?.leafletElement.fitBounds(leafletBounds as any, {
        padding: [32, 32],
        maxZoom: 18,
      });
    }
  }, [deviceLocations, datum]);

  const handleFarmChanged = (newFarmId: string) => {
    const location = {
      search: new URLSearchParams({
        farmId: newFarmId,
        disableDebugMode: String(disableDebugMode),
      }).toString(),
    };
    history.push(location);
  };

  const handleMapVisible = (visible: boolean) => {
    if (disableDebugMode) return;

    setMapVisible(visible);
    if (mapMode === MapMode.HEAT_MAP) {
      if (visible) {
        setGatewayIdsForHeatMap(new Set(gateways.map((gateway) => gateway.id)));
      } else {
        setGatewayIdsForHeatMap(new Set());
      }
    } else if (mapMode === MapMode.KEY_HOLE_RADIO_MAP) {
      if (visible) {
        setTowerIdsForCoverageMap(new Set(towersWithGateways.map((tower) => tower.id)));
      } else {
        setTowerIdsForCoverageMap(new Set());
      }
    }
  };

  const handleSelectTowerForCoverageMap = (towerId: string) => {
    if (disableDebugMode) return;

    setTowerIdsForCoverageMap((currentSet) => {
      const newSet = new Set(currentSet);
      if (newSet.has(towerId)) {
        newSet.delete(towerId);
      } else {
        newSet.add(towerId);
      }

      if (newSet.size === towersWithGateways.length) {
        setMapVisible(true);
      } else {
        setMapVisible(false);
      }

      return newSet;
    });
  };

  const handleSelectGatewayForHeatMap = (gatewayId: string) => {
    if (disableDebugMode) return;

    setGatewayIdsForHeatMap((currentSet) => {
      const newSet = new Set(currentSet);
      if (newSet.has(gatewayId)) {
        newSet.delete(gatewayId);
      } else {
        newSet.add(gatewayId);
      }
      if (newSet.size === gateways.length) {
        setMapVisible(true);
      } else {
        setMapVisible(false);
      }

      return newSet;
    });
  };

  useEffect(() => {
    setMapVisible(false);
    setTowerIdsForCoverageMap(new Set());
    setGatewayIdsForHeatMap(new Set());
  }, [farmId, mapMode]);

  return (
    <ScreenWrapper
      style={
        disableDebugMode ? { position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, height: '100%' } : {}
      }
    >
      {!disableDebugMode && (
        <>
          <LinearProgress variant={loading ? 'query' : 'determinate'} value={100} />
          <ContentWrapper>
            {metadata == null && (
              <AlertWrapper>
                <Alert severity="warning">Warning: No location data for selected farm</Alert>
              </AlertWrapper>
            )}
            <FarmSelectWrapper>
              <FarmSelect onChange={handleFarmChanged} />
            </FarmSelectWrapper>

            <MapActionsWrapper>
              <MapActionsBox>
                <MapModeButton mapMode={mapMode} onChangeMapMode={setMapMode} />
                <MapToggleButton
                  mapMode={mapMode}
                  mapVisible={mapVisible}
                  onChangeMapVisible={handleMapVisible}
                />
                <HeatMapResolutionSelect visible={mapMode === MapMode.HEAT_MAP} />
              </MapActionsBox>
            </MapActionsWrapper>
          </ContentWrapper>
        </>
      )}

      <MapWrapper>
        <LeafletMap
          ref={mapRef}
          preferCanvas
          bounds={metadata?.bounds ?? DEFAULT_MAP_CENTER}
          minZoom={metadata?.zoom.minZoom ?? 13}
          maxZoom={metadata?.zoom.maxZoom ?? 22}
          style={{ flex: 1 }}
          zoomControl={!disableDebugMode}
        >
          <TileLayer url="https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}" />
          {metadata && <TileLayer url={metadata.tileUrl} />}

          {!disableDebugMode && <ActiveBreaksLayer farmId={farmId} onLoadingChange={setLoading} />}

          <TowerCoverageLayer coverageMapsVisibilitySet={towerIdsForCoverageMap} />

          <TowerLocationsLayer
            towers={towers}
            mapMode={mapMode}
            onSelectTowerForCoverageMap={handleSelectTowerForCoverageMap}
            onSelectGatewayForHeatMap={handleSelectGatewayForHeatMap}
          />
          <UnassignedGatewayLocationsLayer
            gateways={unassignedGateways}
            mapMode={mapMode}
            onSelectGatewayForHeatMap={handleSelectGatewayForHeatMap}
          />

          <LoraCoverageHeatMapsLayer gatewayIdsForHeatMap={gatewayIdsForHeatMap} />

          {deviceLocations?.map((cattleLocation) => {
            const mob = mobs.find((m) => m.id === cattleLocation.mobId);
            const lastUpdated = moment(cattleLocation.updatedDate).fromNow();

            const mobJson = {
              Mob: mob?.name,
              'Serial Number': cattleLocation.serialNumber,
              'Last Updated': lastUpdated,
            };

            return (
              <Marker
                key={cattleLocation.serialNumber}
                riseOnHover
                icon={L.divIcon({
                  className: 'bla',
                  html: ReactDOMServer.renderToString(<CowDot mob={mob} />),
                })}
                position={{
                  lat: cattleLocation.location.coordinates[1],
                  lng: cattleLocation.location.coordinates[0],
                }}
              >
                <Popup autoPan={false}>
                  <JSONTree
                    labelRenderer={([key]) => <span style={{ fontSize: 14 }}>{key}</span>}
                    valueRenderer={(value) => <span style={{ fontSize: 14 }}>{value}</span>}
                    data={mobJson}
                    theme={jsonTreeTheme}
                    shouldExpandNode={() => true}
                    hideRoot
                  />
                </Popup>
              </Marker>
            );
          })}
        </LeafletMap>
      </MapWrapper>
    </ScreenWrapper>
  );
};

export default LiveViewScreen;
