import React, { FunctionComponent } from 'react';
import { Dialog, DialogContent, DialogTitle, List, ListItem } from '@material-ui/core';
import Button from 'application/components/button';
import { useDispatch } from 'react-redux';
import towerEffects from 'store/effects/tower.effects';
import infrastructureEffects from 'store/effects/infrastructure.effects';
import { DeviceActionDialogProps } from '../DevicesActionsWrapper';

const DisableDialog: FunctionComponent<DeviceActionDialogProps> = ({ open, onClose, devices }) => {
  const dispatch = useDispatch();

  function handleRequest() {
    onClose();
    devices.forEach((device) => {
      dispatch(
        towerEffects.updateDeviceStatusById({
          id: device.id,
          updateDeviceStatusDto: { enabled: false },
        })
      );
      dispatch(
        infrastructureEffects.updateDeviceStatusById({
          id: device.id,
          updatedInfraProductStatusRequest: { maintenance: true },
        })
      );
    });
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Disable devices?</DialogTitle>
      <DialogContent>
        <List dense>
          {devices.map((device) => (
            <ListItem key={device.id}>- {device.id}</ListItem>
          ))}
        </List>
        <Button text="Disable" onClick={handleRequest} />
      </DialogContent>
    </Dialog>
  );
};

export default DisableDialog;
