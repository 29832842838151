import React, { FunctionComponent, useState } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty, omitBy } from 'lodash';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import FarmSelect from 'application/components/farm-select';
import MobSelect from 'application/components/mob-select';

import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import { getCurrentMob } from 'store/selectors/mob.selectors';
import { splitNumberList } from '../../../utils';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0px 24px 16px 24px;
`;

type DevicesSearchPanelProps = {
  searchMode: 'farm' | 'device';
  mobId?: string;
  cattleNames?: string[];
  serialNumbers?: string[];
  onChangeFarmId: (value: string) => void;
};

const SEPARATOR = ',';

const DevicesSearchPanel: FunctionComponent<DevicesSearchPanelProps> = ({
  searchMode,
  cattleNames: initialCattleNames,
  serialNumbers: initialSerialNumbers,
  onChangeFarmId,
}) => {
  const farmId = useSelector(getCurrentFarm);
  const mobId = useSelector(getCurrentMob);

  const [serialNumbers, setSerialNumbers] = useState(initialSerialNumbers);
  const [cattleNames, setCattleNames] = useState(initialCattleNames);

  const handleSearchEvent = () => {
    ReactGA.event({
      category: 'Devices Overview',
      action: 'Search',
    });
  };

  return (
    <Wrapper>
      <Grid container spacing={2} alignItems="center" alignContent="center">
        {searchMode === 'farm' && (
          <Grid item xs={6} sm={3} lg={2} style={{ display: 'flex' }}>
            <FarmSelect onChange={onChangeFarmId} />
          </Grid>
        )}
        {searchMode === 'farm' && (
          <Grid item xs={6} sm={3} lg={2} style={{ display: 'flex' }}>
            <MobSelect farmId={farmId} />
          </Grid>
        )}
        {searchMode === 'farm' && (
          <Grid item xs={12} sm={3} style={{ display: 'flex' }}>
            <TextField
              label="Cow numbers"
              value={cattleNames}
              onChange={(event) =>
                setCattleNames(splitNumberList(event.target.value).map((name) => name.trim()))
              }
              style={{ flex: 1 }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={searchMode === 'farm' ? 3 : 10} style={{ display: 'flex' }}>
          <TextField
            label="Serial numbers"
            value={serialNumbers}
            onChange={(event) =>
              setSerialNumbers(splitNumberList(event.target.value).map((serial) => serial.trim()))
            }
            style={{ flex: 1 }}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={2} style={{ display: 'flex' }}>
          <Button
            size="small"
            color="primary"
            component={Link}
            style={{
              backgroundColor: '#1769aa',
              color: 'white',
              margin: 16,
              padding: '8px 24px 8px 24px',
            }}
            to={{
              search: new URLSearchParams(
                omitBy(
                  {
                    mode: searchMode,
                    cattleNames: cattleNames?.join(SEPARATOR),
                    serialNumbers: serialNumbers?.join(SEPARATOR),
                    farmId,
                    mobId: mobId ?? '',
                  },
                  (value) => value == null || isEmpty(value)
                ) as any
              ).toString(),
            }}
            onClick={() => handleSearchEvent()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default DevicesSearchPanel;
