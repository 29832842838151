import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICreateNetworkGroupFarmMappingRequestDTO,
  IDeviceDTO,
  IFarmDTO,
  IFarmMetadataDTO,
  INetworkGroupFarmMappingDTO,
  IShardDTO,
  IUpdateDeviceRequestDTO,
} from '@halter-corp/farm-infra-service-client';
import FarmInfraService from 'services/farm-infra-service';

const farmInfraEffects = {
  fetchShards: createAsyncThunk('farm-infra/fetchShards', async (): Promise<IShardDTO[] | []> => {
    const data = await FarmInfraService.fetchShards();
    return data;
  }),

  fetchFarms: createAsyncThunk('farm-infra/fetchFarms', async (): Promise<IFarmDTO[] | []> => {
    const data = await FarmInfraService.fetchFarms();
    return data;
  }),

  fetchDevicesByFarmId: createAsyncThunk(
    'farm-infra/fetchDevicesByFarmId',
    async (props: { farmId: string }): Promise<IDeviceDTO[] | []> => {
      const { farmId } = props;
      const data = await FarmInfraService.fetchDevicesByFarmId(farmId);
      return data;
    }
  ),

  fetchDevicesByShardId: createAsyncThunk(
    'farm-infra/fetchDevicesByShardId',
    async (props: { shardId: string }): Promise<IDeviceDTO[] | []> => {
      const { shardId } = props;
      const data = await FarmInfraService.fetchDevicesByShardId(shardId);
      return data;
    }
  ),

  updateDeviceById: createAsyncThunk(
    'farm-infra/updateDeviceById',
    async (props: {
      deviceId: string;
      farmId: string;
      updateDeviceRequestDTO: IUpdateDeviceRequestDTO;
    }): Promise<IDeviceDTO | null> => {
      const { deviceId, farmId, updateDeviceRequestDTO } = props;
      try {
        if (!deviceId?.trim() || !farmId?.trim()) throw new Error('Device ID and Farm ID cannot be empty');
        const data = await FarmInfraService.updateDeviceById(deviceId, farmId, updateDeviceRequestDTO);
        return data;
      } catch (err) {
        return null;
      }
    }
  ),

  fetchFarmMetadataById: createAsyncThunk(
    'farm-infra/fetchFarmMetadataById',
    async (props: { farmId: string }): Promise<IFarmMetadataDTO | null> => {
      const { farmId } = props;
      const data = await FarmInfraService.fetchFarmMetadataById(farmId);
      return data;
    }
  ),
  fetchNetworkGroupsByFarmId: createAsyncThunk(
    'farm-infra/fetchNetworkGroupsByFarmId',
    async (props: { farmId: string }): Promise<string[] | []> => {
      const { farmId } = props;
      const data = await FarmInfraService.fetchNetworkGroupMappingsByFarm(farmId);
      return data.map((networkGroupMapping) => networkGroupMapping.networkGroupId);
    }
  ),
  saveNetworkGroupFarmMapping: createAsyncThunk(
    'farm-infra/saveNetworkGroupFarmMapping',
    async (props: {
      createNetworkGroupFarmMappingRequest: ICreateNetworkGroupFarmMappingRequestDTO;
    }): Promise<INetworkGroupFarmMappingDTO | null> => {
      const { createNetworkGroupFarmMappingRequest } = props;
      const data = await FarmInfraService.saveNetworkGroupFarmMapping(createNetworkGroupFarmMappingRequest);
      return data;
    }
  ),
};

export default farmInfraEffects;
