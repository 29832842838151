import React, { FunctionComponent, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { keyBy, isEmpty, entries, groupBy, uniqBy, values, flatten } from 'lodash';

import {
  IApplyModifiersRequestDTO,
  ITrainingModifierDTO,
  TrainingSettingsTypeEnum,
} from '@halter-corp/training-service-client';

import { useDispatch } from 'store';
import trainingEffects from 'store/effects/training.effects';
import { ApplyModifiersOption, Modifier } from 'data/training';
import TrainingService from 'services/training.service';

import Wrapper from 'application/components/wrapper';
import Button from 'application/components/button';
import Checkbox from 'application/components/checkbox';

import styled from 'styled-components';
import Summary from '../../components/summary';
import { ApplyModifiers } from '../../forms/apply-profiles.form/types';

import CowApplySummary from './cow-apply-summary';

type ApplySummaryContainerProps = {
  farmId: string;
  modifiersToApply: ApplyModifiers;
};

const ApplyModifiersContainer: FunctionComponent<ApplySummaryContainerProps> = ({
  farmId,
  modifiersToApply,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const appliedCowModifiers = TrainingService.useSelectAppliedModifiers();
  const isLoading = TrainingService.useIsLoading();

  const [includeUntaggedCattle, setIncludeUntaggedCattle] = useState(false);

  const summaries: {
    cattleId: string;
    cattleName: string;
    mobName?: string;
    currentModifiers: { [type: string]: ITrainingModifierDTO[] };
    modifiersAfterApply: { [type: string]: ITrainingModifierDTO[] };
  }[] = useMemo(() => {
    const keyedByCow = keyBy(appliedCowModifiers, ({ cattleId }) => cattleId);

    const getModifiersAfterApply = (
      apply: ApplyModifiersOption,
      currentModifiers: ITrainingModifierDTO[]
    ) => {
      if (apply.type === 'remove-all') return [];
      if (apply.type === 'keep-current') return currentModifiers;
      return [
        ...currentModifiers.filter((modifier) => modifier.autoApplyCondition != null),
        ...apply.modifiers,
      ];
    };

    const changedModifiers = entries(modifiersToApply).filter(
      ([, applyModifiersOption]) => applyModifiersOption.type !== 'keep-current'
    );

    return appliedCowModifiers
      .filter(({ serialNumber }) => includeUntaggedCattle || serialNumber != null)
      .map(({ cattleId, cowAppliedModifiers }) => {
        const currentModifiers: { [type: string]: ITrainingModifierDTO[] } = groupBy(
          cowAppliedModifiers.modifiers,
          (modifier) => modifier.type
        );
        const modifiersAfterApply: { [type: string]: ITrainingModifierDTO[] } = {};
        changedModifiers.forEach(([type, applyModifierOption]) => {
          modifiersAfterApply[type] = getModifiersAfterApply(
            applyModifierOption,
            currentModifiers[type] ?? []
          );
        });

        return {
          cattleId,
          currentModifiers,
          modifiersAfterApply,
          cattleName: keyedByCow[cattleId].cattleName,
          mobName: keyedByCow[cattleId].mobName,
        };
      });
  }, [modifiersToApply, appliedCowModifiers, includeUntaggedCattle]);

  const hasApplyWithDeletedProfileOrModifiers = useMemo(() => {
    const isDeleted = ({ deletedAt }: { deletedAt?: Date }): boolean => deletedAt != null;

    return summaries.some(({ modifiersAfterApply: trainingsAfterApply }) =>
      flatten(values(trainingsAfterApply)).some(isDeleted)
    );
  }, [summaries]);

  const onApply = async () => {
    const cattleIds = summaries.map(({ cattleId }) => cattleId);
    const applyModifierOptionsWithChanges: [
      string,
      { type: 'apply'; modifiers: Modifier[] } | { type: 'remove-all' }
    ][] = entries(modifiersToApply).filter(
      (entry): entry is [string, { type: 'apply'; modifiers: Modifier[] } | { type: 'remove-all' }] =>
        entry[1].type !== 'keep-current'
    );

    const request: IApplyModifiersRequestDTO = {
      cattleIds,
      modifiers: applyModifierOptionsWithChanges.map(([typeString, applyModifiersOption]) => {
        const type: TrainingSettingsTypeEnum = typeString as TrainingSettingsTypeEnum;
        if (applyModifiersOption.type === 'remove-all') return { type, modifierIds: [] };
        return { type, modifierIds: applyModifiersOption.modifiers.map((modifier) => modifier.id) };
      }),
    };

    const result = await dispatch(trainingEffects.applyModifiers({ farmId, request }));

    if (trainingEffects.applyModifiers.fulfilled.match(result)) {
      history.push('/training');
    }
  };

  return (
    <ContainerWrapper>
      {!isLoading && (
        <>
          <Checkbox
            label="Include untagged cows"
            checked={includeUntaggedCattle}
            onChangeValue={setIncludeUntaggedCattle}
            style={{ padding: 8 }}
          />
          <Summary
            cattleCount={uniqBy(summaries, ({ cattleId }) => cattleId).length}
            modifiersToApply={modifiersToApply}
          />
          {summaries.map((summary) => (
            <CowApplySummary key={summary.cattleId} summary={summary} />
          ))}
          {!isEmpty(summaries) && hasApplyWithDeletedProfileOrModifiers && (
            <ButtonWrapper>
              These changes can&apos;t be applied because some deleted profiles or modifiers are still
              applied, please make sure these are removed
            </ButtonWrapper>
          )}
          {!isEmpty(summaries) && !hasApplyWithDeletedProfileOrModifiers && (
            <ButtonWrapper>
              Please double check it&apos;s what you intended if there are modifiers being removed in the
              summaries above :)
              <Button text="Apply" onClick={onApply} style={{ margin: 4 }} />
            </ButtonWrapper>
          )}
        </>
      )}
    </ContainerWrapper>
  );
};

const ContainerWrapper = styled(Wrapper)`
  padding: 16px;
`;

const ButtonWrapper = styled(Wrapper)`
  flex: 1px;
  align-items: center;
  padding: 8px;
`;

export default ApplyModifiersContainer;
