export class MapUtils {
  static fromObject<K extends PropertyKey, V>(obj: Record<K, V>): Map<string, V> {
    return new Map(Object.entries<V>(obj));
  }

  static toObject<K, V>(map: Map<K, V>): Record<string, V> {
    return Object.fromEntries(map.entries());
  }

  static mapValues<K, V, R>(map: Map<K, V>, mapValues: (value: V) => R): Map<K, R> {
    return new Map(Array.from(map.entries()).map(([key, value]) => [key, mapValues(value)]));
  }

  static mergeWith<K, V>(
    map1: Map<K, V>,
    map2: Map<K, V>,
    customMergeFn: (value1: V, value2: V) => V
  ): Map<K, V> {
    const mergedMap = new Map<K, V>();

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of map1.entries()) {
      mergedMap.set(key, value);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of map2.entries()) {
      if (mergedMap.has(key)) {
        mergedMap.set(key, customMergeFn(mergedMap.get(key)!, value));
      } else {
        mergedMap.set(key, value);
      }
    }

    return mergedMap;
  }

  static keyBy<K, V>(list: V[], keyFn: (value: V) => K): Map<K, V> {
    return new Map(list.map((value) => [keyFn(value), value]));
  }
}
