import React, { FunctionComponent } from 'react';
import Map, { MapRef, NavigationControl } from 'react-map-gl';
import BlueSkyLayer from '../layers/blue-sky.layer';
import TerrainLayer from '../layers/terrain-heatmap.layer';

type MapboxMapProps = {
  mapRef: React.RefObject<MapRef>;
  setMapLoaded: (loaded: boolean) => void;
  children?: React.ReactNode;
};

const MapboxMap: FunctionComponent<MapboxMapProps> = ({ mapRef, setMapLoaded, children }) => (
  <Map
    ref={mapRef}
    initialViewState={{
      longitude: 174,
      latitude: -41,
      zoom: 5,
    }}
    maxPitch={80}
    terrain={{ source: 'mapbox-raster-dem' }}
    mapStyle="mapbox://styles/mapbox/satellite-v9"
    onLoad={() => setMapLoaded(true)}
    attributionControl={false}
    logoPosition="top-left"
    mapboxAccessToken="pk.eyJ1IjoiYmVuamFtaW4tc3dlbmV5LWhhbHRlciIsImEiOiJja3Y1d2N0cW0yNDRtMm9xMWowYTN1dTBvIn0.IJN324g19cwXGMnpOE73ww"
  >
    <NavigationControl />
    <BlueSkyLayer />
    <TerrainLayer />
    {children}
  </Map>
);
export default MapboxMap;
