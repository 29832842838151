import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { splitFieldValues } from 'application/utils';

import BffDebugToolService from 'services/bff-debug-tool.service';

import mapPageItemsSlice from 'store/slices/map-page-items.slice';
import mobSlice from 'store/slices/mob.slice';

import { getCurrentFarm } from 'store/selectors/farm-context.selectors';

import topographyEffects from 'store/effects/topography.effects';
import DebugCattleScreen from './screens/debug-cattle.screen';

const DebugCattleModule: FunctionComponent = () => {
  const urlQueryString = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();

  const cattleEarTagsString = urlQueryString.get('earTags');
  const serialNumbersString = urlQueryString.get('serialNumbers');
  const fromDateString = urlQueryString.get('fromDate');
  const toDateString = urlQueryString.get('toDate');
  const collarCommandIdsFromQueryString = urlQueryString.get('collarCommandIds');
  const firmwareVersionsFromQueryString = urlQueryString.get('firmwareVersions');
  const farmIdFromQueryString = urlQueryString.get('farmId');
  const mobIdFromQueryString = urlQueryString.get('mobId');
  const mobIds =
    mobIdFromQueryString && mobIdFromQueryString?.length > 0 ? mobIdFromQueryString.split(',') : null;

  if (mobIds != null) {
    dispatch(mobSlice.actions.setCurrentMobs({ currentMobs: mobIds }));
  }

  const cattleEarTags = useMemo<string[]>(() => splitFieldValues(cattleEarTagsString), [cattleEarTagsString]);
  const serialNumbers = useMemo<string[]>(() => splitFieldValues(serialNumbersString), [serialNumbersString]);
  const collarCommandIds = useMemo<number[]>(
    () => splitFieldValues(collarCommandIdsFromQueryString).map((c) => parseInt(c, 10)),
    [collarCommandIdsFromQueryString]
  );
  const firmwareVersions = useMemo<string[]>(
    () => splitFieldValues(firmwareVersionsFromQueryString),
    [firmwareVersionsFromQueryString]
  );

  const requestDateRange = useMemo<[Date, Date]>(
    () =>
      fromDateString == null || toDateString == null
        ? [moment().subtract(30, 'minutes').toDate(), new Date()]
        : [new Date(fromDateString), new Date(toDateString)],
    [fromDateString, toDateString]
  );

  const farmIdFromSelector = useSelector(getCurrentFarm);
  const currentFarmId = farmIdFromQueryString || farmIdFromSelector;

  const [loading, setLoading] = useState(false);
  const [hasResults, setHasResults] = useState(false);
  const [queryReturnedError, setQueryReturnedError] = useState(false);

  useEffect(() => {
    if ([...urlQueryString.keys()].length === 0) return;
    if (requestDateRange == null) return;

    setLoading(true);
    setQueryReturnedError(false);
    setHasResults(false);

    (async () => {
      dispatch(mapPageItemsSlice.actions.clear());

      try {
        const data = await BffDebugToolService.fetchMapPageData({
          serialNumbers,
          mobIds,
          timestamp: requestDateRange,
          farmId: currentFarmId,
          cattleNames: cattleEarTags || [],
          collarCommandIds,
          firmwareVersions,
        });
        setHasResults(true);
        dispatch(mapPageItemsSlice.actions.setData(data));
      } catch (error) {
        setQueryReturnedError(true);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cattleEarTags,
    serialNumbers,
    requestDateRange,
    currentFarmId,
    collarCommandIds,
    mobIds?.join(','),
    dispatch,
  ]);

  useEffect(() => {
    dispatch(topographyEffects.fetchPaddocks({ farmId: currentFarmId }));
  }, [currentFarmId, dispatch]);

  return (
    <DebugCattleScreen
      key={urlQueryString.toString()}
      loading={loading}
      cattleEarTags={cattleEarTags}
      serialNumbers={serialNumbers}
      dateRange={requestDateRange}
      itemsDateRange={requestDateRange}
      collarCommandIds={collarCommandIds}
      firmwareVersions={firmwareVersions}
      queryReturnedError={queryReturnedError}
      hasResults={hasResults}
    />
  );
};

export default DebugCattleModule;
