import React, { FunctionComponent, useMemo } from 'react';
import TrainingService from 'services/training.service';
import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';
import DataTable, { Column } from 'application/components/data-table';
import { AppliedTrainingProfile } from 'data/training';
import { useSelector } from 'react-redux';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import BusinessService from 'services/business.service';
import { keyBy } from 'lodash';
import { prettyPrintProfileType } from '../utils';

const AppliedProfilesTable: FunctionComponent = () => {
  const appliedProfiles = TrainingService.useSelectAppliedProfiles();
  const currentFarm = useSelector(getCurrentFarm);
  const farms = BusinessService.useFetchFarmList();
  const keyedFarms = useMemo(() => keyBy(farms, ({ id }) => id), [farms]);

  return (
    <DataTable<AppliedTrainingProfile[]>
      data={[appliedProfiles]}
      columns={[
        {
          name: 'Farm',
          render: () => keyedFarms[currentFarm]?.name,
        },
        ...Object.values(TrainingSettingsTypeEnum).map<Column<AppliedTrainingProfile[]>>((profileType) => ({
          name: prettyPrintProfileType(profileType),
          render: (applied) => applied.find((ap) => ap.profile?.type === profileType)?.profile?.name,
        })),
      ]}
    />
  );
};

export default AppliedProfilesTable;
