import React, { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'store';
import DataTable, { Column } from 'application/components/data-table';
import { CaddieColours } from 'application/theme/colours';
import { dateToText } from 'application/modules/fleet-watch/utils';
import { Link } from 'react-router-dom';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import {
  IDiagnosedSummaryHealthCaseDTO,
  INotDiagnosedSummaryHealthCaseDTO,
  IHealthCaseDTO,
} from '@halter-corp/cow-health-service-client';
import CattleService from 'services/cattle.service';
import { keyBy } from 'lodash';
import { Typography } from '@material-ui/core';

type HealthCase = INotDiagnosedSummaryHealthCaseDTO | IDiagnosedSummaryHealthCaseDTO | IHealthCaseDTO;
function getDiagnoses(entry: HealthCase): string[] {
  if ('diagnoses' in entry) {
    return entry.diagnoses?.map((d) => d.diagnosis);
  }
  return [];
}
function getSymptoms(entry: HealthCase): string[] {
  if ('symptoms' in entry) {
    return entry.symptoms.map((s) => `${s.prefix ?? ''} ${s.symptomType}`);
  }
  return [];
}

type HealthTableProps = {
  title: string;
  healthCases: HealthCase[];
  hideDiagnoses?: boolean;
  hideSymptoms?: boolean;
};

export const HealthTable: FunctionComponent<HealthTableProps> = ({
  healthCases,
  title,
  hideSymptoms,
  hideDiagnoses,
}) => {
  const currentFarm = useSelector(getCurrentFarm);

  const selectCattle = CattleService.useSelectCattle();
  const cattleByKey = keyBy(selectCattle, (item) => item.id);
  const columns = useMemo(() => {
    const nullableColumns = [
      {
        name: '',
        render: (_, index) => Number(index + 1),
      },
      {
        name: 'Cow',
        keyExtractor: (entry) => cattleByKey[entry.cattleId]?.name,
        render: (entry) => cattleByKey[entry.cattleId]?.name,
      },
      {
        name: 'First detected at',
        keyExtractor: (entry) => entry.firstDetectedAt,
        render: (entry) => dateToText(entry.firstDetectedAt),
      },
      {
        name: 'Last Flagged',
        keyExtractor: (entry) => entry.lastDetectedAt,
        render: (entry) => dateToText(entry.lastDetectedAt),
      },
      {
        name: 'Status',
        keyExtractor: (entry) => entry.status,
        render: (entry) => entry.status,
      },
      hideDiagnoses !== true
        ? {
            name: 'Sickness Type',
            keyExtractor: (entry) => getDiagnoses(entry).join(', '),
            render: (entry) => getDiagnoses(entry).join(', '),
          }
        : null,
      hideSymptoms !== true
        ? {
            name: 'Symptoms',
            keyExtractor: (entry) => getSymptoms(entry).join(', '),
            render: (entry) => getSymptoms(entry).join(', '),
          }
        : null,
      {
        name: 'Grafana',
        render: (entry) => (
          <Link
            to={{
              pathname: `https://halter.grafana.net/d/I9QlPyi4z/behaviour-support-toolbelt?orgId=1&var-farm_id=${currentFarm}&var-cattle_id=${entry.cattleId}`,
            }}
            target="_blank"
            style={{
              color: 'black',
              textDecoration: 'none',
              fontSize: '15px',
              textAlign: 'center',
            }}
          >
            🛠
          </Link>
        ),
      },
    ] as (Column<HealthCase> | null)[];
    return nullableColumns.filter((c) => c !== null) as Column<HealthCase>[];
  }, [hideSymptoms, hideDiagnoses]);

  return (
    <div>
      <Typography variant="h6">{title}</Typography>
      <DataTable<HealthCase>
        data={healthCases}
        columns={columns}
        defaultSortColumnName="First detected at"
        headerColour={CaddieColours.AccentGrey}
        indexColumn={false}
        sortDirectionOption="desc"
      />
    </div>
  );
};
