/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent } from 'react';
import { makeStyles, Slider, SliderProps } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { DEFAULT_DATE_TIME_FORMAT } from '../utils/constants';

const useStyles = makeStyles(() => ({
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
}));

export type DateRangeSliderProps = Omit<
  SliderProps,
  'value' | 'onChange' | 'onChangeCommitted' | 'min' | 'max'
> & {
  dateRange: [Date, Date];
  min: Date;
  max: Date;
  onChange: (event: React.ChangeEvent<{}>, value: [Date, Date]) => void;
  onChangeCommitted?: (event: React.ChangeEvent<{}>, value: [Date, Date]) => void;
};

const convertValueToDateRange = (value: number | number[]): [Date, Date] =>
  (value as number[]).map((n) => new Date(+n)) as [Date, Date];

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const valueLabelComponent = (props: Props) => {
  const { children, open, value } = props;
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value} arrow>
      {children}
    </Tooltip>
  );
};

const DateRangeSlider: FunctionComponent<DateRangeSliderProps> = ({
  dateRange,
  min,
  max,
  onChange,
  onChangeCommitted,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Slider
      style={{ marginLeft: '40px', maxWidth: '90%' }}
      value={dateRange.map((d) => d.getTime())}
      classes={{
        track: classes.track,
        rail: classes.rail,
      }}
      min={min.getTime()}
      max={max.getTime()}
      step={moment.duration(3, 'seconds').asMilliseconds()}
      valueLabelFormat={(value: number) => moment(value).format(DEFAULT_DATE_TIME_FORMAT)}
      ValueLabelComponent={valueLabelComponent}
      valueLabelDisplay="auto"
      onChange={(event, value) => onChange && onChange(event, convertValueToDateRange(value))}
      onChangeCommitted={(event, value) =>
        onChangeCommitted && onChangeCommitted(event, convertValueToDateRange(value))
      }
      {...rest}
      marks={[
        { value: min.getTime(), label: moment(min.getTime()).format('DD/MM/YYYY HH:mm') },
        { value: max.getTime(), label: moment(max.getTime()).format('DD/MM/YYYY HH:mm') },
      ]}
    />
  );
};
export default DateRangeSlider;
