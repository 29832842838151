import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import fleetEffects from 'store/effects/fleet.effects';
import FleetTrackingScreen from '../screens/fleet-tracking.screen';

const FleetTrackingContainer: FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fleetEffects.fetchOffFarmCheckpointsByCheckpoints());
  }, []);

  return <FleetTrackingScreen />;
};

export default FleetTrackingContainer;
