import React, { FunctionComponent, useState, ReactElement } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  DialogActions,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ModalButton from 'application/components/modal-button';
import DataTable from 'application/components/data-table';

import Checkbox from '../../../components/checkbox';
import { CaddieColours } from '../../../theme/colours';
import { FarmStatusMode } from '../utils';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '24px 18px 24px 24px',
    margin: '25px 0',
    display: 'flex',
  },
  farm: {
    marginLeft: 'auto',
  },
}));

const cancelButtonStyle = {
  textDecoration: 'underline',
  flex: 'none',
};

type FarmStatusDialogProps = {
  farmStates: FarmState[];
  isDialogOpen: (isOpen: boolean) => void;
  dialogContent: ReactElement;
  farmStatus: FarmStatusMode;
  handleConfirm: () => void;
  dialogConfirmation: ReactElement;
  color: CaddieColours;
  confirmationColor: CaddieColours;
};

type FarmState = {
  farmId: string;
  farmName: string;
  status: FarmStatusMode;
};

const FarmStatusDialog: FunctionComponent<FarmStatusDialogProps> = ({
  farmStates,
  isDialogOpen,
  dialogContent,
  farmStatus,
  handleConfirm,
  dialogConfirmation,
  color,
  confirmationColor,
}) => {
  const classes = useStyles();
  const [hasCheckedConfirmation, setHasCheckedConfirmation] = useState<boolean>(false);

  const confirmButtonStyle = {
    backgroundColor: color,
    color: 'white',
    flex: 'none',
  };

  const handleCheckbox = () => {
    setHasCheckedConfirmation(!hasCheckedConfirmation);
  };

  const modalTable = (
    <DataTable<FarmState>
      data={farmStates}
      columns={[
        {
          name: (
            <Typography className={classes.farm} component="div" variant="body2">
              Farm
            </Typography>
          ),
          keyExtractor: (item: FarmState) => item.farmName,
          render: (item: FarmState) => (
            <Typography className={classes.farm} component="div" variant="body2">
              {item.farmName}
            </Typography>
          ),
        },
        {
          name: 'Status',
          keyExtractor: (item: FarmState) => item.status,
          render: (item: FarmState) => (
            <Typography className={classes.farm} component="div" variant="body2">
              {item?.status}
            </Typography>
          ),
        },
      ]}
      indexColumn={false}
      headerColour={CaddieColours.DarkGrey}
    />
  );

  return (
    <Dialog open>
      <DialogTitle>Turn on {farmStatus} Mode</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">{dialogContent}</DialogContentText>
        {modalTable}
        <Paper elevation={0} className={classes.paper} style={{ backgroundColor: confirmationColor }}>
          <Checkbox checked={hasCheckedConfirmation} onChange={() => handleCheckbox()} />
          <div style={{ paddingLeft: 5 }}>{dialogConfirmation}</div>
        </Paper>
      </DialogContent>
      <DialogActions>
        <ModalButton
          onPress={() => {
            isDialogOpen(false);
          }}
          text="Cancel"
          style={cancelButtonStyle}
        />
        <ModalButton
          onPress={handleConfirm}
          disabled={!hasCheckedConfirmation}
          text={`Turn on ${farmStatus} Mode`}
          style={confirmButtonStyle}
        />
      </DialogActions>
    </Dialog>
  );
};

export default FarmStatusDialog;
