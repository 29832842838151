import { Grid, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { camelCase, startCase } from 'lodash';
import React, { FunctionComponent } from 'react';
import { transitionCowgStatuses, CowgStatusEnum } from 'store/selectors/map-page-items.selectors';
import { generateEventIcon, getCattleEventIconColor } from '../../../utils/cattle-event-util';

export type CowgEventFilterProps = {
  selectedCowGStatuses: CowgStatusEnum[];
  allAvailableCowgStatuses: CowgStatusEnum[];
  showCattleEvents: boolean;
  onSelectedCowGStatusesChange: (selectedCowGStatuses: CowgStatusEnum[]) => void;
};

const CowgEventFilter: FunctionComponent<CowgEventFilterProps> = ({
  allAvailableCowgStatuses,
  selectedCowGStatuses,
  showCattleEvents,
  onSelectedCowGStatusesChange,
}) => {
  const handleEventStatusChange = (eventStatus: CowgStatusEnum, checked: boolean) => {
    const newEventStatus = checked
      ? [...selectedCowGStatuses, eventStatus]
      : selectedCowGStatuses.filter((selected) => selected !== eventStatus);
    onSelectedCowGStatusesChange(newEventStatus);
  };

  if (allAvailableCowgStatuses == null) return null;

  const getOptionDisplayName = (name: string) => startCase(camelCase(name));

  return (
    <>
      <Grid item xs={8}>
        <Typography color="textSecondary" gutterBottom>
          Event Status
          {!showCattleEvents && (
            <Typography color="error" display="inline">
              {' '}
              (not visible yet. Please zoom more to see cowg events)
            </Typography>
          )}
        </Typography>
        <FormControl component="fieldset">
          <FormGroup row style={{ width: '100%' }}>
            {allAvailableCowgStatuses.map(
              (eventStatus: CowgStatusEnum) =>
                !transitionCowgStatuses.includes(eventStatus) && (
                  <FormControlLabel
                    key={`${eventStatus}`}
                    control={
                      <Checkbox
                        checked={selectedCowGStatuses.some((type) => type === eventStatus)}
                        onChange={(e, checked) => handleEventStatusChange(eventStatus, checked)}
                        style={{ color: getCattleEventIconColor([eventStatus]) }}
                      />
                    }
                    label={<Typography>{getOptionDisplayName(CowgStatusEnum[eventStatus])}</Typography>}
                  />
                )
            )}
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <Typography color="textSecondary" gutterBottom>
          Transition Types
        </Typography>
        <FormControl component="fieldset">
          <FormGroup row>
            {transitionCowgStatuses.map((transitionStatus: CowgStatusEnum) => (
              <Grid key={`${transitionStatus}`} item xs={12}>
                <FormControlLabel
                  style={{ width: '100%' }}
                  control={
                    <Checkbox
                      checked={selectedCowGStatuses.some((type) => type === transitionStatus)}
                      onChange={(e, checked) => handleEventStatusChange(transitionStatus, checked)}
                    />
                  }
                  label={
                    <>
                      <span
                        style={{
                          marginRight: '10px',
                          lineHeight: '35px',
                          verticalAlign: 'text-bottom',
                        }}
                      >
                        {getOptionDisplayName(CowgStatusEnum[transitionStatus])}
                      </span>
                      <span>{generateEventIcon([transitionStatus])}</span>
                    </>
                  }
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <FormControlLabel
                key="OTHER_EVENTS"
                control={<Checkbox checked disabled />}
                label={
                  <>
                    <span
                      style={{
                        marginRight: '10px',
                        lineHeight: '35px',
                        verticalAlign: 'text-bottom',
                      }}
                    >
                      Others
                    </span>
                    <span>{generateEventIcon([])}</span>
                  </>
                }
              />
            </Grid>
          </FormGroup>
        </FormControl>
      </Grid>
    </>
  );
};

export default CowgEventFilter;
