import BffDebugToolService from 'services/bff-debug-tool.service';
import * as moment from 'moment';
import { IDeviceSwapRequestedDTO } from '@halter-corp/device-service-client';
import CowtrollerService from 'services/cowtroller.service';
import { Dictionary, keyBy, groupBy } from 'lodash';
import TopographyService from 'services/topography.service';
import {
  ICowtrollerDeviceDTO,
  ICowtrollerDeviceStateDTO,
  ICowtrollerGroupDTO,
  IDeviceCommandDTO,
  IDevicePayloadDTO,
  IDevicePayloadStatusEnum,
  IDevicePayloadTypeEnum,
  IDevicePayloadDTOPayload,
  DeviceCommandStatusEnum,
} from '@halter-corp/cowtroller-service-client';
import { ICattleDeviceSettingsDTO } from '@halter-corp/settings-service-client';
import { IFeatureDTO } from '@halter-corp/topography-service-client';
import {
  BuiltInTestStatus,
  CowgEventStreamDTO,
  Device,
  DeviceLocation,
  DeviceStateReport,
  DeviceStats,
  LastCommandStatus,
  PowerGeneration,
  WifiCheckinStatus,
} from 'data/device';
import { buildBuiltInTestStatusStat } from 'store/effects/device.effects/device-stats-util';
import DeviceService from './device.service';
import CattleService from './cattle.service';
import BusinessService from './business.service';
import OtaService from './ota.service';

export type SettingsDetail = {
  lastSyncedDateTime?: Date;
  status?: string; // Only available live
  settingsObject?: object;
};

export type SettingsV2Detail = SettingsDetail & {
  settingsChunksStatus?: { [key: string]: IDevicePayloadStatusEnum };
};

export interface OtaAndReleaseChannelDetails {
  channelName?: string;
  targetVersion?: string;
  retriesAttempted?: number;
  state?: string;
}

export type MapFeaturesSyncDetails = {
  status?: string;
  syncedCount: number;
  totalCount: number;
};

export type CommandSyncDetails = {
  status?: string;
  syncedCount: number;
  failedToSyncCount: number;
  syncingCount: number;
  totalCount: number;
};

/**
 * DeviceDetail
 */
export type DeviceDetail = {
  cattleName?: string;
  serialNumber: string;
  farmName?: string;
  farmId?: string;
  mobName?: string;
  mobId?: string;
  firmwareVersion?: string;
  settings?: SettingsDetail;
  settingsV2?: SettingsV2Detail;
  cowgStatus?: {
    statuses?: string[];
    controlDisabled?: boolean;
    controlDisabledReasons?: string[];
  };
  paused?: boolean;
  offPlatform?: boolean;
  inBarnMode?: boolean;
  firmwareVersionOk?: boolean;

  batteryLevenInMv?: number;
  batteryPercentage?: number;
  imuCalibrationPercentage?: number;
  faulty?: boolean;
  faultsList?: string[];
  lastResetDateTime?: Date;
  firstTaggedOnDate?: Date;
  lastTaggedOnDate?: Date;
  lastTaggedOffDate?: Date;
  commandStatus?: {
    collarCommandId?: number;
    commandType?: number;
    commandPaddockName?: string;
    lastCommandStatus?: {
      inZone?: boolean;
      outsideBoundaryBuffer?: boolean;
      leftZoneCount?: number;
      shockSummary?: {
        failedCount?: number;
        count?: number;
        lockoutCount?: number;
      };
    };
  };
  commsStatus?: {
    lastLoraMessageDateTime?: Date;
    lastWifiCheckinDateTime?: Date;
    lastHeartbeatAttemptDateTime?: Date;
    lastHeartbeatStatus?: string;
    lastHeartbeatComms?: string;
    wifiReportedGps?: {
      latitude?: number;
      longitude?: number;
    };
  };
  backendDeviceCommandHash?: string;
  collarCommands?: {
    paddockName?: string;
    status?: string;
    commandType?: number;
    collarCommandId?: number;
  }[]; // Only available live
  gps?: {
    fixType?: number;
    fixAge?: number;
    numOfSatellites?: number;
    hdop?: number;
    latitude?: number;
    longitude?: number;
    timestamp?: Date;
    satelliteCnrDbHz?: number;
  };
  deviceStateReport?: {
    commandSetHashValue?: string;
    lastUpdate?: Date;
  };
  otaStatus?: OtaAndReleaseChannelDetails;
  swapStatus?: IDeviceSwapRequestedDTO | null;
};

const getFarmName = async (farmId?: string): Promise<string | undefined> => {
  if (farmId == null) return undefined;
  const farm = await BusinessService.fetchFarmById(farmId);
  return farm?.name;
};

const getMobName = async (farmId?: string, mobId?: string): Promise<string | undefined> => {
  if (farmId == null || mobId == null) return undefined;
  const mob = await CattleService.fetchMobById(farmId, mobId);
  return mob?.name;
};

const retrieveCowtrollerDevice = async (serialNumber: string): Promise<ICowtrollerDeviceDTO> =>
  CowtrollerService.fetchDeviceCommands(serialNumber);

const retrieveCollarCommands = async (
  currentCommands: IDeviceCommandDTO[] | undefined,
  farmId?: string,
  baseDate: Date | null = null
): Promise<any[] | undefined> => {
  if (baseDate != null || farmId == null) return undefined;
  try {
    const cowtrollerGroupsByGroupId: Dictionary<ICowtrollerGroupDTO> = keyBy(
      await CowtrollerService.fetchCowtrollerGroups(farmId),
      'id'
    );
    const paddocksByPaddockId: Dictionary<IFeatureDTO> = keyBy(
      await TopographyService.fetchAllPaddocksForFarm(farmId),
      'id'
    );
    return currentCommands?.map((command) => {
      const cowtrollerGroup = cowtrollerGroupsByGroupId[command.command.groupId];
      const paddock = paddocksByPaddockId[cowtrollerGroup?.paddockId];
      return {
        paddockName: paddock?.feature?.properties?.name,
        status: command?.lastStatus?.status,
        commandType: command?.command?.type,
        collarCommandId: command?.collarCommandId,
      };
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error trying to retrieve collar commands:', e);
    return undefined;
  }
};

const retrieveOtaStatus = async (serialNumber: string): Promise<OtaAndReleaseChannelDetails | undefined> => {
  try {
    const channelName = await OtaService.fetchReleaseChannelNameForDevice(serialNumber);
    if (channelName === undefined) {
      return {};
    }
    const otaDeviceJob = await OtaService.fetchOtaDeviceJobByID(serialNumber);
    const channelInfo = await OtaService.fetchReleaseChannelDetails(channelName);
    return {
      channelName,
      targetVersion: channelInfo?.target,
      retriesAttempted: otaDeviceJob?.retryAttempt,
      state: otaDeviceJob?.status,
    };
  } catch (e) {
    return {};
  }
};

const getDevicePayloadsStatus = (devicePayloads: IDevicePayloadDTO[]) => {
  const statuses = devicePayloads.map((dp) => dp.lastStatus);
  if (statuses.some((s) => s === IDevicePayloadStatusEnum.SYNCING)) return IDevicePayloadStatusEnum.SYNCING;
  if (statuses.some((s) => s === IDevicePayloadStatusEnum.NOT_SYNCED))
    return IDevicePayloadStatusEnum.NOT_SYNCED;
  if (statuses.some((s) => s === IDevicePayloadStatusEnum.FAILED_TO_SYNC))
    return IDevicePayloadStatusEnum.FAILED_TO_SYNC;
  if (statuses.some((s) => s === IDevicePayloadStatusEnum.SYNCED)) return IDevicePayloadStatusEnum.SYNCED;

  return IDevicePayloadStatusEnum.NOT_SYNCED;
};

export const getSettingsV2Detail = (
  settingsData: ICattleDeviceSettingsDTO | undefined,
  devicePayloads: IDevicePayloadDTO[]
): SettingsV2Detail | undefined => {
  if (settingsData == null || settingsData.settings == null) {
    return undefined;
  }

  const settingsPayloads = devicePayloads.filter((dp) => dp.payloadType === IDevicePayloadTypeEnum.SETTINGS);

  const settingsChunksStatus = settingsPayloads
    .map((s) => {
      const settingsPayload: IDevicePayloadDTOPayload | undefined = s.payload;
      if (!settingsPayload) return undefined;
      return { [settingsPayload.type]: s.lastStatus };
    })
    .reduce((a, c) => ({ ...a, ...c }), {});

  const status = getDevicePayloadsStatus(settingsPayloads);

  return {
    settingsObject: settingsData.settings,
    status,
    settingsChunksStatus,
  };
};

const retrieveSettingsV2 = async (
  farmId?: string,
  serialNumber?: string,
  baseDate: Date | null = null
): Promise<SettingsV2Detail | undefined> => {
  if (farmId == null || serialNumber == null) return undefined;
  if (baseDate != null) {
    const settingsV2FromHistory = await BffDebugToolService.getLastSettingsV2ForDevice(
      serialNumber,
      baseDate
    );
    return {
      settingsObject: settingsV2FromHistory?.data?.payload?.settingsEvent?.data?.settings,
    };
  }
  const devicePayloads = await CowtrollerService.fetchDevicePayload(serialNumber);
  const cattleDeviceSettings = await DeviceService.fetchSettingsV2(farmId, [serialNumber]);
  const cattleDeviceSettingsBySerialNumber = cattleDeviceSettings.find(
    (s) => s.serialNumber === serialNumber
  );
  return getSettingsV2Detail(cattleDeviceSettingsBySerialNumber, devicePayloads);
};

export const getMapFeaturesDetail = (
  devicePayloads: IDevicePayloadDTO[]
): MapFeaturesSyncDetails | undefined => {
  const mapFeaturePayloads = devicePayloads.filter(
    (dp) => dp.payloadType === IDevicePayloadTypeEnum.MAP_FEATURE
  );
  if (mapFeaturePayloads.length === 0) return undefined;

  const syncedCount = mapFeaturePayloads.filter(
    (dp) => dp.lastStatus === IDevicePayloadStatusEnum.SYNCED
  ).length;
  const status = getDevicePayloadsStatus(mapFeaturePayloads);

  return { status, syncedCount, totalCount: mapFeaturePayloads.length };
};

export const getCommandsDetails = (
  deviceCommands?: ICowtrollerDeviceStateDTO
): CommandSyncDetails | undefined => {
  if (deviceCommands == null) return undefined;
  const deviceCommandsPerStatus = groupBy(deviceCommands?.currentCommands, ({ status }) => status);
  const notSynced = deviceCommandsPerStatus[DeviceCommandStatusEnum.NOT_SYNCED]?.length || 0;
  const failedToSyncCount = deviceCommandsPerStatus[DeviceCommandStatusEnum.FAILED_TO_SYNC]?.length || 0;
  const syncingCount = notSynced + deviceCommandsPerStatus[DeviceCommandStatusEnum.SYNCING]?.length || 0;
  const totalCount = deviceCommands?.currentCommands.length ?? 0;
  let status = 'SYNCED';
  if (failedToSyncCount > 0) status = 'FAILED_TO_SYNC';
  if (syncingCount > 0) status = 'SYNCING';
  return {
    syncingCount,
    totalCount,
    failedToSyncCount,
    status,
    syncedCount: totalCount - notSynced - failedToSyncCount - syncingCount,
  };
};

const calculateLastResetDateTime = (uptime?: number, metricTime?: Date): Date | undefined => {
  if (uptime == null) return undefined;
  return moment
    .utc(metricTime || new Date())
    .subtract(uptime, 'seconds')
    .toDate();
};

export const fetchDeviceDetailsData = async (
  device: Device,
  baseDate: Date | null = null
): Promise<DeviceDetail> => {
  const { serialNumber, firstTaggedOnDate, lastTaggedOnDate, lastTaggedOffDate } = device;
  let { farmId, mobId, cattleName } = device;
  const tStart = new Date();
  const isLive = baseDate == null;
  let cowgEvent: CowgEventStreamDTO | undefined;
  let powerGeneration: PowerGeneration | undefined;
  let lastCommandStatus: LastCommandStatus | undefined;
  let location: DeviceLocation | undefined;
  let builtInTestStatusStat: BuiltInTestStatus | undefined;
  let deviceStateReport: DeviceStateReport | undefined;
  let collarCommandId: number | undefined;
  let wifiCheckInTimestamp: Date;
  let loraCheckinTimestamp: Date;
  let wifiCheckin: WifiCheckinStatus | undefined;

  let inBarnMode: boolean | undefined;
  let offPlatform: boolean | undefined;
  let paused: boolean | undefined;
  let firmwareVersionOk: boolean | undefined;

  if (isLive) {
    const [deviceStats]: (undefined | DeviceStats)[] = await Promise.all([
      DeviceService.getDeviceStats(serialNumber),
    ]);
    inBarnMode = device.status.inBarn;
    offPlatform = device.status.offPlatform;
    cowgEvent = deviceStats?.cowgEventStream;
    firmwareVersionOk = device.status.firmwareVersionOk;
    powerGeneration = deviceStats?.powerGeneration;
    lastCommandStatus = deviceStats?.lastCommandStatus;
    location = deviceStats?.deviceLocation;
    builtInTestStatusStat = deviceStats?.builtInTestStatus;
    collarCommandId = lastCommandStatus?.commandId;
    deviceStateReport = deviceStats?.deviceStateReport;
    wifiCheckInTimestamp = deviceStats?.wifiCheckinStatus?.lastUpdate || new Date(0);
    loraCheckinTimestamp = deviceStats?.loraCheckinStatus?.lastUpdate || new Date(0);
    wifiCheckin = deviceStats?.wifiCheckinStatus;
    paused = device.cattle?.paused;
  } else {
    const lookupDate = baseDate!;
    const [
      deviceUpdateSr,
      lastCommandStatusSr,
      locationSr,
      loraCheckinSr,
      wifiCheckinSr,
      cowgEventSr,
      powerGenerationSr,
      builtInTestStatusSr,
      deviceStateReportSr,
    ] = await Promise.all([
      BffDebugToolService.getLastDeviceUpdateForDevice(serialNumber, lookupDate),
      BffDebugToolService.getLastCommandStatusForDevice(serialNumber, lookupDate),
      BffDebugToolService.getLastDeviceLocationForDevice(serialNumber, lookupDate),
      BffDebugToolService.getLastLoraCheckinForDevice(serialNumber, lookupDate),
      BffDebugToolService.getLastWifiCheckinForDevice(serialNumber, lookupDate),
      BffDebugToolService.getLastCowgEventForDevice(serialNumber, lookupDate),
      BffDebugToolService.getLastPowerGenerationForDevice(serialNumber, lookupDate),
      BffDebugToolService.getLastBuiltInTestStatusForDevice(serialNumber, lookupDate),
      BffDebugToolService.getLastDeviceStateReportForDevice(serialNumber, lookupDate),
    ]);
    farmId = deviceUpdateSr?.data?.farmId;
    mobId = deviceUpdateSr?.data?.payload?.mob?.id;
    cattleName = deviceUpdateSr?.data?.payload?.cattle?.name;
    paused = deviceUpdateSr?.data?.payload?.device?.status?.disabled;
    wifiCheckInTimestamp = wifiCheckinSr?.timestamp ?? new Date(0);
    loraCheckinTimestamp = loraCheckinSr?.timestamp ?? new Date(0);

    powerGeneration = powerGenerationSr?.data?.metric?.powerGeneration;
    location = locationSr?.data?.metric?.position;
    if (location != null) {
      location.timestamp = locationSr?.timestamp;
    }
    deviceStateReport = deviceStateReportSr?.data?.metric?.deviceStateReport;
    cowgEvent = cowgEventSr?.data?.metric?.cowgEventStream;
    wifiCheckin = wifiCheckinSr?.data?.metric?.wifiCheckin;
    builtInTestStatusStat = buildBuiltInTestStatusStat(builtInTestStatusSr);
    lastCommandStatus = lastCommandStatusSr?.data?.metric?.lastCommandStatus;
    deviceStateReport = deviceStateReportSr?.data?.metric?.deviceStateReport;
  }
  const tEnd = new Date();

  // eslint-disable-next-line no-console
  console.log(`fetchDeviceDetailsData took ${tEnd.getTime() - tStart.getTime()}ms ${baseDate}`);
  const [farmName, mobName, cowtrollerDevice, settingsV2, otaStatus] = await Promise.all([
    getFarmName(farmId),
    getMobName(farmId, mobId),
    retrieveCowtrollerDevice(serialNumber),
    retrieveSettingsV2(farmId, serialNumber, baseDate),
    retrieveOtaStatus(serialNumber),
    // BffDebugToolService.getLastHeartbeatAttemptForDevice(serialNumber, baseDate ?? new Date()),
  ]);

  const collarCommands = await retrieveCollarCommands(cowtrollerDevice?.currentCommands, farmId, baseDate);
  const commandPaddockName = collarCommands?.find(
    (command) => command.collarCommandId === collarCommandId
  )?.paddockName;

  let latitude = location?.latitude;
  let longitude = location?.longitude;
  let fixType = location?.fixType;
  let fixAge = location?.fixAge;
  let numOfSatellites = location?.numSatellites;
  let hdop = location?.hdop;
  let locationTimestamp: Date | undefined = location?.timestamp;
  const firmwareVersion = device?.firmwareVersion;

  if (wifiCheckInTimestamp > loraCheckinTimestamp) {
    latitude = wifiCheckin?.latitude;
    longitude = wifiCheckin?.longitude;
    fixType = 0;
    fixAge = 0;
    numOfSatellites = 0;
    hdop = 0;
    locationTimestamp = wifiCheckInTimestamp;
  }

  const gps = {
    fixType,
    fixAge,
    numOfSatellites,
    hdop,
    latitude,
    longitude,
    timestamp: locationTimestamp,
    satelliteCnrDbHz: location?.satelliteCnrDbHz,
  };

  const details: DeviceDetail = {
    serialNumber,
    cattleName,
    farmName,
    farmId,
    mobName,
    mobId,
    firmwareVersion,
    settingsV2,
    cowgStatus: {
      statuses: cowgEvent?.cowgStatus,
      controlDisabled: lastCommandStatus?.zoneStatusContext?.actuationMetrics?.controlDisabled,
      controlDisabledReasons: lastCommandStatus?.controlDisabledReasons,
    },
    paused,
    batteryLevenInMv: powerGeneration?.batteryVoltageMv,
    batteryPercentage: powerGeneration?.batterySocPercent,
    imuCalibrationPercentage: builtInTestStatusStat?.magCalPercentage,
    faulty: builtInTestStatusStat?.isFaulty,
    faultsList: builtInTestStatusStat?.faults,
    lastResetDateTime: calculateLastResetDateTime(powerGeneration?.uptime),
    firstTaggedOnDate,
    lastTaggedOnDate,
    lastTaggedOffDate,
    offPlatform,
    firmwareVersionOk,
    inBarnMode,

    commandStatus: {
      collarCommandId,
      commandPaddockName,
      commandType: lastCommandStatus?.commandType,
      lastCommandStatus: {
        inZone: lastCommandStatus?.zoneStatusContext?.inZone,
        outsideBoundaryBuffer:
          lastCommandStatus?.zoneStatusContext?.actuationMetrics?.isOutsideBoundaryBuffer,
        leftZoneCount: lastCommandStatus?.zoneStatusContext?.actuationMetrics?.leftZoneCount,
        shockSummary: {
          count: lastCommandStatus?.zoneStatusContext?.actuationMetrics?.shockSummary?.count,
          failedCount: lastCommandStatus?.zoneStatusContext?.actuationMetrics?.shockSummary?.failedCount,
          lockoutCount: lastCommandStatus?.zoneStatusContext?.actuationMetrics?.shockSummary?.lockoutCount,
        },
      },
    },
    commsStatus: {
      lastLoraMessageDateTime: loraCheckinTimestamp,
      lastWifiCheckinDateTime: wifiCheckInTimestamp,
      // lastHeartbeatAttemptDateTime:
      //   lastHeartbeatAttempt?.data?.payload?.deviceRequestResponseEvent?.schedulingDatetime,
      // lastHeartbeatStatus: lastHeartbeatAttempt?.data?.payload?.deviceRequestResponseEvent?.responseCode,
      // lastHeartbeatComms: lastHeartbeatAttempt?.data?.payload?.deviceRequestResponseEvent?.commnsType,
      wifiReportedGps: {
        latitude: wifiCheckin?.latitude,
        longitude: wifiCheckin?.longitude,
      },
    },
    gps,
    deviceStateReport: {
      commandSetHashValue: (deviceStateReport?.commandHash ?? 0).toString(),
      lastUpdate: deviceStateReport?.lastUpdate,
    },
    backendDeviceCommandHash: cowtrollerDevice?.deviceHashState?.commandHash,
    collarCommands,
    otaStatus,
    swapStatus: device.status.swapRequested,
  };

  return details;
};
