import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { AppState } from 'store';
import { ICommandDTO } from '@halter-corp/cowtroller-service-client';
import { findCattleLocationPaths } from './cattle-location.selector';
import { findAllCommands, findAllCowgEvents } from './map-page-common.selector';

/**
 * Map component selectors
 */
export const findCattleLocationPathsForDateRangeAndSelectedCows = createSelector(
  findCattleLocationPaths,
  (_state: AppState, props: { minimumDate: Date | null }) => props.minimumDate?.getTime(),
  (_state: AppState, props: { maximumDate: Date | null }) => props.maximumDate?.getTime(),
  (_state: AppState, props: { selectedCows: string[] | null }) => props.selectedCows,
  (locationPaths, minTimestamp, maxTimestamp, selectedCows) => {
    const keyedCowNames = keyBy(selectedCows ?? [], (cow) => cow);
    if (minTimestamp == null || maxTimestamp == null) return locationPaths;

    return locationPaths
      .filter((locationPath) => selectedCows == null || keyedCowNames[locationPath.cattleName] != null)
      .map((locationPath) => {
        const { locations } = locationPath;
        const minIndex = locations.findIndex((location) => location.timestamp > minTimestamp);
        const maxIndex = locations.slice(minIndex).findIndex((location) => location.timestamp > maxTimestamp);
        const filteredLocations = locations.slice(minIndex, maxIndex < 0 ? undefined : minIndex + maxIndex);
        return {
          cattleName: locationPath.cattleName,
          locations: filteredLocations,
        };
      });
  }
);

const findCowgEventsForDateRangeAndSelectedCows = createSelector(
  findAllCowgEvents,
  (_state: AppState, props: { minimumDate: Date | null }) => props.minimumDate?.getTime(),
  (_state: AppState, props: { maximumDate: Date | null }) => props.maximumDate?.getTime(),
  (_state: AppState, props: { selectedCows: string[] | null }) => props.selectedCows,
  (cowgEvents, minTimestamp, maxTimestamp, selectedCows) => {
    const keyedCowNames = keyBy(selectedCows ?? [], (cow) => cow);
    if (minTimestamp == null || maxTimestamp == null) return cowgEvents;

    const minDate = new Date(minTimestamp).toISOString();
    const maxDate = new Date(maxTimestamp).toISOString();

    const cowgEventsForCows = cowgEvents.filter(
      (cowgEvent) => selectedCows == null || keyedCowNames[cowgEvent.cattleName] != null
    );

    const minIndex = cowgEventsForCows.findIndex((cowgEvent) => cowgEvent.timestamp > minDate);
    const maxIndex = cowgEventsForCows
      .slice(minIndex)
      .findIndex((cowgEvent) => cowgEvent.timestamp > maxDate);
    return cowgEventsForCows.slice(minIndex, maxIndex < 0 ? undefined : minIndex + maxIndex);
  }
);

export const commandsForDateRangeAndSelectedCows = createSelector(
  findCowgEventsForDateRangeAndSelectedCows,
  findAllCommands,
  (cowgEvents, commands) => {
    const commandsByCollarCommandId = keyBy(commands, (command) => command.collarCommandId.toString());
    const commandsToDisplay: { [groupId: string]: ICommandDTO } = {};
    cowgEvents.forEach((cowgEvent) => {
      const command = commandsByCollarCommandId[cowgEvent.collarCommandId];
      if (command == null) {
        return;
      }
      const existingCommandsForGroup = commandsToDisplay[command.groupId];
      if (existingCommandsForGroup?.id !== command.id) {
        commandsToDisplay[command.groupId] = command;
        
      }
    });
    return Object.values(commandsToDisplay);
  }
);
