import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Card, Grid, LinearProgress, TableContainer } from '@material-ui/core';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { useDispatch, useSelector } from 'store';
import trainingEffects from 'store/effects/training.effects';
import TrainingService from 'services/training.service';

import ScreenWrapper from 'application/components/screen-wrapper';
import Wrapper from 'application/components/wrapper';
import Button from 'application/components/button';
import Checkbox from 'application/components/checkbox';
import { useParseQueryParameters } from 'application/hooks/use-parameters';

import FarmSelect from 'application/components/farm-select';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import { CaddieColours } from 'application/theme/colours';
import { isMobile } from 'react-device-detect';
import FarmerGuidePanel from '../components/farmer-guide-panel';
import AppliedModifiersable from '../components/applied-modifiers-table';
import TrainingSearchPanel from '../components/training-search-panel';
import AppliedProfilesTable from '../components/applied-profiles-table';

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  opacity: 0.75;
`;

const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  opacity: 0.7;
`;

const HorizontalStickyContainer = styled.div`
  position: sticky;
  left: 0;
  display: flex;
  flex: 1;
  justify-content: center;
`;

const TrainingScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const farmId = useSelector(getCurrentFarm);
  const { mobId, cattleNames } = useParseQueryParameters();
  const loading = TrainingService.useIsLoading();

  const [showLoading, setShowLoading] = useState(loading);
  useEffect(() => {
    if (loading) setShowLoading(loading);
    else setTimeout(() => setShowLoading(loading), 500);
  }, [loading]);

  const [includeUntaggedCattle, setIncludeUntaggedCattle] = useState(false);
  const shouldShowTable = useMemo(() => !isEmpty(location.search), [location.search]);

  useEffect(() => {
    dispatch(trainingEffects.fetchAppliedProfiles({ farmId }));
    dispatch(trainingEffects.fetchAppliedModifiers({ farmId, mobId, cattleNames }));
    dispatch(trainingEffects.fetchFarmerTrainingGuides(farmId));
    dispatch(trainingEffects.fetchFarmerTrainingGuideOptions(farmId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmId, mobId, cattleNames]);

  return (
    <>
      <LinearProgress variant={showLoading ? 'query' : 'determinate'} value={100} />
      <ScreenWrapper
        style={{
          position: 'relative',
          height: 'calc(100vh - 44px)',
          backgroundColor: CaddieColours.LightGrey,
        }}
      >
        <TableContainer style={{ margin: '8px' }}>
          <HorizontalStickyContainer>
            <Grid item xs={12} sm={10} lg={8}>
              <Wrapper style={{ flex: 1, flexDirection: 'row', alignItems: 'center', padding: '16px 0px' }}>
                <FarmSelect />
              </Wrapper>
              <FarmerGuidePanel farmId={farmId} />
              <Card style={{ marginTop: '16px', borderRadius: '8px' }}>
                <Wrapper
                  style={{
                    flex: 1,
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: 'center',
                    padding: '16px 40px',
                  }}
                >
                  <Title>Training</Title>
                  <Wrapper
                    style={{ flexDirection: 'row', marginLeft: isMobile ? '' : 'auto' }}
                    itemContainerStyle={{ margin: 8 }}
                  >
                    <Button
                      text="View profiles and modifiers"
                      onClick={() => history.push('/training/profiles')}
                      size="large"
                      backgroundColour="black"
                      colour="white"
                    />
                    <Button
                      text="Apply Profiles"
                      onClick={() => history.push('/training/apply-profiles')}
                      size="large"
                      backgroundColour="black"
                      colour="white"
                    />
                    <Button
                      text="Apply Modifiers"
                      onClick={() => history.push('/training/apply-modifiers')}
                      size="large"
                      backgroundColour="black"
                      colour="white"
                    />
                  </Wrapper>
                </Wrapper>
              </Card>
              <Wrapper style={{ flex: 1, padding: 16, borderTop: '1px solid #c5c5c5' }}>
                <SubTitle>Applied profiles:</SubTitle>
                <AppliedProfilesTable />
              </Wrapper>
              <Wrapper style={{ flex: 1, padding: 16, borderTop: '1px solid #c5c5c5' }}>
                <SubTitle>Search applied modifiers:</SubTitle>
                <TrainingSearchPanel farmId={farmId} mobId={mobId} cattleNames={cattleNames} />
              </Wrapper>
              {shouldShowTable && mobId == null && (
                <Checkbox
                  label="Include untagged cows"
                  checked={includeUntaggedCattle}
                  onChangeValue={setIncludeUntaggedCattle}
                  containerStyle={{ padding: '0px 16px 0px 16px' }}
                />
              )}
            </Grid>
          </HorizontalStickyContainer>
          {shouldShowTable && (
            <Box m={1}>
              <AppliedModifiersable includeUntaggedCattle={includeUntaggedCattle} />
            </Box>
          )}
        </TableContainer>
      </ScreenWrapper>
    </>
  );
};

export default TrainingScreen;
