import { useSelector } from 'store';
import { selectMobs, getCurrentMob, selectAllFarmsMobs } from 'store/selectors/mob.selectors';

const MobService = {
  useFetchMobList: () => useSelector(selectMobs),

  useSelectCurrentMob: () => useSelector(getCurrentMob),

  useGetAllMobs: () => useSelector(selectAllFarmsMobs),
};

export default MobService;
