import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useShouldDisplayTable } from 'application/hooks/use-searched-data-table';
import farmInfraEffects from 'store/effects/farm-infra.effects';
import { selectTowers as selectInfrastructureTowers } from 'store/selectors/infrastructure.selectors';
import {
  selectTowers,
  selectDeviceContext,
  selectDevicesWithMetadata,
  selectTowersWithDevicesAndNotes,
  selectGatewayIds,
  DeviceStatusEnum,
  selectNetworkDeviceContext,
} from 'store/selectors/tower.selectors';
import towerEffects from 'store/effects/tower.effects';
import towerSlice from 'store/slices/tower.slice';
import { ProductTypeEnum } from 'application/utils/infrastructureUtils';
import infrastructureEffects from 'store/effects/infrastructure.effects';
import TowersOverviewScreen from '../screens/towers-overview.screen';
import { useParseQueryParameters } from '../hooks/use-parameters';

const TowersOverviewContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const urlQueryString = new URLSearchParams(location.search);
  const searchMode: 'farm' | 'shard' = urlQueryString.get('mode') === 'shard' ? 'shard' : 'farm';
  const { farmId, shardId } = useParseQueryParameters();
  const shouldDisplayTable = useShouldDisplayTable();
  const devices = useSelector(selectDeviceContext);
  const networkDevices = useSelector(selectNetworkDeviceContext);
  const gatewayIds = useSelector(selectGatewayIds);
  const devicesWithMetadata = useSelector(selectDevicesWithMetadata);
  const towers = useSelector(selectTowers);
  const infrastructureTowers = useSelector(selectInfrastructureTowers);
  const towersWithDevicesAndNotes = useSelector(selectTowersWithDevicesAndNotes);

  const [autoRefresh, setAutoRefresh] = useState(false);
  const [enabledOnly, setEnabledOnly] = useState(false);
  const [disabledOnly, setDisabledOnly] = useState(false);
  const [selectedDeviceStatusType, setSelectedDeviceStatusType] = useState<DeviceStatusEnum | 'all'>('all');
  const [selectedDeviceType, setSelectedDeviceType] = useState<ProductTypeEnum | string>('all');
  const [activeIntervalId, setActiveIntervalId] = useState<number | undefined>(undefined);

  const filteredDevicesByStatus = useMemo(() => {
    if (selectedDeviceStatusType === 'all') {
      return devicesWithMetadata;
    }
    if (selectedDeviceStatusType === DeviceStatusEnum.ON_OUTAGE) {
      return devicesWithMetadata.filter((device) => device.status?.onOutage);
    }
    if (selectedDeviceStatusType === DeviceStatusEnum.FORCE_OFF) {
      return devicesWithMetadata.filter((device) => device.settings?.forceOff);
    }
    if (selectedDeviceStatusType === DeviceStatusEnum.FORCE_ROUND_ROBIN) {
      return devicesWithMetadata.filter((device) => device.settings?.forceRoundRobin);
    }
    if (selectedDeviceStatusType === DeviceStatusEnum.SUSPENDED) {
      return devicesWithMetadata.filter((device) => device.status?.suspended);
    }
    return devicesWithMetadata;
  }, [selectedDeviceStatusType, devicesWithMetadata]);

  const filteredDevices = useMemo(() => {
    if (selectedDeviceType === 'all') {
      return filteredDevicesByStatus;
    }
    return filteredDevicesByStatus.filter((device) => device.type === selectedDeviceType);
  }, [selectedDeviceType, filteredDevicesByStatus]);

  const selectedevices = useMemo(() => {
    if (enabledOnly) return filteredDevices.filter((device) => device.status?.enabled === true);
    if (disabledOnly) return filteredDevices.filter((device) => device.status?.enabled === false);
    return filteredDevices;
  }, [enabledOnly, disabledOnly, filteredDevices]);

  const handleEnabledOnlyChange = (enabledOnlyValue: boolean) => {
    setDisabledOnly(false);
    setEnabledOnly(enabledOnlyValue);
  };

  const handleDisabledOnlyChange = (disabledOnlyValue: boolean) => {
    setEnabledOnly(false);
    setDisabledOnly(disabledOnlyValue);
  };

  const handleSelectedDeviceStatusChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (typeof event.target.value === 'string') {
      const status = event.target.value;
      switch (status) {
        case DeviceStatusEnum.ON_OUTAGE:
          return setSelectedDeviceStatusType(status);
        case DeviceStatusEnum.FORCE_OFF:
          return setSelectedDeviceStatusType(status);
        case DeviceStatusEnum.FORCE_ROUND_ROBIN:
          return setSelectedDeviceStatusType(status);
        case DeviceStatusEnum.SUSPENDED:
          return setSelectedDeviceStatusType(status);
        default:
      }
    }
    return setSelectedDeviceStatusType('all');
  };

  const handleSelectedDeviceTypeChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (typeof event.target.value === 'string') {
      setSelectedDeviceType(event.target.value);
    }
  };

  useEffect(() => {
    dispatch(farmInfraEffects.fetchFarms());
    dispatch(farmInfraEffects.fetchShards());
  }, [dispatch]);

  useEffect(() => {
    if (!farmId) return;
    dispatch(farmInfraEffects.fetchFarmMetadataById({ farmId }));
    dispatch(towerEffects.fetchTowersByFarmId({ farmId }));
    dispatch(infrastructureEffects.fetchTowersByFarmId({ farmId }));
  }, [dispatch, farmId]);

  useEffect(() => {
    if (!towers) return;
    dispatch(towerEffects.fetchNotesByTowers({ towers }));
  }, [dispatch, towers]);

  useEffect(() => {
    dispatch(towerSlice.actions.resetDevices());
    if (searchMode === 'farm' && farmId) {
      dispatch(
        towerEffects.fetchAllDeviceContextByFarmAndTowers({
          farmId,
          towerIds: towers.map((tower) => tower.id),
        })
      );
    }
    if (searchMode === 'shard' && shardId) {
      dispatch(towerEffects.fetchAllDeviceContextByShardId({ shardId }));
    }
  }, [dispatch, farmId, towers, shardId, shouldDisplayTable, searchMode]);

  useEffect(() => {
    dispatch(
      towerEffects.fetchAllNetworkDeviceContextByTowerIdList({
        towerIdList: towers.map((tower) => tower.id),
      })
    );
    dispatch(
      infrastructureEffects.fetchAllInfraProductContextsByTowerIdList({
        towerIdList: infrastructureTowers.map((tower) => tower.id),
      })
    );
  }, [dispatch, towers, infrastructureTowers, shouldDisplayTable, searchMode]);

  useEffect(() => {
    const totalDevices = [...devices];
    totalDevices.push(...networkDevices.map((networkDevice) => ({ ...networkDevice, farmId: '' })));
    dispatch(towerEffects.fetchDeviceStatusByDevices({ devices: totalDevices }));
    dispatch(
      towerEffects.fetchDeviceSettingsByIds({
        // Note: we filter by empty farm id here since network devices do not have a farm id.
        ids: totalDevices.filter((el) => el.farmId !== '').map((el) => el.id),
      })
    );
  }, [devices, networkDevices]);

  useEffect(() => {
    dispatch(towerEffects.fetchSelectionByGatewayIds({ gatewayIds }));
  }, [gatewayIds]);

  useEffect(() => {
    if (autoRefresh) {
      const intervalId = window.setInterval(() => {
        const totalDevices = [...devices];
        totalDevices.push(...networkDevices.map((networkDevice) => ({ ...networkDevice, farmId: '' })));
        dispatch(towerEffects.fetchDeviceStatusByDevices({ devices: totalDevices }));
      }, 30_000);

      setActiveIntervalId(intervalId);
    } else if (activeIntervalId != null) {
      clearInterval(activeIntervalId);
    }

    return () => clearInterval(activeIntervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefresh]);

  return (
    <TowersOverviewScreen
      searchMode={searchMode}
      farmId={farmId}
      shardId={shardId}
      autoRefresh={autoRefresh}
      enabledOnly={enabledOnly}
      disabledOnly={disabledOnly}
      onChangeAutoRefresh={setAutoRefresh}
      onChangeEnabledOnly={handleEnabledOnlyChange}
      onChangeDisabledOnly={handleDisabledOnlyChange}
      onChangeSelectedDeviceType={handleSelectedDeviceTypeChange}
      onChangeSelectedDeviceStatus={handleSelectedDeviceStatusChange}
      towers={towersWithDevicesAndNotes}
      devices={selectedevices}
      displayTable={shouldDisplayTable}
    />
  );
};

export default TowersOverviewContainer;
