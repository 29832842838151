import { IDeviceOtaJobDTO, IOtaDeviceContextDTO } from '@halter-corp/ota-service-client';
import { AxiosError } from 'axios';

import { IReleaseChannelDTO, IUpdateOtaDeviceContextRequestDTO } from '@halter-corp/ota-service-client/api';
import HttpApiService from './http-api.service';

const OtaService = {
  fetchReleaseChannelNameForDevice: async (serialNumber: string): Promise<string | undefined> => {
    const api = await HttpApiService.getOtaDeviceApi();
    try {
      const { data } = await api.getOtaDeviceContextByID(serialNumber);
      if (data !== undefined) {
        return data.releaseChannelId;
      }
      return undefined;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },

  fetchReleaseChannelDetails: async (releaseChannelName: string): Promise<IReleaseChannelDTO | undefined> => {
    const api = await HttpApiService.getOtaReleaseChannelApi();
    try {
      const { data } = await api.getReleaseChannelByID(releaseChannelName);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },

  fetchOtaDeviceJobByID: async (id: string): Promise<IDeviceOtaJobDTO | undefined> => {
    const api = await HttpApiService.getOtaJobApi();
    try {
      const { data } = await api.getOtaJobByDeviceID(id);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },

  updateReleaseChannelForThing: async (
    serialNumber: string,
    releaseChannelId: string
  ): Promise<IOtaDeviceContextDTO | undefined> => {
    const api = await HttpApiService.getOtaDeviceApi();
    const updateOtaDeviceContextRequest: IUpdateOtaDeviceContextRequestDTO = {
      releaseChannelId,
    };
    try {
      const { data } = await api.updateOtaDeviceContextByID(serialNumber, updateOtaDeviceContextRequest);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },
};

export default OtaService;
