import { createSlice } from '@reduxjs/toolkit';
import { IDeviceDTO } from '@halter-corp/device-service-client';
import deviceEffects from '../effects/device.effects';
import { reset } from '../actions';

type DevicesState = {
  devices: IDeviceDTO[];
  devicesStatus: 'loading' | 'error' | 'success';
};

const initialState: DevicesState = {
  devices: [],
  devicesStatus: 'success',
};

const devicesSlice = createSlice({
  initialState,
  name: 'devices',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(deviceEffects.fetchDevices.fulfilled, (state, action) => {
        state.devices = action.payload;
        state.devicesStatus = 'success';
      })
      .addCase(deviceEffects.fetchDevices.rejected, (state) => {
        state.devicesStatus = 'error';
      })
      .addCase(deviceEffects.fetchDevices.pending, (state) => {
        state.devicesStatus = 'loading';
      }),
});

export default devicesSlice;
