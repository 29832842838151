import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Divider,
  Grid,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from 'application/components/button';
import { useSelector } from 'store';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import CattleService from 'services/cattle.service';
import { DateSourceEnum, ICalvingDTO, ICreateCalvingDTO } from '@halter-corp/cattle-breeding-service-client';
import { groupBy } from 'lodash';
import DataTable from 'application/components/data-table';
import { dateToText } from 'application/modules/fleet-watch/utils';
import moment from 'moment-timezone';
import BehaviourService from 'services/behaviour.service';
import styled from 'styled-components';
import { CaddieColours } from '../../../theme/colours';

const Papa = require('papaparse');

type Option = {
  label: string;
  value: string;
};

type CalvingDates = {
  earTag: string;
  calvingDate: string;
};

type PreviewCreateCalvingDTO = {
  earTag: string;
  calvedAt: Date | undefined;
  cattleId: string | undefined;
};

const uploadOptions = [{ label: 'Calving dates', value: 'calvingDates' }];

interface InstructionListProps {
  instructions: string[];
}

const dateTimeFormatOptions: Option[] = [
  { label: '01 Nov 2023', value: 'DD MMM YYYY' },
  { label: '1 Nov 23', value: 'D MMM YY' },
  { label: '1-Nov-23', value: 'D-MMM-YY' },
  { label: '01-Nov-23', value: 'DD-MMM-YY' },
  { label: '1-Nov', value: 'D-MMM' },
  { label: '01-Nov', value: 'DD-MMM' },
  { label: '1/11/2023', value: 'D/MM/YYYY' },
  { label: '01-11-2023', value: 'DD-MM-YYYY' },
  { label: '11/1/2023', value: 'MM/D/YYYY' },
  { label: '11/01/2023', value: 'MM/DD/YYYY' },
  { label: '2023-11-1', value: 'YYYY-MM-D' },
  { label: '2023-11-01', value: 'YYYY-MM-DD' },
  { label: '1/11/23', value: 'D/MM/YY' },
  { label: '01/11/23', value: 'DD/MM/YY' },
];

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;

const CSVImporter: React.FC = () => {
  const cattle = CattleService.useSelectCattle();
  const [uploadOption, setUploadOption] = useState<string>(uploadOptions[0].value);
  const [tableData, setTableData] = useState<CalvingDates[]>([]);
  const [previewCalvingDTO, setPreviewCalvingDTO] = useState<PreviewCreateCalvingDTO[]>([]);
  const [dateTimeFormat, setDateTimeFormat] = useState(dateTimeFormatOptions[0].value);
  const currentFarm = useSelector(getCurrentFarm);
  const [uploadRunning, setUploadIsRunning] = useState(false);
  const [uploadedCalvingDates, setUploadedCalvingDates] = useState<(ICalvingDTO | undefined)[]>([]);
  const scaryInstructionText = 'PLEASE MAKE SURE YOU ARE ON THE CORRECT FARM';
  const instructionText = [
    'Step 1: Make sure the columns with ear tag and calving date are named as such. eg. [earTag, calvingDate]',
    'Step 2: Choose your file',
    'Step 3: Preview the dates and select the correct date format',
    'Step 4: Preview the import to check the data looks OK',
    'Step 5: Upload your calving dates!',
  ];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableData([]);
    setPreviewCalvingDTO([]);
    setUploadedCalvingDates([]);
    const { files } = event.target;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      try {
        Papa.parse(selectedFile, {
          header: true,
          skipEmptyLines: true,
          complete: (results: { data: any; meta: { fields: any } }) => {
            setTableData(results.data);
          },
        });
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  };

  const handleOptionChange = (event: React.ChangeEvent<{}>, value: Option | null) => {
    if (value) {
      setUploadOption(value.value);
    }
  };

  const handleSelectDateTimeFormat = (event: React.ChangeEvent<{}>, value: Option | null) => {
    if (value) {
      setDateTimeFormat(value.value);
    }
  };

  const InstructionList: FunctionComponent<InstructionListProps> = ({ instructions }) => (
    <List style={{ backgroundColor: CaddieColours.DarkGrey, color: 'black', borderRadius: '4px' }}>
      {instructions.map((instruction: string, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <ListItem key={index}>
          <ListItemText primary={instruction} />
        </ListItem>
      ))}
    </List>
  );

  const getDataToPreview = async () => {
    const cattleDTOSByName = groupBy(cattle, (cattleDTO) => cattleDTO.name);
    const data = tableData.map((row) => {
      const { earTag, calvingDate } = row;
      const cattleDTO = cattleDTOSByName[earTag];
      const calvedAtMoment = moment(calvingDate, dateTimeFormat).tz('Pacific/Auckland');
      return {
        earTag,
        calvedAt: calvedAtMoment.isValid() ? calvedAtMoment.toDate() : undefined,
        cattleId: cattleDTO?.length > 0 ? cattleDTO[0].id : undefined,
        source: DateSourceEnum.OTHER,
      };
    });
    setPreviewCalvingDTO(data);
  };

  const uploadData = async () => {
    setUploadIsRunning(true);
    const uploadedResponses: (ICalvingDTO | undefined)[] = await Promise.all(
      previewCalvingDTO.flatMap(async (calvingDTO) => {
        if (calvingDTO.cattleId !== undefined && calvingDTO.calvedAt !== undefined) {
          const createCalvingDTO: ICreateCalvingDTO = {
            cattleId: calvingDTO.cattleId,
            calvedAt: calvingDTO.calvedAt,
          };

          const response = await BehaviourService.uploadCalvingDates(createCalvingDTO, currentFarm);
          return response;
        }
        return undefined;
      })
    );
    setUploadedCalvingDates(uploadedResponses.filter((item) => item !== undefined));
    setUploadIsRunning(false);
  };

  const resetData = async () => {
    setTableData([]);
    setPreviewCalvingDTO([]);
    setUploadedCalvingDates([]);
  };

  useEffect(() => {
    resetData();
  }, [currentFarm]);

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
        <Grid item xs={12} sm={12} lg={12}>
          <Typography
            variant="body1"
            style={{
              fontSize: '20px',
              backgroundColor: CaddieColours.DarkGrey,
              padding: '16px',
              borderRadius: '4px',
              color: CaddieColours.ActiveGreen,
              fontWeight: 'bold',
            }}
          >
            {scaryInstructionText}
          </Typography>
          <Divider />
          <InstructionList instructions={instructionText} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
        style={{ marginTop: '16px' }}
      >
        <Grid item xs={2} sm={2} lg={2} style={{ display: 'flex' }}>
          <Autocomplete
            options={uploadOptions}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => <TextField {...params} label="Select an option" variant="outlined" />}
            value={uploadOptions.find(({ value }) => value === uploadOption)}
            onChange={handleOptionChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2} sm={2} lg={2} style={{ display: 'flex' }}>
          <input type="file" accept=".csv" onChange={handleFileChange} />
        </Grid>
        <Grid item xs={2} sm={2} lg={2} style={{ display: 'grid' }}>
          <Autocomplete
            options={dateTimeFormatOptions}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Select a date format" variant="outlined" />
            )}
            value={uploadOptions.find(({ value }) => value === dateTimeFormat)}
            onChange={handleSelectDateTimeFormat}
            fullWidth
          />
        </Grid>
        <Grid item xs={2} sm={2} lg={2} style={{ display: 'grid' }}>
          <Button
            text="Preview import"
            backgroundColour="black"
            colour="white"
            onClick={getDataToPreview}
            disabled={tableData.length === 0}
          />
        </Grid>
        <Grid item xs={1} sm={1} lg={1} style={{ display: 'grid' }}>
          <Button text="Reset" backgroundColour="black" colour="white" onClick={resetData} />
        </Grid>
        <Grid item xs={2} sm={2} lg={2} style={{ display: 'grid' }}>
          <Button
            text="Upload"
            backgroundColour={CaddieColours.ActiveGreen}
            colour="white"
            onClick={uploadData}
            disabled={previewCalvingDTO.length === 0 && !uploadRunning}
          />
        </Grid>
      </Grid>
      <Divider variant="inset" flexItem />
      {uploadRunning ? (
        <SpinnerWrapper>
          <CircularProgress size="40px" />
        </SpinnerWrapper>
      ) : (
        <Grid container style={{ marginTop: '16px' }}>
          <Grid item xs-sm-md-lg={3}>
            {tableData.length > 0 && (
              <Paper style={{ padding: '16px' }}>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Consolas, monospace',
                    fontSize: '16px',
                    backgroundColor: '#f5f5f5',
                    padding: '8px',
                    borderRadius: '4px',
                  }}
                >
                  File Preview
                </Typography>
                <DataTable<CalvingDates>
                  data={tableData}
                  tableSortDisabled
                  columns={[
                    {
                      name: 'Ear Tag',
                      keyExtractor: (calving) => calving.earTag,
                      render: (calving) => calving.earTag,
                    },
                    {
                      name: 'Calved At',
                      keyExtractor: (calving) => calving.calvingDate,
                      render: (calving) => calving.calvingDate ?? 'Invalid date',
                    },
                  ]}
                />
              </Paper>
            )}
          </Grid>
          <Grid item xs-sm-md-lg={3}>
            {previewCalvingDTO.length > 0 && (
              <Paper style={{ padding: '16px' }}>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Consolas, monospace',
                    fontSize: '16px',
                    backgroundColor: '#f5f5f5',
                    padding: '8px',
                    borderRadius: '4px',
                  }}
                >
                  Import preview
                </Typography>
                <DataTable<PreviewCreateCalvingDTO>
                  data={previewCalvingDTO}
                  tableSortDisabled
                  columns={[
                    {
                      name: 'Ear Tag',
                      keyExtractor: (calving) => calving.earTag,
                      render: (calving) => calving.earTag,
                    },
                    {
                      name: 'CattleId',
                      keyExtractor: (calving) => calving.cattleId,
                      render: (calving) => calving.cattleId,
                    },
                    {
                      name: 'Calved At',
                      keyExtractor: (calving) => calving.calvedAt,
                      render: (calving) => dateToText(calving?.calvedAt),
                    },
                  ]}
                />
              </Paper>
            )}
          </Grid>
          <Grid item xs-sm-md-lg={3}>
            {uploadedCalvingDates.length > 0 && (
              <Paper style={{ padding: '16px' }}>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Consolas, monospace',
                    fontSize: '16px',
                    backgroundColor: '#f5f5f5',
                    padding: '8px',
                    borderRadius: '4px',
                  }}
                >
                  {`${uploadedCalvingDates.length} updated or created`}
                </Typography>
                <DataTable<ICalvingDTO | undefined>
                  data={uploadedCalvingDates}
                  tableSortDisabled
                  columns={[
                    {
                      name: 'CattleId',
                      keyExtractor: (calving) => calving?.cattleId,
                      render: (calving) => calving?.cattleId,
                    },
                    {
                      name: 'Calving Date',
                      keyExtractor: (calving) => calving?.calvedAt,
                      render: (calving) => dateToText(calving?.calvedAt),
                    },
                    {
                      name: 'Status',
                      keyExtractor: (calving) => calving?.status,
                      render: (calving) => calving?.status,
                    },
                    {
                      name: 'Created',
                      keyExtractor: (calving) => calving?.createdAt,
                      render: (calving) => dateToText(calving?.createdAt),
                    },
                  ]}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CSVImporter;
