import React, { FunctionComponent } from 'react';
import _groupBy from 'lodash/groupBy';
import { Grid } from '@material-ui/core';

import { IMobDTO } from '@halter-corp/cattle-service-client';
import { TransitionReportSummary } from 'data/reports';
import MetricsCard from 'application/modules/reports/components/metrics.card';
import MetricsContainer from 'application/modules/reports/components/metrics.container';
import MetricsHeader from 'application/modules/reports/components/metrics.header';
import InfoBox from 'application/modules/reports/components/info.box';
import moment from 'moment';
import momenttz from 'moment-timezone';

type TransitionMetricsProps = {
  transition: TransitionReportSummary;
  mobs: IMobDTO[];
};

const TransitionMetricsPanel: FunctionComponent<TransitionMetricsProps> = ({ transition, mobs }) => {
  const mobsById = _groupBy(mobs, (m) => m.id);

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12}>
        <MetricsHeader>
          <InfoBox title="Start Time">
            {momenttz
              .tz(moment.unix(transition.endTimeUtcS), 'Pacific/Auckland')
              .format('DD/MM/YYYY HH:mm:ss')}
          </InfoBox>
          <InfoBox title="End Time">
            {momenttz
              .tz(moment.unix(transition.endTimeUtcS), 'Pacific/Auckland')
              .format('DD/MM/YYYY HH:mm:ss')}
          </InfoBox>
          <InfoBox title="Cow Count">{transition.cowCount}</InfoBox>
          <InfoBox title="Mobs">
            {transition.mobIds.map((mobId) => mobsById[mobId]?.map((m) => m.name) || mobId).join(', ')}
          </InfoBox>
        </MetricsHeader>
      </Grid>
      <Grid item xs={12}>
        <MetricsContainer>
          <MetricsCard
            title="Shock"
            data={{
              Average: transition.shock.average.toFixed(2),
              Max: transition.shock.max,
              Total: transition.shock.total,
              'Total Shock Lockouts': transition.shock.totalLockout,
              'Total Failed Shocks': transition.shock.totalFailed,
            }}
          />

          <MetricsCard
            title="Piezo"
            data={{
              'Average Activations': transition.piezo.averageActivations.toFixed(2),
              'Max Activations': transition.piezo.maxActivations,
              'Average Intensity': transition.piezo.averageIntensity.toFixed(2),
            }}
          />

          <MetricsCard
            title="ODBA/ODBV"
            data={{
              'Average ODBA': transition.odba.averageOdba.toFixed(2),
              'Average ODBV': transition.odba.averageOdbv.toFixed(2),
              'Max ODBA Average for single cow': transition.odba.maxOdba,
            }}
          />

          <MetricsCard
            title="Heading"
            data={{
              'Average Heading Error (deg)': transition.heading.averageHeadingError.toFixed(2),
              'Max Heading Error (deg)': transition.heading.maxHeadingError,
            }}
          />
        </MetricsContainer>
      </Grid>
    </Grid>
  );
};

export default TransitionMetricsPanel;
