import React, { FunctionComponent, useState } from 'react';
import { Typography, makeStyles, Box, IconButton, SvgIcon } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom';
import DataTable from 'application/components/data-table';

import GenericSwitch from 'application/components/generic-switch';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import { CaddieColours } from '../../../theme/colours';
import Checkbox from '../../../components/checkbox';
import {
  FarmStatusMode,
  formatEnumValue,
  getOnFarmStatusCountColour,
  mapOnFarmStatusToDescription,
} from '../utils';
import { FleetFarmData } from '../screens/fleet-view.screen';

const useStyles = makeStyles(() => ({
  iconButton: {
    backgroundColor: 'None',
    position: 'relative',
    '&:hover': {
      backgroundColor: CaddieColours.AccentGrey,
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    borderRadius: '50%',
    marginRight: '8px',
    border: 1,
    width: '8px',
    height: '8px',
  },
}));

type FarmSelectedState = {
  farmId: string;
  farmName: string;
  isPinned: boolean;
};

type FarmSummaryCardProps = {
  handleFilterChange: (value: string) => void;
  handleSelectedFarm: (value: FleetFarmData) => void;
  handleSelectAllFarms: (isPinned: boolean, hasSelectedAllFarms: boolean) => void;
  selectedFarms: FarmSelectedState[];
  data: FleetFarmData[];
  isPinned: boolean;
  toggleMaintenance: (enableMaintenanceMode: boolean, farmId: string) => void;
};

const FarmSummaryCard: FunctionComponent<FarmSummaryCardProps> = ({
  data,
  selectedFarms,
  handleSelectAllFarms,
  handleSelectedFarm,
  handleFilterChange,
  toggleMaintenance,
  isPinned,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [hasSelectedAllFarms, setHasSelectedAllFarms] = useState<boolean>(false);

  const handleAllFarmsCheckbox = () => {
    handleSelectAllFarms(isPinned, !hasSelectedAllFarms);
    setHasSelectedAllFarms(!hasSelectedAllFarms);
  };

  const active = (
    <div className={classes.status}>
      <Box className={classes.statusIcon} style={{ backgroundColor: CaddieColours.ActiveGreen }} />
      Active
    </div>
  );

  const maintenance = (
    <div className={classes.status}>
      <Box className={classes.statusIcon} style={{ backgroundColor: CaddieColours.MaintenanceYellow }} />
      Maintenance
    </div>
  );

  const emergency = (
    <div className={classes.status} style={{ color: CaddieColours.EmergencyRed }}>
      <Box className={classes.statusIcon} style={{ backgroundColor: CaddieColours.EmergencyRed }} />
      Emergency
    </div>
  );

  const notLive = (
    <div className={classes.status}>
      <Box className={classes.statusIcon} style={{ backgroundColor: CaddieColours.WorriedOrange }} />
      Not Live
    </div>
  );

  return (
    <DataTable<FleetFarmData>
      data={data}
      columns={[
        {
          name: (
            <Checkbox
              checked={hasSelectedAllFarms}
              indeterminate={!hasSelectedAllFarms && selectedFarms.length > 0}
              onChange={() => handleAllFarmsCheckbox()}
            />
          ),
          keyExtractor: (item) => item.key,
          render: (item) => (
            <Checkbox
              checked={!!selectedFarms.find((farm) => farm.farmId === item.farmId)}
              onChange={() => handleSelectedFarm(item)}
            />
          ),
        },
        {
          name: 'Farm',
          keyExtractor: (item) => item?.farmName,
          render: (item) => (
            <Typography
              variant="body2"
              style={{ marginLeft: 'auto' }}
              onClick={() => history.push({ pathname: `/fleet-watch/${item.farmId}` })}
            >
              {item?.farmName}
            </Typography>
          ),
        },
        ...Object.values(DeviceOnFarmStatusEnum).map((status) => ({
          name: formatEnumValue(status),
          headerToolTip: mapOnFarmStatusToDescription(status),
          keyExtractor: (item: FleetFarmData) => item.collarCountByStatusMap.get(status) ?? 0,
          render: (item: FleetFarmData) => (
            <Typography
              style={{
                color: getOnFarmStatusCountColour(item.collarCountByStatusMap.get(status) ?? 0, status),
              }}
              onClick={() => history.push({ pathname: `/fleet-watch/${item.farmId}` })}
            >
              {item.collarCountByStatusMap.get(status) ?? 0}
            </Typography>
          ),
        })),
        {
          name: 'Status',
          keyExtractor: (item) => item?.status,
          render: (item) => (
            <Typography
              component="div"
              onClick={() => history.push({ pathname: `/fleet-watch/${item.farmId}` })}
            >
              {(item.status === FarmStatusMode.Emergency && emergency) ||
                (item.status === FarmStatusMode.Maintenance && maintenance) ||
                (item.status === FarmStatusMode.NotLive && notLive) ||
                active}
            </Typography>
          ),
        },
        {
          name: 'Maintenance',
          keyExtractor: (item) => item?.status,
          render: (item) => (
            <GenericSwitch
              value={item.status === FarmStatusMode.Maintenance}
              requireConfirmation
              confirmationMessage={
                item.status === FarmStatusMode.Maintenance
                  ? `Are you sure you want to turn maintenance mode OFF?`
                  : `Are you sure you want to turn maintenance mode ON?`
              }
              onChange={() => toggleMaintenance(item.status === FarmStatusMode.Maintenance, item.farmId)}
            />
          ),
        },
        {
          name: ' ',
          render: (item) => (
            <IconButton
              onClick={() => handleFilterChange(item.farmName)}
              style={{
                border: isPinned ? 'solid' : '',
                borderColor: 'black',
                borderWidth: 'thin',
                fontSize: '48px',
              }}
              className={classes.iconButton}
            >
              <SvgIcon style={{ fontSize: '20px' }}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill={isPinned ? 'black' : 'grey'}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M25.6667 17.4933C25.6667 16.8667 25.2133 16.36 24.6 16.1867C22.9067 15.72 21.6667 14.1733 21.6667 12.3333L21.6667 5.66667H23C23.7333 5.66667 24.3333 5.06667 24.3333 4.33333C24.3333 3.6 23.7333 3 23 3H9.66667C8.93333 3 8.33333 3.6 8.33333 4.33333C8.33333 5.06667 8.93333 5.66667 9.66667 5.66667H11L11 12.3333C11 14.1733 9.76 15.72 8.06666 16.1867C7.45333 16.36 7 16.8667 7 17.4933L7 17.6667C7 18.4 7.6 19 8.33333 19H14.9733L15 28.3333C15 29.0667 15.6 29.6667 16.3333 29.6667C17.0667 29.6667 17.6667 29.0667 17.6667 28.3333L17.64 19H24.3333C25.0667 19 25.6667 18.4 25.6667 17.6667L25.6667 17.4933Z"
                  />
                </svg>
              </SvgIcon>
            </IconButton>
          ),
        },
        {
          name: '  ',
          render: (item) => (
            <IconButton onClick={() => history.push({ pathname: `/fleet-watch/${item.farmId}` })}>
              <ChevronRightIcon style={{ color: 'black' }} />
            </IconButton>
          ),
        },
      ]}
      indexColumn={false}
      headerColour={CaddieColours.DarkGrey}
      defaultSortColumnName="Faulty"
      sortDirectionOption="desc"
    />
  );
};

export default FarmSummaryCard;
