import React, { FC, useEffect, useState } from 'react';
import { GeoJSON } from 'react-leaflet';
import BffDebugToolService, { LiveBreakCoordinate } from 'services/bff-debug-tool.service';

const MINUTES_TO_LOAD = 5;

type LiveBreakProps = { farmId: string; onLoadingChange: (loading: boolean) => void };

const ActiveBreaksLayer: FC<LiveBreakProps> = ({ farmId, onLoadingChange }) => {
  const [liveBreaks, setLiveBreaks] = useState<LiveBreakCoordinate[]>([]);

  useEffect(() => {
    (async () => {
      onLoadingChange(true);
      const fetchedLiveBreaks = await BffDebugToolService.fetchLatestLiveBreaks(farmId, MINUTES_TO_LOAD);
      setLiveBreaks(fetchedLiveBreaks);
      onLoadingChange(false);
    })();
  }, [farmId]);

  const liveBreakFeatures = liveBreaks.map((liveBreak) => ({
    id: liveBreak.id,
    geometry: { coordinates: liveBreak.coordinates, type: 'Polygon' },
    type: 'Feature' as 'Feature',
  }));

  return (
    <>
      {liveBreakFeatures.map((liveBreak) => [
        <GeoJSON
          key={`${liveBreak.id}-zone`}
          data={liveBreak}
          style={{
            fillOpacity: 0,
            color: 'white',
            weight: 2.5,
          }}
        />,
      ])}
    </>
  );
};

export default ActiveBreaksLayer;
