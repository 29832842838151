import {
  ICreateTowerFarmMappingRequestDTO,
  ICreateTowerRequestDTO,
  IInfraProductContextDTO,
  IInfraProductStatusDTO,
  ITowerDTO,
  ITowerFarmMappingDTO,
  ITowerMetadataDTO,
  IUpdateInfraProductContextRequestDTO,
  IUpdateInfraProductStatusRequestDTO,
  IUpdateTowerMetadataRequestDTO,
  IUpdateTowerRequestDTO,
} from '@halter-corp/infrastructure-service-client';
import { AxiosError } from 'axios';
import HttpApiService from './http-api.service';

const InfrastructureService = {
  fetchInfraProductContextByTowerId: async (towerId: string): Promise<IInfraProductContextDTO[]> => {
    const api = await HttpApiService.getInfraProductApi();
    const { data } = await api.getAllInfraProductContextsByTowerID(towerId);
    return data;
  },
  updateInfraProductContextById: async (
    id: string,
    updateInfraProductContextDto: IUpdateInfraProductContextRequestDTO
  ): Promise<IInfraProductContextDTO | null> => {
    const api = await HttpApiService.getInfraProductApi();
    try {
      const { data } = await api.updateInfraProductContextByID(id, updateInfraProductContextDto);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  updateAllInfraProductContextTowerIdByGroupId: async (
    groupId: string,
    towerId: string
  ): Promise<IInfraProductContextDTO[]> => {
    const api = await HttpApiService.getInfraProductApi();
    const { data } = await api.updateAllInfraProductContextTowerIDByGroupID(groupId, towerId);
    return data;
  },
  updateInfraProductStatusById: async (
    id: string,
    updatedInfraProductStatusDto: IUpdateInfraProductStatusRequestDTO
  ): Promise<IInfraProductStatusDTO | null> => {
    const api = await HttpApiService.getInfraProductApi();
    try {
      const { data } = await api.updateInfraProductStatusByID(id, updatedInfraProductStatusDto);
      return data;
    } catch (err) {
      return null;
    }
  },

  fetchTowers: async (): Promise<ITowerDTO[]> => {
    const api = await HttpApiService.getInfrastructureTowerApi();
    const { data } = await api.getAllTowers();
    return data;
  },
  fetchTowersByFarmId: async (farmId: string): Promise<ITowerDTO[]> => {
    const api = await HttpApiService.getInfrastructureTowerApi();
    const { data } = await api.getAllTowersByFarmID(farmId);
    return data;
  },
  fetchTowersByLatLngAndRadius: async (
    latitude: number,
    longitude: number,
    radius: number
  ): Promise<ITowerDTO[]> => {
    const api = await HttpApiService.getInfrastructureTowerApi();
    const { data } = await api.getAllTowersByLatLngAndRadius(latitude, longitude, radius);
    return data;
  },
  createTower: async (createTowerDto: ICreateTowerRequestDTO): Promise<ITowerDTO | null> => {
    const api = await HttpApiService.getInfrastructureTowerApi();
    try {
      const { data } = await api.createTower(createTowerDto);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 409) {
        return null;
      }
      throw err;
    }
  },
  updateTowerById: async (id: string, updatedTowerDto: IUpdateTowerRequestDTO): Promise<ITowerDTO | null> => {
    const api = await HttpApiService.getInfrastructureTowerApi();
    try {
      const { data } = await api.updateTowerByID(id, updatedTowerDto);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },
  deleteTowerById: async (id: string): Promise<boolean> => {
    const api = await HttpApiService.getInfrastructureTowerApi();
    try {
      await api.deleteTowerByID(id);
      return true;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      return axiosError.response != null && axiosError.response.status === 404;
    }
  },
  updateTowerMetadataById: async (
    id: string,
    updatedTowerMetadataDto: IUpdateTowerMetadataRequestDTO
  ): Promise<ITowerMetadataDTO | null> => {
    const api = await HttpApiService.getInfrastructureTowerApi();
    try {
      const { data } = await api.updateTowerMetadataByID(id, updatedTowerMetadataDto);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return null;
      }
      throw err;
    }
  },

  createTowerFarmMapping: async (
    createTowerFarmMappingDto: ICreateTowerFarmMappingRequestDTO
  ): Promise<ITowerFarmMappingDTO | null> => {
    const api = await HttpApiService.getInfrastructureTowerMappingApi();
    try {
      const { data } = await api.createTowerFarmMapping(createTowerFarmMappingDto);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 409) {
        return null;
      }
      throw err;
    }
  },
  deleteTowerFarmMappingByFarmIdAndTowerId: async (farmId: string, towerId: string): Promise<boolean> => {
    const api = await HttpApiService.getInfrastructureTowerMappingApi();
    try {
      await api.deleteTowerFarmMappingByFarmIDAndTowerID(farmId, towerId);
      return true;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      return axiosError.response != null && axiosError.response.status === 404;
    }
  },
};

export default InfrastructureService;
