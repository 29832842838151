import React, { ChangeEvent, FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import BusinessService from 'services/business.service';
import farmContextSlice from 'store/slices/farm-context.slice';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import farmEffects from 'store/effects/farm.effects';
import { IFarmDTO } from '@halter-corp/business-service-client';
import mobSlice from 'store/slices/mob.slice';
import farmInfraSlice from 'store/slices/farm-infra.slice';

type FarmSelectProps = {
  onChange?: (value: string) => void;
};

const FarmSelect: FunctionComponent<FarmSelectProps> = ({ onChange }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(farmEffects.fetch());
  }, [dispatch]);

  const currentFarmId = useSelector(getCurrentFarm);
  const farms = BusinessService.useFetchFarmList();

  const handleFarmChange = (_event: ChangeEvent<{}>, newFarm: IFarmDTO | null) => {
    if (newFarm == null) {
      return;
    }

    if (onChange != null) {
      onChange(newFarm.id);
    }

    dispatch(
      farmContextSlice.actions.setCurrentFarm({
        currentFarm: newFarm.id,
      })
    );

    dispatch(
      farmInfraSlice.actions.setCurrentFarm({
        currentFarm: newFarm.id,
      })
    );

    dispatch(
      mobSlice.actions.setCurrentMob({
        currentMob: '',
      })
    );
  };

  // If a farm is selected on page load (from localstorage) then fire the onchange callback
  useEffect(() => {
    if (currentFarmId != null && onChange != null) {
      onChange(currentFarmId);
    }
  }, []);

  return (
    <FormControl style={{ width: '100%' }}>
      {farms.length > 0 && (
        <Autocomplete
          options={farms}
          renderInput={(farm) => <TextField {...farm} label="Farm" />}
          getOptionLabel={(option) => option.name || ''}
          value={farms.find(({ id }) => id === currentFarmId)}
          onChange={handleFarmChange}
        />
      )}
    </FormControl>
  );
};

export default FarmSelect;
