import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';

import { Profile } from 'data/training';

import Wrapper from 'application/components/wrapper';

import { prettyPrintProfileType } from '../../utils';

const TypeTitle = styled.div`
  font-size: 18px;
  opacity: 0.8;
  padding: 8px;
`;

const ProfileLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
  padding: 8px;
`;

type ProfileSummaryCellProps = {
  profilesToBeApplied: { type: TrainingSettingsTypeEnum; profile?: Profile };
};

const ProfileSummaryCell: FunctionComponent<ProfileSummaryCellProps> = ({ profilesToBeApplied }) => (
  <SummaryWrapper>
    <TypeTitle>{prettyPrintProfileType(profilesToBeApplied.type)}</TypeTitle>
    {profilesToBeApplied.profile != null && (
      <ProfileLabel>Profiles: {profilesToBeApplied.profile?.name}</ProfileLabel>
    )}
  </SummaryWrapper>
);

const SummaryWrapper = styled(Wrapper)`
  flex: 1px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 4px;
`;

export default ProfileSummaryCell;
