import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { isEmpty, keyBy, uniq } from 'lodash';
import styled from 'styled-components';

import { Device } from 'data/device';
import BusinessService from 'services/business.service';
import DeviceService from 'services/device.service';

import Button from 'application/components/button';
import Checkbox from 'application/components/checkbox';
import Wrapper from 'application/components/wrapper';

import DataTable from '../../../../components/data-table';

import PreProvisioningActionModal from './select-pre-provisioning-farm-modal';
import { PreProvisioningAction } from './types';
import {
  batteryVoltageColumn,
  batteryPercentageColumn,
  cowColumn,
  farmColumn,
  alarmsColumn,
  firmwareVersionColumn,
  loraColumn,
  serialNumberColumn,
} from '../common-columns';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px 8px 32px 8px;
`;

type PreProvisioningTableProps = {
  devices: Device[];
};

const PreProvisioningTable: FunctionComponent<PreProvisioningTableProps> = ({ devices }) => {
  const farms = BusinessService.useFetchFarmList();

  const selectableDevices = useMemo(() => devices.filter((d) => d.cattleId == null), [devices]);
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState<string[]>([]);

  const [actionModalOpen, setActionModalOpen] = useState(false);

  const handleConfirm = useCallback(
    async (action: PreProvisioningAction) => {
      const keyedDevices = keyBy(devices, (device) => device.serialNumber);
      const selectedDevices = selectedSerialNumbers.map((serialNumber) => keyedDevices[serialNumber]);

      if (action.type === 'set')
        await DeviceService.preProvisionDevicesToFarm(selectedDevices, action.farmId);
      else await DeviceService.removeFarmPreProvisioningFromDevices(selectedDevices);

      setActionModalOpen(false);
    },
    [selectedSerialNumbers, devices, setActionModalOpen]
  );

  return (
    <Wrapper>
      <DataTable<Device>
        data={devices}
        defaultSortColumnName="Serial number"
        columns={[
          {
            name: (
              <Checkbox
                checked={
                  !isEmpty(selectableDevices) && selectableDevices.length === selectedSerialNumbers.length
                }
                onChangeValue={(checked) =>
                  setSelectedSerialNumbers(
                    checked ? selectableDevices.map((device) => device.serialNumber) : []
                  )
                }
                disabled={isEmpty(selectableDevices)}
              />
            ),
            render: (device) => (
              <Checkbox
                checked={selectedSerialNumbers.includes(device.serialNumber)}
                onChangeValue={(checked) =>
                  setSelectedSerialNumbers((serialNumbers) =>
                    checked
                      ? uniq([...serialNumbers, device.serialNumber])
                      : serialNumbers.filter((serialNumber) => serialNumber !== device.serialNumber)
                  )
                }
                disabled={!selectableDevices.includes(device)}
              />
            ),
          },
          serialNumberColumn,
          cowColumn,
          farmColumn(farms),
          batteryPercentageColumn,
          batteryVoltageColumn,
          alarmsColumn,
          loraColumn,
          firmwareVersionColumn,
        ]}
      />
      <ButtonWrapper>
        <Button
          text="Update pre-provisioning"
          onClick={() => setActionModalOpen(true)}
          disabled={isEmpty(selectedSerialNumbers)}
        />
      </ButtonWrapper>
      <PreProvisioningActionModal
        open={actionModalOpen}
        onConfirm={handleConfirm}
        onDismiss={() => setActionModalOpen(false)}
      />
    </Wrapper>
  );
};

export default PreProvisioningTable;
