import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import { Device } from 'data/device';

import SettingsService from 'services/settings.service';
import BusinessService from 'services/business.service';
import DataTable from '../../../components/data-table';

import { getSettingsColumns } from './utils';
import { cowColumn, farmColumn, mobColumn, serialNumberColumn } from './common-columns';

type SettingsTableProps = {
  devices: Device[];
  mode: 'farm' | 'device';
};

const SettingsTable: FunctionComponent<SettingsTableProps> = ({ devices, mode }) => {
  const history = useHistory();
  const settingsMetadata = SettingsService.useSettingsMetadata();

  const farms = BusinessService.useFetchFarmList();

  return (
    <DataTable<Device>
      data={devices}
      defaultSortColumnName="Serial number"
      onSelectRow={(device) => history.push(`/devices/${device.serialNumber}`)}
      columns={[
        cowColumn,
        serialNumberColumn,
        ...(mode === 'device' ? [farmColumn(farms)] : []),
        mobColumn,
        {
          name: 'Status',
          keyExtractor: (device) => device.settingsV2?.status,
          render: (device) => device.settingsV2?.status,
        },
        ...getSettingsColumns(devices, settingsMetadata),
      ]}
    />
  );
};

export default SettingsTable;
