import HttpApiService from './http-api.service';

const LiveViewService = {
  fetchDeviceLocationsList: async (farmId: string) => {
    const bffInitialStateApi = await HttpApiService.getBffMobileInitialStateApi(farmId);
    const { data: deviceLocations } = await bffInitialStateApi.listDeviceLocations();
    return deviceLocations;
  },
};

export default LiveViewService;
