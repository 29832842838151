import { Box, Divider, IconButton, Popover, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import React, { FunctionComponent, useState } from 'react';

const SeasonMetricsPopup: FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(Boolean(anchorEl));

  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <Info style={{ color: 'black' }} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box p={3}>
          <Typography variant="h6">Cycling</Typography>
          <Typography>Have had a heat in the last 35 days.</Typography>
          <Typography>
            <b>% calculated:</b> The count of cycled cattle divided by the number of included cattle in the
            season.
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography variant="h6">Non-cycling</Typography>
          <Typography>
            Have not had a heat or have not had a heat in the last 35 days and have not been mated.
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography variant="h6">Submitted/Not</Typography>
          <Typography>Have been mated or not.</Typography>
          <br />
          <Divider />
          <br />
          <Typography variant="h6">Non-return rate</Typography>
          <Typography>
            Calculated as the number of non-returned cattle/heats divided by the number of eligible
            inseminations.
            <br />
            <b>Note:</b> we expect non-return rates for Halter farms to be lower than farms which are not
            using Halter. This is not a bad thing - it is a by-product of our sensitive heat detection.
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography variant="h6">Eligible inseminations</Typography>
          <Typography>Heat events which have been mated more than 24 days ago.</Typography>
          <br />
          <Divider />
          <br />
          <Typography variant="h6">Non-returned</Typography>
          <Typography>Mated more than 24 days ago and have not had a heat in the last 24 days.</Typography>
          <br />
          <Divider />
          <br />
          <Typography variant="h6">Returned</Typography>
          <Typography>Mated more than 24 days ago and have had a heat in the last 24 days.</Typography>
          <Divider />
          <br />
          <Typography variant="h6">Six week in calf rate</Typography>
          <Typography>Percent of the herd which conceived in the first 6 weeks of mating. Estimated using non-return and submission rates.</Typography>
          <Divider />
          <br />
          <Typography variant="h6">Three week submssion rate</Typography>
          <Typography>Percent of the herd which was mated at least once in the first three weeks of mating.</Typography>
        </Box>
      </Popover>
    </>
  );
};

export default SeasonMetricsPopup;
