import { createAsyncThunk } from '@reduxjs/toolkit';
import { chunk, flatten, isEmpty } from 'lodash';

import {
  ICreateTrainingProfileRequestDTO,
  IUpdateTrainingProfileRequestDTO,
  TrainingSettingsTypeEnum,
  ICreateTrainingModifierRequestDTO,
  IUpdateTrainingModifierRequestDTO,
  IUpsertCurrentStageRequestDTO,
  IUpsertFarmerTrainingGuideOptionsRequestDTO,
  IApplyProfilesRequestDTO,
  IApplyModifiersRequestDTO,
  ITrainingModifierDTO,
  ITrainingProfileDTO,
} from '@halter-corp/training-service-client';

import HttpApiService from 'services/http-api.service';

import {
  Profile,
  Modifier,
  FarmerTrainingGuide,
  FarmerTrainingGuideOptions,
  AppliedTrainingProfile,
  CowAppliedModifiers,
} from 'data/training';
import { cattleApiService, Cattle, WithMob } from 'data/cattle';

type FetchAppliedProfilesRequest = { farmId: string; types?: TrainingSettingsTypeEnum[] };
type FetchAppliedProfilesResult = { farmId: string; appliedProfiles: AppliedTrainingProfile[] };
type FetchAppliedModifiersRequest = { farmId: string; mobId?: string; cattleNames?: string[] };
type FetchAppliedModifiersResult = {
  cattleWithMobs: WithMob<Cattle>[];
  appliedModifiers: CowAppliedModifiers[];
};

const trainingEffects = {
  fetchProfiles: createAsyncThunk(
    'training/profiles/fetch',
    async (farmId: string | undefined = undefined): Promise<Profile[]> => {
      const trainingProfileApi = await HttpApiService.getTrainingProfileApi();

      const farms = farmId == null ? [] : [{ id: farmId }];

      const { data: commonFarmProfiles } = await trainingProfileApi.findAllProfiles({
        headers: HttpApiService.getNoFarmContextOverrideHeader(),
      });
      const commonFarmProfilesWithFarmName: Profile[] = commonFarmProfiles.map((profile) => ({
        ...profile,
        farmName: 'All farms',
      }));

      const profileListsForFarms: Profile[][] = await Promise.all(
        farms.map(async (farm) => {
          const httpOptionsForFarm = { headers: HttpApiService.getContextOverrideHeader(farm.id) };
          const { data: profilesForFarm } = await trainingProfileApi.findAllProfiles(httpOptionsForFarm);

          return profilesForFarm.reduce<Profile[]>((previous, current) => {
            if (current.farmId == null) return previous;
            return [...previous, current];
          }, []);
        })
      );

      return [
        ...commonFarmProfilesWithFarmName,
        ...profileListsForFarms.reduce((previous, current) => [...previous, ...current], []),
      ];
    }
  ),

  createProfile: createAsyncThunk(
    'training/profiles/create',
    async (props: { request: ICreateTrainingProfileRequestDTO }): Promise<ITrainingProfileDTO> => {
      const trainingProfileApi = await HttpApiService.getTrainingProfileApi();

      const { data: createdProfile } = await trainingProfileApi.createProfile(props.request);
      return createdProfile;
    }
  ),

  updateProfile: createAsyncThunk(
    'training/profiles/update',
    async (props: {
      profileId: string;
      request: IUpdateTrainingProfileRequestDTO;
    }): Promise<ITrainingProfileDTO> => {
      const trainingProfileApi = await HttpApiService.getTrainingProfileApi();

      const { data: updatedProfile } = await trainingProfileApi.updateProfile(props.profileId, props.request);
      return updatedProfile;
    }
  ),

  deleteProfile: createAsyncThunk(
    'training/profiles/delete',
    async (props: { profileId: string }, { dispatch }): Promise<void> => {
      const trainingProfileApi = await HttpApiService.getTrainingProfileApi();

      await trainingProfileApi.deleteProfile(props.profileId);

      await dispatch(trainingEffects.fetchProfiles());
    }
  ),

  fetchModifiers: createAsyncThunk('training/modifiers/fetch', async (): Promise<Modifier[]> => {
    const trainingModifierApi = await HttpApiService.getTrainingModifierApi();

    const { data: commonFarmModifiers } = await trainingModifierApi.findAllModifiers({
      headers: HttpApiService.getNoFarmContextOverrideHeader(),
    });
    return commonFarmModifiers.map((profile) => ({
      ...profile,
      farmName: 'All farms',
    }));
  }),

  createModifier: createAsyncThunk(
    'training/modifiers/create',
    async (props: { request: ICreateTrainingModifierRequestDTO }): Promise<ITrainingModifierDTO> => {
      const trainingModifierApi = await HttpApiService.getTrainingModifierApi();

      const { data: createdModifier } = await trainingModifierApi.createModifier(props.request);
      return createdModifier;
    }
  ),

  updateModifier: createAsyncThunk(
    'training/modifiers/update',
    async (props: {
      modifierId: string;
      request: IUpdateTrainingModifierRequestDTO;
    }): Promise<ITrainingModifierDTO> => {
      const trainingModifierApi = await HttpApiService.getTrainingModifierApi();

      const { data: updatedModifier } = await trainingModifierApi.updateModifier(
        props.modifierId,
        props.request
      );
      return updatedModifier;
    }
  ),

  deleteModifier: createAsyncThunk(
    'training/modifiers/delete',
    async (props: { modifierId: string }, { dispatch }): Promise<void> => {
      const trainingModifierApi = await HttpApiService.getTrainingModifierApi();

      await trainingModifierApi.deleteModifier(props.modifierId);

      await dispatch(trainingEffects.fetchModifiers());
    }
  ),

  applyProfiles: createAsyncThunk(
    'training/apply-profiles',
    async (props: { request: IApplyProfilesRequestDTO }): Promise<void> => {
      const applyProfilesApi = await HttpApiService.getApplyProfilesApi();

      await applyProfilesApi.applyProfiles(props.request);
    }
  ),

  applyModifiers: createAsyncThunk(
    'training/apply-modifiers',
    async (props: { farmId: string; request: IApplyModifiersRequestDTO }): Promise<void> => {
      const applyModifiersApi = await HttpApiService.getApplyModifiersApi(props.farmId);

      await applyModifiersApi.bulkApplyModifiers(props.request);
    }
  ),

  fetchAppliedProfiles: createAsyncThunk(
    'training/applied-profiles/fetch',
    async (props: FetchAppliedProfilesRequest): Promise<FetchAppliedProfilesResult> => {
      const { farmId } = props;

      const applyProfilesApi = await HttpApiService.getApplyProfilesApi(farmId);

      const { data: appliedProfiles } = await Promise.resolve(applyProfilesApi.findAllAppliedProfiles());

      return { farmId, appliedProfiles };
    }
  ),

  fetchAppliedModifiers: createAsyncThunk(
    'training/applied-modifiers/fetch',
    async (props: FetchAppliedModifiersRequest): Promise<FetchAppliedModifiersResult> => {
      const { farmId, mobId } = props;
      const cattleNames = isEmpty(props.cattleNames) ? undefined : props.cattleNames;

      const cattleWithMobs = await Promise.resolve(
        cattleApiService.fetchWithMob({ farmId, mobId, cattleNames })
      );

      const applyModifiersApi = await HttpApiService.getApplyModifiersApi(farmId);
      if (isEmpty(cattleNames)) {
        const { data: appliedModifiers } = await applyModifiersApi.findAllAppliedModifiers();
        return { cattleWithMobs, appliedModifiers };
      }

      const cattleIds = cattleWithMobs.map((cow) => cow.id);
      const appliedModifiersChunks = await Promise.all(
        chunk(cattleIds, 100).map(async (cattleIdsChunk) => {
          const { data: appliedModifiers } = await applyModifiersApi.findAllAppliedModifiers(cattleIdsChunk);
          return appliedModifiers;
        })
      );

      return { cattleWithMobs, appliedModifiers: flatten(appliedModifiersChunks) };
    }
  ),

  fetchFarmerTrainingGuides: createAsyncThunk(
    'training/farmer-training-guides/fetch',
    async (farmId: string): Promise<FarmerTrainingGuide[]> => {
      const farmerTrainingGuideApi = await HttpApiService.getFarmerTrainingGuideApi();

      const { data: farmerTrainingGuides } = await farmerTrainingGuideApi.findFarmerTrainingGuides(farmId);

      return farmerTrainingGuides;
    }
  ),

  updateFetchFarmerTrainingGuides: createAsyncThunk(
    'training/farmer-training-guides/update',
    async (props: { request: IUpsertCurrentStageRequestDTO }, { dispatch }): Promise<void> => {
      const farmerTrainingGuideApi = await HttpApiService.getFarmerTrainingGuideApi();

      await farmerTrainingGuideApi.upsertCurrentStage(props.request);

      await dispatch(trainingEffects.fetchFarmerTrainingGuides(props.request.farmId));
    }
  ),

  fetchFarmerTrainingGuideOptions: createAsyncThunk(
    'training/farmer-training-guide-options/fetch',
    async (farmId: string): Promise<FarmerTrainingGuideOptions> => {
      const farmerTrainingGuideApi = await HttpApiService.getFarmerTrainingGuideApi();

      const { data: farmerTrainingGuideOptions } =
        await farmerTrainingGuideApi.findFarmerTrainingGuideOptions(farmId);

      return farmerTrainingGuideOptions;
    }
  ),

  updateFetchFarmerTrainingGuideOptions: createAsyncThunk(
    'training/farmer-training-guides-options/update',
    async (
      props: { farmId: string; request: IUpsertFarmerTrainingGuideOptionsRequestDTO },
      { dispatch }
    ): Promise<void> => {
      const farmerTrainingGuideApi = await HttpApiService.getFarmerTrainingGuideApi();

      await farmerTrainingGuideApi.upsertFarmerTrainingGuideOptions(props.farmId, props.request);

      await dispatch(trainingEffects.fetchFarmerTrainingGuideOptions(props.farmId));
    }
  ),
};

export default trainingEffects;
