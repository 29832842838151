import { environment } from 'env-config';
import React, { FunctionComponent } from 'react';
import { Source, Layer } from 'react-map-gl';
import { useLocation } from 'react-router-dom';

type HalterDroneMapLayerProps = {
  farmId?: string;
};

const HalterDroneMapLayer: FunctionComponent<HalterDroneMapLayerProps> = ({ farmId }) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const overrideFarmId = urlParams.get('basemapFarmId');
  const farmIdToUse = overrideFarmId ?? farmId;

  const tileUrl = `https://halter-admin-dashboard-tiles.${environment}.halter.io/${
    farmIdToUse ?? 'common'
  }/{z}/{x}/{y}.png`;

  return (
    <Source key={farmIdToUse} type="raster" tiles={[tileUrl]} tileSize={256}>
      <Layer type="raster" />
    </Source>
  );
};

export default HalterDroneMapLayer;
