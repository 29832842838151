import React, { FunctionComponent, useMemo } from 'react';
import { Polyline } from 'react-leaflet';

import { CattleLocationPath } from 'store/selectors/debug-cattle.selectors/mappers';
import { useLeafletZoom } from 'use-leaflet';
import { getColorForMetric } from '../../../utils';

export type CattleLocationsProps = {
  cattlePaths: CattleLocationPath[];
};

const CattleLocationsLayer: FunctionComponent<CattleLocationsProps> = ({ cattlePaths }) => {
  const zoom = useLeafletZoom();
  const showCattleEvents = useMemo(() => zoom > 20, [zoom]);

  return (
    <>
      {cattlePaths.map((path) => (
        <Polyline
          color={getColorForMetric()}
          opacity={showCattleEvents ? 0.4 : 1}
          key={path.cattleName}
          positions={path.locations.map((l) => l.location)}
        />
      ))}
    </>
  );
};

export default CattleLocationsLayer;
