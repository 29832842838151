import { FarmerTrainingGuideTypeEnum, TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';

const underscoreToHumanReadable = (input: string): string => {
  const words = input.split('_');
  const humanReadable = words.join(' ').toLowerCase();
  if (humanReadable.length === 0) return humanReadable;
  return humanReadable.charAt(0).toUpperCase() + humanReadable.slice(1);
};

export const prettyPrintProfileType = (type: TrainingSettingsTypeEnum) =>
  underscoreToHumanReadable(type.toString());

export const prettyPrintFarmerGuideType = (type: FarmerTrainingGuideTypeEnum) => {
  switch (type) {
    case FarmerTrainingGuideTypeEnum.BREAK_FENCE_CREATION:
      return 'Creating Break Fences';
    case FarmerTrainingGuideTypeEnum.TRANSITIONS:
      return 'Transitions';
    default:
      return '';
  }
};
