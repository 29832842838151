import React, { FunctionComponent, useEffect, useState } from 'react';
import Button from 'application/components/button';
import { DeviceWithMetadata } from 'store/selectors/tower.selectors';
import { DeviceActionDialogProps } from '../DevicesActionsWrapper';

type ActionWrapperProps = {
  devices: DeviceWithMetadata[];
  actionName: string;
  component: FunctionComponent<DeviceActionDialogProps>;
  disabled: boolean;
};

const ActionWrapper: FunctionComponent<ActionWrapperProps> = ({
  devices,
  actionName,
  component,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [targets, setTargets] = useState(devices.map((device) => device.id));

  useEffect(() => {
    setTargets(devices.map((device) => device.id));
  }, [devices]);

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      {component({ open, targets, onClose: handleClose, devices })}
      <Button disabled={disabled} style={{ margin: 5 }} text={actionName ?? ''} onClick={handleClick} />
    </>
  );
};

export default ActionWrapper;
