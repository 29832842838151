import { IFeatureDTO } from '@halter-corp/topography-service-client';
import { createSlice } from '@reduxjs/toolkit';
import { Feature, Point, Polygon } from 'geojson';
import heatMapEffects, { FarmWithDatum } from 'store/effects/heat-map.effects';
import { SearchParameters } from 'application/modules/heat-map/screens/heat-map.screen';
import { reset } from '../actions';

type Status = 'pending' | 'error' | 'success' | 'idle';

export type Boundarys = {
  paddock?: Feature<Polygon>;
  break?: Feature<Polygon>;
};

export type EventLocation = {
  latitude: number;
  longitude: number;
  timestamp: Date;
};

export type BreakFence = {
  geometry: Feature<Polygon>;
  startTime: Date;
  dropTime: Date;
};

export type ExitPoint = {
  point: Feature<Point>;
  startTime: Date;
  endTime: Date;
};

export type BehaviourPosition = {
  latitude: number;
  longitude: number;
  grazingProbability: number;
  restingProbability: number;
  ruminatingProbability: number;
  timestamp: Date;
};

export type SlotGroup = {
  id: string;
  slotGroupMappings: string[];
  features: Feature<Polygon>[];
};

type LayerStatuses = {
  shockLocations: Status;
  piezoLocations: Status;
  vibeLocations: Status;
  positionMetrics: Status;
  farmsWithDatums: Status;
  farmPaddocks: Status;
  farmBreaks: Status;
  exitPoints: Status;
  searchFromCommand: Status;
  behaviourPositions: Status;
  slotGroups: Status;
};

type HeatMapItemsState = {
  positionMetrics: EventLocation[];
  shockLocations: EventLocation[];
  piezoLocations: EventLocation[];
  vibeLocations: EventLocation[];
  farmsWithDatums: FarmWithDatum[];
  farmPaddocks: IFeatureDTO[];
  farmBreaks: BreakFence[];
  exitPoints: ExitPoint[];
  behaviourPositions: BehaviourPosition[];
  slotGroups: SlotGroup[];
  searchFromCommand?: SearchParameters;
  layerStatuses: LayerStatuses;
};

const initialState: HeatMapItemsState = {
  positionMetrics: [],
  shockLocations: [],
  piezoLocations: [],
  vibeLocations: [],
  farmsWithDatums: [],
  farmPaddocks: [],
  farmBreaks: [],
  exitPoints: [],
  behaviourPositions: [],
  slotGroups: [],
  searchFromCommand: undefined,
  layerStatuses: {
    shockLocations: 'idle',
    piezoLocations: 'idle',
    vibeLocations: 'idle',
    positionMetrics: 'idle',
    farmsWithDatums: 'idle',
    farmPaddocks: 'idle',
    farmBreaks: 'idle',
    exitPoints: 'idle',
    behaviourPositions: 'idle',
    searchFromCommand: 'idle',
    slotGroups: 'idle',
  },
};

const heatMapSlice = createSlice({
  initialState,
  name: 'heatMapSlice',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(heatMapEffects.fetchPositionMetrics.pending, (state) => {
        state.layerStatuses.positionMetrics = 'pending';
      })
      .addCase(heatMapEffects.fetchPositionMetrics.rejected, (state) => {
        state.layerStatuses.positionMetrics = 'error';
      })
      .addCase(heatMapEffects.fetchPositionMetrics.fulfilled, (state, action) => {
        state.layerStatuses.positionMetrics = 'success';
        state.positionMetrics = action.payload;
      })
      .addCase(heatMapEffects.fetchShockLocations.pending, (state) => {
        state.layerStatuses.shockLocations = 'pending';
      })
      .addCase(heatMapEffects.fetchShockLocations.rejected, (state) => {
        state.layerStatuses.shockLocations = 'error';
      })
      .addCase(heatMapEffects.fetchShockLocations.fulfilled, (state, action) => {
        state.layerStatuses.shockLocations = 'success';
        state.shockLocations = action.payload;
      })
      .addCase(heatMapEffects.fetchPiezoLocations.pending, (state) => {
        state.layerStatuses.piezoLocations = 'pending';
      })
      .addCase(heatMapEffects.fetchPiezoLocations.rejected, (state) => {
        state.layerStatuses.piezoLocations = 'error';
      })
      .addCase(heatMapEffects.fetchPiezoLocations.fulfilled, (state, action) => {
        state.layerStatuses.piezoLocations = 'success';
        state.piezoLocations = action.payload;
      })
      .addCase(heatMapEffects.fetchVibeLocations.pending, (state) => {
        state.layerStatuses.vibeLocations = 'pending';
      })
      .addCase(heatMapEffects.fetchVibeLocations.rejected, (state) => {
        state.layerStatuses.vibeLocations = 'error';
      })
      .addCase(heatMapEffects.fetchVibeLocations.fulfilled, (state, action) => {
        state.layerStatuses.vibeLocations = 'success';
        state.vibeLocations = action.payload;
      })
      .addCase(heatMapEffects.fetchFarmsWithDatums.pending, (state) => {
        state.layerStatuses.farmsWithDatums = 'pending';
      })
      .addCase(heatMapEffects.fetchFarmsWithDatums.rejected, (state) => {
        state.layerStatuses.farmsWithDatums = 'error';
      })
      .addCase(heatMapEffects.fetchFarmsWithDatums.fulfilled, (state, action) => {
        state.layerStatuses.farmsWithDatums = 'success';
        state.farmsWithDatums = action.payload;
      })
      .addCase(heatMapEffects.fetchFarmPaddocks.pending, (state) => {
        state.layerStatuses.farmPaddocks = 'pending';
      })
      .addCase(heatMapEffects.fetchFarmPaddocks.rejected, (state) => {
        state.layerStatuses.farmPaddocks = 'error';
      })
      .addCase(heatMapEffects.fetchFarmPaddocks.fulfilled, (state, action) => {
        state.layerStatuses.farmPaddocks = 'success';
        state.farmPaddocks = action.payload;
      })
      .addCase(heatMapEffects.fetchFarmBreaks.pending, (state) => {
        state.layerStatuses.farmBreaks = 'pending';
      })
      .addCase(heatMapEffects.fetchFarmBreaks.rejected, (state) => {
        state.layerStatuses.farmBreaks = 'error';
      })
      .addCase(heatMapEffects.fetchFarmBreaks.fulfilled, (state, action) => {
        state.layerStatuses.farmBreaks = 'success';
        state.farmBreaks = action.payload;
      })
      .addCase(heatMapEffects.fetchExitPoints.pending, (state) => {
        state.layerStatuses.exitPoints = 'pending';
      })
      .addCase(heatMapEffects.fetchExitPoints.rejected, (state) => {
        state.layerStatuses.exitPoints = 'error';
      })
      .addCase(heatMapEffects.fetchExitPoints.fulfilled, (state, action) => {
        state.layerStatuses.exitPoints = 'success';
        state.exitPoints = action.payload;
      })
      .addCase(heatMapEffects.fetchBehaviourPositions.pending, (state) => {
        state.layerStatuses.behaviourPositions = 'pending';
      })
      .addCase(heatMapEffects.fetchBehaviourPositions.rejected, (state) => {
        state.layerStatuses.behaviourPositions = 'error';
      })
      .addCase(heatMapEffects.fetchBehaviourPositions.fulfilled, (state, action) => {
        state.layerStatuses.behaviourPositions = 'success';
        state.behaviourPositions = action.payload;
      })
      .addCase(heatMapEffects.fetchAllSlotGroupsByFarmIds.pending, (state) => {
        state.layerStatuses.slotGroups = 'pending';
      })
      .addCase(heatMapEffects.fetchAllSlotGroupsByFarmIds.rejected, (state) => {
        state.layerStatuses.slotGroups = 'error';
      })
      .addCase(heatMapEffects.fetchAllSlotGroupsByFarmIds.fulfilled, (state, action) => {
        state.layerStatuses.slotGroups = 'success';
        state.slotGroups = action.payload;
      }),
});

export default heatMapSlice;
