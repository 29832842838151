import React, { FunctionComponent, useState } from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

import { useDispatch } from 'store';
import trainingEffects from 'store/effects/training.effects';
import TrainingService from 'services/training.service';

import ScreenWrapper from 'application/components/screen-wrapper';
import LoadingBar from 'application/components/loading-bar';

import ApplyProfilesForm from '../../forms/apply-profiles.form';
import { ApplyProfiles, GetApplyProfilesSummaryFormValues } from '../../forms/apply-profiles.form/types';
import ApplyProfilesContainer from '../../containers/apply-summary.container/apply-profiles.container';

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  opacity: 0.9;
  padding: 12px;
`;

const ApplyProfilesScreen: FunctionComponent = () => {
  const dispatch = useDispatch();

  const loading = TrainingService.useIsLoading();

  const [farmId, setFarmId] = useState<string | undefined>();
  const [trainingsToBeApplied, setTrainingsToBeApplied] = useState<ApplyProfiles | undefined>();

  const handleSubmit = async (values: GetApplyProfilesSummaryFormValues) => {
    await dispatch(trainingEffects.fetchAppliedProfiles({ farmId: values.farmId }));

    setTrainingsToBeApplied(values.trainings);
    setFarmId(values.farmId);
  };

  return (
    <>
      <LoadingBar loading={loading} />
      <ScreenWrapper>
        <Grid item xs={12} sm={10} lg={8}>
          <Title>Apply profiles</Title>
          <ApplyProfilesForm
            onSubmit={handleSubmit}
            onChange={() => setTrainingsToBeApplied(undefined)}
            disableSubmit={trainingsToBeApplied != null}
          />
          {trainingsToBeApplied && farmId && (
            <ApplyProfilesContainer farmId={farmId} profilesToBeApplied={trainingsToBeApplied} />
          )}
        </Grid>
      </ScreenWrapper>
    </>
  );
};

export default ApplyProfilesScreen;
