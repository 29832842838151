import { CowgSettingsMetadata, SettingsMetadata } from 'data/settings';
import { createSelector } from '@reduxjs/toolkit';
import { ISettingsMetadataDTO } from '@halter-corp/settings-service-client';
import { AppState } from '../store';

// type SettingsMetadata

export const settingsMetadataSlice = (state: AppState): ISettingsMetadataDTO | undefined =>
  state.settings.metadata;

export const selectCowgSettingsMetadata = createSelector(
  settingsMetadataSlice,
  (settingsMetadata): CowgSettingsMetadata | null => {
    if (settingsMetadata == null) return null;

    return settingsMetadata as CowgSettingsMetadata;
  }
);

export const selectSettingsMetadata = createSelector(
  settingsMetadataSlice,
  (settingsMetadataDTO): SettingsMetadata | null => {
    if (settingsMetadataDTO == null) return null;

    return {
      control: settingsMetadataDTO.control,
      engineering: settingsMetadataDTO.engineering,
      operational: settingsMetadataDTO.operational,
      cowg: { ...settingsMetadataDTO.cattleCowg, system: settingsMetadataDTO.farmCowg },
    } as SettingsMetadata;
  }
);
