import {
  IRebootJobRequestDTO,
  IResetPortsJobRequestDTO,
  IThingDTO,
  IThingOtaExecutionDTO,
} from '@halter-corp/thing-service-client';
import { AxiosError } from 'axios';

import HttpApiService from './http-api.service';

const ThingService = {
  fetchThingsBySerialNumbers: async (serialNumbers: string[]): Promise<IThingDTO[] | []> => {
    const api = await HttpApiService.getThingRegistryApi();
    try {
      const things = await Promise.all(
        serialNumbers.map(async (serialNumber) => {
          const { data } = await api.findThing(serialNumber);
          return data;
        })
      );
      return things;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },

  fetchReleaseChannel: async (serialNumber: string): Promise<IThingDTO | undefined> => {
    const api = await HttpApiService.getThingRegistryApi();
    try {
      const { data } = await api.findThing(serialNumber);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },

  fetchOtaStatus: async (serialNumber: string): Promise<IThingOtaExecutionDTO | undefined> => {
    const api = await HttpApiService.getThingRegistryApi();
    try {
      const { data } = await api.getThingOta(serialNumber);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },

  requestResetPortsJob: async (resetPortsJobRequest: IResetPortsJobRequestDTO) => {
    const api = await HttpApiService.getJobApi();
    api.createResetPortsJobRequest(resetPortsJobRequest);
  },
  requestRebootJob: async (rebootJobRequest: IRebootJobRequestDTO) => {
    const api = await HttpApiService.getJobApi();
    api.createRebootJobRequest(rebootJobRequest);
  },
};

export default ThingService;
