import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { ApplyModifiersOption, Modifier } from 'data/training';

import SelectField from '../../components/select-field';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ExtraModifierWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const AddModifierWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

type ModifiersSelectProps = {
  label: string;
  modifiers?: Modifier[];
  selected: ApplyModifiersOption;
  onChange: (option: ApplyModifiersOption) => void;
};

type FirstModifierSelect =
  | { type: 'apply'; firstModifier: Modifier }
  | { type: 'keep-current' }
  | { type: 'remove-all' };

const ModifiersSelect: FunctionComponent<ModifiersSelectProps> = ({
  label,
  modifiers = [],
  selected,
  onChange,
}) => {
  const firstModifierDisplayNameExtractor = (option: FirstModifierSelect) => {
    if (option.type === 'keep-current') return 'Keep current';
    if (option.type === 'remove-all') return 'None';
    return option.firstModifier.name;
  };

  const firstModifierValue = useMemo(() => {
    if (selected.type !== 'apply') return selected;
    return { type: 'apply', firstModifier: selected.modifiers[0] };
  }, [selected]);

  const onChangeFirstModifier = useCallback((firstModifierSelect: FirstModifierSelect) => {
    if (firstModifierSelect.type !== 'apply') onChange(firstModifierSelect);
    else onChange({ type: 'apply', modifiers: [firstModifierSelect.firstModifier] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeExtraSelectedModifier = (modifier: Modifier, extraModiferIndex: number) => {
    if (selected.type !== 'apply') return;
    const newModifiersList = selected.modifiers;
    newModifiersList[extraModiferIndex + 1] = modifier;
    onChange({ type: 'apply', modifiers: newModifiersList });
  };

  const availableExtraModifiers: Modifier[][] = useMemo(() => {
    if (selected.type !== 'apply') return [];
    return selected.modifiers.map((_, index) => {
      const notAvailableModifiers = selected.modifiers.slice(0, index + 1);
      return modifiers.filter((m) => !notAvailableModifiers.map(({ id }) => id).includes(m.id));
    });
  }, [modifiers, selected]);

  const addExtraModifer = useCallback(() => {
    if (selected.type !== 'apply') return;
    if (isEmpty(availableExtraModifiers[selected.modifiers.length - 1])) return;
    onChange({
      type: 'apply',
      modifiers: [...selected.modifiers, availableExtraModifiers[selected.modifiers.length - 1][0]],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, availableExtraModifiers]);

  const removeExtraModifer = useCallback(
    (index: number) => {
      if (selected.type !== 'apply') return;
      onChange({
        type: 'apply',
        modifiers: [...selected.modifiers.slice(0, index + 1), ...selected.modifiers.slice(index + 2)],
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selected, modifiers]
  );

  return (
    <Wrapper>
      <SelectField<FirstModifierSelect, string>
        label={label}
        options={[
          { type: 'keep-current' },
          { type: 'remove-all' },
          ...modifiers.map<FirstModifierSelect>((m) => ({ type: 'apply', firstModifier: m })),
        ]}
        value={JSON.stringify(firstModifierValue)}
        valueExtractor={(option) => JSON.stringify(option)}
        displayNameExtractor={firstModifierDisplayNameExtractor}
        onChange={(value) => onChangeFirstModifier(JSON.parse(value))}
        noValue={JSON.stringify({ type: 'keep-current' })}
      />
      {selected.type === 'apply' && (
        <Wrapper>
          {selected.modifiers.slice(1).map((extraSelectedModifier, index) => (
            <ExtraModifierWrapper key={extraSelectedModifier.id}>
              <SelectField<Modifier, string>
                label={label}
                options={availableExtraModifiers[index]}
                value={JSON.stringify(extraSelectedModifier)}
                valueExtractor={(modifier) => JSON.stringify(modifier)}
                displayNameExtractor={(modifier) => modifier.name}
                onChange={(value) => onChangeExtraSelectedModifier(JSON.parse(value), index)}
              />
              <CloseIcon onClick={() => removeExtraModifer(index)} style={{ cursor: 'pointer' }} />
            </ExtraModifierWrapper>
          ))}
          <AddModifierWrapper>
            <AddIcon
              onClick={addExtraModifer}
              style={
                isEmpty(availableExtraModifiers[selected.modifiers.length - 1])
                  ? { opacity: 0.4 }
                  : { cursor: 'pointer' }
              }
            />
          </AddModifierWrapper>
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default ModifiersSelect;
