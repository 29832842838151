import { createSlice } from '@reduxjs/toolkit';
import fleetEffects from 'store/effects/fleet.effects';
import { DeviceOffFarmCheckpoint } from 'data/fleet-watch';
import { IDeviceOnFarmStatesContextDTO } from '@halter-corp/fleet-service-client';
import { groupBy, keyBy } from 'lodash';
import { FarmDeviceOnFarmStatusCounts } from 'application/modules/fleet-watch/types';
import { reset } from '../actions';

type FleetState = {
  offFarmCheckpointsByCheckpoint?: Record<string, DeviceOffFarmCheckpoint[]>;
  offFarmCheckpointsByCheckpointStatus: 'loading' | 'error' | 'success';
  deviceOnFarmStatesByFarmId?: Record<string, IDeviceOnFarmStatesContextDTO[]>;
  deviceOnFarmStatesByFarmIdStatus: 'loading' | 'error' | 'success';
  deviceOnFarmStatusCountsByFarmId?: Record<string, FarmDeviceOnFarmStatusCounts>;
  deviceOnFarmStatusCountsByFarmIdStatus: 'loading' | 'error' | 'success';
};

const initialState: FleetState = {
  offFarmCheckpointsByCheckpoint: undefined,
  offFarmCheckpointsByCheckpointStatus: 'loading',
  deviceOnFarmStatesByFarmId: undefined,
  deviceOnFarmStatesByFarmIdStatus: 'loading',
  deviceOnFarmStatusCountsByFarmId: undefined,
  deviceOnFarmStatusCountsByFarmIdStatus: 'loading',
};

const fleetSlice = createSlice({
  initialState,
  name: 'fleet',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(fleetEffects.fetchOffFarmCheckpointsByCheckpoints.pending, (state) => {
        state.offFarmCheckpointsByCheckpointStatus = 'loading';
      })
      .addCase(fleetEffects.fetchOffFarmCheckpointsByCheckpoints.rejected, (state) => {
        state.offFarmCheckpointsByCheckpointStatus = 'error';
      })
      .addCase(fleetEffects.fetchOffFarmCheckpointsByCheckpoints.fulfilled, (state, action) => {
        state.offFarmCheckpointsByCheckpointStatus = 'success';
        state.offFarmCheckpointsByCheckpoint = action.payload;
      })
      .addCase(fleetEffects.fetchDeviceOnFarmStates.pending, (state) => {
        state.deviceOnFarmStatesByFarmIdStatus = 'loading';
      })
      .addCase(fleetEffects.fetchDeviceOnFarmStates.rejected, (state) => {
        state.deviceOnFarmStatesByFarmIdStatus = 'error';
      })
      .addCase(fleetEffects.fetchDeviceOnFarmStates.fulfilled, (state, action) => {
        state.deviceOnFarmStatesByFarmIdStatus = 'success';
        if (state.deviceOnFarmStatesByFarmId == null) {
          state.deviceOnFarmStatesByFarmId = {};
        }

        const fetchedDeviceOnFarmStatesByFarmId = groupBy(action.payload, 'farmId');
        // eslint-disable-next-line no-restricted-syntax
        for (const [farmId, deviceOnFarmStates] of Object.entries(fetchedDeviceOnFarmStatesByFarmId)) {
          state.deviceOnFarmStatesByFarmId[farmId] = deviceOnFarmStates;
        }
      })
      .addCase(fleetEffects.fetchDeviceOnFarmStatusCounts.pending, (state) => {
        if (state.deviceOnFarmStatusCountsByFarmIdStatus === 'success') return;
        state.deviceOnFarmStatusCountsByFarmIdStatus = 'loading';
      })
      .addCase(fleetEffects.fetchDeviceOnFarmStatusCounts.rejected, (state) => {
        state.deviceOnFarmStatusCountsByFarmIdStatus = 'error';
      })
      .addCase(fleetEffects.fetchDeviceOnFarmStatusCounts.fulfilled, (state, action) => {
        if (action.payload.length === 0) return;
        state.deviceOnFarmStatusCountsByFarmIdStatus = 'success';
        state.deviceOnFarmStatusCountsByFarmId = keyBy(action.payload, 'farmId');
      })
      .addCase(fleetEffects.fetchDeviceOnFarmStatusCountsByFarmId.fulfilled, (state, action) => {
        if (state.deviceOnFarmStatusCountsByFarmId == null) {
          state.deviceOnFarmStatusCountsByFarmId = {};
        }
        state.deviceOnFarmStatusCountsByFarmId[action.payload.farmId] = action.payload;
      }),
});

export default fleetSlice;
