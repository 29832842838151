import { FormControl, Input, InputLabel } from '@material-ui/core';
import Button from 'application/components/button';
import L from 'leaflet';
import React, { ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { replaceWhitespaces } from 'application/utils';
import { ITowerLocationDTO } from '@halter-corp/tower-service-client';
import { Tower } from 'store/selectors/tower.selectors';
import CreateNoteDialog from './dialogs/create-note.dialog';
import { useModifiedTowersContext } from '../contexts/modified-tower-context';
import { useInvalidInputContext } from '../contexts/invalid-input-context';
import TowerFormSelect from './tower-form-select';

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 296px;
  z-index: 9999;
  gap: 10px;
`;

type TowerFormProps = {
  tower: Tower;
  isNew: boolean;
  setTowerMap: Dispatch<SetStateAction<Map<string, Tower>>>;
  handleClose: (towerId: string) => void;
  moveTo: (latlng: L.LatLng, instant?: boolean) => void;
};

const TowerForm: FunctionComponent<TowerFormProps> = ({ tower, setTowerMap, handleClose, moveTo, isNew }) => {
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [formData, setFormData] = useState<{
    id: string;
    latitude: number;
    longitude: number;
    mainsPowered: boolean | undefined;
    starlink: boolean | undefined;
    router: boolean | undefined;
    networkGroup: string | undefined;
    notes: [];
    groupIndex: number | undefined;
    routerTower: string | undefined;
  }>({
    id: tower?.id,
    latitude: tower?.location.latitude,
    longitude: tower?.location.longitude,
    mainsPowered: tower?.mainsPowered,
    starlink: tower?.starlink,
    router: tower?.router,
    networkGroup: tower?.networkGroupId,
    notes: [],
    groupIndex: tower?.groupIndex ?? 0,
    routerTower: tower?.routerTowerId ?? '',
  });
  const { setModified } = useModifiedTowersContext();
  const { setInvalidInput } = useInvalidInputContext();

  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(false);
  };

  const handleTowerForm = () => {
    if (formData.mainsPowered === undefined) {
      return;
    }
    setModified((current) => {
      current.delete(tower.id);
      return new Map(current);
    });
    setInvalidInput((current) => {
      current.delete(tower.id);
      return new Map(current);
    });
    const newTowerId = replaceWhitespaces(formData.id.trim(), '-');
    const updatedTower = {
      id: newTowerId,
      farmId: tower.farmId,
      location: {
        latitude: formData.latitude,
        longitude: formData.longitude,
      } as ITowerLocationDTO,
      mainsPowered: formData.mainsPowered,
      router: formData.router,
      starlink: formData.starlink,
      notes: tower.notes,
      groupIndex: formData.groupIndex,
      routerTowerId: formData.routerTower,
    } as Tower;

    if (tower.id !== newTowerId) {
      setTowerMap((current) => {
        current.delete(tower.id);
        current.set(newTowerId, updatedTower);
        return new Map(current);
      });
    } else {
      setTowerMap((current) => {
        current.set(tower.id, updatedTower);
        return new Map(current);
      });
    }

    moveTo(new L.LatLng(updatedTower.location.latitude, updatedTower.location.longitude), true);
    handleClose(tower.id);
  };

  const updateLocation = (towerLocation: ITowerLocationDTO) => {
    const { latitude, longitude } = towerLocation;
    setFormData({
      ...formData,
      latitude,
      longitude,
    });
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModified((current) => {
      current.set(tower.id, true);
      return new Map(current);
    });
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === 'latitude' || e.target.name === 'longitude' || e.target.name === 'groupIndex'
          ? Number(e.target.value)
          : e.target.value,
    });
  };

  const handleSelect = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setModified((current) => {
      current.set(tower.id, true);
      return new Map(current);
    });
    if (e.target.name !== undefined) {
      setFormData({
        ...formData,
        [e.target.name]: Boolean(e.target.value),
      });
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleTowerForm();
    }
  };

  useEffect(() => {
    if (tower?.location) {
      updateLocation(tower.location);
    }
  }, [tower?.location]);

  return (
    <DialogWrapper>
      {isNew ? (
        <>
          <FormControl>
            <InputLabel htmlFor={`${tower?.id}-name`}>Tower name:</InputLabel>
            <Input
              id={`${tower?.id}-name`}
              name="id"
              type="text"
              onFocus={handleFocus}
              onChange={handleFormChange}
              onKeyPress={handleKeyPress}
              value={formData.id}
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor={`${tower?.id}-groupIndex`}>Network Group Index:</InputLabel>
            <Input
              id={`${tower?.id}-groupIndex`}
              name="groupIndex"
              type="number"
              onFocus={handleFocus}
              onChange={handleFormChange}
              onKeyPress={handleKeyPress}
              value={formData.groupIndex}
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor={`${tower?.id}-routerTower`}>Router Tower Id:</InputLabel>
            <Input
              id={`${tower?.id}-router-tower`}
              name="routerTower"
              type="string"
              onFocus={handleFocus}
              onChange={handleFormChange}
              onKeyPress={handleKeyPress}
              value={formData.routerTower}
            />
          </FormControl>
        </>
      ) : (
        <FormControl>
          <InputLabel htmlFor={`${tower?.id}-networkGroup`}>Network Group:</InputLabel>
          <Input
            id={`${tower?.id}-router-tower`}
            name="routerTower"
            type="string"
            onFocus={handleFocus}
            onChange={handleFormChange}
            onKeyPress={handleKeyPress}
            value={formData.networkGroup}
          />
        </FormControl>
      )}
      <FormControl>
        <InputLabel htmlFor={`${tower?.id}-latitude`}>Tower latitude:</InputLabel>
        <Input
          id={`${tower?.id}-latitude`}
          name="latitude"
          type="number"
          onChange={handleFormChange}
          onKeyPress={handleKeyPress}
          value={formData.latitude}
        />
      </FormControl>
      <FormControl>
        <InputLabel htmlFor={`${tower?.id}-longitude`}>Tower longitude:</InputLabel>
        <Input
          id={`${tower?.id}-longitude`}
          name="longitude"
          type="number"
          onChange={handleFormChange}
          onKeyPress={handleKeyPress}
          value={formData.longitude}
        />
      </FormControl>
      <TowerFormSelect
        id={tower?.id}
        displayName="Mains Powered"
        fieldName="mainsPowered"
        handleChange={handleSelect}
        value={formData.mainsPowered}
      />
      <TowerFormSelect
        id={tower?.id}
        displayName="Router"
        fieldName="router"
        handleChange={handleSelect}
        value={formData.router}
      />
      <TowerFormSelect
        id={tower?.id}
        displayName="Starlink"
        fieldName="starlink"
        handleChange={handleSelect}
        value={formData.starlink}
      />
      {!isNew && (
        <Button
          text="Add note"
          onClick={() => {
            setOpenNoteDialog(true);
          }}
        />
      )}

      <Button style={{ display: 'block' }} text="Commit changes" onClick={handleTowerForm} />
      <CreateNoteDialog tower={tower} open={openNoteDialog} onClose={handleCloseNoteDialog} />
    </DialogWrapper>
  );
};

export default TowerForm;
