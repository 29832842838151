import React from 'react';
import { toArray, isEmpty } from 'lodash';
import { IFarmDTO } from '@halter-corp/business-service-client';
import { IMobDTO } from '@halter-corp/cattle-service-client';
import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';
import BusinessService from 'services/business.service';
import MobService from 'services/mob.service';
import TrainingService from 'services/training.service';
import Wrapper from 'application/components/wrapper';
import Button from 'application/components/button';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { ApplyModifiersOption } from 'data/training';
import SelectField from '../components/select-field';
import InputField from '../components/input-field';
import useUpdateMobs from '../../hooks/use-update-mobs';
import { useModifiersByType } from '../../hooks/use-profiles-by-type';
import ModifiersSelect from './components/modifier-select';
import { prettyPrintProfileType } from '../../utils';
import { GetApplyModifiersSummaryFormValues } from './types';
import { getInitialModifiersValues } from './utils';

type ApplyModifiersFormContent = {
  onChange?: () => void;
  disableSubmit: boolean;
};

const ApplyModifiersFormContent = (props: ApplyModifiersFormContent) => {
  const { onChange, disableSubmit } = props;

  const { handleSubmit, setValues, setFieldValue, values, errors } =
    useFormikContext<GetApplyModifiersSummaryFormValues>();
  const { farmId } = values;

  const farms = BusinessService.useFetchFarmList();
  const mobs = MobService.useFetchMobList();

  useUpdateMobs(farmId);

  const modifiers = TrainingService.useSelectModifiersByFarmId(farmId);
  const modifiersByType = useModifiersByType(modifiers);

  const handleChange = () => {
    if (onChange != null) {
      onChange();
    }
  };

  const onFarmIdChange = (newFarmId: string) => {
    setValues({
      ...values,
      farmId: newFarmId,
      mobId: undefined,
      trainings: getInitialModifiersValues(newFarmId).trainings,
    });
    handleChange();
  };

  const onMobIdChange = (newMobId: string | undefined) => {
    setFieldValue('mobId', newMobId);
    handleChange();
  };

  const onCattleNamesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      'cattleNames',
      isEmpty(event.target.value.trim()) ? [] : event.target.value.trim().split(',')
    );
    handleChange();
  };

  const onModifiersChange = (type: TrainingSettingsTypeEnum) => (option: ApplyModifiersOption) => {
    setFieldValue(`trainings[${type}]`, option);
    handleChange();
  };

  const onSubmitPress = () => {
    handleSubmit();
  };

  return (
    <Wrapper>
      <>
        <InputRow>
          <SelectField<IFarmDTO>
            label="Farm"
            options={farms}
            value={values.farmId}
            valueExtractor={(farm) => farm.id}
            displayNameExtractor={(farm) => farm.name!}
            onChange={onFarmIdChange}
            hasError={errors.farmId != null}
          />
          <SelectField<IMobDTO | undefined, string | undefined>
            label="Mob"
            options={[undefined, ...mobs]}
            value={values.mobId}
            valueExtractor={(mob) => mob?.id}
            displayNameExtractor={(mob) => mob?.name ?? 'All mobs'}
            onChange={onMobIdChange}
          />
          <InputField
            label="Cow numbers"
            value={values.cattleNames.join(',')}
            onChange={onCattleNamesChange}
          />
        </InputRow>
        <InputRow>
          {toArray(TrainingSettingsTypeEnum).map((type) => (
            <ModifiersSelect
              key={type}
              label={`${prettyPrintProfileType(type)} modifier`}
              modifiers={modifiersByType[type]}
              selected={values.trainings[type]}
              onChange={onModifiersChange(type)}
            />
          ))}
        </InputRow>
        <ButtonWrapper>
          <Button text="Next" onClick={onSubmitPress} disabled={disableSubmit} />
        </ButtonWrapper>
      </>
    </Wrapper>
  );
};

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
`;

const ButtonWrapper = styled(Wrapper)`
  flex-direction: row;
  justify-content: center;
`;

export default ApplyModifiersFormContent;
