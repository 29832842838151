import React, { FunctionComponent, Children, ReactNode, CSSProperties } from 'react';
import { GridProps } from '@material-ui/core';
import styled from 'styled-components';

const BaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type WrapperProps = Pick<GridProps, 'lg' | 'md' | 'sm' | 'xs'> & {
  className?: string;
  style?: CSSProperties;
  itemContainerStyle?: CSSProperties;
};

const renderChildren = (children: ReactNode, itemContainerStyle?: CSSProperties) => {
  if (Children.count(children) < 2 || itemContainerStyle == null) return children;

  return Children.map(children, (child) => <BaseWrapper style={itemContainerStyle}>{child}</BaseWrapper>);
};

const Wrapper: FunctionComponent<WrapperProps> = ({ style, className, itemContainerStyle, children }) => (
  <BaseWrapper style={style} className={className}>
    {renderChildren(children, itemContainerStyle)}
  </BaseWrapper>
);

export default Wrapper;
