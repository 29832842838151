import { createSlice } from '@reduxjs/toolkit';

import { reset } from 'store/actions';
import reportsEffects from 'store/effects/reports.effects';
import { Report, ReportData } from 'data/reports';

const initialState: {
  loading: boolean;
  allReports: Report[];
  report: Report | null;
  reportData: ReportData | null;
} = {
  loading: false,
  allReports: [],
  report: null,
  reportData: null,
};

const reportsSlice = createSlice({
  initialState,
  name: 'reports',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(reportsEffects.fetchAllReports.fulfilled, (state, action) => {
        state.allReports = action.payload;
        state.loading = false;
      })
      .addCase(reportsEffects.fetchReportDetails.fulfilled, (state, action) => {
        state.report = action.payload;
        state.loading = false;
      })
      .addCase(reportsEffects.fetchReportData.fulfilled, (state, action) => {
        state.reportData = action.payload;
        state.loading = false;
      })
      .addCase(reportsEffects.fetchAllReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportsEffects.fetchReportDetails.pending, (state) => {
        state.loading = true;
        state.report = null;
      })
      .addCase(reportsEffects.fetchReportData.pending, (state) => {
        state.loading = true;
        state.reportData = null;
      }),
});

export default reportsSlice;
