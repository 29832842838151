import { QueryExecutionStatusEnum } from '@halter-corp/bff-debug-tool-service-client';
import axios from 'axios';
import csv from 'csv-parser';
import moment from 'moment';
import HttpApiService from './http-api.service';

const BffDebugToolQueryService = {
  query: async <T>(query: string): Promise<T[]> => {
    const api = await HttpApiService.getBffDebugToolApi();
    let { data: queryExection } = await api.startQueryExecution({ query });

    while (queryExection.resultUrl == null) {
      if (queryExection.status === QueryExecutionStatusEnum.FAILED) {
        throw new Error();
      }

      // eslint-disable-next-line no-await-in-loop
      await new Promise((r) => setTimeout(r, 500));
      // eslint-disable-next-line no-await-in-loop
      queryExection = (await api.findQueryExecution(queryExection.id)).data;
    }

    const resultsResponse = await axios({
      url: queryExection.resultUrl,
      method: 'GET',
      responseType: 'blob',
    });

    return new Promise((resolve) => {
      const items: T[] = [];

      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result;
        csv()
          .on('data', (row) => {
            items.push(row);
          })
          .write(text);
      };
      reader.onloadend = () => {
        resolve(items);
      };
      reader.readAsText(resultsResponse.data);
    });
  },

  getTimestampFilter: (from: Date, to: Date): string =>
    `cast(partition_utc_timestamp as varchar) between '${moment.utc(from).format('YYYY-MM-DD')}' and '${moment
      .utc(to)
      .format('YYYY-MM-DD')}z'
     and cast(utc_timestamp as varchar) between '${from.toISOString().replace('T', ' ')}' and '${to
      .toISOString()
      .replace('T', ' ')}'`,
};

export default BffDebugToolQueryService;
