import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Source, Layer, HeatmapLayer } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import HeatMapService from 'services/heat-map.service';
import heatMapEffects from 'store/effects/heat-map.effects';
import { featureCollection, point } from 'turf';
import { Bounds, DataMapSearch } from '../panels/data-map.panel';
import { eventLocationBounds } from '../util/utils';

const heatmapLayer: HeatmapLayer = {
  id: 'postion-heatmap',
  type: 'heatmap',
  paint: {
    'heatmap-weight': 1,
    'heatmap-intensity': 1,
    'heatmap-radius': ['interpolate', ['exponential', 2], ['zoom'], 0, 1, 22, 200],
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(227,92,255,0)',
      0.1,
      'rgba(227,92,255,0.8)',
      1,
      'rgb(241,173,255)',
    ],
  },
};

type PositionHeatmapLayerProps = {
  search: DataMapSearch;
  currentTime?: Date;
  recenterMap: (bounds: Bounds) => void;
  refresh: boolean;
};

const PositionHeatmapLayer: FunctionComponent<PositionHeatmapLayerProps> = ({
  search,
  currentTime,
  recenterMap,
  refresh,
}) => {
  const dispatch = useDispatch();
  const positions = HeatMapService.useFetchPositions();

  const filteredPositions =
    currentTime === undefined
      ? positions
      : positions.filter(
          (position) =>
            position.timestamp.getTime() <= currentTime.getTime() &&
            position.timestamp.getTime() >= currentTime.getTime() - 180 * 1000
        );

  const positionFeatureCollection =
    filteredPositions &&
    featureCollection(filteredPositions.map((position) => point([position.longitude, position.latitude])));

  useEffect(() => {
    const newStartTime = search.startTime
      ? new Date(search.startTime.getTime() - 3 * 60 * 1000)
      : search.startTime;
    dispatch(
      heatMapEffects.fetchPositionMetrics({
        farmIds: search.farmIds,
        mobIds: search.mobIds,
        cattleNames: search.cattleNames,
        startTime: newStartTime,
        endTime: search.endTime,
      })
    );
  }, [JSON.stringify(search), refresh]);

  // Recenter Map
  useMemo(() => {
    if (positions.length > 0) {
      const bounds = eventLocationBounds(positions);
      recenterMap(bounds);
    }
  }, [positions]);

  return (
    <Source type="geojson" data={positionFeatureCollection}>
      <Layer {...heatmapLayer} minzoom={12} />
    </Source>
  );
};
export default PositionHeatmapLayer;
