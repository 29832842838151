import { Button, IconButton, Slider, Typography } from '@material-ui/core';
import { PauseSharp, PlayArrowSharp } from '@material-ui/icons';
import { dateToText } from 'application/modules/fleet-watch/utils';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import useInterval from '../hooks/use-interval.hook';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px 20px;
  flex: 1;
  display: flex;
`;

const PlaybackFormat = (seconds: number): string => {
  if (Number.isNaN(seconds)) return '0:00';

  const hours = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  return `${hours}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

type PlaybackControlProps = {
  minTimestamp: Date;
  maxTimestamp: Date;
  currentTime: Date;
  setCurrentTime: (currentTime?: Date) => void;
};

const PlaybackControl: FunctionComponent<PlaybackControlProps> = ({
  minTimestamp,
  maxTimestamp,
  currentTime,
  setCurrentTime,
}) => {
  const [playing, setPlaying] = useState(false);
  const [speed, setSpeed] = useState(100);
  const currentTimeSeconds = (currentTime.getTime() - minTimestamp.getTime()) / 1000;
  const totalTimeSeconds = (maxTimestamp.getTime() - minTimestamp.getTime()) / 1000;

  const togglePlaying = () => {
    setPlaying(!playing);

    if (!playing) {
      if (currentTimeSeconds === totalTimeSeconds) {
        setCurrentTime(minTimestamp);
      }
    }
  };

  // Update at 40fps
  useInterval(() => {
    if (playing) {
      if (currentTime.getTime() >= maxTimestamp.getTime()) {
        setPlaying(false);
        setCurrentTime(maxTimestamp);
      } else {
        setCurrentTime(new Date(currentTime.getTime() + 100 * speed));
      }
    }
  }, 25);

  return (
    <Wrapper>
      <IconButton style={{ padding: 0, marginRight: '18px' }} onClick={togglePlaying}>
        {playing ? (
          <PauseSharp fontSize="medium" style={{ color: 'white' }} />
        ) : (
          <PlayArrowSharp fontSize="medium" style={{ color: 'white' }} />
        )}
      </IconButton>
      <Slider
        min={minTimestamp.getTime()}
        max={maxTimestamp.getTime()}
        style={{ color: 'white', padding: '15px 0' }}
        value={currentTime.getTime()}
        onChange={(event, value) => setCurrentTime(new Date(value as number))}
      />
      <Typography style={{ color: 'white', fontSize: '14px', lineHeight: '32px', marginLeft: '10px' }}>
        {PlaybackFormat(currentTimeSeconds)}/{PlaybackFormat(totalTimeSeconds)}
      </Typography>
      <Typography
        style={{
          color: 'white',
          fontSize: '14px',
          lineHeight: '32px',
          marginLeft: '10px',
          marginRight: '20px',
          whiteSpace: 'nowrap',
        }}
      >
        {dateToText(currentTime)}
      </Typography>
      <Button
        variant="contained"
        style={{ minWidth: "auto", fontSize: "12px", whiteSpace: 'nowrap', marginRight: '30px' }}
        onClick={() => { setCurrentTime(undefined); setPlaying(false); }}
      >
        Exit Playback
      </Button>
      <Slider
        min={0}
        max={200}
        style={{ color: 'white', position: 'absolute', right: '45px', bottom: '65px', height: '200px' }}
        orientation="vertical"
        value={speed}
        onChange={(event, value) => setSpeed(value as number)}
      />
    </Wrapper>
  );
};
export default PlaybackControl;
