import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import farmEffects from 'store/effects/farm.effects';
import BusinessService from 'services/business.service';
import farmStatusEffects from 'store/effects/farm-status.effects';

import deviceEffects from 'store/effects/device.effects';
import { useParams } from 'react-router-dom';
import farmContextSlice from 'store/slices/farm-context.slice';
import { Auth } from 'aws-amplify';
import FarmStatusService from 'services/farm-status.service';
import FleetService from 'services/fleet.service';
import fleetEffects from 'store/effects/fleet.effects';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import { getFleetFarmStates } from '../utils';
import FarmIssuesScreen from '../screens/farm-issue.screen';

/**
 * @context - Temporary container component to migrate from device-service APIs to fleet-service APIs
 */
const FarmIssuesContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const params = useParams() as { farmId: string };
  const currentFarmId = useMemo(() => params.farmId, [params]);

  const [mode, setMode] = useState<DeviceOnFarmStatusEnum>(DeviceOnFarmStatusEnum.FAULTY_ON_COW);

  const [user, setUser] = useState('');

  const farms = BusinessService.useFetchFarmList();
  const farmStatuses = FarmStatusService.useFetchAllFarmStatuses();
  const onFarmDeviceStatesByFarmId = FleetService.useFetchDeviceOnFarmStatesByFarmId();
  const deviceOnFarmStatusCountsByFarmId = FleetService.useFetchDeviceOnFarmStatusCountsByFarmId();

  useEffect(() => {
    dispatch(farmEffects.fetch());
    dispatch(farmStatusEffects.fetchAll());
  }, [dispatch]);

  // Todo: is this necessary?
  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: false }).then((u) => {
      setUser(`${u.attributes.given_name} ${u.attributes.family_name}`);
    });
  }, []);

  useEffect(() => {
    if (!farms) return;
    dispatch(fleetEffects.fetchDeviceOnFarmStatusCounts({ farmIds: farms.map((f) => f.id) }));
  }, [dispatch, farms]);

  useEffect(() => {
    if (currentFarmId == null) return;

    dispatch(farmContextSlice.actions.setCurrentFarm({ currentFarm: currentFarmId }));
    dispatch(fleetEffects.fetchDeviceOnFarmStatusCountsByFarmId({ farmId: currentFarmId }));
    dispatch(fleetEffects.fetchDeviceOnFarmStates({ farmId: currentFarmId }));
  }, [dispatch, currentFarmId]);

  const deviceSerials: string[] = useMemo(() => {
    if (onFarmDeviceStatesByFarmId == null || onFarmDeviceStatesByFarmId[currentFarmId] == null) return [];

    const deviceOnFarmStatesForMode = onFarmDeviceStatesByFarmId[currentFarmId].filter(
      (deviceOnFarm) => deviceOnFarm.status === mode
    );
    return deviceOnFarmStatesForMode.map((d) => d.serialNumber);
  }, [onFarmDeviceStatesByFarmId, currentFarmId, mode]);

  useEffect(() => {
    dispatch(deviceEffects.fetchDevices(deviceSerials));
  }, [dispatch, deviceSerials]);

  const farmStateData = useMemo(
    () => getFleetFarmStates(farms, farmStatuses.entities, deviceOnFarmStatusCountsByFarmId),
    [farms, farmStatuses.entities, deviceOnFarmStatusCountsByFarmId]
  );

  return <FarmIssuesScreen farmStateData={farmStateData} user={user} mode={mode} setMode={setMode} />;
};

export default FarmIssuesContainer;
