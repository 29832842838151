import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  createStyles,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
} from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { DEFAULT_DATE_TIME_FORMAT } from 'application/modules/debug-cattle/utils/constants';
import GenericSwitch from 'application/components/generic-switch';

import { DeviceDetail, fetchDeviceDetailsData } from 'services/device-detail-page.service';
import DeviceService from 'services/device.service';
import CattleService from 'services/cattle.service';

import settingsEffects from 'store/effects/settings.effects';
import { flatten } from 'flat';

import ResetButton from '../components/reset-button';
import TagOffButton from '../components/tag-off-button';
import SingleDeviceMapView from '../components/single-device-map-view';
import { CommandType } from '../types';
import {
  deviceBatteryStatus,
  deviceCommandSetHashStatus,
  deviceFaultsStatus,
  deviceLoraStatus,
  deviceSyncingStatusToTrafficLight,
  deviceWifiStatus,
} from '../components/device-status.util';
import SettingsPanel from '../panels/settings.panel';
import { detailedBitReason } from '../../../../store/effects/device.effects/device-stats-util';
import { Device } from '../../../../data/device';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 2rem;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: solid 1px #a2c3dd;
  border-bottom: solid 1px #a2c3dd;
  padding: 8px;
  margin: 8px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailPageJson: {
      color: theme.palette.text.primary,
      display: 'block',
    },
    label: {
      color: theme.palette.text.secondary,
      float: 'left',
      marginRight: '5px;',
    },
    gridWithExpandablePanel: {
      padding: '1rem',
    },
    accordion: {
      width: '100%',
    },
    topTextContent: {
      marginLeft: '2rem',
    },
    timeMachine: {
      marginLeft: '2rem',
      float: 'right',
    },
    topContent: {
      marginLeft: '1rem',
    },
    commandSetHashTitle: {
      lineHeight: '22px',
      marginBottom: '10px',
    },
  })
);

type DeviceDetailsScreenProps = {
  serialNumber: string;
  atTime?: Date;
};

const DeviceDetailsScreen: FunctionComponent<DeviceDetailsScreenProps> = ({ serialNumber, atTime }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const device: undefined | Device = DeviceService.useSelectDevice();

  const [loading, setLoading] = useState(false);
  const [deviceDetailData, setDeviceDetailData] = useState<DeviceDetail | undefined>(undefined);
  const [commandStatusExpanded, setCommandStatusExpanded] = useState(!isMobile);
  const [swapStatusExpanded, setSwapStatusExpanded] = useState(!isMobile);
  const [commsStatusExpanded, setCommsStatusExpanded] = useState(!isMobile);
  const [allCollarCommandsExpanded, setAllCollarCommandsExpanded] = useState(!isMobile);
  const [gpsExpanded, setGpsExpanded] = useState(!isMobile);
  const [firmwareUpgradeExpanded, setFirmwareUpgradeExpanded] = useState(!isMobile);
  const [othersExpanded, setOthersExpanded] = useState(!isMobile);
  const [lastCowGExpanded, setLastCowGExpanded] = useState(!isMobile);
  const [mapFeaturesExpanded, setMapFeaturesExpanded] = useState(!isMobile);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (device != null) {
        const deviceDetail = await fetchDeviceDetailsData(device, atTime);
        setDeviceDetailData(deviceDetail);
      }
      setLoading(false);
    })();
  }, [device, atTime]);

  const history = useHistory();
  const [timeMachineDateTime, setTimeMachineDateTime] = useState<Date | null>(
    atTime == null ? null : new Date(atTime)
  );
  useEffect(() => {
    if (timeMachineDateTime != null) {
      history.push(`${history.location.pathname}?atTime=${timeMachineDateTime.toISOString()}`);
    } else {
      history.push(history.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeMachineDateTime]);

  useEffect(() => {
    dispatch(settingsEffects.fetchMetadata());
  }, [dispatch]);

  const dateToText = (date: Date | undefined) =>
    date ? moment(date).format(DEFAULT_DATE_TIME_FORMAT) : ' - ';

  const reportingWindow = useMemo(() => {
    const data = deviceDetailData?.settingsV2?.settingsObject ?? {};
    const settingsFlattened = flatten<any, { [key: string]: string | number }>(data);
    return Number(settingsFlattened['operational.lora.reportingWindow']);
  }, [deviceDetailData]);

  const generateTableForList = (list: any[] | undefined) => (
    <TableContainer>
      <Table>
        <TableBody>
          {list?.map((reason, index) => (
            <TableRow key={`${index}-${reason}`}>
              <TableCell style={{ border: 'none', padding: '4px' }}>{detailedBitReason(reason)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const generateDebugLink = (serial: string, debugDate: Date) => {
    const dateTo = moment(debugDate).add(1, 'day');
    const dateFrom = moment(debugDate).add(-7, 'day');
    const pageVariable = `var-serialNumber=${serial}&from=${dateFrom.valueOf()}&to=${dateTo.valueOf()}`;
    const shortTermPage = moment().diff(dateFrom, 'days') < 30;
    if (shortTermPage) {
      return `https://halter.grafana.net/d/VQetH7i4z/collar-debug?orgId=1&${pageVariable}`;
    }
    return `https://halter.grafana.net/d/W2fjkt74k/collar-debug-long-term?orgId=1&${pageVariable}`;
  };

  const togglePause = () => {
    if (device?.cattle == null) {
      return;
    }
    CattleService.togglePause(device.cattle);
  };

  return (
    <>
      <LinearProgress variant={loading ? 'query' : 'determinate'} value={100} />
      <Wrapper>
        <Grid container spacing={3}>
          <Grid container spacing={1} direction="row" style={{ marginBottom: '25px', width: '100%' }}>
            <Grid container={!isMobile} item={isMobile} lg={10} xs={12} direction="row">
              <Typography variant="h6" className={`${classes.topTextContent} ${classes.label}`}>
                Cow:
              </Typography>
              <Typography variant="h6">{deviceDetailData?.cattleName}</Typography>
              <Typography variant="h6" className={`${classes.topTextContent} ${classes.label}`}>
                Serial:
              </Typography>
              <Typography variant="h6">{deviceDetailData?.serialNumber}</Typography>
              <Typography variant="h6" className={`${classes.topTextContent} ${classes.label}`}>
                Farm:
              </Typography>
              <Typography variant="h6">{deviceDetailData?.farmName}</Typography>
              <Typography variant="h6" className={`${classes.topTextContent} ${classes.label}`}>
                Mob:
              </Typography>
              <Typography variant="h6">{deviceDetailData?.mobName}</Typography>
              <Typography variant="h6" className={`${classes.topTextContent} ${classes.label}`}>
                Firmware Version:
              </Typography>
              <Typography variant="h6">{deviceDetailData?.firmwareVersion}</Typography>
              {deviceDetailData?.firmwareVersionOk === false && (
                <Typography variant="h6" className={`${classes.topTextContent}`}>
                  Firmware Version NOT OK!
                </Typography>
              )}
              {deviceDetailData?.offPlatform === true && (
                <Typography variant="h6" className={`${classes.topTextContent}`}>
                  Off Platform
                </Typography>
              )}
              {deviceDetailData?.inBarnMode === true && (
                <Typography variant="h6" className={`${classes.topTextContent}`}>
                  BarnMode
                </Typography>
              )}
            </Grid>
            <Grid item lg={2} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  className={classes.timeMachine}
                  value={timeMachineDateTime}
                  onChange={setTimeMachineDateTime}
                  label="Device Time Machine"
                  clearLabel="Current device state"
                  clearable
                  disableFuture
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
        {device != null && (
          <ButtonsWrapper>
            {device.cattle && (
              <>
                <div>Paused:&nbsp;</div>
                <GenericSwitch
                  value={device.cattle.paused}
                  requireConfirmation
                  confirmationMessage={`Are you sure you want to ${
                    device.cattle.paused ? 'unpause' : 'pause'
                  } ${device.cattle.name != null ? `cow ${device.cattle.name}` : ''}, collar ${
                    device.cattle.serialNumber ?? ''
                  }?`}
                  disabled={loading}
                  onChange={togglePause}
                />
              </>
            )}
            <ResetButton device={device} />
            <TagOffButton device={device} />
          </ButtonsWrapper>
        )}

        <Grid container spacing={1} direction="row" style={{ marginBottom: '25px' }}>
          <Grid className={classes.gridWithExpandablePanel} container spacing={1} lg={6} xs={12}>
            <SettingsPanel deviceDetail={deviceDetailData} />
          </Grid>

          <Grid container spacing={1} lg={6} xs={12}>
            <Grid className={classes.gridWithExpandablePanel} container spacing={1}>
              <Accordion
                expanded={swapStatusExpanded}
                onChange={(e, isExpanded) => setSwapStatusExpanded(isExpanded)}
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <Grid item>
                    <Typography variant="h6">Tag Info</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item lg={6} xs={12}>
                      <Typography className={classes.label}>First Tag On: </Typography>
                      <Typography>
                        {dateToText(deviceDetailData?.firstTaggedOnDate)}
                        {deviceDetailData?.firstTaggedOnDate &&
                          ` (${moment().diff(deviceDetailData.firstTaggedOnDate, 'days')} days ago)`}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Typography className={classes.label}>Swap Reason: </Typography>
                      <Typography>{deviceDetailData?.swapStatus?.reason}</Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Typography className={classes.label}>Last Tag On: </Typography>
                      <Typography>
                        {dateToText(deviceDetailData?.lastTaggedOnDate)}
                        {deviceDetailData?.lastTaggedOnDate &&
                          ` (${moment().diff(deviceDetailData.lastTaggedOnDate, 'days')} days ago)`}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Typography className={classes.label}>Request Created: </Typography>
                      <Typography>
                        {deviceDetailData?.swapStatus?.createdDate &&
                          dateToText(new Date(deviceDetailData.swapStatus.createdDate))}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Typography className={classes.label}>Last Tag Off: </Typography>
                      <Typography>
                        {`${dateToText(deviceDetailData?.lastTaggedOffDate)} `}
                        {deviceDetailData?.lastTaggedOffDate && (
                          <Link
                            to={{
                              pathname: generateDebugLink(
                                deviceDetailData.serialNumber,
                                deviceDetailData.lastTaggedOffDate
                              ),
                            }}
                            target="_blank"
                            style={{ color: 'black', display: 'inline-block' }}
                          >
                            <Typography>
                              ({moment().diff(deviceDetailData.lastTaggedOffDate, 'days')} days ago 🛠)
                            </Typography>
                          </Link>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Typography className={classes.label}>Requested By: </Typography>
                      <Typography>{deviceDetailData?.swapStatus?.createdBy}</Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid className={classes.gridWithExpandablePanel} container spacing={1} lg={12} xs={12}>
              <Accordion
                expanded={lastCowGExpanded}
                onChange={(e, isExpanded) => setLastCowGExpanded(isExpanded)}
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <Grid item lg={12} xs={12}>
                    <Typography variant="h6">Last CowG Status</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={3} xs={12}>
                      <Typography className={classes.label}>Statuses: </Typography>
                      {generateTableForList(deviceDetailData?.cowgStatus?.statuses)}
                    </Grid>
                    <Grid item lg={9} xs={12}>
                      <Grid item lg={12} xs={12}>
                        <Typography className={classes.label}>Control Disabled: </Typography>
                        <Typography>{deviceDetailData?.cowgStatus?.controlDisabled?.toString()}</Typography>
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <Typography className={classes.label}>Disabled Reasons: </Typography>
                        {generateTableForList(deviceDetailData?.cowgStatus?.controlDisabledReasons)}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid className={classes.gridWithExpandablePanel} container spacing={1} lg={12} xs={12}>
              <Accordion
                expanded={mapFeaturesExpanded}
                onChange={(e, isExpanded) => setMapFeaturesExpanded(isExpanded)}
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <Grid item lg={12} xs={12}>
                    <Typography variant="h6">Map features</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={12} xs={12}>
                      <Typography className={classes.label}>Status: </Typography>
                      <Typography>
                        {deviceSyncingStatusToTrafficLight(device?.mapFeatures?.status)}
                      </Typography>
                      <Typography>{device?.mapFeatures?.status}</Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography className={classes.label}>Count: </Typography>
                      {device?.mapFeatures && (
                        <Typography>
                          {device?.mapFeatures?.syncedCount} synced out of {device?.mapFeatures?.totalCount}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid className={classes.gridWithExpandablePanel} container spacing={1} lg={12} xs={12}>
              <Accordion
                expanded={othersExpanded}
                onChange={(e, isExpanded) => setOthersExpanded(isExpanded)}
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <Grid item lg={12} xs={12}>
                    <Typography variant="h6">Others</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={6} xs={12}>
                      <Typography className={classes.label}>Paused: </Typography>
                      <Typography>{deviceDetailData?.paused?.toString()}</Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Typography className={classes.label}>Battery Level: </Typography>
                      <Typography>{deviceBatteryStatus(deviceDetailData?.batteryLevenInMv)}</Typography>
                      <Typography>
                        {deviceDetailData == null
                          ? ''
                          : `${deviceDetailData?.batteryPercentage?.toFixed(1)}% (${
                              deviceDetailData?.batteryLevenInMv
                            } mV)`}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Typography className={classes.label}>Last Reset: </Typography>
                      <Typography>{dateToText(deviceDetailData?.lastResetDateTime)}</Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Typography className={classes.label}>Calibration: </Typography>
                      <Typography>{`${
                        deviceDetailData?.imuCalibrationPercentage || ' - '
                      }% good`}</Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography className={classes.label}>Faults: </Typography>
                      <Typography>
                        {deviceFaultsStatus(deviceDetailData?.faultsList, deviceDetailData?.faulty)}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Faulty: </Typography>
                      <Typography>{deviceDetailData?.faulty?.toString()}</Typography>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                      <Typography className={classes.label}>Faults list: </Typography>
                      {generateTableForList(deviceDetailData?.faultsList)}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          <Grid container spacing={1} lg={6} xs={12}>
            <Grid className={classes.gridWithExpandablePanel} container spacing={1}>
              <Accordion
                expanded={commandStatusExpanded}
                onChange={(e, isExpanded) => setCommandStatusExpanded(isExpanded)}
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <Grid item lg={12} xs={12}>
                    <Typography variant="h6">Command Status</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={3} xs={12}>
                      <Typography className={classes.label}>Paddock: </Typography>
                      {atTime == null ? (
                        <Typography>{deviceDetailData?.commandStatus?.commandPaddockName}</Typography>
                      ) : (
                        <Typography style={{ opacity: 0.8 }}>Not available in the time machine</Typography>
                      )}
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <Typography className={classes.label}>Active command id: </Typography>
                      <Typography>{deviceDetailData?.commandStatus?.collarCommandId}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Type: </Typography>
                      <Typography>
                        {CommandType[deviceDetailData?.commandStatus?.commandType ?? 0]}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <Typography className={classes.label}>In zone: </Typography>
                      <Typography>
                        {deviceDetailData?.commandStatus?.lastCommandStatus?.inZone?.toString()}
                      </Typography>
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <Typography className={classes.label}>Outside boundary buffer: </Typography>
                      <Typography>
                        {deviceDetailData?.commandStatus?.lastCommandStatus?.outsideBoundaryBuffer?.toString()}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Left zone count: </Typography>
                      <Typography>
                        {deviceDetailData?.commandStatus?.lastCommandStatus?.leftZoneCount}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography className={classes.label}>Shock summary</Typography>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <Typography className={classes.label}>Count</Typography>
                      <Typography>
                        {deviceDetailData?.commandStatus?.lastCommandStatus?.shockSummary?.count}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <Typography className={classes.label}>Shock Lockout:</Typography>
                      <Typography>
                        {deviceDetailData?.commandStatus?.lastCommandStatus?.shockSummary?.lockoutCount}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <Typography className={classes.label}>Failed Shocks:</Typography>
                      <Typography>
                        {deviceDetailData?.commandStatus?.lastCommandStatus?.shockSummary?.failedCount}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid className={classes.gridWithExpandablePanel} container spacing={1}>
              <Accordion
                expanded={commsStatusExpanded}
                onChange={(e, isExpanded) => setCommsStatusExpanded(isExpanded)}
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <Grid item lg={12} xs={12}>
                    <Typography variant="h6">Comms Status</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={12} xs={12}>
                      <Typography className={classes.label}>Reporting Window: </Typography>
                      <Typography>{reportingWindow ? `${reportingWindow / 60} Minutes` : ''}</Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography className={classes.label}>Last Lora checkin: </Typography>
                      <Typography>
                        {deviceLoraStatus(
                          deviceDetailData?.commsStatus?.lastLoraMessageDateTime,
                          reportingWindow,
                          atTime ?? new Date()
                        )}
                      </Typography>
                      <Typography>
                        {dateToText(deviceDetailData?.commsStatus?.lastLoraMessageDateTime)}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography className={classes.label}>Last Wifi checkin: </Typography>
                      <Typography>
                        {deviceWifiStatus(
                          deviceDetailData?.commsStatus?.lastWifiCheckinDateTime,
                          atTime ?? new Date()
                        )}
                      </Typography>
                      <Typography>
                        {dateToText(deviceDetailData?.commsStatus?.lastWifiCheckinDateTime)}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography className={classes.label}>Last Heartbeat: </Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Date time: </Typography>
                      <Typography>
                        {dateToText(deviceDetailData?.commsStatus?.lastWifiCheckinDateTime)}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Status: </Typography>
                      <Typography>{deviceDetailData?.commsStatus?.lastHeartbeatStatus}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Comms: </Typography>
                      <Typography>{deviceDetailData?.commsStatus?.lastHeartbeatComms}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>WiFi reported position: </Typography>
                      <Typography>
                        {deviceDetailData?.commsStatus?.wifiReportedGps?.latitude},
                        {deviceDetailData?.commsStatus?.wifiReportedGps?.longitude}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid className={classes.gridWithExpandablePanel} container spacing={1}>
              <Accordion
                expanded={allCollarCommandsExpanded}
                onChange={(e, isExpanded) => setAllCollarCommandsExpanded(isExpanded)}
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <Grid item lg={12} xs={12}>
                    <Typography variant="h6">All collar commands</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {atTime == null ? (
                    <Grid container spacing={1} xs={12}>
                      <Grid item lg={12} xs={12}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography
                              className={classes.commandSetHashTitle}
                              gutterBottom
                              variant="subtitle1"
                            >
                              {deviceCommandSetHashStatus(
                                deviceDetailData?.deviceStateReport?.commandSetHashValue,
                                deviceDetailData?.backendDeviceCommandHash
                              )}{' '}
                              Command Set Hash
                            </Typography>
                            <Grid container lg={12} xs={12}>
                              <Grid item lg={3} sm={6} xs={12}>
                                <Typography className={classes.label}>Device</Typography>
                                <Typography>
                                  {deviceDetailData?.deviceStateReport?.commandSetHashValue}
                                </Typography>
                              </Grid>
                              <Grid item lg={3} sm={6} xs={12}>
                                <Typography className={classes.label}>Backend</Typography>
                                <Typography>{deviceDetailData?.backendDeviceCommandHash}</Typography>
                              </Grid>
                              <Grid item lg={6} sm={6} xs={12}>
                                <Typography className={classes.label}>UpdatedAt</Typography>
                                <Typography>
                                  {dateToText(deviceDetailData?.deviceStateReport?.lastUpdate)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Divider variant="middle" />
                      <Grid item lg={12} xs={12}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <TableSortLabel>Paddock</TableSortLabel>
                                </TableCell>
                                <TableCell>
                                  <TableSortLabel>Status</TableSortLabel>
                                </TableCell>
                                <TableCell>
                                  <TableSortLabel>Type</TableSortLabel>
                                </TableCell>
                                <TableCell>
                                  <TableSortLabel>Id</TableSortLabel>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {deviceDetailData?.collarCommands?.map((command) => (
                                <TableRow key={command.collarCommandId}>
                                  <TableCell>{command.paddockName}</TableCell>
                                  <TableCell>{command.status}</TableCell>
                                  <TableCell>{command.commandType}</TableCell>
                                  <TableCell>{command.collarCommandId}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography style={{ opacity: 0.8 }}>
                      Collar commands are not available in the time machine
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          <Grid container spacing={1} lg={6} xs={12}>
            <Grid className={classes.gridWithExpandablePanel} container spacing={1}>
              <Accordion
                expanded={firmwareUpgradeExpanded}
                onChange={(e, isExpanded) => setFirmwareUpgradeExpanded(isExpanded)}
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <Grid item lg={12} xs={12}>
                    <Typography variant="h6">Firmware Upgrade</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {atTime == null ? (
                    <Grid container spacing={1}>
                      <Grid item lg={4} xs={12}>
                        <Typography className={classes.label}>Release channel: </Typography>
                        <Typography>{deviceDetailData?.otaStatus?.channelName}</Typography>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <Typography className={classes.label}>Target version: </Typography>
                        <Typography>{deviceDetailData?.otaStatus?.targetVersion}</Typography>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <Typography className={classes.label}>State: </Typography>
                        <Typography>{deviceDetailData?.otaStatus?.state}</Typography>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <Typography className={classes.label}>Retries attempted:</Typography>
                        <Typography>{deviceDetailData?.otaStatus?.retriesAttempted?.toString()}</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography style={{ opacity: 0.8 }}>
                      Firmware upgrade job status is not available in the time machine
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid className={classes.gridWithExpandablePanel} container spacing={1} lg={12} xs={12}>
              <Accordion
                expanded={gpsExpanded}
                onChange={(e, isExpanded) => setGpsExpanded(isExpanded)}
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <Grid item lg={12} xs={12}>
                    <Typography variant="h6">GPS</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Fix type: </Typography>
                      <Typography>{deviceDetailData?.gps?.fixType}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Fix age: </Typography>
                      <Typography>{deviceDetailData?.gps?.fixAge}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Hdop: </Typography>
                      <Typography>{deviceDetailData?.gps?.hdop}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Satellite CNR (dBHz): </Typography>
                      <Typography>{deviceDetailData?.gps?.satelliteCnrDbHz}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Latitude: </Typography>
                      <Typography>{deviceDetailData?.gps?.latitude}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Longitude: </Typography>
                      <Typography>{deviceDetailData?.gps?.longitude}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Num satellites: </Typography>
                      <Typography>{deviceDetailData?.gps?.numOfSatellites}</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Typography className={classes.label}>Timestamp: </Typography>
                      <Typography>
                        {deviceDetailData?.gps?.timestamp != null
                          ? dateToText(new Date(deviceDetailData?.gps?.timestamp))
                          : null}
                      </Typography>
                    </Grid>
                    {deviceDetailData?.gps && (
                      <SingleDeviceMapView
                        serialNumber={serialNumber}
                        farmId={deviceDetailData?.farmId}
                        location={{
                          latitude: deviceDetailData?.gps?.latitude,
                          longitude: deviceDetailData?.gps?.longitude,
                        }}
                      />
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default DeviceDetailsScreen;
