import React, { FunctionComponent, useState } from 'react';
import { TextField, Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import Button from 'application/components/button';
import ConfirmationModal from 'application/components/confirmation.modal';

const SEPARATOR = ',';

type RequestSwapFormPanelProps = {
  handleClose: () => void;
  handleCreate: (serialNumbers: string[], reason: string, comments?: string) => void;
};

const RequestSwapFormPanel: FunctionComponent<RequestSwapFormPanelProps> = ({
  handleClose,
  handleCreate,
}) => {
  const swapReasons = [
    'Calibration (old)',
    'Hardware (old)',
    'Power (old)',
    'Shock (old)',
    'Other (old)',
    'Remove Request (old)',
    "Create 'take off cow' task (new)",
    "Cancel 'take off cow' task (new)",
  ];

  const [swapReason, setSwapReason] = useState('');
  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);
  const [confirmationPopupVisible, setConfirmationPopupVisible] = useState(false);
  const [comments, setComments] = useState('');

  const handleSwapReasonChange = (e: any) => {
    setSwapReason(e.target.value);
  };

  const validateForm = () => {
    if (!(swapReason === 'Other' && comments === '')) {
      setConfirmationPopupVisible(true);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <InputLabel style={{ marginBottom: '8px' }}>Serial No.</InputLabel>
        <TextField
          style={{ backgroundColor: 'white' }}
          value={serialNumbers}
          onChange={(event) => {
            setSerialNumbers(event.target.value.trim().split(SEPARATOR));
          }}
          fullWidth
          variant="outlined"
          placeholder="Use commas to separate serial no."
        />
      </Grid>

      <Grid item xs={12}>
        <InputLabel style={{ marginBottom: '8px' }}>Reason</InputLabel>
        {swapReasons?.length > 0 && (
          <Select
            displayEmpty
            value={swapReason}
            onChange={(e) => handleSwapReasonChange(e)}
            fullWidth
            required
            variant="outlined"
          >
            {swapReasons?.map((reason) => (
              <MenuItem key={reason} value={reason}>
                {reason}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
      <Grid item xs={12}>
        <InputLabel style={{ marginBottom: '8px' }}>Comments</InputLabel>
        <TextField
          multiline
          rows={3}
          type="string"
          value={comments}
          onChange={(event) => setComments(event.target.value)}
          variant="outlined"
          fullWidth
          required
          error={comments === '' && swapReason === 'Other'}
        />
      </Grid>
      <Grid item container xs={6} justifyContent="flex-start">
        <Button
          text="Cancel"
          fullWidth
          borderRadius={8}
          onClick={handleClose}
          backgroundColour="white"
          colour="black"
          style={{ border: 'solid', borderStyle: 'thin' }}
        />
      </Grid>
      <Grid item container xs={6} justifyContent="flex-end">
        <Button
          text="Request Swap"
          fullWidth
          borderRadius={8}
          onClick={validateForm}
          backgroundColour="black"
          colour="white"
          size="large"
        />
        <ConfirmationModal
          confirmationMessage={`Are you sure you want to request swap for ${serialNumbers.length} collars? This will show as a collar issue in app.`}
          open={confirmationPopupVisible}
          onDismiss={() => setConfirmationPopupVisible(false)}
          onConfirm={() => handleCreate(serialNumbers, swapReason, comments)}
        />
      </Grid>
    </>
  );
};

export default RequestSwapFormPanel;
