import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { SearchResultTypeEnum } from '@halter-corp/bff-debug-tool-service-client';

export type ItemDiscriminator = {
  itemType: SearchResultTypeEnum;
  name: string;
};

export const findAllLocations = createSelector(
  (state: AppState) => state.mapPageItems.data,
  (d) => ({ cowg: d.cowg, positions: d.positions })
);
export const findAllCowgEvents = createSelector(
  (state: AppState) => state.mapPageItems.data,
  (d) => d.cowg
);
export const findAllCommands = createSelector(
  (state: AppState) => state.mapPageItems.data,
  (d) => d.commands
);

export const findEventsForDateRange = createSelector(
  findAllLocations,
  findAllCowgEvents,
  (_state: AppState, props: { minimumDate: Date | null }) => props.minimumDate?.getTime(),
  (_state: AppState, props: { maximumDate: Date | null }) => props.maximumDate?.getTime(),
  (locations, cowgEvents, minTimestamp, maxTimestamp) => {
    const filterOnDateRange = (date: Date) => {
      const timestamp = new Date(date).getTime();
      if (minTimestamp != null && timestamp < minTimestamp) return false;
      if (maxTimestamp != null && timestamp > maxTimestamp) return false;
      return true;
    };
    return {
      locations: locations.positions.filter((location) => filterOnDateRange(location.timestamp)),
      cowg: cowgEvents.filter((cowgEvent) => filterOnDateRange(cowgEvent.timestamp)),
    };
  }
);
