import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICreateTowerNoteRequestDTO,
  ICreateTowerRequestDTO,
  IDeviceContextDTO,
  IDeviceStatusDTO,
  ILoraCoverageHeatMapCellDTO,
  ITowerDTO,
  ILoraCoverageSelectionDTO,
  ITowerNoteDTO,
  IUpdateDeviceSettingsRequestDTO,
  IDeviceSettingsDTO,
  IUpdateDeviceContextRequestDTO,
  IUpdateDeviceStatusRequestDTO,
  IUpdateTowerNoteRequestDTO,
  IUpdateTowerRequestDTO,
  INetworkDeviceContextDTO,
  ICreateTowerFarmMappingDTO,
  ITowerFarmMappingDTO,
} from '@halter-corp/tower-service-client';
import TowerService from 'services/tower.service';
import { flatten } from 'lodash';

const towerEffects = {
  fetchAllTowers: createAsyncThunk('tower/fetchAllTowers', async (): Promise<ITowerDTO[] | []> => {
    const data = await TowerService.fetchAllTowers();
    return data;
  }),
  fetchAllTowersByRadiusAndLatLng: createAsyncThunk(
    'tower/fetchAllTowersByRadiusAndLatLng',
    async (props: { latitude: number; longitude: number; radius: number }): Promise<ITowerDTO[]> => {
      const { latitude, longitude, radius } = props;
      const externalTowerList = await TowerService.fetchAllTowersByRadiusAndLatLng(
        latitude,
        longitude,
        radius
      );
      return externalTowerList;
    }
  ),
  saveTower: createAsyncThunk(
    'tower/saveTower',
    async (props: { createTowerRequest: ICreateTowerRequestDTO }): Promise<ITowerDTO | null> => {
      const { createTowerRequest } = props;
      const newTower = await TowerService.saveTower(createTowerRequest);
      return newTower;
    }
  ),
  fetchTowersByFarmId: createAsyncThunk(
    'tower/fetchTowersByFarmId',
    async (props: { farmId: string }): Promise<ITowerDTO[] | null> => {
      try {
        if (props.farmId?.trim() === '') throw new Error('Farm ID cannot be empty');
        const data = await TowerService.fetchTowersByFarmId(props.farmId);
        return data;
      } catch (err) {
        return null;
      }
    }
  ),
  fetchTowerById: createAsyncThunk(
    'tower/fetchTowerById',
    async (props: { id: string }): Promise<ITowerDTO | null> => {
      try {
        if (props.id?.trim() === '') throw new Error('Tower ID cannot be empty');
        const data = await TowerService.fetchTowerById(props.id);
        return data;
      } catch (err) {
        return null;
      }
    }
  ),
  updateTowerById: createAsyncThunk(
    'tower/updateTowerById',
    async (props: {
      towerId: string;
      updateTowerRequest: IUpdateTowerRequestDTO;
    }): Promise<ITowerDTO | null> => {
      const { towerId, updateTowerRequest } = props;
      const updatedTower = await TowerService.updateTowerById(towerId, updateTowerRequest);
      return updatedTower;
    }
  ),
  deleteTowerById: createAsyncThunk(
    'tower/deleteTowerById',
    async (props: { towerId: string }): Promise<string | null> => {
      const { towerId } = props;
      await TowerService.deleteTowerById(towerId);
      return towerId;
    }
  ),
  saveTowerNote: createAsyncThunk(
    'tower/saveTowerNote',
    async (props: {
      createTowerNoteRequest: ICreateTowerNoteRequestDTO;
      towerId: string;
    }): Promise<ITowerNoteDTO | null> => {
      const { createTowerNoteRequest, towerId } = props;
      createTowerNoteRequest.towerId = towerId;
      const newTowerNote = await TowerService.saveTowerNote(createTowerNoteRequest);
      return newTowerNote;
    }
  ),
  fetchNotesByTowerId: createAsyncThunk(
    'tower/fetchNotesByTowerId',
    async (props: { towerId: string }): Promise<ITowerNoteDTO[] | []> => {
      const { towerId } = props;
      const notes = await TowerService.fetchTowerNotesByTowerId(towerId);
      return notes;
    }
  ),
  fetchNotesByTowers: createAsyncThunk(
    'tower/fetchNotesByTowers',
    async (props: { towers: ITowerDTO[] }): Promise<ITowerNoteDTO[]> => {
      const { towers } = props;
      if (towers.length !== 0) {
        const allNotes = await Promise.all(
          towers.map(async (tower) => {
            const notes = await TowerService.fetchTowerNotesByTowerId(tower.id);
            return notes ?? [];
          })
        );
        return flatten(allNotes);
      }
      return [];
    }
  ),
  updateNoteByTowerId: createAsyncThunk(
    'tower/updateNoteByTowerId',
    async (props: {
      towerId: string;
      createdAt: number;
      updateNoteDto: IUpdateTowerNoteRequestDTO;
    }): Promise<ITowerNoteDTO | null> => {
      const { towerId, createdAt, updateNoteDto } = props;
      return TowerService.updateTowerNoteByTowerIdAndCreatedAt(towerId, createdAt, updateNoteDto);
    }
  ),
  deleteNoteByTowerId: createAsyncThunk(
    'tower/deleteTowerNoteById',
    async (props: {
      towerId: string;
      createdAt: number;
    }): Promise<{ towerId: string; createdAt: number } | null> => {
      const { towerId, createdAt } = props;
      await TowerService.deleteTowerNoteByTowerIdAndCreatedAt(towerId, createdAt);
      return { towerId, createdAt };
    }
  ),
  fetchAllDeviceContextByFarmId: createAsyncThunk(
    'tower/fetchAllDeviceContextByFarmId',
    async (props: { farmId: string }): Promise<IDeviceContextDTO[]> => {
      const { farmId } = props;
      const deviceContext = await TowerService.fetchAllDeviceContextByFarmId(farmId);
      return deviceContext;
    }
  ),
  fetchAllDeviceContextByFarmAndTowers: createAsyncThunk(
    'tower/fetchAllDeviceContextByFarmAndTowers',
    async (props: { towerIds: string[]; farmId: string }): Promise<IDeviceContextDTO[]> => {
      const { towerIds, farmId } = props;
      let deviceContextList: IDeviceContextDTO[] = [];
      await Promise.all(
        towerIds.map(async (towerId) => {
          const towerDeviceContextList = await TowerService.fetchAllDeviceContextByTowerId(towerId);
          deviceContextList = deviceContextList.concat(towerDeviceContextList);
        })
      );
      const farmDevices = await TowerService.fetchAllDeviceContextByFarmId(farmId);
      deviceContextList = deviceContextList.concat(farmDevices);

      return deviceContextList;
    }
  ),
  fetchAllDeviceContextByShardId: createAsyncThunk(
    'tower/fetchAllDeviceContextByShardId',
    async (props: { shardId: string }): Promise<IDeviceContextDTO[]> => {
      const { shardId } = props;
      const deviceContext = await TowerService.fetchAllDeviceContextByShardId(shardId);
      return deviceContext;
    }
  ),
  fetchAllDeviceContextById: createAsyncThunk(
    'tower/fetchAllDeviceContextById',
    async (props: { id: string }): Promise<IDeviceContextDTO[]> => {
      const { id } = props;
      const deviceContext = await TowerService.fetchAllDeviceContextById(id);
      return deviceContext;
    }
  ),
  fetchAllNetworkDeviceContextByTowerIdList: createAsyncThunk(
    'tower/fetchAllNetworkDeviceContextByTowerIdList',
    async (props: { towerIdList: string[] }): Promise<INetworkDeviceContextDTO[]> => {
      const { towerIdList } = props;

      const networkDeviceContextList: INetworkDeviceContextDTO[] = [];
      await Promise.all(
        towerIdList.map(async (Id) => {
          const result = await TowerService.fetchAllNetworkDeviceContextsByTowerId(Id);
          networkDeviceContextList.push(...result);
        })
      );

      return networkDeviceContextList;
    }
  ),
  updateDeviceContextByIdAndFarmId: createAsyncThunk(
    'tower/updateDeviceContextByIdAndFarmId',
    async (props: {
      id: string;
      farmId: string;
      updateDeviceContextDto: IUpdateDeviceContextRequestDTO;
    }): Promise<IDeviceContextDTO | null> => {
      const { id, farmId, updateDeviceContextDto } = props;
      return TowerService.updateDeviceContextByIdAndFarmId(id, farmId, updateDeviceContextDto);
    }
  ),
  fetchDeviceStatusByDevices: createAsyncThunk(
    'tower/fetchDeviceStatusByDvices',
    async (props: { devices: IDeviceContextDTO[] }): Promise<IDeviceStatusDTO[]> => {
      const { devices } = props;
      const deviceStatuses: IDeviceStatusDTO[] = [];

      const fetchedData = await Promise.all(
        devices.map((device) => TowerService.fetchDeviceStatusById(device.id))
      );
      fetchedData.forEach((data) => data != null && deviceStatuses.push(data));

      return deviceStatuses;
    }
  ),
  fetchDeviceStatusById: createAsyncThunk(
    'tower/fetchDeviceStatusById',
    async (props: { id: string }): Promise<IDeviceStatusDTO | null> => {
      const { id } = props;
      const deviceStatus = await TowerService.fetchDeviceStatusById(id);
      return deviceStatus;
    }
  ),
  updateDeviceStatusById: createAsyncThunk(
    'tower/updateDeviceStatusById',
    async (props: {
      id: string;
      updateDeviceStatusDto: IUpdateDeviceStatusRequestDTO;
    }): Promise<IDeviceStatusDTO | null> => {
      const { id, updateDeviceStatusDto } = props;
      return TowerService.updateDeviceStatusById(id, updateDeviceStatusDto);
    }
  ),
  fetchDeviceSettingsByIds: createAsyncThunk(
    'tower/fetchDeviceSettingsByIds',
    async (props: { ids: string[] }): Promise<IDeviceSettingsDTO[]> => {
      const { ids } = props;
      const deviceSettings: IDeviceSettingsDTO[] = [];

      const fetchedData = await Promise.all(ids.map((id) => TowerService.fetchDeviceSettingsById(id)));
      fetchedData.forEach((data) => data != null && deviceSettings.push(data));

      return deviceSettings;
    }
  ),
  fetchDeviceSettingsById: createAsyncThunk(
    'tower/fetchDeviceSettingsById',
    async (props: { id: string }): Promise<IDeviceSettingsDTO | null> => {
      const { id } = props;
      const deviceStatus = await TowerService.fetchDeviceSettingsById(id);
      return deviceStatus;
    }
  ),
  updateDeviceSettingsById: createAsyncThunk(
    'tower/updateDeviceSettingsById',
    async (props: {
      id: string;
      updateDeviceSettingsDto: IUpdateDeviceSettingsRequestDTO;
    }): Promise<IDeviceSettingsDTO | null> => {
      const { id, updateDeviceSettingsDto: updateDeviceStatusDto } = props;
      return TowerService.updateDeviceSettingsById(id, updateDeviceStatusDto);
    }
  ),
  fetchAllHeatMapsByGateways: createAsyncThunk(
    'tower/fetchAllHeatMapsByGateways',
    async (props: { gatewayIds: string[]; resolution?: number }): Promise<ILoraCoverageHeatMapCellDTO[]> => {
      const { gatewayIds, resolution } = props;

      const gatewayHeatMaps = await Promise.all(
        gatewayIds.map(async (gatewayId): Promise<ILoraCoverageHeatMapCellDTO[]> => {
          const heatMapCells = await TowerService.fetchLoraCoverageHeatMapById(gatewayId, resolution);
          return heatMapCells || [];
        })
      );

      return flatten(gatewayHeatMaps);
    }
  ),
  fetchSelectionByGatewayIds: createAsyncThunk(
    'tower/fetchSelectionByGatewayIds',
    async (props: { gatewayIds: string[] }): Promise<ILoraCoverageSelectionDTO[]> => {
      const { gatewayIds } = props;

      let selectionList = await Promise.all(
        gatewayIds.map(async (gatewayId): Promise<ILoraCoverageSelectionDTO | null> => {
          const selection = await TowerService.fetchSelectionByGatewayId(gatewayId);
          return selection;
        })
      );
      selectionList = selectionList.filter((selection) => selection !== null);

      return selectionList as ILoraCoverageSelectionDTO[];
    }
  ),
  saveTowerFarmMapping: createAsyncThunk(
    'tower/saveTowerFarmMapping',
    async (props: {
      createTowerFarmMappingRequest: ICreateTowerFarmMappingDTO;
    }): Promise<ITowerFarmMappingDTO | null> => {
      const { createTowerFarmMappingRequest } = props;
      const newTowerFarmMapping = await TowerService.saveTowerFarmMapping(createTowerFarmMappingRequest);
      return newTowerFarmMapping;
    }
  ),
  deleteTowerFarmMappingByFarmIDAndTowerID: createAsyncThunk(
    'tower/saveTowerFarmMapping',
    async (props: {
      farmId: string;
      towerId: string;
    }): Promise<{ farmId: string; towerId: string } | null> => {
      const { farmId, towerId } = props;
      await TowerService.deleteTowerFarmMappingByFarmIDAndTowerID(farmId, towerId);
      return { farmId, towerId };
    }
  ),
};

export default towerEffects;
