import { createSlice } from '@reduxjs/toolkit';

import { reset } from 'store/actions';
import deviceEffects from 'store/effects/device.effects';
import { Device } from '../../data/device';

type DeviceState = {
  device?: Device;
};

const initialState: DeviceState = {
  device: undefined,
};

const deviceSlice = createSlice({
  initialState,
  name: 'device',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(deviceEffects.fetchBySerialNumber.fulfilled, (state, action) => {
        state.device = action.payload;
      }),
});

export default deviceSlice;
