import React, { FunctionComponent } from 'react';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';
import ModalButton from './modal-button';

const ModalBody = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  max-width: 360px;
`;

const Dialog = styled.div`
  padding-bottom: 16px;
  text-align: center;
`;

const ButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  padding: 16px 32px 16px 32px;
`;

type ConfirmationModalProps = {
  confirmationMessage: string;
  open: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
};

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  confirmationMessage,
  open,
  onDismiss,
  onConfirm,
}) => (
  <Modal
    open={open}
    onClose={onDismiss}
    disableAutoFocus
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', outline: 'none' }}
  >
    <ModalBody>
      <Dialog>{confirmationMessage}</Dialog>
      <ButtonPanel>
        <ModalButton
          onPress={onDismiss}
          text="No"
          style={{ border: '1px solid #009e60', color: '#009e60' }}
        />
        <ModalButton onPress={onConfirm} text="Yes" style={{ backgroundColor: '#009e60', color: 'white' }} />
      </ButtonPanel>
    </ModalBody>
  </Modal>
);

export default ConfirmationModal;
