import React, { FunctionComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import styled from 'styled-components';
import ReactGA from 'react-ga';

const StyledButton = styled(Button)`
  margin: 0 0 0 20px;
  minwidth: 175px;
`;

type SerialLoadSectionProps = { onClickLoad: (serialNumber: string) => Promise<void> };

const SerialLoadSection: FunctionComponent<SerialLoadSectionProps> = ({ onClickLoad }) => {
  const { handleChange, values, handleSubmit } = useFormik({
    initialValues: {
      serialNumber: '',
    },
    onSubmit: () => {
      ReactGA.event({
        category: 'Engineering Settings',
        action: 'Load',
      });
      onClickLoad(values.serialNumber);
    },
  });

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        id="serialNumber"
        label="Serial Number"
        value={values.serialNumber}
        onChange={handleChange}
        placeholder="002-0009-00390"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <StyledButton variant="contained" size="large" color="primary" type="submit">
        Load Settings
      </StyledButton>
    </form>
  );
};

export default SerialLoadSection;
