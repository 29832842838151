import { createSelector } from '@reduxjs/toolkit';
import { DeviceOnFarmStateEnum } from '@halter-corp/fleet-service-client';
import { mapValues } from 'lodash';
import { AppState } from '../store';

export const selectOffFarmCheckpointsByCheckpointSlice = (state: AppState) =>
  state.fleet.offFarmCheckpointsByCheckpoint;
export const selectOffFarmChecpointsByCheckpointStatusSlice = (state: AppState) =>
  state.fleet.offFarmCheckpointsByCheckpointStatus;

export const selectDeviceOnFarmStatesByFarmIdSlice = (state: AppState) =>
  state.fleet.deviceOnFarmStatesByFarmId;
export const selectDeviceOnFarmStatesByFarmIdStatusSlice = (state: AppState) =>
  state.fleet.deviceOnFarmStatesByFarmIdStatus;
export const selectDeviceOnFarmStatusCountsByFarmIdSlice = (state: AppState) =>
  state.fleet.deviceOnFarmStatusCountsByFarmId;
export const selectDeviceOnFarmStatusCountsByFarmIdStatusSlice = (state: AppState) =>
  state.fleet.deviceOnFarmStatusCountsByFarmIdStatus;

export const getOffFarmCheckpointsByCheckpoint = createSelector(
  selectOffFarmCheckpointsByCheckpointSlice,
  (items) => items
);
export const isOffFarmCheckpointsByCheckpointLoading = createSelector(
  selectOffFarmChecpointsByCheckpointStatusSlice,
  (status) => status === 'loading'
);

export const getDeviceOnFarmStatesByFarmId = createSelector(
  selectDeviceOnFarmStatesByFarmIdSlice,
  (items) => items
);
export const isDeviceOnFarmStatesByFarmIdLoading = createSelector(
  selectDeviceOnFarmStatesByFarmIdStatusSlice,
  (status) => status === 'loading'
);
export const hasDeviceOnFarmStatesByFarmIdFailed = createSelector(
  selectDeviceOnFarmStatesByFarmIdStatusSlice,
  (status) => status === 'error'
);
export const getDeprovisionedDeviceOnFarmStatesByFarmId = createSelector(
  selectDeviceOnFarmStatesByFarmIdSlice,
  (devicesByFarmId) =>
    mapValues(devicesByFarmId ?? {}, (devices) =>
      devices.filter((device) => device.status === DeviceOnFarmStateEnum.DEPROVISIONED)
    )
);
export const getDeviceOnFarmStatusCountsByFarmId = createSelector(
  selectDeviceOnFarmStatusCountsByFarmIdSlice,
  (items) => items
);
export const isDeviceOnFarmStatusCountsByFarmIdLoading = createSelector(
  selectDeviceOnFarmStatusCountsByFarmIdStatusSlice,
  (status) => status === 'loading'
);
export const hasDeviceOnFarmStatusCountsByFarmIdFailed = createSelector(
  selectDeviceOnFarmStatusCountsByFarmIdStatusSlice,
  (status) => status === 'error'
);
