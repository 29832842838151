import { useMemo } from 'react';
import { groupBy, sortBy } from 'lodash';

import { Modifier, Profile } from 'data/training';
import { Dictionary } from '@reduxjs/toolkit';

export const useProfilesByType = (profiles: Profile[]): Dictionary<Profile[]> =>
  useMemo(() => {
    const sortedProfiles = sortBy(profiles, ({ name }) => name.toLowerCase());

    return groupBy(sortedProfiles, (profile) => profile.type);
  }, [profiles]);

export const useModifiersByType = (modifiers: Modifier[]): Dictionary<Modifier[]> =>
  useMemo(() => {
    const sortedModifiers = sortBy(modifiers, ({ name }) => name.toLowerCase());

    return groupBy(sortedModifiers, (modifier) => modifier.type);
  }, [modifiers]);
