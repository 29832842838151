import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';

const MetricsContainer: FunctionComponent = ({ children }) => (
  <Grid container spacing={2}>
    {children}
  </Grid>
);

export default MetricsContainer;
