import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import HeatMapService from 'services/heat-map.service';
import heatMapEffects from 'store/effects/heat-map.effects';
import { bbox } from 'turf';
import { Bounds, DataMapSearch } from '../panels/data-map.panel';
import { stringToColour } from '../util/utils';

type SlotGroupsLayerProps = {
  search: DataMapSearch;
  recenterMap: (bounds: Bounds) => void;
  refresh: boolean;
};

const SlotGroupsLayer: FunctionComponent<SlotGroupsLayerProps> = ({ search, recenterMap, refresh }) => {
  const dispatch = useDispatch();

  const slotGroups = HeatMapService.useFetchSlotGroups();

  useEffect(() => {
    if (!search.farmIds) return;
    dispatch(heatMapEffects.fetchAllSlotGroupsByFarmIds(search?.farmIds));
  }, [JSON.stringify(search), refresh]);

  // Recenter Map
  useMemo(() => {
    if (slotGroups.length > 0) {
      let bboxes: number[][] = [];
      slotGroups.forEach((slotGroup) => {
        const innerBboxes = slotGroup.features.map((feature) => bbox(feature));
        bboxes = bboxes.concat(innerBboxes);
      });
      const bounds = bboxes.reduce(
        (acc, bb) => ({
          minLng: Math.min(acc.minLng, bb[0]),
          minLat: Math.min(acc.minLat, bb[1]),
          maxLng: Math.max(acc.maxLng, bb[2]),
          maxLat: Math.max(acc.maxLat, bb[3]),
        }),
        { minLng: Infinity, minLat: Infinity, maxLng: -Infinity, maxLat: -Infinity }
      );

      if (bounds.minLng !== Infinity) {
        recenterMap(bounds);
      }
    }
  }, [slotGroups]);

  return (
    <>
      {slotGroups.map((slotGroup, i) =>
        slotGroup.features.map((feature, j) => (
          // eslint-disable-next-line react/no-array-index-key
          <Source type="geojson" data={feature} key={`${feature.properties?.name}${i}${j}`}>
            <Layer
              type="fill"
              paint={{
                'fill-outline-color': '#fffeee',
                'fill-color': stringToColour(slotGroup.id),
                'fill-opacity': 0.8,
              }}
            />
          </Source>
        ))
      )}
    </>
  );
};
export default SlotGroupsLayer;
