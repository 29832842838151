import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Input,
  InputLabel,
  List,
  ListItem,
} from '@material-ui/core';
import { IResetPortsJobRequestDTO } from '@halter-corp/thing-service-client';
import ThingService from 'services/thing.service';
import Button from 'application/components/button';
import { DeviceActionDialogProps } from '../DevicesActionsWrapper';

const ResetPortsDialog: FunctionComponent<DeviceActionDialogProps> = ({ open, onClose, targets }) => {
  const [ports, setPorts] = useState<number[]>([]);
  const [durationInSec, setDurationInSec] = useState(30);

  function handleSetDuration(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setDurationInSec(Number(e.target.value));
  }

  function getCheckboxData(): HTMLInputElement[] {
    return Array.from(document.getElementsByName('ports')) as HTMLInputElement[];
  }

  function handleSetPorts() {
    const selectedPorts = getCheckboxData()
      .filter((el) => el.checked)
      .map((el) => Number(el.value));
    setPorts(selectedPorts);
  }

  const resetRequest = {
    targets,
    ports,
    durationInSec,
  } as IResetPortsJobRequestDTO;

  function handleRequest() {
    if (durationInSec < 1 || durationInSec > 300) {
      // eslint-disable-next-line no-alert
      alert('Duration should be beetween 1 and 300 seconds inclusive');
    } else {
      onClose();
      ThingService.requestResetPortsJob(resetRequest);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Set reset parameters</DialogTitle>
      <List>
        <ListItem>
          <FormGroup onChange={handleSetPorts}>
            <FormControlLabel
              label="Port 1"
              control={
                <Checkbox color="primary" value={1} inputProps={{ name: 'ports' }} defaultChecked={false} />
              }
            />
            <FormControlLabel
              label="Port 2"
              control={
                <Checkbox color="primary" value={2} inputProps={{ name: 'ports' }} defaultChecked={false} />
              }
            />
            <FormControlLabel
              label="Port 3"
              control={
                <Checkbox color="primary" value={3} inputProps={{ name: 'ports' }} defaultChecked={false} />
              }
            />
            <FormControlLabel
              label="Port 4"
              control={
                <Checkbox color="primary" value={4} inputProps={{ name: 'ports' }} defaultChecked={false} />
              }
            />
            <InputLabel style={{ gap: 10 }}>
              Reset duration in seconds:
              <Input type="number" value={durationInSec} onChange={handleSetDuration} required />
            </InputLabel>
          </FormGroup>
        </ListItem>
        <ListItem>
          <Button text="Reset selected ports" onClick={handleRequest} />
        </ListItem>
      </List>
    </Dialog>
  );
};

export default ResetPortsDialog;
