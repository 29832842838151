import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';

import * as envConfig from '../env-config';

import Application from './application';

Amplify.configure(envConfig[envConfig.environment || 'dev'].awsConfig);

export default withAuthenticator(Application, false);
