import React, { FunctionComponent, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { IDeviceDTO } from '@halter-corp/device-service-client';

import CattleService from 'services/cattle.service';
import ConfirmationModal from 'application/components/confirmation.modal';

type TagOffButtonProps = {
  device: Pick<IDeviceDTO, 'serialNumber' | 'cattleName' | 'cattleId' | 'farmId'>;
};

const TagOffButton: FunctionComponent<TagOffButtonProps> = ({
  device: { serialNumber, cattleName, cattleId, farmId },
}) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [confirmationPopupVisible, setConfirmationPopupVisible] = useState(false);

  if (farmId == null || cattleId == null) return null;

  const tagOff = async () => {
    setLoading(true);
    setConfirmationPopupVisible(false);

    try {
      CattleService.tagOff(farmId, cattleId);

      setTimeout(() => {
        setLoading(false);
        history.goBack();
      }, 250);
    } catch {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        disabled={loading}
        onClick={(e) => {
          setConfirmationPopupVisible(true);
          e.stopPropagation();
        }}
      >
        Tag Off
      </Button>
      <ConfirmationModal
        confirmationMessage={`Are you sure you want to tag off ${
          cattleName != null ? `cow ${cattleName}` : ''
        }, collar ${serialNumber ?? ''}?`}
        open={confirmationPopupVisible}
        onDismiss={() => setConfirmationPopupVisible(false)}
        onConfirm={tagOff}
      />
    </>
  );
};

export default TagOffButton;
