import React, { FunctionComponent, useState, useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';
import styled from 'styled-components';

import { SearchResultTypeEnum } from '@halter-corp/bff-debug-tool-service-client';
import TimelineSliderPanel2 from '../panels/timeline-slider.panel2';
import TablePanel2 from '../panels/table.panel2';
import SearchPanel from '../panels/search.panel/search.panel';
import FilterItemTypes2 from '../panels/filter-item-types.panel2';
import { ItemDiscriminator } from '../../../../store/selectors/map-page-items.selectors';
import MapPanel2 from '../panels/map.panel/map.panel2';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 44px);
  width: 100vw;
  overflow: hidden;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const defaultDiscriminators: ItemDiscriminator[] = [
  { itemType: SearchResultTypeEnum.HISTORYEVENT, name: 'BreakFenceEvent' },
  { itemType: SearchResultTypeEnum.DEVICEMETRIC, name: 'TRANSITION_METRICS' },
  { itemType: SearchResultTypeEnum.DEVICEMETRIC, name: 'ZONE_SUMMARY' },
];

export type MainScreenProps = {
  loading: boolean;
  dateRange: [Date, Date] | null;
  itemsDateRange: [Date, Date] | null;
  cattleEarTags: string[];
  serialNumbers: string[];
  query: string | null;
  fetchAllDeviceMetrics: boolean;
  queryReturnedError: boolean;
  hasResults: boolean;
};

const DebugCattleScreen2: FunctionComponent<MainScreenProps> = ({
  loading,
  dateRange,
  itemsDateRange,
  cattleEarTags,
  serialNumbers,
  queryReturnedError,
  hasResults,
}) => {
  const [activeDateRange, setActiveDateRange] = useState(itemsDateRange);
  const [selectedDiscriminators, setSelectedDiscriminators] = useState(defaultDiscriminators);
  const [searchPanelIsExpanded, setSearchPanelIsExpanded] = useState(!hasResults);
  const [fullScreenMap, setFullScreenMap] = useState(false);

  useEffect(() => setSearchPanelIsExpanded(!hasResults), [hasResults]);
  useEffect(() => setActiveDateRange(itemsDateRange), [itemsDateRange]);

  return (
    <Wrapper>
      <LinearProgress variant={loading ? 'query' : 'determinate'} value={100} />
      <Row>
        <Column>
          <MapPanel2
            searchDateRange={dateRange}
            activeDateRange={activeDateRange}
            isFullScreen={fullScreenMap}
            onChangeIsFullScreen={setFullScreenMap}
          />
        </Column>
        <Column style={fullScreenMap ? { display: 'none' } : {}}>
          <SearchPanel
            cattleEarTags={cattleEarTags}
            serialNumbers={serialNumbers}
            dateRange={dateRange}
            collarCommandIds={[]}
            firmwareVersions={[]}
            queryReturnedError={queryReturnedError}
            searchPanelIsExpanded={searchPanelIsExpanded}
            onSearchPanelExpandButtonClick={(newExpandedState: boolean) =>
              setSearchPanelIsExpanded(newExpandedState)
            }
          />
          {hasResults && (
            <>
              <FilterItemTypes2
                selectedDiscriminators={selectedDiscriminators}
                onChangeSelectedDiscriminators={setSelectedDiscriminators}
              />
              <TimelineSliderPanel2
                dateRange={itemsDateRange}
                activeDateRange={activeDateRange}
                onChangeActiveDateRange={setActiveDateRange}
                selectedDiscriminators={selectedDiscriminators}
              />
              <TablePanel2
                activeDateRange={activeDateRange}
                selectedDiscriminators={selectedDiscriminators}
              />
            </>
          )}
        </Column>
      </Row>
    </Wrapper>
  );
};

export default DebugCattleScreen2;
