import React, { FunctionComponent } from 'react';
import HeatMapService from 'services/heat-map.service';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import HeatMapScreen from '../screens/heat-map.screen';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 49px);
  overflow: hidden;
  position: relative;
`;



const HeatMapContainer: FunctionComponent = () => {
  const loading = HeatMapService.useIsLoading();

  return (
    <>
      <LinearProgress variant={loading ? 'query' : 'determinate'} value={100} />
      <Wrapper>
        <HeatMapScreen />
      </Wrapper>
    </>
  );
};

export default HeatMapContainer;
