import { SeverityLevelEnum } from '@halter-corp/cow-health-service-client';
import { CaddieColours } from 'application/theme/colours';
import moment from 'moment';

type DateString = string & { __Type: 'DateString' };

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export const DateString = {
  REGEX: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
  fromDate: (date: Date): DateString => moment(date).format(DEFAULT_DATE_FORMAT) as DateString,
  fromDateTime: (date: Date): DateString => moment(date).format(DEFAULT_DATE_TIME_FORMAT) as DateString,
  startOfDayFromString: (date: string): Date => new Date(new Date(date).setHours(0, 0, 0, 0)),
};

export const getSeverityColor = (severityLevel: SeverityLevelEnum | undefined): string => {
  switch (severityLevel) {
    case SeverityLevelEnum.LOW:
      return CaddieColours.MaintenanceYellow;
    case SeverityLevelEnum.MEDIUM:
      return CaddieColours.WorriedOrange;
    case SeverityLevelEnum.HIGH:
      return CaddieColours.EmergencyRed;
    case SeverityLevelEnum.NOT_APPLICABLE:
    case SeverityLevelEnum.UNKNOWN:
      return 'black';
    default:
      return 'black';
  }
};
