import { createSlice } from '@reduxjs/toolkit';

import { IFeatureDTO } from '@halter-corp/topography-service-client';

import { reset } from 'store/actions';
import topographyEffects from 'store/effects/topography.effects';

const initialState: { loading: boolean; paddocks: IFeatureDTO[] } = {
  loading: false,
  paddocks: [],
};

const topographySlice = createSlice({
  initialState,
  name: 'topography',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(topographyEffects.fetchPaddocks.fulfilled, (state, action) => {
        state.paddocks = action.payload;
        state.loading = false;
      })
      .addCase(topographyEffects.fetchPaddocks.pending, (state) => {
        state.loading = true;
      }),
});

export default topographySlice;
