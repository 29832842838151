import { CircularProgress, IconButton } from '@material-ui/core';
import Checkbox from 'application/components/checkbox';
import { startCase } from 'lodash';
import React, { FunctionComponent } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import RefreshIcon from '@material-ui/icons/Refresh';
import styled from 'styled-components';
import { LayerSelection } from '../panels/input.panel';

const Row = styled.div`
  flex: 1;
  display: flex;
`;

export type LayerCheckboxProps = {
  field: string;
  layers: LayerSelection;
  setLayers: (layers: LayerSelection) => void;
  setRefreshLayers: (layers: LayerSelection) => void;
  refreshLayers: LayerSelection;
  status: string;
};

const LayerCheckbox: FunctionComponent<LayerCheckboxProps> = ({
  field,
  layers,
  setLayers,
  setRefreshLayers,
  refreshLayers,
  status,
}) => {
  const refreshLayer = () => {
    setRefreshLayers({ ...refreshLayers, [field]: !refreshLayers[field as keyof LayerSelection] });
  };

  return (
    <Row>
      <Checkbox
        label={startCase(field)}
        checked={layers[field as keyof LayerSelection]}
        onChange={(e, checked) => setLayers({ ...layers, [field]: checked })}
      />
      <div style={{ marginLeft: 'auto', alignSelf: 'center' }}>
        {status === 'error' && (
          <IconButton onClick={refreshLayer}>
            <RefreshIcon style={{ color: 'grey', fontSize: '1rem' }} />
          </IconButton>
        )}

        {status === 'pending' && <CircularProgress size="1rem" />}
        {status === 'success' && (
          <DoneIcon style={{ color: 'green', fontSize: '1rem', verticalAlign: 'middle' }} />
        )}
        {status === 'error' && (
          <ClearIcon style={{ color: 'red', fontSize: '1rem', verticalAlign: 'middle' }} />
        )}
      </div>
    </Row>
  );
};
export default LayerCheckbox;
