import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Dialog, DialogTitle, FormControlLabel, TextField } from '@material-ui/core';
import Button from 'application/components/button';
import styled from 'styled-components';
import { ICreateTowerNoteRequestDTO } from '@halter-corp/tower-service-client';
import { Tower } from 'store/selectors/tower.selectors';

type NoteFormProps = {
  initialNote?: ICreateTowerNoteRequestDTO;
  open: boolean;
  tower: Tower;
  onClose: () => void;
  onSave: (noteDto: ICreateTowerNoteRequestDTO) => void;
};

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 8px;
  margin-top: 8px;
`;

const NoteFormDialog: FC<NoteFormProps> = ({ initialNote, open, onClose, tower, onSave }) => {
  const [note, setNote] = useState<ICreateTowerNoteRequestDTO>(
    initialNote ?? {
      towerId: tower.id,
      note: '',
      caution: false,
    }
  );

  const handleSave = () => {
    onSave(note);
    setNote({
      towerId: tower.id,
      note: '',
      caution: false,
    });
  };

  useEffect(() => {
    if (initialNote) setNote(initialNote);
  }, [initialNote, open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ paddingLeft: 15, paddingRight: 15, textTransform: 'capitalize' }}>
        {initialNote ? `Edit note for tower` : `Add note to tower: `}{' '}
        <span style={{ color: '#3f50b5' }}>{tower.id}</span>
      </DialogTitle>
      <DialogContent>
        <TextField
          multiline
          minRows={4}
          id={`${tower?.id}-note`}
          name="note"
          placeholder="Please enter note"
          onChange={(e) => {
            const { value } = e.currentTarget;
            setNote((current) => ({ ...current, note: value }));
          }}
          value={note.note}
          style={{ padding: 8, flexGrow: 0 }}
        />
        <FormControlLabel
          label="Caution?"
          style={{ padding: 8, marginTop: -16, marginBottom: -8 }}
          control={
            <Checkbox
              style={{ alignSelf: 'start' }}
              title="Caution"
              id={`${tower?.id}-caution`}
              checked={note.caution}
              name="caution"
            />
          }
          onChange={(_, v) => setNote((current) => ({ ...current, caution: v }))}
        />
        <Div>
          <Button color="primary" text="Save" onClick={handleSave} />
          <Button backgroundColour="#616161" text="Cancel" onClick={onClose} />
        </Div>
      </DialogContent>
    </Dialog>
  );
};

export default NoteFormDialog;
