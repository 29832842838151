import { createAsyncThunk } from '@reduxjs/toolkit';

import { IMobDTO } from '@halter-corp/cattle-service-client';

import HttpApiService from 'services/http-api.service';

const mobEffects = {
  fetch: createAsyncThunk('mobs/fetch', async (props: { farmId: string }): Promise<IMobDTO[]> => {
    const mobApi = await HttpApiService.getMobApi(props.farmId);
    const { data: mobs } = await mobApi.findAll();

    return mobs;
  }),

  fetchAllFarmsMobs: createAsyncThunk('mobs/getAllMobs', async (props: { farmIds: string[] }) =>
    Promise.all(
      props.farmIds.map(async (farmId) => {
        const mobApi = await HttpApiService.getMobApi(farmId);
        const { data: mobs } = await mobApi.findAll();
        return { farmId, mobs };
      })
    )
  ),
};

export default mobEffects;
