import React, { FunctionComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import L from 'leaflet';
import JSONTree from 'react-json-tree';
import { Marker, Popup } from 'react-leaflet';
import { SettingsInputAntenna } from '@material-ui/icons';

import { jsonTreeTheme } from 'application/modules/debug-cattle/utils';
import { DeviceWithMetadata } from 'store/selectors/tower.selectors';
import { MapMode } from '../types';
import { getGatewayStatusColor } from '../util';

type UnassignedGatewayLocationsLayerProps = {
  gateways: DeviceWithMetadata[];
  mapMode: MapMode;
  onSelectGatewayForHeatMap: (gatewayId: string) => void;
};

const UnassignedGatewayLocationsLayer: FunctionComponent<UnassignedGatewayLocationsLayerProps> = ({
  gateways,
  mapMode,
  onSelectGatewayForHeatMap,
}) => (
  <>
    {gateways.map((gateway) => {
      const lastSeenEntry = moment.utc(gateway.status?.lastSeenTimestamp).fromNow();
      const gatewayData = {
        Name: gateway.name,
        ID: gateway.id,
        TowerID: gateway.towerId ?? 'unassigned',
        Enabled: gateway.status?.enabled ?? 'no data',
        Suspended: gateway.status?.suspended ?? 'no data',
        'Last Seen': lastSeenEntry,
      };

      const handleContextMenu = () => {
        if (mapMode === MapMode.HEAT_MAP) {
          onSelectGatewayForHeatMap(gateway.id);
        }
      };

      if (gateway?.status?.location == null) return null;

      return (
        <Marker
          key={gateway.id}
          oncontextmenu={handleContextMenu}
          title={gateway.name}
          zIndexOffset={999999}
          icon={L.divIcon({
            className: 'bla',
            html: ReactDOMServer.renderToString(
              <SettingsInputAntenna
                style={
                  gateway?.status?.suspended === true
                    ? { fontSize: '32px', stroke: '#FF8200', strokeDasharray: '2px', strokeWidth: '0.9' }
                    : { fontSize: '32px' }
                }
                stroke="black"
                strokeWidth={gateway?.status?.suspended === true ? 0.7 : 0.5}
                htmlColor={getGatewayStatusColor(gateway)}
              />
            ),
          })}
          position={{
            lat: gateway.status.location.latitude,
            lng: gateway.status.location.longitude,
          }}
        >
          <Popup autoPan={false}>
            <JSONTree data={gatewayData} theme={jsonTreeTheme} shouldExpandNode={() => true} hideRoot />
          </Popup>
        </Marker>
      );
    })}
  </>
);

export default UnassignedGatewayLocationsLayer;
