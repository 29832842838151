import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Source, Layer, HeatmapLayer } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import HeatMapService from 'services/heat-map.service';
import heatMapEffects from 'store/effects/heat-map.effects';
import { featureCollection, point } from 'turf';
import { Bounds, DataMapSearch } from '../panels/data-map.panel';
import { eventLocationBounds } from '../util/utils';

type ShockHeatmapLayerProps = {
  search: DataMapSearch;
  currentTime?: Date;
  recenterMap: (bounds: Bounds) => void;
  refresh: boolean;
};

const heatmapLayer: HeatmapLayer = {
  id: 'shock-heatmap',
  type: 'heatmap',
  paint: {
    'heatmap-weight': 1,
    'heatmap-intensity': 1,
    'heatmap-radius': ['interpolate', ['exponential', 2], ['zoom'], 0, 1, 22, 200],
  },
};

const ShockHeatmapLayer: FunctionComponent<ShockHeatmapLayerProps> = ({
  search,
  currentTime,
  recenterMap,
  refresh,
}) => {
  const dispatch = useDispatch();
  const shockLocations = HeatMapService.useFetchShockLocations();
  const filteredShockLocations =
    currentTime === undefined
      ? shockLocations
      : shockLocations.filter(
        (shockLocation) =>
          shockLocation.timestamp.getTime() <= currentTime.getTime() &&
          shockLocation.timestamp.getTime() >= currentTime.getTime() - 180 * 1000
      );
  const shockFeatureCollection =
    filteredShockLocations &&
    featureCollection(
      filteredShockLocations.map((shockLocation) => point([shockLocation.longitude, shockLocation.latitude]))
    );

  useEffect(() => {
    dispatch(
      heatMapEffects.fetchShockLocations({
        farmIds: search.farmIds,
        mobIds: search.mobIds,
        cattleNames: search.cattleNames,
        startTime: search.startTime,
        endTime: search.endTime,
      })
    );
  }, [JSON.stringify(search), refresh]);

  // Recenter Map
  useMemo(() => {
    if (shockLocations.length > 0) {
      const bounds = eventLocationBounds(shockLocations);
      recenterMap(bounds);
    }
  }, [shockLocations]);

  return (
    <Source type="geojson" data={shockFeatureCollection}>
      <Layer {...heatmapLayer} minzoom={12} />
    </Source>
  );
};
export default ShockHeatmapLayer;
