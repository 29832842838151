import React, { createContext, FC, useContext, useState } from 'react';

type InvalidInputContextType = {
  invalidInput: Map<string, boolean>;
  setInvalidInput: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
};

const InvalidInputContext = createContext<InvalidInputContextType>(null!);

export const useInvalidInputContext = () => useContext(InvalidInputContext);

export const InvalidInputContextProvider: FC = ({ children }) => {
  const [invalidInput, setInvalidInput] = useState<Map<string, boolean>>(new Map());

  return (
    <InvalidInputContext.Provider value={{ invalidInput, setInvalidInput }}>
      {children}
    </InvalidInputContext.Provider>
  );
};
