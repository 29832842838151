import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { omitBy, isEmpty } from 'lodash';
import BusinessService from 'services/business.service';
import { splitFieldValues } from 'application/utils';

type SearchParameters = {
  farmId: string;
  mobId?: string;
  cattleNames?: string[];
  serialNumbers?: string[];
  fromDate?: Date;
  toDate?: Date;
};

export const useParseQueryParameters = (): SearchParameters => {
  const location = useLocation();

  const farmIdFromSelector = BusinessService.useSelectCurrentFarm();

  return useMemo(() => {
    const urlQueryString = new URLSearchParams(location.search);

    const farmIdFromQueryString = urlQueryString.get('farmId');

    const mobIdFromQueryString = urlQueryString.get('mobId') ?? undefined;
    const cattleNamesString = urlQueryString.get('cattleNames') ?? '';
    const serialNumbersString = urlQueryString.get('serialNumbers') ?? '';
    const fromDateString = urlQueryString.get('fromDate');
    const toDateString = urlQueryString.get('toDate');

    return {
      farmId: farmIdFromQueryString ?? farmIdFromSelector,
      mobId: mobIdFromQueryString,
      cattleNames: splitFieldValues(cattleNamesString),
      serialNumbers: splitFieldValues(serialNumbersString),
      fromDate: fromDateString != null ? new Date(fromDateString) : undefined,
      toDate: toDateString != null ? new Date(toDateString) : undefined,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
};

export const useLocationSearch = (searchParams: { [key: string]: string | undefined }) =>
  useMemo(
    () => ({
      search: new URLSearchParams(
        omitBy(searchParams, (value) => value == null || isEmpty(value)) as any
      ).toString(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [searchParams]
  );
