import React, { FC, ChangeEvent } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Size } from '@material-ui/core';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import { isString } from 'lodash';
import copy from 'copy-to-clipboard';
import useTableSorting from 'application/hooks/use-table-sorting';
import { Column } from 'application/components/data-table';
import { DeviceWithMetadata, TowerWithDevicesAndNotes } from 'store/selectors/tower.selectors';
import DevicesDataRow, { DevicesDataRowData } from './devices-data-row';

type TowersTableProps = {
  towers: TowerWithDevicesAndNotes[];
  columns: Column<DevicesDataRowData>[];
  headerColour?: string;
  selectedDevices: Map<string, DeviceWithMetadata>;
  onSelectDevice: (event: ChangeEvent<HTMLInputElement>) => void;
  defaultSortColumnName?: string;
  size?: Size;
  padding?: number;
};

const TowersTable: FC<TowersTableProps> = ({
  towers,
  columns,
  defaultSortColumnName,
  size,
  headerColour,
  padding,
  selectedDevices,
  onSelectDevice,
}) => {
  const {
    sortColumnName,
    setSortColumnName,
    sortDirection,
    setSortDirection,
    sortedItems: sortedTowers,
  } = useTableSorting<TowerWithDevicesAndNotes>({
    defaultSortColumnName,
    items: towers,
    columns,
  });

  return (
    <Table stickyHeader size={size}>
      <TableHead>
        <TableRow style={{ position: 'relative', backgroundColor: headerColour }}>
          <TableCell style={{ width: 5 }} />

          {columns.map(({ name, keyExtractor, render, allowCopyToClipboard, headerToolTip }, index) => (
            <TableCell
              // eslint-disable-next-line react/no-array-index-key
              key={isString(name) ? name : index}
              sortDirection={sortColumnName === name ? sortDirection : undefined}
              style={{
                width: index === 0 ? '8.5%' : '16.5%',
                padding,
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {name && (
                <Tooltip title={headerToolTip ?? ''} style={{ marginRight: 5 }}>
                  <TableSortLabel
                    active={sortColumnName === name && name !== ''}
                    direction={sortColumnName === name ? sortDirection : undefined}
                    onClick={() => {
                      if (name === '' || !isString(name)) return;
                      setSortDirection(sortColumnName !== name || sortDirection === 'asc' ? 'desc' : 'asc');
                      setSortColumnName(name);
                    }}
                  >
                    {name}
                  </TableSortLabel>
                </Tooltip>
              )}
              {allowCopyToClipboard != null && (
                <FileCopyOutlined
                  onClick={() => copy(sortedTowers.map<any>(keyExtractor ?? render).join(','))}
                  fontSize="small"
                  style={{ opacity: 0.8, marginBottom: -4, cursor: 'pointer' }}
                />
              )}
            </TableCell>
          ))}
          <TableCell colSpan={6} />
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedTowers.map((tower, rowIndex) => (
          <DevicesDataRow
            // eslint-disable-next-line react/no-array-index-key
            key={rowIndex}
            rowIndex={rowIndex}
            data={tower}
            columns={columns}
            selectedDevices={selectedDevices}
            onSelectDevice={onSelectDevice}
            padding={padding}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default TowersTable;
