import React, { FunctionComponent, useState } from 'react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import styled from 'styled-components';
import { Button } from 'aws-amplify-react';
import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';

const Wrapper = styled.div`
  background-color: #000;
  height: 100%;
  width: 100%;
`;

type QRCodeScanningScreenProps = {
  handleScan: (result: string | null) => void;
};

const QRCodeScanningScreen: FunctionComponent<QRCodeScanningScreenProps> = ({ handleScan }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleInputSubmit = () => {
    handleScan(inputValue);
    setIsModalOpen(false);
  };

  return (
    <Wrapper>
      <QrScanner onDecode={handleScan} onError={() => {}} />
      <Button
        onClick={() => setIsModalOpen(true)}
        style={{ position: 'absolute', bottom: '90px', right: '10px', left: '10px' }}
      >
        Manual Entry
      </Button>
      <Button
        onClick={() => handleScan(null)}
        style={{ position: 'absolute', bottom: '40px', right: '10px', left: '10px' }}
      >
        Exit
      </Button>
      <Dialog
        PaperProps={{ style: { width: '100%' } }}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <DialogTitle>Enter QR scan manually</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            style={{ width: '100%' }}
            label="Input"
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
          />
        </DialogContent>
        <Button onClick={handleInputSubmit}>Submit</Button>
      </Dialog>
    </Wrapper>
  );
};
export default QRCodeScanningScreen;
