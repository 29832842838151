import { createSelector } from '@reduxjs/toolkit';
import { LatLng, LatLngBounds } from 'leaflet';
import { intersection, keyBy, sortBy, uniqBy } from 'lodash';
import { DeviceCommandFeature2 } from 'store/effects/debug-cattle.effects';
import { HistoryDeviceCommandStatusEnum, CommandTypeEnum } from '@halter-corp/timeline-service-client';

import { AppState } from '../../store';
import { CowgStatusEnum } from '../map-page-items.selectors';

import {
  mapHistoryEventToDeviceCommandFeature,
  createZonesLookup,
  mapDeviceMetricsToCattleLocations,
  mapDeviceMetricsToCattleEvents,
} from './mappers';

export const selectIsLoading = (state: AppState) => {
  const { historyEventsStatus, positionMetricsStatus, cowGEventsStreamStatus } = state.debugCattle;
  return (
    historyEventsStatus === 'pending' ||
    positionMetricsStatus === 'pending' ||
    cowGEventsStreamStatus === 'pending'
  );
};

export const selectHasError = (state: AppState) => {
  const { historyEventsStatus, positionMetricsStatus, cowGEventsStreamStatus } = state.debugCattle;
  return (
    historyEventsStatus === 'error' || positionMetricsStatus === 'error' || cowGEventsStreamStatus === 'error'
  );
};

export const selectHasResults = (state: AppState) =>
  state.debugCattle.historyEvents.length > 0 ||
  state.debugCattle.positionMetrics.length > 0 ||
  state.debugCattle.cowGEventsStream.length > 0;

const getHistoryEvents = (state: AppState) => state.debugCattle.historyEvents;
const getPositionMetrics = (state: AppState) => state.debugCattle.positionMetrics;
const getCowGEventsStream = (state: AppState) => state.debugCattle.cowGEventsStream;

export const selectHistoryEvents = createSelector(getHistoryEvents, (historyEvents) =>
  mapHistoryEventToDeviceCommandFeature(historyEvents)
);

export const selectZoneLookup = createSelector(selectHistoryEvents, (historyEvents) =>
  createZonesLookup(historyEvents)
);

export const selectMappedPositionMetrics = createSelector(
  getPositionMetrics,
  getCowGEventsStream,
  (positionMetrics, cowGEventsStream) => {
    const sortedLocationItems = sortBy([...positionMetrics, ...cowGEventsStream], 'timestamp');
    return mapDeviceMetricsToCattleLocations(sortedLocationItems);
  }
);

export const selectCowGEventStream = createSelector(getCowGEventsStream, (cowGEventStream) =>
  mapDeviceMetricsToCattleEvents(cowGEventStream)
);

export const selectAllMapItems = createSelector(
  getHistoryEvents,
  getPositionMetrics,
  getCowGEventsStream,
  (historyEvents, positionMetrics, cowGeventStream) => {
    const allMapItems = [...historyEvents, ...positionMetrics, ...cowGeventStream];
    return sortBy(allMapItems, 'timestamp');
  }
);

export const selectFilteredDeviceCommandFeatures = createSelector(
  selectHistoryEvents,
  selectZoneLookup,
  (_state: AppState, props: { maximumDate: Date | null }) => props.maximumDate,
  (_state: AppState, props: { selectedCows: string[] | null }) => props.selectedCows,
  (commandFeatures, zoneLookup, maxTimestamp, selectedCows) => {
    const maxTime = maxTimestamp?.getTime();

    const lastEventsFromDateRangeAndSelectedCows: Map<string, DeviceCommandFeature2> = commandFeatures.reduce(
      (previous, commandFeature) => {
        if (maxTime != null && commandFeature.eventDate > maxTime) {
          return previous;
        }

        if (selectedCows != null && !selectedCows.includes(commandFeature.cattleName)) {
          return previous;
        }

        // When completed, command should not be displayed on the map anymore...
        if (commandFeature.commandStatus === HistoryDeviceCommandStatusEnum.COMPLETED) {
          return previous;
        }

        const zone =
          commandFeature.commandType !== CommandTypeEnum.SETZONE
            ? zoneLookup[commandFeature.referenceId]
            : commandFeature.zone;

        previous.set(`${commandFeature.serialNumber}#${commandFeature.groupId}`, {
          ...commandFeature,
          zone,
        });
        return previous;
      },
      new Map() // Using Map instead of object and spread operator for performance
    );

    const lastEventPerSerialNumberAndPaddockArray = Array.from(
      lastEventsFromDateRangeAndSelectedCows.values()
    );

    return uniqBy(
      lastEventPerSerialNumberAndPaddockArray,
      ({ commandId, commandType }) => `${commandId}-${commandType}`
    );
  }
);

export const selectFilteredCattleLocationPaths = createSelector(
  selectMappedPositionMetrics,
  (_state: AppState, props: { minimumDate: Date | null }) => props.minimumDate?.getTime(),
  (_state: AppState, props: { maximumDate: Date | null }) => props.maximumDate?.getTime(),
  (_state: AppState, props: { selectedCows: string[] | null }) => props.selectedCows,
  (positionMetrics, minTimestamp, maxTimestamp, selectedCows) => {
    const keyedCowNames = keyBy(selectedCows ?? [], (cow) => cow);
    if (minTimestamp == null || maxTimestamp == null) {
      return positionMetrics;
    }

    return positionMetrics
      .filter((locationPath) => selectedCows == null || keyedCowNames[locationPath.cattleName] != null)
      .map((locationPath) => {
        const { locations } = locationPath;
        const minIndex = locations.findIndex((location) => location.timestamp > minTimestamp);
        const maxIndex = locations.slice(minIndex).findIndex((location) => location.timestamp > maxTimestamp);
        const filteredLocations = locations.slice(minIndex, maxIndex < 0 ? undefined : minIndex + maxIndex);
        return {
          cattleName: locationPath.cattleName,
          locations: filteredLocations,
        };
      });
  }
);

export const selectFilteredCattleEvents = createSelector(
  selectCowGEventStream,
  (_state: AppState, props: { minimumDate: Date | null }) => props.minimumDate?.getTime(),
  (_state: AppState, props: { maximumDate: Date | null }) => props.maximumDate?.getTime(),
  (_state: AppState, props: { mapViewBounds: LatLngBounds | null }) => props.mapViewBounds,
  (_state: AppState, props: { selectedCows: string[] | null }) => props.selectedCows,
  (_state: AppState, props: { selectedCowGStatuses: CowgStatusEnum[] | null }) => props.selectedCowGStatuses,
  (events, minTimestamp, maxTimestamp, mapViewBounds, selectedCows, selectedCowGStatuses) =>
    events.filter((event) => {
      if (minTimestamp != null && new Date(event.timestamp).getTime() < minTimestamp) return false;
      if (maxTimestamp != null && new Date(event.timestamp).getTime() > maxTimestamp) return false;
      if (selectedCows != null && !selectedCows.includes(event.cattleName)) return false;
      if (selectedCowGStatuses != null && intersection(selectedCowGStatuses, event.cowgStatuses).length === 0)
        return false;
      if (
        mapViewBounds != null &&
        !mapViewBounds.contains(new LatLng(event.location.lat, event.location.lon))
      )
        return false;

      return true;
    })
);
