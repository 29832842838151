import React, { FunctionComponent } from 'react';
import { TextField } from '@material-ui/core';
import moment from 'moment';

export type DateTimeSelectProps = {
  label: string;
  onChange: (value: Date) => void;
  value?: Date;
};

const DateTimeSelect: FunctionComponent<DateTimeSelectProps> = ({ label, onChange, value }) => (
  <TextField
    id="datetime-local"
    label={label}
    type="datetime-local"
    variant="outlined"
    size="small"
    style={{ width: '100%' }}
    onChange={(e) => {
      onChange(new Date(e.target.value));
    }}
    value={value ? moment(value).format('YYYY-MM-DDTHH:mm') : ''}
    InputLabelProps={{
      shrink: true,
    }}
  />
);
export default DateTimeSelect;
