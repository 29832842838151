import { ISearchResultItemDTO } from '@halter-corp/bff-debug-tool-service-client';
import { createSlice } from '@reduxjs/toolkit';
import debugCattleEffects from 'store/effects/debug-cattle.effects';

type Status = 'pending' | 'error' | 'success' | 'idle';

type MapPageItemsState = {
  historyEventsStatus: Status;
  historyEvents: ISearchResultItemDTO[];
  positionMetricsStatus: Status;
  positionMetrics: ISearchResultItemDTO[];
  cowGEventsStreamStatus: Status;
  cowGEventsStream: ISearchResultItemDTO[];
};

const initialState: MapPageItemsState = {
  historyEventsStatus: 'idle',
  historyEvents: [],
  positionMetricsStatus: 'idle',
  positionMetrics: [],
  cowGEventsStreamStatus: 'idle',
  cowGEventsStream: [],
};

const debugCattleSlice = createSlice({
  initialState,
  name: 'debugCattle',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(debugCattleEffects.fetchHistoryEvents.pending, (state) => {
        state.historyEventsStatus = 'pending';
      })
      .addCase(debugCattleEffects.fetchHistoryEvents.rejected, (state) => {
        state.historyEventsStatus = 'error';
      })
      .addCase(debugCattleEffects.fetchHistoryEvents.fulfilled, (state, action) => {
        state.historyEventsStatus = 'success';
        state.historyEvents = action.payload;
      })
      .addCase(debugCattleEffects.fetchPositionMetrics.pending, (state) => {
        state.positionMetricsStatus = 'pending';
      })
      .addCase(debugCattleEffects.fetchPositionMetrics.rejected, (state) => {
        state.positionMetricsStatus = 'error';
      })
      .addCase(debugCattleEffects.fetchPositionMetrics.fulfilled, (state, action) => {
        state.positionMetricsStatus = 'success';
        state.positionMetrics = action.payload;
      })
      .addCase(debugCattleEffects.fetchCowGEventStream.pending, (state) => {
        state.cowGEventsStreamStatus = 'pending';
      })
      .addCase(debugCattleEffects.fetchCowGEventStream.rejected, (state) => {
        state.cowGEventsStreamStatus = 'error';
      })
      .addCase(debugCattleEffects.fetchCowGEventStream.fulfilled, (state, action) => {
        state.cowGEventsStreamStatus = 'success';
        state.cowGEventsStream = action.payload;
      }),
});

export default debugCattleSlice;
