import React, { FunctionComponent } from 'react';
import { Typography } from '@material-ui/core';
import DataTable from 'application/components/data-table';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import { MapUtils } from 'application/utils/MapUtils';
import { CaddieColours } from '../../../theme/colours';
import { formatEnumValue, getOnFarmStatusCountColour, mapOnFarmStatusToDescription } from '../utils';
import { FleetFarmData } from '../screens/fleet-view.screen';

type FleetSummaryData = {
  type: string;
  totalCountByStatus: Map<DeviceOnFarmStatusEnum, number>;
};

type FleetSummaryTableProps = {
  data: FleetFarmData[];
};

const FleetSummaryTable: FunctionComponent<FleetSummaryTableProps> = ({ data }) => {
  const collarSummary: FleetSummaryData = data.reduce(
    (acc, farm) => ({
      type: 'Collars',
      totalCountByStatus: MapUtils.mergeWith(
        acc.totalCountByStatus,
        farm.collarCountByStatusMap,
        (a, b) => a + b
      ),
    }),
    { type: 'Collars', totalCountByStatus: new Map<DeviceOnFarmStatusEnum, number>() }
  );

  const farmSummary: FleetSummaryData = data.reduce(
    (acc, farm) => ({
      type: 'Farms',
      totalCountByStatus: MapUtils.mergeWith(
        acc.totalCountByStatus,
        farm.collarCountByStatusMap,
        (accTotal, currentTotal) => accTotal + (currentTotal > 0 ? 1 : 0)
      ),
    }),
    { type: 'Farms', totalCountByStatus: new Map<DeviceOnFarmStatusEnum, number>() }
  );

  const summaryData = [collarSummary, farmSummary];

  return (
    <DataTable<FleetSummaryData>
      data={summaryData}
      columns={[
        {
          name: 'Type',
          keyExtractor: (item) => item?.type,
          render: (item) => (
            <Typography variant="body2" style={{ marginLeft: 'auto' }}>
              {item?.type}
            </Typography>
          ),
        },
        ...Object.values(DeviceOnFarmStatusEnum).map((status) => ({
          name: formatEnumValue(status),
          headerToolTip: mapOnFarmStatusToDescription(status),
          keyExtractor: (item: FleetSummaryData) => item.totalCountByStatus.get(status),
          render: (item: FleetSummaryData) => (
            <Typography
              style={{ color: getOnFarmStatusCountColour(item.totalCountByStatus.get(status) ?? 0, status) }}
            >
              {item.totalCountByStatus.get(status) ?? 0}
            </Typography>
          ),
        })),
      ]}
      indexColumn={false}
      headerColour={CaddieColours.DarkGrey}
      sortDirectionOption="desc"
      size="small"
      stickyHeader={false}
    />
  );
};

export default FleetSummaryTable;
