export type ParsedTower = {
  name: string;
  lat: number;
  lng: number;
  router: boolean;
  starlink: boolean;
  groupIndex: number;
  routerTower: string;
};

const checkFlag = (name: string, flag: string) => {
  const sections = name.split('-');
  let containsFlag = false;
  sections.forEach((s) => {
    if (s === flag) {
      containsFlag = true;
    }
  });
  return containsFlag;
};

const isFloat = (word: string) => {
  const regExFloat = new RegExp(/(-?\d+\.\d+)(?=,), -?\d+\.\d+/);
  return regExFloat.test(word);
};

export const parsePasteData = (pastedString: string) => {
  const parsedTowers: ParsedTower[] = [];
  pastedString.split('\n').forEach((el, index, arr) => {
    if (isFloat(el)) {
      const fullName: string = arr[index - 1];
      const cleanedName = fullName.replace(/\([^)]*\)/, '').trim();
      const [lat, lng] = el.split(',').map((coordinate) => Number(coordinate));
      const parsedTower: ParsedTower = {
        name: cleanedName,
        lat,
        lng,
        router: checkFlag(fullName, 'router'),
        starlink: checkFlag(fullName, 'starlink'),
        // TODO: eventually the group index field should always exist, this is to prevent blocking go live flow.
        groupIndex: !Number.isNaN(Number(arr[index + 1])) ? Number(arr[index + 1]) : 0,
        routerTower: arr[index + 2].trim(),
      };
      parsedTowers.push(parsedTower);
    }
  });
  return parsedTowers;
};
