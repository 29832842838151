import { createSelector } from '@reduxjs/toolkit';

import { IFarmDTO } from '@halter-corp/business-service-client';

import farmAdapter from 'store/adapters/farm.adapter';
import { AppState } from '../store';

export const getCurrentFarm = createSelector(
  (state: AppState) => state.farmContext,
  (farmContext) =>
    farmContext.currentFarm ||
    window.localStorage.getItem('currentFarm') ||
    '4ef316f1-4628-4457-9468-1a294d1e7769'
);

export const selectFarmsSlice = (state: AppState) => state.farmContext.entityState;

export const { selectIds: selectFarmIds, selectEntities: selectFarmEntities } =
  farmAdapter.getSelectors(selectFarmsSlice);

export const selectFarms = createSelector(selectFarmEntities, (profiles): IFarmDTO[] =>
  Object.values(profiles).reduce<IFarmDTO[]>((previous, farm) => {
    if (farm == null) return previous;
    return [...previous, farm];
  }, [])
);
