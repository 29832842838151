import { ICommandDTO, ICowtrollerDeviceDTO, ICowtrollerGroupDTO, IDevicePayloadDTO } from '@halter-corp/cowtroller-service-client';
import HttpApiService from './http-api.service';

const CowtrollerService = {
  fetchCowtrollerGroups: async (farmId: string): Promise<ICowtrollerGroupDTO[]> => {
    const api = await HttpApiService.getCowtrollerGroupsApi(farmId);
    const { data } = await api.getGroups();
    return data;
  },

  fetchDeviceCommands: async (serialNumber: string): Promise<ICowtrollerDeviceDTO> => {
    const api = await HttpApiService.getCowtrollerDeviceApi();

    const { data } = await api.getDeviceBySerialNumber(serialNumber);
    return data;
  },

  fetchCommandData: async (commandId: string): Promise<ICommandDTO> => {
    const api = await HttpApiService.getCowtrollerCommandsApi();

    const { data } = await api.getCommand(commandId);
    return data;
  },

  fetchDevicePayload: async (serialNumber: string): Promise<IDevicePayloadDTO[]> => {
    const api = await HttpApiService.getCowtrollerDevicePayloadApi();

    const { data } = await api.getDevicePayloadBySerialNumber(serialNumber);
    return data;
  },
};

export default CowtrollerService;
