import React from 'react';
import { flatten } from 'flat';
import { IFarmDTO } from '@halter-corp/business-service-client';
import { Device } from 'data/device';
import { Column } from 'application/components/data-table';
import { Link } from 'react-router-dom';

import { generateBuiltInTestStatusToolTip } from 'store/effects/device.effects/device-stats-util';
import moment from 'moment';
import { deviceBatteryStatus, deviceFaultsStatus, deviceLoraStatus } from '../components/device-status.util';
import { getCheckinAge, prettyPrintLastLoraCheckinTime } from './utils';
import DeviceAlarmsOverview from '../components/device-alarms-overview';

export const indexColumn: Column<Device> = {
  name: '',
  render: (device, index) => Number(index + 1),
};

export const cowColumn: Column<Device> = {
  name: 'Cow',
  keyExtractor: (device) => {
    if (Number.isNaN(Number(device.cattleName)) || device.cattleName?.split('')[0] === '0') {
      return device.cattleName;
    }
    return Number(device.cattleName);
  },
  render: (device) => device.cattleName,
  allowCopyToClipboard: true,
};

export const serialNumberColumn: Column<Device> = {
  name: 'Serial number',
  keyExtractor: (device) => device.serialNumber,
  render: (device) => (
    <Link to={{ pathname: `/devices/${device.serialNumber}` }} target="_blank">
      {device.serialNumber}
    </Link>
  ),
  allowCopyToClipboard: true,
};

export const farmColumn = (farms: IFarmDTO[]): Column<Device> => ({
  name: 'Farm',
  render: (device: Device) => farms.find((farm) => farm.id === device.farmId)?.name,
});

export const mobColumn: Column<Device> = {
  name: 'Mob',
  keyExtractor: (device) => device.mobName,
  render: (device) => device.mobName,
};

export const batteryVoltageColumn: Column<Device> = {
  name: 'Battery (mV)',
  keyExtractor: (device) => Number(device.stats?.batteryVoltage),
  render: (device) => <>{`${device.stats?.batteryVoltage}`}</>,
  noDataCondition: (device) => device.stats?.batteryVoltage == null,
};

export const batteryPercentageColumn: Column<Device> = {
  name: 'Battery (%)',
  keyExtractor: (device) => Number(device.stats?.batteryPercentage),
  render: (device) => (
    <>
      {`${device.stats?.batteryPercentage?.toFixed(1)}%`}
      {deviceBatteryStatus(Number(device.stats?.batteryPercentage))}
    </>
  ),
  noDataCondition: (device) => device.stats?.batteryPercentage == null,
};

export const alarmsColumn: Column<Device> = {
  name: 'Alarms',
  keyExtractor: (device) => device.alarms?.join(', '),
  render: (device) => <DeviceAlarmsOverview device={device} />,
};

export const faultsColumn: Column<Device> = {
  name: 'Faults',
  keyExtractor: (device) => device.stats?.builtInTestStatus?.faults?.join(', '),
  render: (device) => (
    <>
      {deviceFaultsStatus(device.stats?.builtInTestStatus?.faults, device.stats?.builtInTestStatus?.isFaulty)}
      {device.stats?.builtInTestStatus?.faults?.join(', ') || 'No faults'}
    </>
  ),
  toolTip: (device) => generateBuiltInTestStatusToolTip(device.stats?.builtInTestStatus) || 'No faults',
  noDataCondition: (device) => device.stats?.builtInTestStatus == null,
};

export const loraColumn: Column<Device> = {
  name: 'Lora',
  keyExtractor: (device) => device.stats?.loraCheckinStatus?.lastUpdate,
  render: (device) => (
    <>
      {`${getCheckinAge(
        device.stats?.loraCheckinStatus?.lastUpdate,
        device.stats?.loraCheckinStatus?.lastRetrieval
      )}`}
      {deviceLoraStatus(
        device.stats?.loraCheckinStatus?.lastUpdate,
        Number(
          flatten<any, { [key: string]: string | number }>(device.settingsV2?.settingsObject ?? {})[
            'operational.lora.reportingWindow'
          ]
        ),
        device.stats?.loraCheckinStatus?.lastRetrieval
      )}
    </>
  ),
  toolTip: (device) => prettyPrintLastLoraCheckinTime(device.stats?.loraCheckinStatus?.lastUpdate),
  noDataCondition: (device) => device.stats?.loraCheckinStatus == null,
};

export const wifiColumn: Column<Device> = {
  name: 'Wifi',
  keyExtractor: (device) => device.stats?.wifiCheckinStatus?.lastUpdate,
  render: (device) => (
    <>
      {`${getCheckinAge(
        device.stats?.wifiCheckinStatus?.lastUpdate,
        device.stats?.wifiCheckinStatus?.lastRetrieval
      )}`}
    </>
  ),
  toolTip: (device) => prettyPrintLastLoraCheckinTime(device.stats?.wifiCheckinStatus?.lastUpdate),
  noDataCondition: (device) => device.stats?.wifiCheckinStatus == null,
};

export const firmwareVersionColumn: Column<Device> = {
  name: 'Version',
  keyExtractor: (device) => device?.firmwareVersion,
  render: (device) => device?.firmwareVersion,
  noDataCondition: (device) => device?.firmwareVersion == null,
};

export const swapRequestColumn: Column<Device> = {
  name: 'Swap Request',
  keyExtractor: (device) => device.status.swapRequested?.createdDate ?? '',
  render: (device) =>
    device.status.swapRequested ? (
      <>
        <p style={{ margin: 0 }}>{device.status.swapRequested.reason}</p>
        <span style={{ margin: '0', color: '#696969', fontSize: '12px' }}>
          {device.status.swapRequested.createdBy}{' '}
          {moment(device.status.swapRequested.createdDate).format('YYYY-MM-DD HH:mm')}
        </span>
      </>
    ) : (
      <></>
    ),
};
