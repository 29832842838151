import { Typography } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import HeatMapService from 'services/heat-map.service';
import heatMapEffects from 'store/effects/heat-map.effects';
import { Bounds, DataMapSearch, PopupInfo } from '../panels/data-map.panel';

type FarmLocationsLayerProps = {
  search: DataMapSearch;
  recenterMap: (bounds: Bounds) => void;
  refresh: boolean;
};

const FarmLocationsLayer: FunctionComponent<FarmLocationsLayerProps> = ({ search, recenterMap, refresh }) => {
  const dispatch = useDispatch();

  const farmsWithDatums = HeatMapService.useFetchFarmsWithDatums();

  const [popupInfo, setPopupInfo] = React.useState<PopupInfo | undefined>();

  useEffect(() => {
    dispatch(heatMapEffects.fetchFarmsWithDatums(search?.farmIds));
  }, [JSON.stringify(search), refresh]);

  // Recenter map
  useMemo(() => {
    if (farmsWithDatums.length > 0) {
      const bounds = farmsWithDatums.reduce(
        (acc, farm) => ({
          minLng: Math.min(acc.minLng, farm.datum?.geometry.coordinates[0] || Infinity),
          minLat: Math.min(acc.minLat, farm.datum?.geometry.coordinates[1] || Infinity),
          maxLng: Math.max(acc.maxLng, farm.datum?.geometry.coordinates[0] || -Infinity),
          maxLat: Math.max(acc.maxLat, farm.datum?.geometry.coordinates[1] || -Infinity),
        }),
        { minLng: Infinity, minLat: Infinity, maxLng: -Infinity, maxLat: -Infinity }
      );
      recenterMap(bounds);
    }
  }, [farmsWithDatums]);

  return (
    <>
      {farmsWithDatums.map((farm) => (
        <Marker
          longitude={farm.datum?.geometry.coordinates[0]}
          latitude={farm.datum?.geometry.coordinates[1]}
          key={farm.id}
          color="grey"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const longitude = farm.datum?.geometry.coordinates[0];
            const latitude = farm.datum?.geometry.coordinates[1];
            if (!longitude || !latitude) return;
            const popup: PopupInfo = {
              longitude,
              latitude,
              contents: (
                <>
                  <Typography style={{ fontWeight: 'bold' }}>{farm.name}</Typography>
                  <Typography style={{ fontSize: '10px' }}>{farm.id}</Typography>
                </>
              ),
            };
            setPopupInfo(popup);
          }}
        />
      ))}

      {popupInfo && (
        <Popup
          anchor="bottom"
          longitude={Number(popupInfo.longitude)}
          latitude={Number(popupInfo.latitude)}
          onClose={() => setPopupInfo(undefined)}
        >
          {popupInfo?.contents}
        </Popup>
      )}
    </>
  );
};
export default FarmLocationsLayer;
