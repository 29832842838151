import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import SettingsFormContainer from '../containers/settings-form.container';

const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  opacity: 0.9;
  padding: 12px;
  text-align: center;
`;

const Wrapper = styled.div`
  width: 80%;
  margin: 20px auto;
`;

const SelectSettingsScreen: FunctionComponent = () => (
  <Wrapper>
    <Title>Edit Engineering Settings</Title>
    <SettingsFormContainer />
  </Wrapper>
);

export default SelectSettingsScreen;
