import React, { FunctionComponent, useState } from 'react';
import { MenuItem, Modal, Select, CircularProgress, IconButton } from '@material-ui/core';
import styled from 'styled-components';

import BusinessService from 'services/business.service';

import Checkbox from 'application/components/checkbox';
import Wrapper from 'application/components/wrapper';

import ModalButton from 'application/components/modal-button';
import CloseIcon from '@material-ui/icons/Close';
import DeviceService from 'services/device.service';

import { PreProvisioningAction } from './types';

const ModalBody = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  max-width: 360px;
`;

const Title = styled.div`
  padding: 4px 0px;
  text-align: start;
`;

const ButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  padding: 8px 32px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 8px 8px;
`;

const ProgressWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExitButton = styled(IconButton)`
  top: 0;
  right: 0;
  color: black;
  position: absolute;
`;

type PreProvisioningActionModalProps = {
  open: boolean;
  onConfirm: (action: PreProvisioningAction) => void;
  onDismiss: () => void;
};

const PreProvisioningActionModal: FunctionComponent<PreProvisioningActionModalProps> = ({
  open,
  onConfirm,
  onDismiss,
}) => {
  const farms = BusinessService.useFetchFarmList();
  const isProvisioning = DeviceService.useIsProvisioningLoading();

  const [actionType, setActionType] = useState<'remove' | 'set'>('remove');
  const [selectedFarmId, setSelectedFarmId] = useState<string | null>(null);
  const disableButton = (actionType === 'set' && selectedFarmId == null) || isProvisioning;

  const handleConfirm = () => {
    if (actionType === 'remove') onConfirm({ type: actionType });
    else if (selectedFarmId != null) onConfirm({ type: actionType, farmId: selectedFarmId });
  };

  const handleClose = (e: any) => {
    if (isProvisioning === false) {
      onDismiss();
    }
    e.stopPropagation();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableAutoFocus
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', outline: 'none' }}
    >
      <ModalBody style={{ position: 'relative' }}>
        <ExitButton onClick={onDismiss} disabled={isProvisioning}>
          <CloseIcon />
        </ExitButton>
        <TextWrapper>
          <Title>Update pre-provisioning</Title>
          {isProvisioning && <Title>This may take a while...</Title>}
        </TextWrapper>
        <ProgressWrapper>{isProvisioning && <CircularProgress size="32px" />}</ProgressWrapper>
        <Wrapper style={{ flex: 1, flexDirection: 'column', padding: '8px' }}>
          <Checkbox
            label="De-provision from farm"
            checked={actionType === 'remove'}
            onChangeValue={(value) => setActionType(value ? 'remove' : 'set')}
          />
          <Checkbox
            label="Pre-provision to farm..."
            checked={actionType === 'set'}
            onChangeValue={(value) => setActionType(value ? 'set' : 'remove')}
          />
          {actionType === 'set' && (
            <Select
              displayEmpty
              value={selectedFarmId ?? ''}
              onChange={(e) => setSelectedFarmId(e.target.value as string)}
            >
              {farms.map((farm) => (
                <MenuItem key={farm.id} value={farm.id}>
                  {farm.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Wrapper>
        <ButtonPanel>
          <ModalButton
            onPress={handleConfirm}
            disabled={disableButton}
            text="Go!"
            style={{ backgroundColor: '#1769aa', color: 'white' }}
          />
        </ButtonPanel>
      </ModalBody>
    </Modal>
  );
};

export default PreProvisioningActionModal;
