import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import farmEffects from 'store/effects/farm.effects';
import trainingEffects from 'store/effects/training.effects';
import settingsEffects from 'store/effects/settings.effects';
import { useSelector } from 'store';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';

import ProfilesScreen from './screens/profiles.screen';
import CreateProfileScreen from './screens/create-profile.screen';
import ShowProfileScreen from './screens/show-profile.screen';
import ApplyProfilesScreen from './screens/apply-profiles.screen';
import TrainingScreen from './screens/training.screen';
import CreateModifierScreen from './screens/create-modifier.screen';
import ShowModifierScreen from './screens/show-modifier.screen';
import ApplyModifiersScreen from './screens/apply-profiles.screen/apply-modifiers.screen';

const TrainingModule: FunctionComponent = () => {
  const dispatch = useDispatch();

  const farmId = useSelector(getCurrentFarm);

  useEffect(() => {
    dispatch(farmEffects.fetch());
    dispatch(trainingEffects.fetchProfiles(farmId));
    dispatch(trainingEffects.fetchModifiers());
    dispatch(settingsEffects.fetchMetadata());
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path="/training">
        <TrainingScreen />
      </Route>
      <Route exact path="/training/profiles">
        <ProfilesScreen />
      </Route>
      <Route exact path="/training/profiles/create">
        <CreateProfileScreen />
      </Route>
      <Route exact path="/training/profiles/:profileId">
        <ShowProfileScreen />
      </Route>
      <Route exact path="/training/modifiers/create">
        <CreateModifierScreen />
      </Route>
      <Route exact path="/training/modifiers/:modifierId">
        <ShowModifierScreen />
      </Route>
      <Route exact path="/training/apply-profiles">
        <ApplyProfilesScreen />
      </Route>
      <Route exact path="/training/apply-modifiers">
        <ApplyModifiersScreen />
      </Route>
    </Switch>
  );
};

export default TrainingModule;
