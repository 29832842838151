import React, { FunctionComponent } from 'react';
import { Box, Button, Menu, MenuItem, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { CaddieColours } from '../../../theme/colours';

type FarmStatusDropdownProps = {
  selectedFarmsNumber: number;
  handleEmergencyClick: (isOpen: boolean) => void;
  handleActiveClick: (isOpen: boolean) => void;
};

const useStyles = makeStyles(() => ({
  StatusIcon: {
    borderRadius: '50%',
    marginRight: '8px',
    border: 1,
    width: '8px',
    height: '8px',
  },
  Menu: {
    marginTop: '5px',
  },
  MenuItem: {
    height: 40,
    padding: '25px 16px',
  },
  ButtonStyle: {
    backgroundColor: 'black',
    color: 'white',
    textTransform: 'none',
  },
  DropDownIcon: {
    color: 'white',
  },
}));

const FarmStatusDropdown: FunctionComponent<FarmStatusDropdownProps> = ({
  selectedFarmsNumber,
  handleEmergencyClick,
  handleActiveClick,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActiveItem = () => {
    handleActiveClick(true);
    handleClose();
  };

  const handleEmergencyItem = () => {
    handleEmergencyClick(true);
    handleClose();
  };

  return (
    <div>
      <Button className={classes.ButtonStyle} onClick={handleClick}>
        {selectedFarmsNumber === 0 ? 'Set status' : `Set status (${selectedFarmsNumber})`}
        <ArrowDropDownIcon className={classes.DropDownIcon} />
      </Button>
      <Menu
        id="simple-menu"
        className={classes.Menu}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={handleActiveItem} className={classes.MenuItem}>
          <Box className={classes.StatusIcon} style={{ backgroundColor: CaddieColours.ActiveGreen }} />
          Active
        </MenuItem>
        <MenuItem
          onClick={handleEmergencyItem}
          className={classes.MenuItem}
          style={{ color: CaddieColours.EmergencyRed }}
        >
          <Box className={classes.StatusIcon} style={{ backgroundColor: CaddieColours.EmergencyRed }} />
          Emergency
        </MenuItem>
      </Menu>
    </div>
  );
};

export default FarmStatusDropdown;
