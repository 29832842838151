import { ITowerLocationDTO } from '@halter-corp/tower-service-client';
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  FormControl,
  Input,
  Tooltip,
} from '@material-ui/core';
import { Edit, Check, Delete, Restore, Save } from '@material-ui/icons';
import { replaceWhitespaces } from 'application/utils';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Tower } from 'store/selectors/tower.selectors';
import Button from 'application/components/button';
import { useInvalidInputContext } from '../contexts/invalid-input-context';
import { useModifiedTowersContext } from '../contexts/modified-tower-context';
import TowerNotesPopupDrawer from './tower-notes-popup-drawer';
import DeleteConfirmationDialog from './dialogs/delete-confirmation.dialog';

interface TowerPopupProps {
  tower: Tower;
  setTowerMap: Dispatch<SetStateAction<Map<string, Tower>>>;
  existing: boolean;
  editingState: boolean;
  setEditingState: (state: boolean) => void;
  handleCreateTower: (tower: Tower) => Promise<any>;
  handleRemoveTower: (towerId: string) => void;
  handleDeleteTowerFarmMapping?: (towerId: string) => void;
  handleUpdateTower: (tower: Tower) => void;
}
const TowerPopupEditor = ({
  tower,
  setTowerMap,
  existing,
  editingState,
  handleCreateTower,
  handleUpdateTower,
  handleRemoveTower,
  handleDeleteTowerFarmMapping,
  setEditingState,
}: TowerPopupProps) => {
  const smallSquareButton = { height: 14, width: 14 };

  const [formData, setFormData] = useState<{
    id: string;
    latitude: number;
    longitude: number;
    mainsPowered: boolean | undefined;
    starlink: boolean | undefined;
    router: boolean | undefined;
    notes: [];
  }>({
    id: tower?.id,
    latitude: tower?.location.latitude,
    longitude: tower?.location.longitude,
    mainsPowered: tower?.mainsPowered,
    starlink: tower?.starlink,
    router: tower?.router,
    notes: [],
  });
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const { modified, setModified } = useModifiedTowersContext();
  const { setInvalidInput } = useInvalidInputContext();

  const handleTowerForm = () => {
    if (formData.mainsPowered === undefined) {
      return;
    }
    setModified((current) => {
      current.delete(tower.id);
      return new Map(current);
    });
    setInvalidInput((current) => {
      current.delete(tower.id);
      return new Map(current);
    });
    const newTowerId = replaceWhitespaces(formData.id.trim(), '-');
    const updatedTower: Tower = {
      id: newTowerId,
      farmId: tower.farmId,
      networkGroupId: tower.networkGroupId,
      location: {
        latitude: formData.latitude,
        longitude: formData.longitude,
      },
      mainsPowered: formData.mainsPowered,
      router: formData.router,
      starlink: formData.starlink,
      notes: tower.notes,
    };

    if (tower.id !== newTowerId) {
      setTowerMap((current) => {
        current.delete(tower.id);
        current.set(newTowerId, updatedTower);
        return new Map(current);
      });
    } else {
      setTowerMap((current) => {
        current.set(tower.id, updatedTower);
        return new Map(current);
      });
    }
  };

  const updateLocation = (towerLocation: ITowerLocationDTO) => {
    const { latitude, longitude } = towerLocation;
    setFormData({
      ...formData,
      latitude,
      longitude,
    });
  };

  useEffect(() => {
    if (tower?.location) {
      updateLocation(tower.location);
    }
  }, [tower?.location]);

  const handleCommitTower = () => {
    setEditingState(false);
    handleTowerForm();
  };

  const handleOnSaveTower = async () => {
    const result = await handleCreateTower(tower);
    if (result.payload != null) handleRemoveTower(tower.id);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModified((current) => {
      current.set(tower.id, true);
      return new Map(current);
    });
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === 'latitude' || e.target.name === 'longitude'
          ? Number(e.target.value)
          : e.target.value,
    });
  };

  const handleRemoveTowerFarmMapping = () => {
    if (handleDeleteTowerFarmMapping) {
      handleDeleteTowerFarmMapping(tower.id);
    }
  };

  const handleResetTower = () => {
    setFormData({
      id: tower?.id,
      latitude: tower?.location.latitude,
      longitude: tower?.location.longitude,
      mainsPowered: tower?.mainsPowered,
      starlink: tower?.starlink,
      router: tower?.router,
      notes: [],
    });
    setModified((current) => {
      current.delete(tower.id);
      return new Map(current);
    });
    setEditingState(false);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        {existing ? (
          <Typography style={{ fontSize: 14, marginTop: 0, marginBottom: 0, marginRight: 3 }}>
            {tower.id}
          </Typography>
        ) : (
          <FormControl>
            <Input
              style={{ fontSize: 14, marginTop: 0, marginBottom: 0, marginRight: 3 }}
              id={`${tower?.id}-name`}
              name="id"
              type="text"
              onFocus={handleFocus}
              onChange={handleFormChange}
              defaultValue={formData.id}
            />
          </FormControl>
        )}
        <Box display="flex" marginLeft="auto" marginRight={0}>
          {existing && modified.get(tower.id) ? (
            <Tooltip title="Reset changes">
              <div>
                <IconButton style={smallSquareButton} onClick={() => handleResetTower()}>
                  <Restore fontSize="small" />
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={existing ? 'Push changes' : 'Create tower'}>
              <div>
                <IconButton
                  style={smallSquareButton}
                  onClick={() => {
                    if (existing) handleUpdateTower(tower);
                    else handleOnSaveTower();
                  }}
                  disabled={modified.get(tower.id)}
                >
                  <Save fontSize="small" />
                </IconButton>
              </div>
            </Tooltip>
          )}
          {!editingState ? (
            <Tooltip key={`${editingState}`} title="Edit tower">
              <div>
                <IconButton
                  style={smallSquareButton}
                  onClick={() => {
                    setEditingState(true);
                  }}
                  disabled={editingState}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <Tooltip key={`${editingState}`} title="Commit changes">
              <div>
                <IconButton
                  style={smallSquareButton}
                  onClick={() => {
                    handleCommitTower();
                  }}
                  disabled={!modified.get(tower.id)}
                >
                  <Check fontSize="small" />
                </IconButton>
              </div>
            </Tooltip>
          )}
          <Tooltip title="Delete tower">
            <div>
              <IconButton
                style={smallSquareButton}
                onClick={() => {
                  if (existing) setOpenDeleteDialogue(true);
                  else handleRemoveTower(tower.id);
                }}
                disabled={!editingState}
              >
                <Delete fontSize="small" />
              </IconButton>
            </div>
          </Tooltip>
        </Box>
      </Box>
      <FormGroup>
        <FormControlLabel
          disabled={!editingState ?? true}
          control={
            <Checkbox
              size="small"
              checked={formData.mainsPowered}
              onChange={() => {
                setModified((current) => {
                  current.set(tower.id, true);
                  return new Map(current);
                });
                setFormData({ ...formData, mainsPowered: !formData.mainsPowered });
              }}
            />
          }
          label="Mains Powered"
        />
        <FormControlLabel
          disabled={!editingState ?? true}
          control={
            <Checkbox
              checked={formData.router}
              size="small"
              onChange={() => {
                setModified((current) => {
                  current.set(tower.id, true);
                  return new Map(current);
                });
                setFormData({ ...formData, router: !formData.router });
              }}
            />
          }
          label="Router"
        />
        <FormControlLabel
          disabled={!editingState ?? true}
          control={
            <Checkbox
              checked={formData.starlink}
              size="small"
              onChange={() => {
                setModified((current) => {
                  current.set(tower.id, true);
                  return new Map(current);
                });
                setFormData({ ...formData, starlink: !formData.starlink });
              }}
            />
          }
          label="Starlink"
        />
      </FormGroup>
      <Box display="grid" style={{ gap: 8 }}>
        {existing && <TowerNotesPopupDrawer tower={tower} />}
        {handleDeleteTowerFarmMapping !== undefined && (
          <Button
            text="Unmap from current Farm"
            style={{ width: '100%', backgroundColor: 'red' }}
            onClick={handleRemoveTowerFarmMapping}
          />
        )}
      </Box>
      <DeleteConfirmationDialog
        open={openDeleteDialogue}
        onClose={() => setOpenDeleteDialogue(false)}
        handleDelete={handleRemoveTower}
        tower={tower}
      />
    </Box>
  );
};

export default TowerPopupEditor;
