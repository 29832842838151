import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Drawer, Box, Button } from '@material-ui/core';
import { orderBy } from 'lodash';
import styled from 'styled-components';

import NoteFormDialog from 'application/modules/install-planner/components/dialogs/note-form.dialog';
import { TowerWithDevicesAndNotes } from 'store/selectors/tower.selectors';
import towerEffects from 'store/effects/tower.effects';
import { ICreateTowerNoteRequestDTO, IUpdateTowerNoteRequestDTO } from '@halter-corp/tower-service-client';
import TowerNoteCard from './tower-note-card';
import TowerNoteDrawerHeader from './tower-note-drawer-header';

const cardBoxShadow =
  'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px';

const NotesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 8px;
`;

type TowerNoteDrawerProps = {
  open: boolean;
  onClose: () => void;
  selectedTower: TowerWithDevicesAndNotes | undefined;
  drawerWidth: number;
};

const TowerNotesDrawer: FC<TowerNoteDrawerProps> = ({ open, onClose, selectedTower, drawerWidth }) => {
  const notes = selectedTower?.notes || [];
  const sortedNotes = orderBy(notes, 'createdAt', 'desc');
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const handleNoteCreate = (createTowerNoteRequest: ICreateTowerNoteRequestDTO) => {
    if (!selectedTower) return;
    setCreateDialogOpen(false);

    dispatch(
      towerEffects.saveTowerNote({
        createTowerNoteRequest,
        towerId: selectedTower.id,
      })
    );
  };

  const handleNoteUpdate = (noteCreatedAt: number, updateNoteDto: IUpdateTowerNoteRequestDTO) => {
    if (selectedTower == null) return;
    dispatch(
      towerEffects.updateNoteByTowerId({
        towerId: selectedTower.id,
        createdAt: noteCreatedAt,
        updateNoteDto,
      })
    );
  };

  const handleNoteDelete = (noteCreatedAt: number) => {
    if (selectedTower == null) return;
    dispatch(towerEffects.deleteNoteByTowerId({ towerId: selectedTower.id, createdAt: noteCreatedAt }));
  };

  const handleCloseDialog = () => setCreateDialogOpen(false);

  return (
    <Drawer
      style={{ width: drawerWidth, minHeight: '97vh' }}
      PaperProps={{
        style: {
          position: 'relative',
          boxShadow: open ? cardBoxShadow : '',
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <Box style={{ padding: '8px 14px' }}>
        <TowerNoteDrawerHeader selectedTower={selectedTower} onClose={onClose} />

        <Box display="flex" mt={2.5} mb={1.5} px={0.7} justifyContent="center" alignItems="center">
          <Typography style={{ width: '100%', fontWeight: 500, fontSize: 16 }} component="p">
            {selectedTower?.notes.length} Note{notes.length !== 1 && 's'}
          </Typography>

          <Button
            onClick={() => setCreateDialogOpen(true)}
            style={{ whiteSpace: 'nowrap', paddingLeft: 18, paddingRight: 18 }}
            size="small"
            variant="outlined"
            color="primary"
          >
            Create Note
          </Button>
        </Box>

        {selectedTower && (
          <NotesList>
            {sortedNotes.map((note) => (
              <TowerNoteCard
                key={`${note.note}-${note.createdAt}`}
                note={note}
                tower={selectedTower}
                onDeleteNote={handleNoteDelete}
                onUpdateNote={handleNoteUpdate}
              />
            ))}
          </NotesList>
        )}
      </Box>

      {selectedTower && (
        <NoteFormDialog
          open={createDialogOpen}
          tower={selectedTower}
          onClose={handleCloseDialog}
          onSave={handleNoteCreate}
        />
      )}
    </Drawer>
  );
};

export default TowerNotesDrawer;
