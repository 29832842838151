import React, { FunctionComponent, useState } from 'react';
import { Grid, Modal, Typography, IconButton, TextField } from '@material-ui/core';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { IBulkMarkAsMatedRequestDTO } from '@halter-corp/cattle-breeding-service-client';
import BehaviourService from 'services/behaviour.service';
import Button from 'application/components/button';
import CattleService from 'services/cattle.service';
import { splitNumberList } from 'application/utils';
import { DateString } from '../utils';
import SimpleSnackbar from './snackbar';

const SEPARATOR = ',';

const ModalBody = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  margin: 16px;
`;

type BulkAddMatingsModalProps = {
  seasonId: string;
  farmId: string;
};

const BulkAddMatingsModal: FunctionComponent<BulkAddMatingsModalProps> = ({ seasonId, farmId }) => {
  const [open, setOpen] = useState(false);
  const [cattleNames, setCattleNames] = useState(['']);
  const [matedAt, setMatedAt] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCattleNames(['']);
    setMatedAt(new Date());
    setOpen(false);
  };

  const fetchCattleIds = async (): Promise<string[] | undefined> => {
    if (farmId == null || cattleNames == null) return undefined;
    const allCattle = await CattleService.fetchCattleByName(cattleNames, farmId);
    return allCattle.map((item) => item.id);
  };

  const markAsMated = async () => {
    setLoading(true);
    const cattleIds = await fetchCattleIds();
    if (cattleIds == null) return;
    try {
      const iBulkMarkAsMatedRequestDTO: IBulkMarkAsMatedRequestDTO = {
        cattleIds,
        matedAt,
      };
      await BehaviourService.bulkMarkAsMated(seasonId, farmId, iBulkMarkAsMatedRequestDTO);
    } catch (err) {
      setSnackBarOpen(true);
      setSnackBarMessage(`Something went wrong: ${err}`);
    }
    setLoading(false);
    handleClose();
  };

  return (
    <>
      <Button text="Add matings" colour="white" backgroundColour="black" onClick={handleClickOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          outline: 'none',
          ...{ marginLeft: '25%', marginRight: '25%' },
        }}
      >
        <ModalBody>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                Please put in the time she was mated!
              </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <IconButton onClick={handleClose} style={{ color: 'black' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Cow ear tags"
                value={cattleNames?.join(SEPARATOR)}
                onChange={(event) => setCattleNames(splitNumberList(event.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mated date"
                type="datetime-local"
                value={DateString.fromDateTime(matedAt)}
                onChange={(event) => setMatedAt(new Date(event.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                text="Mark as mated"
                colour="white"
                backgroundColour="black"
                onClick={markAsMated}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
      <SimpleSnackbar message={snackBarMessage} open={snackBarOpen} />
    </>
  );
};

export default BulkAddMatingsModal;
