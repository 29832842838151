import React from 'react';
import { ProductTypeEnum } from '@halter-corp/discovery-service-client';
import { Box, Link, Tooltip } from '@material-ui/core';
import { Column } from 'application/components/data-table';
import { StatusColours } from 'application/components/status-icon';
import { environment } from 'env-config';
import { round } from 'lodash';
import moment from 'moment';
import { DeviceWithMetadata } from 'store/selectors/tower.selectors';
import {
  deviceStatusKeyExtractor,
  generateStatusIcon,
  getDeviceStatusColor,
  getHighestPriorityStatus,
  getPrettifiedDeviceType,
  getSelectionTimeLeft,
  getStatusColor,
  getSuspendedColor,
  selectionKeyExtractor,
} from './table-utils';
import { DevicesDataRowData } from './devices-data-row';

export const indexColumn: Column<{}> = {
  name: '',
  render: (_thing, index) => Number(index + 1),
};

export const towerNameColumn: Column<DevicesDataRowData> = {
  name: 'Tower ID',
  keyExtractor: (tower) => tower.id,
  render: (tower) => tower.id,
  allowCopyToClipboard: true,
};

export const towerDeviceCountColumn: Column<DevicesDataRowData> = {
  name: 'Device Count',
  keyExtractor: (tower) => tower.devices.length,
  render: (tower) => tower.devices.length,
  allowCopyToClipboard: true,
};

export const typeColumn: Column<DeviceWithMetadata> = {
  name: 'Type',
  keyExtractor: (device) => device.type,
  render: getPrettifiedDeviceType,
  allowCopyToClipboard: true,
};

export const deviceNameColumn: Column<DeviceWithMetadata> = {
  name: 'Name',
  keyExtractor: (device) => device.name,
  render: (device) => device.name,
  allowCopyToClipboard: true,
};

export const deviceTowerNameColumn: Column<DeviceWithMetadata> = {
  name: 'Tower ID',
  keyExtractor: (device) => device.towerId ?? '!',
  render: (device) => {
    if (device.towerId != null) {
      return device.towerId;
    }
    return 'Not tagged';
  },
  allowCopyToClipboard: true,
};

export const idColumn: Column<DeviceWithMetadata> = {
  name: 'ID',
  keyExtractor: (device) => device.id,
  render: (device) => {
    if (device.type === ProductTypeEnum.GATEWAY) {
      return (
        <Link
          href={`https://${device.shardId}.lorawan.ap-southeast-2.${environment}.halter.io/console/gateways/${device.id}`}
          target="_blank"
        >
          {device.id}
        </Link>
      );
    }
    if (device.type === ProductTypeEnum.SOLAR_SWITCH) {
      return (
        <Link
          href={`https://halter.grafana.net/d/vl0V56R4k/solar-switch-metrics?orgId=1&var-environment=${environment}&var-serialNumber=${device.id}`}
          target="_blank"
        >
          {device.id}
        </Link>
      );
    }
    if (device.type === ProductTypeEnum.TOWER_NODE) {
      return (
        <Link href={`http://${device.id}.tower-nodes.${environment}.halter.io`} target="_blank">
          {device.id}
        </Link>
      );
    }
    if (device.type === ProductTypeEnum.ROUTER) {
      if (device.id.match(/^rutx/i) != null) {
        return (
          <Link href={`https://${device.id}.customer-networks.${environment}.halter.io`} target="_blank">
            {device.id}
          </Link>
        );
      }

      return (
        <Link href={`https://${device.id}.farms.${environment}.halter.io`} target="_blank">
          {device.id}
        </Link>
      );
    }
    return device.id;
  },
  allowCopyToClipboard: true,
};

export const enabledColumn: Column<DeviceWithMetadata> = {
  name: 'Enabled',
  render: (device) => {
    if (device.status != null) {
      return String(device.status.enabled);
    }
    return 'null';
  },
  allowCopyToClipboard: true,
};

export const suspendedColumn: Column<DeviceWithMetadata> = {
  name: 'Suspended',
  render: (device) => {
    if (device.status?.suspended != null) {
      const { suspended } = device.status;
      return (
        <Box component="span">
          {generateStatusIcon(getSuspendedColor(device))}
          {String(suspended)}
        </Box>
      );
    }
    return 'null';
  },
  allowCopyToClipboard: true,
};

export const statusColumn: Column<DeviceWithMetadata> = {
  name: 'Status',
  keyExtractor: (device) => deviceStatusKeyExtractor(device),
  render: (device) => (
    <Box component="span">
      {generateStatusIcon(getDeviceStatusColor(device))}
      {getHighestPriorityStatus(device)}
    </Box>
  ),
  allowCopyToClipboard: true,
};

export const selectionColumn: Column<DeviceWithMetadata> = {
  name: 'Selection',
  keyExtractor: (device) => selectionKeyExtractor(device),
  render: (device) => {
    if (device.type === ProductTypeEnum.SOLAR_SWITCH) {
      return device.settings?.selectionPortOverride ?? 'N/a';
    }
    if (device.settings?.forceOn) {
      return 'Selection disabled';
    }
    if (device.type !== ProductTypeEnum.GATEWAY) {
      return 'N/a';
    }
    return getSelectionTimeLeft(device);
  },
  allowCopyToClipboard: true,
};

export const batteryVoltageColumn: Column<DeviceWithMetadata> = {
  name: 'Battery Voltage',
  keyExtractor: (device) => device.status?.batteryVoltageInMv ?? 0,
  render: (device) => {
    const batteryMv = device.status?.batteryVoltageInMv;
    if (batteryMv != null) {
      const adjustedBatteryMv = batteryMv - 22000;
      return (
        <>
          <Tooltip title={`${round(batteryMv / 1000, 1)}V`}>
            <Box component="span">{`${round((adjustedBatteryMv / 6000) * 100, 1)}%`}</Box>
          </Tooltip>
          {generateStatusIcon(batteryMv > 23000 ? StatusColours.GREEN : StatusColours.AMBER)}
        </>
      );
    }
    return device.type === ProductTypeEnum.SOLAR_SWITCH ? 'Unavailable' : '';
  },
  allowCopyToClipboard: true,
};

export const lastCheckinColumn: Column<DeviceWithMetadata> = {
  name: 'Last check-in',
  keyExtractor: (device) => device.status?.lastSeenTimestamp,
  render: (device) => {
    if (device?.status?.enabled == null) {
      return <Box component="span">Unavailable</Box>;
    }
    if (device.status?.lastSeenTimestamp != null) {
      const timestamp = device.status.lastSeenTimestamp;
      return (
        <>
          <Tooltip title={`${timestamp}`}>
            <Box component="span">
              {generateStatusIcon(getStatusColor(timestamp, device))}
              {moment.utc(timestamp).fromNow()}
            </Box>
          </Tooltip>
        </>
      );
    }
    return device.status.enabled ? (
      <>
        <Tooltip title="sad">
          <Box component="span">M.I.A</Box>
        </Tooltip>
        {generateStatusIcon(StatusColours.RED)}
      </>
    ) : (
      <>
        <Tooltip title="Maintenance mode enabled">
          <Box component="span">No data</Box>
        </Tooltip>
        {generateStatusIcon(StatusColours.GRAY)}
      </>
    );
  },
  allowCopyToClipboard: true,
};

export const vendorColumn: Column<DeviceWithMetadata> = {
  name: 'Vendor',
  keyExtractor: (device) => device.status?.lastSeenTimestamp,
  render: (device) => device.vendor,
  allowCopyToClipboard: true,
};
