import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { ICattleDTO, IMobDTO } from '@halter-corp/cattle-service-client';

import store from 'store';
import cattleEffects from 'store/effects/cattle.effects';

import { useSelector } from 'react-redux';
import { selectAllCattle } from 'store/selectors/cattle.selectors';
import HttpApiService from './http-api.service';

const CattleService = {
  useSelectCattle: () => useSelector(selectAllCattle),
  useFetchMobsList: () => useCallback((farmId: string) => CattleService.fetchMobsList(farmId), []),

  fetchMobsList: async (farmId: string) => {
    const api = await HttpApiService.getMobApi(farmId);

    const { data } = await api.findAll();
    return data;
  },

  fetchMobById: async (farmId: string, mobId: string): Promise<IMobDTO | undefined> => {
    const api = await HttpApiService.getMobApi(farmId);
    try {
      const { data } = await api.findbyId(mobId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },

  tagOff: async (farmId: string, cattleId: string): Promise<void> =>
    unwrapResult(await store.dispatch(cattleEffects.tagOff({ farmId, cattleId }))),

  togglePause: async (cattle: ICattleDTO): Promise<void> =>
    unwrapResult(await store.dispatch(cattleEffects.togglePause({ cattle }))),

  fetchCattleByName: async (cattleNames: string[], farmId: string) => {
    const cattleApi = await HttpApiService.getCattleApi(farmId);

    const { data } = await cattleApi.findAllCattle(
      `name:in:${cattleNames.join(',')}`,
      undefined,
      undefined,
      undefined
    );
    return data;
  },
};

export default CattleService;
