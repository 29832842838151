import { createSelector } from '@reduxjs/toolkit';
import { intersection } from 'lodash';
import { AppState } from 'store';
import { LatLng, LatLngBounds } from 'leaflet';
import { findAllCowgEvents } from './map-page-common.selector';

/**
 * Cattle Events
 */
// Order sets color priority on caddie
// To match events being generated in the parser
// https://github.com/halter-corp/halter-common-protobuf/blob/master/src/notification-cowg-event-stream-parser.ts#L12-L33
export enum CowgStatusEnum {
  HARD_SHOCK_LOCKOUT = 'HARD_SHOCK_LOCKOUT',
  SOFT_SHOCK_LOCKOUT = 'SOFT_SHOCK_LOCKOUT',
  CONTROL_DISABLED_STATIONARY_COW = 'CONTROL_DISABLED_STATIONARY_COW',
  CONTROL_DISABLED_LOCATION = 'CONTROL_DISABLED_LOCATION',
  CONTROL_DISABLED_NEAR_WATER_TROUGH = 'CONTROL_DISABLED_NEAR_WATER_TROUGH',
  CONTROL_DISABLED_COMMAND_PAUSED = 'CONTROL_DISABLED_COMMAND_PAUSED',
  CONTROL_DISABLED_MANUAL_PAUSE_OR_DISABLED = 'CONTROL_DISABLED_MANUAL_PAUSE_OR_DISABLED',
  CONTROL_DISABLED_OTHER = 'CONTROL_DISABLED_OTHER',
  COLLAR_ROLLED = 'COLLAR_ROLLED',
  SHOCK = 'SHOCK',
  SHOCK_FAILED = 'SHOCK_FAILED',
  SHOVE = 'SHOVE',
  VIBING = 'VIBING',
  PIEZOING = 'PIEZOING',
  GENTLE = 'GENTLE',
  IN_BOUNDARY = 'IN_BOUNDARY',
  IN_ZONE = 'IN_ZONE',
  TRANSITIONING = 'TRANSITIONING',
}

export enum CowgEventType {
  EVENT_POSITION_UPDATE = 0, // Logged when there are no events, just a position update with location metadata
  EVENT_CONTROL_STATUS_CHANGED = 1, // Logged every time cowgorithm control state changes
  EVENT_CONTROL_SHOCKED = 2, // Logged every time cowg shocked cow and it was confirmed
  EVENT_CONTROL_NUDGED = 3, // Logged every time cowg nudged cow, deprecated, replaced with BIT_NUDGING status
  EVENT_CONTROL_SHOCK_FAILED = 4, // Logged every time cowg shocked cow and it was not confirmed (i.e. failed)
  EVENT_PADDOCK_ACTIVATED = 5,
  EVENT_PADDOCK_DEACTIVATED = 6,
}

export type CattleEvent = {
  id: string;
  serialNumber: string;
  cattleName: string;
  timestamp: string;
  location: { lat: number; lon: number };
  locationMetadata: {
    heading: number;
  };
  commandId: number;
  cowgStatuses: CowgStatusEnum[];
  eventType: CowgEventType;
};

export const transitionCowgStatuses = [
  CowgStatusEnum.IN_BOUNDARY,
  CowgStatusEnum.IN_ZONE,
  CowgStatusEnum.TRANSITIONING,
];

export const findCattleEvent = createSelector(findAllCowgEvents, (cowgEvents) =>
  cowgEvents.map((cowgEvent) => ({
    commandId: cowgEvent.collarCommandId,
    id: cowgEvent.cattleId,
    eventType: cowgEvent.eventType,
    cattleName: cowgEvent.cattleName,
    serialNumber: cowgEvent.serialNumber,
    firmwareVersion: cowgEvent.firmwareVersion,
    cowgStatuses: cowgEvent.cowgStatuses,
    pulseAttemptCount: cowgEvent.pulseAttemptCount,
    pulseSuccessCount: cowgEvent.pulseSuccessCount,
    locationMetadata: {
      heading: cowgEvent.heading,
      targetHeading: cowgEvent.targetHeading,
      fixAge: cowgEvent.fixAge,
    },
    location: { lat: cowgEvent.latitude, lon: cowgEvent.longitude },
    timestamp: cowgEvent.timestamp,
    peakShockConfirmCurrentAmps: cowgEvent.peakShockConfirmCurrentAmps,
  }))
);

export const findCattleEventsForViewBoundsAndDateRangeAndSelectedFilters = createSelector(
  findCattleEvent,
  (_state: AppState, props: { minimumDate: Date | null }) => props.minimumDate?.getTime(),
  (_state: AppState, props: { maximumDate: Date | null }) => props.maximumDate?.getTime(),
  (_state: AppState, props: { mapViewBounds: LatLngBounds | null }) => props.mapViewBounds,
  (_state: AppState, props: { selectedCows: string[] | null }) => props.selectedCows,
  (_state: AppState, props: { selectedCowGStatuses: CowgStatusEnum[] | null }) => props.selectedCowGStatuses,
  (events, minTimestamp, maxTimestamp, mapViewBounds, selectedCows, selectedCowGStatuses) =>
    events.filter((event) => {
      if (minTimestamp != null && new Date(event.timestamp).getTime() < minTimestamp) return false;
      if (maxTimestamp != null && new Date(event.timestamp).getTime() > maxTimestamp) return false;
      if (selectedCows != null && !selectedCows.includes(event.cattleName)) return false;
      if (
        mapViewBounds != null &&
        !mapViewBounds.contains(new LatLng(event.location.lat, event.location.lon))
      )
        return false;
      // Always render activation / deactivation events
      if (event.eventType === 5 || event.eventType === 6) return true;
      // Always render events when cow has left both paddock and break
      if (event.cowgStatuses.length === 0) return true;
      if (selectedCowGStatuses != null && intersection(selectedCowGStatuses, event.cowgStatuses).length === 0)
        return false;

      return true;
    })
);
