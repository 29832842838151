import React, { ChangeEvent, FunctionComponent, useMemo, useState } from 'react';
import { Box, MenuItem, Select, TableContainer } from '@material-ui/core';
import Wrapper from 'application/components/wrapper';
import { makeStyles } from '@material-ui/core/styles';

import styled from 'styled-components';
import Checkbox from 'application/components/checkbox';
import {
  DeviceStatusEnum,
  DeviceWithMetadata,
  TowerWithDevicesAndNotes,
} from 'store/selectors/tower.selectors';
import { ProductTypeEnum } from 'application/utils/infrastructureUtils';
import Tab from '../components/tab';
import TowersSearchPanel from '../panels/towers-search.panel';
import OverviewTable from '../tables/overview-table';
import DevicesActionsWrapper from '../components/DevicesActionsWrapper';
import TowerNotesDrawer from '../components/notes/tower-notes-drawer';

type TowersScreenProps = {
  farmId: string;
  shardId: string;
  searchMode: 'farm' | 'shard';
  autoRefresh: boolean;
  enabledOnly: boolean;
  disabledOnly: boolean;
  onChangeAutoRefresh: (value: boolean) => void;
  onChangeEnabledOnly: (value: boolean) => void;
  onChangeDisabledOnly: (value: boolean) => void;
  onChangeSelectedDeviceType: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
  onChangeSelectedDeviceStatus: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
  devices: DeviceWithMetadata[];
  towers: TowerWithDevicesAndNotes[];
  displayTable: boolean;
};

const CheckboxWrapper = styled.div`
  margin: 4px 0px 0px 40px;
`;
const SelectWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const HorizontalStickyContainer = styled.div`
  position: sticky;
  left: 0;
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  flex-wrap: wrap;
`;

const TableWrapper = styled.div``;

const drawerWidth = 480;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const TowersOverviewScreen: FunctionComponent<TowersScreenProps> = ({
  searchMode,
  farmId,
  shardId,
  autoRefresh = false,
  enabledOnly = false,
  disabledOnly = false,
  onChangeAutoRefresh,
  onChangeEnabledOnly,
  onChangeDisabledOnly,
  onChangeSelectedDeviceType,
  onChangeSelectedDeviceStatus,
  devices,
  towers,
  displayTable,
}) => {
  const [selectedSearchMode, setSelectedSearchMode] = useState(searchMode);
  const [farmIdInSelect, setFarmIdInSelect] = useState(farmId);
  const [shardIdInSelect, setShardIdInSelect] = useState(shardId);
  const [selectedDevices, setSelectedDevices] = useState<Map<string, DeviceWithMetadata>>(new Map());
  const [selectedTowerIdForNotes, setSelectedTowerIdForNotes] = useState<string | null>(null);

  const classes = useStyles();

  const handleTowerNotesSelect = (selectedTower: TowerWithDevicesAndNotes) => {
    setSelectedTowerIdForNotes((curentId) => {
      if (curentId === selectedTower.id) return null;
      return selectedTower.id;
    });
  };

  const drawerOpen = !!selectedTowerIdForNotes;

  const selectedTowerForNotes = useMemo(
    () => towers.find((tower) => tower.id === selectedTowerIdForNotes),
    [towers, selectedTowerIdForNotes]
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Wrapper
        style={{ display: 'flex' }}
        className={`${classes.content} ${drawerOpen ? classes.contentShift : ''}`}
      >
        <HorizontalStickyContainer>
          <TabsWrapper>
            <Tab
              text="Search by farm"
              selected={selectedSearchMode === 'farm'}
              onPress={() => {
                setSelectedSearchMode('farm');
              }}
            />
            <Tab
              text="Search by shard"
              selected={selectedSearchMode === 'shard'}
              onPress={() => {
                setSelectedSearchMode('shard');
              }}
            />
          </TabsWrapper>
          <TowersSearchPanel
            searchMode={selectedSearchMode}
            onChangeFarmId={setFarmIdInSelect}
            onChangeShardId={setShardIdInSelect}
            setSelectedDevices={setSelectedDevices}
            farmId={farmIdInSelect}
            shardId={shardIdInSelect}
          />
          <TabsWrapper>
            <DevicesActionsWrapper selectedDevices={selectedDevices} searchMode={searchMode} />
            <CheckboxWrapper>
              <Checkbox label="Auto refresh" checked={autoRefresh} onChangeValue={onChangeAutoRefresh} />
            </CheckboxWrapper>
            {searchMode === 'shard' && (
              <>
                <CheckboxWrapper>
                  <Checkbox
                    label="Enabled only"
                    checked={enabledOnly}
                    onChangeValue={onChangeEnabledOnly}
                    onClick={() => setSelectedDevices(new Map())}
                  />
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <Checkbox
                    label="Disabled only"
                    checked={disabledOnly}
                    onChangeValue={onChangeDisabledOnly}
                    onClick={() => setSelectedDevices(new Map())}
                  />
                </CheckboxWrapper>
                <SelectWrapper>
                  <Select
                    label="Filter by device type"
                    onChange={onChangeSelectedDeviceType}
                    defaultValue="none"
                    style={{ color: '#333111' }}
                  >
                    <MenuItem value="none" disabled>
                      Filter by device type
                    </MenuItem>
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value={ProductTypeEnum.GATEWAY}>Gateway</MenuItem>
                    <MenuItem value={ProductTypeEnum.SOLAR_SWITCH}>Solar Switch</MenuItem>
                  </Select>
                  <Select
                    label="Filter by device status"
                    onChange={onChangeSelectedDeviceStatus}
                    defaultValue="none"
                    style={{ color: '#333111' }}
                  >
                    <MenuItem value="none" disabled>
                      Filter by device status
                    </MenuItem>
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value={DeviceStatusEnum.ON_OUTAGE}>On Outage</MenuItem>
                    <MenuItem value={DeviceStatusEnum.FORCE_OFF}>Force Off</MenuItem>
                    <MenuItem value={DeviceStatusEnum.FORCE_ROUND_ROBIN}>Force Round Robin</MenuItem>
                    <MenuItem value={DeviceStatusEnum.SUSPENDED}>Suspended</MenuItem>
                  </Select>
                </SelectWrapper>
              </>
            )}
          </TabsWrapper>
        </HorizontalStickyContainer>
        {displayTable && searchMode === selectedSearchMode && (
          <>
            <TableContainer>
              <TableWrapper
                style={farmId !== farmIdInSelect || shardId !== shardIdInSelect ? { opacity: 0.5 } : {}}
              >
                <OverviewTable
                  searchMode={searchMode}
                  devices={devices}
                  towers={towers}
                  setSelectedDevices={setSelectedDevices}
                  setSelectedTowerForNotes={handleTowerNotesSelect}
                  selectedDevices={selectedDevices}
                />
              </TableWrapper>
            </TableContainer>
          </>
        )}
      </Wrapper>

      <TowerNotesDrawer
        selectedTower={selectedTowerForNotes}
        open={drawerOpen}
        onClose={() => setSelectedTowerIdForNotes(null)}
        drawerWidth={drawerWidth}
      />
    </Box>
  );
};

export default TowersOverviewScreen;
