import Axios from 'axios';
import { Auth } from 'aws-amplify';
import getHalterApiUrl from '../services/configuration.service';

const getAuthorizationHeader = async () => {
  const session = await Auth.currentSession();
  return { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` };
};

const getContextOverrideHeader = (farmId?: string) => ({
  'context-override': JSON.stringify({
    farmId: farmId ?? window.localStorage.getItem('currentFarm'),
  }),
});

type OpenApiClassType<T> = new (configuration?: any, basePath?: string, axios?: any) => T;

const HttpApiClient = {
  constructOpenApi: async <T>(OpenApi: OpenApiClassType<T>, farmId?: string): Promise<T> => {
    const authorization = await getAuthorizationHeader();
    const contextOverride = getContextOverrideHeader(farmId);

    const axios = Axios.create({ headers: { ...authorization, ...contextOverride } });

    return new OpenApi(undefined, getHalterApiUrl(), axios);
  },
};

export default HttpApiClient;
