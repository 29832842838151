import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';


export type GenericInputProps = {
    label: string;
    onChange?: (value: string) => void;
    value?: string;
}

const GenericInput: FunctionComponent<GenericInputProps> = ({ label, onChange, value }) => (
    <TextField
        label={label}
        onChange={(event) => onChange ? onChange(event.target.value.trim()) : null}
        type="text"
        variant='outlined'
        style={{ width: '100%' }}
        size='small'
        value={value || ''}
    />
)
export default GenericInput;