import { createSlice } from '@reduxjs/toolkit';
import { ISettingsMetadataDTO } from '@halter-corp/settings-service-client';

import settingsEffects from 'store/effects/settings.effects';
import { reset } from 'store/actions';

type SettingsInitialState = {
  metadata?: ISettingsMetadataDTO;
};

const initialState: SettingsInitialState = {
  metadata: undefined,
};

const settingsSlice = createSlice({
  initialState,
  name: 'settingsSlice',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(settingsEffects.fetchMetadata.fulfilled, (state, action) => {
        state.metadata = action.payload;
      }),
});

export default settingsSlice;
