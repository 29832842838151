import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { FileCopyOutlined } from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import useTableSorting from 'application/hooks/use-table-sorting';
import { isString } from 'lodash';
import { Column } from 'application/components/data-table';
import { CattleEventsSummary } from '../../panels/map.panel/events-summary.panel';
import ExpandableCowgEventRow from './expandable-cowg-event-row';

type CowgEventTableProps = {
  cattleEventsLayerData: CattleEventsSummary[];
  columns: Column<CattleEventsSummary>[];
};

const useStyles = makeStyles({
  headerCell: {
    '&:hover $clipboardIcon': {
      opacity: 0.8,
    },
  },
  clipboardIcon: {
    opacity: 0,
    cursor: 'pointer',
    marginBottom: -6,
    transition: 'opacity 0.2s ease-in-out',
  },
});

const CowgEventTable: FunctionComponent<CowgEventTableProps> = ({ cattleEventsLayerData, columns }) => {
  const styles = useStyles();

  const {
    sortColumnName,
    setSortColumnName,
    sortDirection,
    setSortDirection,
    sortedItems: sortedCattleEvents,
  } = useTableSorting<CattleEventsSummary>({
    defaultSortColumnName: 'Ear Tag',
    items: cattleEventsLayerData,
    columns,
  });

  return (
    <TableContainer style={{ maxHeight: '600px', width: '100%' }}>
      <Table stickyHeader aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: 'rgb(232, 234, 237)' }}>
            <TableCell style={{ width: 5, padding: 4 }} />

            {columns.map(({ name, keyExtractor, render, allowCopyToClipboard, headerToolTip }, index) => (
              <TableCell
                key={isString(name) ? name : index}
                className={styles.headerCell}
                sortDirection={sortColumnName === name ? sortDirection : undefined}
                style={{
                  fontSize: 13,
                  fontWeight: 600,
                  paddingLeft: 3,
                  paddingRight: 3,
                }}
              >
                {name && (
                  <Tooltip title={headerToolTip ?? ''}>
                    <TableSortLabel
                      active={sortColumnName === name && name !== ''}
                      direction={sortColumnName === name ? sortDirection : undefined}
                      onClick={() => {
                        if (name === '' || !isString(name)) return;
                        setSortDirection(sortColumnName !== name || sortDirection === 'asc' ? 'desc' : 'asc');
                        setSortColumnName(name);
                      }}
                    >
                      {name}
                    </TableSortLabel>
                  </Tooltip>
                )}
                {allowCopyToClipboard != null && (
                  <FileCopyOutlined
                    className={styles.clipboardIcon}
                    onClick={() => copy(sortedCattleEvents.map<any>(keyExtractor ?? render).join(','))}
                    fontSize="small"
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedCattleEvents?.map((row) => (
            <ExpandableCowgEventRow key={row.cattleName} data={row} columns={columns} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CowgEventTable;
