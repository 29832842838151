import { IDeviceDTO, IDeviceLocationDTO, IFarmDTO } from '@halter-corp/farm-infra-service-client';
import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store/store';

export interface Device extends IDeviceDTO {
  metadata?: {
    enabled: boolean;
    checkInTimestamp?: string;
    batteryVoltage?: number;
    location?: IDeviceLocationDTO;
  };
}
const selectInfra = createSelector(
  (state: AppState) => state.farmInfra,
  (infra) => infra
);

export const selectCurrentFarm = createSelector(
  selectInfra,
  (infra) =>
    infra.currentFarm || window.localStorage.getItem('currentFarm') || '4ef316f1-4628-4457-9468-1a294d1e7769'
);

export const selectCurrentShard = createSelector(
  selectInfra,
  (infra) => infra.currentShard || window.localStorage.getItem('currentShard') || 's-001'
);
export const selectDevices = createSelector(selectInfra, (infra) => infra.devices);

export const selectShards = createSelector(selectInfra, (infra) => infra.shards);

export const selectFarms = createSelector(selectInfra, (infra) => infra.farms);

export const selectNetworkGroups = createSelector(selectInfra, (infra) => infra.networkGroups);

export const selectCurrentFarmShard = createSelector(
  selectCurrentFarm,
  selectFarms,
  (selectedFarm: string, farms: IFarmDTO[]) => farms.find((farm) => farm.id === selectedFarm)?.shardId
);

export const selectFarmMetadata = createSelector(selectInfra, (infra) => infra.currentFarmMetadata);
