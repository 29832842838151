import React, { FunctionComponent, useState } from 'react';
import moment from 'moment';
import { flatten, unflatten } from 'flat';
import { DEFAULT_DATE_TIME_FORMAT } from 'application/modules/debug-cattle/utils/constants';
import { Button, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import SettingsService from 'services/settings.service';
import { SettingsDetail } from 'services/device-detail-page.service';
import { deviceSyncingStatusToTrafficLight } from '../components/device-status.util';
import SettingsTree from '../components/settings-tree';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.text.secondary,
      float: 'left',
      marginRight: '5px;',
    },
  })
);

const dateToText = (date: Date | undefined) => (date ? moment(date).format(DEFAULT_DATE_TIME_FORMAT) : ' - ');

type SettingsDetailsPanelProps = {
  settingsDetail?: SettingsDetail;
  showDefaultValuesEnabled?: boolean;
};

const SettingsDetailPanel: FunctionComponent<SettingsDetailsPanelProps> = ({
  settingsDetail,
  showDefaultValuesEnabled = true,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [showDefaultValues, setShowDefaultValues] = useState(false);

  const settingsMetadata = SettingsService.useSettingsMetadata();

  let data = settingsDetail?.settingsObject ?? {};

  if (settingsMetadata != null && !showDefaultValues) {
    const settingsFlattened = flatten<any, { [key: string]: string | number }>(data);
    const settingsMetadataFlattened = flatten<any, { [key: string]: string | number }>(settingsMetadata);
    data = unflatten(
      Object.entries(settingsFlattened)
        .filter(([key, value]) => settingsMetadataFlattened[`${key}.default`] !== value)
        .map(([key, value]) => ({ [key]: value }))
        .reduce((a, c) => ({ ...a, ...c }), {})
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button color="primary" onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Collapse All' : 'Expand All'}
        </Button>
        {showDefaultValuesEnabled && (
          <Button color="primary" onClick={() => setShowDefaultValues(!showDefaultValues)}>
            {' '}
            {showDefaultValues ? 'Hide Defaults' : 'Show Defaults'}
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>Status: </Typography>
        <Typography>{deviceSyncingStatusToTrafficLight(settingsDetail?.status)}</Typography>
        <Typography>{settingsDetail?.status}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>Last synced: </Typography>
        <Typography>{dateToText(settingsDetail?.lastSyncedDateTime)}</Typography>
      </Grid>
      <Grid item xs={12}>
        <SettingsTree expanded={expanded} data={data || {}} />
      </Grid>
    </Grid>
  );
};

export default SettingsDetailPanel;
