import {
  Grid,
  Typography,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { CowgStatusEnum } from 'store/selectors/map-page-items.selectors';
import { useLeafletZoom } from 'use-leaflet';
import CowgEventFilter from './components/cowg-event-filter';
import EarTagFilter from './components/ear-tag-filter';

type CattleEventsSummary = { cattleName: string; shockFailed: number; shock: number };

export type MapFilterPanelProps = {
  selectedCows: string[];
  selectedCowGStatuses: CowgStatusEnum[];
  allAvailableCows: string[];
  allAvailableCowgStatuses: CowgStatusEnum[];
  onSelectedCowsChange: (selectedCows: string[]) => void;
  onSelectedCowGStatusesChange: (selectedCowGStatuses: CowgStatusEnum[]) => void;
};

const MapFilterPanel: FunctionComponent<MapFilterPanelProps> = ({
  allAvailableCows,
  allAvailableCowgStatuses,
  selectedCows,
  selectedCowGStatuses,
  onSelectedCowsChange,
  onSelectedCowGStatusesChange,
}) => {
  const zoom = useLeafletZoom();
  const showCattleEvents = useMemo(() => zoom > 20, [zoom]);

  const [isMapFilterExpanded, setIsMapFilterExpanded] = useState(false);
  const [allCowsSelected, setAllCowsSelected] = useState(true);
  const [allEventsSelected, setAllEventsSelected] = useState(false);

  const handleCheckUncheckAllCows = (checked: boolean) => {
    setAllCowsSelected(checked);
    onSelectedCowsChange(checked ? allAvailableCows : []);
  };

  const handleCheckUncheckAllEvents = (checked: boolean) => {
    setAllEventsSelected(checked);
    onSelectedCowGStatusesChange(checked ? allAvailableCowgStatuses : []);
  };

  return (
    <>
      <Accordion
        onChange={(e, isExpanded) => setIsMapFilterExpanded(isExpanded)}
        style={{ flex: 1 }}
        expanded={isMapFilterExpanded}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content">
          {!isMapFilterExpanded && (
            <div>
              <Typography color="textSecondary" gutterBottom>
                Event filters
              </Typography>
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails style={{ height: '700px' }}>
          {isMapFilterExpanded && (
            <Grid container spacing={5}>
              <Grid item xs={9}>
                <EarTagFilter
                  onSelectedCowsChange={onSelectedCowsChange}
                  allAvailableCows={allAvailableCows.sort((a: any, b: any) => Number(a) - Number(b))}
                  selectedCows={selectedCows}
                />
              </Grid>
              <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allCowsSelected}
                      onChange={(e, checked) => handleCheckUncheckAllCows(checked)}
                    />
                  }
                  label="Select all cows"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allEventsSelected}
                      onChange={(e, checked) => handleCheckUncheckAllEvents(checked)}
                    />
                  }
                  label="Select all events"
                />
              </Grid>
              {allAvailableCowgStatuses.length > 0 && (
                <CowgEventFilter
                  selectedCowGStatuses={selectedCowGStatuses}
                  allAvailableCowgStatuses={allAvailableCowgStatuses}
                  onSelectedCowGStatusesChange={onSelectedCowGStatusesChange}
                  showCattleEvents={showCattleEvents}
                />
              )}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default MapFilterPanel;
