import React, { ChangeEvent, FunctionComponent } from 'react';
import { DeviceWithMetadata } from 'store/selectors/tower.selectors';
import { Column } from 'application/components/data-table';
import { Table, TableBody } from '@material-ui/core';
import DevicesDataRow, { DevicesDataRowData } from './devices-data-row';

type DeviceCategoryTableProps = {
  category: string;
  columns: Column<DevicesDataRowData>[];
  selectedDevices: Map<string, DeviceWithMetadata>;
  onSelectDevice: (event: ChangeEvent<HTMLInputElement>) => void;
  devices: DeviceWithMetadata[];
  padding?: number;
};

const DeviceCategoryTable: FunctionComponent<DeviceCategoryTableProps> = ({
  category,
  columns,
  selectedDevices,
  onSelectDevice,
  devices,
  padding,
}) => {
  if (devices.length === 0) return null;

  return (
    <Table>
      <TableBody>
        <DevicesDataRow
          columns={columns}
          data={{
            id: category,
            devices,
          }}
          selectedDevices={selectedDevices}
          onSelectDevice={onSelectDevice}
          padding={padding}
        />
      </TableBody>
    </Table>
  );
};

export default DeviceCategoryTable;
