import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { entries, orderBy } from 'lodash';
import { ITrainingModifierDTO, TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';
import { prettyPrintProfileType } from '../../utils';
import ModifiersBeforeAndAfter from './modifiers-before-and-after';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 4px;
`;

const ResultCellWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 4px;
  margin: 2px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
`;

const TypeTitle = styled.div`
  font-size: 18px;
  opacity: 0.8;
  padding: 8px;
`;

const ResultContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
  padding: 8px;
`;

type CowApplySummaryProps = {
  summary: {
    cattleId: string;
    cattleName: string;
    mobName?: string;
    currentModifiers: { [type: string]: ITrainingModifierDTO[] };
    modifiersAfterApply: { [type: string]: ITrainingModifierDTO[] };
  };
};

const CowApplySummary: FunctionComponent<CowApplySummaryProps> = ({ summary }) => (
  <Wrapper>
    Cow {summary.cattleName}
    <ResultsWrapper>
      {entries(summary.modifiersAfterApply).map(([type, modifiersAfter]) => (
        <ResultCellWrapper key={type}>
          <TypeTitle>{prettyPrintProfileType(type as TrainingSettingsTypeEnum)}</TypeTitle>
          <ResultContentWrapper>
            <Label>Modifiers</Label>
            <ModifiersBeforeAndAfter
              before={orderBy(summary.currentModifiers[type] ?? [], ({ priority }) => priority, 'desc')}
              after={orderBy(modifiersAfter, ({ priority }) => priority, 'desc')}
            />
          </ResultContentWrapper>
        </ResultCellWrapper>
      ))}
    </ResultsWrapper>
  </Wrapper>
);

export default CowApplySummary;
