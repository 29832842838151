import React, { FunctionComponent } from 'react';
import { IMobDTO } from '@halter-corp/cattle-service-client';

type CowDotProps = {
  mob?: Pick<IMobDTO, 'displaySettings'>;
};

const CowDot: FunctionComponent<CowDotProps> = ({ mob }) => (
  <div
    style={{
      width: 16,
      height: 16,
      backgroundColor: 'white',
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        width: 12,
        height: 12,
        backgroundColor: mob?.displaySettings?.color ?? 'grey',
        borderRadius: 6,
      }}
    />
  </div>
);

export default CowDot;
