import React, { createContext, FC, useContext, useState } from 'react';

type ModifiedTowerContextType = {
  modified: Map<string, boolean>;
  setModified: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
};

const ModifiedTowersContext = createContext<ModifiedTowerContextType>(null!);

export const useModifiedTowersContext = () => useContext(ModifiedTowersContext);

export const ModifiedTowersContextProvider: FC = ({ children }) => {
  const [modified, setModified] = useState<Map<string, boolean>>(new Map());

  return (
    <ModifiedTowersContext.Provider value={{ modified, setModified }}>
      {children}
    </ModifiedTowersContext.Provider>
  );
};
