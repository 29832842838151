import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import deviceEffects from 'store/effects/device.effects';

import DeviceDetailsScreen from '../screens/device-details.screen';

const DeviceDetailsContainer: FunctionComponent = () => {
  const dispatch = useDispatch();

  const urlQueryString = new URLSearchParams(useLocation().search);
  const atTimeString = urlQueryString.get('atTime');
  const params = useParams() as { serialNumber: string };
  const serialNumber = useMemo(() => params.serialNumber, [params]);

  useEffect(() => {
    dispatch(deviceEffects.fetchBySerialNumber({ serialNumber }));
  }, [dispatch, serialNumber]);

  return (
    <DeviceDetailsScreen
      serialNumber={serialNumber}
      atTime={atTimeString != null ? new Date(atTimeString) : undefined}
    />
  );
};

export default DeviceDetailsContainer;
