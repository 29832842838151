import { createSlice } from '@reduxjs/toolkit';

import { reset } from 'store/actions';
import appliedProfileAdapter from 'store/adapters/applied-profile.adapter';
import {
  profileAdapter,
  appliedModifiersAdapter,
  modifierAdapter,
  cowsToApplyToAdapter,
  farmerTrainingGuideAdapter,
  farmerTrainingGuideOptionsAdapter,
} from 'store/adapters/training.adapters';
import trainingEffects from 'store/effects/training.effects';

const initialState = {
  loading: false,
  profiles: profileAdapter.getInitialState(),
  modifiers: modifierAdapter.getInitialState(),
  appliedModifiers: appliedModifiersAdapter.getInitialState(),
  appliedProfile: appliedProfileAdapter.getInitialState(),
  cowsToApplyTo: cowsToApplyToAdapter.getInitialState(),
  farmerTrainingGuides: farmerTrainingGuideAdapter.getInitialState(),
  farmerTrainingGuideOptions: farmerTrainingGuideOptionsAdapter.getInitialState(),
};

const trainingSlice = createSlice({
  initialState,
  name: 'training',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(trainingEffects.fetchProfiles.fulfilled, (state, action) => {
        const entities = action.payload;

        profileAdapter.removeAll(state.profiles);
        profileAdapter.addMany(state.profiles, entities);

        state.loading = false;
      })
      .addCase(trainingEffects.createProfile.fulfilled, (state, action) => {
        const entity = action.payload;

        profileAdapter.addOne(state.profiles, entity);

        state.loading = false;
      })
      .addCase(trainingEffects.updateProfile.fulfilled, (state, action) => {
        const entity = action.payload;

        profileAdapter.removeOne(state.profiles, entity.id);
        profileAdapter.addOne(state.profiles, entity);

        state.loading = false;
      })
      .addCase(trainingEffects.fetchModifiers.fulfilled, (state, action) => {
        const entities = action.payload;

        modifierAdapter.removeAll(state.modifiers);
        modifierAdapter.addMany(state.modifiers, entities);

        state.loading = false;
      })
      .addCase(trainingEffects.createModifier.fulfilled, (state, action) => {
        const entity = action.payload;

        modifierAdapter.addOne(state.modifiers, entity);

        state.loading = false;
      })
      .addCase(trainingEffects.updateModifier.fulfilled, (state, action) => {
        const entity = action.payload;

        modifierAdapter.removeOne(state.modifiers, entity.id);
        modifierAdapter.addOne(state.modifiers, entity);

        state.loading = false;
      })
      .addCase(trainingEffects.fetchAppliedModifiers.fulfilled, (state, action) => {
        const appliedModifierEntities = action.payload.appliedModifiers;

        appliedModifiersAdapter.removeAll(state.appliedModifiers);
        appliedModifiersAdapter.addMany(state.appliedModifiers, appliedModifierEntities);

        const cowsToApplyToEntities = action.payload.cattleWithMobs;
        cowsToApplyToAdapter.removeAll(state.cowsToApplyTo);
        cowsToApplyToAdapter.addMany(state.cowsToApplyTo, cowsToApplyToEntities);

        state.loading = false;
      })
      .addCase(trainingEffects.fetchAppliedProfiles.fulfilled, (state, action) => {
        const appliedProfileEntities = action.payload.appliedProfiles;

        appliedProfileAdapter.removeAll(state.appliedProfile);
        appliedProfileAdapter.addMany(state.appliedProfile, appliedProfileEntities);

        state.loading = false;
      })
      .addCase(trainingEffects.fetchProfiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(trainingEffects.fetchAppliedModifiers.pending, (state) => {
        appliedModifiersAdapter.removeAll(state.appliedModifiers);
        state.loading = true;
      })
      .addCase(trainingEffects.fetchAppliedProfiles.pending, (state) => {
        appliedProfileAdapter.removeAll(state.appliedProfile);
        state.loading = true;
      })
      .addCase(trainingEffects.fetchFarmerTrainingGuides.fulfilled, (state, action) => {
        const entities = action.payload;
        farmerTrainingGuideAdapter.removeAll(state.farmerTrainingGuides);
        farmerTrainingGuideAdapter.addMany(state.farmerTrainingGuides, entities);
        state.loading = false;
      })
      .addCase(trainingEffects.fetchFarmerTrainingGuideOptions.fulfilled, (state, action) => {
        const entities = action.payload;
        farmerTrainingGuideOptionsAdapter.removeAll(state.farmerTrainingGuideOptions);
        farmerTrainingGuideOptionsAdapter.addOne(state.farmerTrainingGuideOptions, entities);
        state.loading = false;
      }),
});

export default trainingSlice;
