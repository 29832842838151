import { isString } from 'lodash';
import { HistoryDeviceCommandStatusEnum, HistoryEventTypeEnum } from '@halter-corp/timeline-service-client';

export type BffDebugToolUserParameters = {
  metricNames?: string[];
  eventTypes?: HistoryEventTypeEnum[];
  timestamp: [Date, Date];
  farmId: string | undefined;
  cattleNames: string[];
  serialNumbers: string[];
  collarCommandIds: number[];
  firmwareVersions: string[];
  customQuery?: string | null;
  mobIds: string[] | null;
  fetchAllDeviceMetrics?: boolean;
};

export type BffDebugToolEventsOutOfDateRangeParameters = {
  timestamp: [string | Date, string | Date];
  farmId: string | undefined;
  commandIds: string[];
  cattleNames: string[];
  serialNumbers: string[];
};

const getDateString = (value: string | Date) => (isString(value) ? value : value.toISOString());

const combineQueries = (...queries: string[]) =>
  queries
    .filter((query) => query.trim() !== '')
    .map((query) => `(${query})`)
    .join(' AND ');

const generateLatestMetricsQuery = (props: { minutes: number; farmId: string }) => {
  const { minutes, farmId } = props;

  const now = new Date();
  const minutesAgo = new Date(now);
  minutesAgo.setMinutes(minutesAgo.getMinutes() - minutes);

  const predicates = [`context.farmId:"${farmId}"`];
  predicates.push(`timestamp:[${getDateString(minutesAgo)} TO ${getDateString(now)}]`);
  predicates.push('metricName:LAST_COMMAND_STATUS AND NOT metric.lastCommandStatus.commandId:0');
  return predicates.join(' AND ');
};

const generateHistoryEventsByCommandIdQuery = (props: {
  commandId: string;
  statuses?: HistoryDeviceCommandStatusEnum[];
}) => {
  const { commandId, statuses } = props;
  const historyPredicates = [
    `eventType:DeviceCommandEvent AND payload.command.command.collarCommandId:${commandId}`,
  ];

  if (statuses && statuses.length > 0) {
    const statusPredicate = statuses.map((status) => `payload.command.status:"${status}"`).join(' OR ');
    historyPredicates.push(`(${statusPredicate})`);
  }
  return historyPredicates.join(' AND ');
};

const generateDeviceMetricsQuery = (
  props: Omit<
    BffDebugToolUserParameters,
    'eventTypes' | 'customQuery' | 'collarCommandIds' | 'firmwareVersions'
  >
) => {
  const {
    timestamp: [fromDate, toDate],
    farmId,
    cattleNames,
    serialNumbers,
    fetchAllDeviceMetrics,
    mobIds,
    metricNames,
  } = props;

  const datePredicates =
    fromDate && toDate ? [`timestamp:[${getDateString(fromDate)} TO ${getDateString(toDate)}]`] : '';
  const metricPredicates = [`context.farmId:"${farmId}"`];
  if (mobIds != null && mobIds.length > 0)
    metricPredicates.push(`context.mobId:(${mobIds.map((m) => `"${m}"`).join(' OR ')})`);

  if (cattleNames.length > 0) {
    const namesAsQueryParam = cattleNames.join(' OR ');
    metricPredicates.push(`context.cattleName:(${namesAsQueryParam})`);
  } else if (serialNumbers.length > 0) {
    const serialNumberAsParam = serialNumbers.map((serial) => `"${serial}"`).join(' OR ');
    metricPredicates.push(`serialNumber:(${serialNumberAsParam})`);
  }

  const allDeviceMetricsPredicate = fetchAllDeviceMetrics
    ? '(metricName:*)'
    : `(metricName:(${metricNames?.join(' OR ')}))`;

  return [...datePredicates, ...metricPredicates, allDeviceMetricsPredicate].join(' AND ');
};

const generateHistoryEventsQuery = (
  props: Omit<
    BffDebugToolUserParameters,
    'customQuery' | 'fetchAllDeviceMetrics' | 'collarCommandIds' | 'firmwareVersions'
  >
) => {
  const {
    eventTypes,
    timestamp: [fromDate, toDate],
    farmId,
    cattleNames,
    serialNumbers,
    mobIds,
  } = props;
  const fromDateWithPast24Hours = new Date(fromDate);
  const fromDateMidnight = new Date(fromDate);

  fromDateWithPast24Hours.setDate(fromDateWithPast24Hours.getDate() - 1); // include zones from past 24 hours
  fromDateMidnight.setHours(0, 0, 0, 0); // sets time of from date to midnight

  const historyPredicates = [`farmId:"${farmId}"`];
  const datePredicates =
    fromDate && toDate ? [`eventDatetime:[${getDateString(fromDate)} TO ${getDateString(toDate)}]`] : '';

  if (eventTypes?.length) {
    const eventTypesAsQueryParam = eventTypes.join(' OR ');
    historyPredicates.push(`eventType:(${eventTypesAsQueryParam})`);
  }
  if (mobIds != null && mobIds.length > 0) {
    historyPredicates.push(`payload.mob.id:(${mobIds.map((m) => `"${m}"`).join(' OR ')})`);
  } else {
    historyPredicates.push('_exists_:payload.mob.id');
  }
  if (cattleNames.length > 0) {
    const namesAsQueryParam = cattleNames.join(' OR ');
    historyPredicates.push(
      `(payload.cattle.name:(${namesAsQueryParam}) OR (NOT _exists_:payload.cattle.name))`
    );
  } else if (serialNumbers.length > 0) {
    const serialNumberAsParam = serialNumbers.map((serial) => `"${serial}"`).join(' OR ');
    historyPredicates.push(
      `(payload.device.serialNumber:(${serialNumberAsParam}) OR (NOT _exists_:payload.device.serialNumber))`
    );
  }

  return [...datePredicates, ...historyPredicates].join(' AND ');
};

const generateQueryForEventsOutOfDateRange = (props: BffDebugToolEventsOutOfDateRangeParameters) => {
  const {
    farmId,
    commandIds,
    cattleNames,
    serialNumbers,
    timestamp: [fromDate],
  } = props;
  const farmPredicate = `farmId:${farmId} `;
  const datePredicate = fromDate ? `AND eventDatetime:[* TO ${getDateString(fromDate)}]` : '';
  const commandsPredicate = `AND payload.command.collarCommandId:(${commandIds.join(' OR ')})`;
  const cattleNamesPredicate =
    cattleNames.length > 0 ? `AND payload.cattle.name:(${cattleNames.join(' OR ')})` : '';
  const serialNumbersPredicate =
    serialNumbers.length > 0
      ? `AND payload.device.serialNumber:(${serialNumbers.map((serial) => `"${serial}"`).join(' OR ')})`
      : '';
  return `${farmPredicate} ${datePredicate} ${commandsPredicate} ${
    cattleNamesPredicate || serialNumbersPredicate
  }`;
};

const generateQueryForSummaries = (props: BffDebugToolEventsOutOfDateRangeParameters) => {
  const { farmId, commandIds, cattleNames, serialNumbers } = props;
  const farmPredicate = `farmId:${farmId} `;
  const cattleNamesPredicate =
    cattleNames.length > 0 ? `AND context.cattleName:(${cattleNames.join(' OR ')})` : '';
  const zoneSummaryPredicate = `metric.zoneSummary.zoneId:(${commandIds.join(' OR ')})`;
  const transitionSummaryPredicate = `metric.transitionMetrics.commandId:(${commandIds.join(' OR ')})`;
  const serialNumbersPredicate =
    serialNumbers.length > 0
      ? `AND serialNumber:(${serialNumbers.map((serial) => `"${serial}"`).join(' OR ')})`
      : '';
  const summaryPredicate = `AND (${zoneSummaryPredicate} OR ${transitionSummaryPredicate})`;
  return `${farmPredicate} ${summaryPredicate} ${cattleNamesPredicate || serialNumbersPredicate}`;
};

const LuceneQueryService = {
  generateLatestMetricsQuery,
  generateDeviceMetricsQuery,
  generateHistoryEventsByCommandIdQuery,
  generateHistoryEventsQuery,
  generateQueryForEventsOutOfDateRange,
  generateQueryForSummaries,
  combineQueries,
};

export default LuceneQueryService;
