import { Dialog, DialogTitle } from '@material-ui/core';
import Button from 'application/components/button';
import React, { FunctionComponent } from 'react';
import { Tower } from 'store/selectors/tower.selectors';
import styled from 'styled-components';

type DeleteConfirmationDialogProps = {
  open: boolean;
  tower: Tower;
  onClose: () => void;
  handleDelete: (towerId: string) => void;
};

const DialogContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 8px;
`;

const DeleteConfirmationDialog: FunctionComponent<DeleteConfirmationDialogProps> = ({
  open,
  onClose,
  tower,
  handleDelete,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Are you sure you want to delete tower?</DialogTitle>
    <DialogContent>
      <Button
        color="secondary"
        text="Delete"
        backgroundColour="#d50000"
        onClick={() => {
          onClose();
          handleDelete(tower.id);
        }}
      />
      <Button color="primary" text="Cancel" onClick={onClose} />
    </DialogContent>
  </Dialog>
);

export default DeleteConfirmationDialog;
