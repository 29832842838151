import { AxiosError } from 'axios';
import { IFarmDTO } from '@halter-corp/business-service-client';
import { useSelector } from 'react-redux';
import { selectFarms, getCurrentFarm } from 'store/selectors/farm-context.selectors';
import HttpApiService from './http-api.service';

const BusinessService = {
  useFetchFarmList: () => useSelector(selectFarms),

  fetchFarmById: async (farmId: string): Promise<IFarmDTO | undefined> => {
    const api = await HttpApiService.getFarmApi();

    try {
      const { data } = await api.findbyId(farmId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },

  fetchAllFarms: async (): Promise<IFarmDTO[] | undefined> => {
    const api = await HttpApiService.getFarmApi();

    try {
      const { data } = await api.findAll();
      return data;
    } catch (err) {
      const axiosError: AxiosError = err;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },

  useSelectCurrentFarm: () => useSelector(getCurrentFarm),
};

export default BusinessService;
