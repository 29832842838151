import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import store from 'store';
import deviceEffects from 'store/effects/device.effects';
import {
  listDevicesWithOverviewInfo,
  getSelectedDevice,
  isLoading,
  isProvisioning,
} from 'store/selectors/device.selectors';
import { IDeviceDTO, IDeviceStatsDTO, IDeviceStatusDTO } from '@halter-corp/device-service-client';
import { ICattleDeviceSettingsDTO, IEngineeringSettingsDTO } from '@halter-corp/settings-service-client';
import { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import { getDevices } from 'store/selectors/devices.selectors';
import {
  BuiltInTestStatusDTO,
  CowgEventDTO,
  DeviceStateReportDTO,
  GPSPointDTO,
  LastCommandStatusDTO,
  PowerMetricsDTO,
  PowerUsageMetricsDTO,
} from '@halter-corp/halter-common-protobuf';
import HttpApiService from './http-api.service';
import {
  BuiltInTestStatus,
  DeviceLocation,
  DeviceStateReport,
  DeviceStats,
  LastCommandStatus,
  LoraCheckinStatus,
  PowerGeneration,
  PowerUsage,
  WifiCheckinStatus,
} from '../data/device';
import { toDate } from '../application/utils';

interface DeviceStatsLocationDTO extends GPSPointDTO {
  lastUpdate?: string;
}

interface DeviceStatsBuiltInTestDTO extends BuiltInTestStatusDTO {
  lastUpdate?: string;
}

interface DeviceStatsPowerGenerationDTO extends PowerMetricsDTO {
  lastUpdate: string;
}

interface DeviceStatsLastCommandStatusDTO extends LastCommandStatusDTO {
  lastUpdate: string;
}

interface DeviceStatsLoraCheckinStatusDTO {
  gateway?: {
    rssi?: number;
    snr?: number;
    time?: string;
  };
  lastUpdate: string;
}

interface DeviceStatsPowerUsageDTO extends PowerUsageMetricsDTO {
  lastUpdate: string;
}

interface DeviceStatsWifiCheckinStatusDTO {
  bssid?: string;
  rssi?: number;
  channel?: number;
  batteryMv?: number;
  latitude?: number;
  longitude?: number;
  firmwareVersion?: string;
  bootLoaderVersion?: string;
  gpsFirmwareVersion?: string;
  lastUpdate: string;
}

interface DeviceStatsCowgEventStreamDTO extends CowgEventDTO {
  lastUpdate: string;
}

export interface DeviceStatsStateReportDTO extends DeviceStateReportDTO {
  lastUpdate?: string;
}

export interface DeviceStatsDTO {
  deviceLocation?: DeviceStatsLocationDTO;
  builtInTestStatus?: DeviceStatsBuiltInTestDTO;
  powerGeneration?: DeviceStatsPowerGenerationDTO;
  lastCommandStatus?: DeviceStatsLastCommandStatusDTO;
  loraCheckinStatus?: DeviceStatsLoraCheckinStatusDTO;
  powerUsage?: DeviceStatsPowerUsageDTO;
  wifiCheckinStatus?: DeviceStatsWifiCheckinStatusDTO;
  cowgEventStream?: DeviceStatsCowgEventStreamDTO;
  deviceStateReport?: DeviceStatsStateReportDTO;
}

export const buildDeviceStatsFromApiResult = (raw: IDeviceStatsDTO): DeviceStats => {
  const dto = raw as DeviceStatsDTO;
  let powerGeneration: PowerGeneration | undefined;
  let batteryVoltage: number | undefined;
  let batteryPercentage: number | undefined;
  const now = new Date();

  if (dto.powerGeneration) {
    powerGeneration = {
      ...dto.powerGeneration,
      timestamp: toDate(dto.powerGeneration.timestamp),
      lastUpdate: toDate(dto.powerGeneration.lastUpdate),
      batteryVoltageMv: dto.powerGeneration.batteryMv,
      batterySocPercent: dto.powerGeneration.batterySocPercent,
    };
    batteryVoltage = powerGeneration?.batteryVoltageMv;
    batteryPercentage = powerGeneration?.batterySocPercent;
  }

  let lastCommandStatus: LastCommandStatus | undefined;
  if (dto.lastCommandStatus) {
    lastCommandStatus = {
      ...dto.lastCommandStatus,
      lastUpdate: toDate(dto.lastCommandStatus.lastUpdate),
    };
  }

  let powerUsage: PowerUsage | undefined;
  if (dto.powerUsage) {
    powerUsage = {
      ...dto.powerUsage,
      lastUpdate: toDate(dto.powerUsage.lastUpdate),
    };
  }

  let deviceLocation: DeviceLocation | undefined;
  if (dto.deviceLocation) {
    deviceLocation = {
      fixType: dto.deviceLocation.fixType,
      latitude: dto.deviceLocation.latitude,
      longitude: dto.deviceLocation.longitude,
      numSatellites: dto.deviceLocation.numSatellites,
      fixAge: dto.deviceLocation.fixAge,
      timestamp: toDate(dto.deviceLocation.timestamp),
      lastUpdate: toDate(dto.deviceLocation.lastUpdate),
      satelliteCnrDbHz: dto.deviceLocation.satelliteCnrDbHz,
    };
  }

  let builtInTestStatus: BuiltInTestStatus | undefined;
  if (dto.builtInTestStatus) {
    builtInTestStatus = {
      ...dto.builtInTestStatus,
    };
  }

  let wifiCheckinStatus: WifiCheckinStatus | undefined;
  if (dto.wifiCheckinStatus) {
    wifiCheckinStatus = {
      ...dto.wifiCheckinStatus,
      lastUpdate: toDate(dto.wifiCheckinStatus.lastUpdate),
      lastRetrieval: now,
    };
  }

  let deviceStateReport: DeviceStateReport | undefined;
  if (dto.deviceStateReport) {
    deviceStateReport = {
      ...dto.deviceStateReport,
      lastUpdate: toDate(dto.deviceStateReport.lastUpdate),
    };
  }

  let loraCheckinStatus: LoraCheckinStatus | undefined;
  if (dto.loraCheckinStatus) {
    loraCheckinStatus = {
      rssi: dto.loraCheckinStatus?.gateway?.rssi,
      lastUpdate: toDate(dto.loraCheckinStatus.lastUpdate),
      lastRetrieval: now,
    };
  }

  return {
    batteryVoltage,
    batteryPercentage,
    powerGeneration,
    lastCommandStatus,
    loraCheckinStatus,
    builtInTestStatus,
    powerUsage,
    deviceLocation,
    wifiCheckinStatus,
    deviceStateReport,
  };
};

const DeviceService = {
  useIsOverviewLoading: () => useSelector(isLoading),

  useIsProvisioningLoading: () => useSelector(isProvisioning),

  useSelectOverview: () => useSelector(listDevicesWithOverviewInfo),

  useSelectDevice: () => useSelector(getSelectedDevice),

  useSelectDevices: () => useSelector(getDevices),

  preProvisionDevicesToFarm: async (
    devices: Pick<IDeviceDTO, 'serialNumber' | 'version'>[],
    farmId: string
  ): Promise<void> =>
    unwrapResult(await store.dispatch(deviceEffects.preProvisionDevicesToFarm({ devices, farmId }))),

  removeFarmPreProvisioningFromDevices: async (
    devices: Pick<IDeviceDTO, 'serialNumber' | 'version'>[]
  ): Promise<void> =>
    unwrapResult(await store.dispatch(deviceEffects.removeFarmPreProvisioningFromDevices({ devices }))),

  updateDevice: async (devices: Pick<IDeviceDTO, 'serialNumber'>[]): Promise<void> =>
    unwrapResult(await store.dispatch(deviceEffects.updateDevice({ devices }))),

  fetchSettingsV2: async (farmId: string, serialNumbers: string[]): Promise<ICattleDeviceSettingsDTO[]> => {
    if (farmId == null) return [];
    const api = await HttpApiService.getSettingsApi(farmId);
    if (isEmpty(serialNumbers)) return [];
    const { data: cattleDeviceSettings } = await api.getSettings(serialNumbers);
    return cattleDeviceSettings;
  },

  getEngineeringSettings: async (serialNumber: string, farmId?: string): Promise<IEngineeringSettingsDTO> => {
    const api = await HttpApiService.getSettingsApi(farmId);

    const { data: engineeringSettings } = await api.getDeviceEngineeringSettings(serialNumber);
    return engineeringSettings;
  },

  updateEngineeringSettings: async (
    serialNumber: string,
    settings: IEngineeringSettingsDTO,
    farmId?: string
  ) => {
    const api = await HttpApiService.getSettingsApi(farmId);

    await api.updateDeviceEngineeringSettings(serialNumber, settings);
  },

  setDeviceStatus: async (
    serialNumber: string,
    deviceStatusUpdate: IDeviceStatusDTO
  ): Promise<IDeviceStatusDTO | undefined> => {
    try {
      const deviceStatusApi = await HttpApiService.getDeviceStatusApi();

      const { data } = await deviceStatusApi.updateStatus(serialNumber, deviceStatusUpdate);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },

  getDeviceStats: async (serialNumber: string): Promise<DeviceStats | undefined> => {
    try {
      const api = await HttpApiService.getDeviceStatsApi();
      const { data } = await api.findStats(serialNumber);
      return buildDeviceStatsFromApiResult(data);
    } catch (err) {
      const axiosError: AxiosError = err;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },
};

export default DeviceService;
