import React, { FunctionComponent } from 'react';
import moment from 'moment';

// @ts-ignore
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { TextField } from '@material-ui/core';
import { DEFAULT_DATE_TIME_FORMAT } from '../modules/debug-cattle/utils/constants';

export type DateRangePickerProps = {
  fromDate: string | undefined;
  toDate: string | undefined;
  setToDate: (toDate: string) => void;
  setFromDate: (fromDate: string) => void;
  leftMode?: boolean;
  noMobileMode?: boolean;
};

const datePickerStyle = {
  standaloneLayout: {
    display: 'flex',
    maxWidth: 'fit-content',
    width: '100%',
    color: 'blue',
  },
};

const DateRangePicker: FunctionComponent<DateRangePickerProps> = ({
  fromDate,
  toDate,
  setToDate,
  setFromDate,
  leftMode,
  noMobileMode,
}) => {
  const now = Date.now();
  const ranges = {
    '30 minutes': [moment(now).subtract(30, 'minutes'), moment(now)],
    '1 Hours': [moment(now).subtract(1, 'hours'), moment(now)],
    '2 Hours': [moment(now).subtract(2, 'hours'), moment(now)],
    '4 Hours': [moment(now).subtract(4, 'hours'), moment(now)],
    '6 Hours': [moment(now).subtract(6, 'hours'), moment(now)],
    '12 Hours': [moment(now).subtract(12, 'hours'), moment(now)],
    '24 Hours': [moment(now).subtract(24, 'hours'), moment(now)],
    'Today Only': [moment(now).startOf('day'), moment(now)],
    'Yesterday Only': [
      moment(now).startOf('day').subtract(1, 'days'),
      moment(now).endOf('day').subtract(1, 'days'),
    ],
  };
  const displayFormat = DEFAULT_DATE_TIME_FORMAT;
  const local = {
    format: displayFormat,
    sundayFirst: false,
  };
  const maxDate = moment();

  return (
    <DateTimeRangeContainer
      ranges={ranges}
      start={moment(fromDate)}
      end={moment(toDate)}
      local={local}
      maxDate={maxDate}
      applyCallback={(startDate: Date, endDate: Date) => {
        setFromDate(moment.isMoment(startDate) ? startDate.toDate().toISOString() : startDate.toISOString());
        setToDate(moment.isMoment(endDate) ? endDate.toDate().toISOString() : endDate.toISOString());
      }}
      style={datePickerStyle}
      smartMode
      autoApply
      leftMode={leftMode}
      noMobileMode={noMobileMode}
    >
      <TextField
        label="Date range"
        value={`${moment(fromDate).format(displayFormat)} - ${moment(toDate).format(displayFormat)}`}
        style={{ flex: 1, width: '100%', color: 'red' }}
      />
    </DateTimeRangeContainer>
  );
};

export default DateRangePicker;
