import React from 'react';
import { FormControl, InputLabel, Select as BaseSelect, SelectProps, MenuItem } from '@material-ui/core';

type SelectFieldProps<T, V> = {
  label?: string;
  options: T[];
  value: V;
  valueExtractor: (entity: T) => string | number | undefined;
  displayNameExtractor?: (entity: T) => string | number | undefined;
  onChange: (value: V) => void;
  hasError?: boolean;
  noValue?: V;
  withMargin?: boolean;
  variant?: SelectProps['variant'];
};

function SelectField<T, V = string>({
  label,
  options,
  value,
  valueExtractor,
  displayNameExtractor,
  onChange,
  noValue,
  hasError = false,
  withMargin = true,
  variant,
}: SelectFieldProps<T, V>) {
  return (
    <FormControl style={{ display: 'flex', flex: 1, margin: withMargin ? 8 : 0 }} variant={variant}>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <BaseSelect
        displayEmpty
        value={value}
        onChange={(e) => onChange((e as React.ChangeEvent<{ value: V }>).target.value)}
        error={hasError}
        style={value == null || value === noValue ? { color: '#c5c5c5' } : {}}
        variant={variant}
      >
        {options.map((option, index) => (
          <MenuItem
            key={valueExtractor(option) ?? `index-${index}`}
            value={valueExtractor(option)}
            style={{ height: 36 }}
          >
            {displayNameExtractor != null ? displayNameExtractor(option) : valueExtractor(option)}
          </MenuItem>
        ))}
      </BaseSelect>
    </FormControl>
  );
}

export default SelectField;
