import moment from 'moment';

const SEPARATOR = /;|,|\s+/;
export const splitNumberList = (s: string): string[] => s.trim().split(SEPARATOR);

export const splitFieldValues = (fieldValue: string | null) => {
  if (fieldValue == null) return [];
  return fieldValue
    .split(',')
    .map((s) => s.trim())
    .filter((name) => name !== '');
};

export const isHalterEmail = (email: string) =>
  /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(halter\.co\.nz|halter\.io)$/.test(email);

export const startAndEndOfDates = (date: Date): [Date, Date] => {
  const utcDate = moment.utc(date);
  return [utcDate.startOf('day').toDate(), utcDate.endOf('day').toDate()];
};

export const replaceWhitespaces = (value: string, replacer: string = '-'): string =>
  value.replace(/\s+/g, replacer);

export const timestampToDate = (timestamp?: number): Date | undefined => {
  if (timestamp == null) {
    return undefined;
  }
  if (timestamp! > 4099680000) {
    // FIXME I'm sure this a proper way to do this, but this just checks for a timestamp > 2100-01-01 and assumes it's in ms, otherwise assumes it's in seconds
    return new Date(timestamp!);
  }
  return new Date(timestamp! * 1000);
};

export const toDate = (value?: any): Date | undefined => (value != null ? new Date(value) : undefined);
