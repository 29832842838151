import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FarmSelect from 'application/components/farm-select';

import { CaddieColours } from 'application/theme/colours';
import { Divider, Grid } from '@material-ui/core';
import Wrapper from 'application/components/wrapper';
import Tab from 'application/modules/towers/components/tab';
import BehaviourService from 'services/behaviour.service';
import HeatPanel from '../panels/heat.panel';
import HealthPanel from '../panels/health.panel';
import CowPanel from '../panels/cow.panel';
import CalvingPanel from '../panels/calving.panel';
import CSVImporter from '../components/csv-importer';
import InterventionsPanel from '../panels/interventions.panel';

enum SearchModes {
  MATING = 'Mating',
  CALVING = 'Calving',
  HEALTH = 'Health',
  INTERVENTIONS = 'Interventions',
  COW = 'Cow',
  UPLOAD = 'Upload',
}
const tabStyle: React.CSSProperties = { textTransform: 'none', fontSize: '24px', width: '100%' };

const BehaviourScreen: FunctionComponent = () => {
  const history = useHistory();
  const { behaviourSearchMode } = localStorage;
  const behaviourSearchModeFromBrowser: SearchModes =
    behaviourSearchMode === undefined ? SearchModes.CALVING : JSON.parse(behaviourSearchMode);
  const [searchMode, setSearchMode] = useState<SearchModes>(behaviourSearchModeFromBrowser);
  const season = BehaviourService.useSelectSeason();

  const handleFarmChanged = (newFarmId: string) => {
    const location = { search: new URLSearchParams({ farmId: newFarmId }).toString() };
    history.push(location);
  };

  useEffect(() => {
    localStorage.setItem('behaviourSearchMode', JSON.stringify(searchMode));
  }, [searchMode]);

  return (
    <Wrapper
      style={{ backgroundColor: CaddieColours.LightGrey, minHeight: 'calc(100vh - 44px)', padding: '16px' }}
    >
      <Grid container spacing={2}>
        <Grid container justifyContent="space-around">
          {Object.values(SearchModes).map((mode) => (
            <Grid item lg={2} key={mode}>
              <Tab
                text={mode}
                selected={searchMode === mode}
                onPress={() => {
                  setSearchMode(mode);
                }}
                styleProps={tabStyle}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} sm={12} lg={6} style={{ display: 'flex' }}>
          <FarmSelect onChange={handleFarmChanged} />
        </Grid>
        <Divider />
        {searchMode === SearchModes.MATING && <HeatPanel season={season} />}
        {searchMode === SearchModes.CALVING && <CalvingPanel />}
        {searchMode === SearchModes.HEALTH && <HealthPanel />}
        {searchMode === SearchModes.INTERVENTIONS && <InterventionsPanel />}
        {searchMode === SearchModes.COW && <CowPanel />}
        {searchMode === SearchModes.UPLOAD && <CSVImporter />}
      </Grid>
    </Wrapper>
  );
};

export default BehaviourScreen;
