import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpApiService from 'services/http-api.service';

const farmEffects = {
  fetch: createAsyncThunk('farms/fetch', async () => {
    const farmApi = await HttpApiService.getFarmApi();
    const { data: farms } = await farmApi.findAll();

    return farms;
  }),
};

export default farmEffects;
