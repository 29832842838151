import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import SelectSettingsScreen from './screens/settings-select-settings.screen';

const SettingsModule: FunctionComponent = () => (
  <Switch>
    <Route exact path="/settings">
      <SelectSettingsScreen />
    </Route>
  </Switch>
);

export default SettingsModule;
