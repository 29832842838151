import { CattleApi, MobApi } from '@halter-corp/cattle-service-client';

import { WithMob, Cattle } from 'data/cattle/cattle';
import HttpApiClient from '../http-api.client';

const constructApis = async (farmId: string) =>
  Promise.all([
    HttpApiClient.constructOpenApi(CattleApi, farmId),
    HttpApiClient.constructOpenApi(MobApi, farmId),
  ]);

const cattleApiService = {
  fetchWithMob: async (props: {
    farmId: string;
    mobId?: string;
    cattleNames?: string[];
  }): Promise<WithMob<Cattle>[]> => {
    const { farmId, mobId, cattleNames } = props;
    const [cattleApi, mobApi] = await constructApis(farmId);

    const filterChunks: string[] = [];
    if (mobId != null) filterChunks.push(`mobId:equal:${mobId}`);
    if (cattleNames != null) {
      if (cattleNames.length === 0) return [];
      filterChunks.push(`name:in:${cattleNames.join(',')}`);
    }
    const filter = filterChunks.length === 0 ? undefined : filterChunks.join(';');

    const { data: cattle } = await cattleApi.findAllCattle(filter);
    const { data: mobs } = await mobApi.findAll();

    return cattle.map((cow) => ({
      ...cow,
      mob: mobs.find((mob) => cow.mobId === mob.id),
    }));
  },
};

export default cattleApiService;
