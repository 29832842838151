import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { ICreateTowerNoteRequestDTO } from '@halter-corp/tower-service-client';
import towerEffects from 'store/effects/tower.effects';
import { Tower } from 'store/selectors/tower.selectors';
import NoteFormDialog from './note-form.dialog';

type CreateNoteDialogProps = {
  open: boolean;
  tower: Tower;
  onClose: () => void;
};

const CreateNoteDialog: FunctionComponent<CreateNoteDialogProps> = ({ open, onClose, tower }) => {
  const dispatch = useDispatch();

  const handleNoteSave = (createTowerNoteRequest: ICreateTowerNoteRequestDTO) => {
    if (!tower) return;
    dispatch(
      towerEffects.saveTowerNote({
        createTowerNoteRequest,
        towerId: tower.id,
      })
    );

    onClose();
  };

  return <NoteFormDialog open={open} onClose={onClose} tower={tower} onSave={handleNoteSave} />;
};

export default CreateNoteDialog;
