import React, { FunctionComponent } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import styled from 'styled-components';

export type EarTagFilterProps = {
  selectedCows: string[];
  allAvailableCows: string[];
  onSelectedCowsChange: (selectedCows: string[]) => void;
};

const CowsFilterWrapper = styled.div`
  max-height: 480px;
  overflow: scroll;
`;

const EarTagFilter: FunctionComponent<EarTagFilterProps> = ({
  allAvailableCows,
  selectedCows,
  onSelectedCowsChange,
}) => {
  const handleCowCheckedChange = (cattleName: string, checked: boolean) => {
    const newSelectedCows = checked
      ? [...selectedCows, cattleName]
      : selectedCows.filter((selected) => selected !== cattleName);
    onSelectedCowsChange(newSelectedCows);
  };

  if (allAvailableCows == null) return null;

  return (
    <CowsFilterWrapper>
      <Typography color="textSecondary" gutterBottom>
        {`Cow Ear Tags (${allAvailableCows.length} cow${allAvailableCows.length > 1 ? 's' : ''})`}
      </Typography>
      <FormControl component="fieldset">
        <FormGroup row style={{ width: '100%' }}>
          {allAvailableCows.map((cattleName) => (
            <FormControlLabel
              key={`${cattleName}`}
              control={
                <Checkbox
                  checked={selectedCows.some((d) => d === cattleName)}
                  onChange={(e, checked) => handleCowCheckedChange(cattleName, checked)}
                />
              }
              label={cattleName}
            />
          ))}
        </FormGroup>
      </FormControl>
    </CowsFilterWrapper>
  );
};

export default EarTagFilter;
