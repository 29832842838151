import React, { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as h3 from 'h3-js';
import { GeoJSON, Tooltip } from 'react-leaflet';
import { selectLoraCoverageHeatMapCellsMap } from 'store/selectors/tower.selectors';
import { ILoraCoverageHeatMapCellDTO } from '@halter-corp/tower-service-client';

type LoraCoverageHeatMapsLayerProps = { gatewayIdsForHeatMap: Set<string> };

const getMaxRssiForEachGeoIndex = (
  gatewayIdList: string[],
  gatewayHeatMapCellsMap: Map<string, ILoraCoverageHeatMapCellDTO[]>
): Map<string, number> => {
  const geoIndexToMaxRssiMap: Map<string, number> = new Map();

  gatewayIdList.forEach((gatewayId) => {
    const heatMapCells = gatewayHeatMapCellsMap.get(gatewayId);
    if (heatMapCells == null) return;

    heatMapCells.forEach((heatMapCell) => {
      const maxRssi = Math.max(geoIndexToMaxRssiMap.get(heatMapCell.geoIndex) ?? -Infinity, heatMapCell.rssi);
      geoIndexToMaxRssiMap.set(heatMapCell.geoIndex, maxRssi);
    });
  });

  return geoIndexToMaxRssiMap;
};

const getRssiOpacity = (rssi: number): number => (rssi > -105 ? 0.6 : 0.3);
const getRssiColor = (rssi: number): string => (rssi > -110 ? '#ff9800' : '#FF0000');

const LoraCoverageHeatMapsLayer: FunctionComponent<LoraCoverageHeatMapsLayerProps> = ({
  gatewayIdsForHeatMap,
}) => {
  const gatewayHeatMapCellsMap = useSelector(selectLoraCoverageHeatMapCellsMap);

  const gatewayIdList = useMemo(() => Array.from(gatewayIdsForHeatMap), [gatewayIdsForHeatMap]);

  const geoIndexToMaxRssiMap: Map<string, number> = useMemo(
    () => getMaxRssiForEachGeoIndex(gatewayIdList, gatewayHeatMapCellsMap),
    [gatewayIdList, gatewayHeatMapCellsMap]
  );

  return (
    <div key={`${gatewayIdList.length}-heat-maps`}>
      {Array.from(geoIndexToMaxRssiMap).map(([geoIndex, rssi]) => {
        const coordinates = h3.cellToBoundary(geoIndex);

        const data = {
          id: `cell-${geoIndex}`,
          geometry: {
            coordinates: [coordinates.map((coord) => [coord[1], coord[0]])],
            type: 'Polygon',
          },
          type: 'Feature' as 'Feature',
        };

        return (
          <GeoJSON
            key={data.id}
            data={data}
            style={{
              fillOpacity: getRssiOpacity(rssi),
              fillColor: getRssiColor(rssi),
              color: 'white',
              weight: 0,
            }}
          >
            <Tooltip direction="top"> {rssi} </Tooltip>
          </GeoJSON>
        );
      })}
    </div>
  );
};

export default LoraCoverageHeatMapsLayer;
