import React, { FunctionComponent } from 'react';
import ReactGA from 'react-ga';
import { useFormik } from 'formik';
import { IEngineeringSettingsDTO } from '@halter-corp/settings-service-client';
import { Button } from '@material-ui/core';

import Checkbox from 'application/components/checkbox';

import { DeepRequired } from '../types';
import NumberInput from './number-input';

type SettingsFormSectionProps = {
  serialNumber: string;
  data: DeepRequired<IEngineeringSettingsDTO>;
  onClickSave: (serialNumber: string, data: DeepRequired<IEngineeringSettingsDTO>) => Promise<void>;
};

const SettingsFormSection: FunctionComponent<SettingsFormSectionProps> = ({
  serialNumber,
  data,
  onClickSave,
}) => {
  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async () => {
      ReactGA.event({
        category: 'Engineering Settings',
        action: 'Edit',
      });
      onClickSave(serialNumber, values);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <h5>Serial Number {serialNumber} has been loaded</h5>
      <h4>Data Collar Settings</h4>
      <Checkbox
        onChange={handleChange}
        name="dataCollar.enable"
        checked={values.dataCollar.enable}
        label="dataCollar"
      />

      <h4>GPS Settings</h4>
      <NumberInput
        onChange={handleChange}
        name="gps.maxOffTimeMin"
        value={values.gps.maxOffTimeMin}
        label="maxOffTimeMin"
        id="maxOffTimeMin"
      />
      <NumberInput
        onChange={handleChange}
        name="gps.constellationSelect"
        value={values.gps.constellationSelect}
        label="constellationSelect"
        id="constellationSelect"
      />
      <NumberInput
        onChange={handleChange}
        name="gps.minOnTimeSec"
        value={values.gps.minOnTimeSec}
        label="minOnTimeSec"
        id="minOnTimeSec"
      />

      <h4>Location Diagnostic Settings</h4>
      <Checkbox
        onChange={handleChange}
        name="locationDiagnostics.enable"
        checked={values.locationDiagnostics.enable}
        label="locationDiagnostics"
      />
      <NumberInput
        onChange={handleChange}
        name="locationDiagnostics.logPeriodMs"
        value={values.locationDiagnostics.logPeriodMs}
        label="logPeriodMs"
        id="logPeriodMs"
      />

      <h4>ODBA Settings</h4>
      <NumberInput
        onChange={handleChange}
        name="odba.wakeHoldOff"
        value={values.odba.wakeHoldOff}
        label="wakeHoldOff"
        id="wakeHoldOff"
      />
      <NumberInput
        onChange={handleChange}
        name="odba.stopHoldOff"
        value={values.odba.stopHoldOff}
        label="stopHoldOff"
        id="stopHoldOff"
      />
      <NumberInput
        onChange={handleChange}
        name="odba.wakeOdba"
        value={values.odba.wakeOdba}
        label="wakeOdba"
        id="wakeOdba"
      />
      <NumberInput
        onChange={handleChange}
        name="odba.stopOdba"
        value={values.odba.stopOdba}
        label="stopOdba"
        id="stopOdba"
      />

      <h4>Power Saver Settings</h4>
      <Checkbox
        onChange={handleChange}
        name="powerSaver.forceOn"
        checked={values.powerSaver.forceOn}
        label="forceOn"
      />
      <NumberInput
        onChange={handleChange}
        name="powerSaver.onThresholdBatteryVoltageMv"
        value={values.powerSaver.onThresholdBatteryVoltageMv}
        label="onThresholdBatteryVoltageMv"
        id="onThresholdBatteryVoltageMv"
      />

      <h4>Positioning Settings</h4>
      <NumberInput
        onChange={handleChange}
        name="positioning.maximumInaccuracyM"
        value={values.positioning.maximumInaccuracyM}
        label="Maximum location inaccuracy(m)"
        id="maximumInaccuracyM"
      />

      <h4>StatusUI Settings</h4>
      <Checkbox onChange={handleChange} name="statusUi.cowg" checked={values.statusUi.cowg} label="cowg" />
      <Checkbox onChange={handleChange} name="statusUi.gps" checked={values.statusUi.gps} label="gps" />
      <Checkbox
        onChange={handleChange}
        name="statusUi.movement"
        checked={values.statusUi.movement}
        label="movement"
      />
      <Checkbox onChange={handleChange} name="statusUi.bit" checked={values.statusUi.bit} label="bit" />
      <Checkbox
        onChange={handleChange}
        name="statusUi.heading"
        checked={values.statusUi.heading}
        label="heading"
      />

      <h4>Test Mode Settings</h4>
      <Checkbox
        onChange={handleChange}
        name="testModes.gpsPowerControlDisable"
        checked={values.testModes.gpsPowerControlDisable}
        label="gpsPowerControlDisable"
      />
      <Checkbox
        onChange={handleChange}
        name="testModes.powerDrainModeEnable"
        checked={values.testModes.powerDrainModeEnable}
        label="powerDrainModeEnable"
      />
      <Checkbox
        onChange={handleChange}
        name="testModes.lowPowerSleepDisable"
        checked={values.testModes.lowPowerSleepDisable}
        label="lowPowerSleepDisable"
      />
      <Checkbox
        onChange={handleChange}
        name="testModes.soakModeEnable"
        checked={values.testModes.soakModeEnable}
        label="soakModeEnable"
      />
      <br />

      <Button variant="contained" size="large" color="primary" style={{ margin: '20px 0 0 0' }} type="submit">
        Save Settings
      </Button>
    </form>
  );
};

export default SettingsFormSection;
