export enum CaddieColours {
  LightGrey = '#F2F3F5',
  DarkGrey = '#E8EAED',
  AccentGrey = '#E0E0E0',
  ActiveGreen = '#047B61',
  ActiveGreenConfirmation = '#8FDFCE',
  MaintenanceYellow = '#FCB017',
  WorriedOrange = '#fa9702',
  EmergencyRed = '#D82833',
  EmergencyRedConfirmation = '#FCDCDD',
}
