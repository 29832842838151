import React, { FunctionComponent, useMemo, useState } from 'react';
import Wrapper from 'application/components/wrapper';
import {
  LinearProgress,
  Grid,
  Box,
  Typography,
  Divider,
  IconButton,
  Switch,
  Tab,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import BusinessService from 'services/business.service';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DeviceService from 'services/device.service';
import { orderBy } from 'lodash';
import { TabContext, TabList } from '@material-ui/lab';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import FleetService from 'services/fleet.service';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import FarmIssuesTable from '../tables/farm-issues.table';
import RequestSwapModal from '../components/request-swap.modal';
import {
  Column,
  Row,
  FarmStatusMode,
  getOnFarmStatusCountColour,
  formatEnumValue,
  formatFarmName,
} from '../utils';
import { CaddieColours } from '../../../theme/colours';
import { FleetFarmState } from '../containers/fleet-view.container';
import FarmIssueSidePanel from '../components/farm-issue-side.panel';
import DeprovisionedDevicesTable from '../tables/deprovisioned-devices.table';

const useStyles = makeStyles(() =>
  createStyles({
    tabList: {
      '& .MuiTabs-flexContainer': {
        overflow: 'scroll',
      },
    },
  })
);

type FarmIssuesScreenProps = {
  farmStateData: FleetFarmState[];
  user: string;
  mode: DeviceOnFarmStatusEnum;
  setMode: (mode: DeviceOnFarmStatusEnum) => void;
};

const FarmIssuesScreen: FunctionComponent<FarmIssuesScreenProps> = ({
  farmStateData,
  user,
  mode,
  setMode,
}) => {
  const classes = useStyles();
  const currentFarm = useSelector(getCurrentFarm);
  const history = useHistory();
  const farms = BusinessService.useFetchFarmList();
  const farmName = formatFarmName(farms.find(({ id }) => id === currentFarm));

  const loading = FleetService.useIsDeviceOnFarmStatusCountsByFarmIdLoading();
  const loadingFailed = FleetService.useHasDeviceOnFarmStatusCountsByFarmIdFailed();

  const devices = DeviceService.useSelectDevices();
  /* Use deprovisioned devices from fleet-service as device-service does not retrieve deprovisioned devices */
  const deprovisiondDevicesByFarmId = FleetService.useFetchDeprovisionedDeviceOnFarmStatesByFarmId();
  const currentFarmDeprovisionedDevices = deprovisiondDevicesByFarmId[currentFarm];

  const [includeNotLiveFarms, setIncludeNotLiveFarms] = useState<boolean>(
    window.localStorage.getItem('includeNotLiveFarms') === 'true' ?? false
  );

  const [includeInternalFarms, setIncludeInternalFarms] = useState<boolean>(
    window.localStorage.getItem('includeInternalFarms') === 'true' ?? false
  );

  const orderedDevices = useMemo(() => orderBy(devices, 'cattleName'), [devices]);

  const locateCollars = () => {
    const serialNumberString = `'${devices.map((device) => device.serialNumber).join("','")}'`;
    window.open(
      `https://halter.grafana.net/d/slXcWM4Vz/locate-collars?orgId=1&var-serialNumbers=${serialNumberString}`,
      '_blank'
    );
  };

  const toggleIncludeNotLiveFarms = () => {
    setIncludeNotLiveFarms(!includeNotLiveFarms);
    window.localStorage.setItem('includeNotLiveFarms', JSON.stringify(!includeNotLiveFarms));
  };

  const toggleIncludeInternalFarms = () => {
    setIncludeInternalFarms(!includeInternalFarms);
    window.localStorage.setItem('includeInternalFarms', JSON.stringify(!includeInternalFarms));
  };

  const currentFarmState = useMemo(
    () => farmStateData.find((item) => item.farmId === currentFarm),
    [farmStateData, currentFarm]
  );

  const farmSummary = useMemo(
    () =>
      farmStateData
        .filter((item) => includeNotLiveFarms || item.status === FarmStatusMode.NotLive)
        .filter((item) => includeInternalFarms || !item.farmName.includes('Internal'))
        .map((item) => ({
          farmId: item.farmId,
          farmName: item.farmName,
          count: item.collarCountByStatusMap.get(mode) ?? 0,
          mode,
        })),
    [farmStateData, includeNotLiveFarms, includeInternalFarms, mode]
  );

  return (
    <Wrapper style={{ backgroundColor: CaddieColours.LightGrey, minHeight: 'calc(100vh - 44px)' }}>
      <LinearProgress
        variant={loading ? 'query' : 'determinate'}
        value={100}
        style={{ position: 'sticky' }}
      />
      <Row>
        <FarmIssueSidePanel farmSummary={farmSummary} />
        <TabContext value={mode}>
          <Column style={{ maxWidth: '80%', maxHeight: 'calc(100vh - 44px)' }}>
            <Box m={3}>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={{ flexGrow: 1 }}>
                  <Grid container alignItems="center">
                    <IconButton onClick={() => history.push({ pathname: `/fleet-watch` })}>
                      <ChevronLeftIcon style={{ color: 'black' }} />
                    </IconButton>
                    <Typography variant="h6">{farmName}</Typography>
                    {orderedDevices && (
                      <Typography
                        variant="h6"
                        style={{
                          color: getOnFarmStatusCountColour(orderedDevices.length, mode),
                          marginLeft: '16px',
                        }}
                      >
                        {loadingFailed && 'Failed to load'}
                        {loading ? '' : orderedDevices.length}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item style={{ paddingRight: 0 }}>
                  <Typography variant="subtitle2">Locate Collars:</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={locateCollars} style={{ color: 'black' }}>
                    <MyLocationIcon />
                  </IconButton>
                </Grid>
                <Grid item style={{ paddingRight: 0 }}>
                  <Typography variant="subtitle2">Include Internal Farms:</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={includeInternalFarms}
                    value="unchecked"
                    onChange={toggleIncludeInternalFarms}
                  />
                </Grid>
                <Grid item style={{ paddingRight: 0 }}>
                  <Typography variant="subtitle2">Include Not Live Farms:</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={includeNotLiveFarms}
                    value="unchecked"
                    onChange={toggleIncludeNotLiveFarms}
                  />
                </Grid>
                <Grid item style={{ paddingRight: 0 }}>
                  <Typography variant="subtitle2">Request Swap:</Typography>
                </Grid>
                <Grid item>
                  <RequestSwapModal user={user} />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList className={classes.tabList} onChange={(event, newMode) => setMode(newMode)}>
                {Object.values(DeviceOnFarmStatusEnum).map((status) => (
                  <Tab
                    key={status}
                    label={`${formatEnumValue(status)} (${
                      currentFarmState?.collarCountByStatusMap.get(status as DeviceOnFarmStatusEnum) ?? 0
                    })`}
                    value={status}
                    style={{ fontSize: '11px', minWidth: '100px' }}
                  />
                ))}
              </TabList>
            </Box>
            <Divider />
            {mode === DeviceOnFarmStatusEnum.DEPROVISIONED ? (
              // Use a different table for deprovisioned devices as they don't relevant attributes except for serial number
              <DeprovisionedDevicesTable deprovisionedDevices={currentFarmDeprovisionedDevices ?? []} />
            ) : (
              // Use the normal farm issue table for all other statuses
              <FarmIssuesTable devices={orderedDevices} />
            )}
          </Column>
        </TabContext>
      </Row>
    </Wrapper>
  );
};

export default FarmIssuesScreen;
