import { createSelector } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { AppState } from 'store';
import { SearchResultTypeEnum } from '@halter-corp/bff-debug-tool-service-client';
import { selectAllMapItems } from '../debug-cattle.selectors';

export type ItemDiscriminator = {
  itemType: SearchResultTypeEnum;
  name: string;
};

const createSelectorFindMapItemsListByItemType = (filterByItemTypes?: SearchResultTypeEnum[]) =>
  createSelector(
    selectAllMapItems,
    (allMapItems) => {
      if (filterByItemTypes == null) return allMapItems;
      return allMapItems.filter((item) =>
        filterByItemTypes.some((type) => type === item.itemType)
      );
    }
  );

export const findAllMapItems = createSelectorFindMapItemsListByItemType();
export const findHistoryMapItems = createSelectorFindMapItemsListByItemType([
  SearchResultTypeEnum.HISTORYEVENT,
]);

const createSelectorFindMapItemDiscriminators = (filterByItemTypes?: SearchResultTypeEnum[]) =>
  createSelector(createSelectorFindMapItemsListByItemType(filterByItemTypes), (mapItems) =>
    uniqBy(
      mapItems.map<ItemDiscriminator>((item) => {
        const { itemType } = item;

        let discriminator = 'unknown';
        if (itemType === SearchResultTypeEnum.HISTORYEVENT) discriminator = item.data.eventType;
        if (itemType === SearchResultTypeEnum.DEVICEMETRIC) discriminator = item.data.metricName;

        return { itemType, name: discriminator };
      }),
      'name'
    ).sort((a, b) => {
      if (a.itemType !== b.itemType) return a.itemType > b.itemType ? -1 : 1;
      return a.name < b.name ? -1 : 1;
    })
  );

export const findAllMapItemDiscriminators = createSelectorFindMapItemDiscriminators();

export const findDiscriminatedMapItemsForDateRange = createSelector(
  findAllMapItems,
  (_state: AppState, props: { minimumDate: Date | null }) => props.minimumDate?.getTime(),
  (_state: AppState, props: { maximumDate: Date | null }) => props.maximumDate?.getTime(),
  (_state: AppState, props: { selectedDiscriminators: ItemDiscriminator[] }) => props.selectedDiscriminators,
  (mapItems, minTimestamp, maxTimestamp, selectedDiscriminators) => {
    const discriminatedMapItems = mapItems.filter((item) => {
      const { itemType } = item;
      return selectedDiscriminators.some((discriminator) => {
        if (itemType !== discriminator.itemType) return false;
        if (itemType === SearchResultTypeEnum.HISTORYEVENT) return item.data.eventType === discriminator.name;
        if (itemType === SearchResultTypeEnum.DEVICEMETRIC)
          return item.data.metricName === discriminator.name;

        return false;
      });
    });

    return discriminatedMapItems.filter((item) => {
      const timestamp = new Date(item.timestamp).getTime();
      if (minTimestamp != null && timestamp < minTimestamp) return false;
      if (maxTimestamp != null && timestamp > maxTimestamp) return false;

      return true;
    });
  }
);
