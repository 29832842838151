import { Box, Typography, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import Button from 'application/components/button';
import React from 'react';
import { Tower } from 'store/selectors/tower.selectors';

interface TowerPopupExternal {
  tower: Tower;
  handleCreateTowerFarmMapping: (towerId: string) => void;
}
const TowerPopupExternal = ({ tower, handleCreateTowerFarmMapping }: TowerPopupExternal) => (
  <Box>
    <Typography style={{ fontSize: 14, marginTop: 0, marginBottom: 0, marginRight: 3 }}>
      {tower.id}
    </Typography>
    <FormGroup>
      <FormControlLabel
        disabled
        control={<Checkbox size="small" checked={tower.mainsPowered} />}
        label="Mains Powered"
      />
      <FormControlLabel disabled control={<Checkbox checked={tower.router} size="small" />} label="Router" />
      <FormControlLabel
        disabled
        control={<Checkbox checked={tower.starlink} size="small" />}
        label="Starlink"
      />
    </FormGroup>
    <Button
      disabled={false}
      text="Map to current Farm"
      style={{ width: '100%', backgroundColor: 'green' }}
      onClick={() => handleCreateTowerFarmMapping(tower.id)}
    />
  </Box>
);
export default TowerPopupExternal;
