import React, { FunctionComponent } from 'react';
import { Box, Button, Divider, Grid } from '@material-ui/core';
import { Column } from 'application/modules/fleet-watch/utils';
import Header from 'application/components/header';
import { Mode } from '../containers/fleet-tools.container';

type FleetToolsScreenProps = {
  setMode: (mode: Mode) => void;
};

const FleetToolsScreen: FunctionComponent<FleetToolsScreenProps> = ({ setMode }) => (
  <>
    <Column>
      <Box m={0}>
        <Header text="Fleet Tools" size="large" style={{ marginTop: '24px', marginLeft: '16px' }} />
        <Divider style={{ marginBottom: '16px' }} />
        <Box m={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item style={{ width: '100%' }}>
              <Button
                variant="contained"
                style={{ width: '100%', fontSize: '12px', whiteSpace: 'nowrap' }}
                onClick={() => setMode('scan-pallet')}
              >
                Arrived at Halter (scan pallet)
              </Button>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Button
                variant="contained"
                style={{ width: '100%', fontSize: '12px', whiteSpace: 'nowrap' }}
                onClick={() => setMode('start-prep')}
              >
                Start Prep (scan box)
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Column>
  </>
);
export default FleetToolsScreen;
