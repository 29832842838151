import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Dictionary } from '@reduxjs/toolkit';
import { Button, Grid, LinearProgress } from '@material-ui/core';
import styled from 'styled-components';
import { groupBy, keyBy, keys, uniq } from 'lodash';

import { Modifier, Profile } from 'data/training';
import TrainingService from 'services/training.service';
import { useDispatch } from 'store';
import trainingEffects from 'store/effects/training.effects';

import ScreenWrapper from 'application/components/screen-wrapper';
import { CaddieColours } from 'application/theme/colours';
import BusinessService from 'services/business.service';
import FarmProfiles from '../components/farm-profiles';

const HeaderWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 16px 0px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  opacity: 0.75;
`;

const ProfilesScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(trainingEffects.fetchProfiles());
  }, [dispatch]);

  const allProfiles = TrainingService.useSelectProfiles();
  const allModifiers = TrainingService.useSelectModifiers();
  const farms = BusinessService.useFetchFarmList();
  const loading = TrainingService.useIsLoading();

  const groupedProfiles: Dictionary<Profile[]> = useMemo(
    () => groupBy(allProfiles, (profile) => profile.farmId),
    [allProfiles]
  );
  const groupedModifiers: Dictionary<Modifier[]> = useMemo(
    () => groupBy(allModifiers, (modifier) => modifier.farmId),
    [allModifiers]
  );
  const keyedFarms = useMemo(() => keyBy(farms, (farm) => farm.id), [farms]);

  const profilesAndModifiers: Dictionary<{ profiles: Profile[]; modifiers: Modifier[] }> = useMemo(
    () =>
      uniq([...keys(groupedProfiles), ...keys(groupedModifiers)]).reduce(
        (previous, key) => ({
          ...previous,
          [key]: {
            profiles: groupedProfiles[key] ?? [],
            modifiers: groupedModifiers[key] ?? [],
          },
        }),
        {}
      ),
    [groupedProfiles, groupedModifiers]
  );

  return (
    <>
      <LinearProgress variant={loading ? 'query' : 'determinate'} value={100} />
      <ScreenWrapper style={{ backgroundColor: CaddieColours.LightGrey }}>
        <Grid item xs={12} sm={10} lg={8} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <HeaderWrapper>
            <Title>Profiles</Title>
            <div style={{ marginLeft: '60%' }}>
              <Button
                size="small"
                color="primary"
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  marginLeft: 'auto',
                  padding: '8px 24px 8px 24px',
                  margin: '4px',
                }}
                onClick={() => history.push('/training/profiles/create')}
              >
                Add profile
              </Button>
              <Button
                size="small"
                color="primary"
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  marginLeft: 'auto',
                  padding: '8px 24px 8px 24px',
                  margin: '4px',
                }}
                onClick={() => history.push('/training/modifiers/create')}
              >
                Add modifier
              </Button>
            </div>
          </HeaderWrapper>
          {Object.entries(profilesAndModifiers).map(([farmId, profilesAndModifiersForFarm]) => (
            <FarmProfiles
              key={farmId}
              farmName={keyedFarms[farmId]?.name ?? ''}
              profiles={profilesAndModifiersForFarm?.profiles ?? []}
              modifiers={profilesAndModifiersForFarm?.modifiers ?? []}
              onSelectProfile={(profile) => history.push(`/training/profiles/${profile.id}`)}
              onSelectModifier={(modifier) => history.push(`/training/modifiers/${modifier.id}`)}
            />
          ))}
        </Grid>
      </ScreenWrapper>
    </>
  );
};

export default ProfilesScreen;
