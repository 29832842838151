import { Column } from 'application/components/data-table';
import { CattleEventsSummary } from '../../panels/map.panel/events-summary.panel';

const DIGITS_REGEX = /^\d+$/;

export const earTagColumn: Column<CattleEventsSummary> = {
  name: 'Ear Tag',
  keyExtractor: (data: CattleEventsSummary) =>
    DIGITS_REGEX.test(data.cattleName) ? parseInt(data.cattleName, 10) : data.cattleName,
  render: (data: CattleEventsSummary) => data.cattleName,
  allowCopyToClipboard: true,
};

export const failedShockColumn: Column<CattleEventsSummary> = {
  name: 'Failed Shock',
  keyExtractor: (data: CattleEventsSummary) => data.shockFailed,
  render: (data: CattleEventsSummary) => data.shockFailed,
};

export const shockColumn: Column<CattleEventsSummary> = {
  name: 'Shock',
  keyExtractor: (data: CattleEventsSummary) => data.shock,
  render: (data: CattleEventsSummary) => data.shock,
};

export const controlDisabledColumn: Column<CattleEventsSummary> = {
  name: 'Control Disabled',
  keyExtractor: (data: CattleEventsSummary) => data.controlDisabled,
  render: (data: CattleEventsSummary) => data.controlDisabled,
};

export const shoveColumn: Column<CattleEventsSummary> = {
  name: 'Shove',
  keyExtractor: (data: CattleEventsSummary) => data.shove,
  render: (data: CattleEventsSummary) => data.shove,
};

export const piezoingColumn: Column<CattleEventsSummary> = {
  name: 'Piezoing',
  keyExtractor: (data: CattleEventsSummary) => data.piezoing,
  render: (data: CattleEventsSummary) => data.piezoing,
};

export const deactivationEventsColumn: Column<CattleEventsSummary> = {
  name: 'Deactivation Events',
  keyExtractor: (data: CattleEventsSummary) => data.deactivationEvents,
  render: (data: CattleEventsSummary) => data.deactivationEvents ?? 0,
};
