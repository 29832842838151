import { createSlice, PayloadAction, EntityState } from '@reduxjs/toolkit';
import { IMobDTO } from '@halter-corp/cattle-service-client';
import { AllMobs } from 'data/cattle/cattle';

import { reset } from 'store/actions';
import mobEffects from 'store/effects/mob.effects';
import { mobAdapter, allFarmsMobsAdapter } from '../adapters/mob.adapter';

type MobContextInitialState = {
  entityState: EntityState<IMobDTO>;
  currentMobs?: string[];
  allFarmsMobs: EntityState<AllMobs>;
};

const initialState: MobContextInitialState = {
  entityState: mobAdapter.getInitialState(),
  currentMobs: undefined,
  allFarmsMobs: allFarmsMobsAdapter.getInitialState(),
};

const mobSlice = createSlice({
  initialState,
  name: 'mob',
  reducers: {
    clear: () => initialState,

    setCurrentMob: (state, action: PayloadAction<{ currentMob?: string }>) => {
      state.currentMobs = action.payload.currentMob != null ? [action.payload.currentMob] : undefined;
    },
    setCurrentMobs: (state, action: PayloadAction<{ currentMobs?: string[] }>) => {
      state.currentMobs = action.payload.currentMobs;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(mobEffects.fetch.fulfilled, (state, action) => {
        const entities = action.payload;

        mobAdapter.removeAll(state.entityState);
        mobAdapter.addMany(state.entityState, entities);
      })
      .addCase(mobEffects.fetchAllFarmsMobs.fulfilled, (state, action) => {
        const entities = action.payload;
        allFarmsMobsAdapter.removeAll(state.allFarmsMobs);
        allFarmsMobsAdapter.addMany(state.allFarmsMobs, entities);
      }),
});

export default mobSlice;
