import React, { FunctionComponent, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import JSONTree from 'react-json-tree';
import { Power, Router, SettingsInputAntenna, Star } from '@material-ui/icons';
import moment from 'moment';
import { jsonTreeTheme } from 'application/modules/debug-cattle/utils';
import { Box, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { TowerWithDevices } from 'store/selectors/tower.selectors';
import { ProductTypeEnum } from '@halter-corp/discovery-service-client';
import { getGatewayStatusColor } from '../util';
import { MapMode } from '../types';

type TowerLocationsLayerProps = {
  towers: TowerWithDevices[];
  mapMode: MapMode;
  onSelectTowerForCoverageMap: (towerId: string) => void;
  onSelectGatewayForHeatMap: (gatewayId: string) => void;
};

const TowerLocationsLayer: FunctionComponent<TowerLocationsLayerProps> = ({
  towers,
  mapMode,
  onSelectTowerForCoverageMap,
  onSelectGatewayForHeatMap,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState<string | null>(null);

  const borderlessIcon = {
    strokeWidth: '0%',
  };

  return (
    <>
      {towers.map((tower) => {
        const gateway = tower.devices.find((device) => device.type === ProductTypeEnum.GATEWAY);
        const location = gateway?.status?.location ?? tower.location;
        let gatewayData;
        if (gateway != null) {
          const lastSeenEntry = moment.utc(gateway.status?.lastSeenTimestamp).fromNow();
          gatewayData = {
            Name: gateway.name,
            ID: gateway.id,
            TowerID: tower.id,
            Enabled: gateway.status?.enabled ?? 'no data',
            Suspended: gateway.status?.suspended ?? 'no data',
            'Last Seen': lastSeenEntry,
          };
        }

        const handleContextMenu = () => {
          if (gateway != null) {
            if (mapMode === MapMode.HEAT_MAP) {
              onSelectGatewayForHeatMap(gateway.id);
            } else {
              onSelectTowerForCoverageMap(tower.id);
            }
          } else {
            setSnackbarOpen(true);
            setSnackbarContent(`No heat map for "${tower.id}" that does not have a gateway`);
          }
        };

        return (
          <Marker
            key={tower.id}
            oncontextmenu={handleContextMenu}
            title={tower.id}
            zIndexOffset={999999}
            icon={L.divIcon({
              className: 'bla',
              html: ReactDOMServer.renderToString(
                <Box>
                  <Box display="flex">
                    <SettingsInputAntenna
                      style={
                        gateway?.status?.suspended === true
                          ? {
                              fontSize: '32px',
                              stroke: '#FF8200',
                              strokeDasharray: '2px',
                              strokeWidth: '0.9',
                            }
                          : { fontSize: '32px' }
                      }
                      stroke="black"
                      strokeWidth={gateway?.status?.suspended === true ? 0.7 : 0.5}
                      htmlColor={getGatewayStatusColor(gateway)}
                    />
                    {tower.starlink && (
                      <Star
                        id={tower.id}
                        fontSize="small"
                        // eslint-disable-next-line no-nested-ternary
                        htmlColor={getGatewayStatusColor(gateway)}
                        style={borderlessIcon}
                      />
                    )}
                    {tower.router && (
                      <Router
                        id={tower.id}
                        fontSize="small"
                        // eslint-disable-next-line no-nested-ternary
                        htmlColor={getGatewayStatusColor(gateway)}
                        style={borderlessIcon}
                      />
                    )}
                  </Box>
                  {tower.mainsPowered && (
                    <Power
                      id={tower.id}
                      fontSize="small"
                      // eslint-disable-next-line no-nested-ternary
                      htmlColor={getGatewayStatusColor(gateway)}
                      style={borderlessIcon}
                    />
                  )}
                </Box>
              ),
            })}
            position={{
              lat: location.latitude,
              lng: location.longitude,
            }}
          >
            <Popup autoPan={false}>
              {gatewayData ? (
                <JSONTree data={gatewayData} theme={jsonTreeTheme} shouldExpandNode={() => true} hideRoot />
              ) : (
                <p style={{ fontSize: 14 }}>No Gateway Found</p>
              )}
            </Popup>
          </Marker>
        );
      })}

      <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={() => setSnackbarOpen(false)}>
        <Alert elevation={6} variant="filled" onClose={() => setSnackbarOpen(false)} severity="warning">
          {snackbarContent}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TowerLocationsLayer;
