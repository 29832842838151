import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpApiService from 'services/http-api.service';
import { IFarmStatusDTO } from '@halter-corp/business-service-client';

const farmStatusEffects = {
  fetch: createAsyncThunk('farm-status/fetch', async (props: { farmId: string }): Promise<IFarmStatusDTO> => {
    const farmStatusApi = await HttpApiService.getFarmStatusApi();
    const { data: farmStatus } = await farmStatusApi.retrieveFarmStatus(props.farmId);

    return farmStatus;
  }),
  fetchAll: createAsyncThunk('farm-status/fetch-all', async (): Promise<IFarmStatusDTO[]> => {
    const farmStatusApi = await HttpApiService.getFarmStatusApi();
    const { data: farmStatuses } = await farmStatusApi.retrieveAllFarmStatuses();

    return farmStatuses;
  }),
  setMaintenanceOn: createAsyncThunk(
    'farm-status/set-maintenance-on',
    async (props: { farmId: string }): Promise<IFarmStatusDTO> => {
      const farmStatusApi = await HttpApiService.getFarmStatusApi();
      const { data: farmStatus } = await farmStatusApi.setFarmMaintenanceFlagOn(props.farmId);

      return farmStatus;
    }
  ),
  setMaintenanceOff: createAsyncThunk(
    'farm-status/set-maintenance-off',
    async (props: { farmId: string }): Promise<IFarmStatusDTO> => {
      const farmStatusApi = await HttpApiService.getFarmStatusApi();
      const { data: farmStatus } = await farmStatusApi.setFarmMaintenanceFlagOff(props.farmId);

      return farmStatus;
    }
  ),
  setEmergencyOn: createAsyncThunk(
    'farm-status/set-emergency-on',
    async (props: { farmId: string }): Promise<IFarmStatusDTO> => {
      const farmStatusApi = await HttpApiService.getFarmStatusApi();
      const { data: farmStatus } = await farmStatusApi.setFarmEmergencyFlagOn(props.farmId);

      return farmStatus;
    }
  ),
  setEmergencyOff: createAsyncThunk(
    'farm-status/set-emergency-off',
    async (props: { farmId: string }): Promise<IFarmStatusDTO> => {
      const farmStatusApi = await HttpApiService.getFarmStatusApi();
      const { data: farmStatus } = await farmStatusApi.setFarmEmergencyFlagOff(props.farmId);

      return farmStatus;
    }
  ),
};

export default farmStatusEffects;
