import React, { FunctionComponent, CSSProperties } from 'react';
import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
  FormControlLabel,
} from '@material-ui/core';

type CheckboxProps = BaseCheckboxProps & {
  label?: string;
  checked: boolean;
  onChangeValue?: (checked: boolean) => void;
  containerStyle?: CSSProperties;
  labelPlacement?: 'end' | 'start' | 'bottom' | 'top' | undefined;
  boxColour?: string;
};

const Checkbox: FunctionComponent<CheckboxProps> = ({
  label,
  name,
  checked,
  onChange,
  onChangeValue,
  containerStyle,
  style,
  labelPlacement,
  boxColour,
  ...rest
}) => (
  <FormControlLabel
    control={
      <BaseCheckbox
        checked={checked}
        // eslint-disable-next-line no-sequences
        onChange={(e, v) => (onChange?.(e, v), onChangeValue?.(v))}
        name={name}
        style={{
          color: boxColour || '#1769aa',
          ...(rest.disabled ? { opacity: 0.65 } : {}),
          ...style,
        }}
        {...rest}
      />
    }
    label={label}
    style={containerStyle}
    labelPlacement={labelPlacement}
  />
);

export default Checkbox;
