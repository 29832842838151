import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Box, Button, Grid } from '@material-ui/core';
import { entries } from 'lodash';

import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';
import { ICattleCowgSettingsDTO } from '@halter-corp/settings-service-client';
import { IFarmDTO } from '@halter-corp/business-service-client';

import BusinessService from 'services/business.service';
import SettingsService from 'services/settings.service';
import { Profile } from 'data/training';
import { CattleCowgSettingsMetadata } from 'data/settings';

import { ProfileFormValues } from './profile.form.types';
import { prettyPrintProfileType } from '../../utils';
import InputField from '../components/input-field';
import SelectField from '../components/select-field';
import SettingsInputTable from './components/settings-input-table';
import { deriveInitialProfileValues, getDefaults } from './utils';
import { profileTypeToCowgSettingsType } from './constants';
import { AnyCowgSettingsType } from './settings.types';

type ProfileFormProps = {
  onSubmit: (values: any) => void;
  isSubmitting?: boolean;
  initialProfile?: Profile;
};

const ProfileForm: FunctionComponent<ProfileFormProps> = ({
  onSubmit,
  isSubmitting = false,
  initialProfile,
}) => {
  const history = useHistory();

  const farms = BusinessService.useFetchFarmList();
  const settingsMetadata = SettingsService.useCowgSettingsMetadata();

  const cowgSettingsMetadata = useMemo(
    () => (settingsMetadata?.cattleCowg ?? {}) as Partial<CattleCowgSettingsMetadata>,
    [settingsMetadata]
  );

  const defaults = useMemo(
    () =>
      entries(cowgSettingsMetadata).reduce((previous, [key, value]) => {
        if (value == null) return previous;

        return { ...previous, [key]: getDefaults<AnyCowgSettingsType>(value) };
      }, {}) as ICattleCowgSettingsDTO,
    [cowgSettingsMetadata]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = useMemo(
    () => deriveInitialProfileValues({ profile: initialProfile, defaults }),
    [defaults]
  );

  // Handlers
  //-----------------------------------------------------

  const handleProfileSubmit = (values: ProfileFormValues) => {
    onSubmit({ ...values });
  };

  // Render
  //-----------------------------------------------------

  return (
    <>
      {initialValues.settings != null && (
        <Formik<ProfileFormValues> initialValues={initialValues} onSubmit={handleProfileSubmit}>
          {({ handleChange, setFieldValue, handleSubmit, values }) => (
            <Box>
              {/* Farm information */}
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <InputField
                    label="Name"
                    value={values.name}
                    onChange={handleChange('name')}
                    withMargin={false}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SelectField<IFarmDTO | undefined, string | undefined>
                    label="Farm"
                    options={[undefined, ...farms]}
                    value={values.farmId}
                    valueExtractor={(farm) => farm?.id}
                    displayNameExtractor={(farm) => farm?.name ?? 'All farms'}
                    onChange={(value) => setFieldValue('farmId', value)}
                    withMargin={false}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SelectField
                    label="Profile type"
                    options={Object.values(TrainingSettingsTypeEnum)}
                    value={values.type}
                    valueExtractor={(value) => value}
                    displayNameExtractor={prettyPrintProfileType}
                    onChange={(type) => (
                      // eslint-disable-next-line no-sequences
                      setFieldValue('type', type),
                      setFieldValue('settings', defaults[profileTypeToCowgSettingsType[type]])
                    )}
                    withMargin={false}
                  />
                </Grid>
              </Grid>

              {/* Farm settings */}
              <Box mt={6}>
                <SettingsInputTable
                  mode="profile"
                  rows={entries(cowgSettingsMetadata[profileTypeToCowgSettingsType[values.type]] ?? {}).map(
                    ([key, metaData]) => ({
                      key,
                      metaData,
                      value: values.settings[key],
                      onChange: (value) => setFieldValue(`settings.${key}`, value),
                    })
                  )}
                />
              </Box>
              <Box mt={6}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button onClick={history.goBack} disabled={isSubmitting} size="large" variant="outlined">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Formik>
      )}
    </>
  );
};

export default ProfileForm;
