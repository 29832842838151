import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

type InfoBoxProps = {
  title: string;
  large?: boolean;
};

const InfoBoxContainer = styled.div`
  margin-right: 1rem;
`;

const InfoBox: FunctionComponent<InfoBoxProps> = ({ title, large = false, children }) => (
  <InfoBoxContainer>
    <Typography variant="subtitle2" color="textSecondary">
      {title}
    </Typography>
    <Typography variant={large ? 'h5' : 'body1'} gutterBottom>
      {children}
    </Typography>
  </InfoBoxContainer>
);

export default InfoBox;
