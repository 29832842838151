import { useSelector } from 'store';
import {
  getAllFarmStatuses,
  getFarmStatus,
  getLoadingFarmStatus,
} from 'store/selectors/farm-status.selectors';

const FarmStatusService = {
  useFetchAllFarmStatuses: () => useSelector(getAllFarmStatuses),
  useFetchFarmStatus: (farmId: string) => useSelector(getFarmStatus(farmId)),
  useLoadingFarmStatus: () => useSelector(getLoadingFarmStatus),
};

export default FarmStatusService;
