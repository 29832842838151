import React, { FunctionComponent } from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

type WarningMessageProps = {
  onClose: () => void;
  open: boolean;
  content: string;
};

const WarningMessage: FunctionComponent<WarningMessageProps> = ({ onClose, open, content }) => (
  <Snackbar
    open={open}
    autoHideDuration={3000}
    style={{ translate: '0 35px' }}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    onClose={onClose}
  >
    <Alert elevation={3} variant="filled" onClose={onClose} severity="warning">
      {content}
    </Alert>
  </Snackbar>
);

export default WarningMessage;
