import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import DevicesOverviewContainer from './containers/devices-overview.container';
import DeviceDetailsContainer from './containers/device-details.container';

const DevicesModule: FunctionComponent = () => (
  <Switch>
    <Route exact path="/devices">
      <DevicesOverviewContainer />
    </Route>
    <Route exact path="/devices/:serialNumber">
      <DeviceDetailsContainer />
    </Route>
  </Switch>
);

export default DevicesModule;
