import React, { FunctionComponent, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { entries, keys, pick, uniq } from 'lodash';

import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';
import { ICattleCowgSettingsDTO } from '@halter-corp/settings-service-client';
import { IFarmDTO } from '@halter-corp/business-service-client';

import BusinessService from 'services/business.service';
import SettingsService from 'services/settings.service';
import { Modifier } from 'data/training';
import { CattleCowgSettingsMetadata } from 'data/settings';

import Wrapper from 'application/components/wrapper';
import Button from 'application/components/button';

import { ModifierFormValues, ProfileFormValues } from './profile.form.types';
import { prettyPrintProfileType } from '../../utils';
import InputField from '../components/input-field';
import SelectField from '../components/select-field';
import SettingsInputTable from './components/settings-input-table';
import { deriveInitialModifierValues, getDefaults } from './utils';
import { profileTypeToCowgSettingsType } from './constants';
import { AnyCowgSettingsType } from './settings.types';

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
`;

const Separator = styled.div`
  height: 2px;
  background-color: black;
  opacity: 0.15;
  width: 100%;
  margin: 16px 0px 16px 0px;
`;

type ProfileFormProps = {
  onSubmit: (values: any) => void;
  isSubmitting?: boolean;
  initialModifier?: Modifier;
};

const ModifierForm: FunctionComponent<ProfileFormProps> = ({
  onSubmit,
  isSubmitting = false,
  initialModifier,
}) => {
  const history = useHistory();

  const farms = BusinessService.useFetchFarmList();
  const settingsMetadata = SettingsService.useCowgSettingsMetadata();

  const cowgSettingsMetadata = useMemo(
    () => (settingsMetadata?.cattleCowg ?? {}) as Partial<CattleCowgSettingsMetadata>,
    [settingsMetadata]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaults = useMemo(
    () =>
      entries(cowgSettingsMetadata).reduce((previous, [key, value]) => {
        if (value == null) return previous;

        return { ...previous, [key]: getDefaults<AnyCowgSettingsType>(value) };
      }, {}) as ICattleCowgSettingsDTO,
    [cowgSettingsMetadata]
  );

  const [modifierWhitelistedSettings, setModifierWhitelistedSettings] = useState<string[]>(
    keys(initialModifier?.settings ?? [])
  );

  const handleModifierSelectionChange = (key: string, selected: boolean) => {
    if (selected) {
      setModifierWhitelistedSettings(uniq([...modifierWhitelistedSettings, key]));
    } else {
      setModifierWhitelistedSettings(modifierWhitelistedSettings.filter((k) => k !== key));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = useMemo(
    () => deriveInitialModifierValues({ modifier: initialModifier, defaults }),
    [cowgSettingsMetadata]
  );

  const handleProfileSubmit = (values: ProfileFormValues) => {
    onSubmit({ ...values, settings: pick(values.settings, ...modifierWhitelistedSettings) });
  };

  return (
    <>
      {initialValues.settings != null && (
        <Formik<ModifierFormValues> initialValues={initialValues} onSubmit={handleProfileSubmit}>
          {({ handleChange, setFieldValue, handleSubmit, values }) => (
            <Wrapper>
              <InputRow>
                <Grid item xs={12} sm={8} md={4}>
                  <InputField label="Name" value={values.name} onChange={handleChange('name')} />
                </Grid>
              </InputRow>
              <InputRow>
                <SelectField<IFarmDTO | undefined, string | undefined>
                  label="Farm"
                  options={[undefined, ...farms]}
                  value={values.farmId}
                  valueExtractor={(farm) => farm?.id}
                  displayNameExtractor={(farm) => farm?.name ?? 'All farms'}
                  onChange={(value) => setFieldValue('farmId', value)}
                />
                <SelectField
                  label="Modifier type"
                  options={Object.values(TrainingSettingsTypeEnum)}
                  value={values.type}
                  valueExtractor={(value) => value}
                  displayNameExtractor={prettyPrintProfileType}
                  onChange={(type) => (
                    // eslint-disable-next-line no-sequences
                    setFieldValue('type', type),
                    setFieldValue('settings', defaults[profileTypeToCowgSettingsType[type]]),
                    setModifierWhitelistedSettings([])
                  )}
                />
                <SelectField<number, number>
                  label="Priority"
                  options={[10, 20, 30, 40, 50, 60, 70, 80, 90]}
                  value={values.priority}
                  valueExtractor={(value) => value}
                  displayNameExtractor={(value) => value}
                  onChange={(value) => setFieldValue('priority', value)}
                />
              </InputRow>
              <Separator />
              <Wrapper style={{ flex: 1, flexDirection: 'row' }}>
                <SettingsInputTable
                  mode="modifier"
                  onChangeSettingSelected={handleModifierSelectionChange}
                  rows={entries(cowgSettingsMetadata[profileTypeToCowgSettingsType[values.type]] ?? {}).map(
                    ([key, metaData]) => ({
                      key,
                      metaData,
                      value: values.settings[key],
                      onChange: (value) => setFieldValue(`settings.${key}`, value),
                      selectedForModifier: modifierWhitelistedSettings.includes(key),
                    })
                  )}
                />
              </Wrapper>
              <Wrapper
                style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}
                itemContainerStyle={{ margin: 8 }}
              >
                <Button
                  text="Cancel"
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid #1769aa',
                    color: '#1769aa',
                  }}
                  onClick={history.goBack}
                  disabled={isSubmitting}
                />
                <Button text="Save" onClick={() => handleSubmit()} disabled={isSubmitting} />
              </Wrapper>
            </Wrapper>
          )}
        </Formik>
      )}
    </>
  );
};

export default ModifierForm;
