import { createAsyncThunk } from '@reduxjs/toolkit';

import HttpApiService from 'services/http-api.service';
import { IFarmGrazingHeatmapMetricDTO } from '@halter-corp/cow-location-service-client';

const grazingHeatmapEffects = {
  fetchGrazingHeatmapMetricForAllFarms: createAsyncThunk(
    'grazing-heatmap/fetch/metrics/all-farms',
    async (): Promise<IFarmGrazingHeatmapMetricDTO[]> => {
      const metricsApi = await HttpApiService.getGrazingHeatmapMetricsApi();
      const { data: metrics } = await metricsApi.getGrazingHeatmapMetricsForAllFarms();
      return metrics;
    }
  ),
};

export default grazingHeatmapEffects;
