import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import store from '../store';

import NavigationContainer from './navigation/navigation.container';

ReactGA.initialize('UA-176083914-1');

const Application: FunctionComponent = () => (
  <Provider store={store}>
    <BrowserRouter>
      <NavigationContainer />
    </BrowserRouter>
  </Provider>
);

export default Application;
