import React, { ChangeEvent, FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import farmInfraEffects from 'store/effects/farm-infra.effects';
import { selectCurrentShard, selectShards } from 'store/selectors/farm-infra.selectors';
import { IShardDTO } from '@halter-corp/farm-infra-service-client';
import farmInfraSlice from 'store/slices/farm-infra.slice';

type ShardSelectProps = {
  onChange?: (value: string) => void;
  variant?: 'standard' | 'outlined' | 'filled';
  inputLabelStyle?: any;
};

const ShardSelect: FunctionComponent<ShardSelectProps> = ({ onChange }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(farmInfraEffects.fetchShards());
  }, [dispatch]);

  const currentShardId = useSelector(selectCurrentShard);
  const shards = useSelector(selectShards);

  const handleFarmChange = (_event: ChangeEvent<{}>, newShard: IShardDTO | null) => {
    if (newShard == null) {
      return;
    }

    if (onChange != null) {
      onChange(newShard.id);
    }

    dispatch(
      farmInfraSlice.actions.setCurrentShard({
        currentShard: newShard.id,
      })
    );
  };

  useEffect(() => {
    if (currentShardId != null && onChange != null) {
      onChange(currentShardId);
    }
  }, []);

  return (
    <FormControl style={{ width: '100%' }}>
      {shards.length > 0 && (
        <Autocomplete
          options={shards}
          renderInput={(farm) => <TextField {...farm} label="Shard" />}
          getOptionLabel={(option) => option.id || ''}
          value={shards.find(({ id }) => id === currentShardId)}
          onChange={handleFarmChange}
        />
      )}
    </FormControl>
  );
};

export default ShardSelect;
