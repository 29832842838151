import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import deviceEffects from 'store/effects/device.effects';
import mobSlice from 'store/slices/mob.slice';
import settingsEffects from 'store/effects/settings.effects';
import farmEffects from 'store/effects/farm.effects';

import { useParseQueryParameters } from 'application/hooks/use-parameters';
import { useShouldDisplayTable } from 'application/hooks/use-searched-data-table';

import DevicesOverviewScreen from '../screens/devices-overview.screen';

const DevicesOverviewContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const urlQueryString = new URLSearchParams(location.search);
  const searchMode: 'farm' | 'device' = urlQueryString.get('mode') === 'device' ? 'device' : 'farm';

  const { farmId, mobId, cattleNames, serialNumbers } = useParseQueryParameters();
  const shouldDisplayTable = useShouldDisplayTable();

  useEffect(() => {
    dispatch(mobSlice.actions.setCurrentMob({ currentMob: mobId }));
  }, [dispatch, mobId]);

  useEffect(() => {
    if (shouldDisplayTable) {
      dispatch(deviceEffects.fetch({ serialNumbers, searchMode, mobId, loadSettings: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, shouldDisplayTable, farmId, serialNumbers]);

  const loadSettings = useCallback(() => {
    dispatch(deviceEffects.fetch({ serialNumbers, searchMode, mobId, loadSettings: true }));
  }, [dispatch, farmId, searchMode, serialNumbers, mobId]);

  useEffect(() => {
    dispatch(farmEffects.fetch());
    dispatch(settingsEffects.fetchMetadata());
  }, [dispatch]);

  const [autoRefresh, setAutoRefresh] = useState(false);
  const [activeIntervalId, setActiveIntervalId] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (autoRefresh) {
      const intervalId = window.setInterval(() => {
        dispatch(deviceEffects.fetch({ serialNumbers, searchMode, mobId }));
      }, 60_000);
      setActiveIntervalId(intervalId);
    } else if (activeIntervalId != null) {
      clearInterval(activeIntervalId);
    }

    return () => clearInterval(activeIntervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefresh]);

  return (
    <DevicesOverviewScreen
      searchMode={searchMode}
      autoRefresh={autoRefresh}
      onChangeAutoRefresh={setAutoRefresh}
      displayTable={shouldDisplayTable}
      farmId={farmId}
      mobId={mobId}
      cattleNames={cattleNames}
      serialNumbers={serialNumbers}
      loadSettings={loadSettings}
    />
  );
};

export default DevicesOverviewContainer;
