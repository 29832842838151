import React, { FunctionComponent } from 'react';
import { Button as BaseButton, ButtonProps as BaseButtonProps, Size } from '@material-ui/core';

type ButtonProps = BaseButtonProps & {
  text: string;
  size?: Size | 'large';
  backgroundColour?: string;
  colour?: string;
  fullWidth?: boolean;
  outline?: string;
  borderRadius?: number;
  target?: string;
};

const Button: FunctionComponent<ButtonProps> = ({
  text,
  style,
  size,
  backgroundColour,
  colour,
  fullWidth,
  borderRadius,
  ...rest
}) => (
  <BaseButton
    size={size ?? 'medium'}
    style={{
      backgroundColor: backgroundColour ?? '#1769aa',
      color: colour ?? 'white',
      borderRadius,
      textTransform: 'none',
      ...style,
      ...(rest.disabled ? { opacity: 0.65 } : {}),
    }}
    fullWidth={fullWidth}
    {...rest}
  >
    {text}
  </BaseButton>
);

export default Button;
