import React, { FunctionComponent } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { useDispatch } from 'store';
import farmStatusEffects from 'store/effects/farm-status.effects';

import FarmStatusDialog from './farm-status.dialog';
import { FarmStatusMode } from '../utils';
import { CaddieColours } from '../../../theme/colours';

const useStyles = makeStyles(() => ({
  dialogContent: {
    fontWeight: 'bold',
    display: 'inline',
  },
  dialogLink: {
    marginLeft: '3px',
    color: '#757575',
    textDecoration: 'underline',
    fontWeight: 'bold',
    display: 'inline',
  },
  dialogConfirmation: {
    fontWeight: 'bold',
    display: 'inline',
    textDecoration: 'underline',
  },
}));

type FarmEmergencyDialogProps = {
  farmStates: FarmState[];
  isDialogOpen: (isOpen: boolean) => void;
  clearSelectedFarms: () => void;
};

type FarmState = {
  farmId: string;
  farmName: string;
  status: FarmStatusMode;
};

const FarmEmergencyDialog: FunctionComponent<FarmEmergencyDialogProps> = ({
  farmStates,
  isDialogOpen,
  clearSelectedFarms,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleConfirm = async () => {
    try {
      const farmsMaintenance = farmStates.filter((farm) => farm.status === FarmStatusMode.Maintenance);
      await Promise.all(
        farmsMaintenance.map((farm) => dispatch(farmStatusEffects.setMaintenanceOff({ farmId: farm.farmId })))
      );

      const farmsToEmergency = farmStates.filter((farm) => farm.status !== FarmStatusMode.Emergency);
      await Promise.all(
        farmsToEmergency.map((farm) => dispatch(farmStatusEffects.setEmergencyOn({ farmId: farm.farmId })))
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error when turning farm to emergency mode', e);
    } finally {
      isDialogOpen(false);
      clearSelectedFarms();
    }
  };

  const dialogContent = (
    <>
      Are you sure you want to turn on emergency mode for{' '}
      <div className={classes.dialogContent}>{farmStates.length} farms? </div>
      All app functionality will be disabled for the farmer. Collars will hold their commands for 12 hours
      before being released.
      <Link
        className={classes.dialogLink}
        target="_blank"
        rel="noreferrer"
        href="https://www.notion.so/Local-Emergency-Release-1c65b976f4384d9ab3f53865df2beeef"
      >
        Learn more
      </Link>
    </>
  );

  const dialogConfirmation = (
    <>
      I have read and understand the <div className={classes.dialogConfirmation}>implications</div> of turning
      on Emergency mode.
    </>
  );

  return (
    <FarmStatusDialog
      farmStates={farmStates}
      isDialogOpen={isDialogOpen}
      dialogContent={dialogContent}
      farmStatus={FarmStatusMode.Emergency}
      handleConfirm={handleConfirm}
      dialogConfirmation={dialogConfirmation}
      color={CaddieColours.EmergencyRed}
      confirmationColor={CaddieColours.EmergencyRedConfirmation}
    />
  );
};

export default FarmEmergencyDialog;
