import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { ITrainingModifierDTO } from '@halter-corp/training-service-client';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16;
  font-weight: 300;
  opacity: 0.9;
`;

const ModifierListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-horizontal: 4px;
`;

type ModifiersBeforeAndAfterProps = {
  before: ITrainingModifierDTO[];
  after: ITrainingModifierDTO[];
};

const ModifiersBeforeAndAfter: FunctionComponent<ModifiersBeforeAndAfterProps> = ({ before, after }) => (
  <Wrapper>
    <Content>
      Before:
      <ModifierListWrapper>
        {before.map(({ name, deletedAt }) => (
          <div key={name} style={{ backgroundColor: deletedAt != null ? '#ef5350' : 'transparent' }}>
            {name}
          </div>
        ))}
      </ModifierListWrapper>
    </Content>
    <Content>
      After:
      <ModifierListWrapper>
        {after.map(({ name, deletedAt }) => (
          <div key={name} style={{ backgroundColor: deletedAt != null ? '#ef5350' : 'transparent' }}>
            {name}
          </div>
        ))}
      </ModifierListWrapper>
    </Content>
  </Wrapper>
);

export default ModifiersBeforeAndAfter;
