import React, { FC, useMemo } from 'react';
import { TileLayer } from 'react-leaflet';
import { environment } from 'env-config';

type TowerCoverageLayerProps = {
  coverageMapsVisibilitySet: Set<string>;
};

const TowerCoverageLayer: FC<TowerCoverageLayerProps> = ({ coverageMapsVisibilitySet }) => {
  const towerIdsForCoverageMaps = useMemo(
    () => Array.from(coverageMapsVisibilitySet),
    [coverageMapsVisibilitySet]
  );

  const coverageMapOpacity = towerIdsForCoverageMaps.length === 1 ? 0.55 : 0.375;

  return (
    <>
      {towerIdsForCoverageMaps.map((towerId) => {
        const tileUrl = `https://halter-keyhole-radio-coverage.${environment}.halter.io/tower-coverage/${towerId}/{z}/{x}/{y}.png`;

        return (
          <TileLayer
            key={`coverage-map-${towerId}`}
            opacity={coverageMapOpacity}
            url={tileUrl}
            maxNativeZoom={16}
            minNativeZoom={16}
          />
        );
      })}
    </>
  );
};

export default TowerCoverageLayer;
