import { EventLocation } from 'store/slices/heat-map.slice';
import { Bounds } from '../panels/data-map.panel';

export const eventLocationBounds = (eventLocations: EventLocation[]): Bounds =>
  eventLocations.reduce(
    (acc, event) => ({
      minLng: Math.min(acc.minLng, event.longitude),
      minLat: Math.min(acc.minLat, event.latitude),
      maxLng: Math.max(acc.maxLng, event.longitude),
      maxLat: Math.max(acc.maxLat, event.latitude),
    }),
    { minLng: Infinity, minLat: Infinity, maxLng: -Infinity, maxLat: -Infinity }
  );

export function stringToColour(string: string) {
  let hash = 0;
  string.split('').forEach((char) => {
    // eslint-disable-next-line no-bitwise
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = '#';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, '0');
  }
  return colour;
}
