import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { IMobDTO } from '@halter-corp/cattle-service-client';

import MobService from 'services/mob.service';

import Button from 'application/components/button';
import MultiValueInputField from 'application/components/multi-value-input-field';
import { useLocationSearch } from 'application/hooks/use-parameters';
import SelectField from '../forms/components/select-field';
import useUpdateMobs from '../hooks/use-update-mobs';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 16px 24px 16px 24px;
`;

type TrainingSearchPanelProps = {
  farmId: string;
  mobId?: string;
  cattleNames?: string[];
};

const TrainingSearchPanel: FunctionComponent<TrainingSearchPanelProps> = ({
  farmId,
  mobId: initialMobId,
  cattleNames: initialCattleNames,
}) => {
  const mobs = MobService.useFetchMobList();

  const [mobId, setMobId] = useState<string | undefined>(initialMobId);
  const [cattleNames, setCattleNames] = useState(initialCattleNames);

  const history = useHistory();
  const locationSearch = useLocationSearch({ farmId, mobId, cattleNames: cattleNames?.join(',') });
  useUpdateMobs(farmId);

  const handleSearchEvent = () => {
    ReactGA.event({
      category: 'Profiles',
      action: 'Search',
    });
  };

  useEffect(() => {
    setMobId(undefined);
  }, [farmId]);

  return (
    <Wrapper>
      <Grid container spacing={2} alignItems="center" alignContent="center">
        <Grid item xs={12} sm={5} style={{ display: 'flex' }}>
          <SelectField<IMobDTO | undefined, string | undefined>
            label="Mob"
            options={[undefined, ...mobs]}
            value={mobId}
            valueExtractor={(mob) => mob?.id}
            displayNameExtractor={(mob) => mob?.name}
            onChange={setMobId}
          />
        </Grid>
        <Grid item xs={12} sm={5} style={{ display: 'flex' }}>
          <MultiValueInputField label="Cow numbers" value={cattleNames} onChange={setCattleNames} />
        </Grid>
        <Grid item xs={12} sm={2} style={{ display: 'grid' }}>
          <Button
            text="Search"
            size="large"
            backgroundColour="black"
            colour="white"
            // eslint-disable-next-line no-sequences
            onClick={() => (history.push(locationSearch), handleSearchEvent())}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default TrainingSearchPanel;
