import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Source, Layer, HeatmapLayer } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import HeatMapService from 'services/heat-map.service';
import heatMapEffects from 'store/effects/heat-map.effects';
import { featureCollection, point } from 'turf';
import { Bounds, DataMapSearch } from '../panels/data-map.panel';
import { eventLocationBounds } from '../util/utils';

type VibeHeatmapLayerProps = {
  search: DataMapSearch;
  currentTime?: Date;
  recenterMap: (bounds: Bounds) => void;
  refresh: boolean;
};

const heatmapLayer: HeatmapLayer = {
  id: 'vibe-heatmap',
  type: 'heatmap',
  paint: {
    'heatmap-weight': 1,
    'heatmap-intensity': 1,
    'heatmap-radius': ['interpolate', ['exponential', 2], ['zoom'], 0, 1, 22, 200],
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(35, 194, 45,0)',
      0.1,
      'rgba(35, 194, 45,0.8)',
      1,
      'rgb(110, 240, 119)',
    ],
  },
};

const VibeHeatmapLayer: FunctionComponent<VibeHeatmapLayerProps> = ({
  search,
  currentTime,
  recenterMap,
  refresh,
}) => {
  const dispatch = useDispatch();
  const vibeLocations = HeatMapService.useFetchVibeLocations();

  const filteredVibeLocations =
    currentTime === undefined
      ? vibeLocations
      : vibeLocations.filter(
        (vibeLocation) =>
          vibeLocation.timestamp.getTime() <= currentTime.getTime() &&
          vibeLocation.timestamp.getTime() >= currentTime.getTime() - 180 * 1000
      );
  const vibeFeatureCollection =
    filteredVibeLocations &&
    featureCollection(
      filteredVibeLocations.map((vibeLocation) => point([vibeLocation.longitude, vibeLocation.latitude]))
    );

  useEffect(() => {
    dispatch(
      heatMapEffects.fetchVibeLocations({
        farmIds: search.farmIds,
        mobIds: search.mobIds,
        cattleNames: search.cattleNames,
        startTime: search.startTime,
        endTime: search.endTime,
      })
    );
  }, [JSON.stringify(search), refresh]);

  // Recenter Map
  useMemo(() => {
    if (vibeLocations.length > 0) {
      const bounds = eventLocationBounds(vibeLocations);
      recenterMap(bounds);
    }
  }, [vibeLocations]);

  return (
    <Source type="geojson" data={vibeFeatureCollection}>
      <Layer {...heatmapLayer} minzoom={12} />
    </Source>
  );
};
export default VibeHeatmapLayer;
