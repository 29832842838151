import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import RotationGrazedAreaScreen from './screens/rotation-grazed-area-screen';

const BeefModule: FunctionComponent = () => (
  <Switch>
    <Route exact path="/beef">
      <RotationGrazedAreaScreen />
    </Route>
  </Switch>
);

export default BeefModule;
