import { entries, groupBy, orderBy } from 'lodash';
import { ISearchResultItemDTO } from '@halter-corp/bff-debug-tool-service-client';
import {
  BuiltInTestStatus,
  BuiltInTestStatusWhitelist,
  DeviceStats,
  LastCommandStatus,
  LoraCheckinStatus,
  PowerGeneration,
  PowerUsage,
  WifiCheckinStatus,
} from 'data/device';
import BffDebugToolService from 'services/bff-debug-tool.service';
import { IDeviceStatsDTO } from '@halter-corp/device-service-client';
import { buildDeviceStatsFromApiResult } from '../../../services/device.service';

export const buildSerialNumberToDeviceStatsMap = (deviceStatsDTOList: IDeviceStatsDTO[]) => {
  const serialNumberToDeviceStatsMap = new Map();
  // eslint-disable-next-line no-restricted-syntax
  for (const deviceStatsDTO of deviceStatsDTOList) {
    const deviceStats = buildDeviceStatsFromApiResult(deviceStatsDTO);
    serialNumberToDeviceStatsMap.set(deviceStatsDTO.serialNumber, deviceStats);
  }
  return serialNumberToDeviceStatsMap;
};

const buildPowerGenerationStat = (item?: ISearchResultItemDTO): PowerGeneration | undefined => {
  if (item == null) return undefined;
  return {
    batteryVoltageMv: item.data?.metric?.powerGeneration?.batteryMv,
    batterySocPercent: item.data?.metric?.powerGeneration?.batterySocPercent,
    uptime: item.data?.metric?.powerGeneration?.uptime,
    lastUpdate: new Date(item.timestamp), // FIXME ? should this be x 1000
  };
};

const buildLastCommandStatusStat = (item?: ISearchResultItemDTO): LastCommandStatus | undefined => {
  if (item == null) return undefined;
  return {
    commandId: item.data?.metric?.lastCommandStatus?.commandId,
    commandType: item.data?.metric?.lastCommandStatus?.commandType,
    inZone: item.data?.metric?.lastCommandStatus?.zoneStatusContext?.inZone,
    controlDisabledReasons: item.data?.metric.lastCommandStatus.controlDisabledReasons,
    lastUpdate: new Date(item.timestamp), // FIXME ? should this be x 1000
  };
};

const buildLoraCheckinStatusStat = (item?: ISearchResultItemDTO): LoraCheckinStatus | undefined => {
  if (item == null) return undefined;
  const metric = item.data?.metric?.loraCheckinStatus;
  return { ...metric, lastUpdate: item.timestamp, lastRetrieval: new Date() };
};

const buildWifiCheckStatusStat = (item?: ISearchResultItemDTO): WifiCheckinStatus | undefined => {
  if (item == null) return undefined;
  const metric = item.data?.metric?.wifiCheckinStatus;
  return { ...metric, lastUpdate: item.timestamp, lastRetrieval: new Date() };
};

export const buildBuiltInTestStatusStat = (item?: ISearchResultItemDTO): BuiltInTestStatus | undefined => {
  if (item == null) return undefined;
  const faults: string[] = item.data?.metric?.builtInTestStatus?.faults || [];
  const filteredFaults: string[] = faults.filter((label) => !BuiltInTestStatusWhitelist.includes(label));
  const filteredBuiltInTestStatus = {
    isFaulty: item.data.metric?.builtInTestStatus?.isFaulty,
    faults: filteredFaults,
    magCalPercentage: item.data.metric?.imuStatus?.magCalPercentage,
  };
  return filteredBuiltInTestStatus;
};

const buildPowerUsageStat = (item?: ISearchResultItemDTO): PowerUsage | undefined => {
  if (item == null) return undefined;
  return { batteryVoltageMv: item.data?.metric?.powerUsage?.batteryState?.batteryVoltageMv };
};

const getBatteryVoltage = (
  powerGeneration: PowerGeneration | undefined,
  powerUsage: PowerUsage | undefined
): number | undefined => powerGeneration?.batteryVoltageMv || powerUsage?.batteryVoltageMv || undefined;

const getBatteryPercentage = (powerGeneration: PowerGeneration | undefined): number | undefined =>
  powerGeneration?.batterySocPercent;

export const getDeviceStats = async (
  serialNumbers: string[]
): Promise<{ [serialNumber: string]: DeviceStats }> => {
  const rawDeviceMetricItems = await BffDebugToolService.getStatsForDevicesOverview(serialNumbers);
  const itemsBySerialNumber = groupBy(rawDeviceMetricItems, (item) => item.data.serialNumber);

  return entries(itemsBySerialNumber).reduce((deviceStats, [serialNumber, items]) => {
    const itemsByMetricType = groupBy(items, (item) => item.data.metricName);
    const mostRecentMetrics: { [metricName: string]: ISearchResultItemDTO } = entries(
      itemsByMetricType
    ).reduce((mostRecentMetricItemByName, [metricName, metricItems]) => {
      const mostRecentItem = orderBy(metricItems, (item) => item.timestamp, 'desc')[0];
      if (mostRecentItem == null) return mostRecentMetricItemByName;
      return { ...mostRecentMetricItemByName, [metricName]: mostRecentItem };
    }, {});

    const stats: DeviceStats = {
      lastCommandStatus: buildLastCommandStatusStat(mostRecentMetrics.LAST_COMMAND_STATUS),
      loraCheckinStatus: buildLoraCheckinStatusStat(mostRecentMetrics.LORA_CHECKIN),
      wifiCheckinStatus: buildWifiCheckStatusStat(mostRecentMetrics.WIFI_CHECKIN),
      builtInTestStatus: buildBuiltInTestStatusStat(mostRecentMetrics.BUILT_IN_TEST_STATUS),
      batteryVoltage: getBatteryVoltage(
        buildPowerGenerationStat(mostRecentMetrics.POWER_GENERATION),
        buildPowerUsageStat(mostRecentMetrics.POWER_USAGE)
      ),
      batteryPercentage: getBatteryPercentage(buildPowerGenerationStat(mostRecentMetrics.POWER_GENERATION)),
    };

    return { ...deviceStats, [serialNumber]: stats };
  }, {});
};

export const detailedBuiltInTestLookup = new Map<string, string>([
  ['BIT_SLAVE_FAULT', 'Slave side of collar is not communicating'],
  [
    'BIT_UNEXPECTED_ACTUATION',
    'Collar may be vibing or piezoing due to water ingress, check power consumption and swap if appropriate',
  ],
  ['BIT_IMU_NO_COMMS', 'The Inertial Measurement Unit used to determine collar orientation is not operating'],
  [
    'BIT_IMU_NO_CALIBRATION_VALUES',
    'The Inertial Measurement Unit used to determine collar orientation is not calibrated',
  ],
  ['BIT_SLAVE_BUS_FAILURE', 'Slave side of collar is not communicating'],
  ['BIT_CORE_NO_ESP_DATA_RECEIVED', 'The core has not received any data from the host (ESP/COMMS) processor'],
  ['BIT_GPS_NO_COMMS', 'The GPS is not communicating'],
  ['BIT_GPS_NO_VALID_FIX', 'The GPS has not acquired a valid location for an extended period of time'],
  ['BIT_ACTUATION_HARDWARE_FAULT', 'Piezo or vibe outputs may not be operating'],
  ['BIT_GPS_NO_PPS', 'The GPS cannot provide accurate timing information'],
  ['BIT_COLLAR_BACKWARD', 'The collar may fitted backwards to the cow'],
  ['BIT_IMU_GPS_HEADING_MISMATCH', 'The heading of the collar may not be accurate'],
  ['BIT_GPS_NO_FIRMWARE', 'The GPS module is not operational, swap required'],
  [
    'BIT_CORE_NO_ESP_ACKNOWLEDGEMENT',
    'The core has not received an acknowledgement from the host (ESP/COMMS) processor',
  ],
  [
    'BIT_CORE_ESP_WAKE_FAILURE',
    'The core requested to wake the host (ESP/COMMS) processor but the ESP did not become active',
  ],
  ['BIT_NFC_NO_COMMS', 'The Near Field Communications device may not be operational'],
  ['BIT_SHOCK_CHARGE_FAILURE', 'Shock circuit failed to charge to required level'],
  ['BIT_UNEXPECTED_SHOCK', 'Shock circuit has detected the shock circuit is active when it should not be'],
  ['BIT_SLAVE_BUS_INIT_FAILURE', 'Firmware failed to initialize the slave bus'],
  ['BIT_ACTUATION_PIEZO_FAULT', 'Piezo output may not be operating. Note: In release/10.6.* this is noise.'],
  ['BIT_ACTUATION_VIBE_FAULT', 'Vibe output may not be operating. Note: In release/10.6.* this is noise.'],
  ['BIT_UNEXPECTED_PIEZO', 'Piezo output has detected the piezo is active when it should not be'],
  ['BIT_UNEXPECTED_VIBE', 'Vibe output has detected the vibe is active when it should not be'],
  ['BIT_UNEXPECTED_SHOCK_CHARGE', 'Unexpected shock charge detected'],
]);

export const detailedBitReason = (reason: string): string =>
  `${reason} - ${detailedBuiltInTestLookup.get(reason)}` || reason;

export const generateBuiltInTestStatusToolTip = (status: BuiltInTestStatus | undefined): string => {
  if (status?.faults == null) {
    return 'No faults';
  }
  const detailed = status.faults?.map((reason) => detailedBitReason(reason));
  return detailed.join('\n');
};

export const hasFaults = (status: BuiltInTestStatus | undefined): boolean => {
  if (status?.faults == null) {
    return false;
  }
  return status.faults.length > 0;
};
