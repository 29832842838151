import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import FleetToolsScreen from '../screens/fleet-tools.screen';
import ScanPrepBoxScreen from '../screens/scan-prep-box.screen';
import ScanPalletScreen from '../screens/scan-pallet.screen';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  overflow: hidden;
`;

export type Mode = 'none' | 'scan-pallet' | 'start-prep';

const FleetToolsContainer: FunctionComponent = () => {
  const loading = false;

  const [mode, setMode] = useState<Mode>('none');

  return (
    <>
      <LinearProgress variant={loading ? 'query' : 'determinate'} value={100} />
      <Wrapper>
        {mode === 'none' && <FleetToolsScreen setMode={setMode} />}
        {mode === 'scan-pallet' && <ScanPalletScreen setMode={setMode} />}
        {mode === 'start-prep' && <ScanPrepBoxScreen setMode={setMode} />}
      </Wrapper>
    </>
  );
};

export default FleetToolsContainer;
