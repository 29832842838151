import React from 'react';

import StatusIcon, { StatusColours } from 'application/components/status-icon';
import { IAlarmDTO } from '@halter-corp/collar-alarm-service-client';

export const alarmsToString = (alarms: IAlarmDTO[] | undefined) => {
  if (alarms === undefined || alarms.length === 0) {
    return 'No alarms';
  }

  const alarmStrings = alarms.map((alarm) => alarm.type);
  return alarmStrings.join(', ');
};

export const deviceAlarmStatus = (alarms: IAlarmDTO[] | undefined) => {
  if (alarms == null) {
    return null;
  }
  return <StatusIcon color={alarms.length > 0 ? StatusColours.AMBER : StatusColours.GREEN} />;
};
