import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { findAllLocations } from './map-page-common.selector';

/**
 * Cattle location
 */
export type CattleLocation = { timestamp: number; location: { lat: number; lng: number } };

export type CattleLocationPath = {
  cattleName: string;
  locations: CattleLocation[];
};

export type FindLocationPathsByCattleNameForDateRangeReducerType = {
  [cattleName: string]: CattleLocationPath;
};

export const findCattleLocationPaths = createSelector(findAllLocations, ({ cowg, positions }) => {
  const cattleLocations: { [cattleId: string]: CattleLocation[] } = {};
  cowg.forEach((cowgEvent) => {
    if (cattleLocations[cowgEvent.cattleName] != null) {
      cattleLocations[cowgEvent.cattleName].push({
        timestamp: new Date(cowgEvent.timestamp).getTime(),
        location: { lat: cowgEvent.latitude, lng: cowgEvent.longitude },
      });
    } else {
      cattleLocations[cowgEvent.cattleName] = [
        {
          timestamp: new Date(cowgEvent.timestamp).getTime(),
          location: { lat: cowgEvent.latitude, lng: cowgEvent.longitude },
        },
      ];
    }
  });
  positions.forEach((position) => {
    if (cattleLocations[position.cattleName] != null) {
      cattleLocations[position.cattleName].push({
        timestamp: new Date(position.timestamp).getTime(),
        location: { lat: position.latitude, lng: position.longitude },
      });
    } else {
      cattleLocations[position.cattleName] = [
        {
          timestamp: new Date(position.timestamp).getTime(),
          location: { lat: position.latitude, lng: position.longitude },
        },
      ];
    }
  });

  return Object.entries(cattleLocations).map(([cattleName, locations]) => {
    const sorted = sortBy(locations, 'timestamp');
    return { cattleName, locations: sorted };
  });
});
