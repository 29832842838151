import { createSlice } from '@reduxjs/toolkit';

import deviceEffects from '../effects/device.effects';
import { reset } from '../actions';

const initialState = {
  loading: false,
};

const devicesProvisioningSlice = createSlice({
  initialState,
  name: 'devicesProvisioning',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(deviceEffects.preProvisionDevicesToFarm.pending, (state) => {
        state.loading = true;
      })
      .addCase(deviceEffects.preProvisionDevicesToFarm.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deviceEffects.removeFarmPreProvisioningFromDevices.pending, (state) => {
        state.loading = true;
      })
      .addCase(deviceEffects.removeFarmPreProvisioningFromDevices.fulfilled, (state) => {
        state.loading = false;
      }),
});

export default devicesProvisioningSlice;
