import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import farmEffects from 'store/effects/farm.effects';
import BusinessService from 'services/business.service';
import FarmStatusService from 'services/farm-status.service';
import farmStatusEffects from 'store/effects/farm-status.effects';

import FleetService from 'services/fleet.service';
import fleetEffects from 'store/effects/fleet.effects';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import { FarmStatusMode, getFleetFarmStates } from '../utils';
import FleetViewScreen from '../screens/fleet-view.screen';

export type FleetFarmState = {
  farmId: string;
  farmName: string;
  status: FarmStatusMode;
  collarCountByStatusMap: Map<DeviceOnFarmStatusEnum, number>;
};

/**
 * @context - Temporary container component to migrate from device-service APIs to fleet-service APIs
 */
const FleetViewContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const farms = BusinessService.useFetchFarmList();

  const farmStatuses = FarmStatusService.useFetchAllFarmStatuses();
  const deviceOnFarmStatusCountsByFarmId = FleetService.useFetchDeviceOnFarmStatusCountsByFarmId();

  const { favourites } = localStorage;

  useEffect(() => {
    dispatch(farmEffects.fetch());
    dispatch(farmStatusEffects.fetchAll());
  }, [dispatch]);

  useEffect(() => {
    if (!farms) return;
    dispatch(fleetEffects.fetchDeviceOnFarmStatusCounts({ farmIds: farms.map((f) => f.id) }));
  }, [dispatch, farms]);

  const farmStateData = useMemo(
    () => getFleetFarmStates(farms, farmStatuses.entities, deviceOnFarmStatusCountsByFarmId),
    [farms, farmStatuses.entities, deviceOnFarmStatusCountsByFarmId]
  );

  return <FleetViewScreen favourites={favourites} farmStateData={farmStateData} />;
};

export default FleetViewContainer;
