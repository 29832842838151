import { Card, Grid } from '@material-ui/core';

import React, { FunctionComponent } from 'react';
import BehaviourService from 'services/behaviour.service';
import { HealthTable } from '../components/health.table';

const HealthPanel: FunctionComponent = () => {
  const { checkImmediately, needsAttention, diagnosed } = BehaviourService.useSelectFarmCurrentHealthCases();
  const { currentPageData: historical } = BehaviourService.useSelectFarmHistoricalHealthCases();

  return (
    <Grid container>
      <Grid item lg={12}>
        <Card style={{ width: '100%' }}>
          {checkImmediately.length > 0 && (
            <HealthTable title="Check immediately" healthCases={checkImmediately} hideDiagnoses />
          )}
          {diagnosed.length > 0 && <HealthTable title="Diagnosed" healthCases={diagnosed} hideSymptoms />}
          {needsAttention.length > 0 && (
            <HealthTable title="Needs attention" healthCases={needsAttention} hideDiagnoses />
          )}
          {historical.length > 0 && <HealthTable title="Historical" healthCases={historical} hideSymptoms />}
        </Card>
      </Grid>
    </Grid>
  );
};

export default HealthPanel;
