import React, { FunctionComponent } from 'react';
import { Source, Layer, SkyLayer } from 'react-map-gl';

// set color below the ground
const skyLayer: SkyLayer = {
  id: 'sky',
  type: 'sky',
};

const BlueSkyLayer: FunctionComponent = () => (
  <Source id="sky-layer" type="raster">
    <Layer {...skyLayer} />
  </Source>
);
export default BlueSkyLayer;
