import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import HeatMapService from 'services/heat-map.service';
import heatMapEffects from 'store/effects/heat-map.effects';
import { bbox } from 'turf';
import { Bounds, DataMapSearch } from '../panels/data-map.panel';

type FarmPaddocksLayerProps = {
  search: DataMapSearch;
  recenterMap: (bounds: Bounds) => void;
  refresh: boolean;
};

const FarmPaddocksLayer: FunctionComponent<FarmPaddocksLayerProps> = ({ search, recenterMap, refresh }) => {
  const dispatch = useDispatch();

  const farmPaddocks = HeatMapService.useFetchFarmPaddocks();

  useEffect(() => {
    if (!search.farmIds) return;
    dispatch(heatMapEffects.fetchFarmPaddocks(search?.farmIds));
  }, [JSON.stringify(search), refresh]);

  // Recenter Map
  useMemo(() => {
    if (farmPaddocks.length > 0) {
      const bboxes = farmPaddocks.map((paddock) => bbox(paddock.feature));
      const bounds = bboxes.reduce(
        (acc, bb) => ({
          minLng: Math.min(acc.minLng, bb[0]),
          minLat: Math.min(acc.minLat, bb[1]),
          maxLng: Math.max(acc.maxLng, bb[2]),
          maxLat: Math.max(acc.maxLat, bb[3]),
        }),
        { minLng: Infinity, minLat: Infinity, maxLng: -Infinity, maxLat: -Infinity }
      );

      if (bounds.minLng !== Infinity) {
        recenterMap(bounds);
      }
    }
  }, [farmPaddocks]);

  return (
    <>
      {farmPaddocks.map((paddock) => (
        <Source type="geojson" data={paddock.feature} key={paddock.id}>
          <Layer type="line" paint={{ 'line-color': '#fff', 'line-width': 2, 'line-opacity': 0.6 }} />
        </Source>
      ))}
    </>
  );
};
export default FarmPaddocksLayer;
