
import { AxiosError } from 'axios';

import { ISlotGroupMappingDTO } from '@halter-corp/message-service-client';
import HttpApiService from './http-api.service';

const MessageService = {
  getSlotGroupMappingByFarmId: async (farmId: string): Promise<ISlotGroupMappingDTO | undefined> => {
    const api = await HttpApiService.getMessageSlotGroupApi();
    try {
      const { data } = await api.getSlotGroupMappingByFarmID(farmId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return undefined;
      }
      throw err;
    }
  },
  getSlotGroupMappingsBySlotGroupId: async (slotGroupId: string): Promise<ISlotGroupMappingDTO[] | undefined> => {
    const api = await HttpApiService.getMessageSlotGroupApi();
    try {
      const { data } = await api.getAllSlotGroupMappingsBySlotGroupID(slotGroupId);
      return data;
    } catch (err) {
      const axiosError: AxiosError = err as AxiosError;
      if (axiosError.response != null && axiosError.response.status === 404) {
        return [];
      }
      throw err;
    }
  },
};

export default MessageService;
