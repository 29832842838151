import { useMemo, useState } from 'react';
import { Column } from 'application/components/data-table';
import { orderBy, partition } from 'lodash';

function useTableSorting<T>({
  defaultSortColumnName,
  columns,
  items,
}: {
  defaultSortColumnName?: string;
  columns: Column<T>[];
  items: T[];
}) {
  const [sortColumnName, setSortColumnName] = useState<string | undefined>(defaultSortColumnName);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const sortedItems = useMemo(() => {
    const sortColumn = columns.find(({ name }) => sortColumnName === name);
    if (sortColumn == null) return items;

    const [sortExcludedData, sortIncludedData] = partition(
      items,
      (item) => sortColumn.sortExcludeBy?.(item) ?? false
    );

    return [
      ...orderBy(sortIncludedData, sortColumn.keyExtractor ?? sortColumn.render, sortDirection),
      ...sortExcludedData,
    ];
  }, [items, columns, sortColumnName, sortDirection]);

  return { sortedItems, sortColumnName, sortDirection, setSortColumnName, setSortDirection };
}

export default useTableSorting;
