import React, { FunctionComponent } from 'react';
import JSONTree from 'react-json-tree';
import { startCase } from 'lodash';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { jsonTreeTheme } from 'application/modules/debug-cattle/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailPageJson: {
      color: theme.palette.text.primary,
      display: 'block',
    },
  })
);

type SettingsTreeProps = {
  expanded: boolean;
  data: { [key: string]: any };
};
const SettingsTree: FunctionComponent<SettingsTreeProps> = ({ expanded, data }) => {
  const classes = useStyles();

  return (
    <JSONTree
      data={data}
      theme={jsonTreeTheme}
      getItemString={() => <span />}
      labelRenderer={(raw) => (
        <span
          className={classes.detailPageJson}
          style={{
            margin: raw.length > 1 ? '3px 0px 0px 0px' : '7px 0px 0px 0px',
            fontWeight: raw.length > 1 ? 'normal' : 'bold',
          }}
        >
          {`${startCase(raw[0])}:`}
        </span>
      )}
      valueRenderer={(raw) => <span>{raw}</span>}
      shouldExpandNode={() => expanded}
      hideRoot
    />
  );
};

export default SettingsTree;
