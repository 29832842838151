import { createAsyncThunk } from '@reduxjs/toolkit';
import { flatten, isEmpty, uniq, chunk, keyBy, pick, omit } from 'lodash';

import { ICattleDTO, IMobDTO } from '@halter-corp/cattle-service-client';
import { IDeviceDTO } from '@halter-corp/device-service-client';

import { Device, DeviceStats } from 'data/device';
import {
  getSettingsV2Detail,
  SettingsV2Detail,
  CommandSyncDetails,
  getMapFeaturesDetail,
} from 'services/device-detail-page.service';
import HttpApiService from 'services/http-api.service';

import {
  ICowtrollerDeviceStateSummaryDTO,
  ICowtrollerSyncStatsDTO,
  IDevicePayloadStatusEnum,
} from '@halter-corp/cowtroller-service-client';
import { ICattleDeviceSettingsDTO } from '@halter-corp/settings-service-client';
import { buildSerialNumberToDeviceStatsMap } from './device-stats-util';

const deviceEffects = {
  fetch: createAsyncThunk(
    'devices/fetch',
    async (
      props: {
        serialNumbers?: string[];
        searchMode?: 'farm' | 'device';
        mobId?: string;
        loadSettings?: boolean;
      } = {}
    ): Promise<Device[]> => {
      const registryApi = await HttpApiService.getDeviceRegistryApi();
      const settingsServiceApi = await HttpApiService.getSettingsApi();
      const cowtrollerDevicePayloadApi = await HttpApiService.getCowtrollerDevicePayloadApi();
      const cowtrollerDeviceApi = await HttpApiService.getCowtrollerDeviceApi();
      const deviceStatsApi = await HttpApiService.getDeviceStatsApi();

      const allDevices =
        props.searchMode !== 'device'
          ? (await registryApi.findAllDevices(props.serialNumbers)).data
          : await Promise.all(
              (props.serialNumbers ?? []).map(async (serialNumber) => {
                const { data: device } = await registryApi.findBySerialNumber(serialNumber);
                return device;
              })
            );

      const devices = props.mobId ? allDevices.filter((device) => device.mobId === props.mobId) : allDevices;
      const devicesWithoutMulticastDevice = devices
        .filter((device) => device.serialNumber !== device.farmId)
        .map((device) =>
          omit(
            device,
            'appEui',
            'appKey',
            'deviceEui',
            'iotCertificateArn',
            'iotThingId',
            'mac',
            'multicastGroupId',
            'firstTaggedOnDate',
            'lastTaggedOffDate',
            'lastTaggedOnDate',
            'updatedDate',
            'createdDate'
          )
        );
      const serialNumbers = devicesWithoutMulticastDevice.map(({ serialNumber }) => serialNumber);
      if (isEmpty(devicesWithoutMulticastDevice)) return [];

      const getDeviceStatsBySerialNumbers = async () => {
        const chunkedSerialNumbers = chunk(serialNumbers, 500);
        const deviceStats = await Promise.all(
          chunkedSerialNumbers.map(async (s) => {
            const { data: ds } = await deviceStatsApi.findStatsBySerialNumbers(s);
            return ds;
          })
        );
        return flatten(deviceStats);
      };

      const getDevicePayloadSummaries = async () => {
        const { data: devicePayloadSummaries } = await cowtrollerDevicePayloadApi.getDevicePayloadSummaries();
        return keyBy(devicePayloadSummaries, ({ serialNumber }) => serialNumber);
      };

      const getDevicePayloadsStatus = (counts?: ICowtrollerSyncStatsDTO) => {
        if (counts == null) {
          return undefined;
        }
        if (counts.syncingCount > 0) {
          return IDevicePayloadStatusEnum.SYNCING;
        }
        if (counts.failedToSyncCount > 0) {
          return IDevicePayloadStatusEnum.FAILED_TO_SYNC;
        }

        return IDevicePayloadStatusEnum.SYNCED;
      };

      const getSettingsDetail = (
        settingsData: ICattleDeviceSettingsDTO | undefined,
        counts: ICowtrollerSyncStatsDTO
      ): SettingsV2Detail | undefined => {
        const status = getDevicePayloadsStatus(counts);

        return {
          settingsObject: settingsData?.settings,
          status,
        };
      };

      const getDeviceCommandSummmaries = async () => {
        const { data: deviceCommands } = await cowtrollerDeviceApi.getDeviceStateSummaries();
        return keyBy(deviceCommands, ({ serialNumber }) => serialNumber);
      };

      const getCommandsDetails = (
        deviceCommandSummary: ICowtrollerDeviceStateSummaryDTO
      ): CommandSyncDetails | undefined => {
        const { totalCount, syncingCount, failedToSyncCount } = deviceCommandSummary.commands;
        let status = 'SYNCED';
        if (failedToSyncCount > 0) status = 'FAILED_TO_SYNC';
        if (syncingCount > 0) status = 'SYNCING';
        return {
          syncingCount,
          totalCount,
          failedToSyncCount,
          status,
          syncedCount: totalCount - failedToSyncCount - syncingCount,
        };
      };

      const getSettings = async () => {
        if (props.loadSettings != null && !props.loadSettings) {
          return [];
        }
        const serialNumbersChunks = chunk(serialNumbers, 400);
        const allSettingsAxiosResponses = await Promise.all(
          serialNumbersChunks.map(async (serialNumbersChunk) =>
            settingsServiceApi.getSettings(serialNumbersChunk)
          )
        );
        const cattleDeviceSettingsResponses = allSettingsAxiosResponses.map(({ data }) => data);
        return flatten(cattleDeviceSettingsResponses);
      };

      const farmIds: string[] = uniq(
        devices.reduce<string[]>((taggedToFarmIds, { farmId }) => {
          if (farmId != null) return [...taggedToFarmIds, farmId];
          return taggedToFarmIds;
        }, [])
      );

      const cattleForEachFarm = await Promise.all(
        farmIds.map(async (farmId) => {
          const cattleApi = await HttpApiService.getCattleApi(farmId);
          const { data: cattle } = await cattleApi.findAllCattle();

          return cattle;
        })
      );
      const cattles = flatten(cattleForEachFarm);

      const getMobsForEachFarm = async () =>
        Promise.all(
          farmIds.map(async (farmId) => {
            const mobApi = await HttpApiService.getMobApi(farmId);
            const { data: mob } = await mobApi.findAll();
            return mob;
          })
        );

      const getCollarAlarmsByFarmId = async () =>
        Promise.all(
          farmIds.map(async (farmId) => {
            const collarActiveAlarmsApi = await HttpApiService.getCollarActiveAlarmsApi();
            const { data: collarAlarms } =
              await collarActiveAlarmsApi.getAllActionRequiredActiveCollarAlarmsByFarmID(farmId);
            return collarAlarms ?? [];
          })
        );

      const [
        allDeviceStats,
        devicePayloadSummaries,
        mobsForEachFarm,
        deviceCommandSummaries,
        cattleDeviceSettings,
        collarAlarmsForEachFarm,
      ] = await Promise.all([
        getDeviceStatsBySerialNumbers(),
        getDevicePayloadSummaries(),
        getMobsForEachFarm(),
        getDeviceCommandSummmaries(),
        getSettings(),
        getCollarAlarmsByFarmId(),
      ]);

      const mobs = flatten(mobsForEachFarm);
      const collarAlarms = flatten(collarAlarmsForEachFarm);
      const serialNumberToDeviceStatsMap = buildSerialNumberToDeviceStatsMap(allDeviceStats);
      const cattleDeviceSettingsMap = keyBy(cattleDeviceSettings, ({ serialNumber }) => serialNumber!);
      const alarmsBySerialNumber = keyBy(collarAlarms, 'serialNumber');

      return devicesWithoutMulticastDevice.map((device) => {
        const cattle = cattles.find((c) => c.id === device.cattleId);
        const mob = mobs.find((m) => m.id === device.mobId);
        const devicePayloadSummary = devicePayloadSummaries[device.serialNumber];
        const deviceCommandSummary = deviceCommandSummaries[device.serialNumber];
        const stats = serialNumberToDeviceStatsMap.get(device.serialNumber);
        const settingsV2 = cattleDeviceSettingsMap[device.serialNumber];
        const alarms = alarmsBySerialNumber[device.serialNumber]?.alarms ?? undefined;

        const commands = deviceCommandSummary == null ? undefined : getCommandsDetails(deviceCommandSummary);
        return {
          ...device,
          cattle,
          settingsV2: getSettingsDetail(settingsV2, devicePayloadSummary?.settings),
          mapFeatures: {
            ...devicePayloadSummary?.mapFeatures,
            syncedCount:
              (devicePayloadSummary?.mapFeatures.totalCount ?? 0) -
              (devicePayloadSummary?.mapFeatures.syncingCount ?? 0) -
              (devicePayloadSummary?.mapFeatures.failedToSyncCount ?? 0),
            status: getDevicePayloadsStatus(devicePayloadSummary?.mapFeatures),
          },
          stats:
            stats != null
              ? pick(
                  stats,
                  'batteryVoltage',
                  'batteryPercentage',
                  'builtInTestStatus',
                  'loraCheckinStatus',
                  'wifiCheckinStatus',
                  'lastCommandStatus'
                )
              : undefined,
          mobName: mob?.name,
          commands,
          alarms,
        };
      });
    }
  ),

  fetchBySerialNumber: createAsyncThunk(
    'devices/fetchBySerialNumber',
    async (props: { serialNumber: string }): Promise<Device> => {
      const registryApi = await HttpApiService.getDeviceRegistryApi();
      const deviceStatsApi = await HttpApiService.getDeviceStatsApi();

      const { data: device } = await registryApi.findBySerialNumber(props.serialNumber);
      const { data: stats } = await deviceStatsApi.findStats(props.serialNumber);

      const settingsServiceApi = await HttpApiService.getSettingsApi(device.farmId);
      const cowtrollerDevicePayloadApi = await HttpApiService.getCowtrollerDevicePayloadApi(device.farmId);
      const { data: cattleDeviceSettings } = await settingsServiceApi.getSettings([device.serialNumber]);
      const { data: devicePayloads } = await cowtrollerDevicePayloadApi.getDevicePayloadBySerialNumber(
        device.serialNumber
      );

      let mobs: IMobDTO[] = [];
      let cattle: ICattleDTO | undefined;
      if (device.farmId != null) {
        const mobApi = await HttpApiService.getMobApi(device.farmId);
        const { data: mobsForFarm } = await mobApi.findAll();
        mobs = mobsForFarm;
        if (device.cattleId != null) {
          const cattleApi = await HttpApiService.getCattleApi(device.farmId);
          const { data } = await cattleApi.findCattleById(device.cattleId);
          cattle = data;
        }
      }

      const mob = mobs.find((m) => m.id === device.mobId);
      const settingsV2 = cattleDeviceSettings.find((s) => s.serialNumber === device.serialNumber);

      let settingsV2Detail: SettingsV2Detail | undefined;
      if (settingsV2 != null) {
        settingsV2Detail = getSettingsV2Detail(settingsV2, devicePayloads);
      }

      return {
        ...device,
        cattle,
        settingsV2: settingsV2Detail,
        mapFeatures: getMapFeaturesDetail(devicePayloads),
        mobName: mob?.name,
        stats: stats as DeviceStats,
      };
    }
  ),

  preProvisionDevicesToFarm: createAsyncThunk(
    'devices/preProvisionToFarm',
    async (
      props: { devices: Pick<IDeviceDTO, 'serialNumber' | 'version'>[]; farmId: string },
      { dispatch }
    ): Promise<void> => {
      const api = await HttpApiService.getDeviceRegistryApi();

      await (Promise as any).allSettled(
        props.devices.map(async ({ serialNumber, version }) => {
          await api.preProvisionToFarm(serialNumber, { version, farmId: props.farmId });
          await dispatch(deviceEffects.fetchBySerialNumber({ serialNumber }));
        })
      );
    }
  ),

  removeFarmPreProvisioningFromDevices: createAsyncThunk(
    'devices/removePreProvisioningFromFarm',
    async (
      props: { devices: Pick<IDeviceDTO, 'serialNumber' | 'version'>[] },
      { dispatch }
    ): Promise<void> => {
      const api = await HttpApiService.getDeviceRegistryApi();

      await (Promise as any).allSettled(
        props.devices.map(async ({ serialNumber, version }) => {
          await api.removeFarmPreProvisioning(serialNumber, { version });
          await dispatch(deviceEffects.fetchBySerialNumber({ serialNumber }));
        })
      );
    }
  ),

  updateDevice: createAsyncThunk(
    'devices/updateDevice',
    async (props: { devices: Pick<IDeviceDTO, 'serialNumber'>[] }, { dispatch }): Promise<void> => {
      await (Promise as any).allSettled(
        props.devices.map(async ({ serialNumber }) => {
          await dispatch(deviceEffects.fetchBySerialNumber({ serialNumber }));
        })
      );
    }
  ),

  fetchDevices: createAsyncThunk('fetch/devices', async (serialNumbers: string[]): Promise<IDeviceDTO[]> => {
    const deviceRegistryApi = await HttpApiService.getDeviceRegistryApi();
    const serialNumbersChunks = chunk(serialNumbers, 100);
    const response = await Promise.all(
      serialNumbersChunks.map(async (chunked) => deviceRegistryApi.findAllDevices(chunked))
    );
    const devices = flatten(response.map((r) => r.data));
    return devices;
  }),
};

export default deviceEffects;
