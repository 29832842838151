import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';

type TableTabProps = {
  text: string;
  selected: boolean;
  onPress: () => void;
  styleProps?: React.CSSProperties;
};

const Tab: FunctionComponent<TableTabProps> = ({ text, selected, onPress, styleProps }) => (
  <Button
    onClick={onPress}
    style={{
      borderRadius: 0,
      padding: 8,
      margin: 4,
      ...(selected ? { borderBottom: '2px solid #009e60' } : { color: 'rgba(0, 0, 0, 0.5)' }),
      ...styleProps,
    }}
  >
    {text}
  </Button>
);

export default Tab;
