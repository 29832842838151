import { HistoryEventTypeEnum } from '@halter-corp/timeline-service-client';
import { SearchResultTypeEnum } from '@halter-corp/bff-debug-tool-service-client';

const EVENT_TYPE_TO_COLOR: Record<string, string> = {
  [HistoryEventTypeEnum.BreakFenceEvent]: 'green',
  [HistoryEventTypeEnum.DeviceCommandEvent]: 'blue',
};

export const getColorForEventType = (eventType: string) => EVENT_TYPE_TO_COLOR[eventType] ?? '#ccc';

export const getColorForMetric = () => '#f25555';

export const getColorForItem = (): string => '#f25555';

export const getColorForField = (props: { itemType: SearchResultTypeEnum; name: string }) => {
  if (props.itemType === SearchResultTypeEnum.DEVICEMETRIC) return getColorForMetric();
  return getColorForEventType(props.name);
};

export const jsonTreeTheme = {
  base00: '#181818',
  base01: '#282828',
  base02: '#383838',
  base03: '#585858',
  base04: '#b8b8b8',
  base05: '#d8d8d8',
  base06: '#e8e8e8',
  base07: '#f8f8f8',
  base08: '#ab4642',
  base09: '#dc9656',
  base0A: '#f7ca88',
  base0B: '#a1b56c',
  base0C: '#86c1b9',
  base0D: '#7cafc2',
  base0E: '#ba8baf',
  base0F: '#a16946',
};
