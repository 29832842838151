import { createSlice } from '@reduxjs/toolkit';

import { reset } from 'store/actions';
import {
  IDeviceContextDTO,
  IDeviceSettingsDTO,
  IDeviceStatusDTO,
  ILoraCoverageHeatMapCellDTO,
  ILoraCoverageSelectionDTO,
  INetworkDeviceContextDTO,
  ITowerDTO,
  ITowerNoteDTO,
} from '@halter-corp/tower-service-client';
import towerEffects from 'store/effects/tower.effects';
import { reject, uniqBy } from 'lodash';

type TowerState = {
  towers: ITowerDTO[];
  latLngTowers: ITowerDTO[];
  notes: ITowerNoteDTO[];
  deviceContext: IDeviceContextDTO[];
  networkDeviceContext: INetworkDeviceContextDTO[];
  deviceStatus: IDeviceStatusDTO[];
  deviceSettings: IDeviceSettingsDTO[];
  loraCoverageSelections: ILoraCoverageSelectionDTO[];
  loraCoverageHeatMapCells: ILoraCoverageHeatMapCellDTO[];
};

const initialState: TowerState = {
  towers: [],
  latLngTowers: [],
  notes: [],
  deviceContext: [],
  networkDeviceContext: [],
  deviceStatus: [],
  deviceSettings: [],
  loraCoverageSelections: [],
  loraCoverageHeatMapCells: [],
};

const towerSlice = createSlice({
  initialState,
  name: 'tower',
  reducers: {
    clear: () => initialState,
    resetDevices: (state): TowerState => ({ ...state, deviceContext: [], deviceStatus: [] }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(towerEffects.fetchAllTowers.fulfilled, (state, action) => {
        state.towers = action.payload;
      })
      .addCase(towerEffects.saveTower.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload == null) {
          return;
        }
        state.towers = [...state.towers, payload];
      })
      .addCase(towerEffects.updateTowerById.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload == null) {
          return;
        }
        state.towers = state.towers.map((tower) => (tower.id === payload.id ? payload : tower));
      })
      .addCase(towerEffects.deleteTowerById.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload == null) {
          return;
        }
        state.towers = state.towers.filter((tower) => tower.id !== payload);
      })
      .addCase(towerEffects.fetchTowersByFarmId.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload == null) {
          return;
        }
        state.towers = payload;
      })
      .addCase(towerEffects.saveTowerNote.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload == null) {
          return;
        }
        state.notes = [...state.notes, payload];
      })
      .addCase(towerEffects.fetchNotesByTowers.fulfilled, (state, action) => {
        state.notes = uniqBy(action.payload, 'createdAt');
      })
      .addCase(towerEffects.fetchNotesByTowerId.fulfilled, (state, action) => {
        state.notes = uniqBy(action.payload, 'createdAt');
      })
      .addCase(towerEffects.updateNoteByTowerId.fulfilled, (state, action) => {
        const updatedNote = action.payload;
        if (updatedNote == null) return;
        const { towerId, createdAt } = updatedNote;
        const index = state.notes.findIndex(
          (note) => note.towerId === towerId && note.createdAt === createdAt
        );
        state.notes[index] = updatedNote;
      })
      .addCase(towerEffects.deleteNoteByTowerId.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload == null) return;

        const { towerId, createdAt } = payload;
        state.notes = reject(state.notes, (note) => note.towerId === towerId && note.createdAt === createdAt);
      })
      .addCase(towerEffects.fetchAllDeviceContextById.fulfilled, (state, action) => {
        state.deviceContext = uniqBy(action.payload, 'id');
      })
      .addCase(towerEffects.fetchAllDeviceContextByFarmId.fulfilled, (state, action) => {
        state.deviceContext = uniqBy(action.payload, 'id');
      })
      .addCase(towerEffects.fetchAllDeviceContextByFarmAndTowers.fulfilled, (state, action) => {
        state.deviceContext = uniqBy(action.payload, 'id');
      })
      .addCase(towerEffects.fetchAllDeviceContextByShardId.fulfilled, (state, action) => {
        state.deviceContext = uniqBy(action.payload, 'id');
      })
      .addCase(towerEffects.fetchAllNetworkDeviceContextByTowerIdList.fulfilled, (state, action) => {
        state.networkDeviceContext = action.payload;
      })
      .addCase(towerEffects.updateDeviceContextByIdAndFarmId.fulfilled, (state, action) => {
        const updatedDeviceContext = action.payload;
        if (updatedDeviceContext == null) return;
        const { id, farmId } = updatedDeviceContext;
        const index = state.deviceContext.findIndex(
          (deviceContext) => deviceContext.id === id && deviceContext.farmId === farmId
        );
        state.deviceContext[index] = updatedDeviceContext;
      })
      .addCase(towerEffects.fetchDeviceStatusByDevices.fulfilled, (state, action) => {
        if (action.payload == null) {
          return;
        }
        const statuses = action.payload;
        state.deviceStatus = uniqBy(statuses, 'id');
      })
      .addCase(towerEffects.fetchDeviceStatusById.fulfilled, (state, action) => {
        if (action.payload == null) {
          return;
        }
        const index = state.deviceStatus.findIndex((deviceStatus) => deviceStatus.id === action.payload?.id);
        if (index === -1) {
          state.deviceStatus.push(action.payload);
        } else {
          state.deviceStatus[index] = action.payload;
        }
      })
      .addCase(towerEffects.updateDeviceStatusById.fulfilled, (state, action) => {
        const updatedDeviceStatus = action.payload;
        if (updatedDeviceStatus == null) return;

        const index = state.deviceStatus.findIndex(
          (deviceStatus) => deviceStatus.id === updatedDeviceStatus.id
        );
        state.deviceStatus[index] = updatedDeviceStatus;
      })
      .addCase(towerEffects.fetchDeviceSettingsByIds.fulfilled, (state, action) => {
        if (action.payload == null) {
          return;
        }
        const settings = action.payload;
        state.deviceSettings = uniqBy(settings, 'id');
      })
      .addCase(towerEffects.fetchDeviceSettingsById.fulfilled, (state, action) => {
        if (action.payload == null) {
          return;
        }
        const index = state.deviceStatus.findIndex(
          (deviceSettings) => deviceSettings.id === action.payload?.id
        );
        if (index === -1) {
          state.deviceSettings.push(action.payload);
        } else {
          state.deviceSettings[index] = action.payload;
        }
      })
      .addCase(towerEffects.updateDeviceSettingsById.fulfilled, (state, action) => {
        const updatedDeviceSettings = action.payload;
        if (updatedDeviceSettings == null) return;

        const index = state.deviceSettings.findIndex(
          (deviceStatus) => deviceStatus.id === updatedDeviceSettings.id
        );
        state.deviceSettings[index] = updatedDeviceSettings;
      })
      .addCase(towerEffects.fetchSelectionByGatewayIds.fulfilled, (state, action) => {
        state.loraCoverageSelections = action.payload;
      })
      .addCase(towerEffects.fetchAllHeatMapsByGateways.fulfilled, (state, action) => {
        state.loraCoverageHeatMapCells = action.payload;
      })
      .addCase(towerEffects.fetchAllTowersByRadiusAndLatLng.fulfilled, (state, action) => {
        state.latLngTowers = action.payload;
      }),
});

export default towerSlice;
