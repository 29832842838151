import React, { FunctionComponent, useCallback, useState } from 'react';
import { Grid, Modal, Typography, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeviceService from 'services/device.service';
import { IDeviceStatusDTO, IDeviceSwapRequestedDTO } from '@halter-corp/device-service-client';
import TaskService from 'services/task.service';
import { ICreateTasksRequestDTO, TaskTypeEnum } from '@halter-corp/task-service-client';
import { useSelector } from 'store';
import { getCurrentFarm } from 'store/selectors/farm-context.selectors';
import RequestSwapFormPanel from './request-swap-form.panel';
import RequestSwapProgress from './request-swap-progress.panel';

const ModalBody = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  margin: 16px;
`;

type RequestSwapModalProps = {
  user: string;
};

const RequestSwapModal: FunctionComponent<RequestSwapModalProps> = ({ user }) => {
  const [open, setOpen] = useState(false);

  const [swapsSent, setSwapsSent] = useState(0);
  const [failedSerialNumbers, setFailedSerialNumbers] = useState<string[]>([]);
  const [totalSwaps, setTotalSwaps] = useState(0);

  const [mode, setMode] = useState<'create' | 'progress'>('create');

  const currentFarmId = useSelector(getCurrentFarm);

  const handleClickOpen = () => {
    setMode('create');
    setOpen(true);
    setSwapsSent(0);
    setFailedSerialNumbers([]);
  };

  const handleClose = () => {
    setOpen(false);
    if (swapsSent > 0) window.location.reload();
  };

  const setDeviceSwapRequested = useCallback(
    async (serialNumber: string, reason: string) => {
      const swapRequest: IDeviceSwapRequestedDTO = {
        reason,
        createdBy: user,
        createdDate: new Date().toISOString(),
      };
      const deviceStatusUpdate: IDeviceStatusDTO = {
        swapRequested: reason.startsWith('Remove Request') ? null : swapRequest,
      };
      if (await DeviceService.setDeviceStatus(serialNumber, deviceStatusUpdate)) {
        setSwapsSent((sent) => sent + 1);
      } else {
        setFailedSerialNumbers((failed) => [...failed, serialNumber]);
      }
    },
    [user]
  );

  const setSwapRequestedInTaskService = async (farmId: string, serialNumbers: string[]) => {
    const createTaskRequest: ICreateTasksRequestDTO = {
      type: TaskTypeEnum.TAKE_OFF_COW,
      data: { serialNumbers },
    };
    await TaskService.createTasks(farmId, createTaskRequest);
  };

  const cancelSwapRequestInTaskService = async (farmId: string, serialNumbers: string[]) => {
    const openTasks = await TaskService.findOpenTasks(farmId);
    const tasksToCancel = openTasks.filter(
      (task) => task.type === TaskTypeEnum.TAKE_OFF_COW && serialNumbers.includes(task.data.serialNumber)
    );
    await Promise.all(
      tasksToCancel.map(async (task) => {
        await TaskService.cancelTask(farmId, task.id);
      })
    );
  };

  const handleCreate = useCallback(
    async (serialNumbers: string[], reason: string, comments?: string) => {
      setMode('progress');
      setTotalSwaps(serialNumbers.length);
      if (reason === "Create 'take off cow' task (new)") {
        await setSwapRequestedInTaskService(currentFarmId, serialNumbers);
        setSwapsSent(serialNumbers.length);
      } else if (reason === "Cancel 'take off cow' task (new)") {
        await cancelSwapRequestInTaskService(currentFarmId, serialNumbers);
        setSwapsSent(serialNumbers.length);
      } else {
        reason.replace(' (old)', '');
        const reasonAndComment = reason.replace(' (old)', '') + (comments ? ` - ${comments}` : '');
        serialNumbers.map(async (serialNumber) => {
          await setDeviceSwapRequested(serialNumber.trim(), reasonAndComment);
        });
      }
    },
    [currentFarmId, setDeviceSwapRequested]
  );

  return (
    <>
      <IconButton onClick={handleClickOpen} style={{ color: 'black' }}>
        <AddIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          outline: 'none',
          ...{ marginLeft: '25%', marginRight: '25%' },
        }}
      >
        <ModalBody>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>Request Collar Swap</Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <IconButton onClick={handleClose} style={{ color: 'black' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
            {mode === 'create' ? (
              <RequestSwapFormPanel handleClose={handleClose} handleCreate={handleCreate} />
            ) : (
              <RequestSwapProgress
                totalSwaps={totalSwaps}
                swapsSent={swapsSent}
                failedSerialNumbers={failedSerialNumbers}
              />
            )}
          </Grid>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RequestSwapModal;
