import React, { FunctionComponent, useMemo } from 'react';
import { GeoJSON } from 'react-leaflet';
import JSONTree from 'react-json-tree';
import ReactDOMServer from 'react-dom/server';
import { useLeafletZoom } from 'use-leaflet';

import {
  HistoryDeviceCommandStatusEnum,
  CommandTypeEnum,
  HistoryEventTypeEnum,
} from '@halter-corp/timeline-service-client';

import { DeviceCommandFeature2 } from 'store/effects/debug-cattle.effects';
import { getColorForEventType, jsonTreeTheme } from '../../../utils';

export type CommandsProps = {
  commandFeatures: DeviceCommandFeature2[];
};

const defaultSetZoneColor = getColorForEventType(HistoryEventTypeEnum.DeviceCommandEvent);

const baseFeatureStyle = {
  fillOpacity: 0,
  weight: 1,
};

const calculateZoneStyle = (command: DeviceCommandFeature2) => {
  if (command.commandType === CommandTypeEnum.SETZONE) {
    switch (command.commandStatus) {
      case HistoryDeviceCommandStatusEnum.SYNCING:
        return {
          ...baseFeatureStyle,
          color: defaultSetZoneColor,
          dashArray: '5, 5',
          dashOffset: '0',
        };
      case HistoryDeviceCommandStatusEnum.FAILEDTOSYNC:
        return {
          ...baseFeatureStyle,
          color: 'yellow',
          dashArray: '5, 5',
          dashOffset: '0',
        };
      case HistoryDeviceCommandStatusEnum.SYNCED:
        return {
          ...baseFeatureStyle,
          color: defaultSetZoneColor,
        };
      case HistoryDeviceCommandStatusEnum.ACTIVE:
        return {
          ...baseFeatureStyle,
          color: 'white',
          weight: 2.5,
        };
      default:
    }
  }
  // Drop fence (exit or clear)
  switch (command.commandStatus) {
    case HistoryDeviceCommandStatusEnum.SYNCING:
      return {
        ...baseFeatureStyle,
        color: 'red',
        dashArray: '5, 5',
        dashOffset: '0',
      };
    case HistoryDeviceCommandStatusEnum.SYNCED:
      return {
        ...baseFeatureStyle,
        color: 'red',
        weight: 2,
        // dashArray: '5, 5', // commented out to make a synced exit zone solid red
        // dashOffset: '0',
      };
    case HistoryDeviceCommandStatusEnum.ACTIVE:
      return {
        ...baseFeatureStyle,
        color: 'red',
      };
    default:
      return {
        ...baseFeatureStyle,
        color: 'gray',
        weight: 1,
      };
  }
};

const buildCommandEventPopupContent = (command: DeviceCommandFeature2) => {
  const element = (
    <JSONTree
      data={command}
      theme={jsonTreeTheme}
      shouldExpandNode={(keyPath: (string | number)[]) =>
        !(keyPath.includes('geometry') || keyPath.includes('shape'))
      }
      hideRoot
    />
  );
  return ReactDOMServer.renderToStaticMarkup(element);
};

const DeviceCommandsLayer: FunctionComponent<CommandsProps> = ({ commandFeatures }) => {
  const zoom = useLeafletZoom();
  const showCattleEvents = useMemo(() => zoom > 20, [zoom]);

  return (
    <div style={{ zIndex: 0 }}>
      {commandFeatures.map((command: DeviceCommandFeature2) => [
        command.boundary && (
          <GeoJSON
            key={`${command.historyEventId}-boundary`}
            data={command.boundary}
            style={{
              color: 'white',
              fill: false,
              weight: 1,
            }}
          />
        ),
        command.zone && (
          <GeoJSON
            key={`${command.historyEventId}-zone-${showCattleEvents}`}
            data={command.zone}
            style={calculateZoneStyle(command)}
            onEachFeature={(feature, layer) => {
              if (showCattleEvents) {
                layer.unbindPopup();
              } else {
                layer.bindPopup(buildCommandEventPopupContent(command));
              }
            }}
          />
        ),
        command.exitPoint && (
          <GeoJSON
            key={`${command.historyEventId}-exit-point`}
            data={command.exitPoint}
            style={{ color: defaultSetZoneColor }}
          />
        ),
        command.exitPath && (
          <GeoJSON
            key={`${command.historyEventId}-exit-path`}
            data={command.exitPath}
            style={{ color: 'orange', dashArray: '5, 5' }}
          />
        ),
      ])}
    </div>
  );
};

export default DeviceCommandsLayer;
