import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import { IDeviceDTO } from '@halter-corp/device-service-client';
import { environment } from 'env-config';
import ReactGA from 'react-ga';

type ResetButtonProps = {
  device: Pick<IDeviceDTO, 'serialNumber' | 'cattleName'>;
};

export const runDeckJobNumber = (env: string): string => {
  const lowerCaseEnv = env.toLowerCase();

  if (lowerCaseEnv === 'prod') {
    return '2e9c4dec-cd93-42f3-8903-888f18019f87';
  }
  if (lowerCaseEnv === 'staging') {
    return '371b8cb1-2b54-478e-ae65-220d7ecbb86e';
  }
  if (lowerCaseEnv === 'dev') {
    return 'e8f67683-8828-4aa7-94f1-2648c5201de1';
  }
  throw Error(`${env} is not a valid environment`);
};

const ResetButton: FunctionComponent<ResetButtonProps> = ({ device: { serialNumber } }) => {
  const envCapitalized = environment[0].toUpperCase() + environment.slice(1).toLowerCase();
  const envJob = runDeckJobNumber(envCapitalized);
  const sendGaEvent = () => {
    ReactGA.event({
      category: 'User action',
      action: 'Reset button pressed',
    });
  };

  return (
    <>
      <Button
        href={`https://rundeck.tools.halter.io/project/${envCapitalized}/job/show/${envJob}?argString=-serial_numbers+${serialNumber}`}
        target="_blank"
        onClick={() => sendGaEvent()}
      >
        Reset
      </Button>
    </>
  );
};

export default ResetButton;
