import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';

import { GetApplyModifiersSummaryFormValues, GetApplyProfilesSummaryFormValues } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getInitialModifiersValues = (farmId: string): GetApplyModifiersSummaryFormValues => ({
  farmId,
  mobId: undefined,
  cattleNames: [],
  trainings: {
    [TrainingSettingsTypeEnum.HOLD_IN_ZONE]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.ENTER_PADDOCK]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.EXIT_PADDOCK]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.EXIT_PADDOCK_DRAFTING]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.BREAK_TO_BREAK]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.RACE_NAVIGATION]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.HOLD_IN_ZONE_CROP]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.BREAK_TO_BREAK_CROP]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.EXIT_PADDOCK_CROP]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.SHARED_BOUNDARY_TRANSITION]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.ENTER_SHARED_BOUNDARY]: { type: 'keep-current' },
  },
});

export const getInitialProfilesValues = (farmId: string): GetApplyProfilesSummaryFormValues => ({
  farmId,
  trainings: {
    [TrainingSettingsTypeEnum.HOLD_IN_ZONE]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.ENTER_PADDOCK]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.EXIT_PADDOCK]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.EXIT_PADDOCK_DRAFTING]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.BREAK_TO_BREAK]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.RACE_NAVIGATION]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.HOLD_IN_ZONE_CROP]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.BREAK_TO_BREAK_CROP]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.EXIT_PADDOCK_CROP]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.SHARED_BOUNDARY_TRANSITION]: { type: 'keep-current' },
    [TrainingSettingsTypeEnum.ENTER_SHARED_BOUNDARY]: { type: 'keep-current' },
  },
});
