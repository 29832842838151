import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty, entries } from 'lodash';

import {
  IApplyProfilesRequestDTO,
  IAppliedTrainingProfileDTO,
  ITrainingProfileDTO,
  TrainingSettingsTypeEnum,
} from '@halter-corp/training-service-client';

import { useDispatch } from 'store';
import trainingEffects from 'store/effects/training.effects';
import { ApplyProfileOption } from 'data/training';
import TrainingService from 'services/training.service';

import Wrapper from 'application/components/wrapper';
import Button from 'application/components/button';

import styled from 'styled-components';
import { ApplyProfiles } from '../../forms/apply-profiles.form/types';
import SummaryProfile from '../../components/summary/profile-summary';

type ApplySummaryContainerProps = {
  farmId: string;
  profilesToBeApplied: ApplyProfiles;
};

const ApplyProfilesContainer: FunctionComponent<ApplySummaryContainerProps> = ({
  farmId,
  profilesToBeApplied,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = TrainingService.useIsLoading();

  const profilesToApply: IAppliedTrainingProfileDTO[] = useMemo(() => {
    const getProfileAfterApply = (apply: ApplyProfileOption, currentProfile?: ITrainingProfileDTO) => {
      if (apply.type === 'remove') return undefined;
      if (apply.type === 'keep-current') return currentProfile;
      return apply.profile;
    };

    const changedTrainings = entries(profilesToBeApplied).filter(
      ([, applyProfilesOption]) => applyProfilesOption.type !== 'keep-current'
    );
    const profilesAfterApply: IAppliedTrainingProfileDTO[] = [];

    changedTrainings.forEach(([type, applyProfileOption]) => {
      profilesAfterApply.push({
        farmId,
        type: type as TrainingSettingsTypeEnum,
        profile: getProfileAfterApply(applyProfileOption),
      });
    });
    return profilesAfterApply;
  }, [farmId, profilesToBeApplied]);

  const onApply = async () => {
    const toApplyDto = (appliedTrainingProfile: Pick<IAppliedTrainingProfileDTO, 'profile' | 'type'>) => ({
      type: appliedTrainingProfile.type,
      profileId: appliedTrainingProfile.profile?.id,
    });

    const request: IApplyProfilesRequestDTO = {
      farmId,
      profiles: profilesToApply.map(toApplyDto),
    };

    const result = await dispatch(trainingEffects.applyProfiles({ request }));

    if (trainingEffects.applyProfiles.fulfilled.match(result)) {
      history.push('/training');
    }
  };

  return (
    <ContainerWrapper>
      {!isLoading && (
        <>
          <SummaryProfile profileToApply={profilesToBeApplied} />
          {!isEmpty(profilesToApply) && (
            <ButtonWrapper>
              <Button text="Apply" onClick={() => onApply()} style={{ margin: 4 }} />
            </ButtonWrapper>
          )}
        </>
      )}
    </ContainerWrapper>
  );
};

const ContainerWrapper = styled(Wrapper)`
  padding: 16px;
`;

const ButtonWrapper = styled(Wrapper)`
  flex: 1px;
  align-items: center;
  padding: 8px;
`;

export default ApplyProfilesContainer;
