import React, { FunctionComponent } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

type SimpleSnackBarProps = {
  message: string;
  open: boolean;
};

const SimpleSnackbar: FunctionComponent<SimpleSnackBarProps> = ({ message, open }) => (
  <Snackbar
    open={open}
    autoHideDuration={3000}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Alert severity={message === 'Success' ? 'success' : 'error'}>{message}</Alert>
  </Snackbar>
);
export default SimpleSnackbar;
