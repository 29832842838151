import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { reset } from 'store/actions';
import { IDeviceDTO, IFarmDTO, IFarmMetadataDTO, IShardDTO } from '@halter-corp/farm-infra-service-client';
import farmInfraEffects from 'store/effects/farm-infra.effects';
import { sortBy, uniqBy } from 'lodash';

type FarmInfraState = {
  devices: IDeviceDTO[];
  shards: IShardDTO[];
  farms: IFarmDTO[];
  networkGroups: string[];
  currentFarm: string | undefined;
  currentFarmMetadata: IFarmMetadataDTO | null;
  currentShard: string | undefined;
};

const initialState: FarmInfraState = {
  devices: [],
  shards: [],
  farms: [],
  networkGroups: [],
  currentFarm: undefined,
  currentShard: undefined,
  currentFarmMetadata: null,
};

const farmInfraSlice = createSlice({
  initialState,
  name: 'farm-infrastructure',
  reducers: {
    clear: () => initialState,
    resetDevices: (state) => ({ ...state, devices: [], metadata: [] }),

    setCurrentFarm: (state, action: PayloadAction<{ currentFarm?: string }>) => {
      state.currentFarm = action.payload.currentFarm;
      window.localStorage.setItem('currentFarm', state.currentFarm || '');
    },

    setCurrentShard: (state, action: PayloadAction<{ currentShard?: string }>) => {
      state.currentShard = action.payload.currentShard;
      window.localStorage.setItem('currentShard', state.currentShard || '');
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(farmInfraEffects.fetchDevicesByFarmId.fulfilled, (state, action) => {
        state.devices = uniqBy(action.payload, 'id');
      })
      .addCase(farmInfraEffects.fetchDevicesByShardId.fulfilled, (state, action) => {
        state.devices = uniqBy(action.payload, 'id');
      })
      .addCase(farmInfraEffects.fetchFarmMetadataById.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload == null) return;
        state.currentFarmMetadata = payload;
      })
      .addCase(farmInfraEffects.fetchShards.fulfilled, (state, action) => {
        state.shards = sortBy(action.payload, 'id');
      })
      .addCase(farmInfraEffects.fetchFarms.fulfilled, (state, action) => {
        state.farms = sortBy(action.payload, (farm) => farm.name.toLowerCase());
      })
      .addCase(farmInfraEffects.saveNetworkGroupFarmMapping.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload == null) {
          return;
        }
        state.networkGroups = [...state.networkGroups, payload.networkGroupId];
      })
      .addCase(farmInfraEffects.fetchNetworkGroupsByFarmId.fulfilled, (state, action) => {
        state.networkGroups = uniqBy(action.payload, (networkGroup) => networkGroup);
      })
      .addCase(farmInfraEffects.updateDeviceById.fulfilled, (state, action) => {
        const updatedDevice = action.payload;
        if (updatedDevice == null) return;

        const index = state.devices.findIndex((device) => device.id === updatedDevice.id);
        state.devices[index] = updatedDevice;
      }),
});

export default farmInfraSlice;
