import React, { FunctionComponent } from 'react';
import { LinearProgress } from '@material-ui/core';

type LoadingBarProps = {
  loading?: boolean;
};

const LoadingBar: FunctionComponent<LoadingBarProps> = ({ loading = false }) => (
  <LinearProgress variant={loading ? 'query' : 'determinate'} value={100} />
);

export default LoadingBar;
