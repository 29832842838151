import { ICreateTasksRequestDTO, ITaskDTO } from '@halter-corp/task-service-client';
import HttpApiService from './http-api.service';

const TaskService = {
  createTasks: async (farmId: string, createTaskRequest: ICreateTasksRequestDTO) => {
    const api = await HttpApiService.getTaskApi(farmId);
    await api.createTasks(createTaskRequest);
  },

  findOpenTasks: async (farmId: string): Promise<ITaskDTO[]> => {
    const api = await HttpApiService.getTaskApi(farmId);
    const { data: tasks } = await api.findOpenTasks();
    return tasks;
  },

  cancelTask: async (farmId: string, taskId: string) => {
    const api = await HttpApiService.getTaskApi(farmId);
    await api.cancelTask(taskId);
  },
};

export default TaskService;
