import { createSlice } from '@reduxjs/toolkit';
import { IFarmGrazingHeatmapMetricDTO } from '@halter-corp/cow-location-service-client';
import { reset } from '../actions';
import grazingHeatmapEffects from '../effects/grazing-heatmap.effects';

type GrazingHeatmapContextInitialState = {
  loading: boolean;
  metricsForAllFarms: IFarmGrazingHeatmapMetricDTO[];
};

const initialState: GrazingHeatmapContextInitialState = {
  loading: false,
  metricsForAllFarms: [],
};

const grazingHeatmapSlice = createSlice({
  initialState,
  name: 'grazing-heatmap',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(grazingHeatmapEffects.fetchGrazingHeatmapMetricForAllFarms.fulfilled, (state, action) => {
        state.metricsForAllFarms = action.payload;
        state.loading = false;
      })
      .addCase(grazingHeatmapEffects.fetchGrazingHeatmapMetricForAllFarms.pending, (state) => {
        state.loading = true;
      }),
});

export default grazingHeatmapSlice;
