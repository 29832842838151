import { createAsyncThunk } from '@reduxjs/toolkit';

import { ISearchResultItemDTO } from '@halter-corp/bff-debug-tool-service-client';
import { sortBy } from 'lodash';
import { Feature } from 'geojson';
import {
  HistoryDeviceCommandStatusEnum,
  CommandTypeEnum,
  HistoryEventTypeEnum,
} from '@halter-corp/timeline-service-client';
import LuceneQueryService from 'services/lucene-query.service';
import HttpApiService from 'services/http-api.service';

export type DeviceCommandFeature2 = {
  historyEventId: string;
  eventDate: number;
  zone?: Feature;
  exitPoint?: Feature;
  exitPath?: Feature;
  boundary?: Feature;
  serialNumber: string;
  cattleName: string;
  groupId: string;
  referenceId: string;
  commandId: number;
  commandType: CommandTypeEnum;
  commandStatus: HistoryDeviceCommandStatusEnum;
};

const positionDeviceMetricsDebugFields = [
  'metricName',
  'context.cattleName',
  'metric.position.latitude',
  'metric.position.longitude',
].join(',');

const historyEventsDebugFields = [
  'eventType',
  'payload.device.serialNumber',
  'payload.command.type',
  'payload.command.collarCommandId',
  'payload.command.status',
  'payload.command.referenceId',
  'payload.command.groupId',
  'payload.command.boundaryGeometry',
  'payload.command.exitPoint',
  'payload.command.exitPath',
  'payload.command.geometry',
].join(',');

const cowgEventStreamDeviceMetricsDebugFields = [
  'metricName',
  'serialNumber',
  'context',
  'metric.cowgEventStream.cowgStatus',
  'metric.cowgEventStream.locationMetadata.heading',
  'metric.cowgEventStream.locationMetadata.targetHeading',
  'metric.cowgEventStream.location.lat',
  'metric.cowgEventStream.location.lon',
  'metric.cowgEventStream.fixAge',
  'metric.cowgEventStream.eventType',
  'metric.cowgEventStream.firmwareVersion',
  'metric.cowgEventStream.pulseAttemptCount',
  'metric.cowgEventStream.pulseSuccessCount',
  'metric.cowgEventStream.peakShockConfirmCurrentAmps',
].join(',');

const fetchHistoryEvents = async (
  props: DebugCattleProps,
  paginationToken?: string
): Promise<ISearchResultItemDTO[]> => {
  const api = await HttpApiService.getBffDebugToolApi();
  const { farmId, mobIds, cattleNames, serialNumbers, timestamp } = props;
  const staticQuerySection = LuceneQueryService.generateHistoryEventsQuery({
    eventTypes: [HistoryEventTypeEnum.DeviceCommandEvent],
    farmId,
    mobIds,
    cattleNames,
    serialNumbers,
    timestamp,
  });

  const { data } = await api.getHistoryEvents(
    staticQuerySection,
    paginationToken,
    10000,
    historyEventsDebugFields
  );

  if (data.paginationToken != null) {
    const otherItems = await fetchHistoryEvents(props, data.paginationToken);
    return [...data.items, ...otherItems];
  }

  return data.items;
};

type DebugCattleProps = {
  farmId: string | undefined;
  mobIds: string[] | null;
  cattleNames: string[];
  serialNumbers: string[];
  timestamp: [Date, Date];
  fetchAllDeviceMetrics?: boolean;
};

const fetchDeviceMetrics = async (
  props: DebugCattleProps,
  metricNames: string[],
  fieldsProjection: string,
  paginationToken?: string
): Promise<ISearchResultItemDTO[]> => {
  const api = await HttpApiService.getBffDebugToolApi();

  const staticQuerySection = LuceneQueryService.generateDeviceMetricsQuery({
    ...props,
    metricNames,
  });

  const { data } = await api.getDeviceMetrics(staticQuerySection, paginationToken, 10000, fieldsProjection);

  if (data.paginationToken != null) {
    const otherItems = await fetchDeviceMetrics(props, metricNames, fieldsProjection, data.paginationToken);
    return [...data.items, ...otherItems];
  }

  return data.items;
};

const debugCattleEffects = {
  fetchHistoryEvents: createAsyncThunk(
    'debug-cattle/fetchHistoryEvents',
    async (props: DebugCattleProps): Promise<ISearchResultItemDTO[]> => {
      // Fetch data
      const items = await fetchHistoryEvents(props);

      // Sort data
      const sortedItems = sortBy(items, ['timestamp']);

      return sortedItems;
    }
  ),
  fetchPositionMetrics: createAsyncThunk(
    'debug-cattle/fetchPositionMetrics',
    async (props: DebugCattleProps): Promise<ISearchResultItemDTO[]> => {
      const items = await fetchDeviceMetrics(props, ['POSITION'], positionDeviceMetricsDebugFields);

      // Sort data
      const sortedItems = sortBy(items, ['timestamp']);

      return sortedItems;
    }
  ),
  fetchCowGEventStream: createAsyncThunk(
    'debug-cattle/fetchCowGEventStream',
    async (props: DebugCattleProps): Promise<ISearchResultItemDTO[]> => {
      const items = await fetchDeviceMetrics(
        props,
        ['COWG_EVENT_STREAM'],
        cowgEventStreamDeviceMetricsDebugFields
      );

      // Sort data
      const sortedItems = sortBy(items, ['timestamp']);

      return sortedItems;
    }
  ),
};

export default debugCattleEffects;
