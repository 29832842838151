import React, { FunctionComponent } from 'react';
import { TableContainer } from '@material-ui/core';

import { IDeviceOnFarmStatesContextDTO } from '@halter-corp/fleet-service-client';
import { collarColumn, debugColumn } from './farm-issues-columns';
import { CaddieColours } from '../../../theme/colours';
import DataTable from '../../../components/data-table';

type DeprovisionedDevicesTableProps = {
  deprovisionedDevices: IDeviceOnFarmStatesContextDTO[];
};

const DeprovisionedDevicesTable: FunctionComponent<DeprovisionedDevicesTableProps> = ({
  deprovisionedDevices,
}) => (
  <TableContainer>
    <DataTable<IDeviceOnFarmStatesContextDTO>
      data={deprovisionedDevices}
      columns={[collarColumn, debugColumn]}
      defaultSortColumnName="Cow"
      rowColour="white"
      headerColour={CaddieColours.LightGrey}
      indexColumn={false}
    />
  </TableContainer>
);

export default DeprovisionedDevicesTable;
