import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Accordion, AccordionSummary, Grid, InputAdornment, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Button from 'application/components/button';
import Wrapper from 'application/components/wrapper';
import { TrainingGuideStage } from 'data/training';

import TrainingService from 'services/training.service';
import { useDispatch } from 'store';
import trainingEffects from 'store/effects/training.effects';
import styled from 'styled-components';
import {
  BreakFenceCreationStageEnum,
  FarmerTrainingGuideTypeEnum,
  TransitionStageEnum,
} from '@halter-corp/training-service-client';

import SelectField from '../forms/components/select-field';
import { prettyPrintFarmerGuideType } from '../utils';

type FarmerGuidePanelProps = {
  farmId: string;
};

type TrainingOptions = {
  [key: string]: any;
};

type TrainingGuideStages = {
  [type: string]: {
    id?: string;
    farmId: string;
    initialStage?: TrainingGuideStage | string;
    updatedStage: TrainingGuideStage | string;
  };
};

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  opacity: 0.75;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 16px 24px 16px 24px;
`;

const FarmerGuidePanel: FunctionComponent<FarmerGuidePanelProps> = ({ farmId }) => {
  const dispatch = useDispatch();
  const trainingGuides = TrainingService.useSelectFarmerTrainingGuides();
  const trainingOptions = TrainingService.useSelectFarmerTrainingGuideOptions().find(
    (option) => option.farmId === farmId
  );
  const [trainingGuideStages, setTrainingGuideStages] = useState<TrainingGuideStages>({});
  const [trainingGuideOptions, setTrainingGuideOptions] = useState<TrainingOptions>({});
  const [farmerGuideExpanded, setFarmerGuideExpanded] = useState(false);

  const getTrainingStagesForType = (type: FarmerTrainingGuideTypeEnum): string[] => {
    switch (type) {
      case FarmerTrainingGuideTypeEnum.BREAK_FENCE_CREATION:
        return Object.values(BreakFenceCreationStageEnum);
      case FarmerTrainingGuideTypeEnum.TRANSITIONS:
        return Object.values(TransitionStageEnum);
      default:
        return [];
    }
  };

  const shouldDisplayChangedWarning = (type: FarmerTrainingGuideTypeEnum): boolean =>
    trainingGuideStages[type]?.initialStage !== trainingGuideStages[type]?.updatedStage;

  const shouldDisplayOptionsChangedWarning = (option: string): boolean =>
    trainingGuideOptions[option].toString() === (trainingOptions as any)[option].toString();

  const shouldDisableSaveButton = (): boolean => {
    let changed: boolean[] = [];
    changed = changed.concat(
      Object.values(trainingGuideStages).map(
        ({ initialStage, updatedStage }) => initialStage !== updatedStage
      )
    );
    if (trainingOptions) {
      changed = changed.concat(
        Object.keys(trainingGuideOptions).map(
          (option) => trainingGuideOptions[option].toString() !== (trainingOptions as any)[option].toString()
        )
      );
    }

    return changed.filter((c) => c).length === 0;
  };

  const shouldDisableNewButton = (): boolean => {
    const allTrainingGuideTypes = Object.values(FarmerTrainingGuideTypeEnum).sort();
    const currentTrainingGuideTypes = Object.keys(trainingGuideStages).sort();
    const hasAllTrainingGuideTypes = allTrainingGuideTypes.every(
      (value: string, index: number) => value === currentTrainingGuideTypes[index]
    );

    return hasAllTrainingGuideTypes;
  };

  const handleAddNewTrainingStages = (): void => {
    const newTrainingStages: TrainingGuideStages = {};
    Object.values(FarmerTrainingGuideTypeEnum).forEach((type) => {
      newTrainingStages[type] = {
        farmId,
        updatedStage: getTrainingStagesForType(type)[0],
      };
    });
    setTrainingGuideStages(newTrainingStages);
  };

  const handleTrainingStageChange = (type: FarmerTrainingGuideTypeEnum, stage: TrainingGuideStage): void => {
    const updatedTrainingStage = { ...trainingGuideStages };
    updatedTrainingStage[type].updatedStage = stage;
    setTrainingGuideStages(updatedTrainingStage);
  };

  const handleSaveTrainingGuides = (): void => {
    Object.keys(trainingGuideStages).forEach((type) => {
      if (trainingGuideStages[type].initialStage !== trainingGuideStages[type].updatedStage) {
        dispatch(
          trainingEffects.updateFetchFarmerTrainingGuides({
            request: {
              id: trainingGuideStages[type].id,
              farmId,
              type: type as FarmerTrainingGuideTypeEnum,
              currentStage: trainingGuideStages[type].updatedStage,
            },
          })
        );
      }
    });

    const updatedTrainingGuideOptions: TrainingOptions = {};
    Object.keys(trainingGuideOptions).forEach((option) => {
      if (trainingGuideOptions[option].toString() !== (trainingOptions as any)[option].toString()) {
        updatedTrainingGuideOptions[option] = trainingGuideOptions[option];
      }
    });
    if (Object.keys(updatedTrainingGuideOptions).length) {
      dispatch(
        trainingEffects.updateFetchFarmerTrainingGuideOptions({
          farmId,
          request: {
            shoveDelayInSec: trainingGuideOptions.shoveDelayInSec,
          },
        })
      );
    }
  };

  const handleOptionsUpdate = (option: string, value: any) => {
    const updatedOptions = { ...trainingGuideOptions, [option]: value };
    setTrainingGuideOptions(updatedOptions);
  };

  useEffect(() => {
    const currentTrainingGuideStages: TrainingGuideStages = {};
    trainingGuides.forEach((guide) => {
      currentTrainingGuideStages[guide.type] = {
        id: guide.id,
        farmId,
        initialStage: guide.currentStage,
        updatedStage: guide.currentStage,
      };
    });
    setTrainingGuideStages(currentTrainingGuideStages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingGuides]);

  useEffect(() => {
    if (trainingOptions) {
      const currentTrainingGuideOptions: TrainingOptions = {};
      Object.keys(trainingOptions).forEach((option) => {
        if (option !== 'farmId') {
          currentTrainingGuideOptions[option] = (trainingOptions as any)[option];
        }
      });
      setTrainingGuideOptions(currentTrainingGuideOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingOptions]);

  return (
    <>
      <Accordion
        onChange={(e, isExpanded) => setFarmerGuideExpanded(isExpanded)}
        expanded={farmerGuideExpanded}
        style={{ padding: '16px 16px', borderRadius: '8px' }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content">
          <Title>Farmer Guide Stages</Title>
        </AccordionSummary>
        <Wrapper
          style={{ flexDirection: 'row', marginLeft: isMobile ? '60%' : '80%' }}
          itemContainerStyle={{ margin: 8 }}
        >
          <Button
            text="New"
            onClick={() => handleAddNewTrainingStages()}
            disabled={shouldDisableNewButton()}
            size="large"
            backgroundColour="black"
            colour="white"
          />
          <Button
            text="Save"
            onClick={() => handleSaveTrainingGuides()}
            disabled={shouldDisableSaveButton()}
            size="large"
            backgroundColour="black"
            colour="white"
          />
        </Wrapper>
        <Wrapper style={{ flex: 1, padding: 8, borderTop: '1px solid #c5c5c5' }}>
          <InnerWrapper>
            <Grid container spacing={2} alignItems="center" alignContent="center">
              {trainingOptions &&
                Object.keys(trainingGuideOptions).map((option) => (
                  <Fragment key={option}>
                    <Grid item xs={3} sm={5} style={{ display: 'flex' }}>
                      <div style={{ marginLeft: '40%', fontWeight: 500, opacity: 0.65 }}>
                        {option === 'shoveDelayInSec' ? `Shove Delay:` : option}
                      </div>
                    </Grid>
                    <Grid item xs={7} sm={5} style={{ display: 'flex' }}>
                      <TextField
                        type={parseInt(trainingGuideOptions[option], 10) ? 'number' : 'text'}
                        label={`Current Shove Delay: ${(trainingOptions as any)[option]} seconds`}
                        value={trainingGuideOptions[option]}
                        onChange={(event) => handleOptionsUpdate(option, event.target.value)}
                        style={{ flex: 1, padding: 8 }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">Seconds</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
                      {shouldDisplayOptionsChangedWarning(option) ? (
                        <div style={{ margin: 'auto', color: 'yellowgreen' }}>Current</div>
                      ) : (
                        <div style={{ margin: 'auto', color: 'orangered' }}>Not Saved</div>
                      )}
                    </Grid>
                  </Fragment>
                ))}
            </Grid>
            <Grid container spacing={2} alignItems="center" alignContent="center">
              {(Object.keys(trainingGuideStages) as FarmerTrainingGuideTypeEnum[])?.map((type) => (
                <Fragment key={type}>
                  <Grid item xs={3} sm={5} style={{ display: 'flex' }}>
                    <div style={{ marginLeft: '40%', fontWeight: 500, opacity: 0.65 }}>
                      {`${prettyPrintFarmerGuideType(type)}:`}
                    </div>
                  </Grid>
                  <Grid item xs={7} sm={5} style={{ display: 'flex' }}>
                    <SelectField<string | undefined>
                      options={getTrainingStagesForType(type)}
                      value={trainingGuideStages[type]?.updatedStage ?? ''}
                      valueExtractor={(stage) => stage}
                      displayNameExtractor={(stage) => stage!.replace(/_/g, ' ')}
                      onChange={(stage) => handleTrainingStageChange(type, stage as TrainingGuideStage)}
                      label="Stage"
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
                    {shouldDisplayChangedWarning(type) ? (
                      <div style={{ margin: 'auto', color: 'orangered' }}>Not Saved</div>
                    ) : (
                      <div style={{ margin: 'auto', color: 'yellowgreen' }}>Current</div>
                    )}
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </InnerWrapper>
        </Wrapper>
      </Accordion>
    </>
  );
};

export default FarmerGuidePanel;
