// COPIED FROM react-leaflet-pixi-overlay WITH CHANGES TO AVOID CRASHES

import { useEffect, useState } from 'react';
// leaflet
import L from 'leaflet';

// pixi-overlay
import * as PIXI from 'pixi.js';
import 'leaflet-pixi-overlay';

import { useLeafletMap } from 'use-leaflet';

PIXI.utils.skipHello();
const PIXILoader = PIXI.Loader.shared;

function getEncodedIcon(svg) {
  const decoded = unescape(encodeURIComponent(svg));
  const base64 = btoa(decoded);
  return `data:image/svg+xml;base64,${base64}`;
}

const PixiOverlay = ({ markers, show = true }) => {
  const [pixiOverlay, setPixiOverlay] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const map = useLeafletMap();

  // CHANGE: deregister the overlay when not showing so interactions with other map layers can work
  useEffect(() => {
    if (pixiOverlay != null) {
      if (show) {
        if (pixiOverlay.utils.getMap() == null) {
          pixiOverlay.addTo(map);
        }
      } else {
        if (pixiOverlay.utils.getMap() != null) {
          pixiOverlay.removeFrom(map);
        }
      }
    }
  }, [show, map, pixiOverlay]);

  // load sprites
  useEffect(() => {
    let loadingAny = false;
    if (PIXILoader.loading) PIXILoader.reset(); // CHANGE: reset the loader if already loading to stop it from crashing
    for (const marker of markers) {
      const resolvedMarkerId = marker.iconId || marker.iconColor;

      // skip if no ID or already cached
      if ((!marker.iconColor && !marker.iconId) || PIXILoader.resources[`marker_${resolvedMarkerId}`]) {
        continue;
      }
      loadingAny = true;
      PIXILoader.add(`marker_${resolvedMarkerId}`, getEncodedIcon(marker.customIcon));
    }
    if (loaded && loadingAny) {
      setLoaded(false);
    }

    if (loadingAny) {
      PIXILoader.load(() => setLoaded(true));
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  // load pixi when map changes
  useEffect(() => {
    const pixiContainer = new PIXI.Container();
    const overlay = L.pixiOverlay((utils) => {
      // redraw markers
      const scale = utils.getScale();
      utils.getContainer().children.forEach((child) => child.scale.set(1 / scale));

      utils.getRenderer().render(utils.getContainer());
    }, pixiContainer);
    overlay.addTo(map);
    setPixiOverlay(overlay);

    return () => pixiContainer.removeChildren();
  }, [map]);

  // draw markers first time in new container
  useEffect(() => {
    if (pixiOverlay && markers && loaded) {
      const { utils } = pixiOverlay;
      const container = utils.getContainer();
      const renderer = utils.getRenderer();
      const project = utils.latLngToLayerPoint;
      const scale = utils.getScale();

      markers.forEach((marker) => {
        const { id, iconColor, iconId, onClick, position } = marker;

        const resolvedIconId = iconId || iconColor;

        if (
          !PIXILoader.resources[`marker_${resolvedIconId}`] ||
          !PIXILoader.resources[`marker_${resolvedIconId}`].texture
        ) {
          return;
        }

        const markerTexture = PIXILoader.resources[`marker_${resolvedIconId}`].texture;
        // const markerTexture = new PIXI.Texture.fromImage(url);

        markerTexture.anchor = { x: 0.5, y: 0.5 }; // CHANGE: center on y axis too

        const markerSprite = PIXI.Sprite.from(markerTexture);
        markerSprite.anchor.set(0.5, 0.5); // CHANGE: center on y axis too

        const markerCoords = project(position);
        markerSprite.x = markerCoords.x;
        markerSprite.y = markerCoords.y;

        markerSprite.scale.set(1 / scale);

        if (onClick) {
          markerSprite.interactive = true;
          markerSprite.on('click', () => {
            if (onClick) {
              onClick(id);
            }
          });
          markerSprite.defaultCursor = 'pointer';
          markerSprite.buttonMode = true;
        }

        container.addChild(markerSprite);
      });

      renderer.render(container);
    }

    return () => pixiOverlay && pixiOverlay.utils.getContainer().removeChildren();
  }, [pixiOverlay, markers, loaded]);

  return null;
};

export default PixiOverlay;
