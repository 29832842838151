import {
  Box,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { CaddieColours } from 'application/theme/colours';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from 'application/hooks/hooks';
import SearchIcon from '@material-ui/icons/Search';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import { Column, getOnFarmStatusCountColour } from '../utils';

type FarmSummary = {
  farmId: string;
  farmName: string;
  count: number;
  mode: DeviceOnFarmStatusEnum;
};

type FarmIssueSidePanelProps = {
  farmSummary: FarmSummary[];
};

const FarmIssueSidePanel: FunctionComponent<FarmIssueSidePanelProps> = ({ farmSummary }) => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');
  const width = useWindowSize();
  const hideSidePanel = width < 1200 ? 'none' : 'block';

  const useStyles = makeStyles(() => ({
    sidePanel: {
      backgroundColor: CaddieColours.LightGrey,
      maxWidth: '20%',
      maxHeight: 'calc(100vh - 44px)',
      overflowY: 'scroll',
      borderRightStyle: 'solid',
      borderRightColor: '#D5D7DB',
      borderRightWidth: '1px',
      display: hideSidePanel,
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Column className={classes.sidePanel}>
        <Box m={2} mr={0}>
          <TextField
            style={{ backgroundColor: 'white', width: '95%', height: '44px', borderRadius: '8px' }}
            type="search"
            placeholder="Search All Farms"
            InputProps={{
              style: { textAlign: 'center', margin: '8px' },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e: any) => {
              setSearchInput(e.target.value);
            }}
          />
          <Divider style={{ marginTop: '8px' }} />
          <Table>
            <TableBody>
              {farmSummary
                .filter((item) => item.farmName?.toLowerCase().includes(searchInput.toLowerCase()))
                .map((item) => (
                  <TableRow
                    onClick={async () => history.push({ pathname: `/fleet-watch/${item.farmId}` })}
                    style={{ cursor: 'pointer' }}
                    key={item.farmId}
                    hover
                  >
                    <TableCell>{item.farmName}</TableCell>
                    <TableCell style={{ color: getOnFarmStatusCountColour(item.count, item.mode) }}>
                      {item.count}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Column>
    </>
  );
};

export default FarmIssueSidePanel;
