import React, { FunctionComponent } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, List, ListItem } from '@material-ui/core';
import Button from 'application/components/button';
import TowerService from 'services/tower.service';
import { IDeviceControlMethodEnum } from '@halter-corp/tower-service-client';
import { DeviceActionDialogProps } from '../DevicesActionsWrapper';

const ForceOnDialog: FunctionComponent<DeviceActionDialogProps> = ({ open, onClose, devices }) => {
  const keepAliveUntil = Math.floor(Date.now() / 1000) + 1800;
  function handleRequest() {
    onClose();
    devices.forEach((device) => {
      TowerService.updateKeepAliveUntilByGatewayId(
        device.id,
        keepAliveUntil,
        IDeviceControlMethodEnum.PORT_TOGGLE
      );
    });
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Force On?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will turn and lock gateway{devices.length > 1 ? 's' : ''} on for 30 minutes
        </DialogContentText>
        <List dense>
          {devices.map((device) => (
            <ListItem key={device.id}>- {device.id}</ListItem>
          ))}
        </List>
        <Button text="Force On" onClick={handleRequest} />
      </DialogContent>
    </Dialog>
  );
};

export default ForceOnDialog;
