import React, { useState, FC, ChangeEvent } from 'react';
import { TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons';
import { ITowerNoteDTO } from '@halter-corp/tower-service-client';
import { Column } from 'application/components/data-table';
import { DeviceWithMetadata } from 'store/selectors/tower.selectors';
import NestedDevicetable from './nested-device-table';

export interface DevicesDataRowData {
  id: string;
  devices: DeviceWithMetadata[];
  notes?: ITowerNoteDTO[];
}

type DevicesDataRowProps = {
  data: DevicesDataRowData;
  columns: Column<DevicesDataRowData>[];
  selectedDevices: Map<string, DeviceWithMetadata>;
  onSelectDevice: (event: ChangeEvent<HTMLInputElement>) => void;
  rowIndex?: number;
  padding?: number;
};

const DevicesDataRow: FC<DevicesDataRowProps> = ({
  rowIndex,
  columns,
  data,
  padding,
  selectedDevices,
  onSelectDevice,
}) => {
  const [open, setOpen] = useState(true);

  const handleCellClick = (e: React.MouseEvent) => {
    // If the user clicks the checkbox, do not toggle the open state
    const isCheckbox = (e.target as any)?.getAttribute('type') === 'checkbox';
    if (isCheckbox) {
      const isChecked = (e.target as any).checked;
      if (!isChecked || open) e.stopPropagation();
    }
  };

  return (
    <>
      <TableRow hover onClick={() => setOpen(!open)}>
        <TableCell style={{ padding, width: '24px' }}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {columns.map((column, colIndex) => (
          <Tooltip
            // eslint-disable-next-line react/no-array-index-key
            key={colIndex}
            title={column.toolTip ? column.toolTip(data) : ''}
            enterDelay={500}
            style={{ whiteSpace: 'pre' }}
          >
            <TableCell
              // eslint-disable-next-line react/no-array-index-key
              key={colIndex}
              style={{
                width: colIndex === 0 ? '8.5%' : '16.5%',
                fontSize: 16,
                fontWeight: 600,
                color: '#454545',
                padding: 4,
              }}
              onClick={handleCellClick}
            >
              {column.render(data, rowIndex ?? 0)}
            </TableCell>
          </Tooltip>
        ))}
        <TableCell colSpan={6} />
      </TableRow>

      <NestedDevicetable
        devices={data.devices}
        open={open}
        selectedDevices={selectedDevices}
        onSelectDevice={onSelectDevice}
      />
    </>
  );
};

export default DevicesDataRow;
