import React from 'react';
import moment from 'moment';

import StatusIcon, { StatusColours } from 'application/components/status-icon';

const generateStatusIcon = (color: StatusColours) => <StatusIcon color={color} />;

export const deviceSyncingStatusToTrafficLight = (syncingStatus?: string) => {
  if (syncingStatus == null) return null;
  switch (syncingStatus?.toLowerCase()) {
    case 'synced':
      return generateStatusIcon(StatusColours.GREEN);
    case 'syncing':
      return generateStatusIcon(StatusColours.AMBER);
    case 'failedtosync':
      return generateStatusIcon(StatusColours.RED);
    case 'failed_to_sync':
      return generateStatusIcon(StatusColours.RED);
    default:
      return null;
  }
};

export const deviceBatteryStatus = (batteryPercentage?: number) => {
  if (batteryPercentage == null) return null;
  switch (true) {
    case batteryPercentage > 50:
      return generateStatusIcon(StatusColours.GREEN);
    case batteryPercentage > 15:
      return generateStatusIcon(StatusColours.AMBER);
    default:
      return generateStatusIcon(StatusColours.RED);
  }
};

export const deviceLoraStatus = (
  lastUpdate?: Date,
  reportingWindow?: number,
  baseDate: Date = new Date()
) => {
  const reportingRange = reportingWindow || 180;
  if (lastUpdate == null) return null;
  switch (true) {
    case moment(baseDate).diff(moment(lastUpdate), 'seconds') < reportingRange * 2:
      return generateStatusIcon(StatusColours.GREEN);
    case moment(baseDate).diff(moment(lastUpdate), 'seconds') < reportingRange * 4:
      return generateStatusIcon(StatusColours.AMBER);
    default:
      return generateStatusIcon(StatusColours.RED);
  }
};

export const deviceWifiStatus = (lastUpdate?: Date, baseDate: Date = new Date()) => {
  if (lastUpdate == null) return null;
  switch (true) {
    case moment(baseDate).diff(moment(lastUpdate), 'hours') < 30:
      return generateStatusIcon(StatusColours.GREEN);
    case moment(baseDate).diff(moment(lastUpdate), 'hours') < 48:
      return generateStatusIcon(StatusColours.AMBER);
    default:
      return generateStatusIcon(StatusColours.RED);
  }
};

export const deviceFaultsStatus = (statusList?: string[], isFaulty?: boolean) => {
  if (statusList == null && isFaulty == null) return null;
  if (isFaulty) {
    return generateStatusIcon(StatusColours.RED);
  }
  if (statusList && statusList.length > 0) {
    return generateStatusIcon(StatusColours.AMBER);
  }
  return generateStatusIcon(StatusColours.GREEN);
};

export const deviceCommandSetHashStatus = (deviceHash?: string, backendHash?: string) => {
  if (!deviceHash || !backendHash) return null;

  if (deviceHash.toString() === backendHash.toString()) {
    return generateStatusIcon(StatusColours.GREEN);
  }

  return generateStatusIcon(StatusColours.RED);
};
