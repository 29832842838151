import React, { FunctionComponent, useEffect, useState } from 'react';
import { Grid, Button, Chip, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { isEmpty, omitBy } from 'lodash';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentFarmShard, selectFarmMetadata } from 'store/selectors/farm-infra.selectors';
import { DeviceWithMetadata } from 'store/selectors/tower.selectors';
import towerEffects from 'store/effects/tower.effects';
import ShardedFarmSelect from '../components/sharded-farm-select';
import ShardSelect from '../components/shard-select';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0px 24px 16px 24px;
`;

type TowersSearchPanelProps = {
  searchMode: 'farm' | 'shard';
  serialNumbers?: string[];
  onChangeFarmId: (value: string) => void;
  onChangeShardId: (value: string) => void;
  setSelectedDevices: React.Dispatch<React.SetStateAction<Map<string, DeviceWithMetadata>>>;
  farmId: string;
  shardId: string;
};

const TowersSearchPanel: FunctionComponent<TowersSearchPanelProps> = ({
  searchMode,
  onChangeFarmId,
  onChangeShardId,
  setSelectedDevices,
  farmId,
  shardId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentShard = useSelector(selectCurrentFarmShard);
  const currentFarmMetadata = useSelector(selectFarmMetadata);

  const getNewUrlSearchParams = () =>
    new URLSearchParams(
      omitBy(
        {
          mode: searchMode,
          farmId,
          shardId,
        },
        (value) => value == null || isEmpty(value)
      ) as any
    ).toString();

  const [urlSearchParams, setUrlSearchParams] = useState(getNewUrlSearchParams());

  const clearSelectedDevices = () => setSelectedDevices(new Map());

  const handleRefresh = () => {
    if (searchMode === 'farm') {
      dispatch(towerEffects.fetchAllDeviceContextByFarmId({ farmId }));
    } else {
      dispatch(towerEffects.fetchAllDeviceContextByShardId({ shardId }));
    }
  };

  const handleSearchOrRefresh = () => {
    const newSearchParams = getNewUrlSearchParams();

    if (newSearchParams !== urlSearchParams) {
      history.push({
        search: newSearchParams,
      });
      setUrlSearchParams(newSearchParams);
      clearSelectedDevices();
    } else {
      handleRefresh();
    }
  };

  useEffect(() => {
    const newSearchParams = getNewUrlSearchParams();
    if (newSearchParams !== urlSearchParams) handleSearchOrRefresh();
  }, [farmId, shardId, searchMode]);

  const farmLive = currentFarmMetadata?.live;

  return (
    <Wrapper>
      <Grid container spacing={2} alignItems="center" alignContent="center">
        {searchMode === 'farm' && (
          <Grid item xs={6} sm={3} lg={2} style={{ display: 'flex' }}>
            <ShardedFarmSelect onChange={onChangeFarmId} />
          </Grid>
        )}
        {searchMode === 'shard' && (
          <Grid item xs={6} sm={3} lg={2} style={{ display: 'flex' }}>
            <ShardSelect onChange={onChangeShardId} />
          </Grid>
        )}
        {currentShard && searchMode === 'farm' && (
          <Box style={{ marginTop: '7px' }}>
            <Chip style={{ color: 'FFF' }} variant="outlined" label={currentShard} />
            <Chip
              style={{ marginLeft: '10px', color: farmLive ? 'green' : 'red' }}
              variant="outlined"
              label={farmLive ? 'Live' : 'Not Live'}
            />
          </Box>
        )}
        <Grid item xs={12} sm={12} lg={2} style={{ display: 'flex' }}>
          <Button
            size="small"
            color="primary"
            style={{
              backgroundColor: '#1769aa',
              color: 'white',
              margin: 16,
              padding: '8px 24px 8px 24px',
            }}
            onClick={handleSearchOrRefresh}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default TowersSearchPanel;
