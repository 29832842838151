import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';

import { Typography, Grid, ThemeProvider, createTheme, LinearProgress } from '@material-ui/core';

import { chain, sumBy } from 'lodash';
import { FarmOperationTypeEnum } from '@halter-corp/business-service-client';
import BusinessService from '../../../../services/business.service';
import { MapUtils } from '../../../utils/MapUtils';
import farmEffects from '../../../../store/effects/farm.effects';
import { useDispatch } from '../../../../store';
import CowLocationService from '../../../../services/cow-location.service';
import grazingHeatmapEffects from '../../../../store/effects/grazing-heatmap.effects';
import { FiCard, FiCardActionArea, FiCardContent, FiCardMedia } from '../component/full-image-card';
import farmImage from './farm.jpg';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  padding: 0rem;
  align-items: center;
`;

const RotationGrazedAreaScreen: FunctionComponent = () => {
  const dispatch = useDispatch();

  const fetchData = () => {
    dispatch(farmEffects.fetch());
    dispatch(grazingHeatmapEffects.fetchGrazingHeatmapMetricForAllFarms());
  };

  const autoReloadInterval = 15 * 60 * 1000; // 15 min
  useEffect(() => {
    fetchData();
    const autoReload = setInterval(() => {
      fetchData();
    }, autoReloadInterval);

    return () => clearInterval(autoReload);
  }, [dispatch]);

  const farms = BusinessService.useFetchFarmList();
  const metrics = CowLocationService.useGrazingHeatmapMetricForAllFarms();
  const loading = CowLocationService.useIsLoading();

  const beefFarms = farms.filter(
    (f) =>
      !f.internal &&
      (f.primaryOperationType === FarmOperationTypeEnum.BEEF ||
        f.primaryOperationType === FarmOperationTypeEnum.BEEF_AND_SHEEP)
  );

  const metricsByFarmId = MapUtils.keyBy(metrics, (m) => m.farmId);

  const data = chain(beefFarms)
    .flatMap((farm) => {
      const metric = metricsByFarmId.get(farm.id);
      if (metric == null) return [];
      return {
        farmId: farm.id,
        farmName: farm.name,
        farmCreatedDate: farm.createdDate,
        areaInHa: metric.areaActivelyGrazedInHa,
      };
    })
    .orderBy((d) => d.areaInHa, 'desc')
    .value();

  // const topGrazingFarm = maxBy(data, (d) => d.areaInHa);

  const totalAreaInHa = sumBy(data, (d) => d.areaInHa).toFixed(0);

  return (
    <>
      <ThemeProvider theme={createTheme({ palette: { type: 'dark' } })}>
        <LinearProgress variant={loading ? 'query' : 'determinate'} value={100} />

        <Wrapper style={{ background: '#222' }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FiCard style={{ height: '100vh', display: 'flex', borderRadius: 0, cursor: 'none' }}>
                <FiCardActionArea disabled style={{ color: '#fff' }}>
                  <FiCardMedia image={farmImage} />
                  <FiCardContent
                    style={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      textAlign: 'center',
                      borderRadius: 0,
                    }}
                  >
                    <Typography style={{ fontSize: 180 }} gutterBottom>
                      {totalAreaInHa}
                      <span style={{ fontSize: 60 }}>HA</span>
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      HECTARES BEING ROTATIONAL GRAZED
                    </Typography>
                  </FiCardContent>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{ position: 'absolute', bottom: '2%', right: '2%' }}
                  >
                    IN THE LAST 100 DAYS
                  </Typography>
                </FiCardActionArea>
              </FiCard>
            </Grid>

            {/* <Grid item xs={12} md={12}> */}
            {/*  <FiCard style={{ height: 800, display: 'flex' }}> */}
            {/*    <FiCardActionArea> */}
            {/*      <FiCardMedia image={grazingImage} /> */}
            {/*      <FiCardContent */}
            {/*        style={{ */}
            {/*          flexDirection: 'column', */}
            {/*          justifyContent: 'center', */}
            {/*          textAlign: 'center', */}
            {/*          color: '#fff', */}
            {/*        }} */}
            {/*      > */}
            {/*        <Typography variant="h5" gutterBottom> */}
            {/*          BIGGEST GRAZER */}
            {/*        </Typography> */}
            {/*        <Typography variant="subtitle1" gutterBottom> */}
            {/*          OUT OF {data.length} FARMS */}
            {/*        </Typography> */}
            {/*        <Typography variant="h1" gutterBottom> */}
            {/*          {topGrazingFarm?.areaInHa.toFixed(2) ?? 0} */}
            {/*        </Typography> */}
            {/*        <Typography variant="h5" gutterBottom> */}
            {/*          HECTARES */}
            {/*        </Typography> */}
            {/*        <Typography variant="subtitle2" gutterBottom> */}
            {/*          {topGrazingFarm?.farmName?.toUpperCase() ?? '-'} */}
            {/*        </Typography> */}
            {/*      </FiCardContent> */}
            {/*    </FiCardActionArea> */}
            {/*  </FiCard> */}
            {/* </Grid> */}

            {/* <Grid item xs={12}> */}
            {/*  <Accordion> */}
            {/*    <AccordionSummary */}
            {/*      expandIcon={<ExpandMoreIcon />} */}
            {/*      aria-controls="panel1a-content" */}
            {/*      id="panel1a-header" */}
            {/*    > */}
            {/*      <Typography>Leaderboard</Typography> */}
            {/*    </AccordionSummary> */}
            {/*    <Paper> */}
            {/*      <TableContainer> */}
            {/*        <Table aria-label="simple table"> */}
            {/*          <TableHead> */}
            {/*            <TableRow> */}
            {/*              <TableCell align="left">#</TableCell> */}
            {/*              <TableCell align="left">Farm Name</TableCell> */}
            {/*              <TableCell align="right">Area (ha)</TableCell> */}
            {/*            </TableRow> */}
            {/*          </TableHead> */}
            {/*          <TableBody> */}
            {/*            {data.map((d, idx) => ( */}
            {/*              <TableRow hover key={d.farmName}> */}
            {/*                <TableCell align="left">{idx + 1}</TableCell> */}
            {/*                <TableCell align="left">{d.farmName ?? d.farmId}</TableCell> */}
            {/*                <TableCell align="right">{d.areaInHa.toFixed(2)}</TableCell> */}
            {/*              </TableRow> */}
            {/*            ))} */}
            {/*          </TableBody> */}
            {/*        </Table> */}
            {/*      </TableContainer> */}
            {/*    </Paper> */}
            {/*  </Accordion> */}
            {/* </Grid> */}
          </Grid>
        </Wrapper>
      </ThemeProvider>
    </>
  );
};

export default RotationGrazedAreaScreen;
