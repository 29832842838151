import React, { FunctionComponent, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Box,
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { DeviceDetail } from 'services/device-detail-page.service';
import SettingsDetailPanel from 'application/modules/devices/panels/settings-detail.panel';

const useStyles = makeStyles(() =>
  createStyles({
    accordion: {
      width: '100%',
    },
  })
);

type SettingsPanelProps = {
  deviceDetail?: DeviceDetail;
};

const SettingsPanel: FunctionComponent<SettingsPanelProps> = ({ deviceDetail }) => {
  const classes = useStyles();
  const [settingsExpanded, setSettingsExpanded] = useState(!isMobile);

  const settingsDetail = useMemo(() => {
    const experimentalFeatureToggleSetting = (deviceDetail?.settingsV2?.settingsObject as any)?.engineering
      ?.experimentalFeatureToggle;
    if (experimentalFeatureToggleSetting == null) {
      return deviceDetail?.settingsV2;
    }
    // eslint-disable-next-line no-param-reassign
    (deviceDetail!.settingsV2!.settingsObject as any).engineering.experimentalFeatureToggleAsBinary =
      experimentalFeatureToggleSetting.toString(2);
    // eslint-disable-next-line no-param-reassign
    (deviceDetail!.settingsV2!.settingsObject as any).engineering.experimentalFeatureToggleEnabledFlags =
      Array(...experimentalFeatureToggleSetting.toString(2))
        .reverse()
        .reduce((prev, curr, index) => {
          if (curr === '1') {
            return [...prev, index];
          }
          return prev;
        }, [])
        .join(', ');
    return deviceDetail!.settingsV2;
  }, [deviceDetail?.settingsV2]);

  return (
    <Accordion
      expanded={settingsExpanded}
      onChange={(e, isExpanded) => setSettingsExpanded(isExpanded)}
      className={classes.accordion}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
        <Grid item lg={12} xs={12}>
          <Typography variant="h6">Settings</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          <Box overflow="auto" flex={1} maxHeight={600}>
            <SettingsDetailPanel settingsDetail={settingsDetail} />
          </Box>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SettingsPanel;
