import React, { forwardRef } from 'react';
import { Input as BaseInput, InputProps as BaseInputProps, FormControl, InputLabel } from '@material-ui/core';

type MultiValueInputFieldProps = Omit<BaseInputProps, 'onChange'> & {
  label: string;
  value?: string[];
  onChange: (value: string[]) => void;
};

const SEPARATOR = ',';

type MultiValueInput = typeof BaseInput;

const MultiValueInputField = forwardRef<MultiValueInput, MultiValueInputFieldProps>(
  ({ label, value, onChange, ...rest }, ref) => (
    <FormControl style={{ display: 'flex', flex: 1, margin: 8 }}>
      <InputLabel>{label}</InputLabel>
      <BaseInput
        ref={ref}
        value={value?.join(SEPARATOR)}
        onChange={(event) => onChange(event.target.value.trim().split(SEPARATOR))}
        {...rest}
      />
    </FormControl>
  )
);

export default MultiValueInputField;
