import React, { FunctionComponent } from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import { isNumber, isBoolean, startCase, isObject, entries } from 'lodash';

import Checkbox from 'application/components/checkbox';

import SettingInput from './setting-input';

type SettingsInputRow<T> = {
  key: string;
  value: T;
  onChange: (value: T) => void;
  metaData: { default: T; options?: string[] };
  selectedForModifier?: boolean;
};

type SettingsInputTableProps = {
  rows: SettingsInputRow<number | boolean | object | string | undefined>[];
  mode: 'profile' | 'modifier';
  onChangeSettingSelected?: (key: string, selected: boolean) => void;

  /**
   * @summary Is this component the root component (not being called recursively). this will adjust the styling of the component.
   * @default true
   */
  isRootComponent?: boolean;
};

const SettingsInputTable: FunctionComponent<SettingsInputTableProps> = ({
  mode,
  rows,
  onChangeSettingSelected,
  isRootComponent = true,
}) => (
  <Box style={{ border: isRootComponent ? '1px solid #cbd5e1' : undefined }}>
    {rows.map(({ key, value, onChange, metaData, selectedForModifier }, index) => (
      <Box
        key={key}
        style={{
          backgroundColor: index % 2 === 0 ? '#f8fafc' : undefined,
          borderBottom: index < rows.length - 1 ? '1px solid #cbd5e1' : undefined,
        }}
        py={isRootComponent ? 2 : undefined}
        px={isRootComponent ? 2 : undefined}
      >
        <Grid
          container
          style={{ flexGrow: 1, ...(mode === 'modifier' && !selectedForModifier ? { opacity: 0.5 } : {}) }}
        >
          {isRootComponent ? (
            <Grid item xs={6}>
              <Grid container alignItems="center">
                {mode === 'modifier' ? (
                  <Grid item>
                    <Checkbox
                      checked={selectedForModifier ?? true}
                      onChangeValue={(checked) => onChangeSettingSelected?.(key, checked)}
                    />
                  </Grid>
                ) : null}
                <Grid item>
                  <Typography>{startCase(key)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid
            item
            xs={isRootComponent ? 6 : 12}
            style={mode === 'modifier' && !selectedForModifier ? { opacity: 0.5 } : {}}
          >
            {(isNumber(value) || isBoolean(value) || metaData.options !== undefined) && (
              <SettingInput
                value={value as number | boolean | string}
                onChange={onChange}
                defaultValue={metaData.default as number | boolean | string}
                options={metaData.options}
              />
            )}
            {isObject(value) &&
              entries(value).map(([k, v], entriesIndex) => {
                const isNotLastChild = entriesIndex < entries(value).length - 1;

                return (
                  <Box
                    key={k}
                    mb={isNotLastChild ? 2 : undefined}
                    pb={isNotLastChild ? 1 : undefined}
                    style={{
                      borderBottom: isNotLastChild ? '1px solid #cbd5e1' : undefined,
                    }}
                  >
                    <Grid container spacing={3} style={{ flexGrow: 1 }}>
                      <Grid item xs={6}>
                        <Typography>{startCase(k)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {isObject(v) ? (
                          <SettingsInputTable
                            rows={[
                              {
                                key: k,
                                value: v,
                                onChange: (newValue) => onChange({ ...value, [k]: newValue }),
                                metaData,
                                selectedForModifier,
                              },
                            ]}
                            mode={mode}
                            onChangeSettingSelected={onChangeSettingSelected}
                            isRootComponent={false}
                          />
                        ) : (
                          <SettingInput
                            value={v}
                            onChange={(newValue) => onChange({ ...value, [k]: newValue })}
                            defaultValue={(metaData.default as any)[k]}
                            options={metaData.options}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
          </Grid>
        </Grid>
      </Box>
    ))}
  </Box>
);

export default SettingsInputTable;
