import { IFarmDTO } from '@halter-corp/business-service-client';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import { StatusColours } from 'application/components/status-icon';
import { MapUtils } from 'application/utils/MapUtils';
import { FarmStatus } from 'data/farm-status';
import { chain } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { FarmDeviceOnFarmStatusCounts } from './types';
import { FleetFarmState } from './containers/fleet-view.container';

export const DATE_COLLAR_UPDATED_FORMAT = 'DD-MM-YYYY h:mm A';

export enum FarmStatusMode {
  Active = 'Active',
  Maintenance = 'Maintenance',
  Emergency = 'Emergency',
  NotLive = 'NotLive',
}

export const farmFilter = (farms?: IFarmDTO[]): IFarmDTO[] =>
  farms?.filter((farm) => farm.name != null && !farm.name.startsWith('Internal')) || [];

export const mergeFarmName = (a1: any[], a2?: IFarmDTO[]) =>
  a1.map((itm) => ({ ...a2?.find((item) => item.id === itm.farmId && item), ...itm }));

export const findFarmStatusMode = (farmStatus: FarmStatus | undefined): FarmStatusMode => {
  if (farmStatus?.emergency) return FarmStatusMode.Emergency;
  if (farmStatus?.maintenance) return FarmStatusMode.Maintenance;
  if (farmStatus?.live === false) return FarmStatusMode.NotLive;
  return FarmStatusMode.Active;
};

export const getHealthyColour = (nIssues: number) => {
  if (nIssues === 0) return StatusColours.GRAY;
  return StatusColours.GREEN;
};

export const getFaultyColour = (nIssues: number) => {
  if (nIssues === 0) return StatusColours.GREEN;
  if (nIssues < 3) return StatusColours.AMBER;
  return StatusColours.RED;
};

export const getSparesColour = (nIssues: number) => {
  if (nIssues === 0) return StatusColours.RED;
  if (nIssues < 2) return StatusColours.AMBER;
  return StatusColours.GREEN;
};

export const getFallenOffCowColour = (nIssues: number) => {
  if (nIssues === 0) return StatusColours.GREEN;
  if (nIssues < 3) return StatusColours.AMBER;
  return StatusColours.RED;
};

export const getPickUpColour = (nIssues: number) => {
  if (nIssues === 0) return StatusColours.GREEN;
  if (nIssues < 3) return StatusColours.AMBER;
  return StatusColours.RED;
};

export const getOnFarmStatusCountColour = (count: number, status: DeviceOnFarmStatusEnum) => {
  switch (status) {
    case DeviceOnFarmStatusEnum.FAULTY_ON_COW:
    case DeviceOnFarmStatusEnum.UNHEALTHY_SPARE:
      return getFaultyColour(count);
    case DeviceOnFarmStatusEnum.HEALTHY_ON_COW:
      return getHealthyColour(count);
    case DeviceOnFarmStatusEnum.POWERED_ON_SPARE:
      return getSparesColour(count);
    case DeviceOnFarmStatusEnum.FALLEN_OFF_COW:
      return getFallenOffCowColour(count);
    case DeviceOnFarmStatusEnum.TO_BE_PICKED_UP:
      return getPickUpColour(count);
    default:
      return 'grey';
  }
};

export const formatEnumValue = (name: string) =>
  name
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const mapOnFarmStatusToDescription = (status: DeviceOnFarmStatusEnum) => {
  switch (status) {
    case DeviceOnFarmStatusEnum.SHIPPED_TO_FARM:
      return 'Collars shipped to farm';
    case DeviceOnFarmStatusEnum.POWERED_ON_SPARE:
      return 'A powered on spare that appears to be healthy';
    case DeviceOnFarmStatusEnum.UNHEALTHY_SPARE:
      return 'Faulty collars not on cow';
    case DeviceOnFarmStatusEnum.HEALTHY_ON_COW:
      return 'Healthy collars on cow';
    case DeviceOnFarmStatusEnum.FAULTY_ON_COW:
      return 'Faulty collars on cow';
    case DeviceOnFarmStatusEnum.FALLEN_OFF_COW:
      return 'Collars fallen off cow';
    case DeviceOnFarmStatusEnum.TO_BE_PICKED_UP:
      return 'Faulty collars not on cow with swap data';
    case DeviceOnFarmStatusEnum.OFF_PLATFORM:
      return 'Collars off platform';
    default:
      return formatEnumValue(status);
  }
};

export const dateToText = (date: Date | undefined) =>
  date ? moment(date).format(DATE_COLLAR_UPDATED_FORMAT) : ' - ';

export const Row = styled.div`
  flex: 1;
  display: flex;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const inactiveSidePanelMobile = 'none';

export function getDeviceCountByOnFarmStatus(
  deviceOnFarmStatusCountsByFarmId: Record<string, FarmDeviceOnFarmStatusCounts>,
  farmId: string
) {
  const farmDeviceCounts = deviceOnFarmStatusCountsByFarmId ? deviceOnFarmStatusCountsByFarmId[farmId] : null;
  if (farmDeviceCounts == null) {
    return null;
  }

  return chain(farmDeviceCounts.deviceOnFarmStatusCounts).keyBy('status').mapValues('count').value();
}

export function formatFarmName(farm: IFarmDTO | null | undefined): string {
  return farm?.name == null
    ? 'Unknown Farm'
    : `${farm.name}${farm.referenceId == null ? '' : ` (${farm.referenceId})`}`;
}

export function getFleetFarmStates(
  farms: IFarmDTO[],
  farmStatuses: Record<string, FarmStatus | undefined>,
  deviceOnFarmStatusCountsByFarmId: Record<string, FarmDeviceOnFarmStatusCounts> = {}
) {
  const farmStates: FleetFarmState[] = farms.flatMap((farm) => {
    const farmStatus = farmStatuses[farm.id];
    const deviceCountByStatus = getDeviceCountByOnFarmStatus(deviceOnFarmStatusCountsByFarmId, farm.id);

    if (deviceCountByStatus == null) return [];

    return {
      farmName: formatFarmName(farm),
      farmId: farm.id,
      status: findFarmStatusMode(farmStatus),
      collarCountByStatusMap: MapUtils.fromObject(deviceCountByStatus ?? {}) as Map<
        DeviceOnFarmStatusEnum,
        number
      >,
    };
  });

  return farmStates;
}
