import React, { FunctionComponent, useState, useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';
import styled from 'styled-components';

import MapPanel from '../panels/map.panel';
import SearchPanel from '../panels/search.panel/search.panel';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 44px);
  width: 100vw;
  overflow: hidden;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

const OtherMapWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 999; // keep above the mapping layer
  width: 600px;
`;

export type MainScreenProps = {
  loading: boolean;
  dateRange: [Date, Date] | null;
  itemsDateRange: [Date, Date] | null;
  cattleEarTags: string[];
  serialNumbers: string[];
  collarCommandIds: number[];
  firmwareVersions: string[];
  queryReturnedError: boolean;
  hasResults: boolean;
};

const DebugCattleScreen: FunctionComponent<MainScreenProps> = ({
  loading,
  dateRange,
  itemsDateRange,
  cattleEarTags,
  serialNumbers,
  collarCommandIds,
  firmwareVersions,
  queryReturnedError,
  hasResults,
}) => {
  const [activeDateRange, setActiveDateRange] = useState(itemsDateRange);
  const [searchPanelIsExpanded, setSearchPanelIsExpanded] = useState(!hasResults);

  useEffect(() => setSearchPanelIsExpanded(!hasResults), [hasResults]);
  useEffect(() => setActiveDateRange(itemsDateRange), [itemsDateRange]);

  return (
    <Wrapper>
      <LinearProgress variant={loading ? 'query' : 'determinate'} value={100} />
      <Row>
        <MapPanel searchDateRange={dateRange} activeDateRange={activeDateRange} />
        <OtherMapWrapper>
          <SearchPanel
            cattleEarTags={cattleEarTags}
            serialNumbers={serialNumbers}
            dateRange={dateRange}
            collarCommandIds={collarCommandIds}
            firmwareVersions={firmwareVersions}
            queryReturnedError={queryReturnedError}
            searchPanelIsExpanded={searchPanelIsExpanded}
            onSearchPanelExpandButtonClick={(newExpandedState: boolean) =>
              setSearchPanelIsExpanded(newExpandedState)
            }
          />
        </OtherMapWrapper>
      </Row>
    </Wrapper>
  );
};

export default DebugCattleScreen;
