import React from 'react';
import { Tower } from 'store/selectors/tower.selectors';
import TowerMarker from './tower-marker';
import TowerPopupExternal from './tower-popup-external';

interface ExternalTowerMarkerWithPopupProps {
  tower: Tower;
  farmId: string;
  color?: string;
  existing: boolean;
  modified: boolean;
  hover: string;
  markerRefs: React.MutableRefObject<Map<any, any>>;
  handleDragEnd: (e: L.DragEndEvent, towerId: string) => void;
  handleRemoveTower: (towerId: string) => void;
  handleCreateTowerFarmMapping: (towerId: string) => void;
}
const ExternalTowerMarkerWithPopup = ({
  tower,
  color,
  existing,
  modified,
  hover,
  markerRefs,
  handleDragEnd,
  handleRemoveTower,
  handleCreateTowerFarmMapping,
}: ExternalTowerMarkerWithPopupProps) => (
  <TowerMarker
    tower={tower}
    color={color}
    existing={existing}
    modified={modified}
    hover={hover}
    markerRefs={markerRefs}
    handleDragEnd={handleDragEnd}
    handleRemoveTower={handleRemoveTower}
  >
    <TowerPopupExternal handleCreateTowerFarmMapping={handleCreateTowerFarmMapping} tower={tower} />
  </TowerMarker>
);

export default ExternalTowerMarkerWithPopup;
