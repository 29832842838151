import React, { FunctionComponent, useState } from 'react';
import DataTable from 'application/components/data-table';
import { dateToText } from 'application/modules/fleet-watch/utils';
import { CaddieColours } from 'application/theme/colours';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import BehaviourService from 'services/behaviour.service';
import { Delete } from '@material-ui/icons';
import { CalvingDTO } from '../panels/calving.panel';

type CalvingTableProps = {
  calvingInfo: CalvingDTO[];
  farmId: string;
};

const CalvingTable: FunctionComponent<CalvingTableProps> = ({ calvingInfo, farmId }) => {
  const [isHandlingDelete, setIsHandlingDelete] = useState(false);

  const handleDeleteCalving = async (calvingId: string) => {
    setIsHandlingDelete(true);
    await BehaviourService.deleteCalving(calvingId, farmId);
    setIsHandlingDelete(false);
  };

  return (
    <>
      <DataTable<CalvingDTO>
        data={calvingInfo}
        columns={[
          {
            name: '',
            render: (_, index) => Number(index + 1),
          },
          {
            name: 'Cow',
            keyExtractor: (calving) => {
              if (Number.isNaN(Number(calving.cattleName)) || calving.cattleName?.split('')[0] === '0') {
                return calving.cattleName;
              }
              return Number(calving.cattleName);
            },
            render: (calving) => calving.cattleName,
            allowCopyToClipboard: true,
          },
          {
            name: 'Date Created',
            keyExtractor: (calving) => calving.createdAt,
            render: (calving) => dateToText(calving.createdAt),
          },
          {
            name: 'Date Updated',
            keyExtractor: (calving) => calving.updatedAt,
            render: (calving) => dateToText(calving.updatedAt),
          },
          {
            name: 'Expected Calving Date',
            keyExtractor: (calving) => calving.expectedCalvingDate,
            render: (calving) => dateToText(calving.expectedCalvingDate),
            allowCopyToClipboard: true,
          },
          {
            name: 'Calving Date',
            keyExtractor: (calving) => calving.calvedAt,
            render: (calving) => dateToText(calving.calvedAt),
            allowCopyToClipboard: true,
          },
          {
            name: 'Calving recovery state',
            keyExtractor: (calving) => calving.calvingRecoveryState,
            render: (calving) => calving.calvingRecoveryState,
          },
          {
            name: 'Status',
            keyExtractor: (calving) => calving.status,
            render: (calving) => calving.status,
          },
          {
            name: 'Grafana',
            render: (calving) => (
              <Link
                to={{
                  pathname: `https://halter.grafana.net/d/I9QlPyi4z/behaviour-support-toolbelt?orgId=1&var-farm_id=${farmId}&var-cattle_id=${calving.cattleId}`,
                }}
                target="_blank"
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  fontSize: '15px',
                  textAlign: 'center',
                }}
              >
                🛠
              </Link>
            ),
          },
          {
            name: '',
            render: (calving) => (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteCalving(calving.id);
                }}
                disabled={isHandlingDelete}
              >
                <Delete htmlColor="black" />
              </IconButton>
            ),
          },
        ]}
        defaultSortColumnName="Cow"
        headerColour={CaddieColours.AccentGrey}
        indexColumn={false}
        sortDirectionOption="asc"
        size="small"
      />
    </>
  );
};

export default CalvingTable;
