import { useSelector } from 'store';
import {
  getBehaviourPositions,
  getExitPoints,
  getFarmBreaks,
  getFarmPaddocks,
  getFarmsWithDatums,
  getLayersStatus,
  getPiezoLocations,
  getPositions,
  getShockLocations,
  getSlotGroups,
  getVibeLocations,
  isLoading,
} from 'store/selectors/heat-map.selectors';

const HeatMapService = {
  useFetchShockLocations: () => useSelector(getShockLocations),
  useFetchPiezoLocations: () => useSelector(getPiezoLocations),
  useFetchVibeLocations: () => useSelector(getVibeLocations),
  useFetchPositions: () => useSelector(getPositions),
  useFetchFarmsWithDatums: () => useSelector(getFarmsWithDatums),
  useFetchFarmPaddocks: () => useSelector(getFarmPaddocks),
  useFetchFarmBreaks: () => useSelector(getFarmBreaks),
  useFetchExitPoints: () => useSelector(getExitPoints),
  useFetchBehaviourPositions: () => useSelector(getBehaviourPositions),
  useFetchSlotGroups: () => useSelector(getSlotGroups),
  useIsLoading: () => useSelector(isLoading),
  useFetchLayersStatus: () => useSelector(getLayersStatus),
};

export default HeatMapService;
