import { useSelector } from 'store';
import { isLoading, allReports, report, reportData } from 'store/selectors/reports.selectors';

const ReportsService = {
  useIsLoading: () => useSelector(isLoading),
  useAllReports: () => useSelector(allReports),
  useReport: () => useSelector(report),
  useReportData: () => useSelector(reportData),
};

export default ReportsService;
