import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { environment } from 'env-config';

const Iframe = styled.iframe`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  width: 100vw;
  height: calc(100vh - 43px);
  border: 0;
  position: absolute;
  top: 43px;
`;

const MapFrame: FunctionComponent = () => (
  <Iframe
    src={`https://halter-admin-dashboard.${environment}.halter.io/map`}
    frameBorder={0}
    width="100%"
    height="100%"
  />
);

export default MapFrame;
