import { createSelector } from 'reselect';
import { FarmStatus } from 'data/farm-status';

import { AppState } from '../index';

export const selectAllFarmStatuses = (state: AppState) => state.farmStatus.farmStatuses;
export const selectLoadingFarmStatus = (state: AppState) => state.farmStatus.loading;
export const getAllFarmStatuses = createSelector(selectAllFarmStatuses, (farmStatuses) => farmStatuses);
export const getLoadingFarmStatus = createSelector(selectLoadingFarmStatus, (loading) => loading);

export const getFarmStatus = (farmId: string) =>
  createSelector(
    selectAllFarmStatuses,
    (farmStatuses): FarmStatus | undefined => farmStatuses.entities[farmId]
  );
