import React, { FunctionComponent, useEffect, useState } from 'react';

import { IDeviceLocationDTO, IPoint } from '@halter-corp/bff-mobile-service-client';

import { useParseQueryParameters } from 'application/hooks/use-parameters';
import BffDebugToolService from 'services/bff-debug-tool.service';
import TopographyService from 'services/topography.service';
import HttpApiService from 'services/http-api.service';
import LiveViewService from 'services/live-view.service';

import { useHistory } from 'react-router-dom';
import { IDeviceDTO } from '@halter-corp/device-service-client';
import { useDispatch, useSelector } from 'react-redux';
import {
  TowerWithDevices,
  selectDeviceContext,
  selectGatewaysWithStatus,
  selectGatewayIds,
  selectTowersWithDevices,
} from 'store/selectors/tower.selectors';
import towerEffects from 'store/effects/tower.effects';
import { useDeepEffect } from 'application/hooks/use-deep-effect';
import LiveViewScreen from '../screens/live-view.screen';
import { useDebugModeDisabled } from '../util';

const LiveViewContainer: FunctionComponent = () => {
  const { farmId } = useParseQueryParameters();
  const history = useHistory();
  const dispatch = useDispatch();
  const disableDebugMode = useDebugModeDisabled();

  const fetchRandomFarm = async () => {
    const farms = await BffDebugToolService.getFarmsWithActiveDevices();
    const badFarms = [
      'b0187312-ab5a-48e3-a723-5d97e20d86ab', // Apple App Store Farm
      '10198a61-8e47-402b-9f82-5746e4a46085', // Fleet
      '31552ba7-eb00-4ab7-a878-1def29a08c5e', // Halter Auckland Farm
      '9c63586e-3b96-471a-a77b-d9acb983c097', // Hardware Team Farm
      'f0729f30-2b31-49b1-ab46-9498cdb3c49c', // Southridge
      'facd458a-7d03-444e-84d0-414853f364b3', // Temporary Morrinsville Farm
      'd3a2ad58-4c20-4bbe-a488-2e846b41f64b', // Temporary Morrinsville Walkaround Farm
      '0ece457b-cd10-406a-87e2-8374d32bc4d4', // Woodhurst Drafting Test Farm
    ];
    const goodFarms = farms!.filter((farm) => !badFarms.includes(farm));
    const newFarm = goodFarms[Math.floor(Math.random() * goodFarms.length)];
    const location = {
      search: new URLSearchParams({
        farmId: newFarm,
        disableDebugMode: String(disableDebugMode),
      }).toString(),
    };
    history.push(location);
  };

  if (farmId === 'random') {
    fetchRandomFarm();
  }
  const towersWithDevices: TowerWithDevices[] = useSelector(selectTowersWithDevices);
  const devices = useSelector(selectDeviceContext);
  const gatewaysWithStatus = useSelector(selectGatewaysWithStatus);
  const gatewayIds = useSelector(selectGatewayIds);

  const [deviceLocations, setDeviceLocations] = useState<
    {
      serialNumber: string;
      location: IPoint;
      currentPaddockId?: string | undefined;
      updatedDate: Date;
      mobId?: string | undefined;
    }[]
  >();
  const [datumLocation, setDatumLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  const loadLocations = async () => {
    const locations = await LiveViewService.fetchDeviceLocationsList(farmId);
    const registryApi = await HttpApiService.getDeviceRegistryApi(farmId);
    const { data: collarDevices } = await registryApi.findAllDevices();

    const mobInfo = collarDevices
      .map((item: IDeviceDTO) => ({ mobId: item.mobId, serialNumber: item.serialNumber }))
      .filter((item: any) => item.mobId != null);

    const locationsWithDeviceInfo = locations?.map((itm: IDeviceLocationDTO) => ({
      ...mobInfo.find((item: any) => item.serialNumber === itm.serialNumber && item),
      ...itm,
    }));

    setDeviceLocations(locationsWithDeviceInfo);
  };

  const loadDatum = async () => {
    const datumFeature = await TopographyService.fetchDatum(farmId);
    if (datumFeature != null) {
      const { coordinates } = datumFeature.feature.geometry;
      setDatumLocation({ latitude: coordinates[1], longitude: coordinates[0] });
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([loadLocations(), loadDatum()]);
    })();

    if (farmId === '') return;
    dispatch(towerEffects.fetchAllDeviceContextByFarmId({ farmId }));
    dispatch(towerEffects.fetchTowersByFarmId({ farmId }));
  }, [farmId]);

  useEffect((): (() => void) => {
    // This allows for adding disableAutoRefresh flag to local storage to prevent the auto centering in live view
    if (localStorage.getItem('disableAutoRefresh')) {
      return () => {};
    }
    const interval = setInterval(async () => {
      await Promise.all([loadLocations()]);
      dispatch(towerEffects.fetchAllDeviceContextByFarmId({ farmId }));
    }, 30_000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmId]);

  useDeepEffect(() => {
    dispatch(towerEffects.fetchDeviceStatusByDevices({ devices }));
  }, [devices]);

  useDeepEffect(() => {
    dispatch(towerEffects.fetchAllHeatMapsByGateways({ gatewayIds }));
  }, [gatewayIds]);

  return (
    <LiveViewScreen
      farmId={farmId}
      deviceLocations={deviceLocations}
      disableDebugMode={disableDebugMode}
      towers={towersWithDevices}
      gateways={gatewaysWithStatus}
      datum={datumLocation}
    />
  );
};

export default LiveViewContainer;
