import React, { FunctionComponent, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import mobSlice from 'store/slices/mob.slice';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentMob, selectMobs } from 'store/selectors/mob.selectors';
import { IMobDTO } from '@halter-corp/cattle-service-client';
import mobEffects from 'store/effects/mob.effects';

export type MobSelectProps = {
  farmId: string;
  onChange?: (value: string) => void;
};

const MobSelect: FunctionComponent<MobSelectProps> = ({ farmId, onChange }) => {
  const dispatch = useDispatch();

  const currentMob = useSelector(getCurrentMob);
  const mobList = useSelector(selectMobs);

  useEffect(() => {
    dispatch(mobEffects.fetch({ farmId }));
  }, [dispatch, farmId]);

  const handleMobChange = (e: any) => {

    if (onChange != null) {
      onChange(e.target.value);
    }

    dispatch(
      mobSlice.actions.setCurrentMob({
        currentMob: e.target.value,
      })
    );
  };

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel>Mob</InputLabel>
      {mobList.length > 0 && (
        <Select displayEmpty value={currentMob || ''} onChange={(e) => handleMobChange(e)}>
          {[
            ...[undefined, ...mobList].map((mob?: IMobDTO) => (
              <MenuItem style={{ height: 40 }} key={mob?.id ?? 'key'} value={mob?.id}>
                {mob?.name}
              </MenuItem>
            )),
          ]}
        </Select>
      )}
    </FormControl>
  );
};

export default MobSelect;
