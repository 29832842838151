import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Source, Layer, HeatmapLayer } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import HeatMapService from 'services/heat-map.service';
import heatMapEffects from 'store/effects/heat-map.effects';
import { featureCollection, point } from 'turf';
import { Bounds, DataMapSearch } from '../panels/data-map.panel';
import { eventLocationBounds } from '../util/utils';

type PiezoHeatmapLayerProps = {
  search: DataMapSearch;
  currentTime?: Date;
  recenterMap: (bounds: Bounds) => void;
  refresh: boolean;
};

const heatmapLayer: HeatmapLayer = {
  id: 'piezo-heatmap',
  type: 'heatmap',
  paint: {
    'heatmap-weight': 1,
    'heatmap-intensity': 1,
    'heatmap-radius': ['interpolate', ['exponential', 2], ['zoom'], 0, 1, 22, 200],
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(219, 188, 33,0)',
      0.1,
      'rgba(219, 188, 33,0.8)',
      1,
      'rgb(242, 217, 92)',
    ],
  },
};

const PiezoHeatmapLayer: FunctionComponent<PiezoHeatmapLayerProps> = ({
  search,
  currentTime,
  recenterMap,
  refresh,
}) => {
  const dispatch = useDispatch();
  const piezoLocations = HeatMapService.useFetchPiezoLocations();
  const filteredPiezoLocations =
    currentTime === undefined
      ? piezoLocations
      : piezoLocations.filter(
        (piezoLocation) =>
          piezoLocation.timestamp.getTime() <= currentTime.getTime() &&
          piezoLocation.timestamp.getTime() >= currentTime.getTime() - 180 * 1000
      );
  const piezoFeatureCollection =
    filteredPiezoLocations &&
    featureCollection(
      filteredPiezoLocations.map((piezoLocation) => point([piezoLocation.longitude, piezoLocation.latitude]))
    );

  useEffect(() => {
    dispatch(
      heatMapEffects.fetchPiezoLocations({
        farmIds: search.farmIds,
        mobIds: search.mobIds,
        cattleNames: search.cattleNames,
        startTime: search.startTime,
        endTime: search.endTime,
      })
    );
  }, [JSON.stringify(search), refresh]);

  // Recenter Map
  useMemo(() => {
    if (piezoLocations.length > 0) {
      const bounds = eventLocationBounds(piezoLocations);
      recenterMap(bounds);
    }
  }, [piezoLocations]);

  return (
    <Source type="geojson" data={piezoFeatureCollection}>
      <Layer {...heatmapLayer} minzoom={12} />
    </Source>
  );
};
export default PiezoHeatmapLayer;
