import { Typography, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FunctionComponent, useState } from 'react';
import { CattleEvent } from 'store/selectors/map-page-items.selectors';
import styled from 'styled-components';
import CowgEventTable from '../../components/tables/cowg-event-table';
import {
  controlDisabledColumn,
  deactivationEventsColumn,
  earTagColumn,
  failedShockColumn,
  piezoingColumn,
  shockColumn,
} from '../../components/tables/cowg-event-table-columns';

const EventTableWrapper = styled.div`
  width: 720px;
  overflow-x: auto;
`;

export type CattleEventsSummary = {
  cattleName: string;
  shockFailed: number;
  shock: number;
  inBounds: number;
  inZone: number;
  piezoing: number;
  controlDisabled: number;
  shove: number;
  deactivationEvents: number;
  items: CattleEvent[];
};

export type EventsSummaryPanelProps = { cattleEventsLayerData: CattleEventsSummary[] };

const cowgEventColumns = [
  earTagColumn,
  failedShockColumn,
  shockColumn,
  controlDisabledColumn,
  piezoingColumn,
  deactivationEventsColumn,
];

const EventsSummaryPanel: FunctionComponent<EventsSummaryPanelProps> = ({ cattleEventsLayerData }) => {
  const [eventsSummaryExpanded, setEventsSummaryExpanded] = useState(false);

  return (
    <Accordion
      onChange={(_, isExpanded) => setEventsSummaryExpanded(isExpanded)}
      expanded={eventsSummaryExpanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content">
        <div>
          <Typography color="textSecondary">{cattleEventsLayerData.length} Cows with Cowg Events</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ paddingTop: 0 }}>
        {eventsSummaryExpanded && (
          <EventTableWrapper>
            <CowgEventTable cattleEventsLayerData={cattleEventsLayerData} columns={cowgEventColumns} />
          </EventTableWrapper>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default EventsSummaryPanel;
