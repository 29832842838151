import React, { FunctionComponent, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import pluralize from 'pluralize';
import { entries } from 'lodash';
import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';
import Wrapper from 'application/components/wrapper';
import { ApplyProfiles } from '../../forms/apply-profiles.form/types';
import ProfileSummaryCell from './profile-summary-cell';

const Title = styled.div`
  font-size: 18px;
  font-weight: 300;
  padding: 16px;
`;

const Separator = styled.div`
  height: 2px;
  background-color: black;
  opacity: 0.15;
  width: 100%;
  margin: 8px 0px 16px 0px;
`;

type ProfileSummaryProps = {
  profileToApply: ApplyProfiles;
};

const ProfileSummary: FunctionComponent<ProfileSummaryProps> = ({ profileToApply }) => {
  const nonEmpty = useMemo(
    () =>
      entries(profileToApply)
        .filter(([, applyProfilesOption]) => applyProfilesOption.type !== 'keep-current')
        .map(([type, applyProfilesOption]) => ({
          type: type as TrainingSettingsTypeEnum,
          profile: applyProfilesOption.type === 'apply' ? applyProfilesOption.profile : undefined,
        })),
    [profileToApply]
  );

  return (
    <Wrapper>
      <Separator />
      <Title>Summary of {pluralize('profile', nonEmpty.length)} being applied</Title>
      <Grid container spacing={1} style={{ paddingBottom: 8 }}>
        {nonEmpty.map((profile) => (
          <Grid key={profile.type} item sm={3}>
            <ProfileSummaryCell profilesToBeApplied={profile} />
          </Grid>
        ))}
      </Grid>
      <Separator />
    </Wrapper>
  );
};

export default ProfileSummary;
