import { createSlice } from '@reduxjs/toolkit';
import { reset } from 'store/actions';
import farmStatusAdapter from 'store/adapters/farm-status.adapter';
import farmStatusEffects from 'store/effects/farm-status.effects';

const initialState = {
  farmStatuses: farmStatusAdapter.getInitialState(),
  loading: false,
};

const farmStatusSlice = createSlice({
  initialState,
  name: 'farm-status',
  reducers: {
    clear: () => initialState,
    setLoading: (state) => ({ ...state, loading: true }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(farmStatusEffects.fetchAll.fulfilled, (state, action) => {
        const entities = action.payload;
        farmStatusAdapter.removeAll(state.farmStatuses);
        farmStatusAdapter.addMany(state.farmStatuses, entities);
        state.loading = false;
      })
      .addCase(farmStatusEffects.fetch.fulfilled, (state, action) => {
        const entity = action.payload;
        farmStatusAdapter.addOne(state.farmStatuses, entity);
        state.loading = false;
      })
      .addCase(farmStatusEffects.setMaintenanceOn.pending, (state) => {
        state.loading = true;
      })
      .addCase(farmStatusEffects.setMaintenanceOn.fulfilled, (state, action) => {
        const entity = action.payload;
        farmStatusAdapter.updateOne(state.farmStatuses, { id: entity.farmId, changes: entity });
        state.loading = false;
      })
      .addCase(farmStatusEffects.setMaintenanceOff.pending, (state) => {
        state.loading = true;
      })
      .addCase(farmStatusEffects.setMaintenanceOff.fulfilled, (state, action) => {
        const entity = action.payload;
        farmStatusAdapter.updateOne(state.farmStatuses, { id: entity.farmId, changes: entity });
        state.loading = false;
      })
      .addCase(farmStatusEffects.setEmergencyOn.pending, (state) => {
        state.loading = true;
      })
      .addCase(farmStatusEffects.setEmergencyOn.fulfilled, (state, action) => {
        const entity = action.payload;
        farmStatusAdapter.updateOne(state.farmStatuses, { id: entity.farmId, changes: entity });
        state.loading = false;
      })
      .addCase(farmStatusEffects.setEmergencyOff.pending, (state) => {
        state.loading = true;
      })
      .addCase(farmStatusEffects.setEmergencyOff.fulfilled, (state, action) => {
        const entity = action.payload;
        farmStatusAdapter.updateOne(state.farmStatuses, { id: entity.farmId, changes: entity });
        state.loading = false;
      }),
});

export default farmStatusSlice;
