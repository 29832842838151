import React, { FunctionComponent } from 'react';
import InstallPlannerContainer from './containers/install-planer.container';
import InstallPlannerContextWrapper from './wrapper/install-planner-context-wrapper';

const InstallPlannerModule: FunctionComponent = () => (
  <InstallPlannerContextWrapper>
    <InstallPlannerContainer />
  </InstallPlannerContextWrapper>
);

export default InstallPlannerModule;
