import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { TrainingSettingsTypeEnum } from '@halter-corp/training-service-client';

import { Modifier, Profile } from 'data/training';

import { useProfilesByType, useModifiersByType } from '../hooks/use-profiles-by-type';
import { prettyPrintProfileType } from '../utils';
import ProfilesTable from './profiles-table';
import ModifiersTable from './modifiers-table';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const FarmTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const ProfilesWrapper = styled.div`
  padding: 8px;
`;

export const TypeTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  opacity: 0.75;
  padding: 4px;
`;

const ModifiersWrapper = styled.div`
  padding-top: 8px;
`;

export const ModifierTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  opacity: 0.6;
  padding: 4px;
`;

type FarmProfileProps = {
  farmName: string;
  profiles: Profile[];
  modifiers: Modifier[];
  onSelectProfile: (profile: Profile) => void;
  onSelectModifier: (modifier: Modifier) => void;
};

const FarmProfiles: FunctionComponent<FarmProfileProps> = ({
  farmName,
  profiles,
  modifiers,
  onSelectProfile,
  onSelectModifier,
}) => {
  const profilesByType = useProfilesByType(profiles);
  const modifiersByType = useModifiersByType(modifiers);

  return (
    <Wrapper>
      <FarmTitle>{farmName}</FarmTitle>
      {Object.values(TrainingSettingsTypeEnum).map((type) => {
        const profilesForType = profilesByType[type] ?? [];
        const modifiersForType = modifiersByType[type] ?? [];

        if (isEmpty(profilesForType) && isEmpty(modifiersForType)) return null;
        return (
          <ProfilesWrapper key={type}>
            <ProfilesTable
              profiles={profilesForType}
              onPressRow={onSelectProfile}
              title={prettyPrintProfileType(type as TrainingSettingsTypeEnum)}
            />
            {!isEmpty(modifiersByType[type]) && (
              <ModifiersWrapper>
                <ModifiersTable
                  modifiers={modifiersByType[type] ?? []}
                  onPressRow={onSelectModifier}
                  title={prettyPrintProfileType(type as TrainingSettingsTypeEnum)}
                />
              </ModifiersWrapper>
            )}
          </ProfilesWrapper>
        );
      })}
    </Wrapper>
  );
};

export default FarmProfiles;
