import { IMobDTO } from '@halter-corp/cattle-service-client';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mobEffects from 'store/effects/mob.effects';
import { selectMobs } from 'store/selectors/mob.selectors';

export type MobSelectProps = {
  farmId: string;
  onChange: (value: IMobDTO[] | undefined) => void;
  disabled?: boolean;
  value?: string[];
};

const MobSelect: FunctionComponent<MobSelectProps> = ({ farmId, onChange, disabled, value }) => {
  const dispatch = useDispatch();

  const mobList = useSelector(selectMobs);

  useEffect(() => {
    dispatch(mobEffects.fetch({ farmId }));
  }, [dispatch, farmId]);

  const currentMobs = mobList.filter((mob) => value?.includes(mob.id));

  return (
    <>
      {mobList.length > 0 && (
        <Autocomplete
          options={mobList}
          renderInput={(farm) => <TextField {...farm} label="Mobs" variant="outlined" size="small" />}
          getOptionLabel={(option) => option.name || ''}
          multiple
          onChange={(event, newValue) => {
            onChange(newValue.length === 0 ? undefined : newValue);
          }}
          disabled={disabled}
          value={currentMobs}
        />
      )}
    </>
  );
};
export default MobSelect;
