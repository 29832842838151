import { useSelector } from 'store';
import {
  isLoading,
  selectProfileEntityById,
  selectProfilesByFarmId,
  selectAllProfiles,
  selectModifiersByFarmId,
  selectAllModifiers,
  selectAppliedTrainings,
  selectModifierEntityById,
  selectAllFarmerTrainingGuides,
  selectAllFarmerTrainingGuideOptions,
  selectAllAppliedProfiles,
} from 'store/selectors/training.selectors';

const TrainingService = {
  useSelectProfiles: () => useSelector(selectAllProfiles),

  useSelectProfilesByFarmId: (farmId: string) => useSelector(selectProfilesByFarmId(farmId)),

  useSelectModifiers: () => useSelector(selectAllModifiers),

  useSelectModifiersByFarmId: (farmId: string) => useSelector(selectModifiersByFarmId(farmId)),

  useSelectProfileById: (profileId: string) =>
    useSelector((state) => selectProfileEntityById(state, profileId)),

  useSelectModifierById: (modifierId: string) =>
    useSelector((state) => selectModifierEntityById(state, modifierId)),

  useIsLoading: () => useSelector(isLoading),

  useSelectAppliedModifiers: () => useSelector(selectAppliedTrainings),

  useSelectAppliedProfiles: () => useSelector(selectAllAppliedProfiles),

  useSelectFarmerTrainingGuides: () => useSelector(selectAllFarmerTrainingGuides),

  useSelectFarmerTrainingGuideOptions: () => useSelector(selectAllFarmerTrainingGuideOptions),
};

export default TrainingService;
