import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import mobEffects from 'store/effects/mob.effects';

const useUpdateMobs = (farmId: string, callback?: () => void) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (callback != null) callback();

    if (isEmpty(farmId)) return;
    dispatch(mobEffects.fetch({ farmId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, farmId]);
};

export default useUpdateMobs;
