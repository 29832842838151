import React, { FunctionComponent, useMemo } from 'react';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';

import Button from 'application/components/button';

const ModalBody = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  max-width: 360px;
`;

const Dialog = styled.div`
  padding-bottom: 16px;
  text-align: center;
`;

const ButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  padding: 16px 32px 16px 32px;
`;

type DeleteModalProps = {
  name: string;
  type: string;
  open: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
};

const DeleteModal: FunctionComponent<DeleteModalProps> = ({ name, type, open, onDismiss, onConfirm }) => {
  const message = useMemo(() => `Are you sure you want to delete ${type}: ${name}?`, [name, type]);

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      onBackdropClick={(e) => {
        onDismiss();
        e.stopPropagation();
      }}
      disableAutoFocus
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', outline: 'none' }}
    >
      <ModalBody>
        <Dialog>{message}</Dialog>
        <ButtonPanel>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Button
              onClick={onDismiss}
              text="No"
              style={{ border: '1px solid grey', backgroundColor: '#e1f5fe', color: 'grey' }}
            />
          </div>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Button onClick={onConfirm} text="Yes" style={{ backgroundColor: '#f44336', color: 'white' }} />
          </div>
        </ButtonPanel>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
