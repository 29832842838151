import React, { FunctionComponent, useEffect, useRef } from 'react';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import moment from 'moment';
import JSONTree from 'react-json-tree';
import { Link } from 'react-router-dom';

import { CattleEvent } from 'store/selectors/map-page-items.selectors';
import { jsonTreeTheme } from 'application/modules/debug-cattle/utils';
import { DEFAULT_DATE_TIME_FORMAT } from 'application/modules/debug-cattle/utils/constants';

type CattleEventPopupProps = {
  cattleEvent: CattleEvent;
  onClose: () => void;
};

const CattleEventPopup: FunctionComponent<CattleEventPopupProps> = ({ cattleEvent, onClose }) => {
  const markerRef = useRef<Marker>(null);

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      markerRef.current?.fireLeafletEvent('click', {});
    }, 250);
  }, [`${cattleEvent.timestamp}#${cattleEvent.serialNumber}`]);

  return (
    <Marker
      ref={markerRef}
      icon={L.divIcon({ iconSize: [0, 0], html: '' })}
      position={[cattleEvent.location.lat, cattleEvent.location.lon]}
    >
      <Popup onClose={onClose} autoPan={false}>
        <JSONTree data={cattleEvent} theme={jsonTreeTheme} shouldExpandNode={() => true} hideRoot />
        <Link
          target="_blank"
          to={`/devices/${cattleEvent.serialNumber}?atTime=${new Date(cattleEvent.timestamp).toISOString()}`}
        >
          View collar details on {moment(cattleEvent.timestamp).format(DEFAULT_DATE_TIME_FORMAT)}
        </Link>
      </Popup>
    </Marker>
  );
};

export default CattleEventPopup;
