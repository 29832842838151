import React, { FunctionComponent, useMemo } from 'react';
import { Polyline } from 'react-leaflet';

import { AppState, useSelector } from 'store';
import {
  CattleLocationPath,
  findCattleLocationPathsForDateRangeAndSelectedCows,
} from 'store/selectors/map-page-items.selectors';
import { useLeafletZoom } from 'use-leaflet';

import { getColorForMetric } from '../../../utils';

export type CattleLocationsProps = {
  selectedCows: string[] | null;
  scrubbedDateRange: [Date, Date] | null;
  onSelectedCow: (name: string | null) => void;
};

const CattleLocationsLayer: FunctionComponent<CattleLocationsProps> = ({
  selectedCows,
  scrubbedDateRange,
  onSelectedCow,
}) => {
  const cattlePaths: CattleLocationPath[] = useSelector((state: AppState) =>
    findCattleLocationPathsForDateRangeAndSelectedCows(state, {
      selectedCows,
      minimumDate: scrubbedDateRange?.[0] || null,
      maximumDate: scrubbedDateRange?.[1] || null,
    })
  );

  const zoom = useLeafletZoom();
  const showCattleEvents = useMemo(() => zoom > 20, [zoom]);

  return (
    <>
      {cattlePaths.map((path) => (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <Polyline
          color={getColorForMetric()}
          opacity={showCattleEvents ? 0.4 : 1}
          onmouseover={() => {
            onSelectedCow(path.cattleName);
          }}
          onmouseout={() => {
            onSelectedCow(null);
          }}
          key={path.cattleName}
          positions={path.locations.map((l) => l.location)}
        />
      ))}
    </>
  );
};

export default CattleLocationsLayer;
